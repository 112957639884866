import { InputText } from '@components/atomic/inputs/InputText';
import { Loader } from '@components/atomic/Loader';
import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';
import { IIframe } from '@models/settings/utils/settingsTypes';
import { getUserInfo } from '@models/users/apiRequests/userRequests';
import { emailValidationRegex } from '@utils/regex';
import { ENTITY_TYPES } from '@utils/roles';
import { placeholderExample } from '@utils/utils';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ColorPickerSection } from '../components/ColorPickerSection';
import ContactInformationsSection from '../components/ContactInformationsSection';
import { ConventionSelector } from '../components/ConventionSelector';

// Couleurs par défaut pour le simulateur
export const DEFAULT_PRIMARY_COLOR = '#0050D5'; // Bleu principal
export const DEFAULT_SECONDARY_COLOR = '#00B0A7'; // Vert-bleu pour les cards d'opérations

// Interface pour les données de l'entité utilisateur
interface IEntityInfo {
  company_name?: string;
  zipcode?: string;
  city?: string;
  address?: string;
  phone_number?: string;
  contact_email?: string;
}

interface IframeSimulatorProps {
  iframeData: IIframe | null;
}

function IframeSimulator({ iframeData }: IframeSimulatorProps) {
  const { t } = useTranslation();
  const { userView } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const isInstaller = userView?.entity_type === ENTITY_TYPES.INSTALLATEUR;

  // États pour les couleurs
  const [primaryColor, setPrimaryColor] = useState<string>(
    iframeData?.theme.color_primary || DEFAULT_PRIMARY_COLOR
  );
  const [primaryTextColor, setPrimaryTextColor] = useState<'white' | 'black'>(
    iframeData?.theme.color_primary_text || 'white'
  );
  const [secondaryColor, setSecondaryColor] = useState<string>(
    iframeData?.theme.color_secondary || DEFAULT_SECONDARY_COLOR
  );
  const [secondaryTextColor, setSecondaryTextColor] = useState<
    'white' | 'black'
  >(iframeData?.theme.color_secondary_text || 'white');

  // État pour les informations de l'entité
  const [entityInfo, setEntityInfo] = useState<IEntityInfo | null>(null);
  const [isLoadingEntityInfo, setIsLoadingEntityInfo] =
    useState<boolean>(false);

  // État pour gérer l'affichage des erreurs d'email
  const [isEmailTouched, setIsEmailTouched] = useState<boolean>(false);

  // Utilisation de useFormContext pour accéder au formulaire défini dans le parent
  const { setValue, watch } = useFormContext();

  // Surveiller les changements de valeurs du formulaire
  const contractId = watch('convention_id');
  const email = watch('contact_email');

  // Validation de l'email
  const isEmailValid = email ? emailValidationRegex.test(email) : true;
  const shouldShowEmailError = isEmailTouched && !isEmailValid;
  const emailError = shouldShowEmailError ? t('forms.email.error_pattern') : '';

  // Gestion du changement d'email
  const handleEmailChange = (value: any) => {
    if (typeof value === 'string') {
      setValue('contact_email', value, {
        shouldValidate: false,
        shouldDirty: true,
      });
    } else if (value && value.target && value.target.value) {
      setValue('contact_email', value.target.value, {
        shouldValidate: false,
        shouldDirty: true,
      });
    }
  };

  // Gestion du changement de focus sur l'email
  const handleEmailBlur = () => {
    setIsEmailTouched(true);
  };

  // Récupérer les données de l'utilisateur
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user && user.id) {
        setIsLoadingEntityInfo(true);
        try {
          const data = await getUserInfo(user.id);
          if (data && data.informations_entite) {
            setEntityInfo({
              company_name: data.informations_entite.company_name || '',
              zipcode: data.informations_entite.zipcode || '',
              city: data.informations_entite.city || '',
              address: data.informations_entite.address || '',
              phone_number: data.informations_entite.phone_number || '',
              contact_email: data.informations_entite.contact_email || '',
            });

            // Pré-remplir les champs du formulaire en respectant l'ordre de priorité :
            // 1. Données de l'iframe si présentes
            // 2. Données de l'utilisateur sinon
            // 3. Champs vides si aucune donnée n'est disponible

            // Raison sociale
            if (iframeData?.coordinates?.company_name) {
              setValue(
                'coordinates_company',
                iframeData.coordinates.company_name
              );
            } else if (data.informations_entite.company_name) {
              setValue(
                'coordinates_company',
                data.informations_entite.company_name
              );
            }

            // Adresse
            if (iframeData?.coordinates?.address) {
              setValue('coordinates_address', iframeData.coordinates.address);
            } else if (data.informations_entite.address) {
              setValue('coordinates_address', data.informations_entite.address);
            }

            // Code postal
            if (iframeData?.coordinates?.zipcode) {
              setValue(
                'coordinates_postal_code',
                iframeData.coordinates.zipcode
              );
            } else if (data.informations_entite.zipcode) {
              setValue(
                'coordinates_postal_code',
                data.informations_entite.zipcode
              );
            }

            // Ville
            if (iframeData?.coordinates?.city) {
              setValue('coordinates_city', iframeData.coordinates.city);
            } else if (data.informations_entite.city) {
              setValue('coordinates_city', data.informations_entite.city);
            }

            // Téléphone
            if (iframeData?.coordinates?.phone_number) {
              setValue(
                'coordinates_phone',
                iframeData.coordinates.phone_number
              );
            } else if (data.informations_entite.phone_number) {
              setValue(
                'coordinates_phone',
                data.informations_entite.phone_number
              );
            }

            // Email
            if (iframeData?.coordinates?.email) {
              setValue('coordinates_email', iframeData.coordinates.email);
            } else if (data.informations_entite.contact_email) {
              setValue(
                'coordinates_email',
                data.informations_entite.contact_email
              );
            }
          }
        } catch (error) {
          console.error(
            'Erreur lors de la récupération des informations utilisateur:',
            error
          );
        } finally {
          setIsLoadingEntityInfo(false);
        }
      }
    };

    fetchUserInfo();
  }, [user]);

  // Mettre à jour les valeurs de couleur dans le formulaire
  useEffect(() => {
    setValue('primary_color', primaryColor);
    setValue('primary_text_color', primaryTextColor);
    setValue('secondary_color', secondaryColor);
    setValue('secondary_text_color', secondaryTextColor);
  }, [
    primaryColor,
    primaryTextColor,
    secondaryColor,
    secondaryTextColor,
    setValue,
  ]);

  return (
    <div className="flex flex-col gap-6 bg-white rounded-default p-6">
      {/* Sélecteur de convention (uniquement pour les installateurs) */}
      {isInstaller && (
        <div className="flex flex-col w-full">
          <ConventionSelector
            iframeData={iframeData}
            contractId={contractId}
            setValue={setValue}
          />
        </div>
      )}

      {/* Champ email */}
      <div className="flex flex-col w-full">
        <InputText
          id="contact_email"
          name="contact_email"
          placeholder={placeholderExample(t).EMAIL}
          label={
            t('settings.iframes.contact_email') ||
            'E-mail de réception des demandes de contact'
          }
          hideIsOptional
          value={email || ''}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          error={shouldShowEmailError}
          textError={emailError}
        />
      </div>

      {/* Section ColorPicker */}
      <ColorPickerSection
        primaryColor={primaryColor}
        primaryTextColor={primaryTextColor}
        secondaryColor={secondaryColor}
        secondaryTextColor={secondaryTextColor}
        setPrimaryColor={setPrimaryColor}
        setPrimaryTextColor={setPrimaryTextColor}
        setSecondaryColor={setSecondaryColor}
        setSecondaryTextColor={setSecondaryTextColor}
      />

      {/* Section Informations de contact */}
      {isLoadingEntityInfo ? (
        <div className="flex justify-center items-center py-4">
          <Loader />
        </div>
      ) : (
        <ContactInformationsSection
          iframeData={iframeData}
          entityInfo={entityInfo}
        />
      )}
    </div>
  );
}

export default IframeSimulator;
