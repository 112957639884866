import { getLinkedFileCheckpoints } from '@models/checkpoints/apiRequests/checkpointsRequests';
import { HeaderCheckpoints } from '@models/checkpoints/components/HeaderCheckpoints';
import LeftPartCheckpoints from '@models/checkpoints/components/LeftPartCheckpoints';
import { MiddlePartCheckpoints } from '@models/checkpoints/components/MiddlePartCheckpoints';
import RightPartCheckPoints from '@models/checkpoints/components/RightPartCheckPoints';
import { TabsCheckPoints } from '@models/checkpoints/components/TabsCheckPoints';
import {
  IControlCounts,
  IILinkedFileMessages,
  ILinkedFileMessage,
  IWorksiteCheckpoints,
} from '@models/checkpoints/utils/checkpointsTypes';
import { GlobalContext } from '@context/globalContext';
import { ILinkedFile } from '@globalTypes/globalTypes';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LinkedFileCheckpointContext } from '@models/checkpoints/utils/linkedFileCheckpointContext';
import { initialWorksite } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { initialDocument } from '@utils/initialState';
import {
  getWorksiteLinkedFiles,
  groupDocuments,
} from '@models/worksites/utils/utils';
import { getResponseValue } from '@models/checkpoints/utils/utils';
import { DOCUMENT_TYPES } from '@utils/utils';

function Checkpoints(): JSX.Element {
  const location = useLocation();
  const { worksiteDetails, documentDetails } = location.state || {};

  const {
    updateDocumentActive,
    globalEnum: { linked_file_type },
  } = useContext(GlobalContext);

  const [document, setDocument] = useState<ILinkedFile>(initialDocument);
  const [worksiteCheckpoints, setWorksiteCheckpoints] = useState<
    IWorksiteCheckpoints[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [savedMessages, setSavedMessages] = useState<IILinkedFileMessages[]>(
    []
  );
  const [linkedFiles, setLinkedFiles] = useState<ILinkedFile[]>([]);
  const [groupedDocuments, setGroupedDocuments] = useState<
    { files: ILinkedFile[]; fileType: number }[]
  >([]);

  const [complianceMessage, setcomplianceMessage] = useState('');

  const [mailIsOpen, setMailIsOpen] = useState(false);

  const getData = async (id: number) => {
    if (id > 0) {
      setIsLoading(true);

      const response = await getLinkedFileCheckpoints(id);

      if (response) {
        setWorksiteCheckpoints((prev) => [
          ...prev.filter((item) => item.document_id !== id),
          { document_id: id, checkpoints: response.checkpoints },
        ]);

        const messagesWithSelected = response.messages.map(
          (msg: ILinkedFileMessage, index: number) => ({
            ...msg,
            selected: index === 0,
          })
        );

        setSavedMessages([
          ...savedMessages,
          { document_id: id, messages: messagesWithSelected },
        ]);

        setWorksiteCheckpoints((prev) => {
          return prev.map((elt) => {
            if (elt.document_id !== id) {
              return elt;
            }

            return { ...elt, checkpoints: response.checkpoints };
          });
        });
      }
      setIsLoading(false);
    }
  };

  const checkpoints = useMemo(() => {
    if (document) {
      return (
        worksiteCheckpoints.find(
          (cp) => cp.document_id === Number(document?.id)
        )?.checkpoints || []
      );
    }
    return [];
  }, [document, worksiteCheckpoints]);

  const worksite: IWorksiteDetails = useMemo(() => {
    if (worksiteDetails?.id) {
      return worksiteDetails;
    }
    return initialWorksite;
  }, [worksiteDetails]);

  const controlCounts: IControlCounts | null = useMemo(() => {
    const total = checkpoints.length;
    const compliant = checkpoints.filter((checkpoint) => {
      const responseValue = getResponseValue(checkpoint, checkpoint.value);
      return responseValue?.valid === true;
    }).length;

    const nonCompliant = checkpoints.filter((checkpoint) => {
      const responseValue = getResponseValue(checkpoint, checkpoint.value);
      return responseValue?.valid === false;
    }).length;

    const toControl = total - compliant - nonCompliant;

    return { to_control: toControl, compliant, non_compliant: nonCompliant };
  }, [checkpoints, document]);

  const linkedFileCheckpointContextValue = useMemo(
    () => ({
      controlCounts,
      checkpoints,
      isLoading,
      updateIsLoading: setIsLoading,
      document,
      updateDocument: setDocument,
      worksite,
      worksiteCheckpoints,
      updateWorksiteCheckpoints: setWorksiteCheckpoints,
      savedMessages,
      updateSavedMessages: setSavedMessages,
      linkedFiles,
      updateLinkedFiles: setLinkedFiles,
      complianceMessage,
      updateComplianceMessage: setcomplianceMessage,
      groupedDocuments,
      updateGroupedDocuments: setGroupedDocuments,
      mailIsOpen,
      updateMailIsOpen: setMailIsOpen,
    }),
    [
      controlCounts,
      checkpoints,
      isLoading,
      document,
      worksite,
      worksiteCheckpoints,
      savedMessages,
      linkedFiles,
      complianceMessage,
      groupedDocuments,
      mailIsOpen,
    ]
  );

  useEffect(() => {
    if (documentDetails?.id && !document?.id) {
      setDocument(documentDetails);
    }
  }, [documentDetails]);

  useEffect(() => {
    if (document.id && document.id > 0) {
      const worksiteCheckpoint = worksiteCheckpoints.some(
        (cp) => cp.document_id === Number(document?.id)
      );

      if (!worksiteCheckpoint && document.file_type !== DOCUMENT_TYPES.AH) {
        getData(document.id);
      }

      updateDocumentActive({
        list: 'checkpoints',
        document,
      });

      const storageKey = `tab_/checkpoints/${worksite.id}_`;
      const storageValue = `${linked_file_type[document.file_type]}_${
        document.id
      }`;

      const tabItem = localStorage.getItem(storageKey);

      if (!tabItem) {
        localStorage.setItem(storageKey, storageValue);
      }
    }
  }, [document]);

  useEffect(() => {
    if (worksite) {
      const files = getWorksiteLinkedFiles(worksite);
      setLinkedFiles(files);
      if (groupedDocuments.length === 0) {
        setGroupedDocuments(groupDocuments(files, true));
      }
    }
  }, [worksite]);

  return (
    <LinkedFileCheckpointContext.Provider
      value={linkedFileCheckpointContextValue}
    >
      <div className="bg-backgroundBody w-screen min-h-screen flex">
        {(!worksite || !document) && !isLoading && (
          <div> Erreur de chargement des données </div>
        )}
        {worksite && document && (
          <div className="flex flex-col w-full">
            <HeaderCheckpoints />
            <div className="mt-[5.5rem] px-[2rem] flex-1 flex flex-col">
              <TabsCheckPoints getData={(id) => getData(id)} />
              <div className="flex flex-1 gap-5">
                <LeftPartCheckpoints />
                <MiddlePartCheckpoints />
                <RightPartCheckPoints />
              </div>
            </div>
          </div>
        )}
      </div>
    </LinkedFileCheckpointContext.Provider>
  );
}

export { Checkpoints };
