import { TFunction } from 'i18next';
import { textGrey, red, green, orange, iconBlue, purple } from '@assets/color';
import { IControlContactSettingsField } from './controlContactTypes';

export const controlContactStatusEnum = {
  LOCKED: 0,
  CONTROLLABLE: 1,
  CONTROLLED: 2,
};

export const controlContactStatus = (t: TFunction) => ({
  [controlContactStatusEnum.LOCKED]: t('control_contact.card.status_locked'),
  [controlContactStatusEnum.CONTROLLABLE]: t(
    'control_contact.card.status_controllable'
  ),
  [controlContactStatusEnum.CONTROLLED]: t(
    'control_contact.card.status_controlled'
  ),
});

export const controlContactLotSource = {
  COFRAC: 'COFRAC',
  OPERATION: 'OPERATION',
};

export const controlContactLotStatus = {
  LOCKED: 0,
  CONTROLLABLE: 1,
  CONTROLLED: 2,
};

export const linkedWorksitesOperationsStatus = {
  CONTROLABLE: 0,
  NON_CONTROLEE: 1,
  SATISFAISANT: 2,
  A_RAPPELER: 3,
  INSUFFISANT: 4,
};

export const startFieldsSettings: (
  t: TFunction
) => IControlContactSettingsField[] = (t: TFunction) => [
  {
    id: 999,
    label: t('control_contact.controller') as string,
    position: 900,
    readonly: true,
    operation_codes: [],
  },
  {
    id: 998,
    label: t('control_contact.control_date') as string,
    position: 902,
    readonly: true,
    operation_codes: [],
  },
];

export const endFieldsSettings: (
  t: TFunction
) => IControlContactSettingsField[] = (t: TFunction) => [
  {
    id: 997,
    label: t('control_contact.status_notice') as string,
    position: 901,
    readonly: true,
    operation_codes: [],
  },
  {
    id: 996,
    label: t('control_contact.recall_date') as string,
    position: 1,
    readonly: true,
    operation_codes: [],
  },
  {
    id: 995,
    label: t('control_contact.comments') as string,
    position: 903,
    readonly: true,
    operation_codes: [],
  },
];

export const responseFormatEnum = {
  DATE: 'date',
  RADIO_BUTTONS: 'radio',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  MULTIPLE_SELECT: 'multiselect',
  TEXT: 'text',
};

export const responseFormatsLabelValue = (t: TFunction) => [
  {
    label: t('settings.type.date'),
    value: responseFormatEnum.DATE,
  },
  {
    label: t('settings.type.radio_buttons'),
    value: responseFormatEnum.RADIO_BUTTONS,
  },
  {
    label: t('settings.type.checkbox'),
    value: responseFormatEnum.CHECKBOX,
  },
  {
    label: t('settings.type.select'),
    value: responseFormatEnum.SELECT,
  },
  {
    label: t('settings.type.multiple_select'),
    value: responseFormatEnum.MULTIPLE_SELECT,
  },
  {
    label: t('settings.type.text'),
    value: responseFormatEnum.TEXT,
  },
];

// Liste des couleurs avec Gris Rouge Vert Orange Bleu Violet
export const colorsResponseFormat = (t: TFunction) => [
  {
    value: textGrey,
    label: t('control_contact.settings.new_field_modal.colors.gray'),
  },
  {
    value: red,
    label: t('control_contact.settings.new_field_modal.colors.red'),
  },
  {
    value: green,
    label: t('control_contact.settings.new_field_modal.colors.green'),
  },
  {
    value: orange,
    label: t('control_contact.settings.new_field_modal.colors.orange'),
  },
  {
    value: iconBlue,
    label: t('control_contact.settings.new_field_modal.colors.blue'),
  },
  {
    value: purple,
    label: t('control_contact.settings.new_field_modal.colors.purple'),
  },
];
