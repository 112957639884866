import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '@context/authContext';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { verifyPermission } from '@models/contracts/utils/contractHelper';
import { GERER } from '@models/contracts/utils/contractConstants';
import { ENTITY_TYPES } from '@utils/roles';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { CONTRACTS_ROUTES } from '@utils/routesUrls';
import { GlobalContext } from '@context/globalContext';
import { getContractLabel } from '../utils/partnersFunctions';

interface IPartnersNewButtonProps {
  entityType: number | null;
  setModal: Dispatch<SetStateAction<boolean>>;
}

function PartnersNewButton({ entityType, setModal }: IPartnersNewButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { userView } = useContext(GlobalContext);

  const contractType = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPES.INSTALLATEUR:
        return ContractTypes.CONVENTION;
      case ENTITY_TYPES.MANDATAIRE:
        return ContractTypes.MANDAT;
      case ENTITY_TYPES.DELEGATAIRE:
      case ENTITY_TYPES.OBLIGE:
        return ContractTypes.VENTE;
      case ENTITY_TYPES.AMO:
        return ContractTypes.TRIPARTITE;
      default:
        return null;
    }
  }, [entityType]);

  const newButton = (permissions = [GERER.CONTRACT]) => {
    if (
      verifyPermission(user, permissions) &&
      userView?.entity_type !== ENTITY_TYPES.INSTALLATEUR
    )
      return (
        <ButtonOpx
          onClick={() =>
            navigate(CONTRACTS_ROUTES.CONTRACTS_CREATION, {
              state: { contractType },
            })
          }
          label={`${getContractLabel(contractType, t)}`}
          type="primary"
        />
      );

    return <div />;
  };

  switch (entityType) {
    case ENTITY_TYPES.INSTALLATEUR:
      return newButton([GERER.CONVENTION]);
    case ENTITY_TYPES.MANDATAIRE:
      return newButton([GERER.CONTRACT_MANDANT]);
    case ENTITY_TYPES.DELEGATAIRE:
    case ENTITY_TYPES.OBLIGE:
      return newButton([GERER.CONTRACT_VENTE]);
    case ENTITY_TYPES.AMO:
      return newButton([GERER.CONVENTION]);
    case ENTITY_TYPES.APPORTEUR_AFFAIRES:
      return (
        <ButtonOpx
          onClick={() => setModal(true)}
          label={t('partners.tabs.button.new_business_provider')}
          type="primary"
        />
      );
    case ENTITY_TYPES.BUREAUX_CONTROLE:
      return (
        <ButtonOpx
          onClick={() => setModal(true)}
          label={t('partners.tabs.button.new_control_office')}
          type="primary"
        />
      );
    case ENTITY_TYPES.SOUS_TRAITANT:
      return (
        <ButtonOpx
          onClick={() => setModal(true)}
          label={t('partners.subcontractors.add_subcontractor')}
          type="primary"
        />
      );
    default:
      return newButton();
  }
}

export { PartnersNewButton };
