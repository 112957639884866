import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { CHECKPOINT_RESPONSE_TYPE } from '@models/settings/utils/enums';
import { InputTypes } from '@utils/utils';
import { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ICheckpoints,
  IFormValuesStoreCheckpoint,
} from '@models/checkpoints/utils/checkpointsTypes';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';

interface IResponseScaleInputs {
  checkpoint?: ICheckpoints;
}

function ResponseScaleInputs({ checkpoint }: IResponseScaleInputs) {
  const methods = useFormContext<IFormValuesStoreCheckpoint>();
  const { control, watch, setValue } = methods;
  const formValues = watch();

  const { t } = useTranslation();

  const valuesName = 'response_values';

  const responseValues = formValues.response_values;

  const { fields } = useFieldArray({
    control,
    name: valuesName,
  });

  const isDate = formValues.response_type === CHECKPOINT_RESPONSE_TYPE.DATE;

  const translationBase = 'settings.checkpoints.modal_add_checkpoint';

  const comparatorOptions = [
    { label: t(`${translationBase}.comparators.between`), value: '≥ ≤' },
    {
      label: t(`${translationBase}.comparators.superior_or_equal`),
      value: '>=',
    },
    {
      label: t(`${translationBase}.comparators.inferior_or_equal`),
      value: '<=',
    },
    { label: t(`${translationBase}.comparators.superior`), value: '>' },
    { label: t(`${translationBase}.comparators.inferior`), value: '<' },
    { label: t(`${translationBase}.comparators.equal`), value: '=' },
  ];

  const emptyValue = { value: '', valid: false };

  useEffect(() => {
    if (checkpoint?.response_values) {
      const values = checkpoint.response_values;
      if (values.length === 1) {
        const comparatorMatch = values[0].value.match(/^([><]=?|=)/);
        const comparator = comparatorMatch ? comparatorMatch[0] : '';
        setValue('comparator', comparator);
      }

      if (values.length === 2) {
        setValue('comparator', '≥ ≤');
      }
    }
  }, [checkpoint]);

  return (
    <div>
      <div className="flex items-center gap-3">
        <InputSelect
          placeholder=""
          label={`${t(`${translationBase}.value_must_be`)}`}
          dataLabelValue={comparatorOptions}
          onSelectLabelValue={(value) => {
            setValue('comparator', value.value);
            if (value.value === '≥ ≤' && fields.length === 1) {
              setValue(valuesName, [emptyValue, emptyValue]);
            } else {
              setValue(valuesName, [emptyValue]);
            }
          }}
          valueInput={
            comparatorOptions.find(
              (option) => option.value === formValues.comparator
            )?.label
          }
          addClass="w-[30%]"
          required
        />
        <div className="mt-[.7rem] w-[70%] flex items-center gap-3">
          {fields.map((val, index) => {
            return (
              <div key={val.id} className="w-full">
                {isDate && <div className="text-[.75rem] mb-2" />}
                <DynamicInputEdit
                  isEditMode
                  inputType={isDate ? InputTypes.DATE : InputTypes.NUMBER}
                  name={`${valuesName}.${index}.value`}
                  label=""
                  placeholder=""
                  required
                  addClass="w-full"
                  initialValue={val.value === '' ? undefined : val.value}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <p className="text-[.75rem] text-textGrey mt-[1.2rem]">
          {t(`${translationBase}.non_conformity_indicator`)}
        </p>
        <div className="text-[.75rem] mt-2 border border-borderGrey rounded-default p-4 flex items-center gap-2">
          <Checkbox
            label="not_valid"
            checked={responseValues.some((val) => !val.valid)}
            onCheck={() => {
              responseValues.forEach((val, index) => {
                setValue(`${valuesName}.${index}.valid`, !val.valid);
              });
            }}
          />
          <div> {t(`${translationBase}.scale_compliance`)}</div>
        </div>
      </div>
    </div>
  );
}

ResponseScaleInputs.defaultProps = {
  checkpoint: undefined,
};

export default ResponseScaleInputs;
