import { Modal } from '@components/Modal';
import { TextError } from '@components/TextError';
import { InputText } from '@components/atomic/inputs/InputText';
import { IIframe } from '@models/settings/utils/settingsTypes';
import {
  generatePdfIframeUrl,
  sendCallbackRequest,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { ICallbackRequestData } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { emailRegex, phoneNumberRegex } from '@utils/regex';
import { placeholderExample } from '@utils/utils';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CallbackRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

function CallbackRequestModal({
  isOpen,
  onClose,
  onSuccess,
}: CallbackRequestModalProps) {
  const { t } = useTranslation();
  const {
    iframeData,
    datasForPdfIframe,
    updateDatasForPdfIframe,
    simulatorData,
    worksiteOperationType,
  } = useContext(WorksiteCreationContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const methods = useForm<ICallbackRequestData>({
    defaultValues: {
      phone: '',
      email: '',
      firstName: '',
      lastName: '',
      companyName: '',
      jobTitle: '',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data: ICallbackRequestData) => {
    setSubmitError(null);
    setIsSubmitting(true);
    let pdfObject = datasForPdfIframe;

    if (!pdfObject) {
      pdfObject = await generatePdfIframeUrl(
        iframeData as IIframe,
        simulatorData,
        worksiteOperationType,
        t
      );
      updateDatasForPdfIframe(pdfObject);
    }

    if (pdfObject) {
      const response = await sendCallbackRequest(iframeData?.id as number, {
        ...data,
        hashKey: pdfObject?.hashKey,
      });

      if (response) {
        methods.reset();
        if (onSuccess) {
          onSuccess();
        }
        onClose();
      }
    }
    setIsSubmitting(false);
  };

  // Si la modal n'est pas ouverte, ne rien afficher
  if (!isOpen) return null;

  return (
    <Modal
      title={t('callback.request_title')}
      setIsModal={() => onClose()}
      backgroundTransparent
      btnCancel
      textBtnConfirm={t('buttons.validate') || 'Valider'}
      isLoading={isSubmitting}
      formId="callback-request-form"
    >
      <div className="space-y-4 pt-4">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} id="callback-request-form">
            <div className="space-y-4">
              <InputText
                id="phone"
                label={t('callback.phone_number')}
                placeholder={placeholderExample(t).TELEPHONE}
                name="phone"
                required
                rules={{
                  pattern: {
                    value: phoneNumberRegex,
                    message: t('forms.phone.error_pattern'),
                  },
                }}
                typeNumber
                dataTestId="callback-phone"
              />
              <div className="grid grid-cols-2 gap-4">
                <InputText
                  id="firstName"
                  label={t('callback.first_name')}
                  placeholder={placeholderExample(t).FIRSTNAME}
                  name="firstName"
                  required
                  dataTestId="callback-firstname"
                />
                <InputText
                  id="lastName"
                  label={t('callback.last_name')}
                  placeholder={placeholderExample(t).LASTNAME}
                  name="lastName"
                  required
                  dataTestId="callback-lastname"
                />
              </div>

              <InputText
                id="email"
                label={t('callback.email')}
                placeholder={placeholderExample(t).EMAIL}
                name="email"
                required
                rules={{
                  pattern: {
                    value: emailRegex,
                    message: t('forms.email.error_pattern'),
                  },
                }}
                dataTestId="callback-email"
              />

              <InputText
                id="companyName"
                label={t('callback.company_name')}
                placeholder={placeholderExample(t).COMPANY_NAME}
                name="companyName"
                dataTestId="callback-company"
              />

              <InputText
                id="jobTitle"
                label={t('callback.job_title')}
                placeholder={placeholderExample(t).FUNCTION}
                name="jobTitle"
                dataTestId="callback-jobtitle"
              />

              {submitError && <TextError errorMessage={submitError} />}
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
}

CallbackRequestModal.defaultProps = {
  onSuccess: undefined,
};

export { CallbackRequestModal };
