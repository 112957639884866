import { IUserType } from '@models/auth/utils/types';

const ROLES = {
  PRODUCTION: 'production',
  GESTION: 'gestion',
  ADMIN: 'admin',
  MAR: 'mar',
};

const VIEW_ROLES = {
  1: ROLES.PRODUCTION,
  2: ROLES.GESTION,
  4: ROLES.ADMIN,
  5: ROLES.MAR,
};

const ENTITY_TYPES = {
  ADMINISTRATOR: 1,
  OBLIGE: 2,
  MANDATAIRE: 3,
  INSTALLATEUR: 4,
  RESEAUX_INSTALLATEUR: 5,
  DELEGATAIRE: 6,
  SOUS_TRAITANT: 7,
  AMO: 8,
  APPORTEUR_AFFAIRES: 9,
  BENEFICIAIRE: 10,
  // MANDATAIRE_NON_DEPOSANT: 11,
  MAR: 11,
  AUDITOR: 12,
  BUREAUX_CONTROLE: 13,
};

export const groupDelegataireOblige = [
  ENTITY_TYPES.DELEGATAIRE,
  ENTITY_TYPES.OBLIGE,
];
export const groupMandataire = [ENTITY_TYPES.MANDATAIRE];
export const groupInstallateurAMO = [
  ENTITY_TYPES.INSTALLATEUR,
  ENTITY_TYPES.AMO,
];

const isInstaller = (user: IUserType | null) => {
  return user && user.entity_type === ENTITY_TYPES.INSTALLATEUR;
};

const isMandatory = (user: IUserType | null) => {
  return user && user.entity_type === ENTITY_TYPES.MANDATAIRE;
};

const isDelegatory = (user: IUserType | null) => {
  return user && user.entity_type === ENTITY_TYPES.DELEGATAIRE;
};

const isOblige = (user: IUserType | null) => {
  return user && user.entity_type === ENTITY_TYPES.OBLIGE;
};

const isAmo = (user: IUserType | null) => {
  return user && user.entity_type === ENTITY_TYPES.AMO;
};

const isAdministrator = (user: IUserType | null) => {
  return user && user.entity_type === ENTITY_TYPES.ADMINISTRATOR;
};

export {
  ROLES,
  VIEW_ROLES,
  isInstaller,
  isMandatory,
  isDelegatory,
  isOblige,
  isAmo,
  isAdministrator,
  ENTITY_TYPES,
};
