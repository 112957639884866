import { useContext, useEffect, useState } from 'react';
import { getMaterialBrandsList } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import BrandSelector from '@models/worksiteCreation/components/createWorksite/material/BrandSelector';
import {
  IArrayInput,
  IBrandMaterials,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import {
  initialMark,
  initialMaterial,
} from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { IMaterial } from '@models/worksiteCreation/utils/types/worksitesType';
import ProductSelector from '@models/worksiteCreation/components/createWorksite/material/ProductSelector';
import { useFormContext } from 'react-hook-form';

interface IElementMaterialInArrayProps {
  element: IArrayInput;
  elementKey: string;
  operationCode: string;
  onChangeForm: () => void;
}

function ElementMaterialInArray({
  element,
  elementKey,
  operationCode,
  onChangeForm,
}: IElementMaterialInArrayProps) {
  const { readOnly } = useContext(WorksiteCreationContext);
  const { watch, setValue } = useFormContext();
  const fieldValue = watch(elementKey);

  const [listMaterials, setListMaterials] = useState<IMaterial[]>([
    initialMaterial,
  ]);
  const [listBrands, setListBrands] = useState<{ name: string; id: number }[]>(
    []
  );
  const [brandActive, setBrandActive] = useState<IBrandMaterials>({
    name: '',
    id: 0,
  });
  const [materialActive, setMaterialActive] =
    useState<IMaterial>(initialMaterial);
  const [isLoadingBrands, setIsLoadingBrands] = useState<boolean>(false);
  const [resetSignal, setResetSignal] = useState<number>(0);

  const handleBrandSelected = (brand: IBrandMaterials) => {
    setBrandActive(brand);
    onChangeForm();
  };

  const handleProductSelected = (material: IMaterial) => {
    const removeEmptyStrings = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map(removeEmptyStrings);
      }
      if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          const cleanedValue = removeEmptyStrings(value);
          if (cleanedValue !== '' && cleanedValue !== undefined) {
            acc[key] = cleanedValue;
          }
          return acc;
        }, {} as any);
      }
      return obj;
    };

    const materialWithoutEmptyValues = removeEmptyStrings(material);
    setMaterialActive(materialWithoutEmptyValues);
    setValue(elementKey, {
      details: {
        ...materialWithoutEmptyValues,
        unite: element?.unit, // nom de l'appareil auquel est rattaché la marques et référence
      },
    });
    onChangeForm();
  };

  const resetBrands = () => {
    setResetSignal((prev) => prev + 1);
    setBrandActive(initialMark);
    setListMaterials([initialMaterial]);
    onChangeForm();
  };

  useEffect(() => {
    if (materialActive.id === 0) {
      setValue(elementKey, '');
    }
  }, [materialActive]);

  useEffect(() => {
    if (!readOnly) {
      getMaterialBrandsList(null, setListBrands, setIsLoadingBrands);
    }
  }, [elementKey]);

  useEffect(() => {
    if (!fieldValue && fieldValue !== '') {
      setValue(elementKey, '');
    }
  }, []);

  return (
    <div className="flex flex-col w-full space-y-[.5rem]">
      <div className="text-textGrey text-[.75rem] leading-[.75rem] my-1">
        {element.label}
      </div>
      <div className="flex gap-4">
        <BrandSelector
          listBrands={listBrands}
          isLoadingBrands={isLoadingBrands}
          resetBrands={resetBrands}
          onBrandSelected={handleBrandSelected}
        />
        <ProductSelector
          brandActive={brandActive}
          operationCode={operationCode}
          setListMaterials={setListMaterials}
          onProductSelected={handleProductSelected}
          listMaterials={listMaterials}
          setMaterialActive={setMaterialActive}
          searchInternal={false}
          resetSignal={resetSignal}
        />
      </div>
    </div>
  );
}

export default ElementMaterialInArray;
