import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import OneEntityLine from '@components/navigation/sidebar/SwitchRoleAndEntity/OneEntityLine';
import { GlobalContext } from '@context/globalContext';
import { switchEntity } from '@models/users/apiRequests/userRequests';
import { IPartnerView } from '@models/partners/utils/types/partnersType';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { blueOpx } from '@assets/color';
import { DASHBOARD_ROUTES } from '@utils/routesUrls';
import { useNavigate } from 'react-router-dom';

interface ISwitchEntityModalProps {
  setSwitchEntityModalOpen: Dispatch<SetStateAction<boolean>>;
}

function SwitchEntityModal({
  setSwitchEntityModalOpen,
}: ISwitchEntityModalProps) {
  const { t } = useTranslation();
  const { userView, listUserViews, updateUserView } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSwitchEntity = async (entityTypeId: number) => {
    setIsLoading(true);
    const switchSuccess = await switchEntity(entityTypeId);
    if (switchSuccess) {
      const userViewSwitched = listUserViews?.find(
        (entity) => entity.entity_type_id === entityTypeId
      );
      if (userViewSwitched) {
        updateUserView(userViewSwitched);
      }
    }
    setIsLoading(false);
    setSwitchEntityModalOpen(false);
    navigate(DASHBOARD_ROUTES.DASHBOARD);
  };

  const uniqueEntityViews = (listUserViews ?? []).reduce<IPartnerView[]>(
    (acc, entityView) => {
      const isCurrent =
        userView?.vue === entityView.vue &&
        userView?.entity_type_id === entityView.entity_type_id;
      const alreadyIncluded = acc.some(
        (item) => item.entity_type_id === entityView.entity_type_id
      );

      if (isCurrent) {
        // S'assurer que l'entité actuelle soit toujours incluse et en premier
        return [
          entityView,
          ...acc.filter(
            (item) => item.entity_type_id !== entityView.entity_type_id
          ),
        ];
      }
      if (
        !alreadyIncluded &&
        userView?.entity_type_id !== entityView.entity_type_id
      ) {
        // Ajouter l'entité si elle n'a pas déjà été incluse et n'a pas le même entity_type_id que userView
        acc.push(entityView);
      }

      return acc;
    },
    []
  );

  return (
    <Modal
      title={t('roles.switch_profile')}
      setIsModal={setSwitchEntityModalOpen}
      btnCancel
      backgroundTransparent
    >
      <div className="relative">
        {isLoading && (
          <LoaderSkeleton
            backgroundColor={blueOpx}
            height=".2rem"
            addClass="absolute top-0"
          />
        )}
        <div className="flex flex-col items-start space-y-4 pt-4 relative">
          {uniqueEntityViews?.map((entityView) => (
            <OneEntityLine
              key={entityView.entity_type_id}
              entityView={entityView}
              handleSwitchEntity={handleSwitchEntity}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default SwitchEntityModal;
