import { Modal } from '@components/Modal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { fileTypeEnum } from '@utils/enums';
import { UploadButton } from '@components/documents/UploadButton';
import { InputText } from '@components/atomic/inputs/InputText';
import { useForm, FormProvider } from 'react-hook-form';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { AddIcon } from '@assets/images/svgComponents';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { isBeforeDate } from '@utils/functions';
import {
  getCofracInfos,
  storeCofracInvoice,
} from '@models/cofrac/apiRequests/cofracRequests';
import { toAPIDateStr } from '@utils/format';
import { ICofracInvoiceInputs } from '@models/cofrac/utils/utils';

interface CofracInviceProps {
  cofrac: ICofracType | undefined;
  setCofrac: Dispatch<SetStateAction<ICofracType | undefined>>;
  setModal: Dispatch<SetStateAction<string>>;
}
function CofracInvoice({
  cofrac,
  setCofrac,
  setModal,
}: CofracInviceProps): JSX.Element {
  const { t } = useTranslation();

  const methods = useForm<ICofracInvoiceInputs>({
    defaultValues: {
      invoice_date: '',
      invoice_amount_ht: '',
      invoice_vat_rate: '',
      invoice_amount_ttc: '',
    },
  });
  const { handleSubmit, setValue, watch, register } = methods;
  const formDataValues = watch();

  const [isFormNotValid, setIsFormNotValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setIsLoading(true);

    const formData = new FormData();
    if (cofrac?.id) {
      formData.append('id', cofrac.id.toString());
    }
    formData.append('invoice', formDataValues.invoice);
    formData.append('invoice_date', formDataValues.invoice_date);
    formData.append('invoice_amount_ht', formDataValues.invoice_amount_ht);
    formData.append('invoice_vat_rate', formDataValues.invoice_vat_rate);
    formData.append('invoice_amount_ttc', formDataValues.invoice_amount_ttc);

    const updatedCofrac = await storeCofracInvoice(formData);

    setModal('');

    if (updatedCofrac) {
      getCofracInfos(updatedCofrac.id, setCofrac);
    }

    setIsLoading(false);
  };

  const docUploaded = () => {
    const file: File | null = formDataValues.invoice;
    const FileName =
      file && file.name ? file.name : t('cofrac.invoice.docs_placeholder');

    return (
      <div
        className={`flex justify-between flex-wrap items-center p-2 cursor-pointer rounded-default
          hover:bg-greyOpacity20 w-full border bg-white right-0 rounded-default p-2`}
      >
        <div className="text-textGrey">{FileName}</div>
        <div className="flex items-center gap-2">
          <div>{t('global.mandatory')}</div>
          <ColorCube
            color={blueOpx}
            numberOrIcon={<AddIcon />}
            size="2rem"
            opacity
            addClass="rounded"
          />
        </div>
      </div>
    );
  };

  // Vérifie si tous les champs sont remplis et si la date de facture est valide
  useEffect(() => {
    let dateNotOk = true;
    if (formDataValues.invoice_date !== '') {
      const cofracDate = cofrac ? cofrac?.cofrac_date.slice(0, 10) : '';
      dateNotOk = isBeforeDate(formDataValues.invoice_date, cofracDate);
    }

    const inputsAreNotEmpty =
      formDataValues.invoice_date.trim() !== '' &&
      formDataValues.invoice_amount_ht.trim() !== '' &&
      formDataValues.invoice_vat_rate.trim() !== '' &&
      formDataValues.invoice_amount_ttc.trim() !== '' &&
      formDataValues.invoice instanceof File;

    const notValid = dateNotOk || !inputsAreNotEmpty;

    setIsFormNotValid(notValid);
  }, [formDataValues, cofrac]);

  useEffect(() => {
    register('invoice');
  }, [register]);

  return (
    <FormProvider {...methods}>
      <Modal
        title={t('cofrac.invoice.title')}
        backgroundTransparent
        sidebarVisible
        buttonsPosition="bottom"
        onConfirmClick={handleSubmit(onSubmit)}
        btnCancel
        onClickCancel={() => setModal('')}
        textBtnConfirm={`${t('buttons.next')}`}
        btnConfirmDisabled={isFormNotValid}
        isLoading={isLoading}
      >
        <>
          <p className="my-5">{t('cofrac.invoice.docs')}</p>
          <div className="w-full grid grid-cols-2 gap-3 my-5">
            <UploadButton
              name="invoice"
              onUpload={(file: File) => setValue('invoice', file)}
              icon={docUploaded()}
              fileType={fileTypeEnum.COFRAC_FACTURE}
            />
            <DatePicker
              defaultDate=""
              onChangeDate={(e) => setValue('invoice_date', toAPIDateStr(e))}
              placeholder="JJ/MM/AAAA"
              error={
                formDataValues.invoice_date !== '' &&
                isBeforeDate(
                  formDataValues.invoice_date,
                  cofrac ? cofrac?.cofrac_date.slice(0, 10) : ''
                )
              }
              textError={`${t('cofrac.form.date_ok_error')}`}
              required
            />
          </div>
          <div className="w-full grid grid-cols-3 gap-3 my-5">
            <InputText
              typeNumber
              id="invoice_amount_ht"
              name="invoice_amount_ht"
              label={t('cofrac.invoice.amount_ht')}
              placeholder="X XXX,X €"
              data-test-id="invoice_amount_ht"
              required
            />
            <InputText
              typeNumber
              id="invoice_vat_rate"
              name="invoice_vat_rate"
              label={t('cofrac.invoice.vat_rate')}
              placeholder="XX,XX %"
              data-test-id="invoice_vat_rate"
              required
            />
            <InputText
              typeNumber
              id="invoice_amount_ttc"
              name="invoice_amount_ttc"
              label={t('cofrac.invoice.amount_ttc')}
              placeholder="X XXX,X €"
              data-test-id="invoice_amount_ttc"
              required
            />
          </div>
        </>
      </Modal>
    </FormProvider>
  );
}

export { CofracInvoice };
