import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';
import { ICertificationType } from '@models/certifications/utils/certificationsTypes';
import { getCertificationById } from '@models/certifications/apiRequests/certificationsRequests';
import { DocumentViewer } from '@components/documents/DocumentViewer';
import { initDocumentActive } from '@utils/initialState';
import { getCardInfosGeneralCertification } from '@models/certifications/utils/certificationsHelper';
import { useTranslation } from 'react-i18next';
import { CardInformations } from '@components/informations/CardInformations';
import { green, orange, red } from '@assets/color';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { DeleteCertificationModal } from '@models/certifications/components/DeleteCertificationModal';
import CertificationCreationModal from '@models/certifications/components/CertificationCreationModal';
import { isAdministrator, isInstaller } from '@utils/roles';
import { AuthContext } from '@context/authContext';
import { USERS_ROUTES } from '@utils/routesUrls';

function CertificationSheet() {
  const { id: certificationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { route, updateRoute, updateDocumentActive, globalEnum } =
    useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const { updateTitleHeader, updateDisplayBackButtonHeader } =
    useContext(HeaderContext);

  const [certification, setCertification] = useState<ICertificationType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const getCertificationData = async () => {
    if (certificationId) {
      const certificationData = await getCertificationById(
        Number(certificationId)
      );
      if (certificationData) {
        setCertification(certificationData);
        setIsLoading(false);
      } else {
        navigate(USERS_ROUTES.PROFILE);
      }
    }
  };

  const itemsInfoGeneral = useMemo(
    () =>
      certification
        ? getCardInfosGeneralCertification(t, certification, globalEnum)
        : [],
    [certification]
  );

  const certificationStatus: {
    label: string;
    color: string;
  } = useMemo(() => {
    const startDate = new Date(certification?.start_date || '');
    const endDate = new Date(certification?.end_date || '');
    const today = new Date();
    if (today > endDate) {
      return {
        label: t('certifications.expired') || '',
        color: red,
      };
    }
    if (today < startDate) {
      return { label: t('certifications.upcoming') || '', color: orange };
    }
    return { label: t('certifications.active') || '', color: green };
  }, [certification]);

  useEffect(() => {
    getCertificationData();
  }, []);

  useEffect(() => {
    if (route.older.length === 0) {
      updateRoute({
        to: route.to,
        older: [USERS_ROUTES.PROFILE],
      });
    }
    updateDisplayBackButtonHeader(true);
    updateTitleHeader('loading');
  }, []);

  useEffect(() => {
    if (certification) {
      updateTitleHeader(certification.name);
      updateDocumentActive({
        list: '',
        listAsTitle: false,
        document: certification.linked_file || initDocumentActive.document,
      });
    }
  }, [certification]);

  return (
    <div className="w-full pt-4">
      {(isInstaller(user) || isAdministrator(user)) && (
        <div className="w-full flex justify-end space-x-4 mb-4">
          <ButtonOpx
            label={t('buttons.update') || ''}
            onClick={() => setShowEditModal(true)}
            dataTestId="update_certification_button"
          />
          <ButtonOpx
            label={t('buttons.delete') || ''}
            type="secondary"
            color={red}
            onClick={() => setShowDeleteModal(true)}
            dataTestId="delete_certification_button"
          />
        </div>
      )}
      <div className="flex justify-between space-x-4">
        <div className="w-3/5">
          <CardInformations
            title={t('convention.general_information') || ''}
            data={{
              status: certificationStatus,
              rows: itemsInfoGeneral,
            }}
            loading={isLoading}
            dataTestId="informations_card"
          />
        </div>
        <div className="w-2/5">
          <DocumentViewer
            isLoading={isLoading}
            setIsLoading={() => ''}
            withoutHeader
          />
        </div>
      </div>
      {showDeleteModal && (
        <DeleteCertificationModal
          setShowModal={setShowDeleteModal}
          certificationData={certification}
        />
      )}
      {showEditModal && (
        <CertificationCreationModal
          setDisplayCreationModal={setShowEditModal}
          setCertification={setCertification}
          certificationToEdit={certification}
        />
      )}
    </div>
  );
}

export default CertificationSheet;
