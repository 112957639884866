import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { deleteControlContactField } from '@models/controlContact/apiRequests/controlContactRequests';

interface IModalDeleteControlContactFieldProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setShowParentModal: Dispatch<SetStateAction<boolean>>;
  fieldIdToEdit: number;
  getFields: () => void;
}

function ModalDeleteControlContactField({
  setShowModal,
  setShowParentModal,
  fieldIdToEdit,
  getFields,
}: IModalDeleteControlContactFieldProps) {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteField = async () => {
    setIsDeleting(true);
    const response = await deleteControlContactField(fieldIdToEdit as number);
    if (response) {
      setShowModal(false);
      getFields();
      setShowParentModal(false);
    }
    setIsDeleting(false);
  };

  return (
    <Modal
      title={t('control_contact.settings.delete_field_modal_title')}
      setIsModal={setShowModal}
      backgroundTransparent
      textBtnCancel={t('global.canceled') as string}
      btnCancel
      btnConfirmDisabled={isDeleting}
      textBtnConfirm={t('buttons.delete') as string}
      onConfirmClick={() => {
        deleteField();
      }}
      isLoading={isDeleting}
    >
      <p className="mt-2">
        {t('control_contact.settings.delete_field_modal_text')}
      </p>
    </Modal>
  );
}

export default ModalDeleteControlContactField;
