import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ENTITY_TYPES } from '@utils/roles';
import { GlobalContext } from '@context/globalContext';
import { SETTINGS_TABS, SETTINGS_TABS_DATA } from '../utils/utils';

interface ISettingsTabsNav {
  tabActive: string;
  setTabActive: Dispatch<SetStateAction<string>>;
}

function SettingsTabsNav({ tabActive, setTabActive }: ISettingsTabsNav) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userView } = useContext(GlobalContext);

  const isDelegataireOrObligeOrMandate =
    userView?.entity_type === ENTITY_TYPES.DELEGATAIRE ||
    userView?.entity_type === ENTITY_TYPES.OBLIGE ||
    userView?.entity_type === ENTITY_TYPES.MANDATAIRE;

  const isInstaller = userView?.entity_type === ENTITY_TYPES.INSTALLATEUR;

  const isDelegataireOrObligeOrMandateTabs = [
    SETTINGS_TABS.CHECKPOINTS,
    SETTINGS_TABS.WHITE_LABEL,
  ];
  const filteredTabs = SETTINGS_TABS_DATA.filter((tab) => {
    if (isDelegataireOrObligeOrMandateTabs.includes(tab.key)) {
      return isDelegataireOrObligeOrMandate;
    }
    switch (tab.key) {
      case SETTINGS_TABS.CONTACT_CONTROL:
      case SETTINGS_TABS.WHITE_LABEL:
        return isDelegataireOrObligeOrMandate;
      case SETTINGS_TABS.IFRAMES:
        return isInstaller || isDelegataireOrObligeOrMandate;
      default:
        return true;
    }
  });

  const handleTabClick = (key: string) => {
    setTabActive(key);
    navigate(`#${key}`, { replace: true });
  };

  return (
    <div className="flex flex-col min-w-[24.125rem] space-y-2">
      {filteredTabs.map((tab) => (
        <button
          key={tab.key}
          type="button"
          className={`text-left p-4 border border-borderGrey rounded-[.5rem] text-[1rem] leading-5 ${
            tabActive === tab.key ? 'bg-blueOpxOpacity10 text-primaryText' : ''
          }`}
          onClick={() => handleTabClick(tab.key)}
        >
          {t(tab.label)}
        </button>
      ))}
    </div>
  );
}

export default SettingsTabsNav;
