import { FileIcon } from '@assets/images/svgComponents';
import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Loader } from '@components/atomic/Loader';
import { InputText } from '@components/atomic/inputs/InputText';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';
import { getIframeData } from '@models/settings/apiRequests/settingsRequests';
import { IIframe } from '@models/settings/utils/settingsTypes';
import {
  SETTINGS_ROUTES,
  SETTINGS_ROUTES_WITH_ID,
  WORKSITES_ROUTES_WITH_ID,
} from '@utils/routesUrls';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

interface IDimensionsForm {
  width: string;
  height: string;
}

// Type d'option de dimensions
type DimensionOption = 'custom' | 'responsive';

function IframeCode() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { updateTitleHeader, updateDisplayBackButtonHeader } =
    useContext(HeaderContext);
  const { updateRoute, route } = useContext(GlobalContext);
  const [iframeData, setIframeData] = useState<IIframe | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [previewIsLoading, setPreviewIsLoading] = useState<boolean>(false);
  // 16/9
  const [appliedWidth, setAppliedWidth] = useState<string>('1200');
  const [appliedHeight, setAppliedHeight] = useState<string>('675');
  // Option de dimension sélectionnée (personnalisée ou responsive)
  const [dimensionOption, setDimensionOption] =
    useState<DimensionOption>('responsive');

  // Formulaire pour les dimensions
  const methods = useForm<IDimensionsForm>({
    defaultValues: {
      width: '1200',
      height: '675',
    },
  });

  const { handleSubmit, watch } = methods;
  const width = watch('width');
  const height = watch('height');

  // URL de base pour l'application
  const baseUrl = window.location.origin || 'https://votre-app.com';

  // Fonction pour obtenir le style de prévisualisation en fonction de l'option sélectionnée
  const getPreviewStyle = () => {
    if (dimensionOption === 'responsive') {
      return {
        width: '100%',
        aspectRatio: '16/9',
      };
    }
    return {
      width: `${appliedWidth}px`,
      height: `${appliedHeight}px`,
    };
  };

  // Code HTML pour l'iframe
  const getIframeHtml = () => {
    if (!iframeData) return '';

    if (dimensionOption === 'responsive') {
      return `<iframe 
  src="${baseUrl}${
        WORKSITES_ROUTES_WITH_ID(iframeData.slug as string)
          .IFRAME_SIMULATION_SLUG
      }" 
  width="100%" 
  height="100%" 
  style="aspect-ratio: 16/9;"
  frameborder="0"
  title="Simulateur ${iframeData.name}"
  allow="clipboard-write">
</iframe>`;
    }

    return `<iframe 
  src="${baseUrl}${
      WORKSITES_ROUTES_WITH_ID(iframeData.slug as string).IFRAME_SIMULATION_SLUG
    }" 
  width="${width}px" 
  height="${height}px" 
  frameborder="0"
  title="Simulateur ${iframeData.name}"
  allow="clipboard-write">
</iframe>`;
  };

  const iframeHtml = getIframeHtml();

  // Validation des dimensions
  const validateDimension = (value: string): boolean => {
    const num = parseInt(value, 10);
    return !Number.isNaN(num) && num > 0 && num < 2000;
  };

  // Fonction pour copier le code HTML
  const copyToClipboard = () => {
    navigator.clipboard.writeText(iframeHtml).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };

  // Naviguer vers la page de paramétrage
  const goToSettings = () => {
    if (id) {
      navigate(SETTINGS_ROUTES_WITH_ID(id).SETTINGS_IFRAME_SETTING);
    }
  };

  // Gérer le changement d'option de dimensions
  const handleDimensionOptionChange = (option: DimensionOption) => {
    setDimensionOption(option);
    setPreviewIsLoading(true);

    setTimeout(() => {
      setPreviewIsLoading(false);
    }, 500);
  };

  // Wrapper pour la conversion de type string vers DimensionOption
  const handleRadioSelection = (val: string) => {
    // Vérifier que la valeur est bien une DimensionOption valide
    if (val === 'responsive' || val === 'custom') {
      handleDimensionOptionChange(val as DimensionOption);
    }
  };

  // Appliquer les dimensions
  const onDimensionsSubmit = () => {
    if (validateDimension(width) && validateDimension(height)) {
      setPreviewIsLoading(true);
      // Appliquer les nouvelles dimensions
      setAppliedWidth(width);
      setAppliedHeight(height);
      // Simuler un chargement pour la prévisualisation
      setTimeout(() => {
        setPreviewIsLoading(false);
      }, 500);
    }
  };

  // Récupération des données de l'iframe
  useEffect(() => {
    const fetchIframeData = async () => {
      if (id) {
        setIsLoading(true);
        const data = await getIframeData(id);
        if (data) {
          setIframeData(data);
          updateTitleHeader(data.name);
        }
        setIsLoading(false);
      }
    };

    fetchIframeData();
  }, [id]);

  useEffect(() => {
    updateDisplayBackButtonHeader(true);
    if (route.older.length === 0) {
      updateRoute({
        ...route,
        older: [`${SETTINGS_ROUTES.SETTINGS}#iframes`],
      });
    }
  }, []);

  return (
    <div className="w-full mx-auto p-4">
      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <Loader />
        </div>
      ) : (
        <Card
          title={iframeData ? iframeData.name : 'Iframe'}
          actionButtons={
            <ButtonOpx
              label={
                t('settings.iframes.iframe_settings') ||
                "Paramétres de l'iframe"
              }
              onClick={goToSettings}
              type="primary"
            />
          }
        >
          <div>
            <div className="mb-6">
              <h2 className="text-lg font-semibold mb-2">
                {t('settings.iframes.iframe_code') || "Code de l'iframe"}
              </h2>
              <p className="text-sm text-gray-600 mb-4">
                {t('settings.iframes.copy_iframe_description') ||
                  "Copiez ce code pour intégrer l'iframe dans votre application web."}
              </p>

              {/* Bloc de code avec bouton de copie */}
              <div className="relative bg-gray-100 p-4 rounded-md overflow-x-auto text-sm mb-6">
                <pre className="whitespace-pre-wrap">
                  <code>{iframeHtml}</code>
                </pre>
                <div className="absolute top-2 right-2">
                  <ButtonOpx
                    label={
                      isCopied
                        ? t('settings.iframes.copied') || 'Copié'
                        : t('settings.iframes.copy_code') || 'Copier'
                    }
                    icon={<FileIcon />}
                    onClick={copyToClipboard}
                    small
                    type={isCopied ? 'secondary' : 'tab'}
                  />
                </div>
              </div>

              {/* Formulaire pour les dimensions */}
              <div className="mb-6">
                <h3 className="text-md font-semibold mb-3">
                  {t('settings.iframes.dimensions') || 'Dimensions'}
                </h3>

                <div className="flex flex-row space-x-4 mb-4">
                  <TextWithRadio
                    label={
                      t('settings.iframes.responsive_width') ||
                      'Largeur responsive (100%)'
                    }
                    value="responsive"
                    setSelectedValue={handleRadioSelection}
                    isChecked={dimensionOption === 'responsive'}
                    selectValue={dimensionOption}
                    subLabel={
                      t('settings.iframes.responsive_description') ||
                      "S'adapte à la largeur du conteneur avec un ratio 16:9"
                    }
                    labelInBold
                    addClass="flex-1"
                  />

                  <TextWithRadio
                    label={
                      t('settings.iframes.custom_dimensions') ||
                      'Dimensions personnalisées'
                    }
                    value="custom"
                    setSelectedValue={handleRadioSelection}
                    isChecked={dimensionOption === 'custom'}
                    selectValue={dimensionOption}
                    subLabel={
                      t('settings.iframes.custom_description') ||
                      'Définir une largeur et une hauteur spécifiques en pixels'
                    }
                    labelInBold
                    addClass="flex-1"
                  />
                </div>

                {dimensionOption === 'custom' && (
                  <FormProvider {...methods}>
                    <form
                      onSubmit={handleSubmit(onDimensionsSubmit)}
                      className="flex flex-wrap gap-4 items-end"
                    >
                      <div className="flex-1 min-w-[200px]">
                        <InputText
                          id="width"
                          name="width"
                          placeholder="800"
                          label={t('settings.iframes.width') || 'Largeur'}
                          required
                          typeNumber
                          allowZero={false}
                          minNumber={50}
                          maxNumber={2000}
                          error={!validateDimension(width)}
                          textError={
                            t('settings.iframes.dimension_error') ||
                            'Dimension invalide (entre 50 et 2000)'
                          }
                        />
                      </div>

                      <p className="text-textGrey mb-2">X</p>

                      <div className="flex-1 min-w-[200px]">
                        <InputText
                          id="height"
                          name="height"
                          placeholder="600"
                          label={t('settings.iframes.height') || 'Hauteur'}
                          required
                          typeNumber
                          allowZero={false}
                          minNumber={50}
                          maxNumber={2000}
                          error={!validateDimension(height)}
                          textError={
                            t('settings.iframes.dimension_error') ||
                            'Dimension invalide (entre 50 et 2000)'
                          }
                        />
                      </div>

                      <p className="text-textGrey mb-2">px</p>

                      <div className="flex-shrink-0">
                        <ButtonOpx
                          label={t('settings.iframes.apply') || 'Appliquer'}
                          type="primary"
                          onClick={handleSubmit(onDimensionsSubmit)}
                        />
                      </div>
                    </form>
                  </FormProvider>
                )}
              </div>

              {/* Prévisualisation de l'iframe */}
              <div>
                <h3 className="text-md font-semibold mb-3">
                  {t('settings.iframes.preview') || 'Prévisualisation'}
                </h3>

                <div className="border border-borderGrey rounded-default p-2 bg-white overflow-auto">
                  {previewIsLoading ? (
                    <div
                      className="flex justify-center items-center"
                      style={getPreviewStyle()}
                    >
                      <Loader />
                    </div>
                  ) : (
                    iframeData && (
                      <div className="iframe-preview-container">
                        <iframe
                          src={`${baseUrl}${
                            WORKSITES_ROUTES_WITH_ID(iframeData.slug as string)
                              .IFRAME_SIMULATION_SLUG
                          }`}
                          style={getPreviewStyle()}
                          frameBorder="0"
                          title={`Prévisualisation ${iframeData.name}`}
                          allow="clipboard-write"
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}

export default IframeCode;
