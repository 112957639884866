import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { Card } from '@components/Card';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { v4 } from 'uuid';
import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  AddIcon,
  CalendarIcon,
  DownloadIcon,
} from '@assets/images/svgComponents';
import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx, green, iconBlue, red } from '@assets/color';
import { dateToDDMMYYY } from '@utils/format';
import {
  COFRAC_MODAL,
  COFRAC_STATUS,
  DATE_MODAL,
} from '@models/cofrac/utils/cofracConstants';

interface CofracDetailsProps {
  data: ICofracType | undefined;
  setModal: Dispatch<SetStateAction<string>>;
}

function CofracDetails({ data, setModal }: CofracDetailsProps): JSX.Element {
  const { t } = useTranslation();

  const cofracResponseTitle = (): string => {
    if (data?.status === COFRAC_STATUS.SATISFAISANT) {
      return t('cofrac.ok');
    }
    if (data?.status === COFRAC_STATUS.INSATISFAISANT) {
      return t('cofrac.ko');
    }
    return t('cofrac.response');
  };

  const cofracResponseValue = (): string | null => {
    if (
      data?.status === COFRAC_STATUS.SATISFAISANT ||
      data?.status === COFRAC_STATUS.INSATISFAISANT
    ) {
      return data?.cofrac_date;
    }
    return null;
  };

  const dateInfos = useMemo(() => {
    return [
      {
        title: t('cofrac.sending_date'),
        value: data?.cofrac_date,
        icon: <CalendarIcon />,
        className: 'w-full',
        modal: DATE_MODAL.DATE,
      },
      {
        title: cofracResponseTitle(),
        icon: <CalendarIcon />,
        value: cofracResponseValue(),
        colorIcon: data?.status === COFRAC_STATUS.SATISFAISANT ? green : red,
        className: 'w-[49%] pt-4',
        modal: COFRAC_MODAL.OK,
      },
      {
        title: t('cofrac.invoice.title'),
        icon: <CalendarIcon />,
        value: data?.invoice_date,
        colorIcon: green,
        className: 'w-[49%] pt-4',
        modal: COFRAC_MODAL.INVOICE,
      },
    ];
  }, [data]);
  const docInfos = useMemo(() => {
    return [
      {
        title: t('cofrac.form.report_placeholder'),
        date: data?.report_date,
        url: data?.report_url,
        value: data?.linked_files?.report,
      },
      {
        title: t('cofrac.form.summary_placeholder'),
        date: data?.synthesis_date,
        url: data?.synthesis_url,
        value: data?.linked_files?.summary,
      },
      {
        title: t('cofrac.invoice.title'),
        date: data?.invoice_date,
        url: data?.invoice_url,
        value: data?.invoice_url !== null,
      },
    ];
  }, [data]);

  const getDateInfos = (created: boolean) => {
    return dateInfos.filter((elt) => (created ? elt.value : !elt.value));
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-[50%] md:pr-6 mb-[1.5rem]">
        <Card title={`${t('cofrac.validation')}`}>
          <div>
            <div>
              {getDateInfos(true).map((item) => (
                <div className="border border-solid border-transparent border-b-borderGrey pb-4">
                  <InfosWithIcon
                    key={v4()}
                    loading={false}
                    infos={[
                      {
                        title: item.title,
                        subtitle: item.value
                          ? dateToDDMMYYY(String(item.value).slice(0, 10))
                          : '-',
                      },
                    ]}
                    icon={item.icon}
                    addClass={item.className}
                    colorIcon={item.colorIcon}
                    dataTestId="infos_with_icon"
                  />
                </div>
              ))}
            </div>
            {getDateInfos(true).length < 3 && (
              <div className="pt-4">
                {getDateInfos(false).map((item) => (
                  <div
                    key={v4()}
                    className="flex justify-between flex-wrap items-center p-2 cursor-pointer rounded-default hover:bg-greyOpacity20"
                    onClick={() => setModal(item.modal)}
                    aria-hidden="true"
                  >
                    <p>{item.title}</p>
                    <ColorCube
                      color={blueOpx}
                      numberOrIcon={<AddIcon />}
                      size="2rem"
                      opacity
                      addClass="rounded"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Card>
      </div>
      {data && data.linked_files && data.report_date && data.synthesis_date && (
        <div className="w-full md:w-[50%] md:pr-6 mb-[1.5rem]">
          <Card title={`${t('cofrac.docs')}`}>
            <div className="flex flex-col gap-3">
              {docInfos.map(
                (doc) =>
                  doc.value && (
                    <div key={v4()}>
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="font-medium">{doc.title}</p>
                          <div className="text-textGrey">
                            {dateToDDMMYYY(doc.date)}
                          </div>
                        </div>
                        <div />
                        <a
                          href={String(doc.url)}
                          download
                          target="_blank"
                          rel="noreferrer"
                          data-test-id="download_button"
                        >
                          <ColorCube
                            size="1.5rem"
                            numberOrIcon={<DownloadIcon />}
                            color={iconBlue}
                            opacity
                          />
                        </a>
                      </div>
                    </div>
                  )
              )}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}

export { CofracDetails };
