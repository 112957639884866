import { InputText } from '@components/atomic/inputs/InputText';
import {
  IGraph,
  IScopUnit,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import {
  useEffect,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { getMaterialBrandsList } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { OneOperationMaterial } from '@models/worksiteCreation/components/createWorksite/material/OneOperationMaterial';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
// import { OneOperationMaterial } from '@models/worksiteCreation/components/createWorksite/material/OneOperationMaterial';

interface IScopFormProps {
  graph: IGraph;
  setErrorCalculPrimeMessage?: Dispatch<SetStateAction<string>>;
}

function ScopForm({ graph, setErrorCalculPrimeMessage }: IScopFormProps) {
  const { t } = useTranslation();
  const {
    updateSimulatorDataOperation,
    simulatorData,
    readOnly,
    listOperations,
  } = useContext(WorksiteCreationContext);
  const [numberUnits, setNumberUnits] = useState<number>(1);
  const [unitsData, setUnitsData] = useState<IScopUnit[]>([
    {
      scop: '',
      power: '',
      name: '',
      surface: '',
      markMaterial: '',
      details: null,
      manual: false,
    },
  ]);

  const initialMaterial = { mark: '', reference: '' };

  const [listBrands, setListBrands] = useState<{ name: string; id: number }[]>(
    []
  );
  const [isLoadingBrands, setIsLoadingBrands] = useState<boolean>(false);
  const [initialMaterialValues, setInitialMaterialValues] = useState<
    {
      mark: string;
      reference: string;
    }[]
  >([initialMaterial]);

  useEffect(() => {
    if (!readOnly) {
      getMaterialBrandsList(null, setListBrands, setIsLoadingBrands);
    }
  }, []);

  useEffect(() => {
    const updatedUnits = [];
    for (let i = 0; i < numberUnits; i += 1) {
      updatedUnits.push({
        scop: '',
        power: '',
        name: '',
        surface: '',
        markMaterial: '',
        details: null,
        manual: false,
      });
    }
    setUnitsData(updatedUnits.filter((unit) => unit !== undefined));
  }, [numberUnits]);

  useEffect(() => {
    let validScop = true;

    unitsData.forEach((unit) => {
      Object.values(unit).forEach((val) => {
        if (val === '') validScop = false;
      });
    });

    if (validScop) simulatorData['general.scop'] = unitsData;
  }, [unitsData]);

  useEffect(() => {
    if (simulatorData['general.scop']) {
      setUnitsData(simulatorData['general.scop']);

      const initialValues = simulatorData['general.scop'].map(
        (unit: IScopUnit) => {
          const parts = unit.markMaterial.split(' - ');
          const mark = parts[parts.length - 1] || '';
          return { mark, reference: parts[0] || '' };
        }
      );

      setInitialMaterialValues(initialValues);
    }
  }, []);

  useEffect(() => {
    /* const newArrayUnitsData = unitsData.map((unit) => {
      const { markMaterial, ...rest } = unit;
      return rest;
    }); */

    updateSimulatorDataOperation((prevState: any) => {
      const dataArray = Array.isArray(prevState) ? [...prevState] : [prevState];
      return dataArray.map((elt) => {
        if (elt['operation.id'] === 'bar-th-129') {
          return { ...elt, [graph.key]: unitsData };
        }
        return elt;
      });
    });

    if (setErrorCalculPrimeMessage) {
      if (
        unitsData.reduce((acc, unity) => acc + Number(unity.surface), 0) >
        simulatorData['general.surface']
      ) {
        setErrorCalculPrimeMessage(
          ` ${t('worksite_creation.simulation.scop_unit.surface_max_error')}`
        );
      } else {
        setErrorCalculPrimeMessage('');
      }
    }
  }, [unitsData]);

  return (
    <div className="space-y-[.5rem] flex flex-col pt-[1rem]">
      <p>{graph.name}</p>
      <InputText
        typeNumber
        placeholder={t('worksite_creation.simulation.scop_unit.number_units')}
        onChange={(e) => {
          const lastChar = typeof e === 'string' ? e.charAt(e.length - 1) : '1';
          setNumberUnits(
            Number(lastChar) >= 1 && Number(lastChar) <= 5
              ? Number(lastChar)
              : numberUnits
          );
        }}
        id="number_units"
        name="number_units"
        value={numberUnits.toString()}
        valueInitialInput={numberUnits.toString()}
        disabled={readOnly}
        addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
      />
      <div>
        {unitsData.map((unit, index) => {
          return (
            <div
              className="flex flex-col space-y-[.5rem]"
              key={`unit-${index + 1}`}
            >
              <p className="pt-[1rem]">Pièce numéro {index + 1}</p>
              <InputText
                placeholder={t(
                  'worksite_creation.simulation.scop_unit.name_piece'
                )}
                onChange={(e) => {
                  const updatedUnits = [...unitsData];
                  updatedUnits[index] = { ...unit, name: e.toString() };
                  setUnitsData(updatedUnits);
                }}
                id={`name_unit${index + 1}`}
                name={`name_unit${index + 1}`}
                value={unit.name}
                disabled={readOnly}
                addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
              />
              <InputText
                typeNumber
                placeholder={t(
                  'worksite_creation.simulation.scop_unit.surface'
                )}
                onChange={(e) => {
                  const updatedUnits = [...unitsData];
                  updatedUnits[index] = { ...unit, surface: e.toString() };
                  setUnitsData(updatedUnits);
                }}
                error={
                  unitsData.reduce(
                    (acc, unity) => acc + Number(unity.surface),
                    0
                  ) > simulatorData['general.surface']
                }
                id={`surface_unit${index + 1}`}
                name={`surface_unit${index + 1}`}
                value={unit.surface}
                disabled={readOnly}
                addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
              />
              <OneOperationMaterial
                initialValues={initialMaterialValues[index]}
                listBrands={listBrands}
                isLoadingBrands={isLoadingBrands}
                worksiteOperation={{
                  code: 'BAR-TH-129',
                  operation: listOperations.find(
                    (op) => op.code === 'BAR-TH-129'
                  ) as IOperation,
                }}
                unitIndex={index}
                setUnitsData={setUnitsData}
                manualScop={unitsData[index].manual}
              />
              <p className="text-textGrey text-sm ml-[.75rem]">
                {t('worksite_creation.simulation.scop_unit.scop')} : {unit.scop}
              </p>
              <p className="text-textGrey text-sm ml-[.75rem]">
                {t('worksite_creation.simulation.scop_unit.power')} :{' '}
                {unit.power}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ScopForm;

ScopForm.defaultProps = {
  setErrorCalculPrimeMessage: undefined,
};
