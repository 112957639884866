import { blueOpx, greyOpacity20, textGrey } from '@assets/color';
import { AddIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { Tag } from '@components/atomic/Tag';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { GlobalContext } from '@context/globalContext';
import { OperationPriceInputs } from '@models/contractCreation/components/steps/operationPrices/OperationPriceInputs';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import {
  IContractOperationPrice,
  PriceType,
} from '@models/contractCreation/utils/contractCreationTypes';
import {
  BeneficiaryTypes,
  ContractTypes,
  OperationPriceTypes,
  PRICE_TYPE,
} from '@models/contractCreation/utils/enums';
import {
  convertUnitPricesToFixPrices,
  getOperationLabel,
} from '@models/contractCreation/utils/functions';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { IOperation } from '@models/partners/utils/types/partnerWorksiteType';
import { INCENTIVE_TYPE } from '@models/worksiteCreation/utils/enums';
import { formatWord } from '@utils/format';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AddOperationPriceProps {
  operations: IOperationType[];
  isLoading: boolean;
  index?: number;
  isConvention: boolean;
}

function AddOperationPrice({
  operations,
  isLoading,
  index,
  isConvention,
}: AddOperationPriceProps) {
  const { t } = useTranslation();
  const {
    beneficiaryType,
    operationPrices,
    updateOperationPrices,
    contractType,
    incentiveType,
  } = useContext(ContractCreationContext);

  const {
    globalEnum: { operation_price_type },
  } = useContext(GlobalContext);

  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [closeDropdown, setCloseDropdown] = useState<boolean>(true);
  const [operationPriceType, setOperationPriceType] = useState<number>(
    OperationPriceTypes.CLASSIQUE_PRECAIRE
  );
  const [withCdp, setWithCdp] = useState<boolean>(false);
  const [withMinimumAmount, setWithMinimumAmount] = useState<boolean>(false);
  const [withB2b, setWithB2b] = useState<boolean>(false);
  const [fixCdp, setFixCdp] = useState<boolean>(false);
  const [
    loadingOnCangeOperationPriceType,
    setLoadingOnCangeOperationPriceType,
  ] = useState<boolean>(false);

  const [priceValue, setPriceValue] = useState<
    IContractOperationPrice | undefined
  >();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const [differentIncentivePrice, setDifferentIncentivePrice] = useState(false);

  const {
    formState: { errors, isValid },
    reset,
    resetField,
    watch,
  } = useFormContext();

  const operationsAlreadyAdded = operationPrices.map((op) => op.operation_id);
  const operationsWithoutAlreadyAdded = operations.filter(
    (op) => !operationsAlreadyAdded.includes(op.id)
  );

  const incentiveName =
    incentiveType === INCENTIVE_TYPE.INDIRECT ? 'indirect' : 'direct';

  const incentiveArrayData: ('direct' | 'indirect')[] =
    isConvention &&
    incentiveType === INCENTIVE_TYPE.MIXED &&
    differentIncentivePrice
      ? ['direct', 'indirect']
      : [incentiveName];

  const hasCdp = useMemo(() => {
    return selectedIds.some((id) => {
      const operation = operations.find((o) => o.id === id);
      return operation && operation.has_cdp;
    });
  }, [selectedIds, operations, differentIncentivePrice]);

  const priceTypeValues: string[] = useMemo(() => {
    const values = Object.entries(operation_price_type).map((val) => val);

    const selectedOperations = operations.filter((op) =>
      selectedIds.includes(op.id)
    );

    const haveB2b = operations
      .filter((op) => selectedIds.includes(op.id))
      .some((op) => !op.code.toLowerCase().includes('bar-'));

    setWithB2b(haveB2b);

    const onlyB2b =
      selectedOperations.length > 0 &&
      !selectedOperations.some((op) => op.code.toLowerCase().includes('bar-'));

    if (!isConvention && onlyB2b) setWithB2b(false);

    let priceTypes = values;

    if (beneficiaryType === BeneficiaryTypes.ENTITY) {
      priceTypes = priceTypes.filter(
        (p) => Number(p[0]) !== OperationPriceTypes.INCOMES
      );
    }

    if (onlyB2b && isConvention) {
      setOperationPriceType(OperationPriceTypes.UNIQUE);
      priceTypes = priceTypes.filter(
        (p) => Number(p[0]) === OperationPriceTypes.UNIQUE
      );
    }

    return priceTypes.map((val) => val[1]);
  }, [operations, selectedIds]);

  const removeKey = (
    obj: { [x: string]: string | number | IOperation },
    value: string
  ) => {
    const newObj = { ...obj };
    Object.keys(obj).forEach((key) => {
      if (key.includes(value)) {
        delete newObj[key];
      }
    });

    return newObj;
  };

  const handleOnClick = () => {
    const noError = Object.keys(errors).length < 1;
    if (isValid && noError) {
      setCloseDropdown(true);
      setFixCdp(false);
      setWithCdp(false);
      if (priceValue) {
        const formattedPriceValue: {
          [x: string]: string | number | IOperation;
        } = {};

        Object.entries(priceValue).forEach((entry) => {
          const key = entry[0];
          let value = entry[1];

          const isPrice =
            key.includes('_pu_') ||
            key.includes('_pf_') ||
            key.includes('prix') ||
            key.includes('_amount_');

          if (isPrice) {
            value = Number(String(entry[1]).replace(',', '.'));
          }

          formattedPriceValue[key] = value;
        });

        const operationsToAdd = selectedIds.map((id) => {
          const operation = operations.find((o) => o.id === id);

          let opPrice = { ...formattedPriceValue };

          if (!operation?.has_cdp) {
            opPrice = removeKey(opPrice, '_cdp');
          }

          if (!operation?.code.toLowerCase().includes('bar-')) {
            if (isConvention) {
              Object.entries(opPrice).forEach(([key, val]) => {
                if (key.includes(`_${PRICE_TYPE.CLASSIQUE}`)) {
                  const uniqueKey = key.replace(
                    /_classique/g,
                    `_${PRICE_TYPE.UNIQUE}`
                  );
                  opPrice[uniqueKey] = val;
                }

                opPrice = removeKey(opPrice, `_${PRICE_TYPE.CLASSIQUE}`);
                opPrice = removeKey(opPrice, `_${PRICE_TYPE.INTERMEDIAIRE}`);
                opPrice = removeKey(opPrice, `_${PRICE_TYPE.MODESTE}`);
              });
            }

            opPrice = removeKey(opPrice, `_${PRICE_TYPE.PRECAIRE}`);
          }

          const pricesToSend = opPrice as IContractOperationPrice;

          return {
            ...pricesToSend,
            operation_id: id,
            operation,
            label: getOperationLabel(operation),
          };
        });

        updateOperationPrices([...operationPrices, ...operationsToAdd]);

        setPriceValue(undefined);
        setSelectedIds([]);
        setInputValue('');
        reset();
      }
    }
  };

  const chooseOperationPriceType = (label: string) => {
    setLoadingOnCangeOperationPriceType(true);
    setPriceValue({
      operation_id: priceValue?.operation_id || 0,
      label: priceValue?.label || '',
      operation: priceValue?.operation || undefined,
    });
    setWithMinimumAmount(false);
    setWithCdp(false);
    setFixCdp(false);
    reset();

    const value = Object.entries(operation_price_type).find(
      (val) => val[1] === label
    );

    if (value && Number(value[0]) !== operationPriceType) {
      setOperationPriceType(Number(value[0]));
    }
    setTimeout(() => {
      setLoadingOnCangeOperationPriceType(false);
    }, 200);
  };

  const addAllOperations = () => {
    if (
      operationsWithoutAlreadyAdded.every((o) => selectedIds.includes(o.id))
    ) {
      setPriceValue(undefined);
      setSelectedIds([]);
      reset();
    } else {
      setSelectedIds(operations.map((o) => o.id));
      setPriceValue((prevState) => ({
        ...prevState,
        operation_id: 0,
        label: '',
        operation_price_type: operationPriceType,
      }));
    }
  };

  const onSelectOperation = (op: IOperationType) => {
    const opValues = {
      operation_id: op.id,
      label: getOperationLabel(op),
      operation: op,
    };

    setPriceValue(priceValue ? { ...priceValue, ...opValues } : opValues);

    if (!selectedIds.includes(op.id)) {
      setSelectedIds([...selectedIds, op.id]);
    } else {
      const newIds = selectedIds.filter((id) => id !== op.id);
      if (newIds.length < 1) {
        setPriceValue(undefined);
        reset();
      }
      setSelectedIds(newIds);
    }
  };

  useEffect(() => {
    if (priceValue) {
      setPriceValue({
        ...priceValue,
        operation_price_type: operationPriceType,
      });
    } else {
      setPriceValue({
        operation_id: 0,
        label: '',
        operation_price_type: operationPriceType,
      });
    }
  }, [operationPriceType]);

  useEffect(() => {
    convertUnitPricesToFixPrices(priceValue, setPriceValue, fixCdp);
  }, [fixCdp]);

  useEffect(() => {
    if (selectedIds.length === 0) {
      setWithMinimumAmount(false);
      setWithCdp(false);
      setFixCdp(false);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (!differentIncentivePrice && priceValue) {
      let newValue = { ...priceValue };
      Object.entries(priceValue).forEach(([key, value]) => {
        if (key.startsWith('direct') && value) {
          const indirectKey = key.replace('direct', 'indirect') as PriceType;
          newValue = { ...newValue, [indirectKey]: value };
        }
      });

      setPriceValue(newValue);

      Object.keys(watch()).forEach((key) => {
        if (key.includes('indirect')) resetField(key);
      });
    }
  }, [differentIncentivePrice]);

  const operationsSelectedTags = useMemo(() => {
    return (
      <div className="flex my-3 gap-3 flex-wrap w-full">
        {operations
          .filter((op) => selectedIds.includes(op.id))
          .map((op) => (
            <Tag
              color={textGrey}
              label={op.code}
              key={`tag_${op.id}`}
              onCancel={() => {
                const newIds = selectedIds.filter((id) => id !== op.id);
                setSelectedIds(newIds);
                if (newIds.length < 1) setPriceValue(undefined);
              }}
            />
          ))}
      </div>
    );
  }, [selectedIds, operations]);

  const operationsSelector = (
    <InputSelect
      label={`${t('partners.deposits.operation_maj')}`}
      placeholder={t('global.choose') || ''}
      addClass={` ${isConvention ? 'ms-1 me-3' : ''} w-full`}
      disabled={isLoading}
      callbackOnSearch={(e) => {
        setInputValue(e);
        setSearchValue(e);
        setCloseDropdown(false);
      }}
      isAutoComplete
      valueInput={inputValue}
      showClearButton={inputValue.length > 0}
      setCloseDropdown={setCloseDropdown}
      closeDropdown={closeDropdown}
      dataTestIdSelect="dropdown_operations"
      required
    >
      {!closeDropdown ? (
        <div
          className="flex flex-col items-center text-start w-full border-b border-b-borderGrey"
          id="dropdown"
        >
          {operations.length > 1 && (
            <button type="button" className="dropdown-item">
              <div
                className="w-full flex justify-between"
                aria-hidden
                onClick={(e) => {
                  e.stopPropagation();
                  addAllOperations();
                }}
              >
                <div>{t('contract.all_operations')}</div>
                <Checkbox
                  label=""
                  checked={operations.every((o) => selectedIds.includes(o.id))}
                />
              </div>
            </button>
          )}
          {operations
            .filter((o) =>
              formatWord(getOperationLabel(o)).includes(formatWord(searchValue))
            )
            .map((op, i) => (
              <button
                type="button"
                className="flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey border-l border-l-borderGrey"
                key={`one_operation_in_menu_${op.id}`}
                data-test-id={`operation_${i}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectOperation(op);
                }}
              >
                <p className="w-full">{getOperationLabel(op)} </p>
                <Checkbox
                  label=""
                  checked={selectedIds.some((id) => id === op.id)}
                />
              </button>
            ))}
        </div>
      ) : (
        <div />
      )}
    </InputSelect>
  );

  if (isLoading)
    return (
      <div className="flex w-[96%] items-center gap-3 mb-[1rem] min-h-[6rem]">
        <LoaderSkeleton height="3rem" />
        <LoaderSkeleton height="3rem" />
        <LoaderSkeleton height="3rem" />
      </div>
    );

  const addButton = (
    <ColorCube
      size="2.5rem"
      numberOrIcon={<AddIcon />}
      color={!isValid || selectedIds.length < 1 ? greyOpacity20 : blueOpx}
      addClass={`mt-4 ${!isValid ? '!cursor-not-allowed' : ''} ${
        !isConvention ? 'ms-3' : ''
      }`}
      onClick={handleOnClick}
      dataTestId="add_operation_btn"
    />
  );

  return (
    <div
      className={`${
        isConvention ? 'border border-t-1 rounded-default p-5' : ''
      }`}
    >
      <div className="flex w-full items-center">
        <div className="flex-col w-full items-center">
          <div className="w-full flex items-center justify-between md:mr-[0.5rem]">
            {operationsSelector}
            {addButton}
          </div>
          {isConvention && operationsSelectedTags}

          {isConvention && (
            <div className="mb-5 w-full">
              <InputSelect
                placeholder={
                  selectedIds.length < 1
                    ? operation_price_type[operationPriceType]
                    : ''
                }
                dataArrayString={priceTypeValues}
                onSelect={(value) => chooseOperationPriceType(value)}
                valueInput={operation_price_type[operationPriceType]}
                disabled={priceTypeValues.length < 2 || selectedIds.length < 1}
                dataTestIdOptions={`operation_price_type${index}`}
                dataTestIdSelect={`select_operation_price_type${index}`}
              />
            </div>
          )}
          {selectedIds.length > 0 && (
            <>
              {isConvention && incentiveType === INCENTIVE_TYPE.MIXED && (
                <div className="mb-5 w-full flex items-center gap-3">
                  <Checkbox
                    label=""
                    checked={differentIncentivePrice}
                    onCheck={() =>
                      setDifferentIncentivePrice((prevState) => !prevState)
                    }
                    disabled={selectedIds.length < 1}
                  />
                  <div
                    className={`${
                      selectedIds.length < 1 ? 'text-textGrey' : ''
                    }`}
                  >
                    {t('convention.different_incentive_price')}
                  </div>
                </div>
              )}
              {loadingOnCangeOperationPriceType ? (
                <LoaderSkeleton height="3rem" />
              ) : (
                incentiveArrayData.map((incentive) => (
                  <OperationPriceInputs
                    operationPriceType={operationPriceType}
                    action="add"
                    priceValue={priceValue}
                    setPriceValue={setPriceValue}
                    isLoading={isLoading}
                    isConvention={isConvention}
                    selectedIds={selectedIds}
                    operations={operations}
                    incentive={incentive}
                    differentIncentivePrice={differentIncentivePrice}
                    withCdp={withCdp}
                  />
                ))
              )}
            </>
          )}
        </div>
      </div>
      {isConvention && hasCdp && (
        <div className="flex items-center gap-3 my-3">
          <Checkbox
            label="withCdp"
            checked={withCdp}
            onCheck={() => setWithCdp((prevState) => !prevState)}
          />
          <div>{t('convention.with_cdp_prices')}</div>
        </div>
      )}
      {withCdp && (
        <>
          <InputSelect
            placeholder=""
            dataArrayString={[
              t('contract.unit_price'),
              t('contract.fix_price'),
            ]}
            onSelect={(value) => setFixCdp(value === t('contract.fix_price'))}
            valueInput={
              fixCdp ? t('contract.fix_price') : t('contract.unit_price')
            }
            addClass="my-5"
          />
          {incentiveArrayData.map((incentive) => (
            <OperationPriceInputs
              operationPriceType={operationPriceType}
              action="add"
              priceValue={priceValue}
              setPriceValue={setPriceValue}
              isLoading={isLoading}
              isConvention={isConvention}
              isCdp
              fixCdp={fixCdp}
              selectedIds={selectedIds}
              operations={operations}
              incentive={incentive}
              differentIncentivePrice={differentIncentivePrice}
              withCdp={withCdp}
            />
          ))}
        </>
      )}
      {contractType === ContractTypes.CONVENTION && selectedIds.length > 0 && (
        <div className="flex items-center gap-3 mb-5 mt-3">
          <Checkbox
            label="minimum amount"
            checked={withMinimumAmount}
            onCheck={() => {
              if (withMinimumAmount) {
                Object.values(PRICE_TYPE).forEach((priceType) => {
                  resetField(
                    `add_${incentiveName}_beneficiary_minimum_amount_${priceType}`
                  );
                });
              }
              setWithMinimumAmount((prevState) => !prevState);
            }}
          />
          <div>{t('convention.define_minimum_prices')}</div>
        </div>
      )}
      {isConvention &&
        selectedIds.length > 0 &&
        withMinimumAmount &&
        incentiveArrayData.map((incentive) => {
          const inputsData = [
            { display: true, isCdp: false },
            { display: withCdp, isCdp: true },
          ];

          return inputsData.map(
            (elt) =>
              elt.display && (
                <OperationPriceInputs
                  operationPriceType={operationPriceType}
                  action="add"
                  priceValue={priceValue}
                  setPriceValue={setPriceValue}
                  isLoading={isLoading}
                  isConvention
                  selectedIds={selectedIds}
                  operations={operations}
                  isMinimumAmount
                  incentive={incentive}
                  differentIncentivePrice={differentIncentivePrice}
                  isCdp={elt.isCdp}
                  fixCdp={elt.isCdp && fixCdp}
                  withCdp={withCdp}
                />
              )
          );
        })}

      {!isConvention && operationsSelectedTags}
      {withB2b && operationPriceType !== OperationPriceTypes.UNIQUE && (
        <div className="text-textGrey text-[.85rem] leading-3 mt-2">
          * {t(`contract.prices_concerned_message`)}
        </div>
      )}
    </div>
  );
}

AddOperationPrice.defaultProps = {
  index: undefined,
};

export { AddOperationPrice };
