import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { v4 } from 'uuid';

function LoaderCheckpoints() {
  return (
    <div className="flex flex-col gap-3">
      {Array.from({ length: 5 }).map(() => (
        <div
          key={v4()}
          className="bg-white w-full rounded-[.25rem] border border-borderGrey p-[1rem] h-[5rem]"
        >
          <div className="flex justify-between h-full">
            <LoaderDivSkeleton height="0.75rem" width="30rem" />
            <div className="flex h-full gap-3">
              <div className="flex flex-col justify-center items-center h-full">
                <div className="flex gap-2 h-full">
                  {Array.from({ length: 2 }).map(() => (
                    <div
                      key={v4()}
                      className="flex flex-col justify-center h-full"
                    >
                      <div className="flex flex-col gap-5 justify-center items-center">
                        <LoaderDivSkeleton height="0.5rem" width="1.75rem" />
                        <LoaderDivSkeleton
                          height="0.8rem"
                          width="0.8rem"
                          addClass="rounded-full"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export { LoaderCheckpoints };
