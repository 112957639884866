import {
  IBeneficiary,
  IBeneficiaryAddress,
} from '@models/beneficiaries/utils/beneficiariesType';
import { IIframe } from '@models/settings/utils/settingsTypes';
import {
  DataForPdfGenerationType,
  getIncomes,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import {
  HABITATION_ZONE,
  INCOMES,
  OWNER,
  StepsWorksiteCreationEnum,
  WORKSITE_AGE,
} from '@models/worksiteCreation/utils/enums';
import { IConvention } from '@models/worksiteCreation/utils/types/conventionsTypes';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { IWorkflowSidebar } from '@models/worksiteCreation/utils/types/sidebarTypes';
import {
  IGraph,
  IIncomes,
  ISimulatorData,
  ISimulatorDataOperation,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import {
  ITaxHouselod,
  IWorksite,
  IWorksiteAddress,
} from '@models/worksiteCreation/utils/types/worksitesType';
import {
  IWorksiteBeneficiary,
  IWorksiteOperation,
} from '@models/worksites/utils/worksitesTypes';
import { OperationTypeEnum } from '@utils/enums';
import { numFormatSpace } from '@utils/functions';
import { taxNoticeReferenceRegex, taxNumberRegex } from '@utils/regex';
import { addDays } from 'date-fns';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { FieldValues } from 'react-hook-form';
import { IDefaultObject } from '../../../types/globalTypes';

export const arrayAgeOptions = (t: TFunction) => [
  {
    label: t('worksite_creation.simulation.age.young'),
    value: WORKSITE_AGE.YOUNG,
  },
  {
    label: t('worksite_creation.simulation.age.between'),
    value: WORKSITE_AGE.BETWEEN,
  },
  { label: t('worksite_creation.simulation.age.old'), value: WORKSITE_AGE.OLD },
];

export const constructIncomesContentArray = (
  t: TFunction,
  incomes: number[]
) => [
  {
    title: t('worksite_creation.simulation.household_income.lower_than'),
    label: `${numFormatSpace(incomes[0])} €`,
    value: 1,
    legend: t('worksite_creation.simulation.incomes.low'),
    income: Number(incomes[0]) - 1,
  },
  {
    title: t('worksite_creation.simulation.household_income.between'),
    label: `${numFormatSpace(incomes[0])} € ${t('global.and')} ${numFormatSpace(
      incomes[1]
    )} €`,
    value: 2,
    legend: t('worksite_creation.simulation.incomes.modest'),
    income: Number(incomes[0]) + 1,
  },
  {
    title: t('worksite_creation.simulation.household_income.between'),
    label: `${numFormatSpace(incomes[1])} € ${t('global.and')} ${numFormatSpace(
      incomes[2]
    )} €`,
    value: 3,
    legend: t('worksite_creation.simulation.incomes.intermediate'),
    income: Number(incomes[1]) + 1,
  },
  {
    title: t('worksite_creation.simulation.household_income.higher_than'),
    label: `${numFormatSpace(incomes[2])} €`,
    value: 4,
    legend: t('worksite_creation.simulation.incomes.classic'),
    income: Number(incomes[2]) + 1,
  },
];

export const constructReference = (conventionReference: string, ts: string) => {
  let reference = '';

  if (/^\d{4}/.test(conventionReference) && conventionReference.length === 13) {
    // Le format de la conventionReference respecte le format attendu
    const conventionSuffix = conventionReference.slice(8);
    reference = `${conventionSuffix}${ts}`;
  } else {
    // Le format de la conventionReference ne respecte pas le format attendu
    const conventionPrefix = conventionReference.slice(0, 3).toUpperCase();
    reference = `${conventionPrefix}${ts}`;
  }

  return reference;
};

export const getFilteredAndSortedGraphs = (graphGeneral: IGraph[]) => {
  return graphGeneral
    .filter(
      (graph) =>
        ![
          'general.age',
          'general.energy',
          'general.activity',
          'general.worksiteDates',
          'general.habitationNumber',
        ].includes(graph.key)
    )
    .filter((graph) => {
      if (graph.childrens) {
        return graph.childrens.every(
          (child) => !child.key.includes('general.energy.')
        );
      }
      return graph;
    })
    .filter((graph) => graph.key !== '')
    .sort((graph) => {
      if (graph.childrens) {
        return -1;
      }
      if (graph.key === 'general.income') {
        return 1; // INCOMES EN DERNIER
      }
      return 0;
    });
};

export const getRenovGlobalData = (
  data: FieldValues,
  scenarioId: number,
  listOperations: IOperation[] | null,
  simulatorData: ISimulatorData,
  worksiteOperationType: number
) => {
  const subOperations =
    listOperations?.map((op) => ({
      id: op.id,
      code: op.code,
      description: op.description,
    })) || [];

  let operationData = {
    'operation.id': 'bar-th-164',
    'operation.scenario': scenarioId,
    'operation.suboperations': subOperations,
    'operation.totalCost': 0,
  };

  Object.entries(data).forEach((val) => {
    const value =
      val[0] === 'surface' ? String(val[1]).replace(',', '.') : Number(val[1]);
    operationData = {
      ...operationData,
      [`operation.${val[0]}`]: val[1] ? value : undefined,
    };
  });

  return {
    ...simulatorData,
    operations: [operationData],
    operationType: worksiteOperationType,
  };
};

export const onCheckOperation = (
  operation: IOperation,
  listOperationSelected: IOperation[] | null,
  listOperations: IOperation[],
  updateListOperationSelected: Dispatch<SetStateAction<IOperation[] | null>>,
  updateSimulatorDataOperation: Dispatch<
    SetStateAction<ISimulatorDataOperation[]>
  >
) => {
  const operationChecked = listOperations.filter(
    (el) => el.code === operation.code
  )[0];

  if (listOperationSelected !== undefined && listOperationSelected !== null) {
    const index = listOperationSelected.findIndex(
      (item) => item.id === operationChecked.id
    );
    if (index !== -1) {
      const updatedList = [...listOperationSelected];
      updatedList.splice(index, 1);
      updateListOperationSelected(updatedList);
      updateSimulatorDataOperation(
        updatedList.map((op) => ({ 'operation.id': op.code.toLowerCase() }))
      );
    } else {
      const updatedList = [...listOperationSelected, operation];
      updateListOperationSelected(updatedList);
    }
  }
};
export const getNumberConditionValue = (
  value: number,
  sign: string,
  conditionValue: number
): boolean => {
  switch (sign) {
    case '>':
      return value > conditionValue;
    case '>=':
      return value >= conditionValue;
    case '<':
      return value < conditionValue;
    case '<=':
      return value <= conditionValue;
    default:
      return false;
  }
};

export const incompatibleCategoryOperations = (
  operation: IOperation,
  listOperationSelected: IOperation[] | null
) => {
  if (listOperationSelected) {
    return listOperationSelected.some(
      (ope) =>
        ope.code.slice(0, 3) !== operation.code.slice(0, 3) &&
        ope.code !== operation.code
    );
  }
  return false;
};
export const removalToDisplay = (operations: IWorksiteOperation[]) => {
  const removal = operations.map((operation) => operation.replaced_energy);
  const removalWithoutNoneValues = removal.filter(
    (el) => el !== 'none' && el !== null
  );
  const otherRemoval = removalWithoutNoneValues.indexOf('other');

  if (removal.every((el) => el === null || el === 'none')) {
    return 'Aucune';
  }

  if (otherRemoval !== -1) {
    removalWithoutNoneValues[otherRemoval] = 'autre';
  }

  return `Une chaudière à ${removalWithoutNoneValues.join(', ')}`;
};
export const checkGeneralFields = (
  fieldsArray: string[],
  simulatorData: ISimulatorData
) => {
  let fields = fieldsArray.map((elt) => {
    if (elt === 'general.works') {
      return 'general.address';
    }
    if (!elt.startsWith('general.')) {
      return `general.${elt}`;
    }
    return elt;
  });

  if (simulatorData['general.energy.supplemental'] !== 'oui') {
    fields = fields.filter(
      (field) => field !== 'general.energy.supplementalType'
    );
  } else if (!fields.includes('general.energy.supplementalType')) {
    fields.push('general.energy.supplementalType');
  }

  const validFields = Object.entries(simulatorData).filter(
    (elt) => fields.includes(elt[0]) && elt[1] && elt[1] !== ''
  );
  let validFieldsLength = validFields.length;
  // 0 fait passer simulatorData['general.habitationNumberQpv'] ou simulatorData['general.habitationNumberContracted'] en tant que field non valide donc on force
  if (
    simulatorData['general.habitationNumberQpv'] === 0 ||
    simulatorData['general.habitationNumberContracted'] === 0
  ) {
    validFieldsLength += 1;
  }
  return validFieldsLength === fields.length;
};

export const getNextStep = (
  workflowSidebar: IWorkflowSidebar | undefined,
  step: number
) => {
  const actualStepIndex = workflowSidebar?.creation.findIndex(
    (elt) => elt.value === step
  );

  if (!workflowSidebar) return null;

  return workflowSidebar.creation[Number(actualStepIndex) + 1];
};

export const getBeneficiaryName = (
  beneficiary: IBeneficiary | IWorksiteBeneficiary,
  operationType?: number
) => {
  const beneficiaryName = `${beneficiary.firstname} ${beneficiary.lastname}`;

  if (operationType === 2 || !operationType)
    return beneficiary.company_name || beneficiaryName;

  return beneficiaryName;
};

export const getBeneficiaryInfo = (
  t: TFunction,
  beneficiary: IBeneficiary,
  operationType?: number
): string | IBeneficiaryAddress => {
  if (operationType === OperationTypeEnum.B2B || !operationType) {
    return `${t('partners.siret')}: ${beneficiary.siret || ''}, ${
      beneficiary.address.address
    } ${
      beneficiary.address.postal_code
    } ${beneficiary.address.city.toUpperCase()}`;
  }

  return beneficiary.address;
};

export const objectsAreSame = (
  initialObj: IDefaultObject,
  currentObj: IDefaultObject,
  filterKeys?: string[]
) => {
  let isSame = true;

  const filterObjKeys = (obj: IDefaultObject) => {
    if (filterKeys) {
      return Object.entries(obj).filter((elt) => filterKeys.includes(elt[0]));
    }

    return Object.entries(obj);
  };

  const initialArrayObj = filterObjKeys(initialObj);
  const currentArrayObj = filterObjKeys(currentObj);

  if (initialArrayObj.length !== currentArrayObj.length) return false;

  for (let i = 0; i < initialArrayObj.length; i += 1) {
    const elt = initialArrayObj[i];
    const key = String(elt[0]);
    const value = elt[1];

    if (String(currentObj[key]) !== String(value)) {
      isSame = false;
    }
  }

  return isSame;
};

export const arraysAreSame = (
  initialValues: IDefaultObject[],
  currentValues: IDefaultObject[],
  keyValue: string,
  filterKeys?: string[]
) => {
  if (!currentValues || !initialValues) return false;
  let isNoChange = true;
  if (currentValues.length !== initialValues.length) return false;

  for (let i = 0; i < initialValues.length; i += 1) {
    const op = initialValues[i];
    const operationId = op[keyValue];

    const currentOp = currentValues.find(
      (elt) => elt[keyValue] === operationId
    );

    if (!currentOp) return false;

    if (!objectsAreSame(op, currentOp, filterKeys)) isNoChange = false;
  }
  return isNoChange;
};

export const taxHouseHoldToSimulator = (
  updateSimulatorData: Dispatch<SetStateAction<ISimulatorData>>,
  taxHouseholdDatas: ITaxHouselod[]
) => {
  updateSimulatorData((prevState) => ({
    ...prevState,
    fiscalDatas: taxHouseholdDatas,
  }));
};

export const simulatorToTaxHouseHold = (
  simulatorData: ISimulatorData,
  setTaxHouseholdDatas: Dispatch<SetStateAction<ITaxHouselod[]>>
) => {
  if (simulatorData.fiscalDatas && simulatorData.fiscalDatas.length > 0) {
    const formattedData = simulatorData.fiscalDatas.map(
      (tax: { [key: string]: string }) => ({
        ...tax,
        lastname: tax.lastname || '',
        firstname: tax.firstname || '',
        fiscal_number: tax.fiscal_number || '',
        fiscal_reference: tax.fiscal_reference || '',
      })
    );

    setTaxHouseholdDatas(formattedData);
  }
};

export const getCreationStepByData = (
  simulatorData: ISimulatorData,
  operationType: number,
  withCustomPrices: boolean
): StepsWorksiteCreationEnum => {
  const isRenoGlobale = operationType === 3;

  const fiscalData = simulatorData?.fiscalDatas;

  const isValidData = (key: string) =>
    simulatorData[key] && simulatorData[key] !== '';

  const isValidFiscal = (
    data: { [x: string]: string }[] | null | undefined
  ) => {
    if (!data) return false;

    let isValid = true;

    data.forEach((line) => {
      const validLine = Object.entries(line).every((val) => {
        if (val[0] === 'fiscal_number') return taxNumberRegex.test(val[1]);
        if (val[0] === 'fiscal_reference')
          return taxNoticeReferenceRegex.test(val[1]);

        return val[1] && val[1] !== '';
      });
      if (!validLine) isValid = false;
    });

    return isValid;
  };

  const havePrimes = simulatorData.operations.some(
    (operationData: any) => operationData.primes
  );

  if (havePrimes) {
    return StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF;
  }

  const havePrices = simulatorData.operations.some((op: any) =>
    Object.keys(op).some((k) => k.includes('euros'))
  );

  if (havePrices && withCustomPrices) {
    return StepsWorksiteCreationEnum.SIMULATION_PRIX;
  }

  if (
    isRenoGlobale &&
    isValidData('general.persons') &&
    isValidData('general.income') &&
    isValidFiscal(fiscalData)
  ) {
    return StepsWorksiteCreationEnum.GLOBAL_PRIMES;
  }
  if (simulatorData.operations[0]['operation.id'] === null) {
    return StepsWorksiteCreationEnum.SIMULATION_CHOICEOPERATIONS;
  }

  if (
    operationType !== 3 &&
    simulatorData?.operations.some(
      (elt: any) => elt && Object.values(elt).length > 1
    )
  ) {
    return StepsWorksiteCreationEnum.SIMULATION_OPERATIONDETAILS;
  }
  if (simulatorData['general.persons'] !== undefined) {
    return StepsWorksiteCreationEnum.SIMULATION_INFO_BENEFICIARY;
  }
  if (
    simulatorData.operations[0]['operation.id'].toLowerCase() === 'bar-th-164'
  ) {
    if (simulatorData['general.address'])
      return StepsWorksiteCreationEnum.SIMULATION_LOGEMENT;
    return StepsWorksiteCreationEnum.GLOBAL_CHOICEOPERATIONS;
  }
  return StepsWorksiteCreationEnum.SIMULATION_LOGEMENT;
};

export const fetchIncomes = async (
  persons: string,
  worksiteAddress: IWorksiteAddress,
  simulatorData: ISimulatorData,
  updateIncomesOptionsArray: Dispatch<SetStateAction<IIncomes[] | null>>,
  t: TFunction,
  setIsLoadingIncomes?: Dispatch<SetStateAction<boolean>>,
  isIframeMode = false
) => {
  const dataPost = {
    works: {
      worksType: {
        activite: 'residentiel',
      },
      worksStreet: worksiteAddress.streetName,
      worksStreetNumber: worksiteAddress.numberStreet,
      worksZip: simulatorData['general.zipcode'],
      workCity: worksiteAddress.city,
    },
    persons,
  };
  if (persons) {
    await getIncomes(
      t,
      updateIncomesOptionsArray,
      dataPost,
      setIsLoadingIncomes,
      isIframeMode
    );
  }
};

export const labelIncomes = (t: TFunction, generalIncome: number) => {
  switch (generalIncome) {
    case INCOMES.LOW:
      return t('worksite_creation.simulation.incomes.low');
    case INCOMES.MODEST:
      return t('worksite_creation.simulation.incomes.modest');
    case INCOMES.INTERMEDIATE:
      return t('worksite_creation.simulation.incomes.intermediate');
    case INCOMES.CLASSIC:
      return t('worksite_creation.simulation.incomes.classic');
    default:
  }
  return '';
};

export const labelHabitationZone = (
  t: TFunction,
  generalHabitationZone: string
) => {
  switch (generalHabitationZone) {
    case HABITATION_ZONE.QPV:
      return t('worksite_creation.simulation.habitation.zone_qpv');
    case HABITATION_ZONE.CONTRACTED:
      return t('worksite_creation.simulation.habitation.contracted');
    case HABITATION_ZONE.OTHER:
      return t('worksite_creation.simulation.habitation.other');
    default:
  }
  return '';
};

export const labelAge = (t: TFunction, generalAge: string) => {
  switch (generalAge) {
    case WORKSITE_AGE.YOUNG:
      return t('worksite_creation.simulation.age.young');
    case WORKSITE_AGE.BETWEEN:
      return t('worksite_creation.simulation.age.between');
    case WORKSITE_AGE.OLD:
      return t('worksite_creation.simulation.age.old');
    default:
  }
  return '';
};

export const labelOwner = (t: TFunction, generalOwner: string) => {
  switch (generalOwner) {
    case OWNER.LANDLORD:
      return t('worksite_creation.simulation.landlord_owner');
    case OWNER.OWNER:
      return t('worksite_creation.simulation.owner');
    case OWNER.OTHER:
      return t('global.other');
    default:
  }
  return '';
};

export const calculateDateOfExpiration = (
  worksiteDatas: IWorksite,
  conventionActive: IConvention
) => {
  if (worksiteDatas.id) {
    return new Date(worksiteDatas.offer_expired_at);
  }
  const dateAfterOfferValidityDelay = conventionActive.offer_validity_delay
    ? addDays(new Date(), conventionActive.offer_validity_delay)
    : undefined;

  const conventionEndDate = new Date(conventionActive.end_date);
  if (
    dateAfterOfferValidityDelay &&
    dateAfterOfferValidityDelay < conventionEndDate
  ) {
    return dateAfterOfferValidityDelay;
  }
  return conventionEndDate;
};

export const hasNonEmptyArrayWithNonEmptyValues = (obj: any): boolean => {
  const allItemsFilled = Object.values(obj).every(
    (arr) =>
      Array.isArray(arr) &&
      (arr.length === 0 ||
        arr.every((item) =>
          Object.values(item).every((value) => value !== null && value !== '')
        ))
  );

  const hasValueAboveZero = Object.values(obj).some(
    (arr) =>
      Array.isArray(arr) &&
      arr.length > 0 &&
      arr.some((item) =>
        Object.values(item).some(
          (value) =>
            value !== null && value !== '' && value !== '0' && value !== 0
        )
      )
  );

  return allItemsFilled && hasValueAboveZero;
};

export const getActivityLabel = (value: string, t: TFunction) => {
  const labels: { [key: string]: string } = {
    office: t('worksite_creation.simulation.activities.office'),
    school: t('worksite_creation.simulation.activities.school'),
    health: t('worksite_creation.simulation.activities.health'),
    shop: t('worksite_creation.simulation.activities.shop'),
    hotel: t('worksite_creation.simulation.activities.hotel'),
    other: t('worksite_creation.simulation.activities.other'),
  };

  return labels[value] || value;
};

// Vérifie si une valeur, y compris objet, est nulle ou ''
// Si un tableau est vide ou s'il contient des objets qui sont nulls ou ''
export const isEmptyValue = (value: any): boolean => {
  if (value === null || value === '' || value === undefined) {
    return true;
  }
  if (Array.isArray(value)) {
    return value.length === 0 || value.some(isEmptyValue);
  }
  if (typeof value === 'object' && value !== null) {
    return (
      Object.values(value).some(isEmptyValue) || Object.keys(value).length === 0
    );
  }
  return false;
};

export const graphOperationConditionIsOk = (
  conditionValue: string,
  dataValue: string | number
): boolean => {
  const regex = /([<>]=?|=)?\s*(-?\d+(\.\d+)?)/;
  const match = conditionValue.match(regex);

  if (match) {
    const operator = match[1] || '=';
    const value = parseFloat(match[2]);
    const dataNumValue =
      typeof dataValue === 'number'
        ? dataValue
        : parseFloat(dataValue.toString());

    if (!Number.isNaN(dataNumValue)) {
      switch (operator) {
        case '<':
          return dataNumValue < value;
        case '<=':
          return dataNumValue <= value;
        case '>':
          return dataNumValue > value;
        case '>=':
          return dataNumValue >= value;
        case '=':
          return dataNumValue === value;
        default:
          return false;
      }
    } else {
      return false;
    }
  } else {
    // No operator means we check for equality as a string
    return conditionValue === dataValue.toString();
  }
};

export const getGraphErrors = (
  graph: IGraph,
  simulatorData: ISimulatorData,
  simulatorDataOperations: ISimulatorDataOperation[],
  index: number
) => {
  const operation = simulatorDataOperations[index];
  const valueA = operation[graph.key];
  const ruleErrors = graph.rules?.filter((rule) => {
    let valueB = simulatorData[rule.value];
    if (!valueB) return false; // si la conditon n'existe pas dans infos generales

    if (rule.operation) {
      const conditionOperation = simulatorDataOperations.find(
        (op) => op['operation.id'] === rule.operation
      );

      if (!conditionOperation) return false;
      valueB = conditionOperation[rule.value];
    }

    const comparators: { [key: string]: (a: any, b: any) => boolean } = {
      '<=': (a, b) => a <= b,
      '<': (a, b) => a < b,
      '=': (a, b) => a === b,
      '>=': (a, b) => a >= b,
      '>': (a, b) => a > b,
      '!=': (a, b) => a !== b,
    };

    const comparator = comparators[String(rule.condition)];

    return !comparator(Number(valueA), Number(valueB));
  });

  return ruleErrors?.map((error) => ({ ...error, key: graph.key })) || [];
};

// Fonction pour détecter les doublons dans un tableau
export const findDuplicates = (array: string[] | undefined) => {
  if (!array) return [];
  const duplicates: string[] = [];
  const count: Record<string, number> = {};

  // Compter les occurrences de chaque élément
  array.forEach((item) => {
    count[item] = (count[item] || 0) + 1;
    if (count[item] === 2) {
      duplicates.push(item);
    }
  });

  return duplicates;
};

export const updateSimulationPrice = (
  code: string,
  value: string | null,
  updateSimulatorDataOperation: Dispatch<
    SetStateAction<ISimulatorDataOperation[]>
  >,
  precarity: null | 'classic' | 'modest'
) => {
  updateSimulatorDataOperation((prevState) =>
    prevState.map((op) => {
      if (op['operation.id'] === code.toLowerCase()) {
        switch (precarity) {
          case 'classic':
            return { ...op, customPriceClassic: value };
          case 'modest':
            return { ...op, customPriceModest: value };
          default:
            return { ...op, customPrice: value };
        }
      }
      return op;
    })
  );
};

export const getContractPrice = (
  operation: ISimulatorDataOperation,
  precarity: null | 'classic' | 'modest'
) => {
  switch (precarity) {
    case 'classic':
      return operation.contractPriceClassic || operation.eurosMwHCumacClassic;
    case 'modest':
      return operation.contractPriceModest || operation.eurosMwHCumacModest;
    default:
      return operation.contractPrice || operation.eurosMwHCumac;
  }
};

export const handleErrorIsFromApi = (error: any) => {
  if (error.response) {
    const worksiteFromApi = localStorage.getItem('worksite_in_risky_state');
    const status = error.response.status;
    if ((status === 422 || status === 500) && worksiteFromApi) {
      localStorage.setItem('error_worksite_from_api', 'true');
    }
    return true;
  }
  return false;
};

// ensemble de fonctions génériques utilisées pour vérifier que les puissances installées < puissance totale
const findOperationItem = (operationsData: any[], key: string) => {
  return operationsData.find((item) => key in item);
};

export const totalSystemPower = (
  operationsData: ISimulatorDataOperation[],
  keySets: [string, string, string][]
): number => {
  return keySets.reduce((totalPower, [itemKey, subKey, valueKey]) => {
    const systemItem = findOperationItem(operationsData, itemKey);

    if (
      systemItem &&
      itemKey in systemItem &&
      typeof systemItem[itemKey] === 'object' &&
      systemItem[itemKey] !== null
    ) {
      const systemDetails = systemItem[itemKey] as Record<string, any>;

      if (Array.isArray(systemDetails[subKey])) {
        const power = systemDetails[subKey].reduce(
          (subTotal: any, system: any) => {
            if (valueKey in system) {
              return subTotal + Number(system[valueKey]);
            }
            return subTotal;
          },
          0
        );

        return totalPower + power;
      }
    }

    return totalPower;
  }, 0);
};

export const totalHeatingPower = (
  operationsData: ISimulatorDataOperation[]
) => {
  const heatingPowerKey = 'operation.totalHeatingPower';
  const connectedPowerKey = 'operation.totalConnectedPower';
  let result: any = null;

  operationsData.forEach((obj) => {
    if (heatingPowerKey in obj) {
      const value = obj[heatingPowerKey];
      result = typeof value === 'string' ? Number(value) : value;
    } else if (connectedPowerKey in obj) {
      const value = obj[connectedPowerKey];
      result = typeof value === 'string' ? Number(value) : value;
    }
  });

  return result ?? 0;
};

export const systemPowerIsValid = (
  operationsData: ISimulatorDataOperation[],
  keySets: [string, string, string][]
): boolean => {
  const totalPower = totalHeatingPower(operationsData);
  const systemsPower = totalSystemPower(operationsData, keySets);

  return totalPower > 0 ? totalPower >= systemsPower : true;
};

export const systemPowerFormNotValid = (
  operationsData: ISimulatorDataOperation[],
  keySets: [string, string, string][]
): boolean => {
  return keySets.some(([itemKey, subKey, valueKey]) => {
    const sytemsItem = findOperationItem(operationsData, itemKey);

    if (
      sytemsItem &&
      typeof sytemsItem[itemKey] === 'object' &&
      sytemsItem[itemKey] !== null
    ) {
      const systemDetails = sytemsItem[itemKey] as Record<string, any>;

      if (
        !Array.isArray(systemDetails[subKey]) ||
        systemDetails[subKey].length === 0
      ) {
        return true;
      }

      const invalidSystemPower = systemDetails[subKey].some(
        (system: any) => system[valueKey] && system[valueKey] === ''
      );

      if (invalidSystemPower) {
        return true;
      }

      return false;
    }

    return false;
  });
};

export const getTransformedWorkflow = (
  current: IWorkflowSidebar,
  stored: IWorkflowSidebar | null,
  graphOperation: IGraph[]
): IWorkflowSidebar => {
  if (
    graphOperation.length === 1 &&
    ['tra-eq-114', 'tra-eq-117', 'tra-eq-130'].includes(graphOperation[0].value)
  ) {
    const simulation = current.simulation
      .filter((step) => step.label !== 'Informations bénéficiaire')
      .map((step) => {
        if (
          step.label === 'Typologie du logement' ||
          step.label === 'Typologie du bâtiment'
        ) {
          // Pour tra-eq-117, on utilise "Dates prévisionnelles" comme label
          if (graphOperation[0].value === 'tra-eq-117') {
            return { ...step, label: "Dates prévisionnelles de l'opération" };
          }
          // Pour les autres opérations, on garde "Typologie du bénéficiaire"
          return { ...step, label: 'Typologie du bénéficiaire' };
        }
        return step;
      });
    return { ...current, simulation };
  }
  return stored ?? current;
};

export const getSectorBYOperation = (
  code: string,
  globalEnum: any
): [string, string] | undefined => {
  return Object.entries(globalEnum.sector).find(
    (elt) => elt[0] === code.substring(0, 3)
  ) as [string, string] | undefined;
};

/*
simulatorData d'origine B2C
{
    "general.habitation": "house",
    "general.surface": "120",
    "general.persons": 2,
    "general.owner": "owner",
    "general.worksiteStartDate": "15/03/2025",
    "general.worksiteEndDate": "15/03/2025",
    "general.age": "16",
    "general.income": 34883,
    "general.worksiteDates": null,
    "general.address": {
        "address": "50 Rue de Charonne",
        "city": "Paris",
        "country": "France",
        "latitude": 48.8529808,
        "longitude": 2.3777867
    },
    "general.zipcode": "75011",
    "ts": 1741968035591,
    "convention.id": 0,
    "cadastral_parcel": "111 CE 0048",
    "general.income.precarityType": 1,
    "operations": [
        {
            "operation.id": "bar-en-102",
            "operation.material": "isolation_out",
            "operation.isolationSurface": "120",
            "operation.thermalResistance": "100",
            "operation.totalCost": 12500,
            "eurosMwHCumac": "6.99",
            "eurosMwHCumacCdp": null,
            "eurosCdp": null,
            "operation.name": "Isolation des murs",
            "primes": {
                "mpr": 7500,
                "mprMax": 7500,
                "cdp": 0,
                "cee": 1342.08,
                "gisement": 192000,
                "gisementCdp": 0,
                "gisementCee": 192000,
                "bonus": [],
                "ceeMax": 1342.08,
                "cdpMax": 0
            }
        }
    ],
    "operationType": 1,
    "incentiveType": 2,
    "iframe.id": 1,
    "totalCeeEuros": 1342.08,
    "totalMprEuros": 7500,
    "totalPrimesEuros": 8842.08,
    "totalGisement": 192000,
    "beneficiaryMinimumAmount": 0,
    "worksite.offer_expired_at": null,
    "worksite.sector": "BAR"
}

simulatorData d'origine B2B

{
    "general.worksiteName": "azeaze",
    "general.habitationZone": "zone_qpv",
    "general.worksiteStartDate": "18/03/2025",
    "general.worksiteEndDate": "18/03/2025",
    "general.age": "16",
    "general.habitationNumber": "2",
    "general.domain": "syndic",
    "general.habitationNumberContracted": null,
    "general.habitationNumberQpv": "2",
    "general.qpvName": "Quartier st vincent",
    "general.qpvCode": "THECODE",
    "general.worksiteDates": null,
    "general.address": {
        "address": "50 Rue de Charonne",
        "city": "Paris",
        "country": "France",
        "latitude": 48.8529808,
        "longitude": 2.3777867
    },
    "general.zipcode": "75011",
    "ts": 1742203539351,
    "convention.id": 0,
    "cadastral_parcel": "111 CE 0048",
    "operations": [
        {
            "operation.id": "bar-en-102",
            "operation.material": "isolation_out",
            "operation.isolationSurface": "120",
            "operation.thermalResistance": "100",
            "operation.totalCost": 12500,
            "eurosMwHCumacClassic": "6.89",
            "eurosMwHCumacModest": "6.99",
            "eurosMwHCumacClassicCdp": null,
            "eurosMwHCumacModestCdp": null,
            "eurosClassicCdp": null,
            "eurosModestCdp": null,
            "operation.name": "Isolation des murs",
            "primes": {
                "mWhCumac": 192,
                "mWhCumacClassic": 94.08,
                "mWhCumacModest": 97.92,
                "kWhCumac": 192000,
                "kWhCumacClassic": 94080,
                "kWhCumacModest": 97920,
                "eurosClassic": 648.21,
                "eurosModest": 684.46,
                "mWhCumacCdp": 0,
                "mWhCumacClassicCdp": 0,
                "mWhCumacModestCdp": 0,
                "kWhCumacCdp": 0,
                "kWhCumacClassicCdp": 0,
                "kWhCumacModestCdp": 0,
                "eurosClassicCdp": 0,
                "eurosModestCdp": 0,
                "gisementCee": 192000,
                "gisementCdp": 0,
                "gisement": 192000,
                "cee": 1332.67,
                "cdp": 0,
                "mpr": 0,
                "ceeMax": 1332.67,
                "cdpMax": 0
            }
        }
    ],
    "operationType": 2,
    "incentiveType": 2,
    "iframe.id": 1,
    "totalCeeEuros": 1332.67,
    "totalMprEuros": 0,
    "totalPrimesEuros": 1332.67,
    "totalGisement": 192000,
    "beneficiaryMinimumAmount": 0,
    "worksite.offer_expired_at": null
}

*/

export const prepareDataForPdfIframe: (
  simulatorData: ISimulatorData,
  iframeData: IIframe,
  type: 'b2c' | 'b2b',
  t: TFunction
) => DataForPdfGenerationType = (
  simulatorData: ISimulatorData,
  iframeData: IIframe,
  type: 'b2c' | 'b2b',
  t: TFunction
) => {
  // Fonction pour convertir en string de manière sécurisée
  const toStringValue = (value: any): string => {
    if (value === null || value === undefined || value === '') return '-';
    return String(value);
  };

  const getB2BHousingType = (): string => {
    return simulatorData['general.activity']
      ? getActivityLabel(simulatorData['general.activity'], t)
      : '';
  };

  const getB2CHousingType = (): string => {
    return simulatorData['general.habitation'] === 'house'
      ? t('worksite_creation.simulation.habitation.house')
      : t('worksite_creation.simulation.habitation.apartment');
  };

  const getGistement = (operation: any) => {
    if (operation.primes?.gisementCdp > operation.primes?.gisementCee) {
      return Number(operation.primes?.gisementCdp) / 1000;
    }
    return Number(operation.primes?.gisementCee) / 1000;
  };

  const getCee = (operation: any) => {
    if (operation.primes?.cdp > operation.primes?.cee) {
      return Number(operation.primes?.cdp);
    }
    return Number(operation.primes?.cee);
  };

  // 30 jours par défaut
  const defaultValidityDate = new Date(
    Date.now() + 30 * 24 * 60 * 60 * 1000
  ).toLocaleDateString('fr-FR');

  const dataToSend: DataForPdfGenerationType = {
    id: iframeData.id,

    operations: simulatorData.operations.map((operation: any) => ({
      name: toStringValue(operation['operation.name']),
      mwhc: getGistement(operation) || 0,
      cee: getCee(operation) || 0,
      mpr: Number(operation.primes?.mpr) || 0,
    })),

    // Beneficiary will be set later with opx-3322
    beneficiary_name: '',
    beneficiary_address: '',
    beneficiary_zipcode: '',
    beneficiary_city: '',

    // B2B : activity sector
    // B2C : house or apartment
    housing_type: type === 'b2b' ? getB2BHousingType() : getB2CHousingType(),

    housing_age: labelAge(t, simulatorData['general.age']),
    housing_area: simulatorData['general.surface'] ?? '',

    income_zipcode: simulatorData['general.zipcode'] ?? '',
    income_category: simulatorData['general.income.precarityType'] ?? '',
    income_persons: Number(simulatorData['general.persons']) || 0,

    total_cee: Number(simulatorData.totalCeeEuros) || 0,
    total_mpr: Number(simulatorData.totalMprEuros) || 0,
    offer_validity_date: simulatorData['worksite.offer_expired_at']
      ? toStringValue(simulatorData['worksite.offer_expired_at'])
      : defaultValidityDate,
  };

  return dataToSend;
};

export const cannotDisplayHouseInformationsSummary = (
  operations: ISimulatorDataOperation[]
) => {
  const operationsToCheck = ['tra-eq-130', 'tra-eq-117'];
  return operations.every((operation: ISimulatorData) =>
    operationsToCheck.includes(operation['operation.id'].toLowerCase())
  );
};

export const checkNoAidCondition = (
  graph: IGraph,
  value: string | number | null
): string | null => {
  if (!graph.childrens) return null;

  // Si la valeur est null ou vide, on ne déclenche pas de noAid
  if (!value) return null;

  const noAidChild = graph.childrens.find((child) => {
    if (child.key !== 'operation.noaid') return false;

    // Vérifier si la condition est une condition numérique
    const condition = child.condition?.[0];
    if (!condition) return false;

    if (condition.includes('<') || condition.includes('>')) {
      const numberValue = Number(value);
      const conditionValue = Number(condition.match(/[\d.]+/)?.[0]);
      const operator = condition.match(/[<>=]+/)?.[0];

      if (Number.isNaN(numberValue) || Number.isNaN(conditionValue))
        return false;

      switch (operator) {
        case '<':
          return numberValue < conditionValue;
        case '>':
          return numberValue > conditionValue;
        case '<=':
          return numberValue <= conditionValue;
        case '>=':
          return numberValue >= conditionValue;
        default:
          return false;
      }
    }

    // Pour les conditions non numériques, on garde le comportement existant
    return child.condition?.includes(value.toString());
  });

  return noAidChild ? noAidChild.name : null;
};
