import { getWorksiteStepsDatas } from '@models/dashboard/apiRequests/dashboardRequests';
import StatusCardLoader from '@models/dashboard/components/loaders/StatusCardLoader';
import { fakeStatusDatas } from '@models/dashboard/utils/fakeData';
import { WORKSITE_OPERATION_STATUS } from '@models/worksites/utils/enums';
import { useEffect, useState } from 'react';
import { IStatusCardDatas } from '../utils/dashboardTypes';
import StatusCard from './StatusCard';

interface StatusDatasLineProps {
  realData?: boolean;
}

function StatusDatasLine({ realData }: StatusDatasLineProps) {
  const [statusDatas, setStatusDatas] = useState<IStatusCardDatas[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    if (realData) {
      const resDatas = await getWorksiteStepsDatas();
      if (resDatas) {
        setStatusDatas(resDatas);
      }
    } else {
      setStatusDatas(fakeStatusDatas);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [realData]);

  return (
    <div className="flex w-full items-start border border-b border-borderGrey bg-white">
      {!loading ? (
        statusDatas
          .filter(
            (data) => data.step !== WORKSITE_OPERATION_STATUS.CONTROL_CONTACT
          )
          .map((data) => (
            <StatusCard
              key={`status-card-${data.step}`}
              step={data.step}
              numberOperations={data.total_operations}
              amount={data.total_prime_amount}
              gisementMwhc={data.total_mwhc_gisement}
            />
          ))
      ) : (
        <StatusCardLoader />
      )}
    </div>
  );
}

StatusDatasLine.defaultProps = { realData: false };

export default StatusDatasLine;
