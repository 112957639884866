// eslint-disable-next-line no-shadow
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { ILabelValue } from '@globalTypes/globalTypes';
import { IPartnerView } from '@models/partners/utils/types/partnersType';
import { CONTRACTS_ROUTES_WITH_PARAMS } from '@utils/routesUrls';
import { URL_TYPES } from '@models/contracts/utils/contractConstants';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { ITabData } from './settingsTypes';
import { getInstallerConventions } from '../apiRequests/settingsRequests';

export const CustomFieldTypes = {
  TEXT: 1,
  NUMBER: 2,
  BOOL: 3,
  DATE: 4,
  FILE: 9999,
} as const;

export const renderCustomFieldTypeLabel = (type: number, t: TFunction) => {
  let label = '';
  switch (type) {
    case CustomFieldTypes.NUMBER:
      label = t('settings.type.number');
      break;
    case CustomFieldTypes.DATE:
      label = t('settings.type.date');
      break;
    case CustomFieldTypes.BOOL:
      label = t('settings.type.boolean');
      break;
    case CustomFieldTypes.FILE:
      label = t('settings.type.file');
      break;
    case CustomFieldTypes.TEXT:
    default:
      label = t('settings.type.text');
      break;
  }
  return label;
};

export const getCustomFieldTypeValue = (
  label: string,
  t: TFunction
): number => {
  const typeLabels: Record<string, number> = {
    [t('settings.type.number')]: CustomFieldTypes.NUMBER,
    [t('settings.type.date')]: CustomFieldTypes.DATE,
    [t('settings.type.boolean')]: CustomFieldTypes.BOOL,
    [t('settings.type.text')]: CustomFieldTypes.TEXT,
    [t('settings.type.file')]: CustomFieldTypes.FILE,
  };

  return typeLabels[label] || CustomFieldTypes.TEXT; // Retourne TEXT par défaut si le label n'est pas trouvé
};

export const SETTINGS_TABS = {
  SUBSCRIPTION_AND_PURCHASE: 'subscription_and_purchase',
  CUSTOM_FIELDS: 'custom_fields',
  BRANDS: 'brands',
  WHITE_LABEL: 'white_label',
  FINANCIAL_DATA: 'financial_data',
  CONTACT_CONTROL: 'contact_control',
  CHECKPOINTS: 'checkpoints',
  IFRAMES: 'iframes',
};

export const SETTINGS_TABS_DATA: ITabData[] = [
  {
    key: SETTINGS_TABS.CUSTOM_FIELDS,
    label: 'settings.custom_fields',
  },
  {
    key: SETTINGS_TABS.BRANDS,
    label: 'settings.brands',
  },
  {
    key: SETTINGS_TABS.SUBSCRIPTION_AND_PURCHASE,
    label: 'settings.subscriptions_and_purchases',
  },
  {
    key: SETTINGS_TABS.FINANCIAL_DATA,
    label: 'settings.financial_data',
  },
  {
    key: SETTINGS_TABS.WHITE_LABEL,
    label: 'settings.white_label.title',
  },
  {
    key: SETTINGS_TABS.CONTACT_CONTROL,
    label: 'settings.contact_control',
  },
  {
    key: SETTINGS_TABS.CHECKPOINTS,
    label: 'settings.checkpoints.title',
  },
  {
    key: SETTINGS_TABS.IFRAMES,
    label: 'settings.iframes.title',
  },
];

export const fetchConventions = async (
  isInstaller: boolean,
  userView: IPartnerView | null,
  setConventionOptions: Dispatch<SetStateAction<ILabelValue[]>>,
  setIsLoadingConventions: Dispatch<SetStateAction<boolean>>
) => {
  if (isInstaller && userView?.entity_id) {
    await getInstallerConventions(
      setConventionOptions,
      setIsLoadingConventions,
      userView.entity_id
    );
  }
};

export const linkToContract = (
  contractType: ContractTypes,
  contractId: string | number
) => {
  let navigateUrl = '';

  switch (contractType) {
    case ContractTypes.CONVENTION:
      navigateUrl = `/${URL_TYPES.CONVENTION}/${contractId}`;
      break;
    case ContractTypes.TRIPARTITE:
      navigateUrl = `/${URL_TYPES.TRIPARTITE}/${contractId}`;
      break;
    case ContractTypes.MANDAT:
      navigateUrl = CONTRACTS_ROUTES_WITH_PARAMS(
        URL_TYPES.MANDANT,
        contractId
      ).CONTRACT_VIEW;
      break;
    case ContractTypes.MANDAT_NON_DEPOSANT:
      navigateUrl = CONTRACTS_ROUTES_WITH_PARAMS(
        URL_TYPES.MANDANT_NON_DEPOSANT,
        contractId
      ).CONTRACT_VIEW;
      break;
    case ContractTypes.VENTE:
    case ContractTypes.DELEGATION:
      navigateUrl = CONTRACTS_ROUTES_WITH_PARAMS(
        URL_TYPES.SALE,
        contractId
      ).CONTRACT_VIEW;
      break;
    default:
      break;
  }

  return navigateUrl;
};
