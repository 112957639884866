import { blueSecondary, green, textGrey, red } from '@assets/color';
import {
  COFRAC_OPERATION_STATUS,
  COFRAC_STATUS,
} from '@models/cofrac/utils/cofracConstants';
import { t } from 'i18next';
import { ICofracLotsOperations } from '@models/cofrac/utils/cofracTypes';

export const getCofracTag = (status: number | null, isWorksite = false) => {
  const text = isWorksite
    ? 'worksites.operation.cofrac_status.'
    : 'cofrac.status';

  switch (status) {
    case COFRAC_STATUS.EN_CREATION:
      return { text: t('cofrac.status.creation'), color: textGrey };
    case COFRAC_STATUS.EN_COURS:
      return { text: t('cofrac.status.in_progress'), color: blueSecondary };
    case COFRAC_STATUS.SATISFAISANT:
      return { text: t(`${text}ok`), color: green };
    case COFRAC_STATUS.INSATISFAISANT:
      return { text: t(`${text}ko`), color: red };
    default:
      return { text: '', color: '' };
  }
};

export interface ICofracSatisfactionInputs {
  reportDate: string;
  synthesisDate: string;
  summaryFile: File | null;
  reportFile: File | null;
}

export interface ICofracInvoiceInputs {
  invoice: File;
  invoice_date: string;
  invoice_amount_ht: string;
  invoice_vat_rate: string;
  invoice_amount_ttc: string;
}

export const cofracLotsOperationsTagColorByStatus = (
  cofracLotsOperations: ICofracLotsOperations
): string => {
  let color: string = blueSecondary;

  if (cofracLotsOperations && cofracLotsOperations?.status) {
    if (
      cofracLotsOperations?.status === COFRAC_OPERATION_STATUS.SATISFAISANTE ||
      cofracLotsOperations?.status === COFRAC_OPERATION_STATUS.CORRIGEE
    ) {
      color = green;
    } else if (
      cofracLotsOperations?.status === COFRAC_OPERATION_STATUS.INSATISFAISANTE
    ) {
      color = red;
    } else if (
      cofracLotsOperations?.status === COFRAC_OPERATION_STATUS.NON_CONTROLEE
    ) {
      color = textGrey;
    }
  }

  return color;
};
