import { axiosClient } from '@api/apiClient';
import {
  urlCofrac,
  urlDeleteLinkCofracOperation,
  urlGetCofracEligibleOperations,
  urlGetCofracInfos,
  urlGetCofracOperationsLinked,
  urlGetCofracOperationsToLink,
  urlPostLinkCofracOperation,
  urlPutStoreCofrac,
  urlCofracExportCsv,
  urlDeleteCofrac,
  urlPostCofracInvoice,
} from '@api/apiUrls';
import { displayError } from '@utils/format';
import { Dispatch, SetStateAction } from 'react';
import {
  ICofracType,
  IStoreCofracOperations,
} from '@models/cofrac/utils/cofracTypes';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { FieldValues } from 'react-hook-form';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { saveAs } from 'file-saver';

export const getCofracList = async (
  page: number,
  perPage?: number,
  sortAndFilter?: ISortAndFilterType
) => {
  try {
    const response = await axiosClient.post(urlCofrac, {
      pagination: {
        page,
        perPage: perPage || 5,
      },
      sort_and_filter: sortAndFilter,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getCofracEligibleOperations = async (
  setEligibleOperations: Dispatch<SetStateAction<IOperationType[]>>
) => {
  try {
    const response = await axiosClient.get(urlGetCofracEligibleOperations);
    setEligibleOperations(response.data.data);
  } catch (error) {
    displayError(error.response);
  }
};

export const storeCofrac = async (data: FieldValues) => {
  try {
    const response = await axiosClient.put(urlPutStoreCofrac, data);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getCofracInfos = async (
  id: number,
  setCofrac: Dispatch<SetStateAction<ICofracType | undefined>>
) => {
  try {
    const response = await axiosClient.get(urlGetCofracInfos(id));
    setCofrac(response.data.data);
  } catch (error) {
    displayError(error.response);
  }
};

export const getCofracOperationsToLink = async (
  id: number,
  search: string | null,
  setIsLoading: Dispatch<SetStateAction<{ linked: boolean; toLink: boolean }>>,
  sortAndFilter?: ISortAndFilterType
) => {
  try {
    const response = await axiosClient.post(urlGetCofracOperationsToLink(id), {
      keyword: search,
      sort_and_filter: sortAndFilter,
    });
    setIsLoading((prev) => ({ ...prev, toLink: false }));
    return response.data;
  } catch (error) {
    setIsLoading((prev) => ({ ...prev, toLink: false }));
    displayError(error.response);
    return false;
  }
};

export const getCofracLinkedOperations = async (
  id: number,
  search: string | null,
  setIsLoading: Dispatch<SetStateAction<{ linked: boolean; toLink: boolean }>>,
  sortAndFilter?: ISortAndFilterType
) => {
  try {
    const response = await axiosClient.post(urlGetCofracOperationsLinked(id), {
      keyword: search,
      sort_and_filter: sortAndFilter,
    });
    setIsLoading((prev) => ({ ...prev, linked: false }));
    return response.data;
  } catch (error) {
    displayError(error.response);
    setIsLoading((prev) => ({ ...prev, linked: false }));
    return false;
  }
};

export const linkCofracOperation = async (
  cofrac_lot_id: number,
  worksite_operation_id: number
) => {
  try {
    const response = await axiosClient.post(urlPostLinkCofracOperation, {
      cofrac_lot_id,
      worksite_operation_id,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const unlinkCofracOperation = async (id: number) => {
  try {
    const response = await axiosClient.delete(urlDeleteLinkCofracOperation(id));
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const storeCofracDate = async (
  id: number,
  cofrac_date: string | null,
  action: string,
  report_date?: string | null,
  synthesis_date?: string | null,
  cofrac_lots_operations?: IStoreCofracOperations[]
) => {
  try {
    const response = await axiosClient.put(urlPutStoreCofrac, {
      id,
      cofrac_date,
      action,
      report_date,
      synthesis_date,
      cofrac_lots_operations,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const storeCofracDateAndControlOffice = async (
  id: number,
  entity_control_id: number,
  cofrac_date: string,
  action: string,
  cofrac_lots_operations?: IStoreCofracOperations[]
) => {
  try {
    const response = await axiosClient.put(urlPutStoreCofrac, {
      id,
      entity_control_id,
      cofrac_date,
      action,
      cofrac_lots_operations,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

// eslint-disable-next-line consistent-return
export const deleteCofrac = async (cofracId: number) => {
  try {
    const response = await axiosClient.delete(urlDeleteCofrac(cofracId));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const downloadCofracSheets = async (data: ICofracType) => {
  const response = await axiosClient.get(urlCofracExportCsv(data.id), {
    responseType: 'arraybuffer',
  });

  if (response.data) {
    // Créer un blob pour un fichier ZIP
    const blob = new Blob([response.data], {
      type: 'application/zip', // Type MIME pour un fichier ZIP
    });

    // Obtenir la date du jour au format Y-m-d
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD

    // Télécharger le fichier ZIP avec un nom spécifique et la date du jour
    saveAs(blob, `cofrac_${data.name}_${formattedDate}_OPX.zip`); // Ajout de la date dans le nom du fichier
  }

  return response.data;
};

export const storeCofracInvoice = async (formData: FormData) => {
  try {
    const response = await axiosClient.post(urlPostCofracInvoice, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    displayError(error?.response);
    return false;
  }
};
