import { Card } from '@components/Card';
import { IContactType } from '@models/conventions/utils/conventionTypes';
import { useEffect, useMemo, useState } from 'react';
import { blueOpx } from '@assets/color';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { ContactPageIcon, SignataryIcon } from '@assets/images/svgComponents';
import { updateReferents } from '@models/conventions/apiRequests/conventionRequests';
import { IUserType } from '@models/auth/utils/types';
import { useTranslation } from 'react-i18next';

interface InternalReferentsProps {
  contacts: IContactType[];
  list: IUserType[];
  contractId: number;
  contractType: number;
  onRefresh: () => void;
  isConvention?: boolean;
  isCreator: boolean;
}
function InternalReferents({
  contacts,
  list,
  contractId,
  contractType,
  onRefresh,
  isConvention,
  isCreator,
}: InternalReferentsProps) {
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [referent1Id, setReferent1Id] = useState<number | null>(null);
  const [referent2Id, setReferent2Id] = useState<number | null>(null);

  const signataire = contacts.find((elt) => elt.contact_type === 2) || null;

  const referents = useMemo(() => {
    return contacts
      .filter((elt) => elt.contact_type === (isCreator ? 3 : 1))
      .sort((a, b) => a.id - b.id);
  }, [contacts]);

  const resetValues = () => {
    if (referents[0]) setReferent1Id(referents[0].contactUser?.id || 0);
    if (referents[1]) setReferent2Id(referents[1].contactUser?.id || 0);
  };

  const fullName = (contactId: number | null) => {
    const contact = contacts.find((c) => c.contactUser?.id === contactId);
    if (!contact) return '';
    return `${contact?.firstname} ${contact?.lastname}`;
  };

  const getReferent = (contactId: number | null) => {
    return contacts.find((c) => c.contactUser?.id === contactId);
  };

  const onSelectReferent = (name: string, type: 1 | 2) => {
    const referent = list.find((c) => `${c.firstname} ${c.lastname}` === name);

    if (referent) {
      if (type === 1) setReferent1Id(referent.contact_user_id || 0);
      else setReferent2Id(referent.contact_user_id || 0);
    }
  };

  const onSubmit = async () => {
    const ref1 = list.find((c) => referent1Id === c.id);
    const ref2 = list.find((c) => referent2Id === c.id);

    if (ref1) {
      setIsLoading(true);
      const res = await updateReferents(
        contractType,
        contractId,
        ref1,
        ref2 || null,
        isConvention || false,
        isCreator
      );
      setIsLoading(false);

      if (res) {
        onRefresh();
        setIsEditMode(false);
      }
    }
  };

  useEffect(() => {
    resetValues();
  }, [contacts]);

  const signataireRender = (
    <div className="grid grid-cols-2 gap-4 border border-transparent border-b-borderGrey pb-4">
      <InfosWithIcon
        infos={[
          {
            title: t('contract.signatory'),
            subtitle: `${signataire?.firstname} ${signataire?.lastname}`,
          },
        ]}
        addClass="w-1/2"
        icon={<SignataryIcon />}
      />
      <InfosWithIcon
        infos={[
          {
            title: t('infos.email'),
            subtitle: signataire?.email || '',
          },
        ]}
      />
      <InfosWithIcon
        infos={[
          {
            title: t('convention.tab_info_general.function'),
            subtitle: signataire?.function || '',
          },
        ]}
        icon={<div />}
        colorIcon="transparent"
      />
    </div>
  );

  const referentInfo = (title: string, name: string, email: string) => {
    return (
      <div className="grid grid-cols-2">
        <InfosWithIcon
          infos={[{ title, subtitle: name }]}
          icon={<ContactPageIcon />}
        />
        <InfosWithIcon infos={[{ title: t('infos.email'), subtitle: email }]} />
      </div>
    );
  };

  const renderInformation = (
    <div className="flex flex-col gap-4">
      {signataireRender}

      {!isEditMode ? (
        <div className="grid gap-4 divide-y divide-borderGrey">
          {referentInfo(
            t('contract.referent_primary'),
            fullName(referent1Id),
            getReferent(referent1Id)?.email || ''
          )}
          <div className="pt-4">
            {referentInfo(
              t('contract.referent_secondary'),
              fullName(referent2Id) || '-',
              getReferent(referent2Id)?.email || '-'
            )}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4">
          <InputSelect
            placeholder=""
            dataArrayString={list.map(
              (elt) => `${elt.firstname} ${elt.lastname}`
            )}
            defaultSelected={fullName(referent1Id)}
            addClass="w-full"
            label={`${t('contract.referent_primary')}`}
            onSelect={(name) => onSelectReferent(name, 1)}
            required
          />
          <InputSelect
            placeholder=""
            dataArrayString={list.map(
              (elt) => `${elt.firstname} ${elt.lastname}`
            )}
            defaultSelected={fullName(referent2Id)}
            addClass="w-full"
            label={`${t('contract.referent_secondary')}`}
            onSelect={(name) => onSelectReferent(name, 2)}
            showClearButton={referent2Id != null}
            onClear={() => setReferent2Id(null)}
          />
        </div>
      )}
    </div>
  );

  return (
    <Card
      isLoading={isLoading}
      addClass="mb-[2rem]"
      title={`${t('contract.referents')}`}
      actionButtons={
        <div>
          {!isEditMode ? (
            <button
              type="button"
              onClick={() => setIsEditMode(!isEditMode)}
              style={{ color: blueOpx }}
            >
              {t('buttons.update')}
            </button>
          ) : (
            <div className="flex gap-3">
              <ButtonOpx
                label={t('buttons.cancel')}
                type="secondary"
                small
                onClick={() => {
                  resetValues();
                  setIsEditMode(false);
                }}
              />
              <ButtonOpx label={t('buttons.save')} small onClick={onSubmit} />
            </div>
          )}
        </div>
      }
    >
      <div>{renderInformation}</div>
    </Card>
  );
}

export { InternalReferents };

InternalReferents.defaultProps = {
  isConvention: false,
};
