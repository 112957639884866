import {
  ContactPageIcon,
  EuroIcon,
  InfoCircleIcon,
  PositionIcon,
} from '@assets/images/svgComponents';

import { t } from 'i18next';
import { numberWithSeparator } from '@utils/format';
import { CardInformations } from '@components/informations/CardInformations';
import { IBusinessProviderType } from '@models/partners/utils/types/partnersType';
import { useEffect, useState } from 'react';

interface TabBusinessProvidersProps {
  businessProvider: IBusinessProviderType; // either for convention or contract(mandant)
}
function TabBusinessProviders({ businessProvider }: TabBusinessProvidersProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const renderBusinessProvider = (
    identity: string,
    businessProviderInfos: any
  ) => {
    return (
      <div className="py-[0.5rem] grid gap-x-0 md:gap-x-[1.5%] gap-y-4 grid-cols-1">
        <div>
          <CardInformations
            title={`${t('contract.business.tab_title')} ${identity}`}
            canModify={false}
            data={{
              status: undefined,
              rows: [
                [
                  {
                    name: 'company_name',
                    title: t('partners.company_name'),
                    value: businessProviderInfos.entity.company_name,
                    icon: <InfoCircleIcon />,
                  },
                  {
                    name: 'siret',
                    title: t('partners.siret'),
                    value: businessProviderInfos.entity.siret,
                  },
                ],
                [
                  {
                    name: 'postal_address',
                    title: t('partners.postal_address'),
                    value: {
                      address: businessProviderInfos.entity.address,
                      postal_code: businessProviderInfos.entity.zipcode,
                      city: businessProviderInfos.entity.city,
                      country: businessProviderInfos.entity.country,
                    },
                    icon: <PositionIcon />,
                    isEditable: true,
                    type: 'address',
                  },
                  {
                    name: 'additional_address',
                    title: t('partners.additional_address'),
                    value: businessProviderInfos.entity.additional_address,
                    isEditable: true,
                  },
                ],
                [
                  {
                    name: 'partner_firstname_lastname',
                    title: t('partners.business_provider.firstname_lastname'),
                    value: businessProviderInfos?.user.firstname
                      ? `${businessProviderInfos?.user.firstname} ${businessProviderInfos?.user.lastname}`
                      : '-',
                    icon: <ContactPageIcon />,
                    isEditable: true,
                  },
                  {
                    name: 'partner_firstname',
                    title: t('partners.firstname'),
                    value: businessProviderInfos?.user.firstname,
                    isEditable: true,
                    required: true,
                  },
                  {
                    name: 'partner_lastname',
                    title: t('partners.lastname'),
                    value: businessProviderInfos?.user.lastname,
                    isEditable: true,
                    required: true,
                  },
                  {
                    name: 'email',
                    title: t('partners.email_address'),
                    value: businessProviderInfos?.user.email,
                    type: 'email',
                    isEditable: true,
                    required: true,
                  },
                  {
                    name: 'phone_number',
                    title: t('forms.phone.placeholder'),
                    value: businessProviderInfos?.user.phone_number,
                    type: 'phone',
                    isEditable: true,
                  },
                ],
                [
                  {
                    name: 'commission',
                    title: t('contract.business.tab.commissions'),
                    value: `${numberWithSeparator(
                      String(
                        businessProviderInfos?.minimum_bonus_allocation || ''
                      )
                    )}€/MWhc`,
                    icon: <EuroIcon />,
                    isEditable: true,
                  },
                ],
              ],
            }}
            loading={isLoading}
            dataTestId="informations_card"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Set loading to false after 1 second
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, [businessProvider]);

  return (
    <div className="w-full">
      {renderBusinessProvider('1', businessProvider.business_provider_1)}
      {businessProvider.business_provider_2 &&
        renderBusinessProvider('2', businessProvider.business_provider_2)}
    </div>
  );
}

export { TabBusinessProviders };
