import { cloneElement, useState, ReactNode, useContext } from 'react';
import { getIconAndCharacSize } from '@utils/utils';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { blueOpx, white } from '@assets/color';
import { convertHexToRGBA } from '@utils/functions';
import { ThemeContext } from '@context/ThemeContext';

type IColorCubeProps = {
  size: '1rem' | '1.5rem' | '2rem' | '2.5rem';
  /**
   * Peut être un nombre, une chaîne (ex: "5bis") ou un composant React (ex: <CheckIcon />).
   */
  numberOrIcon: number | string | JSX.Element;
  color: string;
  backgroundColor?: string;
  onClick?: () => void;
  /**
   * `true` => background semi-transparent (10%) + texte/icône colorisés
   * `false` => background color uni + texte/icône en blanc
   */
  opacity?: boolean;
  borderRadius?: string;
  addClass?: string;
  dataTestId?: string;
  /**
   * Active/désactive l'effet hover (changement de colorisation si on est au-dessus).
   */
  hover?: boolean;
  /**
   * Petit compteur (badge) qui s'affiche en haut à droite du cube.
   */
  counter?: number;
};

/**
 * ColorCube
 * ----------
 * Affiche un petit carré coloré, qui peut contenir un texte, un nombre ou une icône.
 *
 * - `opacity` est à true si on souhaite un background (10%) de la couleur principale,
 *   tout en gardant l'élément (texte ou icône) coloré.
 * - `opacity` est à false => le background est la couleur principale, et le contenu est blanc.
 * - `hover` permet de changer le rendu quand la souris passe dessus (ex: forcer la couleur blanche à la place ?).
 * - `counter` affiche un badge en haut à droite du cube.
 */

function ColorCube({
  numberOrIcon,
  color,
  size,
  backgroundColor,
  onClick,
  opacity,
  borderRadius,
  addClass,
  dataTestId,
  hover,
  counter,
}: IColorCubeProps): JSX.Element {
  const { themeData } = useContext(ThemeContext);

  const secondaryThemeData = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
  );

  // Récupère les tailles (pour l'icône ou la taille du texte)
  const iconAndCharacSize = getIconAndCharacSize(size);
  const iconSize = iconAndCharacSize.icon;
  const characSize = iconAndCharacSize.charac;

  const [mouseIsOver, setMouseIsOver] = useState<boolean>(false);

  const secondaryTextColor = secondaryThemeData?.text_color || white;
  const secondaryBackgroundColor =
    color || secondaryThemeData?.background_color;

  const opacityColor = secondaryThemeData
    ? convertHexToRGBA(secondaryThemeData.background_color, 0.4)
    : convertHexToRGBA(color, 0.1);

  const colorIsBlueOpx = color === blueOpx;
  const fillColor = colorIsBlueOpx
    ? secondaryThemeData?.text_color || color
    : color;

  let contentToRender: ReactNode;
  if (typeof numberOrIcon === 'string' || typeof numberOrIcon === 'number') {
    contentToRender = numberOrIcon;
  } else {
    contentToRender =
      numberOrIcon &&
      cloneElement(numberOrIcon as JSX.Element, {
        fill:
          (opacity && !mouseIsOver) || backgroundColor
            ? fillColor
            : secondaryTextColor,
        width: iconSize,
        height: iconSize,
      });
  }

  // Couleur du background global du carré
  const finalBackgroundColor =
    backgroundColor ||
    (opacity && !mouseIsOver ? opacityColor : secondaryBackgroundColor);

  // Couleur du contenu (texte) si c’est un string ou un number
  // (si c’est une icône, on a déjà géré sa couleur via cloneElement)
  const finalTextColor =
    (opacity && !mouseIsOver) || backgroundColor
      ? fillColor
      : secondaryTextColor;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onMouseOver={() => hover && setMouseIsOver(true)}
      onFocus={() => hover && setMouseIsOver(true)}
      onMouseLeave={() => hover && setMouseIsOver(false)}
      style={{
        width: size,
        minWidth: size,
        height: size,
        minHeight: size,
        backgroundColor: finalBackgroundColor,
        color: finalTextColor,
        borderRadius,
      }}
      className={[
        'relative flex items-center justify-center',
        onClick ? 'cursor-pointer' : '',
        characSize,
        addClass,
      ].join(' ')}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
      data-test-id={dataTestId}
    >
      {contentToRender}

      {/* Affichage du "badge" si nécessaire */}
      {counter && (
        <div
          className="absolute top-0 right-0 flex items-center justify-center text-[0.75rem] bg-[#FF0000] rounded-default p-[.125rem] min-w-[1.125rem]"
          style={{ transform: 'translate(50%,-50%)' }}
        >
          {counter}
        </div>
      )}
    </div>
  );
}

export { ColorCube };

ColorCube.defaultProps = {
  onClick: null,
  backgroundColor: null,
  opacity: false,
  borderRadius: '4px',
  addClass: '',
  dataTestId: '',
  hover: false,
  counter: undefined,
};
