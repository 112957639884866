/* eslint-disable no-nested-ternary */
import { AuthContext } from '@context/authContext';
import { getBeneficiaryName } from '@models/worksiteCreation/utils/functions';
import { WorksitesCardGeneralInfos } from '@models/worksites/components/worksitesList/WorksitesCardGeneralInfos';
import { WorksitesCardOperations } from '@models/worksites/components/worksitesList/WorksitesCardOperations';
import { WorksitesCardSummary } from '@models/worksites/components/worksitesList/WorksitesCardSummary';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { isMandatory } from '@utils/roles';
import {
  ADMIN_ROUTES_WITH_ID,
  WORKSITES_ROUTES_WITH_ID,
} from '@utils/routesUrls';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { IKeyStringType } from '../../../../types/globalTypes';
import { IWorksiteDetails } from '../../utils/worksitesTypes';

type IWorksitesCardProps = {
  worksiteDetails: IWorksiteDetails;
  isCompact?: boolean;
  adminMode?: boolean;
};

function WorksitesCard({
  worksiteDetails,
  isCompact,
  adminMode,
}: IWorksitesCardProps): JSX.Element {
  const { user } = useContext(AuthContext);
  const [createWorksiteStep, setCreateWorksiteStep] = useState(false);

  const generalInfosTab: IKeyStringType = useMemo(() => {
    if (Number(worksiteDetails.status) === WORKSITE_STATUS.WORKSITE_CREATION)
      setCreateWorksiteStep(true);
    const partner = isMandatory(user)
      ? worksiteDetails.installer
      : worksiteDetails.entity_to;

    const name = worksiteDetails.site_name
      ? worksiteDetails.site_name
      : worksiteDetails.beneficiary !== null
      ? getBeneficiaryName(worksiteDetails.beneficiary)
      : '';

    const address = worksiteDetails.address
      ? `${worksiteDetails.address.address} ${worksiteDetails.address.postal_code} ${worksiteDetails.address.city}`
      : worksiteDetails.created_by
      ? worksiteDetails.created_by.address
      : '';
    return {
      name,
      ref: `${worksiteDetails.reference}`,
      address: String(address || ''),
      entity: worksiteDetails.installer.company_name || '',
      contract:
        partner && partner.company_name
          ? partner.company_name
          : worksiteDetails.convention.from &&
            worksiteDetails.convention.from.company_name
          ? worksiteDetails.convention.from.company_name
          : '',
      rai: worksiteDetails.convention.rai.company_name || '',
      incentive_type: worksiteDetails.incentive_type?.toString(),
    };
  }, [worksiteDetails]);

  const handleLinkToWorksite = () => {
    if (Number(worksiteDetails.status) === WORKSITE_STATUS.CANCELED) {
      return WORKSITES_ROUTES_WITH_ID(worksiteDetails.id).WORKSITES_CANCELED_ID;
    }
    return createWorksiteStep
      ? adminMode
        ? ADMIN_ROUTES_WITH_ID(worksiteDetails.id).ADMIN_WORKSITE_NEW_ID
        : WORKSITES_ROUTES_WITH_ID(worksiteDetails.id).WORKSITES_NEW_ID
      : adminMode
      ? ADMIN_ROUTES_WITH_ID(worksiteDetails.id).ADMIN_WORKSITE_VIEW
      : WORKSITES_ROUTES_WITH_ID(worksiteDetails.id).WORKSITES_VIEW;
  };

  return (
    <Link to={handleLinkToWorksite()}>
      <div
        className="w-full bg-white border cursor-pointer border-borderGrey rounded-default"
        data-test-id={`worksites_card${
          worksiteDetails.worksites_operations?.length === 0 ||
          createWorksiteStep
            ? '_no_operations'
            : ''
        }`}
      >
        <div className="flex items-start flex-col sm:flex-row">
          <WorksitesCardGeneralInfos
            infos={generalInfosTab}
            isCompact={isCompact}
            adminMode={adminMode}
          />
          <WorksitesCardOperations details={worksiteDetails} />
        </div>
        <WorksitesCardSummary details={worksiteDetails} />
      </div>
    </Link>
  );
}

export { WorksitesCard };

WorksitesCard.defaultProps = {
  isCompact: undefined,
  adminMode: false,
};
