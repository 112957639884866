import { blueOpx, green, orange, red, textGrey } from '@assets/color';
import { BriefcaseIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { Card } from '@components/Card';
import { useContext, useState } from 'react';
import { Tag } from '@components/atomic/Tag';
import { GlobalContext } from '@context/globalContext';
import { DOCUMENT_STATUS_ENUM } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { InputText } from '@components/atomic/inputs/InputText';
import { FormProvider, useForm } from 'react-hook-form';
import { LoaderCheckpoints } from './LoaderCheckpoints';
import { LinkedFileCheckpointContext } from '../utils/linkedFileCheckpointContext';
import {
  getErrorMessage,
  updateCheckpoint,
  isWrongResponseValue,
} from '../utils/utils';
import { CheckpointResponse } from './CheckpointResponse';

function MiddlePartCheckpoints() {
  const { t } = useTranslation();

  const methods = useForm();

  const {
    globalEnum: { linked_file_status },
  } = useContext(GlobalContext);

  const {
    document,
    isLoading,
    controlCounts,
    checkpoints,
    updateWorksiteCheckpoints,
  } = useContext(LinkedFileCheckpointContext);
  const readOnly = document.status !== DOCUMENT_STATUS_ENUM.PENDING;

  const [initMailIsOpen, setInitMailIsOpen] = useState(false);

  const tagColor = {
    [DOCUMENT_STATUS_ENUM.REFUSE]: red,
    [DOCUMENT_STATUS_ENUM.VALIDATE]: green,
  };

  const rightPartElement = window.document.getElementById(
    'right_part_checkpoints'
  );

  const isPending = document.status === DOCUMENT_STATUS_ENUM.PENDING;

  return (
    <div
      className="rounded-[.5rem] flex flex-col gap-[1rem] w-[60%]"
      style={{
        height: rightPartElement?.clientHeight || '90vh',
        overflowY: 'auto',
      }}
    >
      <Card
        title=""
        addClass={checkpoints.length === 0 ? 'h-full' : 'w-full'}
        icon={
          <div className="flex items-center gap-2">
            <ColorCube
              color={blueOpx}
              opacity
              size="1.5rem"
              numberOrIcon={<BriefcaseIcon />}
            />
            <p>{t('checkpoints.card_title')}</p>
            <div>
              <Tag
                label={linked_file_status[document.status]}
                color={tagColor[document.status] || textGrey}
              />
            </div>
          </div>
        }
        headerButton={
          controlCounts ? (
            <div className="flex items-center gap-5 text-[.75rem]">
              <div style={{ color: isPending ? orange : textGrey }}>
                {t('checkpoints.count_to_control', {
                  number: controlCounts.to_control,
                })}
              </div>
              <div style={{ color: isPending ? green : textGrey }}>
                {t(
                  `checkpoints.count_compliant_${
                    controlCounts.compliant > 1 ? 'plural' : 'singular'
                  }`,
                  {
                    number: controlCounts.compliant,
                  }
                )}
              </div>
              <div style={{ color: isPending ? red : textGrey }}>
                {t(
                  `checkpoints.count_non_compliant_${
                    controlCounts.non_compliant > 1 ? 'plural' : 'singular'
                  }`,
                  {
                    number: controlCounts.non_compliant,
                  }
                )}
              </div>
            </div>
          ) : undefined
        }
      >
        <FormProvider {...methods}>
          <div className="flex flex-col gap-3">
            {!isLoading ? (
              checkpoints.map((checkpoint) => {
                return (
                  <div key={checkpoint.id} className="flex flex-col gap-2">
                    <div className="border border-borderGrey rounded-[.5rem] px-[1rem] py-3 flex justify-between">
                      <div className="w-full">
                        {checkpoint.checkpoint.question}
                      </div>
                      <CheckpointResponse
                        checkpoint={checkpoint}
                        initMailIsOpen={initMailIsOpen}
                        setInitMailIsOpen={setInitMailIsOpen}
                      />
                    </div>
                    {isWrongResponseValue(checkpoint, checkpoint.value) && (
                      <div className="border border-danger rounded-[.5rem] px-[1rem] py-3  flex justify-between bg-dangerOpacity">
                        <InputText
                          id={String(checkpoint.id)}
                          name={String(checkpoint.id)}
                          placeholder=""
                          addClass="w-full p-[0] m-[0]"
                          addClassToInput="border-none p-[0] m-[0] bg-transparent"
                          value={getErrorMessage(checkpoint)}
                          hideLabel
                          hideIsOptional
                          onChange={(e) =>
                            !readOnly &&
                            updateCheckpoint(
                              document,
                              checkpoint,
                              'custom_conformity_description',
                              String(e),
                              updateWorksiteCheckpoints
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <LoaderCheckpoints />
            )}
          </div>
        </FormProvider>
      </Card>
    </div>
  );
}

export { MiddlePartCheckpoints };
