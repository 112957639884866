import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { GlobalContext } from '@context/globalContext';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { InputTypes } from '@utils/utils';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { getOperationsList } from '@apiRequests/globalRequests';
import { ILabelValue } from '@globalTypes/globalTypes';
import { CHECKPOINT_RESPONSE_TYPE } from '@models/settings/utils/enums';
import { findKeyByValue, useAutomaticScroll } from '@utils/functions';
import {
  storeCheckpoint,
  updateCheckpoint,
} from '@models/checkpoints/apiRequests/checkpointsRequests';
import {
  ICheckpoints,
  IFormValuesStoreCheckpoint,
} from '@models/checkpoints/utils/checkpointsTypes';
import { format, max, min, parseISO } from 'date-fns';
import ResponseArrayInputs from './ResponseArrayInputs';
import ResponseScaleInputs from './ResponseScaleInputs';
import { getDefaultValues } from '../utils/utils';

interface IModalAddCheckpointProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getData: () => Promise<void>;
  checkpoint?: ICheckpoints;
}

function ModalAddCheckpoint({
  setIsOpen,
  getData,
  checkpoint,
}: IModalAddCheckpointProps) {
  const { globalEnum } = useContext(GlobalContext);

  const { checkpoint_response_type, linked_file_type, beneficiaries_type } =
    globalEnum;

  const isEditMode = !!checkpoint;

  // Préparer les valeurs par défaut en fonction du mode édition

  const methods = useForm<IFormValuesStoreCheckpoint>({
    defaultValues: getDefaultValues(checkpoint, globalEnum),
  });
  const { setValue, handleSubmit } = methods;

  const formValues = methods.watch();
  const { t } = useTranslation();

  const [operationsList, setOperationsList] = useState<IOperationType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Ajout d'une référence pour le conteneur modal
  const modalContentRef = useRef<HTMLDivElement>(null);

  const formatEnumToLabelValue = (objEnum: Record<number, string>) => {
    return Object.entries(objEnum).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  };

  const operationsOptions = operationsList.map((op) => op.code);

  const translationBase = 'settings.checkpoints.modal_add_checkpoint';

  const getOperationData = async (beneficiaryType?: number) => {
    setIsLoading(true);
    const response = await getOperationsList(undefined, beneficiaryType);
    if (response.data) {
      setOperationsList(response.data);
      if (!isEditMode) {
        setValue('operations', []);
      }
    }
    setIsLoading(false);
  };

  type IInput = {
    name: string;
    label: string;
    placeholder: string;
    inputType: (typeof InputTypes)[keyof typeof InputTypes];
    options?: ILabelValue[] | string[];
    hide?: boolean;
    required: boolean;
    initialValue?: string | string[];
    onClick?: () => void;
  };

  const topInputValues: IInput[] = [
    {
      name: 'question',
      label: `${t(`${translationBase}.question`)}`,
      placeholder: `${t(`${translationBase}.placeholder`)}`,
      inputType: InputTypes.TEXT,
      required: true,
    },
    {
      name: 'response_type',
      label: `${t(`${translationBase}.response_type`)}`,
      placeholder: `${t(`${translationBase}.response_type`)}`,
      options: formatEnumToLabelValue(checkpoint_response_type),
      inputType: InputTypes.SELECT,
      required: true,
      initialValue: checkpoint
        ? checkpoint_response_type[checkpoint.response_type]
        : '',
    },
  ];

  const bottomInputValues: IInput[] = [
    {
      name: 'conformity_description',
      label: `${t(`${translationBase}.conformity_description`)}`,
      placeholder: `${t(
        `${translationBase}.conformity_description_placeholder`
      )}`,
      inputType: InputTypes.TEXT,
      required: true,
      hide:
        formValues.response_values.every((r) => r.valid !== false) ||
        formValues.response_type === CHECKPOINT_RESPONSE_TYPE.TEXT,
    },
    {
      name: 'linked_file_type',
      label: `${t(`${translationBase}.linked_file_type`)}`,
      placeholder: '',
      options: Object.values(linked_file_type),
      inputType: InputTypes.MULTIPLE_SELECT,
      required: true,
      initialValue: checkpoint
        ? checkpoint.file_types?.map((type) => linked_file_type[Number(type)])
        : [],
    },
    {
      name: 'beneficiary_type',
      label: `${t(`${translationBase}.beneficiaries_type`)}`,
      placeholder: `${t(`${translationBase}.beneficiaries_type`)}`,
      options: formatEnumToLabelValue(beneficiaries_type).map((val) =>
        val.value === '3' ? { ...val, label: 'Tous' } : val
      ),
      inputType: InputTypes.SELECT,
      required: true,
      initialValue: checkpoint
        ? beneficiaries_type[checkpoint.beneficiary_type]
        : undefined,
    },
    {
      name: 'operations',
      label: `${t(`${translationBase}.operations`)}`,
      placeholder: '',
      options: operationsOptions,
      inputType: InputTypes.MULTIPLE_SELECT,
      required: false,
      initialValue: checkpoint
        ? checkpoint.operations?.map((op) => op.code)
        : [],
    },
  ];

  const getInputRender = (input: IInput) =>
    input.hide ? null : (
      <div>
        <DynamicInputEdit
          key={input.name}
          isEditMode
          inputType={input.inputType}
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          options={input.options}
          required={input.required}
          initialValue={input.initialValue}
        />
      </div>
    );

  const saveData = async (formData: FieldValues) => {
    setIsLoading(true);

    // Appeler l'API de création ou de mise à jour selon le mode
    const response =
      isEditMode && checkpoint
        ? await updateCheckpoint(checkpoint.id, formData)
        : await storeCheckpoint(formData);

    if (response.data) {
      setIsOpen(false);
      getData();
    }
    setIsLoading(false);
  };

  const getMinMaxValue = (formData: IFormValuesStoreCheckpoint) => {
    const val1 = formData.response_values[0].value;
    const val2 = formData.response_values[1].value;

    const responseType = formData.response_type;

    if (responseType === CHECKPOINT_RESPONSE_TYPE.DATE) {
      const date1 = parseISO(val1);
      const date2 = parseISO(val2);

      return {
        minValue: format(min([date1, date2]), 'yyyy-MM-dd'),
        maxValue: format(max([date1, date2]), 'yyyy-MM-dd'),
      };
    }
    return {
      minValue: Math.min(Number(val1), Number(val2)).toString(),
      maxValue: Math.max(Number(val1), Number(val2)).toString(),
    };
  };

  const onSubmit = (formData: IFormValuesStoreCheckpoint) => {
    const operationIds = formData.operations.map((op: string) => {
      return operationsList.find((operation) => operation.code === op)?.id;
    });

    const fileTypes = formData.linked_file_type.map((fileType: string) => {
      return findKeyByValue(linked_file_type, fileType);
    });

    const data: FieldValues = {
      ...formData,
      operation_ids: operationIds,
      file_types: fileTypes,
    };

    if (formData.comparator) {
      const response1 = formData.response_values[0];

      if (formData.comparator === '≥ ≤') {
        const response2 = formData.response_values[1];
        const { minValue, maxValue } = getMinMaxValue(formData);

        data.response_values = [
          { ...response1, value: `>=${minValue}` },
          { ...response2, value: `<=${maxValue}` },
        ];
      } else {
        data.response_values = [
          { ...response1, value: `${formData.comparator}${response1.value}` },
        ];
      }
    }

    saveData(data);
  };

  const emptyValue = { value: '', valid: true };

  const responseInputs = useMemo(() => {
    const hasChanged =
      !checkpoint || checkpoint.response_type !== formValues.response_type;

    if (hasChanged) {
      setValue('comparator', '');
    }

    switch (formValues.response_type) {
      case CHECKPOINT_RESPONSE_TYPE.BOOLEAN:
      case CHECKPOINT_RESPONSE_TYPE.LIST:
        if (hasChanged) {
          setValue('response_values', [emptyValue, emptyValue]);
        }
        return <ResponseArrayInputs />;
      case CHECKPOINT_RESPONSE_TYPE.NUMBER:
      case CHECKPOINT_RESPONSE_TYPE.DATE:
        if (hasChanged) {
          setValue('response_values', [{ ...emptyValue, valid: false }]);
        }
        return <ResponseScaleInputs checkpoint={checkpoint} />;
      default:
        return null;
    }
  }, [formValues.response_type]);

  useAutomaticScroll(modalContentRef); // pour faire défiler la modal jusqu'en bas lorsque la liste déroulante n'est plus visible

  useEffect(() => {
    if (formValues.beneficiary_type || !isEditMode) {
      getOperationData(formValues.beneficiary_type);
    }
  }, [formValues.beneficiary_type]);

  useEffect(() => {
    if (!isEditMode) {
      setValue('operations', []);
      setValue('linked_file_type', []);
    }
  }, []);

  return (
    <Modal
      title={
        isEditMode
          ? `${t('settings.checkpoints.edit_checkpoint')}`
          : `${t('settings.checkpoints.new_checkpoint')}`
      }
      backgroundTransparent
      sidebarVisible
      setIsModal={setIsOpen}
      btnCancel
      textBtnConfirm={`${t('buttons.confirm')}`}
      onConfirmClick={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <FormProvider {...methods}>
        <div
          ref={modalContentRef}
          className="flex flex-col gap-[1.5rem] mt-[2rem] overflow-y-auto max-h-[75vh]"
        >
          {topInputValues.map((input) => getInputRender(input))}
          {responseInputs && responseInputs}
          {bottomInputValues.map((input) => getInputRender(input))}
        </div>
      </FormProvider>
    </Modal>
  );
}

ModalAddCheckpoint.defaultProps = {
  checkpoint: undefined,
};

export default ModalAddCheckpoint;
