import { TextWithCheckboxOrToggle } from '@components/atomic/inputs/controls/TextWithCheckboxOrToggle';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import { CONTACT_CONTROL_POINT_QUESTION_TYPE } from '@models/worksites/utils/enums';
import { IContactControlPoint } from '@models/worksites/utils/worksitesTypes';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';

interface ContactControlPointProps {
  contactControlPoint: IContactControlPoint;
  disabled?: boolean;
}

function ContactControlPoint({
  contactControlPoint,
  disabled,
}: ContactControlPointProps) {
  const { watch, setValue, getValues } = useFormContext();

  // Récupère la valeur brute (peut être `undefined`, `string` ou `array`)
  const rawValue = watch(`contactControlPoints.${contactControlPoint.id}`);
  let currentValue: string | string[] = '';

  if (
    contactControlPoint.question_type ===
      CONTACT_CONTROL_POINT_QUESTION_TYPE.CHECKBOX ||
    contactControlPoint.question_type ===
      CONTACT_CONTROL_POINT_QUESTION_TYPE.MULTISELECT
  ) {
    // Ici on veut un tableau
    currentValue = Array.isArray(rawValue) ? rawValue : [];
  } else {
    // Sinon, on reste sur la logique existante (string, number, etc.)
    currentValue = rawValue || '';
  }

  switch (contactControlPoint.question_type) {
    case CONTACT_CONTROL_POINT_QUESTION_TYPE.TEXT:
      return (
        <InputText
          id={`contactControlPoints.${contactControlPoint.id}`}
          name={`contactControlPoints.${contactControlPoint.id}`}
          label={contactControlPoint.label}
          placeholder={contactControlPoint.placeholder}
          required={!contactControlPoint.optional}
          disabled={contactControlPoint.disabled || disabled}
          addClass="mb-5"
        />
      );

    case CONTACT_CONTROL_POINT_QUESTION_TYPE.DATE:
      return (
        <DatePicker
          label={contactControlPoint.label}
          required={!contactControlPoint.optional}
          onChangeDate={(value) =>
            setValue(`contactControlPoints.${contactControlPoint.id}`, value)
          }
          addClass="mb-5"
          defaultDate={
            getValues(`contactControlPoints.${contactControlPoint.id}`) ||
            undefined
          }
          disabled={contactControlPoint.disabled || disabled}
          placeholder={contactControlPoint.placeholder}
        />
      );

    case CONTACT_CONTROL_POINT_QUESTION_TYPE.SELECT:
      return (
        <InputSelect
          disabled={contactControlPoint.disabled || disabled}
          label={contactControlPoint.label}
          addClass="mb-5"
          dataLabelValue={contactControlPoint.question_values.values}
          placeholder={contactControlPoint.placeholder}
          required={!contactControlPoint.optional}
          onSelectLabelValue={(option) => {
            setValue(
              `contactControlPoints.${contactControlPoint.id}`,
              option.value
            );
          }}
          defaultSelected={
            contactControlPoint.question_values.values.find(
              (v) =>
                v.value ===
                getValues(`contactControlPoints.${contactControlPoint.id}`)
            )?.label
          }
        />
      );

    case CONTACT_CONTROL_POINT_QUESTION_TYPE.MULTISELECT: {
      // Récupérer un tableau de labels correspondant aux values présentes dans currentValue
      const multiSelectLabels = contactControlPoint.question_values.values
        .filter((opt) => currentValue.includes(opt.value))
        .map((opt) => opt.label);

      return (
        <InputSelect
          disabled={contactControlPoint.disabled || disabled}
          isMultipleSelect
          label={contactControlPoint.label}
          addClass="mb-5"
          colorSelection={contactControlPoint.question_values.color}
          dataLabelValue={contactControlPoint.question_values.values}
          placeholder={contactControlPoint.placeholder}
          required={!contactControlPoint.optional}
          defaultChecked={multiSelectLabels}
          onSelectLabelValue={(e) => {
            const currentValues =
              getValues(`contactControlPoints.${contactControlPoint.id}`) || [];
            if (currentValues.includes(e.value)) {
              setValue(
                `contactControlPoints.${contactControlPoint.id}`,
                currentValues.filter((val: string) => val !== e.value)
              );
            } else {
              setValue(`contactControlPoints.${contactControlPoint.id}`, [
                ...currentValues,
                e.value,
              ]);
            }
          }}
        />
      );
    }

    case CONTACT_CONTROL_POINT_QUESTION_TYPE.RADIO: {
      const radioOptions = contactControlPoint.question_values.values;

      return (
        <div className="mb-5">
          <div className="text-textGrey leading-3 text-[0.75rem] mb-2">
            {contactControlPoint.label}
          </div>
          <div className="flex flex-row justify-center gap-x-4">
            {radioOptions.map((qv) => {
              const optionValue = `${contactControlPoint.id}.${qv.value}`;
              const isChecked = currentValue === optionValue;
              const onSelect = () => {
                setValue(
                  `contactControlPoints.${contactControlPoint.id}`,
                  optionValue
                );
              };

              return (
                <TextWithRadio
                  key={qv.label}
                  label={qv.label}
                  value={optionValue}
                  addClass="flex-grow"
                  isChecked={isChecked}
                  setSelectedValue={onSelect}
                  disabled={contactControlPoint.disabled || disabled}
                />
              );
            })}
          </div>
        </div>
      );
    }

    case CONTACT_CONTROL_POINT_QUESTION_TYPE.CHECKBOX: {
      const checkboxOptions = contactControlPoint.question_values.values;
      const checkboxValues = currentValue as string[];

      return (
        <div className="mb-5">
          <div className="text-textGrey leading-3 text-[0.75rem] mb-2">
            {contactControlPoint.label}
          </div>
          <div className="flex flex-col gap-y-2">
            {checkboxOptions.map((checkbox) => {
              const isChecked = checkboxValues.includes(checkbox.value);

              const handleCheckboxCheck = (checked: boolean) => {
                if (checked) {
                  setValue(`contactControlPoints.${contactControlPoint.id}`, [
                    ...checkboxValues,
                    checkbox.value,
                  ]);
                } else {
                  setValue(
                    `contactControlPoints.${contactControlPoint.id}`,
                    checkboxValues.filter((val) => val !== checkbox.value)
                  );
                }
              };

              return (
                <TextWithCheckboxOrToggle
                  key={checkbox.label}
                  label={checkbox.label}
                  onCheck={handleCheckboxCheck}
                  type="checkbox"
                  checkOrTogglePosition="left"
                  checked={isChecked}
                  disabled={contactControlPoint.disabled || disabled}
                  isJustifyBetween={false}
                />
              );
            })}
          </div>
        </div>
      );
    }

    default:
      return null;
  }
}

ContactControlPoint.defaultProps = {
  disabled: false,
};

export default ContactControlPoint;
