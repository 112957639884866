import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from '@components/atomic/inputs/controls/Radio';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { getPartnersList } from '@models/partners/apiRequests/partnersRequest';
import { IPartnerType } from '@models/partners/utils/types/partnersType';
import { ModalAddPartnerForm } from '@models/partners/components/ModalAddPartnerForm';
import { ENTITY_TYPES } from '@utils/roles';
import { formatWord } from '@utils/format';

function StepPaymentDelegation() {
  const { t } = useTranslation();
  const { changeStep, updatePaymentEntityId, paymentEntityId } = useContext(
    ContractCreationContext
  );

  const [partnersList, setPartnersList] = useState<IPartnerType[]>([]);

  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [withDelegate, setWithDelegate] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState<boolean>(true);

  const getData = async (id?: number) => {
    setIsLoading(true);
    const resList = await getPartnersList({
      page: 1,
      perPage: 999,
      entityType: null,
      withInstallers: true,
    });
    setIsLoading(false);

    if (resList.data) {
      const list = resList.data as IPartnerType[];
      setPartnersList(list);

      const selectedPartner = list.find(
        (partner) => partner.id_partenaire === (id ?? paymentEntityId)
      );

      if (selectedPartner) {
        setInputValue(selectedPartner.company_name);
      }

      if (resList.data.length === 1) {
        updatePaymentEntityId(resList.data[0].id_partenaire);
      }
    }
  };

  const onSelectRadio = (value: boolean) => {
    setWithDelegate(value);
    if (value === false) {
      updatePaymentEntityId(null);
    }
  };

  const handleCloseModal = async (id: number) => {
    if (id > 0) {
      await getData(id);
      updatePaymentEntityId(id);
    }
  };

  const searchPartnerList = useMemo(() => {
    return partnersList.filter(
      (p) =>
        formatWord(p.company_name).includes(formatWord(searchValue)) ||
        p.siret.includes(searchValue)
    );
  }, [searchValue, partnersList]);

  useEffect(() => {
    if (withDelegate) {
      getData();
    }
  }, [withDelegate]);

  useEffect(() => {
    if (paymentEntityId) {
      setWithDelegate(true);
    }
  }, [paymentEntityId]);

  return (
    <div>
      <Card
        title={t('contract.payment_delegation.title')}
        actionButtons={
          <div className="flex gap-3">
            <ButtonOpx
              type="secondary"
              label={`${t('global.back')}`}
              onClick={() => changeStep('back')}
            />

            <ButtonOpx
              label={t('buttons.next')}
              onClick={() => changeStep('next')}
              dataTestId="button_next"
            />
          </div>
        }
      >
        <div>
          <Radio
            name="withPaymentDelegation"
            value="withPaymentDelegation"
            onSelect={() => onSelectRadio(true)}
            label={`${t('contract.payment_delegation.with')}`}
            isChecked={withDelegate}
            colorText="black"
            addClass="border rounded-default focus:outline-none w-full p-2 flex items-center gap-2 blueOpx mb-2"
            dataTestId="with_payment_delegation"
          />
          <Radio
            name="withoutPaymentDelegation"
            value="withoutPaymentDelegation"
            onSelect={() => onSelectRadio(false)}
            label={`${t('contract.payment_delegation.without')}`}
            isChecked={!withDelegate}
            colorText="black"
            addClass="border rounded-default focus:outline-none w-full p-2 flex items-center gap-2 blueOpx"
            dataTestId="without_payment_delegation"
          />
          {withDelegate && (
            <div className="mt-[1rem] flex items-end justify-between gap-3">
              <InputSelect
                label={`${t('contract.payment_delegation.delegate')}`}
                placeholder=""
                addClass="w-2/3"
                required
                isLoading={isLoading}
                onSelectLabelValue={(item) => {
                  updatePaymentEntityId(Number(item.value));
                }}
                valueInput={inputValue}
                callbackOnSearch={(e) => {
                  setInputValue(e);
                  setSearchValue(e);
                  setCloseDropdown(false);
                }}
                closeDropdown={closeDropdown}
                setCloseDropdown={setCloseDropdown}
                isAutoComplete
              >
                {!closeDropdown ? (
                  <div id="dropdown">
                    {searchPartnerList.map((item) => (
                      <button
                        className="dropdown-item"
                        type="button"
                        key={`one_partner_in_menu_${item.id_partenaire}`}
                        data-test-id={`partner_${item.id_partenaire}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          updatePaymentEntityId(Number(item.id_partenaire));
                          setCloseDropdown(true);
                          setInputValue(item.company_name);
                        }}
                      >
                        {item.company_name}
                      </button>
                    ))}
                  </div>
                ) : (
                  <div />
                )}
              </InputSelect>
              <ButtonOpx
                label={`${t('contract.payment_delegation.new')}`}
                addClass="w-1/3 mb-[.2rem]"
                onClick={() => setShowModal(true)}
              />
            </div>
          )}
          {showModal && (
            <ModalAddPartnerForm
              entityType={ENTITY_TYPES.APPORTEUR_AFFAIRES}
              setModal={setShowModal}
              refreshList={getData}
              isPaymentDelegate
              onClose={(res) => handleCloseModal(Number(res.id || 0))}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export { StepPaymentDelegation };
