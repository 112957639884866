/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate, useParams } from 'react-router-dom';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '@context/headerContext';
import { GlobalContext } from '@context/globalContext';
import { Tag } from '@components/atomic/Tag';
import { blueSecondary, textGrey } from '@assets/color';
import { LoaderDeposit } from '@components/loaders/LoaderDeposit';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { Modal } from '@components/Modal';
import { PARTNERS_ROUTES, PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { ControlContactPageHeader } from './ControlContactPageHeader';
import {
  controlContactFakeData,
  controlContactLinkedListFakeData,
} from '../utils/fakeData';
import {
  IControlContactType,
  IControlContactWorksiteOperationType,
} from '../utils/controlContactTypes';
import { SetOperationList } from './SetOperationList';
import { controlContactStatusEnum } from '../utils/utlis';
import {
  getControlContactInfos,
  getControlContactOperationsConsult,
  postControlContactValidateControl,
  postControlContactValidateLot,
} from '../apiRequests/controlContactRequests';

type IControlContactProps = {
  isInProgress: boolean;
};

function ControlContactDetails({
  isInProgress,
}: IControlContactProps): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    updateTitleHeader,
    updateTagHeader,
    updateDisplayBackButtonHeader,
    refreshHeader,
  } = useContext(HeaderContext);
  const { globalEnum, route, updateRoute } = useContext(GlobalContext);

  const [controlContact, setControlContact] = useState<IControlContactType>();
  const [isLoadingToLink, setIsLoadingToLink] = useState(false);
  const [isLoadingLinked, setIsLoadingLinked] = useState(false);
  const [isLoadingConsult, setIsLoadingConsult] = useState(false);
  const [dataLinked, setDataLinked] = useState<
    IControlContactWorksiteOperationType[]
  >([]);
  const [dataToLink, setDataToLink] = useState<
    IControlContactWorksiteOperationType[]
  >(controlContactLinkedListFakeData as any[]);
  const [lotOperations, setLotOperations] = useState<
    IControlContactWorksiteOperationType[]
  >([]);

  const [sortAndFilterLinked, setSortAndFilterLinked] =
    useState<ISortAndFilterType>();
  const [sortAndFilterToLink, setSortAndFilterToLink] =
    useState<ISortAndFilterType>();
  const [sortAndFilterConsult, setSortAndFilterConsult] =
    useState<ISortAndFilterType>();

  const [searchLinked, setSearchLinked] = useState<string | null>(null);
  const [searchToLink, setSearchToLink] = useState<string | null>(null);
  const [searchConsult, setSearchConsult] = useState<string | null>(null);

  const [tab, setTab] = useState<string>(`${t('control_contact.tab_suivi')}`);

  const [modalConfirmValidationLot, setModalConfirmValidationLot] =
    useState(false);
  const [modalConfirmValidationControl, setModalConfirmValidationControl] =
    useState(false);
  const [isLoadingValidateLot, setIsLoadingValidateLot] = useState(false);

  const [resetSignal, setResetSignal] = useState<number>(0);

  const getControlContact = async () => {
    const res = await getControlContactInfos(Number(id), isInProgress);
    if (res) {
      setControlContact(res);
    } else {
      setControlContact(controlContactFakeData[0] as any);
    }
  };

  const getDataToLink = async (search: string | null = null) => {
    setIsLoadingToLink(true);
    const resToLink = controlContact?.linkable_worksites_operations;
    if (resToLink) {
      console.log('resToLink', resToLink);
    }
    setIsLoadingToLink(false);
  };

  const getDataLinked = async (search: string | null = null) => {
    setIsLoadingLinked(true);
    const resLinked = controlContact?.linked_worksites_operations;
    if (resLinked) {
      console.log('resLinked', resLinked);
    }
    setIsLoadingLinked(false);
  };

  const getDataConsult = async (search: string | null = null) => {
    setIsLoadingConsult(true);
    const resConsult = await getControlContactOperationsConsult(
      Number(id),
      search || searchConsult,
      sortAndFilterConsult
    );
    if (resConsult) {
      console.log('resConsult', resConsult);
    }
    setIsLoadingConsult(false);
  };

  const onLinkOperations = async (operations: number[]) => {
    console.log('operations to link', operations);
    setDataLinked([
      ...(dataLinked || []),
      ...(dataToLink?.filter((item) =>
        operations.includes(item.worksite_operation_id)
      ) || []),
    ]);
    setDataToLink(
      dataToLink?.filter(
        (item) => !operations.includes(item.worksite_operation_id)
      ) ?? []
    );
  };

  const onDeleteOperations = async (operations: number[]) => {
    // TODO : Requete API
    console.log('operations to delete', operations);
    setDataToLink([
      ...(dataToLink || []),
      ...(dataLinked?.filter((item) =>
        operations.includes(item.worksite_operation_id)
      ) || []),
    ]);
    setDataLinked(
      dataLinked?.filter(
        (item) => !operations.includes(item.worksite_operation_id)
      )
    );
  };

  const handleValidateLot = async () => {
    setIsLoadingValidateLot(true);
    const res = await postControlContactValidateLot(
      Number(id),
      dataLinked?.map((item) => item.worksite_operation_id) || []
    );
    if (res.id) {
      navigate(PARTNERS_ROUTES_WITH_ID(res.id).CONTROL_CONTACT_VIEW);
      setResetSignal((prev) => prev + 1);
    } else {
      setLotOperations(dataLinked || []);
    }
    setIsLoadingValidateLot(false);
    setModalConfirmValidationLot(false);
  };

  const handleValidateControl = async () => {
    console.log('validate control');
    const res = await postControlContactValidateControl(Number(id));
    console.log('res', res);
    if (res.success) {
      navigate(PARTNERS_ROUTES.CONTROL_CONTACT);
    }
    setModalConfirmValidationControl(false);
  };

  useEffect(() => {
    getControlContact();
  }, [id]);

  useEffect(() => {
    if (resetSignal) getControlContact();
  }, [resetSignal]);

  useEffect(() => {
    if (controlContact) {
      // Quand controlContact change, on place les opérations linkables dans dataToLink
      if (controlContact?.linkable_worksites_operations) {
        setDataToLink(controlContact.linkable_worksites_operations);
      }
      updateTitleHeader(controlContact.reference_intern);
      updateDisplayBackButtonHeader(true);
      if (route.older.length === 0) {
        updateRoute({
          ...route,
          older: [PARTNERS_ROUTES.CONTROL_CONTACT],
        });
      }

      const tagText = globalEnum.contact_lot_status[controlContact.status];
      let tagColor = textGrey;
      if (controlContact.status === controlContactStatusEnum.CONTROLLABLE) {
        tagColor = blueSecondary;
      }

      const tag = (
        <div className="flex gap-5">
          {tagText && <Tag label={tagText} color={tagColor} />}
          <div>{controlContact.operation_type.description} </div>
          <Tag label={controlContact.operation_type.code} color={textGrey} />
        </div>
      );

      updateTagHeader(tag);
    } else {
      refreshHeader();
    }
  }, [controlContact]);

  if (!controlContact)
    return (
      <div className="relative top-[14%]">
        <LoaderDeposit />;
      </div>
    );

  return (
    <div>
      <ControlContactPageHeader
        data={controlContact}
        setTab={setTab}
        tab={tab}
        loading={isLoadingLinked && isLoadingToLink}
        exportControlContactButtonEnabled={lotOperations?.length === 0}
        setModalConfirmValidationLot={setModalConfirmValidationLot}
        setModalConfirmValidationControl={setModalConfirmValidationControl}
        lotOperations={dataLinked}
      />
      {isInProgress ? (
        <div className="grid grid-cols-2 gap-6">
          <SetOperationList
            type="add"
            data={dataToLink}
            setSearch={(val: string) => {
              setSearchToLink(val);
              getDataToLink(val);
            }}
            isLoading={isLoadingToLink}
            onSort={(column, direction) =>
              sortList(column, direction, setSortAndFilterToLink)
            }
            onFilter={(filters) => filterList(filters, setSortAndFilterToLink)}
            onSubmit={(operationsChecked) =>
              onLinkOperations(operationsChecked)
            }
            fromCofrac={!!controlContact.reference_cofrac}
          />
          <SetOperationList
            type="delete"
            data={dataLinked || []}
            setSearch={(val: string) => {
              setSearchLinked(val);
              getDataLinked(val);
            }}
            isLoading={isLoadingLinked}
            onSubmit={(operationsChecked) =>
              onDeleteOperations(operationsChecked)
            }
            onSort={(column, direction) =>
              sortList(column, direction, setSortAndFilterLinked)
            }
            onFilter={(filters) => filterList(filters, setSortAndFilterLinked)}
            fromCofrac={!!controlContact.reference_cofrac}
          />
        </div>
      ) : (
        controlContact.linked_worksites_operations && (
          <SetOperationList
            type="consult"
            data={controlContact.linked_worksites_operations}
            setSearch={(val: string) => {
              setSearchConsult(val);
              getDataConsult(val);
            }}
            isLoading={isLoadingConsult}
            onSort={(column, direction) =>
              sortList(column, direction, setSortAndFilterConsult)
            }
            onFilter={(filters) => filterList(filters, setSortAndFilterConsult)}
            fromCofrac={!!controlContact.reference_cofrac}
          />
        )
      )}
      {modalConfirmValidationLot && (
        <Modal
          isLoading={isLoadingValidateLot}
          title={t('control_contact.validate_lot_modal_title')}
          textBtnConfirm={`${t('buttons.confirm')}`}
          onConfirmClick={handleValidateLot}
          btnCancel
          setIsModal={() => setModalConfirmValidationLot(false)}
          backgroundTransparent
        >
          <p className="mt-4">{t('control_contact.validate_lot_modal_text')}</p>
        </Modal>
      )}
      {modalConfirmValidationControl && (
        <Modal
          isLoading={false}
          title={t('control_contact.validate_control_modal_title')}
          textBtnConfirm={`${t('buttons.confirm')}`}
          onConfirmClick={handleValidateControl}
          btnCancel
          setIsModal={() => setModalConfirmValidationControl(false)}
          backgroundTransparent
        >
          <p className="mt-4">
            {t('control_contact.validate_control_modal_text')}
          </p>
        </Modal>
      )}
    </div>
  );
}

export { ControlContactDetails };
