import { TextError } from '@components/TextError';
import { AuthContext } from '@context/authContext';
import { useIframeMode } from '@hooks/useIframeMode';
import {
  calculatePrimes,
  getPayload,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import NoAid from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/NoAid';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import {
  arraysAreSame,
  fetchIncomes,
  isEmptyValue,
  objectsAreSame,
  systemPowerFormNotValid,
  systemPowerIsValid,
} from '@models/worksiteCreation/utils/functions';
import { IGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { OperationTypeEnum } from '@utils/enums';
import { isInstaller } from '@utils/roles';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDefaultObject } from '../../../../../types/globalTypes';
import { CardForm } from '../../CardForm';
import { RenderFormGraphOperation } from './RenderFormGraphOperation';

function StepDetailOperation() {
  const {
    graphOperation,
    simulatorDataOperations,
    simulatorData,
    updateStepActiveWorksiteCreation,
    updateSimulatorData,
    updateIsLoading,
    conventionActive,
    worksiteOperationType,
    updateDisabledNextButton,
    updateSimulatorDataOperation,
    lastStep,
    noAidOperationToDisplay,
    updateNoAidOperationToDisplay,
    incomesOptionsArray,
    worksiteAddress,
    updateIncomesOptionsArray,
    worksiteDatas,
    iframeData,
  } = useContext(WorksiteCreationContext);

  const { user } = useContext(AuthContext);

  const { t } = useTranslation();
  const [errorCalculPrimeMessage, setErrorCalculPrimeMessage] =
    useState<string>('');

  const [isSubmitWithNoAid, setIsSubmitWithNoAid] = useState<boolean>(false);

  const initialData = useMemo(() => {
    return { operations: simulatorDataOperations, general: simulatorData };
  }, []);

  const [graphErrors, setGraphErrors] = useState<string[]>([]);

  const isIframeMode = useIframeMode();

  // sous-ensemble de clés utilisées pour vérifier que les puissances installées < puissance totale
  // correspondent à des clés présentes dans les graphes des opérations
  const keySets: [string, string, string][] = [
    ['operation.boilerDetails', 'boiler', 'power'],
    ['operation.pacDetails', 'pacs', 'power'],
    // Ajouter d'autres ensembles si nécessaire
  ];

  const noChange = useMemo(() => {
    if (lastStep <= StepsWorksiteCreationEnum.SIMULATION_OPERATIONDETAILS) {
      return false;
    }

    const initialOps = initialData.operations as IDefaultObject[];
    const currentOps = simulatorDataOperations as IDefaultObject[];

    const { operations: op1, ts: ts1, ...currentObj } = simulatorData;
    const { operations: op2, ts: ts2, ...initialObj } = initialData.general;

    const obj = objectsAreSame(initialObj, currentObj);

    const arr = arraysAreSame(initialOps, currentOps, 'operation.id');

    return obj && arr;
  }, [simulatorDataOperations, simulatorData, lastStep]);

  const onSubmitFormGraphGeneral = async () => {
    if (noAidOperationToDisplay) {
      setIsSubmitWithNoAid(true);
      return;
    }
    let canGoToNextStep = true;
    const withAmounts =
      isIframeMode ||
      !conventionActive.can_use_custom_prices ||
      !!isInstaller(user);

    if (!noChange) {
      updateIsLoading(true);
      let response: boolean;
      if (withAmounts) {
        response = await calculatePrimes(
          simulatorData,
          simulatorDataOperations,
          updateIsLoading,
          updateSimulatorData,
          updateSimulatorDataOperation,
          setErrorCalculPrimeMessage,
          worksiteOperationType,
          conventionActive.id,
          incomesOptionsArray,
          t,
          worksiteDatas.incentive_type,
          updateNoAidOperationToDisplay,
          iframeData?.id
        );
      } else {
        const responseData = await getPayload(
          worksiteDatas,
          simulatorData,
          simulatorDataOperations,
          setErrorCalculPrimeMessage,
          worksiteOperationType,
          conventionActive.id,
          incomesOptionsArray
        );
        updateSimulatorDataOperation(responseData);
        response = !!responseData;
      }
      updateIsLoading(false);

      if (!response) canGoToNextStep = false;
    }

    if (canGoToNextStep) {
      const nextStep = !withAmounts
        ? StepsWorksiteCreationEnum.SIMULATION_PRIX
        : StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF;
      updateStepActiveWorksiteCreation(nextStep); //
    }
  };

  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const checkOperationVariationScopUnit = () => {
    const simulatorDataOperationsArray = Object.values(simulatorDataOperations);

    for (let i = 0; i < simulatorDataOperationsArray.length; i += 1) {
      const operationVariation =
        simulatorDataOperationsArray[i]['operation.variation'];
      if (Array.isArray(operationVariation)) {
        if (
          operationVariation.reduce(
            (acc, unity) => acc + Number(unity.surface),
            0
          ) > simulatorData['general.surface']
        ) {
          return true;
        }
        for (let j = 0; j < operationVariation.length; j += 1) {
          const keys = Object.keys(operationVariation[j]);
          for (let k = 0; k < keys.length; k += 1) {
            if (operationVariation[j][keys[k]] === '') {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  // Cette fonction vérifie si la condition d'un élément est satisfaite en fonction de simulatorData
  const isConditionSatisfied = (condition: any) => {
    if (!condition || Object.keys(condition).length === 0) return false;
    const { step, answer } = condition.generalSimulator;
    const simulatorStepValue = simulatorData[`general.${step}`];
    if (step === 'zipcode') {
      const zipcode = String(simulatorData['general.zipcode']);

      const isNotEqual = answer.startsWith('!=');

      const zipcodeNumber = answer.match(/\d+/)[0]; // '!=97' => '97'

      return isNotEqual
        ? !zipcode.startsWith(zipcodeNumber)
        : zipcode.startsWith(zipcodeNumber);
    }
    return simulatorStepValue === answer;
  };

  // Cette fonction filtre le tree de chaque opération basé sur les conditions et simulatorData
  const getFilteredOperationTree = (operation: IGraph) => {
    // Filtrer les éléments de tree qui satisfont leurs conditions
    const filteredTree = operation.tree?.filter((treeElement) => {
      return isConditionSatisfied(treeElement.condition);
    });

    // Si aucun élément ne satisfait sa condition, retourner ceux sans condition
    if (filteredTree?.length === 0) {
      return operation.tree?.filter((treeElement) => {
        return Object.keys(treeElement.condition || {}).length === 0;
      });
    }
    return filteredTree;
  };

  // Utiliser getFilteredOperationTree pour obtenir les trees filtrés tout en conservant les autres éléments de l'opération
  const operationsWithFilteredTree = graphOperation.map((operation) => {
    const filteredTree = getFilteredOperationTree(operation);
    return { ...operation, tree: filteredTree };
  });

  const checkIfOperationHasQuestion = (operation: IGraph) =>
    !!operation.tree && operation.tree.length === 1 && !operation.tree[0].name;

  const noQuestionInAnyOperation = operationsWithFilteredTree.every(
    (operation) => checkIfOperationHasQuestion(operation)
  );

  useEffect(() => {
    let emptyData = false;
    const haveError = errors && Object.keys(errors).length > 0;

    Object.values(simulatorDataOperations).forEach((obj) => {
      Object.entries(obj).forEach(([key, val]) => {
        if (key.includes('operation.') && isEmptyValue(val)) {
          emptyData = true;
        }
      });

      if ('operation.variation' in obj) {
        emptyData = checkOperationVariationScopUnit();
      }
    });

    const isFormNotValid = systemPowerFormNotValid(
      simulatorDataOperations,
      keySets
    );

    const btnDisabled =
      haveError || isFormNotValid || emptyData || graphErrors.length > 0;

    updateDisabledNextButton(btnDisabled);
  }, [JSON.stringify(simulatorDataOperations), graphErrors]);

  useEffect(() => {
    if (
      (!incomesOptionsArray || incomesOptionsArray.length === 0) &&
      OperationTypeEnum.B2C === worksiteOperationType
    ) {
      fetchIncomes(
        String(simulatorData['general.persons']),
        worksiteAddress,
        simulatorData,
        updateIncomesOptionsArray,
        t,
        undefined,
        isIframeMode
      );
    }
  }, []);

  return (
    <div
      className={`w-full pb-[20rem] ${
        graphOperation.length > 1 ? 'flex flex-wrap' : ''
      }`}
    >
      {noQuestionInAnyOperation && (
        <form
          id="sendGraphOperation"
          onSubmit={handleSubmit(
            onSubmitFormGraphGeneral as (data: FieldValues) => void
          )}
        >
          <div className="mt-[1.5rem]">
            {operationsWithFilteredTree.length === 1
              ? t(
                  'worksite_creation.simulation.no_information_required_for_one_operation'
                )
              : t(
                  'worksite_creation.simulation.no_information_required_for_operations'
                )}
          </div>
        </form>
      )}
      {operationsWithFilteredTree.map((operation, index) => {
        const noQuestion = checkIfOperationHasQuestion(operation);
        if (!operation || noQuestion) {
          return null;
        }

        return (
          <CardForm
            title={operation.name}
            subtitle={operation.description}
            idForm="sendGraphOperation"
            key={operation.name + operation.value}
            onSubmit={onSubmitFormGraphGeneral}
            addClass="flexFormGraph w-full pb-[2rem]"
            methods={methods}
          >
            <div className="mt-[1.5rem]">
              {noAidOperationToDisplay && isSubmitWithNoAid ? (
                <NoAid description={noAidOperationToDisplay.description} />
              ) : (
                operation.tree?.map((children) => {
                  return (
                    <div>
                      <RenderFormGraphOperation
                        key={children.value + children.name}
                        graph={children}
                        index={index}
                        setErrorCalculPrimeMessage={setErrorCalculPrimeMessage}
                        boilerPowerError={
                          !systemPowerIsValid(simulatorDataOperations, keySets)
                        }
                        setGraphErrors={setGraphErrors}
                        operationTree={operation.tree}
                        operationCode={operation.value}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </CardForm>
        );
      })}
      <TextError errorMessage={errorCalculPrimeMessage} />
    </div>
  );
}

export { StepDetailOperation };
