import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { dateToDDMMYYY, toAPIDateStr } from '@utils/format';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { GlobalContext } from '@context/globalContext';
import { UploadButton } from '@components/documents/UploadButton';
import { initialDocument } from '@utils/initialState';
import { FileIcon } from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';
import { createAndEditCertification } from '@models/certifications/apiRequests/certificationsRequests';
import { ICertificationType } from '@models/certifications/utils/certificationsTypes';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCertificationCreationSchema } from '@utils/validationSchemas';
import { PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { isDateValidYYYYMMDD } from '@utils/functions';

interface ICertificationCreationModalProps {
  setDisplayCreationModal: Dispatch<SetStateAction<boolean>>;
  setCertification?: Dispatch<SetStateAction<ICertificationType | undefined>>;
  certificationToEdit?: ICertificationType;
  entityIdForRequests?: number;
}

function CertificationCreationModal({
  setDisplayCreationModal,
  setCertification,
  certificationToEdit,
  entityIdForRequests,
}: ICertificationCreationModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { globalEnum, userView } = useContext(GlobalContext);
  const isEdit = !!certificationToEdit;

  const methods = useForm({
    defaultValues: isEdit
      ? {
          reference: certificationToEdit.reference,
          name: certificationToEdit.name,
          start_date: certificationToEdit.start_date,
          end_date: certificationToEdit.end_date,
        }
      : {},
    resolver: yupResolver(getCertificationCreationSchema(t)),
  });

  const { handleSubmit, setValue, watch } = methods;

  const valuesForm = watch();
  const uploadedFile = valuesForm.uploadedFile as File;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const certificationTypesList = useMemo(() => {
    if (!globalEnum) return [];
    const enumTypes = globalEnum.certification_type;
    return Object.keys(enumTypes).map((key) => enumTypes[key as any]);
  }, [globalEnum]);

  const onSelectCertificationType = (value: string) => {
    if (globalEnum) {
      const enumTypes = globalEnum.certification_type;
      const enumKey = Object.keys(enumTypes).find(
        (key) => enumTypes[key as any] === value
      );
      if (enumKey) setValue('type', Number(enumKey));
    }
  };

  const onUploadFile = async (fileUploaded: File) => {
    setValue('uploadedFile', fileUploaded);
  };

  const handleCertificationCreation = async (formDatas: FieldValues) => {
    setIsLoading(true);
    const certificationDatas: any = {
      ...formDatas,
      type: 1,
      entity_id:
        certificationToEdit?.entity_id ||
        entityIdForRequests ||
        userView?.entity_id ||
        0,
    };

    if (isEdit) {
      certificationDatas.id = certificationToEdit?.id;
    }

    if (!uploadedFile && certificationToEdit?.linked_file) {
      certificationDatas.linked_file = certificationToEdit.linked_file;
    }
    const certificationCreated = await createAndEditCertification(
      certificationDatas as ICertificationType
    );
    setIsLoading(false);
    if (certificationCreated) {
      setDisplayCreationModal(false);
      if (isEdit && setCertification) {
        setCertification(certificationCreated);
      } else {
        navigate(
          PARTNERS_ROUTES_WITH_ID(certificationCreated.id).CERTIFICATION_VIEW
        );
      }
    }
  };

  return (
    <Modal
      title={`${
        isEdit
          ? t('certifications.edit_certification_file')
          : t('certifications.new_certification')
      }`}
      formId="form-certification-creation"
      textBtnConfirm={t('buttons.confirm') || ''}
      setIsModal={setDisplayCreationModal}
      btnCancel
      backgroundTransparent
      sidebarVisible
      isLoading={isLoading}
      btnConfirmDisabled={
        !valuesForm.reference ||
        (!uploadedFile && !certificationToEdit?.linked_file) ||
        !valuesForm.start_date ||
        !valuesForm.end_date ||
        !(
          isDateValidYYYYMMDD(valuesForm.start_date) &&
          isDateValidYYYYMMDD(valuesForm.end_date)
        )
      }
    >
      <FormProvider {...methods}>
        <form
          id="form-certification-creation"
          onSubmit={handleSubmit(handleCertificationCreation)}
        >
          <div className="pt-6 flex flex-col space-y-4">
            <InputSelect
              /* valueInput={
                globalEnum
                  ? globalEnum.certification_type[valuesForm.type as any]
                  : ''
              } */
              disabled
              valueInput={globalEnum.certification_type[1]}
              dataArrayString={certificationTypesList}
              onSelect={(value: string) => onSelectCertificationType(value)}
              placeholder={t('global.choose')}
              label={`${t('certifications.type')}`}
              required
              addClass="w-1/2 pr-2"
              dataTestIdSelect="select_certification_type"
              dataTestIdOptions="options_certification_type"
            />
            <div className="flex space-x-4">
              <InputText
                id="certification-reference"
                name="reference"
                placeholder={t('certifications.reference')}
                required
                defaultValue={certificationToEdit?.reference || ''}
                dataTestId="input_certification_reference"
              />
              <InputText
                id="certification-name"
                name="name"
                placeholder={t('certifications.name')}
                required
                defaultValue={certificationToEdit?.name || ''}
                dataTestId="input_certification_name"
              />
            </div>
            <div className="flex space-x-4">
              <DatePicker
                required
                onChangeDate={(value: string) => {
                  const formattedDate = toAPIDateStr(value);
                  if (formattedDate) {
                    setValue('start_date', formattedDate);
                  } else {
                    setValue('start_date', '');
                  }
                }}
                label={t('certifications.start_date') || ''}
                noDefaultDate={!isEdit}
                defaultDate={
                  certificationToEdit
                    ? dateToDDMMYYY(certificationToEdit?.start_date) ||
                      undefined
                    : undefined
                }
                dataTestId="input_certification_start_date"
                addClass="w-full"
                maxDate={valuesForm.end_date ?? new Date()}
              />
              <DatePicker
                required
                onChangeDate={(value: string) => {
                  const formattedDate = toAPIDateStr(value);
                  if (formattedDate) {
                    setValue('end_date', formattedDate);
                  } else {
                    setValue('end_date', '');
                  }
                }}
                label={t('certifications.end_date') || ''}
                defaultDate={
                  certificationToEdit
                    ? dateToDDMMYYY(certificationToEdit?.end_date) || undefined
                    : undefined
                }
                noDefaultDate={!isEdit}
                dataTestId="input_certification_end_date"
                addClass="w-full"
                minDate={valuesForm.start_date ?? new Date()}
              />
            </div>
            <div className="flex flex-col space-y-2">
              {uploadedFile && (
                <a
                  href={URL.createObjectURL(uploadedFile)}
                  className="flex items-center space-x-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FileIcon fill={blueOpx} />
                  <span className="text-sm text-primaryText">
                    {uploadedFile.name}
                  </span>
                </a>
              )}
              <UploadButton
                name={
                  uploadedFile || certificationToEdit
                    ? t('certifications.edit_certification_file') || ''
                    : t('certifications.join_certification') || ''
                }
                onUpload={onUploadFile}
                document={initialDocument}
                color="blue"
                fileType={4}
                dataTestId="input_certification_file"
                addClass="w-min"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CertificationCreationModal;

CertificationCreationModal.defaultProps = {
  certificationToEdit: undefined,
  setCertification: undefined,
  entityIdForRequests: undefined,
};
