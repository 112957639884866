import { InputText } from '@components/atomic/inputs/InputText';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { decimalRegex } from '@utils/regex';

function InformationMaterial() {
  const { t } = useTranslation();
  const { updatePayloadSizingNote, payloadSizingNote, worksiteDatas } =
    useContext(WorksiteCreationContext);

  const operationWithMaterial = worksiteDatas.worksites_operations.find(
    (operation) =>
      operation.operation.code === 'BAR-TH-159' ||
      operation.operation.code === 'BAR-TH-171' ||
      operation.operation.code === 'BAR-TH-172' ||
      operation.operation.code === 'BAR-TH-113'
  );

  let materialName = '';

  if (
    operationWithMaterial &&
    operationWithMaterial.material &&
    operationWithMaterial.material.caracteristics
  ) {
    const caracteristics = JSON.parse(
      operationWithMaterial.material.caracteristics
    );
    materialName = caracteristics.mark ? caracteristics.mark.name : '';
  }

  return (
    <div className="mt-[3rem]">
      {/* Titre et sous-titre */}
      <p className="font-medium">
        {t(
          'worksite_creation.create_worksite.sizing_note.information_material'
        )}
      </p>
      <p className="text-[.875rem]">
        {`${t(
          'worksite_creation.create_worksite.sizing_note.information_material_subtitle'
        )} ${materialName}.`}
      </p>
      {/* contenu du formulaire:  */}
      <div className="flex items-center space-x-[.5rem] mt-[2rem]">
        <InputText
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.shutdown_temperature'
          )}
          id="shutdown_temperature"
          name="shutdown_temperature"
          value={payloadSizingNote.heat_pump_shutdown_temperature || ''}
          typeNumber
          allowNegative
          onChange={(value: string | ChangeEvent<HTMLInputElement>) => {
            const newValue =
              typeof value === 'string' ? value : value.target.value;
            const validNumber = decimalRegex.test(newValue);
            if (validNumber || newValue === '') {
              updatePayloadSizingNote({
                ...payloadSizingNote,
                heat_pump_shutdown_temperature:
                  newValue === '' ? null : newValue,
              });
            }
          }}
          required
          dataTestId="input_text_shutdown_temperature"
        />
        <InputText
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.power_or_stop'
          )}
          id="power_or_stop"
          name="power_or_stop"
          value={payloadSizingNote.power_temperature || ''}
          typeNumber
          onChange={(value: string | ChangeEvent<HTMLInputElement>) => {
            const newValue =
              typeof value === 'string' ? value : value.target.value;
            updatePayloadSizingNote({
              ...payloadSizingNote,
              power_temperature: newValue === '' ? null : newValue,
            });
          }}
          required
          dataTestId="input_text_power_or_stop"
        />
      </div>
      <div className="flex items-center space-x-[.5rem] mt-[.5rem]">
        <InputText
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.heat_pump_electrical_resistance'
          )}
          dataTestId="input_text_heat_pump_electrical_resistance"
          id="heat_pump_electrical_resistance"
          name="heat_pump_electrical_resistance"
          onChange={(value: string | React.ChangeEvent<HTMLInputElement>) => {
            let inputValueElectricalResistance: string;

            if (typeof value === 'string') {
              inputValueElectricalResistance = value;
            } else {
              inputValueElectricalResistance = value.target.value;
            }

            updatePayloadSizingNote({
              ...payloadSizingNote,
              electrical_resistance_power:
                inputValueElectricalResistance === ''
                  ? null
                  : inputValueElectricalResistance,
            });
          }}
          value={payloadSizingNote.electrical_resistance_power || ''}
          typeNumber
          allowZero
          required
        />
        <InputText
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.other_heating_supplement'
          )}
          id="other_heating_supplement"
          dataTestId="input_text_other_heating_supplement"
          name="other_heating_supplement"
          typeNumber
          allowZero
          onChange={(value: string | React.ChangeEvent<HTMLInputElement>) => {
            const newValue =
              typeof value === 'string' ? value : value.target.value;

            updatePayloadSizingNote({
              ...payloadSizingNote,
              other_heating: newValue === '' ? null : newValue,
            });
          }}
          value={payloadSizingNote.other_heating || ''}
          required
        />
      </div>
    </div>
  );
}

export { InformationMaterial };
