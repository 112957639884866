import { useTranslation } from 'react-i18next';

import { Card } from '@components/Card';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getControlOfficeCofracList } from '@models/partners/apiRequests/partnersRequest';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { CofracCard } from '@models/cofrac/components/CofracCard';
import { v4 } from 'uuid';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';

interface ICardCofracProps {
  loading: boolean;
}

function CardCofrac({ loading }: ICardCofracProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [cofracLots, setCofracLots] = useState<ICofracType[]>([]);
  const getData = async () => {
    const res = await getControlOfficeCofracList(Number(id));
    if (res) {
      setCofracLots(res.data);
    }
  };

  const list =
    cofracLots?.length > 0 ? (
      cofracLots.map((cofrac) => (
        <div key={v4()} className="-mt-4 pb-[0.5rem]">
          <CofracCard cofrac={cofrac} />
        </div>
      ))
    ) : (
      <div>{t('cofrac.no_items')}</div>
    );

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <Card title={t('partners.control_office.cofrac.title')}>
      <div>
        {loading ? (
          <>
            <LoaderSkeleton height="1.25rem" addClass="w-[6rem] mb-1" />
            <LoaderSkeleton height=".75rem" addClass="w-[8rem]" />
          </>
        ) : (
          list
        )}
      </div>
    </Card>
  );
}

export { CardCofrac };
