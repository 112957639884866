import { blueOpx } from '@assets/color';
import {
  ChevronLeftIcon,
  SupportContactIcon,
} from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ColorCube } from '@components/atomic/ColorCube';
import {
  getControlContactControlPointsByWorksiteOperationId,
  saveControlContactControlsPoints,
} from '@models/controlContact/apiRequests/controlContactRequests';
import {
  WORKSITE_OPERATION_STATUS,
  CONTACT_CONTROL_POINT_QUESTION_TYPE,
} from '@models/worksites/utils/enums';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import {
  IContactControlPoint,
  IWorksiteOperation,
} from '@models/worksites/utils/worksitesTypes';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { InputText } from '@components/atomic/inputs/InputText';
import { FormProvider, useForm } from 'react-hook-form';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { AuthContext } from '@context/authContext';
import { Loader } from '@components/atomic/Loader';
import { cleanContactControlPointsToSave } from '@models/controlContact/utils/functions';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ContactControlPoint from './contactControl/ContactControlPoint';

interface HeaderCardContactControlProps {
  displayControleContactPanel: boolean;
  onHideControleContactPanel: () => void;
}

type Inputs = {
  contact_control_date_reminder: string;
  contact_control_user_id: string;
  contact_control_date: string;
  contact_control_status: string;
  contact_control_comments: string;
  contactControlPoints: Record<string, string | string[]>;
};

function HeaderCardContactControl({
  displayControleContactPanel,
  onHideControleContactPanel,
}: HeaderCardContactControlProps) {
  const { t } = useTranslation();
  const { worksiteDetails, updateWorksiteDetails } =
    useContext(WorksitesContext);
  const { user } = useContext(AuthContext);

  // React Hook Form
  const methods = useForm<Inputs>({
    defaultValues: {
      contact_control_date_reminder: '',
      contact_control_user_id: '',
      contact_control_date: '',
      contact_control_status: '',
      contact_control_comments: '',
      contactControlPoints: {},
    },
  });
  const { handleSubmit, reset, getValues, watch } = methods;
  const location = useLocation();

  const [activeWorksiteOperationForCC, setActiveWorksiteOperationForCC] =
    useState<IWorksiteOperation | undefined>();

  const [activeContactControlPoints, setActiveContactControlPoints] = useState<
    IContactControlPoint[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Liste des opérations éligibles au "contrôle contact"
   */
  const listWorksiteOperationsToControl = useMemo(() => {
    return worksiteDetails.worksites_operations.filter((wo) =>
      [
        WORKSITE_OPERATION_STATUS.PostChecks,
        WORKSITE_OPERATION_STATUS.COFRAC,
        WORKSITE_OPERATION_STATUS.CONTROL_CONTACT,
        WORKSITE_OPERATION_STATUS.PreSubmission,
      ].includes(wo.status)
    );
  }, [worksiteDetails]);

  const fetchAndResetForm = async () => {
    if (activeWorksiteOperationForCC) {
      setIsLoading(true);
      const response =
        await getControlContactControlPointsByWorksiteOperationId(
          activeWorksiteOperationForCC.id
        );

      // Préparation des valeurs par défaut du formulaire
      const userFullname =
        activeWorksiteOperationForCC.contact_control_user_fullname ||
        `${user?.firstname} ${user?.lastname}`;

      const defaultValues: Inputs = {
        contact_control_date_reminder:
          activeWorksiteOperationForCC.contact_control_reminder_date || '',
        contact_control_user_id: userFullname,
        contact_control_date:
          activeWorksiteOperationForCC.contact_control_date || '',
        contact_control_status:
          activeWorksiteOperationForCC.contact_control_status?.toString() || '',
        contact_control_comments:
          activeWorksiteOperationForCC.contact_control_comments || '',
        contactControlPoints: {},
      };

      // On reconstruit le state pour les champs dynamiques
      if (response && Array.isArray(response)) {
        const contactControlPointsObj: Record<string, string | string[]> = {};

        response.forEach((accp) => {
          const answers = accp.answer?.answer_values || [];

          // Pour l'initialisation, on reste libre d'y mettre une string ou un tableau
          switch (accp.question_type) {
            case CONTACT_CONTROL_POINT_QUESTION_TYPE.TEXT:
            case CONTACT_CONTROL_POINT_QUESTION_TYPE.SELECT:
            case CONTACT_CONTROL_POINT_QUESTION_TYPE.DATE:
              // Valeur simple : on prend la première réponse
              contactControlPointsObj[accp.id] = answers[0] ?? '';
              break;

            case CONTACT_CONTROL_POINT_QUESTION_TYPE.RADIO:
              // Valeur unique "123.Reponse"
              contactControlPointsObj[accp.id] = answers[0]
                ? `${accp.id}.${answers[0]}`
                : '';
              break;

            case CONTACT_CONTROL_POINT_QUESTION_TYPE.MULTISELECT:
            case CONTACT_CONTROL_POINT_QUESTION_TYPE.CHECKBOX:
              // Ici on initialise directement en tableau, si on a plusieurs réponses
              if (answers.length > 0) {
                contactControlPointsObj[accp.id] = [...answers];
              } else {
                contactControlPointsObj[accp.id] = [];
              }
              break;

            default:
              break;
          }
        });

        defaultValues.contactControlPoints = contactControlPointsObj;
        setActiveContactControlPoints(response);
        setIsLoading(false);
      } else {
        setActiveContactControlPoints([]);
      }

      // MàJ du formulaire
      reset(defaultValues);
    }
  };

  /**
   * Options de statut "avis" pour illustrer
   */
  const statusAvis = [
    { label: t('control_contact.satisfaisant'), value: '2' },
    { label: t('control_contact.to_recall'), value: '3' },
    { label: t('control_contact.insatisfaisant'), value: '4' },
  ];

  /**
   * Gestion de la soumission du formulaire
   */
  const onSubmit = async () => {
    if (!activeWorksiteOperationForCC) return;
    if (isSubmitting) return;

    setIsSubmitting(true);

    const formValues = getValues();
    const cleanedAnswers = cleanContactControlPointsToSave(
      formValues.contactControlPoints
    );

    // Formatage des dates en Y-m-d avant l'envoi
    const formattedControlDate = formValues.contact_control_date
      ? format(
          new Date(
            formValues.contact_control_date.split('/').reverse().join('-')
          ),
          'yyyy-MM-dd'
        )
      : '';

    const formattedReminderDate = formValues.contact_control_date_reminder
      ? format(
          new Date(
            formValues.contact_control_date_reminder
              .split('/')
              .reverse()
              .join('-')
          ),
          'yyyy-MM-dd'
        )
      : '';

    // Appel de l'API avec les dates formatées
    const response = await saveControlContactControlsPoints(
      activeWorksiteOperationForCC.id,
      formattedReminderDate,
      formattedControlDate,
      Number.parseInt(formValues.contact_control_status, 10),
      formValues.contact_control_comments,
      cleanedAnswers
    );

    if (response.success) {
      toast.success(response.success);

      // 1) Cloner le worksiteDetails
      const newWorksiteDetails = { ...worksiteDetails };

      // 2) Mettre à jour le tableau worksites_operations en clonant chaque item
      newWorksiteDetails.worksites_operations =
        newWorksiteDetails.worksites_operations.map((operation) => {
          if (operation.id === activeWorksiteOperationForCC.id) {
            return {
              ...operation,
              contact_control_reminder_date:
                formValues.contact_control_date_reminder,
              contact_control_date: formValues.contact_control_date,
              contact_control_status: Number.parseInt(
                formValues.contact_control_status,
                10
              ),
              contact_control_comments: formValues.contact_control_comments,
            };
          }
          return operation;
        });

      // 3) Mettre à jour le contexte
      updateWorksiteDetails(newWorksiteDetails);
    }

    setIsSubmitting(false);
  };

  /**
   * À l'initialisation, on positionne la première opération éligible comme active
   * (s'il y en a au moins une).
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const forceActiveWorksiteOperationId = queryParams.get(
      'worksite_operation_id'
    );

    if (listWorksiteOperationsToControl.length > 0) {
      if (forceActiveWorksiteOperationId) {
        const operation = listWorksiteOperationsToControl.find(
          (wo) => wo.id === Number(forceActiveWorksiteOperationId)
        );
        if (operation) {
          setActiveWorksiteOperationForCC(operation);
          return;
        }
      }
      setActiveWorksiteOperationForCC(listWorksiteOperationsToControl[0]);
    }
  }, [listWorksiteOperationsToControl, location.search]);

  /**
   * À chaque changement d'opération active :
   * - Récupérer les points de contrôle
   * - Initialiser le formulaire avec ses valeurs
   */
  useEffect(() => {
    if (!activeWorksiteOperationForCC) {
      setActiveContactControlPoints([]);
    }
    fetchAndResetForm();
  }, [activeWorksiteOperationForCC, reset, user?.firstname, user?.lastname]);

  return (
    <div
      className={`
        z-50 absolute top-0 right-0 h-full w-full bg-white
        transition-transform duration-300
        ${displayControleContactPanel ? 'translate-x-0' : 'translate-x-full'}
      `}
    >
      <div className="flex flex-col h-full">
        {/* En-tête */}
        <div className="flex flex-col border-b pb-2">
          {/* Navigation */}
          <div className="p-5 flex items-center space-x-3">
            <ButtonOpx
              label=""
              isOnlyIcon
              type="nav"
              icon={<ChevronLeftIcon className="rotate-[180deg]" />}
              small
              onClick={onHideControleContactPanel}
            />
            <span className="text-lg">{t('control_contact.title')}</span>
            <ColorCube
              color={blueOpx}
              size="2rem"
              opacity
              numberOrIcon={<SupportContactIcon />}
            />
          </div>

          {/* Boutons des opérations contrôlables */}
          {listWorksiteOperationsToControl.length > 0 && (
            <div className="ml-5 pb-3 flex space-x-2 overflow-x-auto">
              {listWorksiteOperationsToControl.map((wo) => (
                <ButtonOpx
                  key={wo.operation.id}
                  label={wo.operation.code}
                  type="nav"
                  active={wo.id === activeWorksiteOperationForCC?.id}
                  small
                  addClass="text-sm"
                  onClick={() => setActiveWorksiteOperationForCC(wo)}
                />
              ))}
            </div>
          )}
        </div>

        {/* Formulaire */}
        <FormProvider {...methods}>
          <form
            id="form_contact_control"
            className="flex-1 overflow-y-auto p-5 break-all"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!isLoading ? (
              <>
                {/* Points de contrôle fixes partie HAUTE */}
                <InputText
                  id="contact_control_user_id"
                  name="contact_control_user_id"
                  placeholder=""
                  label={t('control_contact.controller') || ''}
                  required
                  defaultValue={getValues('contact_control_user_id')}
                  disabled
                  addClass="mb-5"
                  addClassToInput="text-gray-400"
                />

                <DatePicker
                  label={t('control_contact.control_date') || ''}
                  required
                  onChangeDate={(value) =>
                    methods.setValue('contact_control_date', value)
                  }
                  addClass="mb-5"
                  defaultDate={getValues('contact_control_date') || undefined}
                  disabled={
                    activeWorksiteOperationForCC?.contact_control_disabled
                  }
                />

                {/* Points de contrôle dynamiques */}
                {activeContactControlPoints.map((accp) => (
                  <ContactControlPoint
                    key={accp.id}
                    contactControlPoint={accp}
                    disabled={
                      activeWorksiteOperationForCC?.contact_control_disabled ||
                      accp.is_deleted
                    }
                  />
                ))}

                {/* Points de contrôle fixes partie BASSE */}
                <InputSelect
                  placeholder=""
                  label={t('control_contact.status_notice') || ''}
                  required
                  dataLabelValue={statusAvis}
                  addClass="mb-5"
                  onSelectLabelValue={(option) => {
                    methods.setValue('contact_control_status', option.value);
                  }}
                  defaultSelected={
                    statusAvis.find(
                      (st) => st.value === getValues('contact_control_status')
                    )?.label
                  }
                  disabled={
                    activeWorksiteOperationForCC?.contact_control_disabled
                  }
                />

                {watch('contact_control_status') === statusAvis[1].value && (
                  <DatePicker
                    label="Date de rappel"
                    required={
                      watch('contact_control_status') === statusAvis[1].value
                    }
                    onChangeDate={(value) =>
                      methods.setValue('contact_control_date_reminder', value)
                    }
                    addClass="mb-5"
                    defaultDate={
                      watch('contact_control_date_reminder') || undefined
                    }
                    minDate={new Date()}
                  />
                )}

                <InputText
                  id="contact_control_comments"
                  name="contact_control_comments"
                  label={t('control_contact.comments') || ''}
                  placeholder=""
                  defaultValue={getValues('contact_control_comments') || ''}
                  hideIsOptional
                  disabled={
                    activeWorksiteOperationForCC?.contact_control_disabled
                  }
                />

                {/* Bouton de validation */}
                <div className="mt-5">
                  {!activeWorksiteOperationForCC?.contact_control_disabled &&
                    !isSubmitting && (
                      <ButtonOpx
                        type="primary"
                        label={t('control_contact.control_validate', {
                          code: activeWorksiteOperationForCC?.operation.code,
                        })}
                        addClass="w-full"
                        isSubmitBtn
                        formId="form_contact_control"
                        disabled={
                          activeWorksiteOperationForCC?.contact_control_disabled ||
                          isSubmitting
                        }
                      />
                    )}
                  {isSubmitting && <Loader />}
                </div>
              </>
            ) : (
              <div className="mt-10">
                <Loader isBig />
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export { HeaderCardContactControl };
