import { IContractOperationPrice } from '@models/contractCreation/utils/contractCreationTypes';
import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import {
  ContractTypes,
  OperationPriceTypes,
} from '@models/contractCreation/utils/enums';
import { OperationPriceInputs } from '@models/contractCreation/components/steps/operationPrices/OperationPriceInputs';
import { useTranslation } from 'react-i18next';
import InformationWithLabel from '@models/contractCreation/components/steps/operationPrices/InformationWithLabel';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';

interface OneOperationPriceProps {
  operations: IOperationType[];
  isLoading: boolean;
  operationPrice?: IContractOperationPrice;
  index?: number;
  isConvention: boolean;
  selectedIds: number[];
  setSelectedIds: Dispatch<SetStateAction<number[]>>;
}

function OneOperationPrice({
  operations,
  operationPrice,
  isLoading,
  index,
  isConvention,
  setSelectedIds,
  selectedIds,
}: OneOperationPriceProps) {
  const { t } = useTranslation();
  const { contractType } = useContext(ContractCreationContext);

  const incentiveArrayData: ('direct' | 'indirect')[] = useMemo(() => {
    if (!operationPrice) return ['indirect'];
    const prices = Object.entries(operationPrice);

    const isIndirect = prices.some(
      ([key, value]) => key.startsWith('indirect') && value && value !== ''
    );

    const isDirect = prices.some(
      ([key, value]) => key.startsWith('direct') && value && value !== ''
    );

    if (isIndirect && isDirect) return ['direct', 'indirect'];
    if (isDirect) return ['direct'];

    return ['indirect'];
  }, [operationPrice]);

  const withCdp = operationPrice
    ? Object.entries(operationPrice).some(
        (entry) => entry[0].includes('_cdp_') && !!entry[1]
      )
    : false;

  const withMinimumAmount = operationPrice
    ? Object.entries(operationPrice).some(
        (entry) => entry[0].includes('_amount_') && !!entry[1]
      )
    : false;

  const fixPrice = operationPrice
    ? Object.entries(operationPrice).some(
        (entry) => entry[0].includes('_pf_') && !!entry[1]
      )
    : false;

  const defineOperationPriceTypeAccordingToOperationPrice = () => {
    if (operationPrice?.indirect_pu_unique) {
      return OperationPriceTypes.UNIQUE;
    }
    if (operationPrice?.direct_pu_unique) {
      return OperationPriceTypes.UNIQUE;
    }
    if (operationPrice?.indirect_pu_intermediaire) {
      return OperationPriceTypes.INCOMES;
    }
    return OperationPriceTypes.CLASSIQUE_PRECAIRE;
  };

  const operationPriceType =
    defineOperationPriceTypeAccordingToOperationPrice();

  const showMinimumAmountInputs = withMinimumAmount;

  const operationIsSelected = selectedIds.includes(
    Number(operationPrice?.operation_id)
  );

  const handleCheckOperation = () => {
    if (operationIsSelected) {
      setSelectedIds((prevState) => {
        return prevState.filter((id) => id !== operationPrice?.operation_id);
      });
    } else {
      setSelectedIds((prevState) => [
        ...prevState,
        Number(operationPrice?.operation_id),
      ]);
    }
  };

  return (
    <div>
      <div className="flex w-full items-center">
        <div className="flex-col w-full items-center">
          <div className="w-full flex items-end mb-4">
            {index !== undefined && (
              <div className="flex items-start gap-2">
                <Checkbox
                  label=""
                  checked={operationIsSelected}
                  onCheck={handleCheckOperation}
                />

                <ColorCube
                  size="2.5rem"
                  numberOrIcon={index + 1}
                  color={blueOpx}
                  opacity
                  addClass="mr-2 relative cursor-default "
                />
              </div>
            )}
            <InformationWithLabel
              label={t('partners.deposits.operation_maj')}
              information={operationPrice?.label || ''}
            />
          </div>

          <div className="w-full flex gap-2">
            <div className="w-[1.25rem]" />
            <div className="w-full">
              {incentiveArrayData.map((incentive) => (
                <div
                  className="items-end w-full"
                  key={`${incentive}_${operationPrice?.operation_id}`}
                >
                  <OperationPriceInputs
                    operationPriceType={operationPriceType}
                    action="delete"
                    priceValue={operationPrice}
                    isLoading={isLoading}
                    isConvention={isConvention}
                    operationPrice={operationPrice}
                    selectedIds={[operationPrice?.operation_id || 0]}
                    operations={operations}
                    incentive={incentive}
                    differentIncentivePrice={incentiveArrayData.length > 1}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex gap-2">
        <div className="w-[1.25rem]" />
        <div className="w-full">
          {isConvention && withCdp && (
            <div className="mb-3 text-[1rem] font-semibold">
              {t('convention.with_cdp_prices')}
            </div>
          )}
          {withCdp &&
            incentiveArrayData.map((incentive) => (
              <OperationPriceInputs
                key={`${incentive}_${operationPrice?.operation_id}`}
                operationPriceType={operationPriceType}
                action="delete"
                priceValue={operationPrice}
                isLoading={isLoading}
                isConvention={isConvention}
                isCdp
                fixCdp={fixPrice}
                operationPrice={operationPrice}
                selectedIds={[operationPrice?.operation_id || 0]}
                operations={operations}
                incentive={incentive}
                differentIncentivePrice={incentiveArrayData.length > 1}
              />
            ))}
          {contractType === ContractTypes.CONVENTION && withMinimumAmount && (
            <div className="my-3 mb-3 text-[1rem] font-semibold">
              {t('convention.minimum_prices')}
            </div>
          )}
          {showMinimumAmountInputs &&
            incentiveArrayData.map((incentive) => (
              <div key={`${incentive}_${operationPrice?.operation_id}`}>
                <OperationPriceInputs
                  operationPriceType={operationPriceType}
                  action="delete"
                  priceValue={operationPrice}
                  operationPrice={operationPrice}
                  isLoading={isLoading}
                  isConvention
                  selectedIds={[operationPrice?.operation_id || 0]}
                  operations={operations}
                  isMinimumAmount
                  incentive={incentive}
                  differentIncentivePrice={incentiveArrayData.length > 1}
                />
                {withCdp && (
                  <OperationPriceInputs
                    operationPriceType={operationPriceType}
                    action="delete"
                    priceValue={operationPrice}
                    operationPrice={operationPrice}
                    isLoading={isLoading}
                    isConvention
                    selectedIds={[operationPrice?.operation_id || 0]}
                    operations={operations}
                    isMinimumAmount
                    incentive={incentive}
                    differentIncentivePrice={incentiveArrayData.length > 1}
                    isCdp
                    fixCdp={fixPrice}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

OneOperationPrice.defaultProps = {
  operationPrice: undefined,
  index: undefined,
};

export { OneOperationPrice };
