import { green, red, orange } from '@assets/color';
import {
  CheckIcon,
  CrossIcon,
  ClockAlertOutline,
} from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { ILinkedFile } from '@globalTypes/globalTypes';
import { DOCUMENT_STATUS_ENUM } from '@utils/utils';

const tabIcon = (file: ILinkedFile) => {
  let icon = <ClockAlertOutline />;
  let color = orange;

  switch (file.status) {
    case DOCUMENT_STATUS_ENUM.VALIDATE:
      icon = <CheckIcon />;
      color = green;
      break;
    case DOCUMENT_STATUS_ENUM.REFUSE:
      icon = <CrossIcon />;
      color = red;
      break;
    default:
      break;
  }

  return <ColorCube size="1.5rem" numberOrIcon={icon} color={color} opacity />;
};

export const tabList = (
  groupedDocuments: { files: ILinkedFile[]; fileType: number }[],
  typeEnum: { [key: number]: string }
) => {
  return groupedDocuments.map((file) => ({
    label: typeEnum[file.fileType],
    value: String(file.files[0].id),
    icon: tabIcon(file.files[0]),
  }));
};
