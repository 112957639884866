import { useContext, useEffect, useMemo, useState } from 'react';

import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';

import { SheetPartnerOrInstaller } from '@models/partners/components/SheetPartnerOrInstaller';
import { SheetSubcontractor } from '@models/partners/components/SheetSubcontractor';

import { getPartnerActiveTabs } from '@models/partners/apiRequests/partnersFormRequest';
import { getPartnersMenu } from '@models/partners/apiRequests/partnersRequest';
import { HeaderContext } from '@context/headerContext';
import { ENTITY_TYPES } from '@utils/roles';
import { Tag } from '@components/atomic/Tag';
import { darkBlue } from '@assets/color';
import { IPartnerSubmenu } from '../utils/types/partnersType';
import { SheetPartnerByType } from './SheetPartnerByType';
import { PARTNERS_MENU_MAPPING } from '../utils/partnersConstants';

interface IPartnersDetailsProps {
  entityType: number | null;
  id: number;
  installerId: number | null;
}

function PartnersDetails({
  entityType,
  id,
  installerId,
}: IPartnersDetailsProps) {
  const { user } = useContext(AuthContext);
  const { updateDisplayBackButtonHeader, updateTagHeader } =
    useContext(HeaderContext);
  const {
    partnersMenu,
    updatePartnersMenu,
    updateUserView,
    updateListUserViews,
    globalEnum,
    route,
    updateRoute,
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabs, setTabs] = useState<IPartnerSubmenu[]>([]);

  const authUserIsInstaller = useMemo(
    () => ['installateur-admin'].includes(user?.role_name || ''),
    [user]
  );

  // NOTE: Getting tabs for selected partner
  useEffect(() => {
    updateDisplayBackButtonHeader(true);

    setLoading(true);
    if (entityType) {
      // get the hash as per entityType
      const currentPartnerRoute =
        user?.entity_type !== ENTITY_TYPES.INSTALLATEUR &&
        entityType === ENTITY_TYPES.INSTALLATEUR
          ? `installers`
          : `partners#${PARTNERS_MENU_MAPPING[entityType].hash}`;

      if (route.older.length === 0) {
        updateRoute({
          to: route.to,
          older: [currentPartnerRoute],
        });
      }

      if (partnersMenu.length < 1) {
        getPartnersMenu(
          updatePartnersMenu,
          updateListUserViews,
          updateUserView
        );
      } else if (
        globalEnum.entity_type &&
        (tabs.length === 0 || tabs[0].entityId !== Number(id))
      ) {
        getPartnerActiveTabs(Number(id), entityType)
          .then((formattedPartnersSubMenu) => setTabs(formattedPartnersSubMenu))
          .finally(() => setLoading(false));
      }
      setLoading(false);
      updateTagHeader(
        <Tag
          color={darkBlue}
          label={globalEnum?.entity_type[(entityType as number) || 0]}
        />
      );
    }
  }, [entityType, partnersMenu, globalEnum, id]);

  return useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPES.INSTALLATEUR:
        return (
          <SheetPartnerOrInstaller
            loadingTabs={loading}
            partnersSubMenu={[...tabs]}
            entityType={entityType}
            isSheetPartner={false}
          />
        );
      case ENTITY_TYPES.SOUS_TRAITANT:
        return (
          <SheetSubcontractor
            installerId={installerId}
            loadingTabs={loading}
            partnersSubMenu={tabs}
          />
        );
      case ENTITY_TYPES.APPORTEUR_AFFAIRES:
      case ENTITY_TYPES.BUREAUX_CONTROLE:
        return (
          <SheetPartnerByType
            loadingTabs={loading}
            partnersSubMenu={tabs}
            entityType={entityType}
          />
        );
      default:
        return (
          <SheetPartnerOrInstaller
            isSheetPartner
            isInstaller={authUserIsInstaller}
            loadingTabs={loading}
            partnersSubMenu={tabs}
            entityType={entityType}
          />
        );
    }
  }, [entityType, loading, tabs, authUserIsInstaller, id]);
}

export { PartnersDetails };
