import { Card } from '@components/Card';
import { ILabelValue } from '@globalTypes/globalTypes';
import { IContractOperationPrice } from '@models/contractCreation/utils/contractCreationTypes';
import { IAgreementList, IIframe } from '@models/settings/utils/settingsTypes';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { OperationsPriceEditor } from '../components/OperationsPriceEditor';
import { OperationsPriceSelector } from '../components/OperationsPriceSelector';
import { ContractCreationContextWrapper } from '../contexts/ContractCreationContextWrapper';
import { useIframeOperations as useSharedIframeOperations } from '../contexts/IframeOperationsContext';
import { useIframeOperations } from '../hooks/useIframeOperations';

interface IframeOperationPriceProps {
  iframeData: IIframe | null;
  onUpdateOperationPrices?: (prices: IContractOperationPrice[]) => void;
  agreements: IAgreementList[];
  contractOptions: ILabelValue[];
  isLoadingContracts: boolean;
}

/**
 * Composant pour la gestion des prix d'opérations
 * Utilise le formulaire principal pour les sélecteurs
 * et un formulaire isolé pour l'éditeur de prix d'opérations
 */
function IframeOperationPrice({
  iframeData,
  onUpdateOperationPrices,
  agreements,
  contractOptions,
  isLoadingContracts,
}: IframeOperationPriceProps) {
  const { t } = useTranslation();
  // Utilisation du contexte partagé pour obtenir les informations sur les modifications
  const { setCurrentOperationPrices } = useSharedIframeOperations();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [resetSignalContractModel, setResetSignalContractModel] = useState(0);

  // Utiliser le formulaire principal pour les sélecteurs
  const mainForm = useFormContext();

  // Récupération des valeurs du formulaire principal
  const contractId = mainForm.watch('contract_model_id');
  const beneficiaryType = mainForm.watch('beneficiary_type');
  const primeType = mainForm.watch('prime_type');

  // Formulaire isolé pour l'éditeur de prix d'opérations
  const editorForm = useForm();

  // Extraire les opérations de l'iframe si elles existent
  const iframeOperations = useMemo(() => {
    return iframeData?.operations || [];
  }, [iframeData]);

  // Utilisation du hook personnalisé pour la logique métier
  const {
    operationPrices,
    listOperations,
    isLoading,
    availableOperations,
    contractContextValue,
    fetchBuyingPricesOnSelectContract,
    fetchOperations,
    isContractModified,
  } = useIframeOperations({
    contractId,
    beneficiaryType,
    primeType,
    onUpdateOperationPrices: (prices) => {
      // Mettre à jour le contexte partagé
      setCurrentOperationPrices(prices);
      // Appeler le callback du parent si nécessaire
      if (onUpdateOperationPrices) {
        onUpdateOperationPrices(prices);
      }
    },
    agreements,
    initialOperations: iframeOperations,
  });

  // Effet pour charger les données quand le contrat change
  useEffect(() => {
    if (contractId) {
      fetchBuyingPricesOnSelectContract();
    }
  }, [contractId]);

  // Effet pour recharger les opérations quand le type de bénéficiaire change
  useEffect(() => {
    if (beneficiaryType) {
      fetchOperations();
    }
  }, [beneficiaryType]);

  // Effet pour réinitialiser le contrat sélectionné quand des modifications sont détectées
  useEffect(() => {
    if (isContractModified && contractId) {
      // Afficher un message informant l'utilisateur que le contrat a été désélectionné
      toast.info(
        t('settings.iframes.contract_modified') ||
          'Le contrat modèle a été désélectionné car les opérations ont été modifiées'
      );
      // Désélectionner le contrat modèle
      mainForm.setValue('contract_model_id', null);
      setResetSignalContractModel((prev) => prev + 1);
    }
  }, [isContractModified]);

  return (
    <Card
      title={t('settings.iframes.operation_price_title') || ''}
      subtitle={t('settings.iframes.operation_price_description') || ''}
      addClass="w-full"
    >
      <div className="flex flex-col gap-6">
        {/* Sélecteurs qui utilisent le formulaire principal */}
        <OperationsPriceSelector
          contractOptions={contractOptions}
          isLoadingContracts={isLoadingContracts}
          contractId={contractId}
          beneficiaryType={beneficiaryType}
          primeType={primeType}
          agreements={agreements}
          resetSignalContractModel={resetSignalContractModel}
        />

        {/* Zone d'affichage des prix d'opérations avec son formulaire isolé */}
        {listOperations && listOperations.length > 0 && (
          <div className="border border-borderGrey rounded-default p-4 pb-[15rem]">
            <FormProvider {...editorForm}>
              <ContractCreationContextWrapper
                contextValue={contractContextValue}
              >
                <OperationsPriceEditor
                  operationPrices={operationPrices}
                  listOperations={listOperations}
                  isLoading={isLoading}
                  availableOperations={availableOperations}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              </ContractCreationContextWrapper>
            </FormProvider>
          </div>
        )}
      </div>
    </Card>
  );
}

IframeOperationPrice.defaultProps = {
  onUpdateOperationPrices: undefined,
};

export default IframeOperationPrice;
