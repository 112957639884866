import { Dispatch, SetStateAction } from 'react';
import {
  ChevronLeftIcon,
  MoreDotsVerticalIcon,
} from '@assets/images/svgComponents';
import { blueOpx, textGrey } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { Tag } from '@components/atomic/Tag';
import { IControlContactSettingsField } from '@models/controlContact/utils/controlContactTypes';
import { useTranslation } from 'react-i18next';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

interface IOneFieldInListProps {
  field: IControlContactSettingsField;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  setFieldIdToEdit?: Dispatch<SetStateAction<number | null>>;
}

/**
 * Composant représentant un champ dans la liste avec fonctionnalité de drag and drop.
 * Le drag est déclenché via le clic sur l'icône MoreDotsVerticalIcon.
 */
function OneFieldInList({
  field,
  dragHandleProps,
  setFieldIdToEdit,
}: IOneFieldInListProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`p-4 w-full rounded rounded-default grid grid-cols-[1fr_2fr_auto] overflow-hidden ${
        field.readonly ? 'bg-greyOpacity10' : ''
      }`}
    >
      <div className="flex space-x-6 items-center">
        <div className="w-6 h-6">
          {!field.readonly && (
            <div {...dragHandleProps}>
              <MoreDotsVerticalIcon fill={blueOpx} />
            </div>
          )}
        </div>
        <p>{field.label}</p>
      </div>
      <div className="flex flex-grow justify-between items-center overflow-y-auto mr-4">
        <div className="flex space-x-2 items-center">
          {field.operation_codes.length > 0 ? (
            field.operation_codes.map((code) => (
              <Tag key={code} label={code} color={textGrey} />
            ))
          ) : (
            <Tag
              label={t('control_contact.settings.all_operations')}
              color={textGrey}
            />
          )}
        </div>
      </div>
      {!field.readonly && (
        <div className="flex justify-end">
          <ColorCube
            color={blueOpx}
            size="1.5rem"
            numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
            opacity
            onClick={() => setFieldIdToEdit?.(field.id)}
          />
        </div>
      )}
    </div>
  );
}

export default OneFieldInList;

OneFieldInList.defaultProps = {
  dragHandleProps: null,
  setFieldIdToEdit: undefined,
};
