import { axiosClient } from '@api/apiClient';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import {
  urlGetEtablissementData,
  urlPostIsMySubcontractor,
  urlGetEtablissementRge,
  urlPostIsMyPartner,
} from '@api/apiUrls';

const verifyPartnerStatus = async (entityId: number, entityType?: number) => {
  try {
    if (entityType !== undefined && entityType !== null) {
      const response = await axiosClient.post(
        urlPostIsMyPartner(entityId, entityType)
      );
      return { isPartner: response.data.is_partner, isSubcontractor: false };
    }

    const response = await axiosClient.post(urlPostIsMySubcontractor(entityId));
    return { isPartner: false, isSubcontractor: response.data };
  } catch (error) {
    toast.error(error.response.data.errors);
    return { isPartner: false, isSubcontractor: false };
  }
};

export const getEtablissementData = async (
  siret: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setValue?: UseFormSetValue<FieldValues>,
  entityType?: number
) => {
  try {
    const response = await axiosClient.post(urlGetEtablissementData, { siret });
    const { data } = response.data;
    setLoading(false);

    if (setValue) {
      setValue('id', data.id);
      setValue('siren', data.siren);
      setValue('siret', data.siret);
      setValue('legal_category', data.legal_category);
      setValue('zipcode', data.zipcode);
      setValue('city', data.city);
      setValue('entity_to_id', data.id);
      setValue('status', data.status);
      setValue('cessation_date', data.cessation_date);
      setValue('is_my_subcontractor', false);
      setValue('is_my_partner', false);

      if (data.id) {
        const { isPartner, isSubcontractor } = await verifyPartnerStatus(
          data.id,
          entityType
        );
        setValue('is_my_partner', isPartner);
        setValue('is_my_subcontractor', isSubcontractor);
      }

      setValue('company_name', data.company_name);
      setValue('address', data.address);
    }

    if (!data.id) {
      const newEntity = {
        siret: data.siret,
        siren: data.siren,
        legal_category: data.legal_category,
        company_name: data.company_name,
        zipcode: data.zipcode,
        city: data.city,
        address: data.address,
      };
      if (setValue) setValue('new_entity', newEntity);
    }

    return data;
  } catch (error) {
    setLoading(false);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      const errors = error.response.data.errors;

      if (typeof errors === 'string') {
        // exemple : 61755928110480 will return Impossible de récupérer les informations de l'entreprise obtained from back
        // Handle case when errors is a string
        toast.error(errors);
      } else if (Array.isArray(errors) && errors.length > 0) {
        // Handle case when errors is an array
        const firstError = errors[0];
        if (firstError) {
          toast.error(firstError);

          if (firstError.siret && firstError.siret.length > 0) {
            toast.error(firstError.siret[0]);
          }
        }
      } else {
        toast.error(errors);
      }
    }

    if (setValue) {
      setValue('company_name', '');
      setValue('address', '');
      setValue('id', '');
      setValue('siren', '');
      setValue('legal_category', '');
      setValue('zipcode', '');
      setValue('city', '');
      setValue('entity_to_id', '');
      setLoading(false);
    }

    return false;
  }
};

export const getEtablissementRge = async (
  siret: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  prefix?: string,
  setValue?: UseFormSetValue<FieldValues>,
  withoutCheckRge?: boolean
) => {
  const prefixValue = prefix ? `${prefix}.` : '';
  try {
    const response = await axiosClient.post(urlGetEtablissementRge, { siret });
    const { data } = response.data;
    setLoading(false);

    if (setValue) {
      setValue(`${prefixValue}company_name`, data.company_name);
      setValue(`${prefixValue}siren`, data.siren);
      setValue(`${prefixValue}siret`, data.siret);
      setValue(`${prefixValue}legal_category`, data.legal_category);
      setValue(`${prefixValue}zipcode`, data.zipcode);
      setValue(`${prefixValue}city`, data.city);
      setValue(`${prefixValue}address`, data.address);
      if (data.id) {
        setValue(`${prefixValue}entity_id`, data.id);
      } else {
        setValue(`${prefixValue}entity_id`, null);
      }
      if (
        (!data.certifications || data.certifications.length === 0) &&
        !withoutCheckRge
      ) {
        setValue(`${prefixValue}no_rge`, true);
      } else {
        setValue(`${prefixValue}no_rge`, false);
      }
    }

    return data;
  } catch (error) {
    setLoading(false);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      if (error.response?.data?.errors?.length > 0) {
        const firstError = error.response.data.errors[0];
        if (firstError) {
          toast.error(firstError);
          if (firstError.siret && firstError.siret.length > 0) {
            toast.error(firstError.siret[0]);
          }
        }
      } else {
        toast.error(error.response.data.errors);
      }
    }

    if (setValue) {
      setValue(`${prefixValue}company_name`, '');
      setValue(`${prefixValue}siren`, '');
      setValue(`${prefixValue}legal_category`, '');
      setValue(`${prefixValue}zipcode`, '');
      setValue(`${prefixValue}city`, '');
      setValue(`${prefixValue}address`, '');
      setValue(`${prefixValue}entity_id`, '');
      setLoading(false);
    }

    return false;
  }
};
