import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AddIcon } from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { UploadButton } from '@components/documents/UploadButton';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { Loader } from '@components/atomic/Loader';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { dateToDDMMYYY, toAPIDateStr } from '@utils/format';
import { isBeforeDate } from '@utils/functions';
import { DATE_MODAL } from '@models/cofrac/utils/cofracConstants';
import { FormProvider, useForm } from 'react-hook-form';
import { AddCofracOperations } from '@models/cofrac/components/AddCofracOperations';
import { fileTypeEnum } from '@utils/enums';
import { ICofracSatisfactionInputs } from '@models/cofrac/utils/utils';

interface CofracSatisfactionProps {
  data: ICofracType | undefined;
  setData: Dispatch<SetStateAction<ICofracType | undefined>>;
  modal: string;
  reportDate: string | null;
  synthesisDate: string | null;
  setModal: Dispatch<SetStateAction<string>>;
  setReportDate: Dispatch<SetStateAction<string | null>>;
  setSynthesisDate: Dispatch<SetStateAction<string | null>>;
}

function CofracSatisfaction({
  data,
  setData,
  modal,
  setModal,
  reportDate,
  synthesisDate,
  setReportDate,
  setSynthesisDate,
}: CofracSatisfactionProps): JSX.Element {
  const { t } = useTranslation();

  const methods = useForm<ICofracSatisfactionInputs>({
    defaultValues: {
      reportDate: reportDate || '',
      synthesisDate: synthesisDate || '',
      reportFile: null,
      summaryFile: null,
    },
  });
  const { handleSubmit, setValue, watch } = methods;
  const formValues = watch();

  const [errors, setErrors] = useState<{
    date: boolean;
    report: boolean;
    summary: boolean;
  }>({
    date: true,
    report: true,
    summary: true,
  });
  const [isSubmitted] = useState<boolean>(false);
  const [showOperations, setShowOperations] = useState(false);
  const [isFormNotValid, setIsFormNotValid] = useState<boolean>(false);

  const setError = (field: 'date' | 'report' | 'summary', val: boolean) => {
    setErrors((prevState) => ({ ...prevState, [field]: val }));
  };

  const onNext = (cofrac: any) => {
    setShowOperations(true);
    if (cofrac.reportDate && cofrac.synthesisDate) {
      setReportDate(cofrac.reportDate);
      setSynthesisDate(cofrac.synthesisDate);
    }
  };

  const resetForm = () => {
    setValue('reportDate', '');
    setValue('synthesisDate', '');
    setValue('summaryFile', null);
    setValue('reportFile', null);
    setModal('');
  };

  // Gestion de l'affichage des docs dans le composant UploadButton
  const handleDocUpload = (fileType: number, fileIsLoading: boolean) => {
    const isSummary = fileType === fileTypeEnum.COFRAC_SUMMARY_REPORT_FILE;

    const placeholder = isSummary
      ? t('cofrac.form.summary_placeholder')
      : t('cofrac.form.report_placeholder');

    const fileName = isSummary
      ? formValues.summaryFile?.name
      : formValues.reportFile?.name;

    const error = isSummary ? errors.summary : errors.report;
    const borderColor =
      isSubmitted && error ? 'border-red' : 'border-borderGrey';

    return (
      <div
        className={`flex justify-between flex-wrap items-center p-2 cursor-pointer rounded-default
          hover:bg-greyOpacity20 w-full border ${borderColor} bg-white right-0 rounded-default p-2`}
      >
        <div className="text-textGrey">{fileName || placeholder}</div>
        <div className="flex items-center gap-2">
          <div>{t('global.mandatory')}</div>
          <ColorCube
            color={blueOpx}
            numberOrIcon={fileIsLoading ? <Loader /> : <AddIcon />}
            size="2rem"
            opacity
            addClass="rounded"
          />
        </div>
      </div>
    );
  };

  // Enregistrement des dates + gestion d'erreur
  const handleChangeDate = (
    e: string,
    fieldName: 'reportDate' | 'synthesisDate'
  ) => {
    const selectedDate = toAPIDateStr(e);
    const cofracDate = data ? data.cofrac_date.slice(0, 10) : '';

    if (isBeforeDate(e, cofracDate)) {
      setError('date', true);
    } else {
      setValue(fieldName, selectedDate);

      if (fieldName === 'reportDate') {
        setReportDate(selectedDate);
      } else if (fieldName === 'synthesisDate') {
        setSynthesisDate(selectedDate);
      }

      setError('date', false);
    }
  };

  useEffect(() => {
    const areDatesValid =
      formValues.reportDate.trim() !== '' &&
      formValues.synthesisDate.trim() !== '';

    const areFilesUploaded =
      formValues.reportFile instanceof File ||
      formValues.summaryFile instanceof File;

    setIsFormNotValid(!(areDatesValid && areFilesUploaded));
  }, [formValues]);

  // todo bouton suivant actif si au moins un des 2 docs a été uploadé
  /*
  useEffect(() => {
    const formValues = watch();
    const areAllFieldsFilled =
      !!formValues.reportDate &&
      !!formValues.synthesisDate &&
      (!!formValues.reportFile || !!formValues.summaryFile);

    setIsFormNotValid(!areAllFieldsFilled);
  }, [watch()]);
  */

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onNext)}>
        {!showOperations ? (
          <Modal
            title={`${t('cofrac.response')}`}
            backgroundTransparent
            sidebarVisible
            buttonsPosition="bottom"
            onConfirmClick={handleSubmit(onNext)}
            btnCancel
            onClickCancel={() => resetForm()}
            textBtnConfirm={`${t('buttons.next')}`}
            btnConfirmDisabled={isFormNotValid}
          >
            <>
              <div className="w-full flex gap-3 my-5">
                <TextWithRadio
                  label={`${t('cofrac.status.ok')}`}
                  value={DATE_MODAL.OK}
                  selectValue={DATE_MODAL.OK}
                  setSelectedValue={() => setModal(DATE_MODAL.OK)}
                  isChecked={modal === DATE_MODAL.OK}
                  addClass="w-full"
                />
                <TextWithRadio
                  label={`${t('cofrac.status.ko')}`}
                  value={DATE_MODAL.KO}
                  selectValue={DATE_MODAL.KO}
                  setSelectedValue={() => setModal(DATE_MODAL.KO)}
                  isChecked={modal === DATE_MODAL.KO}
                  addClass="w-full"
                />
              </div>
              <p>{t('cofrac.form.docs')}</p>
              <div className="grid grid-cols-2 gap-x-3 gap-y-5 w-full my-5 items-center justify-between">
                <UploadButton
                  name="cofrac_report"
                  onUpload={(file) => setValue('reportFile', file)}
                  fileType={fileTypeEnum.COFRAC_REPORT_FILE}
                  icon={handleDocUpload(fileTypeEnum.COFRAC_REPORT_FILE, false)}
                />
                <DatePicker
                  defaultDate={
                    data?.report_date
                      ? dateToDDMMYYY(data?.report_date.slice(0, 10))
                      : undefined
                  }
                  required
                  onChangeDate={(e) => handleChangeDate(e, 'reportDate')}
                  placeholder="JJ/MM/AAAA"
                  error={errors.date}
                  textError={`${t('cofrac.form.date_ok_error')}`}
                />
                <UploadButton
                  name="cofrac_summary"
                  onUpload={(file) => setValue('summaryFile', file)}
                  fileType={fileTypeEnum.COFRAC_SUMMARY_REPORT_FILE}
                  icon={handleDocUpload(
                    fileTypeEnum.COFRAC_SUMMARY_REPORT_FILE,
                    false
                  )}
                />
                <DatePicker
                  defaultDate={
                    data?.synthesis_date
                      ? dateToDDMMYYY(data?.synthesis_date.slice(0, 10))
                      : undefined
                  }
                  required
                  onChangeDate={(e) => handleChangeDate(e, 'synthesisDate')}
                  placeholder="JJ/MM/AAAA"
                  error={errors.date}
                  textError={`${t('cofrac.form.date_ok_error')}`}
                />
              </div>
            </>
          </Modal>
        ) : (
          <AddCofracOperations
            data={data}
            setData={setData}
            modal={modal}
            setModal={setModal}
          />
        )}
      </form>
    </FormProvider>
  );
}

export { CofracSatisfaction };
