import * as yup from 'yup';
import { TOptions } from 'i18next';
import {
  bicRegex,
  emailValidationRegex,
  ibanRegex,
  phoneNumberRegex,
  siretRegex,
} from '@utils/regex';
import { validSiren } from '@utils/functions';

type SafeTFunction = (key: string, options?: TOptions) => string;

const siretValidator = (t: SafeTFunction) =>
  yup
    .string()
    .required(t('forms.required_error_message'))
    .matches(siretRegex, t('forms.siret.error'));

const sirenValidator = (t: SafeTFunction) =>
  yup
    .string()
    .required(t('forms.required_error_message'))
    .test(
      'is-valid-siren',
      t('auth.registration.forms.siren_error_message'),
      validSiren
    );

const emailValidator = (t: SafeTFunction) =>
  yup
    .string()
    .required(t('forms.required_error_message'))
    .matches(emailValidationRegex, t('forms.email.error_pattern') || '');

/* const numberValidator = (t: SafeTFunction) =>
  yup
    .string()
    .matches(numberRegex, t('forms.only_number'))
    .required(t('forms.required_error_message')); */

export const getLoginSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    email: emailValidator(t),
    password: yup.string().required(t('forms.required_error_message')),
  });
};

export const getElectronicSignatureSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    beneficiary_email: emailValidator(t),
    beneficiary_phone: yup.string().required(t('forms.required_error_message')),
    signatory_selected: yup
      .string()
      .required(t('forms.required_error_message')),
  });
};

export const getAddUserSchema = (t: SafeTFunction) => {
  const optionalPhoneRegex = new RegExp(`^(${phoneNumberRegex.source}|)$`);

  return yup.object().shape({
    email: emailValidator(t),
    lastname: yup.string().required(t('forms.required_error_message')),
    firstname: yup.string().required(t('forms.required_error_message')),
    function: yup.string().required(t('forms.required_error_message')),
    phone_number: yup
      .string()
      .matches(optionalPhoneRegex, t('forms.phone.error_pattern')),
  });
};

export const getSimpleStringSchema = (
  t: SafeTFunction,
  key: string,
  required?: boolean
) => {
  let schema = yup.string();

  // Ajouter le contrôle requis seulement si required est vrai
  if (required) {
    schema = schema.required(t('forms.required_error_message'));
  }

  return yup.object().shape({
    [key]: schema,
  });
};

export const getSiretSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    siret: siretValidator(t),
  });
};

export const getRecognisedSiretSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    company_name: yup.string().required(t('forms.required_error_message')),
    siret: siretValidator(t),
    siren: sirenValidator(t),
    address: yup.string().required(t('forms.required_error_message')),
  });
};

export const getRegistrationPersonalInfoSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    lastname: yup.string().required(t('forms.required_error_message')),
    firstname: yup.string().required(t('forms.required_error_message')),
    function: yup.string().required(t('forms.required_error_message')),
    email: emailValidator(t),
    phone_number: yup
      .string()
      .required(t('forms.required_error_message'))
      .matches(phoneNumberRegex, t('forms.phone.error_pattern')),
    password: yup
      .string()
      .required(t('forms.required_error_message'))
      .min(8, t('forms.password.error_min_length')),
    confirmPassword: yup
      .string()
      .required(t('forms.required_error_message'))
      .min(8, t('forms.password.error_min_length'))
      .oneOf(
        [yup.ref('password')],
        t('forms.password.error_matching') as string
      ),
  });
};

export const getResetPasswordInfoSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(t('forms.required_error_message'))
      .min(8, t('forms.password.error_min_length')),
    confirmPassword: yup
      .string()
      .required(t('forms.required_error_message'))
      .min(8, t('forms.password.error_min_length'))
      .oneOf(
        [yup.ref('password')],
        t('forms.password.error_matching') as string
      ),
  });
};

export const getRegistrationCompanyManagerInfoSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    lastname: yup.string().required(t('forms.required_error_message')),
    firstname: yup.string().required(t('forms.required_error_message')),
    email: emailValidator(t),
  });
};

export const getEmailSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    email: emailValidator(t),
  });
};

export const getPasswordValidationSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    code: yup.string().required(t('forms.required_error_message')),
  });
};

export const getChangePasswordSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    old_password: yup.string().required(t('forms.required_error_message')),
    new_password: yup
      .string()
      .required(t('forms.required_error_message'))
      .min(8, t('forms.password.error_min_length')),
  });
};
export const getAddCofracSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    operation_id: yup.number().required(t('forms.required_error_message')),
    name: yup.string().required(t('forms.required_error_message')),
  });
};

export const getAddControlContactSchema = (t: SafeTFunction) => {
  return yup
    .object()
    .shape({
      operation_id: yup.number().nullable(),
      cofrac_id: yup.number().nullable(),
      name: yup.string().optional(),
      lot_source: yup.string().required(t('forms.required_error_message')),
    })
    .test(
      'cofrac_or_operation_required',
      t('forms.required_error_message'),
      function cofracOrOperationRequired(value) {
        const { operation_id, cofrac_id } = value;
        const hasOperation = Boolean(operation_id && operation_id > 0);
        const hasCofrac = Boolean(cofrac_id && cofrac_id > 0);

        if (!hasOperation && !hasCofrac) {
          // On attache l’erreur au champ "operation_id"
          return this.createError({ path: 'operation_id' });
        }

        return true;
      }
    );
};

export const getCollabRegistrationInfoSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    lastname: yup.string().required(t('forms.required_error_message')),
    firstname: yup.string().required(t('forms.required_error_message')),
    function: yup.string().required(t('forms.required_error_message')),
    phone_number: yup
      .string()
      .required(t('forms.required_error_message'))
      .matches(phoneNumberRegex, t('forms.phone.error_pattern')),
    password: yup
      .string()
      .required(t('forms.required_error_message'))
      .min(8, t('forms.password.error_min_length')),
    confirmPassword: yup
      .string()
      .required(t('forms.required_error_message'))
      .min(8, t('forms.password.error_min_length'))
      .oneOf(
        [yup.ref('password')],
        t('forms.password.error_matching') as string
      ),
  });
};

export const getCertificationCreationSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    type: yup.number().notRequired(),
    name: yup.string().required(t('forms.required_error_message')),
    reference: yup.string().required(t('forms.required_error_message')),
    start_date: yup.string().required(t('forms.required_error_message')),
    end_date: yup.string().required(t('forms.required_error_message')),
    /* end_date: yup
      .string()
      .required(t('forms.required_error_message'))
      .test(
        'is-date-before-today',
        t('certifications.expired_at_creation_error_message'),
        (value) => {
          const today = new Date();
          const endDate = new Date(value);
          return endDate > today;
        }
      ), */
    uploadedFile: yup.mixed().notRequired(),
  });
};

export const getIbanSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    iban: yup
      .string()
      .required(t('forms.required_error_message'))
      .matches(ibanRegex, t('forms.iban.error')),
  });
};

export const getFinancialDataSchema = (t: SafeTFunction) => {
  return yup.object().shape({
    iban: yup
      .string()
      .required(t('forms.required_error_message'))
      .matches(ibanRegex, t('forms.iban.error')),
    bic: yup
      .string()
      .required(t('forms.required_error_message'))
      .matches(bicRegex, t('forms.bic.error')),
  });
};
