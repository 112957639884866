import { InputText } from '@components/atomic/inputs/InputText';
import { Modal } from '@components/Modal';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import {
  getListRequests,
  postNewRequest,
} from '@models/requests/apiRequest/requestRequests';
import { IPayloadDataPostListRequests } from '@models/requests/types/requestTypes';
import { getWorksiteDetails } from '@models/worksites/apiRequests/worksitesRequests';
import { useContext, useState, useEffect } from 'react';
import { useForm, FieldValues, FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';

function NewRequestModal() {
  const location = useLocation();

  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const {
    updateIsModalNewRequest,
    updateWorksiteDetailForNewRequest,
    updateIsLoadingListRequestOrChange,
    numberPageRequestsList,
    updateTotalRequestsMessagesList,
    listMessagesRequests,
    updateListMessagesRequests,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const [isLoading, setIsLoading] = useState(false);

  const idWorkiste =
    location && location.pathname && location.pathname.match(/\d+/)?.[0];

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    const payload = {
      parent_demand_id: null,
      relation_type: location.pathname.includes('worksites')
        ? 'worksite'
        : 'partner',
      relation_id: Number(idWorkiste),
      subject: data.requestTitle,
      message: data.requestContent,
      is_read: false,
    };

    const isSuccess = await postNewRequest(payload);
    if (isSuccess) {
      const dataPost: IPayloadDataPostListRequests = {
        pagination: {
          page: numberPageRequestsList,
          perPage: 10,
        },
        status: 1,
        relation_type: 'worksite',
        relation_id: Number(idWorkiste),
      };

      await getListRequests(
        updateListMessagesRequests,
        updateTotalRequestsMessagesList,
        listMessagesRequests,
        dataPost,
        updateIsLoadingListRequestOrChange,
        2
      );
      updateIsModalNewRequest(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getWorksiteDetails(updateWorksiteDetailForNewRequest, idWorkiste || '');
  }, []);
  return (
    <div>
      <Modal
        btnCancel
        btnConfirmDisabled={
          watch('requestTitle') === '' ||
          watch('requestContent') === '' ||
          isLoading
        }
        textBtnCancel={`${t('buttons.cancel')}`}
        textBtnConfirm={`${t('buttons.create')}`}
        title={`${t('requests_and_changes.new_message')}`}
        backgroundTransparent
        onClickCancel={() => updateIsModalNewRequest(false)}
        formId="newRequestForm"
        isLoading={isLoading}
        buttonsPosition="bottom"
      >
        <FormProvider {...methods}>
          <form
            action="submit"
            id="newRequestForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p className="text-[.875rem] my-[1rem]">
              Créez une demande personnalisée à votre interlocuteur.
            </p>
            <div className="flex flex-col space-y-[.5rem]">
              <InputText
                placeholder="Titre de la demande"
                id="requestTitle"
                name="requestTitle"
                required
              />
              <InputText
                placeholder="Contenu de la demande"
                id="requestContent"
                name="requestContent"
                required
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
}

export { NewRequestModal };
