import { useMemo, useState, useEffect } from 'react';
import { v4 } from 'uuid';

import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { IPartnerSubmenu } from '@models/partners/utils/types/partnersType';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { SubHeader } from '@components/subHeader/SubHeader';
import { sheets } from '@utils/utils';
import { ENTITY_TYPES } from '@utils/roles';
import { TabBusinessProviderInfos } from './businessProviders/TabBusinessProviderInfos';
import { TabPartnersDocuments } from './TabPartnersDocuments';
import { TabControlOfficeInfos } from './controlOffice/TabControlOfficeInfos';

interface ISheetPartnerByTypeProps {
  loadingTabs: boolean;
  partnersSubMenu: IPartnerSubmenu[];
  entityType: number;
}

function SheetPartnerByType({
  loadingTabs,
  partnersSubMenu,
  entityType,
}: ISheetPartnerByTypeProps): JSX.Element {
  const [sheetContent, setSheetContent] = useState<string>('informations');
  const [viewTabs, setViewTabs] = useState<IPartnerSubmenu[]>([]);
  const [titlesList, setTitlesList] = useState<string[]>([]);

  const renderTab = useMemo(() => {
    switch (sheetContent) {
      case sheets.INFORMATIONS:
        return entityType === ENTITY_TYPES.APPORTEUR_AFFAIRES ? (
          <TabBusinessProviderInfos />
        ) : (
          <TabControlOfficeInfos />
        );
      case sheets.DOCUMENTS:
        return <TabPartnersDocuments entityType={entityType} />;
      default:
        return <p>{sheetContent}</p>;
    }
  }, [sheetContent, entityType]);

  useEffect(() => {
    const viewTabsToSet = partnersSubMenu.filter((tab: IPartnerSubmenu) =>
      [sheets.INFORMATIONS, sheets.DOCUMENTS].includes(tab.content || '')
    );
    setViewTabs(viewTabsToSet);
    setTitlesList(viewTabsToSet.map((tab) => tab.submenuLabel || ''));
  }, [partnersSubMenu]);

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          !loadingTabs ? (
            <TabsLeftPart
              titlesList={titlesList}
              onClick={(title) => {
                const selectedTab = viewTabs.find(
                  (tab) => tab.submenuLabel === title
                );
                setSheetContent(selectedTab?.content || '');
              }}
            />
          ) : (
            <div>
              {[...Array(3)].map(() => (
                <LoaderSkeleton
                  key={v4()}
                  height="2.75rem"
                  addClass="mr-3 w-[7.5rem]"
                />
              ))}
            </div>
          )
        }
      />
      <div className="tabs_content">{renderTab}</div>
    </div>
  );
}

export { SheetPartnerByType };
