import { blueOpx } from '@assets/color';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { GlobalSearch } from '@components/header/GlobalSearch';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';
import { ROLES } from '@utils/roles';
import { DIMENSIONS } from '@utils/utils';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonsRequestsAndChanges } from './header/ButtonsRequestsAndChanges';

function Header(): JSX.Element {
  const { sidebarMenuIsOpen, roleUser, route } = useContext(GlobalContext);

  const {
    header_height,
    sidebarmenu_open_percent_width,
    sidebarmenu_close_percent_width,
  } = DIMENSIONS;

  const MENU_OPEN_WIDTH = `${100 - sidebarmenu_open_percent_width}%`;
  const MENU_CLOSE_WIDTH = `${100 - sidebarmenu_close_percent_width}%`;

  const {
    titleHeader,
    displayBackButtonHeader,
    subtitleHeader,
    tagHeader,
    additionnalInfosHeader,
  } = useContext(HeaderContext);

  const navigate = useNavigate();

  const onClick = () => {
    if (route.older.length > 0) {
      navigate(route.older[route.older.length - 1]);
    }
  };

  return (
    <div
      className="flex fixed z-50 bg-white justify-between flex-row items-center px-6 border-b border-b-borderGrey right-0 top-0 z-[52] transition-all duration-500 ease-in-out"
      style={{
        width: sidebarMenuIsOpen ? MENU_OPEN_WIDTH : MENU_CLOSE_WIDTH,
        height: header_height,
      }}
    >
      <div className="w-full flex space-x-[1rem] items-center">
        {displayBackButtonHeader && route.older.length > 0 && (
          <ColorCube
            size="1.5rem"
            numberOrIcon={<ChevronLeftIcon />}
            color={blueOpx}
            onClick={onClick}
            opacity
            dataTestId="chevron_header"
          />
        )}
        {titleHeader !== 'loading' ? (
          <div className="flex space-x-[1rem] items-center">
            <p
              className="text-[1.75rem] font-[500]"
              data-test-id="title_header"
            >
              {titleHeader}
            </p>
            {additionnalInfosHeader &&
              additionnalInfosHeader.map((info) => (
                <p className="text-[.875rem] text-black" key={info}>
                  {info}
                </p>
              ))}
          </div>
        ) : (
          <LoaderSkeleton height="2.625rem" addClass="w-[20rem]" />
        )}
        {subtitleHeader && <p className="text-[.875rem]">{subtitleHeader}</p>}
        {tagHeader && tagHeader}
      </div>
      {roleUser !== ROLES.ADMIN && <GlobalSearch />}
      <ButtonsRequestsAndChanges />
    </div>
  );
}

export { Header };

Header.defaultProps = {
  tag: undefined,
  displayBackButton: false,
  subtitle: undefined,
  additionnalInfos: undefined,
  displaySearchBarHeader: true,
};
