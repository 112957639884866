import { TFunction } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { IWorksiteAddress } from '@models/worksiteCreation/utils/types/worksitesType';
import { IFormAddressDatas } from '@components/formAddress/FormAddress';
import { IKeyStringType } from '../types/globalTypes';

export const BENEFICIARY_GENERAL_DOMAIN = {
  SYNDIC: 'syndic',
};

export const customStatusDoc: { [key: number]: string } = {
  1: 'À importer',
  2: 'En attente de validité',
  3: 'Refusé',
  4: 'Validé',
  5: 'À signer',
  6: 'En attente',
  7: 'Signé',
};

export const DOCUMENT_STATE_STRING = {
  TO_IMPORT: 'À importer',
  PENDING: 'En attente de validité',
  REFUSE: 'Refusé',
  VALIDATE: 'Validé',
  TO_SIGN: 'À signer',
  AWAITING_SIGNATURE: 'En attente',
  SIGNED: 'Signé',
};

export const customStatusSignature: { [key: number]: string } = {
  1: 'En attente de signature',
  2: 'Signé',
  3: 'Annulé',
};

export const SIGNATURE_STATE = {
  AWAITING_SIGNATURE: 'En attente de signature',
  SIGNED: 'Signé',
  CANCELED: 'Annulé',
};

export const DOCUMENT_STATUS_ENUM = {
  TO_IMPORT: 1,
  PENDING: 2,
  REFUSE: 3,
  VALIDATE: 4,
  TO_SIGN: 5,
  AWAITING_SIGNATURE: 6,
  SIGNED: 7,
};

export const DOCUMENT_GENERATION_STATE = {
  GENERATION: 1,
  GENERATED: 2,
};

export const BUTTONS_DOCUMENT_STATE_TABS = (t: TFunction): IKeyStringType => {
  return {
    REFUSED: t('global.document_refused'),
    ACCEPTED: t('global.document_accepted'),
    REQUEST: t('global.document_request'),
    CHANGE: t('global.document_change'),
    CANCELED: t('global.canceled'),
    VALIDATE_REJECTION: t('global.document_validate_rejection'),
    CONFIRM_DOCUMENT: t('global.document_confirm'),
  };
};
export const getIconAndCharacSize = (
  size: '1.5rem' | '1rem' | '2rem' | '2.5rem'
) => {
  let icon;
  let charac;
  switch (size) {
    case '1rem':
      icon = '.5rem';
      charac = 'text-[.75rem] leading-[.75rem]';
      break;
    case '1.5rem':
      icon = '1rem';
      charac = 'text-[.75rem] leading-[.75rem]';
      break;
    case '2rem':
      icon = '1.5rem';
      charac = 'text-[.875rem] leading-[.875rem]';
      break;
    case '2.5rem':
      icon = '2rem';
      charac = 'text-[1rem] leading-[1rem]';
      break;
    default:
      icon = '1.5rem';
      charac = 'text-[.875rem] leading-[.875rem]';
      break;
  }
  return { icon, charac };
};

export function useOptionalFormContext() {
  let context;

  try {
    context = useFormContext();
  } catch (error) {
    context = null;
  }

  return context;
}

// Utilisez une valeur par défaut de 1.5 Mo si la variable d'environnement n'est pas définie
export const MAX_FILE_SIZE_MB = Number(
  process.env.REACT_APP_MAX_FILE_SIZE || 1.5
);

// Convertissez la taille maximale du fichier en octets pour la comparaison avec la taille du fichier
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

export const INVITATION_STATE = (t: TFunction): IKeyStringType => {
  return {
    PENDING: t('auth.registration.account_completed.invitation_status.pending'),
    DONE: t('auth.registration.account_completed.invitation_status.done'),
    CANCELLED: t(
      'auth.registration.account_completed.invitation_status.cancelled'
    ),
  };
};

export const DIMENSIONS = {
  header_height: '5.5rem',
  requests_and_changes_width: '35rem',
  sidebarmenu_open_percent_width: 15,
  sidebarmenu_close_percent_width: 5,
};

export const convertToFormAddressDatas = (
  worksiteAddress: IWorksiteAddress
): IFormAddressDatas => ({
  street: worksiteAddress.streetName,
  streetNumber: worksiteAddress.numberStreet,
  zipCode: worksiteAddress.zipCode,
  city: worksiteAddress.city,
  country: worksiteAddress.country,
  latitude: worksiteAddress.latitude,
  longitude: worksiteAddress.longitude,
});

export const transformAddressKeys = (
  datas: Partial<IFormAddressDatas>
): Partial<IWorksiteAddress> => {
  const transformed: Partial<IWorksiteAddress> = {};

  if (datas.city !== undefined) transformed.city = datas.city;
  if (datas.country !== undefined) transformed.country = datas.country;
  if (datas.street !== undefined) transformed.streetName = datas.street;
  if (datas.streetNumber !== undefined)
    transformed.numberStreet = datas.streetNumber;
  if (datas.zipCode !== undefined) transformed.zipCode = datas.zipCode;
  if (datas.latitude !== undefined) transformed.latitude = datas.latitude;
  if (datas.longitude !== undefined) transformed.longitude = datas.longitude;

  return transformed;
};

export const EVENT_TYPES = {
  LOADING: 'loading',
  UPDATE: 'update',
};

export const DOCUMENT_TYPES = {
  AH: 1,
  CompletionCertificate: 2, // Attestation de fin de travaux
  ContributionFrame: 3, // Cadre de contribution
  Quote: 4, // Devis
  Invoice: 5, // Facture
  AfterServicePhoto: 6, // Photo SAV
  IndivisionAH: 7, // AH indivision
  TaxNotice: 8, // Avis d'imposition
  OwnershipProof: 9, // Justificatif de propriété
  LandlordAH: 10, // AH bailleur
  GrantNotification: 11, // Notification d'octroi
  BeforeWorkPhoto: 12, // Photo avant chantier
  TechnicalSheet: 13, // Fiche technique
  EnergyAuditReport: 14, // Rapport d'audit énergétique
  SizingNote: 15, // Note de dimensionnement
  Other: 16, // Autre
  Contract: 17, // Contrat
  Agreement: 18, // Convention
  CofracReport: 19, // Rapport de cofrac
  CofracSummary: 20, // Synthèse de cofrac
  AfterWorkPhoto: 21, // Photo après chantier
  SignedQuote: 22, // Devis signé
  InsulationPhoto: 23, // Photo du calorifugeage
  BalancingValvePhoto: 24, // Photo de la vanne d'équilibrage
  SubcontractorDoc: 25, // Document de sous-traitant
  RGE: 26, // RGE
  AuditSummary: 27, // Synthèse de l'audit
  AuditReport: 28, // Rapport d'audit
  CofracAuditReport: 29, // Rapport Cofrac audit
  ExternalAgreement: 30, // Convention externe
  Annexes: 31, // Annexes
  PartnershipContract: 32, // Contrat de partenariat
  PhotoHorodatee: 33, // Photo horodatée
  Exemption: 34, // Dérogation
  SignedAH: 100,
  KBIS: 101, // KBIS
  ManagerID: 102, // Justificatif d'identité du gérant (pièce d'identité, passeport)
  RCPro: 103, // RC Pro
  SocialRegularityCertificate: 104, // Attestation de régularité sociale
  DecennialInsurance: 105, // Assurance décennale
  MandateContract: 1701, // Contrat de mandant
  Pouvoir: 106, // Pouvoir
  HeatSupply: 107, // Fourniture de chaleur
  SimplifiedAnalysisGrid: 108, // Grille d'analyse simplifiée
  WorkAttestationQuote: 109, // ATTESTATION_TRAVAUX_DEVIS
  WorkAttestationInvoice: 110, // ATTESTATION_TRAVAUX_FACTURE
  SignatureProxy: 111, // Procuration de signature
  OnlineAidConstitution: 112, // Constitution d'aide en ligne
  FundsProcurementProxy: 113, // Procuration pour la perception des fonds
  CEEAttestation: 114, // Attestation CEE
  GrantApproval: 115, // Accord de la subvention
  HousingAnalysisGrid: 116, // Grille d'analyse du logement
  BankDetails: 117, // RIB
  PurchaseProof: 118, // Preuve d'achat
  CustomField: 9999, // Champ personnalisé
};

export const InputTypes = {
  TEXT: 1,
  NUMBER: 2,
  BOOL: 3,
  DATE: 4,
  SELECT: 5,
  MULTIPLE_SELECT: 6,
  EMAIL: 7,
  ADDRESS: 8,
  PHONE: 9,
  IMAGE: 10,
  MULTIPLE_IMAGE: 11,
  DOCUMENT: 12,
  MULTIPLE_DOCUMENT: 13,
  ARRAY: 14,
  MULTIPLE_ARRAYS: 15,
  RADIO_CIVILITY: 96,
  CHECK_TOGGLE: 97,
  CHECK_BOOL: 98,
  CHOICE: 99,
} as const;

export const InputTypeReverseLookup: {
  [key: number]: keyof typeof InputTypes;
} = {
  1: 'TEXT',
  2: 'NUMBER',
  3: 'BOOL',
  4: 'DATE',
  5: 'SELECT',
  6: 'MULTIPLE_SELECT',
  7: 'EMAIL',
  8: 'ADDRESS',
  9: 'PHONE',
  10: 'IMAGE',
  11: 'MULTIPLE_IMAGE',
  12: 'DOCUMENT',
  13: 'MULTIPLE_DOCUMENT',
  99: 'CHOICE',
} as const;

export const USER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  PENDING: 3,
};

export const placeholderExample = (t: TFunction) => {
  return {
    FIRSTNAME: t('placeholders.firstname'),
    LASTNAME: t('placeholders.lastname'),
    ADDRESS: t('placeholders.address'),
    TELEPHONE: t('placeholders.telephone'),
    LANDLINE_TELEPHONE: t('placeholders.landline_telephone'),
    EMAIL: t('placeholders.email'),
    SIRET: t('placeholders.siret'),
    ZIP_CODE: t('placeholders.zip'),
    CITY: t('placeholders.city'),
    FUNCTION: t('placeholders.function'),
    SELECT: t('placeholders.select'),
    IMPOSITION: t('placeholders.imposition'),
    REFERENCE_IMPOSITION: t('placeholders.reference_imposition'),
    PARCEL_SECTION: 'AX',
    PLOT_SECTION_NUMBER: t('placeholders.plot_section_number'),
    PREFIX: t('placeholders.prefix_number'),
    SIRET_NUMBER: t('placeholders.siret'),
    PASSWORD: '••••••••',
    DATE: '26/10/2026',
    SURFACE2: '100m²',
    REFERENCE: 'CV-006',
    NUMBER: '5000',
    PERCENTAGE: '100%',
    INSULATING_MATERIAL: t('placeholders.insulating_material'),
    INSULATING: t('placeholders.insulating'),
    CONTRACT: t('placeholders.contract'),
    INDICATIVE_TEXT: t('placeholders.indicative_text'),
    DEFAULT_VALUE_CUSTOM_FIELD: t('placeholders.default_value_custom_field'),
    COMPANY_NAME: t('placeholders.company_name'),
    PRICE: '6',
    VOLUME_CEE: '5',
    CLASSIC_SALE_PRICE: '0.50',
    CLASSIC_PENALTY_AMOUNT: '0.10',
    INTERNAL_REPOSITORY_REFERENCE: 'DEP-006',
    INTERNAL_REFERENCE: 'COF-006',
  };
};

export const sheets = {
  INFORMATIONS: 'informations',
  DOCUMENTS: 'documents',
  BUSINESS: 'business',
  DEPOSITS: 'deposits',
  SUBCONTRACTORS: 'subcontractors',
  CERTTIFICATIONS: 'certifications',
  CONVENTIONS: 'conventions',
  CONTRACTS: 'contracts',
};

// from relationTypeEnum of back
export const relationTypeEnum = {
  BUSINESS_PROVIDER: 'business_provider',
  CONTROL_OFFICE: 'control_office',
  SUBCONTRACTOR: 'subcontractor',
  CONVENTION: 'convention',
  CONTRACT: 'contract',
  ENTITY: 'entity',
  WORKSITE: 'worksite',
  FISCAL_DECLARATION: 'fiscal_declaration',
  WORKSITE_OPERATION: 'worksite_operation',
  COFRAC: 'cofrac',
  DEPOSIT: 'deposit',
  NOTE: 'note',
  CERTIFICATION: 'certifications',
  MAR_CONTRACT: 'mar_contract',
  MAR_AUDIT: 'mar_audit',
  MAR_WORKSITE: 'mar_worksite',
  MAR_SCENARIO: 'mar_scenario',
  MAR_DEVIS: 'mar_devis',
  MAR_FACTURE: 'mar_facture',
  USER: 'user',
  ENTITY_TYPES: 'entity_types',
  DEFAULT: 'other',
};

export const partner_type = {
  BUSINESS_PROVIDER: 'business_provider',
  CONTROL_OFFICE: 'control_office',
  SUBCONTRACTOR: 'subcontractors',
  PAYMENT_DELEGATE: 'payment_delegation',
  DEFAULT: 'default',
};

export const BUSINESS_PROVIDERS = {
  PROVIDER_1: 'business_provider_1',
  PROVIDER_2: 'business_provider_2',
} as const;

export type BusinessProviderKeys = keyof typeof BUSINESS_PROVIDERS; // "PROVIDER_1" | "PROVIDER_2"
export type BusinessProviderValues =
  (typeof BUSINESS_PROVIDERS)[BusinessProviderKeys]; // "business_provider_1" | "business_provider_2"

export const PartnerInfoSection = {
  Entity: 'entity',
  Contact: 'contact',
};

export const openInNewWindow = (url: string) => {
  const width = 1024;
  const height = 768;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;

  window.open(
    url,
    '_blank',
    `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no,location=no,status=no`
  );
};
