/* eslint-disable react/jsx-no-useless-fragment */
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { GlobalContext } from '@context/globalContext';

import { ListDocumentsCard } from '@components/documents/ListDocumentsCard';
import { DocumentViewer } from '@components/documents/DocumentViewer';
import { LoaderListDocumentCard } from '@components/loaders/document/LoaderListDocumentCard';
import { UploadButton } from '@components/documents/UploadButton';
import { Card } from '@components/Card';

import { ILinkedFile } from 'types/globalTypes';
import { sendLinkedFile } from '@apiRequests/globalRequests';
import { fileTypeEnum } from '@utils/enums';
import { getPartnersDocumentsLinkedFiles } from '@models/partners/apiRequests/partnersRequest';
import { ENTITY_TYPES } from '@utils/roles';
import { getRelationType } from '@utils/functions';

interface ITabPartnersDocumentsProps {
  entityType: number;
}

interface FormValues {
  title: string;
  relationType: string;
}

function TabPartnersDocuments({ entityType }: ITabPartnersDocumentsProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { userView, updateDocumentActive } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);
  const [linkedFiles, setLinkedFiles] = useState<ILinkedFile[]>([]);

  const computeTitle = () => {
    switch (entityType) {
      case ENTITY_TYPES.APPORTEUR_AFFAIRES:
        return t('partners.business_provider.documents.title');
      case ENTITY_TYPES.BUREAUX_CONTROLE:
        return t('partners.control_office.documents.title');
      default:
        return t('global.documents');
    }
  };

  const { watch, setValue } = useForm<FormValues>({
    defaultValues: {
      title: computeTitle(),
      relationType: getRelationType(entityType),
    },
  });

  const title = watch('title');
  const relationTypeComputed = watch('relationType');

  const getDocumentsData = async () => {
    setIsLoading(true);
    const payload = {
      relation_id: Number(id),
      file_type: fileTypeEnum.AUTRE,
      relation_type: relationTypeComputed,
    };
    const resDocs = await getPartnersDocumentsLinkedFiles(payload);

    if (resDocs) {
      setLinkedFiles(resDocs.linkedFiles);
    }

    setIsLoading(false);
  };

  const updateParentDocuments = async () => {
    getDocumentsData();
  };

  const handleUpload = async (file: File) => {
    await sendLinkedFile(
      file,
      [Number(id)],
      relationTypeComputed,
      fileTypeEnum.AUTRE,
      2,
      '',
      userView?.entity_id || 0,
      false,
      undefined,
      undefined,
      updateParentDocuments,
      undefined,
      true
    );
  };

  useEffect(() => {
    getDocumentsData();
  }, [id]);

  useEffect(() => {
    setValue('title', computeTitle());
  }, [entityType]);

  useEffect(() => {
    if (linkedFiles.length > 0) {
      setIsLoadingDocument(true);
      updateDocumentActive({
        list: title,
        document: linkedFiles[0],
      });
      setIsLoadingDocument(false);
    }
  }, []);

  return (
    <div className="flex space-x-[1.5rem] py-[0.5rem]">
      <Card
        addClass="w-1/2"
        title={title}
        actionButtons={
          <UploadButton
            name={t('global.document_add')}
            onUpload={handleUpload}
            fileType={fileTypeEnum.AUTRE}
          />
        }
        dataTestId="documents_card"
      >
        <>
          {isLoading ? (
            <LoaderListDocumentCard />
          ) : (
            linkedFiles?.length > 0 && (
              <ListDocumentsCard
                key={v4()}
                documents={linkedFiles}
                updateParentDocuments={updateParentDocuments}
                setIsLoading={setIsLoading}
              />
            )
          )}
        </>
      </Card>

      <div className="w-1/2">
        {isLoading ? (
          <LoaderListDocumentCard />
        ) : (
          <DocumentViewer
            isLoading={isLoadingDocument}
            setIsLoading={setIsLoadingDocument}
          />
        )}
      </div>
    </div>
  );
}

export { TabPartnersDocuments };
