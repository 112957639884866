/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { backgroundGrey, blueOpx } from '@assets/color';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { SearchIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { Loader } from '@components/atomic/Loader';

interface ISearchBarProps {
  placeholder: string;
  onSearch: (value: string) => void;
  onClick?: () => void;
  width?: string;
  searchOnEveryChange?: boolean;
  addClass?: string;
  dataTestId?: string;
  setFocus?: Dispatch<SetStateAction<boolean>>;
  setHover?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
  backgroundColor?: string;
}

function SearchBar({
  placeholder,
  onSearch,
  width,
  searchOnEveryChange,
  addClass,
  dataTestId,
  onClick,
  setFocus,
  setHover,
  isLoading,
  backgroundColor,
}: ISearchBarProps) {
  const icon = <SearchIcon width="1rem" height="1rem" fill={blueOpx} />;

  const [searchValue, setSearchValue] = useState('');
  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (inputValue: string) => {
    setSearchValue(inputValue);
    if (searchOnEveryChange) {
      if (timeOut) {
        clearTimeout(timeOut);
        setTimeOut(null);
      }
      const timeout = setTimeout(() => {
        onSearch(inputValue);
      }, 350);
      setTimeOut(timeout);
    }
  };

  const onClickOnSearchIcon = () => {
    if (searchValue === '') {
      inputRef.current?.focus();
    } else if (!searchOnEveryChange) {
      onSearch(searchValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !searchOnEveryChange) {
      onSearch(searchValue);
    }
  };

  return (
    <div
      className={[
        'rounded-default space-x-[.5rem] flex items-center p-[.5rem]',
        addClass,
      ].join(' ')}
      style={{
        backgroundColor:
          backgroundColor === '' ? backgroundGrey : backgroundColor,
        width,
        transition: '0.4s',
      }}
      data-test-id={dataTestId}
      onClick={onClick}
    >
      <ColorCube
        color={blueOpx}
        numberOrIcon={icon}
        onClick={onClickOnSearchIcon}
        opacity
        size="1.5rem"
      />
      <input
        ref={inputRef}
        type="text"
        value={searchValue}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        className="w-full bg-transparent text-ellipsis text-[.75rem] pr-[.5rem] font-400 focus:outline-0"
        onMouseOver={() => setHover && setHover(true)}
        onFocus={() => setFocus && setFocus(true)}
        data-test-id={dataTestId || 'search_bar_input'}
      />
      {isLoading && (
        <div className="absolute right-[2rem]">
          <Loader />
        </div>
      )}
    </div>
  );
}

export { SearchBar };

SearchBar.defaultProps = {
  width: '16.125rem',
  searchOnEveryChange: true,
  addClass: '',
  dataTestId: '',
  onClick: undefined,
  setFocus: undefined,
  setHover: undefined,
  isLoading: false,
  backgroundColor: '',
};
