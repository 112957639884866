import { AddFatIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getControlContactSettingsFields,
  updateControlContactSettingsFields,
} from '@models/controlContact/apiRequests/controlContactRequests';
import { IControlContactSettingsField } from '@models/controlContact/utils/controlContactTypes';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import {
  endFieldsSettings,
  startFieldsSettings,
} from '@models/controlContact/utils/utlis';
import OneFieldInList from './OneFieldInList';
import ModalNewControlContactField from './ModalNewControlContactField';

/**
 * Composant pour gérer les paramètres de contrôle de contact.
 * La liste des champs est séparée en deux parties :
 * - Les champs en lecture seule (non draggable)
 * - Les champs modifiables (avec drag & drop pour le réordonnancement)
 */
function ContactControlSettings() {
  const { t } = useTranslation();
  const [showNewContactControlModal, setShowNewContactControlModal] =
    useState(false);
  const [modifiableFields, setModifiableFields] = useState<
    IControlContactSettingsField[]
  >([]);
  const [readonlyFields, setReadonlyFields] = useState<
    IControlContactSettingsField[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fieldIdToEdit, setFieldIdToEdit] = useState<number | null>(null);

  /**
   * Récupère les champs depuis l'API, les trie et les sépare en champs modifiables et en lecture seule.
   */
  const getFields = async () => {
    setIsLoading(true);
    const response = await getControlContactSettingsFields();
    if (response) {
      // Trie les champs par position avant de les séparer
      const sortedFields = response.sort(
        (a: IControlContactSettingsField, b: IControlContactSettingsField) =>
          a.position - b.position
      );
      setModifiableFields(
        sortedFields.filter(
          (field: IControlContactSettingsField) => !field.readonly
        )
      );
      setReadonlyFields(
        sortedFields.filter(
          (field: IControlContactSettingsField) => field.readonly
        )
      );
    }
    setIsLoading(false);
  };

  /**
   * Réordonne la liste des champs lorsqu'un élément est déplacé (pour les champs modifiables).
   * @param list - La liste initiale de champs.
   * @param startIndex - L'index de départ de l'élément déplacé.
   * @param endIndex - L'index d'arrivée de l'élément déplacé.
   * @returns Une nouvelle liste réordonnée avec des positions mises à jour.
   */
  const reorderFields = (
    list: IControlContactSettingsField[],
    startIndex: number,
    endIndex: number
  ): IControlContactSettingsField[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // Met à jour les positions (indexés à partir de 0)
    return result.map((field, index) => ({ ...field, position: index }));
  };

  /**
   * Gestionnaire d'événement appelé à la fin du drag pour les champs modifiables.
   * Met à jour l'ordre des champs et envoie la nouvelle configuration au backend.
   * @param result - Le résultat du drag and drop.
   */
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorderFields(
      modifiableFields,
      result.source.index,
      result.destination.index
    );
    setModifiableFields(newOrder);
    await updateControlContactSettingsFields(newOrder);
  };

  useEffect(() => {
    if (!showNewContactControlModal) {
      setFieldIdToEdit(null);
    }
  }, [showNewContactControlModal]);

  useEffect(() => {
    if (fieldIdToEdit) {
      setShowNewContactControlModal(true);
    }
  }, [fieldIdToEdit]);

  useEffect(() => {
    getFields();
  }, []);

  return (
    <>
      <Card
        title={t('control_contact.settings.title_card')}
        subtitle={t('control_contact.settings.subtitle_card') as string}
        subtitleClass="!text-[.75rem] text-textGrey w-3/4"
        actionButtons={
          <ButtonOpx
            label={t('control_contact.settings.new_field')}
            icon={<AddFatIcon />}
            onClick={() => setShowNewContactControlModal(true)}
            small
          />
        }
        addClass="w-full"
      >
        {isLoading ? (
          <div className="space-y-4">
            <LoaderDivSkeleton height="3.5rem" width="100%" />
            <LoaderDivSkeleton height="3.5rem" width="100%" />
            <LoaderDivSkeleton height="3.5rem" width="100%" />
          </div>
        ) : (
          <div className="space-y-4">
            {/* Affichage de la section des champs en lecture seule */}
            {startFieldsSettings(t).map((field) => (
              <OneFieldInList key={field.label} field={field} />
            ))}
            {readonlyFields.map((field) => (
              <OneFieldInList key={field.id} field={field} />
            ))}

            {/* Affichage de la section des champs modifiables */}
            {modifiableFields.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="modifiableFields">
                  {(provided) => (
                    <div
                      className="space-y-4"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {modifiableFields.map((field, index) => (
                        <Draggable
                          key={field.id}
                          draggableId={field.id.toString()}
                          index={index}
                        >
                          {(dragProvided) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                            >
                              <OneFieldInList
                                field={field}
                                dragHandleProps={dragProvided.dragHandleProps}
                                setFieldIdToEdit={setFieldIdToEdit}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            {endFieldsSettings(t).map((field) => (
              <OneFieldInList key={field.id} field={field} />
            ))}
          </div>
        )}
      </Card>
      {showNewContactControlModal && (
        <ModalNewControlContactField
          getFields={getFields}
          setShowModal={setShowNewContactControlModal}
          fieldIdToEdit={fieldIdToEdit}
        />
      )}
    </>
  );
}

export default ContactControlSettings;
