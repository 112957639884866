/* eslint-disable no-shadow */
import { GlobalContext } from '@context/globalContext';
import { getOneBeneficiary } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { StepChoiceBeneficiaryType } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/StepChoiceBeneficiaryType';
import { StepChoiceIncentiveType } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/StepChoiceIncentiveType';
import { StepChoiceInstaller } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/StepChoiceInstaller';
import { StepChoiceOperationType } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/StepChoiceOperationType';
import { StepsEnumBeforeSimulation } from '@models/worksiteCreation/utils/enums';
import { useContext, useEffect, useState } from 'react';
import { WorksiteCreationContext } from '../../utils/worksiteCreationContext';
import { StepBindBeneficiary } from './steps/StepBindBeneficiary';
import { StepChoiceContract } from './steps/StepChoiceContract';
import { StepCreateBeneficiary } from './steps/stepCreateBeneficiary/StepCreateBeneficiary';

function FormBeforeSimulation() {
  const { route } = useContext(GlobalContext);
  const { updateBeneficiary, isIframeMode, iframeData } = useContext(
    WorksiteCreationContext
  );

  // STEPS ET AFFICHAGE DES STEPS
  const [stepFormBeforeSimulation, setStepFormBeforeSimulation] =
    useState<StepsEnumBeforeSimulation>(
      StepsEnumBeforeSimulation.CHOICE_BENEFICIARY_TYPE
    );

  const [renderStepComponent, setRenderStepComponent] = useState<JSX.Element>(
    <StepChoiceOperationType
      setStepFormBeforeSimulation={setStepFormBeforeSimulation}
    />
  );

  // RECUPERE LE BENEFICIAIRE SI ON VIENT DE LA PAGE BENEFICIAIRE
  useEffect(() => {
    if (route && Array.isArray(route.older) && route.older.length > 0) {
      const lastRoute = route.older[route.older.length - 1];
      if (lastRoute.includes('beneficiaries/')) {
        setStepFormBeforeSimulation(StepsEnumBeforeSimulation.CHOICE_CONTRACT);
        const idBeneficiary = Number(lastRoute.match(/\d+/)?.[0]);
        getOneBeneficiary(idBeneficiary, updateBeneficiary);
      }
    }
  }, [route]);

  // MODIFIE L'AFFICHAGE SELON LA STEP EN COURS
  useEffect(() => {
    // En mode iframe, on ne montre que l'étape de choix du type de bénéficiaire si beneficiary_type = 3
    if (isIframeMode && iframeData) {
      if (iframeData.beneficiary_type === 3) {
        setRenderStepComponent(
          <StepChoiceBeneficiaryType
            setStepFormBeforeSimulation={setStepFormBeforeSimulation}
          />
        );
        return;
      }
    }

    // Comportement standard hors mode iframe
    switch (stepFormBeforeSimulation) {
      case StepsEnumBeforeSimulation.CHOICE_OPERATION_TYPE:
        setRenderStepComponent(
          <StepChoiceOperationType
            setStepFormBeforeSimulation={setStepFormBeforeSimulation}
          />
        );
        break;
      case StepsEnumBeforeSimulation.CHOICE_CONTRACT:
        setRenderStepComponent(
          <StepChoiceContract
            setStepFormBeforeSimulation={setStepFormBeforeSimulation}
          />
        );
        break;
      case StepsEnumBeforeSimulation.CHOICE_INCENTIVE_TYPE:
        setRenderStepComponent(
          <StepChoiceIncentiveType
            setStepFormBeforeSimulation={setStepFormBeforeSimulation}
          />
        );
        break;
      case StepsEnumBeforeSimulation.CHOICE_BENEFICIARY_TYPE:
        setRenderStepComponent(
          <StepChoiceBeneficiaryType
            setStepFormBeforeSimulation={setStepFormBeforeSimulation}
          />
        );
        break;
      case StepsEnumBeforeSimulation.BIND_BENEFICIARY:
        setRenderStepComponent(
          <StepBindBeneficiary
            setStepFormBeforeSimulation={setStepFormBeforeSimulation}
          />
        );
        break;
      case StepsEnumBeforeSimulation.CREATE_BENEFICIARY:
        setRenderStepComponent(
          <StepCreateBeneficiary
            setStepFormBeforeSimulation={setStepFormBeforeSimulation}
          />
        );
        break;
      case StepsEnumBeforeSimulation.CHOICE_INSTALLER:
        setRenderStepComponent(
          <StepChoiceInstaller
            setStepFormBeforeSimulation={setStepFormBeforeSimulation}
          />
        );
        break;
      default:
    }
  }, [stepFormBeforeSimulation, isIframeMode]);

  return renderStepComponent;
}

export { FormBeforeSimulation };
