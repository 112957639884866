import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { cancelWorksiteCreation } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';

function CancelWorksite() {
  const {
    updateIsModals,
    simulatorData,
    worksiteOperationType,
    worksiteDatas,
    updateWorksiteDatas,
  } = useContext(WorksiteCreationContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const worksiteId = worksiteDatas.id > 0 ? worksiteDatas.id : undefined;

  const closeModals = () => {
    updateIsModals({
      formBeforeSimulation: false,
      bindBeneficiary: false,
      createBeneficiary: false,
      saveAndQuit: false,
      deleteWorksite: false,
      cancelWorksite: false,
    });
  };

  const onClickConfirm = async () => {
    setIsLoading(true);
    const response = await cancelWorksiteCreation(
      simulatorData,
      worksiteOperationType,
      updateWorksiteDatas,
      worksiteId
    );

    if (response.data) closeModals();

    setIsLoading(false);
  };

  return (
    <Modal
      title={`${t('worksite_creation.titles.cancel_simulation')}`}
      backgroundTransparent
      onConfirmClick={onClickConfirm}
      textBtnConfirm={`${t('buttons.confirm')}`}
      btnCancel
      isLoading={isLoading}
      onClickCancel={closeModals}
    >
      <div className="mt-3">
        {t('worksite_creation.subtitles.cancel_simulation')}
      </div>
    </Modal>
  );
}

export { CancelWorksite };
