import { green, red, greyOpacity20 } from '@assets/color';
import {
  CheckIcon,
  CrossIcon,
  EyeIcon,
  EyeOffIcon,
} from '@assets/images/svgComponents';
import {
  cloneElement,
  ChangeEvent,
  InputHTMLAttributes,
  useState,
  useEffect,
  KeyboardEvent,
  useMemo,
} from 'react';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { convertHexToRGBA } from '@utils/functions';
import { ColorCube } from '@components/atomic/ColorCube';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';
import { Loader } from '@components/atomic/Loader';
import {
  // getLinkedUsersList,
  getMentionsList,
} from '@models/users/apiRequests/userRequests';
import { IAccountUser } from '@models/users/utils/userTypes';
import ImageContainer from '@components/atomic/ImageContainer';
import {
  getNumberRegexToUse,
  isValueNumberValid,
  isWithinRange,
  processInputValue,
} from '@utils/numberValidation';
import { IRule } from '../../../types/globalTypes';

/**
 * Props pour le composant InputText.
 */
interface IInputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  placeholder: string;
  required?: boolean;
  errorsHookForm?: FieldErrors;
  error?: boolean | string;
  valid?: boolean;
  disabled?: boolean;
  typeNumber?: boolean;
  allowDecimal?: boolean;
  allowNegative?: boolean;
  allowZero?: boolean;
  label?: string | null;
  addClass?: string;
  onChange?: (value: string | ChangeEvent<HTMLInputElement>) => void;
  rules?: IRule;
  icon?: JSX.Element | string;
  textError?: string;
  onBlurInput?: (e: ChangeEvent<HTMLElement>, index?: number) => void;
  valueInitialInput?: string;
  type?: string;
  dataTestId?: string;
  addClassToInput?: string;
  defaultValue?: string;
  maxLength?: number;
  maxNumber?: number;
  minNumber?: number;
  loading?: boolean;
  spinnerLoader?: boolean;
  hideLabel?: boolean;
  userMention?: boolean;
  setUsersMentioned?: (users: IAccountUser[]) => void;
  resetSignal?: number;
  hideIsOptional?: boolean;
}

/**
 * Composant InputText.
 *
 * @param props - Les propriétés du composant.
 * @returns Un élément JSX représentant l'input textuel.
 */
function InputText({
  id,
  name,
  placeholder,
  required,
  label,
  errorsHookForm,
  error,
  valid,
  disabled,
  typeNumber,
  allowDecimal,
  allowNegative,
  allowZero,
  onChange,
  addClass,
  rules,
  icon,
  onBlurInput,
  textError,
  valueInitialInput,
  type,
  dataTestId,
  addClassToInput,
  defaultValue,
  maxLength,
  maxNumber,
  minNumber,
  loading,
  spinnerLoader,
  hideLabel,
  userMention,
  setUsersMentioned,
  resetSignal,
  hideIsOptional,
  ...otherProps
}: IInputTextProps): JSX.Element {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const {
    register,
    getValues,
    formState: { errors, /* touchedFields, */ isSubmitted },
    setValue,
  } = formContext;

  const initialValue =
    getValues(name) && getValues(name) !== ''
      ? getValues(name)
      : valueInitialInput ?? '';

  const [valueInputText, setValueInputText] = useState(initialValue);
  // État pour gérer la visibilité du mot de passe
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [isUserMentionTriggered, setIsUserMentionTriggered] = useState(false);
  const [userSuggestions, setUserSuggestions] = useState<IAccountUser[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingFetchUsers, setLoadingFetchUsers] = useState(false);
  const [mentions, setMentions] = useState<
    {
      id: number;
      start: number;
      end: number;
    }[]
  >([]);

  const rulesToUse = useMemo(() => {
    if (required) {
      return { ...rules, required: t('forms.required_error_message') };
    }
    return rules;
  }, [rules, required]);

  const errorsToUse = errors || errorsHookForm;

  const validToUse = isSubmitted && valid; /* ||
      (touchedFields[name] && rules?.pattern.value.test(valueInputText)) ||
      (!errorsToUse[name] && touchedFields[name] && valueInputText !== '')) */

  const labelToUse = useMemo(() => {
    if (label === '') {
      return placeholder;
    }
    return label;
  }, [label, placeholder]);

  const typeToUse = useMemo(() => {
    if (!type) {
      return 'text';
    }
    if (type === 'password') {
      return passwordVisible ? 'text' : 'password';
    }
    return type;
  }, [type, passwordVisible]);

  const errorActive = errorsToUse && errorsToUse[name] ? true : error;

  // Basculez la visibilité du mot de passe
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChangeWithUserMention = (valueInput: string) => {
    // Vérifie si la modification se produit à l'intérieur d'une mention
    let newValue = valueInput;
    const newMentions = [...mentions];
    mentions.forEach((mention, index) => {
      if (valueInputText.length > valueInput.length) {
        // Suppression détectée
        if (
          valueInput.length < mention.end &&
          valueInput.length >= mention.start
        ) {
          // La suppression affecte cette mention
          newValue =
            valueInputText.substring(0, mention.start) +
            valueInputText.substring(mention.end);
          newMentions.splice(index, 1); // Supprime cette mention de l'état
        }
      }
    });

    if (valueInput.trim() !== '') {
      setValueInputText(newValue);
    } else {
      setValueInputText('');
    }
    setValue(name, newValue);
    if (mentions.length !== newMentions.length) {
      setMentions(newMentions);
    }

    const indexOfAt = newValue.lastIndexOf('@');
    if (indexOfAt === newValue.length - 1 && newValue !== '') {
      setIsUserMentionTriggered(true);
      setSearchTerm(newValue.substring(indexOfAt + 1));
      if (!showSuggestions) setShowSuggestions(true);
    } else {
      setIsUserMentionTriggered(false);
      setShowSuggestions(false);
    }
  };

  const numberRegexToUse = getNumberRegexToUse(
    allowDecimal ?? false,
    allowNegative ?? false
  );

  const onChangeInput = (valueInput: string) => {
    // Traitement initial de la valeur saisie en fonction du type et des options
    const processedValue = processInputValue(
      valueInput,
      typeNumber ?? false, // Indique si le champ attend un nombre
      allowDecimal ?? false, // Indique si les décimales sont autorisées
      name // Nom du champ pour des traitements spécifiques
    );

    // Si l'option de mention d'utilisateur est activée, on gère la saisie différemment
    if (userMention) {
      handleChangeWithUserMention(processedValue);
    }

    // Si la valeur traitée n'est pas vide après suppression des espaces
    if (processedValue.trim() !== '') {
      // Si le champ est de type numérique
      if (typeNumber) {
        // Vérifie si la valeur est un nombre valide selon les expressions régulières définies
        if (
          isValueNumberValid(processedValue, numberRegexToUse, name, allowZero)
        ) {
          // Vérifie si la valeur est comprise entre minNumber et maxNumber si ces limites sont définies
          if (isWithinRange(processedValue, minNumber, maxNumber)) {
            // Si une fonction onChange est fournie, on lui passe la valeur traitée
            if (onChange) {
              onChange(processedValue);
            }
            // Si l'option userMention n'est pas activée, on met à jour le texte affiché dans le champ
            if (!userMention) {
              setValueInputText(processedValue);
            }
          } else if (onChange) {
            // Si la valeur dépasse les limites autorisées
            // On retire le dernier caractère de la valeur pour revenir à une valeur valide
            setValue(name, processedValue.slice(0, -1));
            setValueInputText(processedValue.slice(0, -1));
          }
        }
      } else {
        // Si le champ n'est pas de type numérique
        // Si une fonction onChange est fournie, on lui passe la valeur traitée
        if (onChange) {
          onChange(processedValue);
        }
        // Si l'option userMention n'est pas activée, on met à jour le texte affiché dans le champ
        if (!userMention) {
          setValueInputText(processedValue);
        }
      }
    } else {
      // Si la valeur traitée est vide (après suppression des espaces)
      // Si une fonction onChange est fournie, on lui passe une chaîne vide
      if (onChange) {
        onChange('');
      }
      // Si l'option userMention n'est pas activée, on efface le texte affiché dans le champ
      if (!userMention) {
        setValueInputText('');
      }
    }
  };

  const borderStyle = useMemo(() => {
    if (errorActive) {
      return 'border-red';
    }
    if (validToUse && !disabled) {
      return 'border-green';
    }
    return 'border-borderGrey';
  }, [errorActive, validToUse, addClass]);

  const iconWithProps = icon
    ? cloneElement(icon as JSX.Element, {
        width: '1rem',
        height: '1rem',
      })
    : null;

  const handleBlur = (e: ChangeEvent<HTMLElement>) => {
    if (onBlurInput) onBlurInput(e);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      (typeNumber &&
        !numberRegexToUse.test(e.key) &&
        e.key !== ',' &&
        e.key !== '.') ||
      (maxLength && e.currentTarget.value.length >= maxLength)
    ) {
      e.preventDefault();
    }
  };

  // Rendre l'icône d'œil pour les champs de type 'password'
  const renderIcon = () => {
    if (type === 'password') {
      return (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="cursor-pointer"
        >
          {passwordVisible ? (
            <EyeIcon width="1.25rem" height="1.25rem" />
          ) : (
            <EyeOffIcon width="1.25rem" height="1.25rem" />
          )}
        </button>
      );
    }
    return icon
      ? iconWithProps
      : (validToUse || errorActive) && !disabled && (
          <ColorCube
            size="1.5rem"
            numberOrIcon={errorActive ? <CrossIcon /> : <CheckIcon />}
            color={errorActive ? red : green}
            backgroundColor={
              errorActive
                ? convertHexToRGBA(red, 0.1)
                : convertHexToRGBA(green, 0.1)
            }
            opacity
          />
        );
  };

  // Filtre les suggestions basées sur la saisie de l'utilisateur après '@'
  const filteredSuggestions = userSuggestions.filter((user) =>
    `${user.email}${user.firstname}${user.lastname}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const selectUser = (user: IAccountUser) => {
    const mentionText =
      (user.firstname && user.lastname
        ? `${user.firstname} ${user.lastname}`
        : user.email) || '';
    const valueBeforeAt = valueInputText.substring(
      0,
      valueInputText.lastIndexOf('@') + 1
    );
    const start = valueBeforeAt.length;
    const end = start + mentionText.length;
    if (
      !Object.values(mentions)
        .map((mention) => mention.id)
        .includes(user.id)
    ) {
      setMentions([...mentions, { id: user.id, start, end }]);
    }
    // Tu pourrais choisir d'insérer l'email, le nom complet, ou les deux
    setValue(name, `${valueBeforeAt}${mentionText}`);
    setShowSuggestions(false);
  };

  const formatValueInputText = (value: string) => {
    if (typeNumber) {
      return value.toString().replace('.', ',');
    }
    return value;
  };

  useEffect(() => {
    if (setUsersMentioned) {
      setUsersMentioned(
        userSuggestions.filter((user) =>
          Object.values(mentions)
            .map((mention) => mention.id)
            .includes(user.id)
        )
      );
    }
  }, [mentions]);

  useEffect(() => {
    if (defaultValue) {
      setValueInputText(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (getValues(name) && getValues(name) !== valueInputText) {
      if (name !== 'siret') {
        setValueInputText(initialValue);
      } else {
        setValue(name, valueInputText);
      }
    }
    if (
      (getValues(name) === undefined || getValues(name) === null) &&
      valueInputText
    ) {
      setValue(name, valueInputText);
    }
  }, [getValues(name)]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoadingFetchUsers(true);
      const response = await getMentionsList();
      setUserSuggestions(response.data);
      setLoadingFetchUsers(false);
    };
    if (isUserMentionTriggered && userSuggestions.length === 0) {
      fetchUsers();
    }
  }, [isUserMentionTriggered]);

  useEffect(() => {
    if (resetSignal && resetSignal > 0) {
      setValueInputText('');
      setValue(name, '');
      setMentions([]);
    }
  }, [resetSignal]);

  // Extraction de 'value' des 'otherProps' pour appliquer le formatage
  const { value, ...restOtherProps } = otherProps;
  const finalValue = value
    ? formatValueInputText(String(value))
    : formatValueInputText(valueInputText);

  return (
    <div className="flex flex-col w-full space-y-[.5rem]">
      <label
        htmlFor={id}
        className={[
          `flex flex-col text-textGrey text-[.625rem] relative`,
          addClass,
        ].join(' ')}
      >
        {!hideLabel && (
          <div className="text-textGrey text-[.75rem] leading-[.75rem] mb-2">
            {labelToUse}{' '}
            <span>{`${
              required || hideIsOptional ? '' : `(${t('global.optional')})`
            }`}</span>
          </div>
        )}
        <div className="relative w-full">
          {type && type === 'textarea' ? (
            <>
              <textarea
                value={valueInputText}
                id={id}
                {...register(name, {
                  onChange: (e) => onChangeInput(e.target.value),
                })}
                placeholder={placeholder}
                disabled={disabled}
                className={` h-[10rem] w-full border text-black text-[.875rem] rounded-default focus:outline-none p-2 ${
                  disabled ? 'bg-gray-100' : ''
                } ${addClassToInput}`}
                data-test-id={dataTestId}
              />
              {loading && spinnerLoader && (
                <div className="absolute right-2 top-[.7rem]">
                  <Loader />
                </div>
              )}
            </>
          ) : (
            <div className="relative w-full">
              <input
                id={id}
                readOnly={disabled}
                type={typeToUse}
                placeholder={placeholder}
                {...register(name, {
                  onChange: (e) => onChangeInput(e.target.value),
                  ...rulesToUse,
                  onBlur: (e) => handleBlur(e),
                })}
                className={`w-full border rounded-default focus:outline-none p-[.7rem] ${addClassToInput} ${
                  icon ? 'pr-8' : ''
                } ${borderStyle} text-[.875rem] text-black ${
                  loading && !spinnerLoader ? 'hidden' : ''
                }`}
                value={finalValue}
                data-test-id={dataTestId}
                onKeyPress={handleKeyPress}
                {...restOtherProps}
                disabled={disabled}
              />
              {loading && spinnerLoader && (
                <div className="absolute right-2 top-[.7rem]">
                  <Loader />
                </div>
              )}
              {showSuggestions && (
                <ul className="absolute z-50 bg-white border border-borderGrey rounded-md bottom-full w-full max-h-50 overflow-y-auto translate-y-[-.375rem] py-4 text-sm">
                  <p className="text-black px-4 mb-2">
                    {t('my_account.tabs.2')}
                  </p>
                  {loadingFetchUsers && <Loader />}
                  {filteredSuggestions.map((user) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                    <li
                      key={user.id}
                      onClick={() => selectUser(user)}
                      className="hover:text-primaryText hover:bg-blueOpxOpacity10 px-4 py-1 flex items-center"
                    >
                      <ImageContainer
                        imageUrl={user?.photo_url}
                        height="1rem"
                        width="1rem"
                        addClass="min-w-[1rem] min-h-[1rem] mr-2"
                      />
                      {user.lastname && user.firstname
                        ? `${user.firstname} ${user.lastname} (${user.email})`
                        : user.email}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center text-black text-[.875rem]">
            {typeof icon === 'string' ? icon : renderIcon()}
          </div>
        </div>

        {loading && !spinnerLoader && (
          <LoaderSkeleton
            height="2.7125rem"
            backgroundColor={greyOpacity20}
            addClass="!rounded-default"
          />
        )}
      </label>
      {errorActive && textError && (
        <div
          className="rounded-default p-[.5rem] text-red text-sm"
          style={{
            backgroundColor: convertHexToRGBA(red, 0.1),
          }}
        >
          {textError}
        </div>
      )}

      <ErrorMessage
        name={name}
        render={({ message }) => (
          <div
            className="rounded-default p-[.5rem] text-red text-sm"
            style={{
              backgroundColor: convertHexToRGBA(red, 0.1),
            }}
          >
            {message}
          </div>
        )}
      />
    </div>
  );
}

export { InputText };

InputText.defaultProps = {
  required: false,
  errorsHookForm: undefined,
  maxLength: undefined,
  maxNumber: undefined,
  minNumber: undefined,
  error: false,
  valid: undefined,
  disabled: false,
  label: '',
  typeNumber: false,
  allowDecimal: true,
  allowNegative: false,
  allowZero: true,
  onChange: undefined,
  addClass: '',
  addClassToInput: '',
  rules: undefined,
  icon: undefined,
  textError: '',
  onBlurInput: undefined,
  valueInitialInput: '',
  type: 'text',
  dataTestId: '',
  loading: false,
  defaultValue: '',
  spinnerLoader: false,
  hideLabel: false,
  userMention: false,
  setUsersMentioned: undefined,
  resetSignal: undefined,
  hideIsOptional: false,
};
