import { axiosClient } from '@api/apiClient';
import {
  urlConventions,
  urlCreateIframe,
  urlDeleteCustomField,
  urlDeleteIframe,
  urlGetCustomFields,
  urlGetIframeAgreements,
  urlGetIframeBySlug,
  urlGetIframeData,
  urlGetIframes,
  urlGetOrPostTheme,
  urlGetThemeBySlug,
  /*   urlGetThemeBySlug, */
  urlGetThemesList,
  urlPostCustomField,
  urlResetTheme,
  urlUpdateCustomField,
  urlUpdateIframe,
} from '@api/apiUrls';
import { displayError } from '@utils/format';
import { Dispatch, SetStateAction } from 'react';
import {
  IElementData,
  IImageData,
  IObjectWhiteLabelToSend,
} from '../utils/settingsTypes';

export const getListCustomFields = async (
  setData: Dispatch<SetStateAction<any>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  const response = await axiosClient.post(urlGetCustomFields);
  setData(response.data);
  setIsLoading(false);
  return response.data;
};

export const addCustomField = async (
  data: any,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(urlPostCustomField, data);
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const updateCustomField = async (
  customFieldId: number,
  data: any,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(
      urlUpdateCustomField(customFieldId),
      data
    );
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const deleteCustomField = async (
  customFieldId: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    await axiosClient.post(urlDeleteCustomField(customFieldId));
    setIsLoading(false);
    return true;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const updateTheme = async (
  entityId: number,
  data: IObjectWhiteLabelToSend
) => {
  try {
    const formData = new FormData();

    data.images.forEach((image: IImageData, index: number) => {
      formData.append(`images[${index}][image]`, image.image);
      if (image.initial) {
        formData.append(`images[${index}][initial]`, image.initial);
      }
      if (image.custom) {
        formData.append(`images[${index}][custom]`, image.custom);
      }
      formData.append(
        `images[${index}][brightness]`,
        image.brightness.toString()
      );
      formData.append(`images[${index}][contrast]`, image.contrast.toString());
      formData.append(
        `images[${index}][saturation]`,
        image.saturation.toString()
      );
      formData.append(`images[${index}][crop]`, JSON.stringify(image.crop));
      if (image.croppedAreaPixels) {
        formData.append(
          `images[${index}][croppedAreaPixels]`,
          JSON.stringify(image.croppedAreaPixels)
        );
      }
    });

    data.elements.forEach((element: IElementData, index: number) => {
      formData.append(`elements[${index}][element]`, element.element);
      formData.append(`elements[${index}][text_color]`, element.text_color);
      formData.append(
        `elements[${index}][background_color]`,
        element.background_color
      );
    });

    formData.append('login_button_text', data.loginButtonText);

    const response = await axiosClient.post(
      urlGetOrPostTheme(entityId),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
    return false;
  }
};

export const getTheme = async (entityId: number) => {
  try {
    const response = await axiosClient.get(urlGetOrPostTheme(entityId));
    return response;
  } catch (error) {
    return false;
  }
};

export const getThemesList = async () => {
  try {
    const response = await axiosClient.get(urlGetThemesList);
    return response;
  } catch (error) {
    return false;
  }
};

export const getThemeBySlug = async (slug: string) => {
  try {
    const response = await axiosClient.get(urlGetThemeBySlug(slug));
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const resetTheme = async (entityId: number) => {
  try {
    const response = await axiosClient.post(urlResetTheme(entityId));
    console.log('resetTheme', response.data);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getIframes = async (
  setData: Dispatch<SetStateAction<any[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.get(urlGetIframes);
    setData(response.data);
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    displayError(error.response);
    return false;
  }
};

// Interface pour les données à envoyer lors de la création d'une iframe
interface ICreateIframeData {
  name: string;
  convention_id: number | null;
}

export const createIframe = async (
  data: ICreateIframeData,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(urlCreateIframe, data);
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    displayError(error.response);
    return false;
  }
};

export const getInstallerConventions = async (
  setData: Dispatch<SetStateAction<any[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  entityId: number
) => {
  setIsLoading(true);
  try {
    const response = await axiosClient.post(urlConventions, {
      partnerId: entityId,
      pagination: {
        page: 1,
        perPage: 100,
      },
    });
    setData(
      response.data.data.data.map((convention: any) => ({
        value: convention.id,
        label:
          `${convention.reference} - ${convention.rai.company_name}` ||
          `Convention #${convention.id}`,
      }))
    );
    setIsLoading(false);
    return response.data.data.data;
  } catch (error) {
    setIsLoading(false);
    displayError(error.response);
    return false;
  }
};

export const getContracts = async () => {
  try {
    const response = await axiosClient.get(urlGetIframeAgreements);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getIframeData = async (iframeId: string) => {
  try {
    const response = await axiosClient.get(urlGetIframeData(iframeId));
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const updateIframe = async (iframeId: string, data: any) => {
  try {
    const formData = new FormData();
    formData.append('email', data.contact_email);
    formData.append('theme[color_primary]', data.primary_color);
    formData.append('theme[color_secondary]', data.secondary_color);
    formData.append('theme[color_primary_text]', data.primary_text_color);
    formData.append('theme[color_secondary_text]', data.secondary_text_color);

    // Traitement des opérations de manière générique
    if (data.operations && data.operations.length > 0) {
      // Pour chaque opération dans le tableau
      const simplifiedOperations = data.operations.map((op: any) => {
        // Créer un objet simplifié sans le sous-objet "operation"
        const simplifiedOp: Record<string, any> = {};

        // Parcourir chaque propriété de l'opération
        Object.keys(op).forEach((key) => {
          if (key === 'id') {
            simplifiedOp.operation_id = op.operation.id;
          }
          // Ignorer l'objet "operation" imbriqué et "label" qui est dérivé
          else if (
            key !== 'operation' &&
            key !== 'label' &&
            key !== 'operation_price_type' &&
            op[key] !== null &&
            op[key] !== undefined
          ) {
            simplifiedOp[key] = op[key];
          }
        });

        return simplifiedOp;
      });

      simplifiedOperations.forEach((op: any, index: number) => {
        Object.keys(op).forEach((key) => {
          formData.append(`operations[${index}][${key}]`, op[key]);
        });
      });
    }

    if (data.coordinates) {
      Object.keys(data.coordinates).forEach((key) => {
        formData.append(`coordinates[${key}]`, data.coordinates[key]);
      });
    }

    if (data.logo_file) {
      formData.append('logo', data.logo_file);
    }

    if (data.beneficiary_type) {
      formData.append('beneficiary_type', String(data.beneficiary_type));
    }

    if (data.prime_type) {
      formData.append('prime_type', String(data.prime_type));
    }

    if (data.contract_model) {
      formData.append('contract_model', String(data.contract_model));
    }

    if (data.convention_id) {
      formData.append('convention_id', String(data.convention_id));
    }

    const response = await axiosClient.post(
      urlUpdateIframe(iframeId),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const deleteIframe = async (iframeId: string) => {
  try {
    const response = await axiosClient.delete(urlDeleteIframe(iframeId));
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getIframeDataBySlug = async (slug: string) => {
  try {
    const response = await axiosClient.get(urlGetIframeBySlug(slug));
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};
