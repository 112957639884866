import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import React, { useMemo } from 'react';

interface ContractCreationContextWrapperProps {
  children: React.ReactNode;
  contextValue: any;
}

/**
 * Wrapper pour le contexte ContractCreation
 * Fournit toutes les valeurs par défaut nécessaires pour que les composants enfants
 * qui utilisent ce contexte fonctionnent correctement
 */
export function ContractCreationContextWrapper({
  children,
  contextValue,
}: ContractCreationContextWrapperProps) {
  // Fonction noop (no operation) typée correctement pour éviter les erreurs de linter
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {};

  // Valeurs par défaut pour les propriétés obligatoires du contexte mais non utilisées par nos composants
  const defaultValues = {
    contract: undefined,
    updateContract: noop,
    editMode: false,
    referenceOpx: '',
    updateReferenceOpx: noop,
    referencePerso: '',
    updateReferencePerso: noop,
    rai: { value: 0, label: '' },
    updateRai: noop,
    relatedContract: null,
    updateRelatedContract: noop,
    updateBeneficiaryType: noop,
    validationDelay: 0,
    updateValidationDelay: noop,
    startDate: '',
    updateStartDate: noop,
    endDate: '',
    updateEndDate: noop,
    activeStep: 0,
    updateActiveStep: noop,
    lastStep: 0,
    volumeMin: { classic: '0', precaire: '0' },
    updateVolumeMin: noop,
    volumeMax: { classic: '0', precaire: '0' },
    updateVolumeMax: noop,
    entityTo: {},
    updateEntityTo: noop,
    referents: {},
    updateReferents: noop,
    amo: {},
    updateAmo: noop,
    paymentConditions: [],
    updatePaymentConditions: noop,
    tunnel: undefined,
    updateTunnel: noop,
    tunnels: [],
    updateTunnels: noop,
    saleProfile: null,
    updateSaleProfile: noop,
    updateContractType: noop,
    penalties: { classic: '0', precaire: '0' },
    updatePenalties: noop,
    deliveryVolume: 0,
    updateDeliveryVolume: noop,
    commission: 0,
    updateCommission: noop,
    listAssociated: { recents: [], all: [] },
    updateListAssociated: noop,
    contactList: [],
    updateContactList: noop,
    salePrices: { classic: '0', precaire: '0' },
    updateSalePrices: noop,
    origination: null,
    updateOrigination: noop,
    intermediaryBusiness: null,
    updateIntermediaryBusiness: noop,
    worksiteAddresses: [],
    updateWorksiteAddresses: noop,
    steps: [],
    changeStep: noop,
    beneficiary: null,
    updateBeneficiary: noop,
    partnerType: 0,
    updatePartnerType: noop,
    payloadData: null,
    updatePayloadData: noop,
    raiMention: '',
    updateRaiMention: noop,
    listObliges: [],
    updateListObliges: noop,
    updateIncentiveType: noop,
    invitationProcess: noop,
    conventionModel: false,
    isElectronicSignature: false,
    updateIsElectronicSignature: noop,
    canManageWorksiteDocuments: false,
    updateCanManageWorksiteDocuments: noop,
    canUseCustomPrices: false,
    updateCanUseCustomPrices: noop,
    paymentEntityId: null,
    updatePaymentEntityId: noop,
    businessProvider: null,
    updateBusinessProvider: noop,
  };

  // Utilisez useMemo pour éviter de recréer l'objet à chaque rendu
  const combinedValue = useMemo(
    () => ({
      ...defaultValues,
      ...contextValue,
    }),
    [contextValue]
  );

  return (
    <ContractCreationContext.Provider value={combinedValue}>
      {children}
    </ContractCreationContext.Provider>
  );
}
