import { Modal } from '@components/Modal';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { InputText } from '@components/atomic/inputs/InputText';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { getEtablissementData } from '@models/sirene/apiRequests/sireneRequests';
import { useTranslation } from 'react-i18next';

import { emailRegex, phoneNumberRegex } from '@utils/regex';
import { TextError } from '@components/TextError';
import { CompanyActivityStatusEnum } from '@utils/enums';
import { IPayloadCreatePartner } from '@models/partners/utils/types/partnersType';
import { ENTITY_TYPES } from '@utils/roles';
import { partner_type } from '@utils/utils';
import { storePartner } from '../apiRequests/partnersRequest';

interface IModalAddPartnerFormProps {
  entityType: number;
  setModal: Dispatch<SetStateAction<boolean>>;
  refreshList: CallableFunction;
  onClose?: (res: any) => void;
  isPaymentDelegate?: boolean;
}

function ModalAddPartnerForm({
  entityType,
  setModal,
  refreshList,
  onClose,
  isPaymentDelegate,
}: IModalAddPartnerFormProps) {
  const { t } = useTranslation();
  const methods = useForm();
  const { handleSubmit, watch, setValue, formState } = methods;
  const { isValid } = formState;

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [siretError, setSiretError] = useState<string | null>(null);

  // Watch entityType dynamically
  const currentEntityType = watch('entityType', entityType);

  const entityKey = useMemo(() => {
    switch (currentEntityType) {
      case ENTITY_TYPES.SOUS_TRAITANT:
        return partner_type.SUBCONTRACTOR;
      case ENTITY_TYPES.BUREAUX_CONTROLE:
        return partner_type.CONTROL_OFFICE;
      case ENTITY_TYPES.APPORTEUR_AFFAIRES:
        return !isPaymentDelegate
          ? partner_type.BUSINESS_PROVIDER
          : partner_type.PAYMENT_DELEGATE;
      default:
        return partner_type.DEFAULT;
    }
  }, [currentEntityType]);

  const onSubmit = async (formData: FieldValues) => {
    setErrorMessage('');

    setLoading(true);
    const payload = {
      ...formData,
      type: currentEntityType,
    } as IPayloadCreatePartner;
    const res = await storePartner(
      t,
      payload,
      setLoading,
      setModal,
      refreshList,
      setErrorMessage
    );

    // Call onClose if it exists
    if (res && typeof onClose === 'function') {
      onClose(res);
    }
  };

  const getEtablissementInfos = async (siret: string) => {
    setLoading(true);
    await getEtablissementData(siret, setLoading, setValue, currentEntityType);
  };

  const onChangeSiret = (siretValue: string) => {
    setErrorMessage('');
    setSiretError('');
    if (!loading && siretValue.length === 14) {
      getEtablissementInfos(siretValue);
    }
  };

  const status = watch('status');
  const isMyPartner = watch('is_my_partner');

  // Effect to compute and set the error message
  useEffect(() => {
    if (isMyPartner) {
      setSiretError(t(`partners.${entityKey}.siret.error`));
    } else if (status === CompanyActivityStatusEnum.BANKRUPTED) {
      setSiretError(
        t('forms.siret.error_invalid_status', {
          company_name: methods.getValues('company_name'),
        })
      );
    } else {
      setSiretError(''); // Clear error
    }
  }, [status, isMyPartner, t, methods]); // Dependencies for the effect

  return (
    <Modal
      title={t(`partners.${entityKey}.modal_add.title`)}
      backgroundTransparent
      textBtnConfirm={String(t('buttons.validate'))}
      btnCancel
      setIsModal={setModal}
      onConfirmClick={handleSubmit(onSubmit)}
      isLoading={loading}
      btnConfirmDisabled={
        loading ||
        !isValid ||
        watch('status') === CompanyActivityStatusEnum.BANKRUPTED ||
        watch('is_my_partner')
      }
      sidebarVisible
    >
      <div className="mt-5">
        {!isPaymentDelegate && (
          <h3 className="text-xl font-medium">
            {t(`partners.${entityKey}.modal_add.header_title`)}
          </h3>
        )}
        {!isPaymentDelegate && (
          <h4>{t(`partners.${entityKey}.modal_add.header_text`)}</h4>
        )}
        <FormProvider {...methods}>
          <form
            id="form-add-control_office"
            className="mt-5 flex flex-col gap-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="w-full">
              <InputText
                id="siret"
                name="siret"
                placeholder={`${t('partners.siret')}`}
                onChange={(e) => onChangeSiret(String(e))}
                required
                disabled={loading}
                typeNumber
                maxLength={14}
                rules={{
                  pattern: {
                    value: /^\d{14}$/,
                    message: t('forms.siret.error'),
                  },
                }}
                error={
                  (watch('status') &&
                    watch('status') === CompanyActivityStatusEnum.BANKRUPTED) ||
                  watch('is_my_partner')
                }
                textError={siretError || undefined}
                valid={watch('siret') && watch('siret').length === 14}
                dataTestId="input_text_siret"
              />
            </div>
            <div>
              <InputText
                id="company_name"
                name="company_name"
                placeholder={`${t('partners.company_name')}`}
                required
                valid={false}
                value={watch('company_name')}
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <InputText
                id="lastname"
                name="lastname"
                placeholder={t('partners.lastname')}
                disabled={loading}
                dataTestId="input_text_partners_lastname"
                required
              />
              <InputText
                id="firstname"
                name="firstname"
                placeholder={t('partners.firstname')}
                disabled={loading}
                dataTestId="input_text_partners_firstname"
                required
              />
              <InputText
                id="phone_number"
                name="phone_number"
                placeholder={t('forms.phone.placeholder')}
                typeNumber
                rules={{
                  pattern: {
                    value: phoneNumberRegex,
                    message: t('forms.phone.error_pattern'),
                  },
                }}
                error={
                  watch('phone_number')?.length > 10 &&
                  !phoneNumberRegex.test(watch('phone_number'))
                }
                textError={`${t('forms.phone.error_pattern')}`}
                disabled={loading}
              />
              {isPaymentDelegate ? (
                <InputText
                  id="function"
                  name="function"
                  placeholder={`${t('forms.function.placeholder')}`}
                  disabled={loading}
                  required
                  value={watch('function')}
                />
              ) : (
                // Factoriser les deux inputs email
                <InputText
                  id="contact_email"
                  name="contact_email"
                  placeholder={`${t('forms.email.placeholder')}`}
                  disabled={loading}
                  required
                  rules={{
                    pattern: {
                      value: emailRegex,
                      message: t('forms.email.error_pattern'),
                    },
                  }}
                  valid={emailRegex.test(watch('contact_email'))}
                  error={
                    typeof errorMessage === 'string' &&
                    errorMessage.includes('mail')
                  }
                  dataTestId="input_text_contact_email"
                  onChange={() => {
                    if (errorMessage !== '') setErrorMessage('');
                  }}
                />
              )}
            </div>
            {isPaymentDelegate && (
              <div>
                <InputText
                  id="contact_email"
                  name="contact_email"
                  placeholder={`${t('forms.email.placeholder')}`}
                  disabled={loading}
                  required
                  rules={{
                    pattern: {
                      value: emailRegex,
                      message: t('forms.email.error_pattern'),
                    },
                  }}
                  valid={emailRegex.test(watch('contact_email'))}
                  error={
                    typeof errorMessage === 'string' &&
                    errorMessage.includes('mail')
                  }
                  dataTestId="input_text_contact_email"
                  onChange={() => {
                    if (errorMessage !== '') setErrorMessage('');
                  }}
                  // Factoriser les deux inputs email
                />
              </div>
            )}
            <div>
              <InputText
                id="address"
                name="address"
                placeholder={t('forms.address.placeholder')}
                disabled
                valid={false}
                value={
                  !watch('address')
                    ? ''
                    : `${watch('address')} ${watch('zipcode')} ${watch('city')}`
                }
                required
              />
            </div>
          </form>
          <TextError errorMessage={errorMessage} />
        </FormProvider>
      </div>
    </Modal>
  );
}

ModalAddPartnerForm.defaultProps = {
  onClose: undefined,
  isPaymentDelegate: false,
};

export { ModalAddPartnerForm };
