import { useContext, useMemo } from 'react';
import { ThemeContext } from '@context/ThemeContext';
import { IThemeInList } from '@models/settings/utils/settingsTypes';
import { THEME_IMAGE_TYPES } from '@models/settings/utils/enums';
import { LogoOpxIcon } from '@assets/images/svgComponents';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTES } from '@utils/routesUrls';
import { AuthContext } from '@context/authContext';

interface ModalChangeThemeProps {
  isOpen: boolean;
  toggleModal: (value: boolean) => void;
}

/**
 * Composant ModalChangeTheme
 * Gère l'affichage et la logique de changement de thème.
 *
 * @param {boolean} isOpen - État d'ouverture de la modal.
 * @param {function} toggleModal - Fonction pour basculer l'état de la modal.
 */
function ModalChangeTheme({ isOpen, toggleModal }: ModalChangeThemeProps) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    themeData,
    themesList,
    updateThemeData,
    updateThemeSlug,
    updateThemeCompanyName,
  } = useContext(ThemeContext);

  /**
   * Fonction pour activer un thème spécifique.
   *
   * @param {IThemeInList} [theme] - Le thème à activer.
   */
  const setActiveTheme = (theme?: IThemeInList) => {
    if (user && theme) {
      localStorage.setItem(`whitelabel_id`, theme.entity_id.toString());
    } else {
      localStorage.removeItem(`whitelabel_id`);
    }
    if (location.pathname !== DASHBOARD_ROUTES.DASHBOARD) {
      updateThemeData(theme?.theme);
      updateThemeSlug(theme?.company_name.toLowerCase());
      updateThemeCompanyName(theme?.company_name);
      toggleModal(false);
      navigate(DASHBOARD_ROUTES.DASHBOARD, { replace: true });
    } else {
      window.location.reload();
    }
  };

  /**
   * Mémoïsation des thèmes pour optimiser les performances.
   */
  const renderedThemes = useMemo(() => {
    return themesList.map((themeInList) => {
      const logoTheme = themeInList.theme.images.find(
        (image) => image.image === THEME_IMAGE_TYPES.LOGO_MENU
      )?.custom;
      const isActive = themeData === themeInList.theme;

      return (
        <button
          key={themeInList.company_name}
          className={`w-full flex justify-between items-center px-4 py-2 rounded-default ${
            isActive
              ? 'bg-white border border-borderGrey cursor-default'
              : 'cursor-pointer'
          }`}
          type="button"
          onClick={() => {
            if (!isActive) {
              setActiveTheme(themeInList);
            }
          }}
        >
          {logoTheme ? (
            <img src={logoTheme} alt="logo" className="w-[6.625rem] h-[2rem]" />
          ) : (
            <LogoOpxIcon width="6.625rem" height="2rem" />
          )}
          <p className="text-[.625rem]">{themeInList.company_name}</p>
          <p className="text-[.75rem] font-medium">
            {isActive
              ? t('settings.white_label.active')
              : t('settings.white_label.login')}
          </p>
        </button>
      );
    });
  }, [themesList, themeData, t]);

  if (!isOpen) return null;

  return (
    <div className="absolute left-0 bg-backgroundBody p-6 rounded-default text-black min-w-[29.75rem] shadow-lg border border-borderGrey z-[100] space-y-3 mt-4">
      {renderedThemes}
      <button
        className={`w-full flex justify-between items-center p-4 rounded-default text-[.75rem] font-medium ${
          !themeData ? 'bg-white border border-borderGrey cursor-default' : ''
        }`}
        type="button"
        onClick={() => {
          if (themeData) {
            setActiveTheme();
          }
        }}
      >
        <p>{t('settings.white_label.consolidated_view')}</p>
        <p>
          {!themeData
            ? t('settings.white_label.active')
            : t('settings.white_label.login')}
        </p>
      </button>
    </div>
  );
}

export { ModalChangeTheme };
