import BoolRadio from '@components/atomic/inputs/controls/BoolRadio';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import { Modal } from '@components/Modal';
import {
  IContractChoice,
  IFormInputsNewControlContactField,
  IOperationChoice,
} from '@models/controlContact/utils/controlContactTypes';
import {
  responseFormatEnum,
  responseFormatsLabelValue,
} from '@models/controlContact/utils/utlis';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  getFieldToEditData,
  getOperationsAndContracts,
  storeOrUpdateControlContactField,
} from '@models/controlContact/apiRequests/controlContactRequests';
import { ILabelValue } from '@globalTypes/globalTypes';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import ResponseFormatValues from './ResponseFormatValues';
import ColorValues from './ColorValues';
import ModalDeleteControlContactField from './ModalDeleteControlContactField';

interface IModalNewControlContactFieldProps {
  getFields: () => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  fieldIdToEdit?: number | null;
}

function ModalNewControlContactField({
  getFields,
  setShowModal,
  fieldIdToEdit,
}: IModalNewControlContactFieldProps) {
  const { t } = useTranslation();

  const methods = useForm<IFormInputsNewControlContactField>({
    defaultValues: {
      operation_ids: ['all'],
      contract_ids: ['all'],
    },
  });
  const { handleSubmit, watch, setValue, reset } = methods;
  const hasTooltipValue = watch('has_tooltip');
  const responseFormatValue = watch('response_format');
  const operationIds = watch('operation_ids');
  const contractIds = watch('contract_ids');
  const optionalValue = watch('optional');

  const [operationsChoices, setOperationsChoices] = useState<
    IOperationChoice[]
  >([]);
  const [contractsChoices, setContractsChoices] = useState<IContractChoice[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showModalDeleteField, setShowModalDeleteField] = useState(false);
  const [resetSignalOperations, setResetSignalOperations] = useState(0);
  const [resetSignalContracts, setResetSignalContracts] = useState(0);

  const modalTitle = fieldIdToEdit
    ? t('control_contact.settings.edit_field_modal_title')
    : t('control_contact.settings.new_field_modal.title');

  const onSubmit = async (data: IFormInputsNewControlContactField) => {
    setIsLoading(true);
    const response = await storeOrUpdateControlContactField(
      data,
      fieldIdToEdit
    );
    if (response) {
      setShowModal(false);
      getFields();
    }
    setIsLoading(false);
  };

  const getFieldToEdit = async () => {
    setIsDataLoading(true);
    const response = await getFieldToEditData(fieldIdToEdit as number, t);
    if (response) {
      reset(response);
    }
    setIsDataLoading(false);
  };

  const getOperationsAndContractsChoices = async () => {
    const response = await getOperationsAndContracts();
    if (response) {
      setOperationsChoices(response.operations);
      setContractsChoices(response.contracts);
      setResetSignalOperations(resetSignalOperations + 1);
      setResetSignalContracts(resetSignalContracts + 1);
    }
  };

  const renderResponseFormatInputs = () => {
    if (!responseFormatValue) {
      return null;
    }
    switch (responseFormatValue.value) {
      case responseFormatEnum.RADIO_BUTTONS:
      case responseFormatEnum.SELECT:
      case responseFormatEnum.CHECKBOX:
        return <ResponseFormatValues />;
      case responseFormatEnum.MULTIPLE_SELECT:
        return (
          <>
            <ResponseFormatValues />
            <ColorValues />
          </>
        );
      default:
        return null;
    }
  };

  const handleCheckMultipleSelect = (
    item: ILabelValue,
    key: 'operation_ids' | 'contract_ids'
  ) => {
    const currentValues = watch(key);
    const isAllChoice = item.value === 'all';
    if (!currentValues) {
      setValue(key, [item.value]);
      return;
    }

    if (isAllChoice) {
      if (!currentValues.includes(item.value)) {
        setValue(key, [item.value]);
      }

      if (key === 'operation_ids') {
        setResetSignalOperations(resetSignalOperations + 1);
      } else {
        setResetSignalContracts(resetSignalContracts + 1);
      }
      return;
    }

    if (currentValues.includes(item.value)) {
      setValue(
        key,
        currentValues.filter((value) => value !== item.value)
      );
    } else {
      setValue(key, [
        ...currentValues.filter((value) => value !== 'all'),
        item.value,
      ]);
    }
  };

  const confirmDisabled = () => {
    if (fieldIdToEdit && isDataLoading) return true;
    const values = watch();
    if (values) {
      return (
        !values.label ||
        !values.response_format ||
        values.has_tooltip === undefined ||
        (!!values.has_tooltip && !values.tooltip_text) ||
        (!!values.response_format_values &&
          values.response_format_values.length === 0) ||
        (!!values.response_format && !values.response_format.value) ||
        (!!values.response_format &&
          !!values.response_format.value &&
          (values.response_format.value === responseFormatEnum.RADIO_BUTTONS ||
            values.response_format.value === responseFormatEnum.SELECT ||
            values.response_format.value === responseFormatEnum.CHECKBOX ||
            values.response_format.value ===
              responseFormatEnum.MULTIPLE_SELECT) &&
          !values.response_format_values?.some((value) => value !== '')) ||
        (!!values.response_format &&
          !!values.response_format.value &&
          values.response_format.value === responseFormatEnum.MULTIPLE_SELECT &&
          !values.response_format_color_values)
      );
    }
    return true;
  };

  const allOperationsChoice = {
    label: t('settings.all_feminine'),
    value: 'all',
  };

  const allContractsChoice = {
    label: t('settings.all'),
    value: 'all',
  };

  useEffect(() => {
    if (fieldIdToEdit) {
      getFieldToEdit();
    } else {
      setValue('optional', true);
    }
    getOperationsAndContractsChoices();
    return () => {
      reset();
    };
  }, []);

  return (
    <>
      <Modal
        title={modalTitle}
        setIsModal={setShowModal}
        backgroundTransparent
        textBtnCancel={t('buttons.cancel') as string}
        btnCancel
        textBtnConfirm={t('buttons.confirm') as string}
        onConfirmClick={handleSubmit(onSubmit)}
        isLoading={isLoading}
        formId="new-control-contact-field"
        isOverflowAuto
        maxHeight="80vh"
        btnConfirmDisabled={confirmDisabled()}
      >
        {fieldIdToEdit && isDataLoading ? (
          <div className="space-y-4 pt-6">
            <LoaderDivSkeleton height="3.5rem" width="100%" />
            <LoaderDivSkeleton height="3.5rem" width="100%" />
            <LoaderDivSkeleton height="3.5rem" width="100%" />
            <LoaderDivSkeleton height="3.5rem" width="100%" />
            <LoaderDivSkeleton height="3.5rem" width="100%" />
          </div>
        ) : (
          <FormProvider {...methods}>
            <form id="new-control-contact-field">
              <div className="flex flex-col gap-4 pt-6">
                <InputText
                  id="label"
                  name="label"
                  label={t('control_contact.settings.new_field_modal.label')}
                  placeholder={t('settings.type.text')}
                  required
                />
                <BoolRadio
                  label={t('control_contact.settings.new_field_modal.optional')}
                  onChange={(choice) => setValue('optional', choice)}
                  value={optionalValue ?? true}
                  required
                />
                <BoolRadio
                  label={t('control_contact.settings.new_field_modal.info')}
                  required
                  onChange={(choice) => setValue('has_tooltip', choice)}
                  value={hasTooltipValue}
                />
                {hasTooltipValue && (
                  <InputText
                    id="tooltip_text"
                    name="tooltip_text"
                    label={t(
                      'control_contact.settings.new_field_modal.tooltip_text'
                    )}
                    placeholder={t('settings.type.text')}
                    required
                  />
                )}
                <InputSelect
                  placeholder={t(
                    'control_contact.settings.new_field_modal.response_format_placeholder'
                  )}
                  label={
                    t(
                      'control_contact.settings.new_field_modal.response_format'
                    ) as string
                  }
                  required
                  dataLabelValue={responseFormatsLabelValue(t)}
                  defaultSelected={
                    responseFormatValue ? responseFormatValue.label : undefined
                  }
                  onSelectLabelValue={(item) =>
                    setValue('response_format', item)
                  }
                  customMenuHeight="18rem"
                />
                {renderResponseFormatInputs()}
                <InputSelect
                  placeholder={t('settings.all_feminine')}
                  label={
                    t(
                      'control_contact.settings.new_field_modal.operations_concerned'
                    ) as string
                  }
                  required
                  dataLabelValue={[
                    allOperationsChoice,
                    ...operationsChoices.map((operation) => ({
                      label: operation.code,
                      value: operation.id.toString(),
                    })),
                  ]}
                  isMultipleSelect
                  onSelectLabelValue={(item) =>
                    handleCheckMultipleSelect(item, 'operation_ids')
                  }
                  defaultChecked={
                    operationIds && operationIds.length > 0
                      ? operationIds
                          .map(
                            (id) =>
                              operationsChoices.find(
                                (operation) => String(operation.id) === id
                              )?.code
                          )
                          .filter((code): code is string => code !== undefined)
                      : [allOperationsChoice.label]
                  }
                  resetSignal={resetSignalOperations}
                  forceSelectedOptionBlack
                />
                <InputSelect
                  placeholder={t('settings.all')}
                  label={
                    t(
                      'control_contact.settings.new_field_modal.contracts_concerned'
                    ) as string
                  }
                  required
                  dataLabelValue={[
                    allContractsChoice,
                    ...contractsChoices.map((contract) => ({
                      label: contract.reference,
                      value: contract.id.toString(),
                    })),
                  ]}
                  isMultipleSelect
                  onSelectLabelValue={(item) =>
                    handleCheckMultipleSelect(item, 'contract_ids')
                  }
                  defaultChecked={
                    contractIds && contractIds.length > 0
                      ? contractIds
                          .map(
                            (id) =>
                              contractsChoices.find(
                                (contract) => String(contract.id) === id
                              )?.reference
                          )
                          .filter((code): code is string => code !== undefined)
                      : [allContractsChoice.label]
                  }
                  resetSignal={resetSignalContracts}
                  forceSelectedOptionBlack
                />
                {fieldIdToEdit && (
                  <div className="flex justify-end">
                    <ButtonOpx
                      label={t('buttons.delete')}
                      onClick={() => setShowModalDeleteField(true)}
                      small
                      type="secondary"
                    />
                  </div>
                )}
              </div>
            </form>
          </FormProvider>
        )}
      </Modal>
      {showModalDeleteField && (
        <ModalDeleteControlContactField
          setShowModal={setShowModalDeleteField}
          fieldIdToEdit={fieldIdToEdit as number}
          getFields={getFields}
          setShowParentModal={setShowModal}
        />
      )}
    </>
  );
}

export default ModalNewControlContactField;

ModalNewControlContactField.defaultProps = {
  fieldIdToEdit: undefined,
};
