import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { Modal } from '@components/Modal';
import { ILabelValue } from '@globalTypes/globalTypes';
import {
  getOperationsWithMaterial,
  storeProduct,
} from '@models/materials/apiRequests/materialsRequests';
import MaterialForm from '@models/materials/components/MaterialForm';
import { getListMeterialsPerOperation } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import {
  initialMaterial,
  materialInitialState,
} from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { IBrandMaterials } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { IMaterial } from '@models/worksiteCreation/utils/types/worksitesType';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { OperationTypeEnum } from '@utils/enums';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

interface ProductSelectorProps {
  brandActive: IBrandMaterials;
  operationCode: string;
  setListMaterials: Dispatch<SetStateAction<IMaterial[]>>;
  onProductSelected: (material: IMaterial) => void;
  listMaterials: IMaterial[];
  searchInternal: boolean;
  setMaterialActive: Dispatch<SetStateAction<IMaterial>>;
  setNewMaterial?: Dispatch<
    SetStateAction<{ mark: string; reference: string }>
  >;
  resetSignal: number;
  defaultProductName?: string;
}

function ProductSelector({
  brandActive,
  operationCode,
  setListMaterials,
  onProductSelected,
  listMaterials,
  searchInternal,
  setMaterialActive,
  setNewMaterial,
  resetSignal,
  defaultProductName = '',
}: ProductSelectorProps) {
  const { t } = useTranslation();
  const { readOnly, worksiteDatas } = useContext(WorksiteCreationContext);

  const isB2B = worksiteDatas?.operation_type === OperationTypeEnum.B2B;

  const [productSearchValue, setProductSearchValue] =
    useState<string>(defaultProductName);
  const [productPage, setProductPage] = useState<number>(1);
  const [lastPageProduct, setLastPageProduct] = useState<number>(2);
  const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(false);
  const [isLoadingProductModal, setIsLoadingProductModal] =
    useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [closeDropdown, setCloseDropdown] = useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [resetSignalIsolated, setResetSignalIsolated] = useState<number>(0);
  const [operationFound, setOperationFound] = useState<ILabelValue | null>(
    null
  );

  const listInnerRefProduct = useRef<HTMLDivElement>(null);

  const methodsModal = useForm();

  const resetValues = () => {
    setProductSearchValue('');
    setProductPage(1);
    setIsSearching(false);
    setIsScrolling(false);
    setLastPageProduct(2);
    setTimeOut(null);
    setIsLoadingProduct(false);
    setMaterialActive(initialMaterial);
    setCloseDropdown(true);
    if (setNewMaterial) {
      setNewMaterial(materialInitialState.newMaterial);
    }
  };

  const createProduct = async (data: FieldValues) => {
    setIsLoadingProductModal(true);
    const payload = {
      brand_name: brandActive.name,
      name: data.product_name,
      comment: data.product_comment || '',
      operations: data.product_operations
        ? data.product_operations.map((op: ILabelValue) => op.value)
        : [],
      files: data.product_files || [],
    };
    await storeProduct(payload, t).then((response) => {
      if (response) {
        const responseData = response;
        const productNameUpperCase = data.product_name.toUpperCase();
        const newMaterialCreated = {
          ...initialMaterial,
          caracteristics: JSON.parse(responseData.caracteristics),
          id: responseData.id,
          material_id: responseData.material_id,
          name: responseData.name,
        };
        setListMaterials((prevMaterials) => [
          ...prevMaterials,
          newMaterialCreated,
        ]);
        setProductSearchValue(productNameUpperCase);
        setCloseDropdown(true);
        onProductSelected(newMaterialCreated);
        setShowConfirmationModal(false);
      }
    });
    setIsLoadingProductModal(false);
  };

  const handleAddProduct = () => {
    setShowConfirmationModal(true);
  };

  const infiniteScroll = () => {
    setIsScrolling(true);
    setIsSearching(true);
    if (
      listInnerRefProduct.current &&
      !isLoadingProduct &&
      productPage < lastPageProduct
    ) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerRefProduct.current;
      if (scrollTop + clientHeight >= scrollHeight - 1.25) {
        setProductPage((prevState) => prevState + 1);
      }
    }
  };

  const getList = async (mark?: string, internal?: boolean) => {
    await getListMeterialsPerOperation(
      setIsLoadingProduct,
      mark ? '' : productSearchValue,
      productPage,
      operationCode,
      setListMaterials,
      isScrolling,
      setLastPageProduct,
      mark || brandActive.name,
      internal || searchInternal
    );
  };

  const onSearchProduct = (value: string) => {
    setIsScrolling(false);
    setCloseDropdown(false);
    setIsSearching(true);
    setProductPage(1);
    setProductSearchValue(value);
  };

  const handleProductSelection = (material: IMaterial) => {
    onProductSelected(material);
    const selectedMaterial = {
      mark: material.caracteristics.mark.name,
      reference: material.name,
    };
    setProductSearchValue(material.name);
    setCloseDropdown(true);
    if (setNewMaterial) {
      setNewMaterial(selectedMaterial);
    }
    // Persiste la sélection dans le localStorage
    localStorage.setItem('selectedMaterial', JSON.stringify(selectedMaterial));
  };

  const fetchOperationsWithMaterial = async () => {
    const ops = await getOperationsWithMaterial();
    if (ops && Array.isArray(ops)) {
      const found = ops.find(
        (op: any) => op.code.toLowerCase() === operationCode.toLowerCase()
      );
      if (found) {
        setOperationFound({
          label: `${found.code} - ${found.description}`,
          value: found.id.toString(),
        });
      }
    }
  };

  useEffect(() => {
    if (resetSignal) {
      resetValues();
      setResetSignalIsolated(resetSignal);
    }
  }, [resetSignal]);

  useEffect(() => {
    if (isSearching) {
      if (timeOut) {
        clearTimeout(timeOut);
        setTimeOut(null);
      }
      const timeout = setTimeout(() => {
        getList();
      }, 350);
      setTimeOut(timeout);
    }
  }, [productSearchValue, isSearching]);

  useEffect(() => {
    if (productPage > 1) {
      getList();
    }
  }, [productPage]);

  useEffect(() => {
    if (brandActive.id !== null && brandActive.id !== 0) {
      getList();
    }
  }, [brandActive.id]);

  useEffect(() => {
    if (operationCode) {
      fetchOperationsWithMaterial();
    }
  }, [operationCode]);

  useEffect(() => {
    if (defaultProductName) {
      setProductSearchValue(defaultProductName);
    }
  }, [defaultProductName]);

  return (
    <>
      <InputSelect
        required={!isB2B}
        label={`${t('worksite_creation.material.reference')}`}
        placeholder={t('worksite_creation.material.search_product')}
        showClearButton={productSearchValue !== ''}
        valueInput={productSearchValue}
        isAutoComplete
        disabled={brandActive.name === '' || readOnly}
        isLoading={isLoadingProduct}
        callbackOnSearch={(value) => onSearchProduct(value)}
        closeDropdown={closeDropdown}
        setCloseDropdown={setCloseDropdown}
        addClass="w-full"
        dataTestIdSearch="search_material_product"
        resetValue={resetSignal !== resetSignalIsolated}
        onClear={() => resetValues()}
      >
        <div
          className="flex flex-col max-h-[25rem]"
          onScroll={infiniteScroll}
          ref={listInnerRefProduct}
        >
          {listMaterials.map((material, index) => (
            <button
              onClick={() => {
                handleProductSelection(material);
              }}
              className="flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey"
              key={v4()}
              type="button"
              data-test-id={`option_product_${index + 1}`}
            >
              {material.name} - {material.caracteristics.mark.name}
            </button>
          ))}
          {productSearchValue !== '' &&
            !listMaterials.some(
              (m) => m.name.toLowerCase() === productSearchValue.toLowerCase()
            ) && (
              <button
                type="button"
                className="text-primaryText rounded-b-default cursor-pointer flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey"
                onClick={handleAddProduct}
              >
                {`+ ${t('brand.products.add')}`}
              </button>
            )}
        </div>
      </InputSelect>
      {showConfirmationModal && (
        <Modal
          title={`${t('brand.products.add')}`}
          btnCancel
          textBtnConfirm={`${t('buttons.confirm')}`}
          setIsModal={setShowConfirmationModal}
          onConfirmClick={methodsModal.handleSubmit(createProduct)}
          backgroundTransparent
          btnConfirmDisabled={!methodsModal.watch('product_name')}
          isLoading={isLoadingProductModal}
        >
          <div className="mt-[1rem]">
            <FormProvider {...methodsModal}>
              <MaterialForm
                formId="materialFormModal"
                operationsOptions={[]}
                operationForced={operationFound || undefined}
                onSubmit={createProduct}
                defaultName={productSearchValue}
              />
            </FormProvider>
          </div>
        </Modal>
      )}
    </>
  );
}

ProductSelector.defaultProps = {
  setNewMaterial: undefined,
  defaultProductName: '',
};

export default ProductSelector;
