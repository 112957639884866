import { useContext, useEffect } from 'react';
import { DocumentViewer } from '@components/documents/DocumentViewer';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { dateToDDMMYYY, getHoursFromDate } from '@utils/format';
import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { ChevronLeftIcon, EnvelopeIcon } from '@assets/images/svgComponents';
import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';
import { FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { TextArea } from '@components/atomic/inputs/TextArea';
import { DOCUMENT_STATUS_ENUM, DOCUMENT_TYPES } from '@utils/utils';
import ImageContainer from '@components/atomic/ImageContainer';
import { ILinkedFileMessage } from '../utils/checkpointsTypes';
import {
  getErrorMessage,
  isWrongResponseValue,
  updateCheckpoint,
} from '../utils/utils';
import { LinkedFileCheckpointContext } from '../utils/linkedFileCheckpointContext';

function RightPartCheckPoints() {
  const {
    globalEnum: { linked_file_type },
  } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const methods = useForm();
  const { watch, setValue } = methods;

  const {
    document,
    savedMessages,
    updateSavedMessages,
    checkpoints,
    updateWorksiteCheckpoints,
    updateComplianceMessage,
    mailIsOpen,
    updateMailIsOpen,
    worksite,
    groupedDocuments,
  } = useContext(LinkedFileCheckpointContext);

  const readOnly = document.status !== DOCUMENT_STATUS_ENUM.PENDING;

  const contact = worksite?.worksite_partner?.contact;
  const address = worksite?.address;

  const isAH = document.file_type === DOCUMENT_TYPES.AH;

  const operations = () => {
    if (document.relation_type !== 'worksite_operation') {
      return worksite.worksites_operations;
    }

    const documentGroup = groupedDocuments.find((gd) =>
      gd.files.some((f) => f.id === document.id)
    );
    const operationsIds =
      documentGroup?.files.flatMap((f) => f.relation_ids) ||
      document.relation_ids ||
      [];
    return worksite?.worksites_operations.filter((op) =>
      operationsIds.includes(op.id)
    );
  };

  const worksiteReference =
    worksite?.site_name ||
    `${worksite?.beneficiary?.firstname} ${worksite?.beneficiary?.lastname}`;

  const initialTopMessage = `${t('global.hello')},\n\n${t(
    `checkpoints.conformity_errors${isAH ? '_ah' : ''}`,
    {
      fileType: linked_file_type[document.file_type],
      worksiteReference,
      worksiteId: worksite?.id,
      worksiteAddress: `${address?.address} ${
        address?.additional_address || ''
      } ${address?.postal_code} ${address?.city}`,
      operations: `"${operations()
        .map((op) => op.operation.description)
        .join(', ')}"`,
    }
  )}`;

  const initialBottomMessage = `\n${t('global.regards')},\n\n${
    user?.firstname
  } ${user?.lastname}`;

  const errorCheckpoints = checkpoints.filter((cp) =>
    isWrongResponseValue(cp, cp.value)
  );

  const documentMessages = savedMessages.find(
    (msg) => msg.document_id === document.id
  );

  const selectedMessage = documentMessages?.messages.find(
    (msg) => msg.selected
  );

  const getMessageLabel = (msg: ILinkedFileMessage) => {
    const date = dateToDDMMYYY(msg.sent_at);
    const time = getHoursFromDate(msg.sent_at);
    return `${t('global.sent_on_at', { date, time })}`;
  };

  const handleSelectMessage = (value: string) => {
    const newSelectedMessage = documentMessages?.messages.find(
      (msg) => msg.id === Number(value)
    );

    if (newSelectedMessage) {
      updateSavedMessages(
        savedMessages.map((msg) => {
          if (msg.document_id === document.id) {
            return {
              ...msg,
              messages: msg.messages.map((m) => ({
                ...m,
                selected: m.id === newSelectedMessage.id,
              })),
            };
          }
          return msg;
        })
      );
    }
  };

  useEffect(() => {
    const errorChekpoints = checkpoints.filter((cp) =>
      isWrongResponseValue(cp, cp.value)
    );

    let errorElements = '';

    errorChekpoints.forEach((elt) => {
      errorElements += `\t• ${
        elt.custom_conformity_description ||
        elt.checkpoint.conformity_description
      } \n`;
    });

    updateComplianceMessage(
      `${watch('top')}\n\n${errorElements}\n${watch('bottom')}`
    );
  }, [watch(), checkpoints]);

  useEffect(() => {
    setValue('top', initialTopMessage);
    setValue('bottom', initialBottomMessage);
  }, [document]);

  return (
    <FormProvider {...methods}>
      <div
        className="relative rounded-[.5rem] flex gap-[1rem] w-[40%]"
        id="right_part_checkpoints"
      >
        <div className="w-[89%]">
          <DocumentViewer
            isLoading={false}
            openInNewWindowButton
            isCheckpoint
          />
        </div>

        <div
          className={`absolute right-0 top-0 h-full bg-white rounded-[.5rem] flex flex-col gap-[1rem] transition-all duration-300 ease-in-out ${
            mailIsOpen ? 'w-full' : 'w-[3.5rem]'
          }`}
        >
          <div
            className={`${
              mailIsOpen ? 'flex gap-3' : 'flex flex-col gap-[1rem]'
            }  items-center border-b border-b-borderGrey p-[1rem] w-full`}
          >
            {!mailIsOpen && (
              <ColorCube
                color={blueOpx}
                opacity
                numberOrIcon={<EnvelopeIcon />}
                size="1.5rem"
              />
            )}
            <ColorCube
              color={blueOpx}
              opacity
              numberOrIcon={<ChevronLeftIcon />}
              size="1.5rem"
              onClick={() => updateMailIsOpen(!mailIsOpen)}
              addClass={mailIsOpen ? 'rotate-180' : ''}
            />
            {mailIsOpen && (
              <div className="text-[1.25rem] py-[0.8rem] flex gap-3 items-center">
                <div>{t('checkpoints.conformity_request')}</div>
                <ColorCube
                  color={blueOpx}
                  opacity
                  numberOrIcon={<EnvelopeIcon />}
                  size="1.5rem"
                />
              </div>
            )}
          </div>
          {mailIsOpen && errorCheckpoints.length > 0 && (
            <div className="px-[1rem]">
              {document.status === DOCUMENT_STATUS_ENUM.PENDING && (
                <p className="text-[0.7rem] text-textGrey">
                  {t('checkpoints.conformity_request_preview', {
                    installer: worksite?.installer.company_name,
                  })}
                </p>
              )}

              <div className="p-[1rem] border border-borderGrey rounded-[.3rem] my-[1.5rem]">
                {t('checkpoints.conformity_request_for_worksite', {
                  reference: worksite?.reference,
                })}
              </div>
              {contact && (
                <div className="p-[1rem] border border-borderGrey rounded-[.3rem] my-[1.5rem] flex gap-3 items-startgap-2">
                  <ImageContainer
                    imageUrl={contact.photo_url}
                    height="2.5rem"
                    width="2.5rem"
                  />
                  <div>
                    <div className="text-[0.7rem] text-textGrey">
                      {contact.firstname} {contact.lastname} (
                      {worksite?.installer.company_name})
                    </div>
                    <div className="text-[0.8rem]">{contact.email}</div>
                  </div>
                </div>
              )}
              <div>
                <div className="text-lg font-[400] flex items-center justify-between p-1 rounded-[.3rem]">
                  {documentMessages &&
                    documentMessages?.messages?.length > 1 && (
                      <InputSelect
                        addClass="w-[16rem]"
                        dataLabelValue={documentMessages?.messages.map(
                          (msg) => ({
                            label: getMessageLabel(msg),
                            value: msg.id.toString(),
                          })
                        )}
                        placeholder=""
                        defaultSelected={
                          selectedMessage
                            ? getMessageLabel(selectedMessage)
                            : ''
                        }
                        onSelectLabelValue={(value) =>
                          handleSelectMessage(value.value)
                        }
                      />
                    )}
                  {documentMessages &&
                    documentMessages?.messages?.length === 1 && (
                      <div className="text-sm text-gray-500">
                        {getMessageLabel(documentMessages.messages[0])}
                      </div>
                    )}
                </div>

                <div className="w-full border border-borderGrey rounded-default p-[1rem] relative flex flex-col">
                  <div className="w-full">
                    <TextArea
                      id="top"
                      name="top"
                      addClass="w-full border-none px-2 py-1 overflow-auto whitespace-pre-wrap break-words focus:outline-none resize-none"
                      readOnly={readOnly}
                    />
                  </div>
                  <div>
                    {errorCheckpoints.map((checkpoint) => (
                      <div
                        key={checkpoint.checkpoint_id}
                        className="w-full flex items-end"
                      >
                        &nbsp;&nbsp;&nbsp;<div>•</div>
                        <InputText
                          id={String(checkpoint.id)}
                          name={String(checkpoint.id)}
                          placeholder=""
                          addClass="w-full p-[0] ms-2"
                          addClassToInput="border-none p-[0] m-[0]"
                          value={getErrorMessage(checkpoint)}
                          hideIsOptional
                          onChange={(e) =>
                            updateCheckpoint(
                              document,
                              checkpoint,
                              'custom_conformity_description',
                              String(e),
                              updateWorksiteCheckpoints
                            )
                          }
                          readOnly={readOnly}
                        />
                      </div>
                    ))}
                  </div>
                  <div>
                    <TextArea
                      id="bottom"
                      name="bottom"
                      addClass="w-full border-none px-2 py-1 overflow-hidden focus:outline-none"
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  );
}

export default RightPartCheckPoints;
