import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { useTranslation } from 'react-i18next';
import { AddIcon, ChevronDownIcon } from '@assets/images/svgComponents';
import {
  getCheckpoint,
  getEntityCheckpoints,
} from '@models/checkpoints/apiRequests/checkpointsRequests';
import { useEffect, useState } from 'react';
import { ICheckpoints } from '@models/checkpoints/utils/checkpointsTypes';
import { v4 } from 'uuid';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import ModalAddCheckpoint from '../../checkpoints/components/ModalAddCheckpoint';

function CustomCheckpoints() {
  const { t } = useTranslation();

  const [checkpoints, setCheckpoints] = useState<ICheckpoints[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalAddCheckpoint, setIsOpenModalAddCheckpoint] =
    useState(false);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState<
    ICheckpoints | undefined
  >(undefined);

  const getCheckpoints = async () => {
    setIsLoading(true);
    const response = await getEntityCheckpoints();

    if (response.data) {
      setCheckpoints(response.data);
    }

    setIsLoading(false);
  };

  const getCheckpointData = async (id: number) => {
    setIsLoading(true);

    const response = await getCheckpoint(id);

    if (response.data) {
      setSelectedCheckpoint(response.data);
      setIsOpenModalAddCheckpoint(true);
    }

    setIsLoading(false);
  };

  const handleOpenModal = (checkpoint?: ICheckpoints) => {
    setSelectedCheckpoint(checkpoint);
    if (checkpoint) {
      getCheckpointData(checkpoint.id);
    } else {
      setSelectedCheckpoint(undefined);
      setIsOpenModalAddCheckpoint(true);
    }
  };

  const handleCloseModal = () => {
    setIsOpenModalAddCheckpoint(false);
    setSelectedCheckpoint(undefined);
  };

  useEffect(() => {
    getCheckpoints();
  }, []);

  return (
    <div className="w-full">
      <Card
        title={t('settings.checkpoints.title')}
        addClass="w-full"
        actionButtons={
          <ButtonOpx
            label={t('settings.checkpoints.new_checkpoint')}
            small
            icon={<AddIcon />}
            onClick={() => handleOpenModal()}
          />
        }
      >
        <div className="flex flex-col gap-[1.5rem]">
          {!isLoading ? (
            checkpoints.map((checkpoint, index) => (
              <button
                key={checkpoint.id}
                type="button"
                className="flex items-center justify-between cursor-pointer hover:bg-gray-50 p-2 rounded-default w-full text-left"
                onClick={() => handleOpenModal(checkpoint)}
              >
                <div className="flex items-center gap-2 w-full">
                  <ColorCube
                    color={blueOpx}
                    opacity
                    numberOrIcon={index + 1}
                    size="2.5rem"
                    addClass="me-[1.5rem]"
                  />
                  <div className="max-w-[90%]">{checkpoint.question}</div>
                </div>

                <ColorCube
                  color={blueOpx}
                  opacity
                  numberOrIcon={<ChevronDownIcon />}
                  size="1.5rem"
                  addClass="me-[1.5rem] -rotate-90"
                />
              </button>
            ))
          ) : (
            <div className="space-y-4">
              {Array.from({ length: 5 }).map(() => (
                <LoaderDivSkeleton key={v4()} height="3.5rem" width="100%" />
              ))}
            </div>
          )}
        </div>
      </Card>
      {isOpenModalAddCheckpoint && (
        <ModalAddCheckpoint
          setIsOpen={handleCloseModal}
          getData={getCheckpoints}
          checkpoint={selectedCheckpoint}
        />
      )}
    </div>
  );
}

export default CustomCheckpoints;
