import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StepEnergyDepositAndPrimes } from '@models/worksiteCreation/components/globalRenovation/StepEnergyDepositAndPrimes';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { getGraph } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { IGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { GlobalRenovationChoiceOperations } from '@models/worksiteCreation/components/globalRenovation/GlobalRenovationChoiceOperations';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { StepHabitationSimulation } from '@models/worksiteCreation/components/simulation/stepHabitationSimulation/StepHabitationSimulation';
import { StepBeneficiarySimulation } from '@models/worksiteCreation/components/simulation/stepBeneficiarySimulation/StepBeneficiarySimulation';
import { Summary } from '@models/worksiteCreation/components/simulation/summary/Summary';
import { StepSubcontractor } from '@models/worksiteCreation/components/createWorksite/subcontractors/StepSubcontractor';
import { StepDocuments } from '@models/worksiteCreation/components/createWorksite/documents/StepDocuments';

interface GlobalRenovationProps {
  setIdFormButton: Dispatch<SetStateAction<string>>;
}

function GlobalRenovation({ setIdFormButton }: GlobalRenovationProps) {
  const {
    updateSimulatorData,
    updateListOperations,
    stepActiveWorksiteCreation,
    updateStepActiveWorksiteCreation,
    updateGraphGeneral,
    conventionActive,
    worksiteDatas,
    updateGraphOperation,
    graphOperation,
    simulatorDataOperations,
    updateDisabledNextButton,
    simulatorData,
    listOperationSelected,
  } = useContext(WorksiteCreationContext);

  const [scenarios, setScenarios] = useState<
    { value: string; label: string }[]
  >([]);
  const [filteredGraph, setFilteredGraph] = useState<IGraph[]>([]);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);

  const getData = async () => {
    setIsLoadingList(true);
    const resetSimulatorData =
      !simulatorData['general.address'] || !simulatorData['general.zipcode'];
    await getGraph(
      listOperationSelected && listOperationSelected.length > 0
        ? listOperationSelected?.map((operation) => String(operation.id))
        : ['bar-th-164'],
      updateGraphGeneral,
      updateGraphOperation,
      updateSimulatorData,
      3,
      simulatorData,
      undefined,
      undefined,
      conventionActive.id,
      undefined,
      resetSimulatorData
    );

    setIsLoadingList(false);
  };

  useEffect(() => {
    if (worksiteDatas.id === 0) {
      updateStepActiveWorksiteCreation(
        StepsWorksiteCreationEnum.GLOBAL_CHOICEOPERATIONS
      );
    }

    if (graphOperation[0].name === '') getData();
  }, []);

  const getOperations = (graph: IGraph | undefined, key: string) => {
    if (graph?.key !== key) {
      if (graph?.childrens) {
        getOperations(graph?.childrens[0], key);
        getOperations(graph?.childrens[0], 'operation.scenario');
      }
    } else if (graph && graph.options) {
      if (key === 'operation.suboperations') {
        const operations = graph.options as unknown as IOperation[];
        updateListOperations(operations);
      }
      if (key === 'operation.scenario') {
        setScenarios(graph.options);
      }
    }
  };

  useEffect(() => {
    if (graphOperation[0] && graphOperation[0].tree) {
      graphOperation[0].tree.forEach((elt) => {
        getOperations(elt, 'operation.suboperations');
      });
    }
  }, [graphOperation]);

  const operations = useMemo(() => {
    const newOperations: IGraph = {
      ...graphOperation[0],
      tree: filteredGraph,
    };
    return [newOperations];
  }, [graphOperation, filteredGraph]);

  const filterNodesByUniqueKeyName = (
    nodes: IGraph[],
    keysToRemove: string[]
  ): IGraph[] => {
    const filteredNodes: IGraph[] = [];

    nodes.forEach((node) => {
      const newNode: IGraph = { ...node };

      if (!keysToRemove.some((key) => node.key.includes(key))) {
        if (node.childrens && node.childrens.length > 0) {
          newNode.childrens = filterNodesByUniqueKeyName(
            node.childrens,
            keysToRemove
          );
        }

        filteredNodes.push(newNode);
      } else if (node.childrens && node.childrens.length > 0) {
        const filteredChildrens = filterNodesByUniqueKeyName(
          node.childrens,
          keysToRemove
        );
        filteredNodes.push(...filteredChildrens);
      }
    });

    return filteredNodes;
  };

  useEffect(() => {
    if (graphOperation[0] && graphOperation[0].tree) {
      const graphArray = [...graphOperation[0].tree];

      const keysToRemove = ['scenario', 'suboperation', 'totalCost', 'noaid'];

      const filteredGraphArray: IGraph[] = filterNodesByUniqueKeyName(
        graphArray,
        keysToRemove
      );
      setFilteredGraph(filteredGraphArray);
    }
  }, [graphOperation]);

  useEffect(() => {
    if (
      simulatorDataOperations &&
      stepActiveWorksiteCreation === StepsWorksiteCreationEnum.GLOBAL_PRIMES
    ) {
      const operation = simulatorDataOperations[0];

      const btnEnable =
        operation['operation.cef_initial'] &&
        operation['operation.cef_initial'] !== '' &&
        operation['operation.cef_projet'] &&
        operation['operation.cef_projet'] !== '' &&
        operation['operation.surface'] &&
        operation['operation.surface'] !== '';

      updateDisabledNextButton(!btnEnable);
    }
  }, [simulatorDataOperations, simulatorData, stepActiveWorksiteCreation]);

  switch (stepActiveWorksiteCreation) {
    case StepsWorksiteCreationEnum.GLOBAL_CHOICEOPERATIONS:
      setIdFormButton('globalRenovationChoiceOperations');
      return (
        <GlobalRenovationChoiceOperations
          scenarios={scenarios}
          isLoadingList={isLoadingList}
        />
      );
    case StepsWorksiteCreationEnum.SIMULATION_LOGEMENT:
      return <StepHabitationSimulation />;
    case StepsWorksiteCreationEnum.SIMULATION_INFO_BENEFICIARY:
      setIdFormButton('sendBeneficiarySimulationInfos');
      return <StepBeneficiarySimulation />;
    case StepsWorksiteCreationEnum.GLOBAL_PRIMES:
      setIdFormButton('calculGisementPrimes');
      return <StepEnergyDepositAndPrimes operations={operations} />;
    case StepsWorksiteCreationEnum.WORKSITE_CREATION_SUBCONSTRACTOR:
      return <StepSubcontractor />;
    case StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF:
      return <Summary />;
    case StepsWorksiteCreationEnum.DOCUMENTS:
      return <StepDocuments />;
    default:
      return <div />;
  }
}

export { GlobalRenovation };
