import { useContext, useEffect, useState } from 'react';
import { PaymentMethod, Subscription } from '@hyperline/react-components';
import { getBillingToken } from '@models/users/apiRequests/userRequests';
import { Loader } from '@components/atomic/Loader';
import { black, blueOpx, borderGrey } from '@assets/color';
import { getNbCredits } from '@models/entities/apiRequests/entitiesRequests';
import { GlobalContext } from '@context/globalContext';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { DocumentModalCreditsPayment } from '@components/documents/DocumentModalCreditsPayment';
import { useTranslation } from 'react-i18next';

function SubscriptionAndPaymentTab() {
  const { t } = useTranslation();
  const { userView } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>('notset'); // because there is an Hyperline error if length < 5
  const [mode, setMode] = useState<'production' | 'sandbox' | undefined>(
    'production'
  );
  const [nbCredits, setNbCredits] = useState<number>(0);
  const [isModalBuyCredits, setIsModalBuyCredits] = useState(false);

  const getToken = async () => {
    await getBillingToken(setToken, setMode);
    setLoading(false);
  };

  const getCredits = async () => {
    if (userView && userView.entity_id) {
      const nbCreditsGetted = await getNbCredits(userView.entity_id);
      if (nbCreditsGetted) {
        setNbCredits(nbCreditsGetted);
      }
    }
  };

  const appearancePaymentMethod = {
    variables: {
      colorPrimary: blueOpx,
    },
    rules: {
      input: {
        color: black,
        boxShadow: 'none',
        borderStyle: `1px solid ${borderGrey}`,
      },
    },
  };

  const appearanceSubscription = {
    variables: {
      colorText: black,
    },
  };

  useEffect(() => {
    getToken();
    getCredits();
  }, []);

  return (
    <div className="w-full flex flex-col">
      <div className="flex gap-5 bg-white border border-grey rounded-default p-5 mb-4">
        <div className="mt-2">
          {t('global.electronic_signature_credits')} : {nbCredits}
        </div>
        <ButtonOpx
          label={t('global.buy_credits')}
          onClick={() => setIsModalBuyCredits(true)}
        />
      </div>
      {isModalBuyCredits && (
        <DocumentModalCreditsPayment
          setIsModalBuyCredits={setIsModalBuyCredits}
          setNbCreditsEntity={setNbCredits}
        />
      )}
      <div className="flex bg-white justify-center border border-grey rounded-default min-h-[23rem]">
        {loading || token === 'notset' ? (
          <Loader />
        ) : (
          <div className="w-full flex text-neutral-400 text-sm justify-center p-6">
            {/* Première colonne : Bouton et détails */}
            <div className="w-1/2 flex justify-center border-r border-borderGrey pr-6">
              <Subscription
                options={{ token, mode, appearance: appearanceSubscription }}
              />
            </div>
            {/* Seconde colonne : Informations de paiement */}
            <div className="w-1/2 flex justify-center pl-6">
              <PaymentMethod
                options={{ token, mode, appearance: appearancePaymentMethod }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubscriptionAndPaymentTab;
