/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InfoCircleIcon,
  PercentIcon,
  VolumeGraphIcon,
} from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { Modal } from '@components/Modal';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';
import { PARTNERS_ROUTES } from '@utils/routesUrls';
import { KpiTab } from '@components/atomic/KpiTab';
import { purple } from '@assets/color';
import { ProgressBarHorizontal } from '@components/atomic/ProgressBarHorizontal';
import {
  IControlContactType,
  IControlContactWorksiteOperationType,
} from '../utils/controlContactTypes';
import { controlContactStatusEnum } from '../utils/utlis';
import { exportControlContactCsv } from '../apiRequests/controlContactRequests';

interface IControlContactPageHeaderProps {
  data: IControlContactType;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
  exportControlContactButtonEnabled: boolean;
  setModalConfirmValidationLot: Dispatch<SetStateAction<boolean>>;
  setModalConfirmValidationControl: Dispatch<SetStateAction<boolean>>;
  lotOperations: IControlContactWorksiteOperationType[];
}

function ControlContactPageHeader({
  data,
  tab,
  setTab,
  loading,
  exportControlContactButtonEnabled,
  setModalConfirmValidationLot,
  setModalConfirmValidationControl,
  lotOperations,
}: IControlContactPageHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateDisplayModalUpgradeSubscription } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [isDeleting, setIsDeleting] = useState(false);

  const elements = useMemo(() => {
    const referenceCofrac = {
      title: t('control_contact.cofrac_reference'),
      value: data.reference_cofrac || '',
      icon: <InfoCircleIcon />,
    };
    const arrInfos = [
      {
        title: t('control_contact.card.regulatory_rate'),
        value:
          data.regulated_rate !== null && data.regulated_rate !== undefined
            ? `${(Number.parseFloat(data.regulated_rate) * 100).toFixed(0)}%`
            : '20%',
        icon: <PercentIcon />,
      },
      {
        title: t('control_contact.card.operations_to_control'),
        value: data.nb_required_operations.toString() || '0',
        icon: <VolumeGraphIcon />,
      },
      {
        title: t('control_contact.card.operations_controlled'),
        value: data.nb_controlled_operations.toString() || '0',
        icon: <VolumeGraphIcon />,
        colorIcon: purple,
      },
      {
        title: t('control_contact.card.total_operations'),
        value: data.nb_total_operations.toString() || '0',
        icon: <VolumeGraphIcon />,
      },
    ];

    if (data.reference_cofrac) {
      arrInfos.unshift(referenceCofrac);
    }

    return arrInfos.map((val) => {
      return {
        infos: [
          {
            title: val.title,
            subtitle: val.value,
          },
        ],
        icon: val.icon,
        colorIcon: val.colorIcon || '#1730BF',
      };
    });
  }, [data]);

  const exportControlContact = async () => {
    if (data) {
      setIsLoading(true);
      await exportControlContactCsv(data.id);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SubHeader
        leftPart={
          <TabsLeftPart
            titlesList={[t('control_contact.tab_suivi')]}
            onClick={(title) => setTab(title)}
            activeButton={tab}
          />
        }
        rightPart={
          <>
            {data.status === controlContactStatusEnum.LOCKED && (
              <ButtonOpx
                label={t('control_contact.btn_validate_lot')}
                disabled={lotOperations.length === 0}
                onClick={() => setModalConfirmValidationLot(true)}
              />
            )}
            {data.status === controlContactStatusEnum.CONTROLLABLE && (
              <ButtonOpx
                label={t('control_contact.btn_validate_control')}
                disabled={
                  data.nb_controlled_operations < data.nb_required_operations
                }
                onClick={() => setModalConfirmValidationControl(true)}
              />
            )}
            <ButtonOpx
              label={t('control_contact.btn_export_csv')}
              isLoading={isLoading}
              onClick={() => {
                if (user?.is_freemium) {
                  updateDisplayModalUpgradeSubscription(true);
                } else {
                  exportControlContact();
                }
              }}
            />
          </>
        }
      />
      <KpiTab infos={elements} />
      <div
        className="w-full p-6 my-6 bg-white border border-borderGrey rounded-default space-y-4"
        data-test-id="op_progress"
      >
        <ProgressBarHorizontal
          percent={`${String(
            (data.nb_controlled_operations / data.nb_required_operations) * 100
          )}%`}
          color={purple}
        />
        <div className="flex justify-between">
          <div className="flex space-x-4 items-center">
            <div className="w-2 h-2 bg-purple rounded-[100%]" />
            <p className="text-[.75rem]">
              {t('control_contact.card.operations_controlled')}
            </p>
          </div>
          <p className="text-[.875rem] font-medium">
            {t('control_contact.card.operations_controlled')}
            {' : '}
            {data.nb_controlled_operations} {t('global.out_of')}{' '}
            {data.nb_required_operations}
          </p>
        </div>
      </div>
    </>
  );
}

export { ControlContactPageHeader };
