import { useMemo, useState, useContext, useEffect } from 'react';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';

import { GlobalContext } from '@context/globalContext';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabInformations } from '@models/partners/components/informations/TabInformations';
import { TabDeposits } from '@models/partners/components/deposits/TabDeposits';
import { SubcontractorList } from '@models/partners/components/subcontractors/SubcontractorList';
import { TabConventions } from '@models/partners/components/conventions/TabConventions';
import { TabContracts } from '@models/partners/components/contracts/TabContracts';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { TabCertifications } from '@models/certifications/components/TabCertifications';

import { IPartnerSubmenu } from '@models/partners/utils/types/partnersType';
import { ROLES } from '@utils/roles';

interface ISheetPartnerOrInstallerProps {
  isInstaller?: boolean;
  isSheetPartner?: boolean;
  loadingTabs: boolean;
  partnersSubMenu: IPartnerSubmenu[];
  entityType: number | null;
}

function SheetPartnerOrInstaller({
  isInstaller,
  isSheetPartner,
  loadingTabs,
  partnersSubMenu,
  entityType,
}: ISheetPartnerOrInstallerProps): JSX.Element {
  const { id } = useParams();
  const { roleUser } = useContext(GlobalContext);

  const [sheetContent, setSheetContent] = useState<string>('informations');
  const [viewTabs, setViewTabs] = useState<IPartnerSubmenu[]>([]);
  const [titlesList, setTitlesList] = useState<string[]>([]);

  const renderTab = useMemo(() => {
    switch (sheetContent) {
      case 'informations':
        return <TabInformations entityType={entityType} />;
      case 'contracts':
        return <TabContracts />;
      case 'deposits':
        return <TabDeposits />;
      case 'conventions':
        return <TabConventions />;
      case 'subcontractors':
        return <SubcontractorList />;
      case 'certifications':
        return <TabCertifications entityIdForRequests={Number(id)} />;
      case 'business':
      default:
        return <p>{sheetContent}</p>;
    }
  }, [sheetContent, isInstaller]);

  useEffect(() => {
    let viewTabsToSet = partnersSubMenu.filter((tab: IPartnerSubmenu) =>
      ['informations', 'conventions'].includes(tab.content || '')
    );
    if (!isSheetPartner) {
      switch (roleUser) {
        case ROLES.PRODUCTION:
          viewTabsToSet = partnersSubMenu.filter((tab: IPartnerSubmenu) =>
            ['informations', 'conventions'].includes(tab.content || '')
          );
          break;
        case ROLES.GESTION:
          viewTabsToSet = partnersSubMenu.filter(
            (tab: IPartnerSubmenu) =>
              !['worksites', 'subcontractors'].includes(tab.content || '')
          );
          break;
        default:
          break;
      }
    } else if (!isInstaller) {
      viewTabsToSet = partnersSubMenu.filter(
        (tab: IPartnerSubmenu) => !['worksites'].includes(tab.content || '')
      );
    }
    setViewTabs(viewTabsToSet);
    setTitlesList(viewTabsToSet.map((tab) => tab.submenuLabel || ''));
  }, [roleUser, partnersSubMenu]);

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          !loadingTabs ? (
            <TabsLeftPart
              titlesList={titlesList}
              onClick={(title) => {
                const selectedTab = viewTabs.find(
                  (tab) => tab.submenuLabel === title
                );
                setSheetContent(selectedTab?.content || '');
              }}
            />
          ) : (
            <div>
              {[...Array(3)].map(() => (
                <LoaderSkeleton
                  key={v4()}
                  height="2.75rem"
                  addClass="mr-3 w-[7.5rem]"
                />
              ))}
            </div>
          )
        }
      />
      <div className="tabs_content">{renderTab}</div>
    </div>
  );
}

SheetPartnerOrInstaller.defaultProps = {
  isInstaller: false,
  isSheetPartner: true,
};

export { SheetPartnerOrInstaller };
