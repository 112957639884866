import { FieldValues } from 'react-hook-form';
import { ILabelValue } from '@globalTypes/globalTypes';
import { IMaterialStore } from './materialTypes';

export const prepareMaterialDataForApi = (
  formData: FieldValues,
  brand_id: number
): IMaterialStore => {
  const storedMaterial: IMaterialStore = {
    name: formData.product_name,
    brand_id,
    operations: formData.product_operations.map(
      (option: ILabelValue) => option.value
    ),
    comment: formData.product_comment,
    files: formData.product_files,
  };

  return storedMaterial;
};
