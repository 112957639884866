/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@components/Card';
import ActionButtons from '@models/worksites/components/worksiteDetails/worksiteInformations/ActionButtons';
import { IPartnerInfosGenerales } from '@models/partners/utils/types/partnersType';
import { EntityStatusEnum } from '@models/worksiteCreation/utils/enums';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import {
  getFinancialDataDetails,
  upsertPartnerFinancialData,
  upsertSettingsFinancialData,
} from '@models/partners/apiRequests/businessProviderRequest';
import { IFinancialDataType } from '@models/partners/utils/types/partnerFinancialDataType';
import { DEFAULT_FINANCIAL_DATA } from '@models/partners/utils/partnersConstants';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import {
  CheckIcon,
  DownloadIcon,
  InfoCircleIcon,
} from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx, green, textGreyInfos } from '@assets/color';
import { dateToDDMMYYY } from '@utils/format';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFinancialDataSchema } from '@utils/validationSchemas';
import { UploadCard } from '@components/atomic/UploadCard';
import { TextError } from '@components/TextError';

interface ICardFinancialDataProps {
  entityId: number | undefined;
  canModify?: boolean;
  entityToId?: number | undefined;
}

function CardFinancialData({
  entityId,
  canModify,
  entityToId,
}: ICardFinancialDataProps) {
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isUploadEmpty, setIsUploadEmpty] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [financialData, setFinancialData] = useState<IFinancialDataType>(
    DEFAULT_FINANCIAL_DATA
  );
  const [uploadFile, setUploadFile] = useState<File>();
  const [fileValid, setFileValid] = useState(false);

  const methods = useForm({
    resolver: yupResolver(getFinancialDataSchema(t)),
    defaultValues: {
      iban: financialData?.iban || '',
      bic: financialData?.bic || '',
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;

  const onUpload = (file: File) => {
    setFileValid(true);
    setUploadFile(file);
    setIsUploadEmpty(false);
  };

  const loadValidButton = () => {
    setFileValid(true);
  };

  const getData = async () => {
    setIsLoadingUpdate(true);

    if (entityId) {
      const res = await getFinancialDataDetails(entityId);

      if (res) {
        setFinancialData(res.financialData);
      }
    }

    setIsLoadingUpdate(false);
  };

  const handleUpsertData = async (formData: FieldValues) => {
    setIsUploadEmpty(false);
    if (financialData.rib_url === '' && !uploadFile) {
      setIsUploadEmpty(true);
      return false;
    }

    if (entityId) {
      const payload = new FormData();
      // Only append 'id' if it exists, and convert it to a string
      if (financialData && financialData.id !== undefined) {
        payload.append('id', String(financialData.id));
      }
      payload.append('iban', formData.iban);
      payload.append('bic', formData.bic);
      if (uploadFile) payload.append('uploadFile', uploadFile); // Add file data

      payload.append('entity_id', String(entityId));

      let res = null;
      if (entityToId) {
        // is undefined if on settings screen

        payload.append('entity_to_id', String(entityToId));
        res = await upsertPartnerFinancialData(payload);
      } else {
        res = await upsertSettingsFinancialData(entityId, payload);
      }

      if (res) {
        getData();
      }
      setIsUploadEmpty(false);
    }
    setIsEditMode(false);
    return true;
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setIsUploadEmpty(false);
    reset(); // Reset the form to its initial values
  };

  useEffect(() => {
    getData();
  }, [entityId]);

  useEffect(() => {
    if (financialData) {
      reset({
        iban: financialData.iban || '', // Fallback to empty string
        bic: financialData.bic || '', // Fallback to empty string
      });
    }
  }, [financialData, reset]);

  return (
    <Card
      title={t('partners.financial_data.title')}
      dataTestId="worksite_beneficiary"
      actionButtons={
        canModify ? (
          <ActionButtons
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            onSubmit={handleSubmit(handleUpsertData)}
            isLoading={isLoadingUpdate}
            disabled={isLoadingUpdate}
            onCancel={handleCancel}
          />
        ) : undefined
      }
    >
      <>
        <FormProvider {...methods}>
          <form id="form-financal_data">
            {isEditMode ? (
              <>
                <div className="flex space-x-4 justify-end">
                  <div className="w-full">
                    <div className="grid gap-4 grid grid-cols-2">
                      <InputText
                        id="iban"
                        name="iban"
                        placeholder={t('partners.financial_data.IBAN') || ''}
                        required
                        defaultValue={financialData?.iban || ''}
                        data-test-id="iban_input"
                      />

                      <InputText
                        id="bic"
                        name="bic"
                        placeholder={t('partners.financial_data.BIC')}
                        required
                        defaultValue={financialData?.bic || ''}
                        data-test-id="bic_input"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid gap-4 grid grid-cols-2">
                  <div className="space-y-4">
                    <div className=" mt-4">
                      <div className="text-textGrey text-[.75rem] leading-[.75rem] mb-2">
                        {t('partners.financial_data.RIB')}
                      </div>
                      <UploadCard
                        fileType=".pdf"
                        onUpload={onUpload}
                        isUploadFile={!uploadFile}
                        fileName={
                          uploadFile
                            ? uploadFile.name
                            : t('partners.financial_data.RIB')
                        }
                        loadValidButton={loadValidButton}
                      />
                    </div>
                    {isUploadEmpty && (
                      <TextError
                        errorMessage={t('forms.required_error_message')}
                        addClass="!mt-0 p-[.5rem] text-red text-sm"
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div className="w-full">
                  {isLoadingUpdate ? (
                    <>
                      <LoaderSkeleton
                        height="1.25rem"
                        addClass="w-[6rem] mb-1"
                      />
                      <LoaderSkeleton height=".75rem" addClass="w-[8rem]" />
                    </>
                  ) : (
                    <>
                      <div className="grid gap-4 grid grid-cols-2">
                        <div>
                          <InfosWithIcon
                            infos={[
                              {
                                title: t('partners.financial_data.IBAN'),
                                subtitle: `${financialData?.iban || '-'}`,
                              },
                            ]}
                            icon={<InfoCircleIcon />}
                          />
                        </div>
                        <div className="flex items-start space-x-[1rem]">
                          <div className="min-w-[2rem]" />
                          <div className="flex-col items-center w-full space-y-[.5rem]">
                            <p
                              className="text-[.75rem] leading-[.75rem] "
                              style={{ color: textGreyInfos }}
                            >
                              {t('partners.financial_data.BIC')}
                            </p>
                            <div className="flex space-x-12 items-center font-[500] text-[.875rem] leading-[1rem] false">
                              {' '}
                              {`${financialData?.bic || '-'}`}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-4 flex">
                        <div className="w-[50%]">
                          <InfosWithIcon
                            infos={[
                              {
                                title: t('partners.financial_data.RIB'),
                                subtitle: `${
                                  financialData?.rib_url
                                    ? dateToDDMMYYY(financialData?.created_at)
                                    : '-'
                                }`,
                              },
                            ]}
                            icon={
                              financialData?.rib_url ? (
                                <CheckIcon />
                              ) : (
                                <InfoCircleIcon />
                              )
                            }
                            colorIcon={financialData?.rib_url ? green : blueOpx}
                          />
                        </div>
                        {financialData?.rib_url && (
                          <div className="flex items-center space-x-[.5rem]">
                            <a
                              href={financialData?.rib_url as string}
                              download
                              target="_blank"
                              rel="noreferrer"
                              data-test-id="download_button"
                            >
                              <ColorCube
                                size="1.5rem"
                                numberOrIcon={<DownloadIcon />}
                                color={green}
                                opacity
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </form>
        </FormProvider>
      </>
    </Card>
  );
}

CardFinancialData.defaultProps = {
  canModify: true,
  entityToId: undefined,
};

export { CardFinancialData };
