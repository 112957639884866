import { ExpandIcon, CollapseIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import moment from 'moment';
import 'moment/locale/fr';
import { useContext, useState } from 'react';
import ImageContainer from '@components/atomic/ImageContainer';

interface OneMessageDetailsRequestProps {
  background: boolean;
  border: boolean;
  message: string;
  date: string;
  senderName: string;
  senderImage: string | null;
  isMail: boolean;
}

function OneMessageDetailsRequest({
  background,
  border,
  message,
  date,
  senderName,
  senderImage,
  isMail,
}: OneMessageDetailsRequestProps) {
  // const { updateDocumentActive } = useContext(GlobalContext);
  const { updateForceOpenDocumentsTab } = useContext(WorksitesContext);
  const [expandMail, setExpandMail] = useState(false);

  const dateMessage = moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM');
  const openDocsTab = async () => {
    updateForceOpenDocumentsTab(true);

    // TODO: to complete in OPX-2465
    // getLinkedFile(fileId).then((res) => {
    //   if (res.data) updateDocumentActive({ list: '', document: res.data });
    // });
  };

  return (
    <div className="relative">
      {!expandMail && (
        <button
          type="button"
          onClick={openDocsTab}
          className="bg-white rounded-default w-full pt-0"
        >
          <div
            className={`p-[1rem] w-full ${
              background ? 'bg-backgroundBody' : ''
            }  ${border ? 'border border-borderGrey' : ''} rounded-[4px]`}
          >
            <div className="flex space-x-[.5rem]  w-full">
              {senderImage === null ? (
                <div className="h-[1.5rem] min-h-[1.5rem] w-[1.5rem]  min-w-[1.5rem] bg-borderGrey rounded-[4px]" />
              ) : (
                <img
                  className="h-[1.5rem] min-h-[1.5rem] w-[1.5rem]  min-w-[1.5rem] rounded-[4px]"
                  src={senderImage}
                  alt="user"
                />
              )}
              <div className="w-full">
                <div className="flex justify-between w-full  items-center">
                  <p className="text-[.813rem] font-medium">{senderName}</p>
                  <p className="text-[.75rem] text-textGrey">
                    {isMail ? (
                      <ColorCube
                        size="1.5rem"
                        numberOrIcon={<ExpandIcon />}
                        color={blueOpx}
                        opacity
                        onClick={() => {
                          setExpandMail(true);
                        }}
                      />
                    ) : (
                      dateMessage
                    )}
                  </p>
                </div>
                <div className="text-[.75rem] text-justify pt-[1.25rem] whitespace-pre-wrap">
                  {message}
                </div>
              </div>
            </div>
          </div>
        </button>
      )}
      {expandMail && (
        <div
          className="fixed top-[60%] left-10/12 transform -translate-x-[100%] -translate-y-1/2 
        bg-white p-6 rounded-default shadow-lg z-50 w-[50vw] max-h-[80vh] overflow-y-auto
         transition-all duration-300 ease-in-out border border-borderGrey"
        >
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-2">
              <ImageContainer
                imageUrl={senderImage}
                width="2rem"
                height="2rem"
                addClass="rounded-[4px]"
              />
              <p className="text-[.813rem] font-medium">{senderName}</p>
            </div>
            <ColorCube
              size="1.5rem"
              numberOrIcon={<CollapseIcon />}
              color={blueOpx}
              opacity
              onClick={() => {
                setExpandMail(false);
              }}
            />
          </div>
          <div className="whitespace-pre-wrap px-[5rem] py-[3rem] text-[0.875rem]">
            <p>{message}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export { OneMessageDetailsRequest };
