/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { CardInformations } from '@components/informations/CardInformations';
import { IPartnerInfosGenerales } from '@models/partners/utils/types/partnersType';

import {
  ContactPageIcon,
  InfoCircleIcon,
  MailIcon,
  PhoneIcon,
  PositionIcon,
} from '@assets/images/svgComponents';
import { IPartnerUserType } from '@models/auth/utils/types';
import { EntityStatusEnum } from '@models/worksiteCreation/utils/enums';
import {
  DEFAULT_GENERAL_INFOS,
  DEFAULT_PARTNER_USER_INFOS,
} from '@models/partners/utils/partnersConstants';
import { HeaderContext } from '@context/headerContext';
import { darkBlue } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { ENTITY_TYPES } from '@utils/roles';
import {
  editPartnerInfo,
  getPartnerByTypeInfos,
} from '@models/partners/apiRequests/partnersRequest';
import { PartnerInfoSection } from '@utils/utils';
import { GlobalContext } from '@context/globalContext';
import { CardCofrac } from './informations/cards/CardCofrac';
import { CardFinancialData } from '../informations/cards/CardFinancialData';

function TabControlOfficeInfos() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { userView } = useContext(GlobalContext);
  const { updateTitleHeader, updateDisplayBackButtonHeader, updateTagHeader } =
    useContext(HeaderContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [entityDetails, setEntityDetails] = useState<IPartnerInfosGenerales>(
    DEFAULT_GENERAL_INFOS.info_generales
  );
  const [userDetails, setUserDetails] = useState<IPartnerUserType>(
    DEFAULT_PARTNER_USER_INFOS
  );
  const methods = useForm();
  const {
    formState: { errors },
    reset,
  } = methods;

  const getData = async () => {
    setLoading(true);

    const res = await getPartnerByTypeInfos({
      entity_id: Number(id),
      entity_type: ENTITY_TYPES.BUREAUX_CONTROLE,
    });

    if (res) {
      setEntityDetails(res.general.entityDetails);
      setUserDetails(res.general.userDetails);
      updateTitleHeader(res.general.entityDetails.company_name || '');
      updateTagHeader(
        <Tag color={darkBlue} label={t('partners.control_office.name')} />
      );
    }

    setLoading(false);
  };

  const handleEditPartnerInfo = async (
    section: string,
    formData: FieldValues
  ) => {
    const payload =
      section === PartnerInfoSection.Entity
        ? {
            entity_type: ENTITY_TYPES.BUREAUX_CONTROLE,
            entityInfo: {
              address: formData.partner_address,
              additional_address: formData.additional_address,
              zipcode: formData.partner_address_zipcode,
              city: formData.partner_address_city,
              contact_email: formData.contact_email,
              phone_number: formData.entity_phone_number,
            },
          }
        : {
            entity_type: ENTITY_TYPES.BUREAUX_CONTROLE,
            userInfo: {
              lastname: formData.partner_lastname,
              firstname: formData.partner_firstname,
              email: formData.email,
              phone_number: formData.phone_number,
            },
          };
    await editPartnerInfo(Number(id), userDetails.id, payload, getData);
  };

  useEffect(() => {
    updateDisplayBackButtonHeader(true);
    updateTitleHeader('loading');
    getData();
  }, [id]);

  return (
    <div className="py-[0.5rem] grid gap-x-0 md:gap-x-[1.5%] gap-y-4 grid-cols-1 md:grid-cols-[32.5%_66%]">
      <div>
        {/* GENERAL INFOS */}
        <CardInformations
          canModify={entityDetails?.status === EntityStatusEnum.GHOST}
          title={t('partners.general_infos')}
          data={{
            status: entityDetails?.active,
            userStatus: userDetails?.status,
            rows: [
              [
                {
                  name: 'company_name',
                  title: t('partners.company_name'),
                  value: entityDetails?.company_name,
                  icon: <InfoCircleIcon />,
                },
                { empty: true },
                {
                  name: 'siret',
                  title: t('partners.siret'),
                  value: entityDetails?.siret,
                },
                {
                  name: 'siren',
                  title: t('partners.siren'),
                  value: entityDetails?.siren,
                },
              ],
              [
                {
                  name: 'postal_address',
                  title: t('partners.postal_address'),
                  value: {
                    address: entityDetails?.address,
                    postal_code: entityDetails?.zipcode,
                    city: entityDetails?.city,
                    country: entityDetails?.country,
                  },
                  icon: <PositionIcon />,
                  isEditable: true,
                  type: 'address',
                },
                {
                  name: 'partner_address',
                  title: t('partners.address'),
                  value: entityDetails?.address,
                  isEditable: true,
                  required: true,
                },
                {
                  name: 'additional_address',
                  title: t('partners.additional_address'),
                  value: entityDetails?.additional_address,
                  isEditable: true,
                },
                {
                  name: 'partner_address_zipcode',
                  title: t('partners.zipcode'),
                  value: entityDetails?.zipcode,
                  isEditable: true,
                  required: true,
                  type: 'number',
                },
                {
                  name: 'partner_address_city',
                  title: t('partners.city'),
                  value: entityDetails?.city,
                  isEditable: true,
                  required: true,
                },
              ],
              [
                {
                  name: 'entity_phone_number',
                  title: t('forms.phone.placeholder'),
                  value: entityDetails?.phone_number,
                  type: 'phone',
                  isEditable: true,
                  icon: <PhoneIcon />,
                },
              ],
              [
                {
                  name: 'contact_email',
                  title: t('partners.email_address'),
                  value: entityDetails?.contact_email,
                  type: 'email',
                  isEditable: true,
                  icon: <MailIcon />,
                },
              ],
            ],
          }}
          loading={loading}
          onEdit={(formData: FieldValues) =>
            handleEditPartnerInfo(PartnerInfoSection.Entity, formData)
          }
          dataTestId="informations_card"
        />
        {/* CONTACT */}
        <div className="mt-6">
          <CardInformations
            canModify={entityDetails?.status === EntityStatusEnum.GHOST}
            title={t('partners.business_provider.contact.title')}
            data={{
              status: entityDetails?.active,
              userStatus: userDetails?.status,
              rows: [
                [
                  {
                    name: 'partner_firstname_lastname',
                    title: t('partners.business_provider.firstname_lastname'),
                    value: userDetails?.firstname
                      ? `${userDetails?.firstname} ${userDetails?.lastname}`
                      : '-',
                    icon: <ContactPageIcon />,
                    isEditable: true,
                  },
                  {
                    name: 'partner_firstname',
                    title: t('partners.firstname'),
                    value: userDetails?.firstname,
                    isEditable: true,
                    required: true,
                  },
                  {
                    name: 'partner_lastname',
                    title: t('partners.lastname'),
                    value: userDetails?.lastname,
                    isEditable: true,
                    required: true,
                  },
                  {
                    name: 'phone_number',
                    title: t('forms.phone.placeholder'),
                    value: userDetails?.phone_number,
                    type: 'phone',
                    isEditable: true,
                  },
                  {
                    name: 'email',
                    title: t('partners.email_address'),
                    value: userDetails?.email,
                    type: 'email',
                    isEditable: true,
                    required: true,
                  },
                ],
              ],
            }}
            loading={loading}
            onEdit={(formData: FieldValues) =>
              handleEditPartnerInfo(PartnerInfoSection.Contact, formData)
            }
            dataTestId="contact_card"
          />
        </div>
        {/* FINANCIAL DATA */}
        <div className="mt-6">
          <CardFinancialData
            entityId={entityDetails.id} // entity of control office
            entityToId={Number(userView?.entity_id)} // entity of user connected
            canModify={entityDetails?.status === EntityStatusEnum.GHOST}
          />
        </div>
      </div>
      {/* COFRAC */}
      <CardCofrac loading={loading} />
    </div>
  );
}

export { TabControlOfficeInfos };
