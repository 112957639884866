import { useTranslation } from 'react-i18next';
import { textGrey, red, green, orange } from '@assets/color';
import {
  IWorksiteDetailsHeader,
  SetOperationAccessibleType,
} from '@models/worksites/utils/worksitesTypes';
import { Tag } from '@components/atomic/Tag';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { numFormatSpace } from '@utils/functions';
import { GlobalContext } from '@context/globalContext';
import { getTagValue } from '@models/deposits/utils/depositHelper';
import { getCofracTag } from '@models/cofrac/utils/utils';
import { COFRAC_STATUS } from '@models/cofrac/utils/cofracConstants';
import { dateToDDMMYYY, formatWord } from '@utils/format';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { WORKSITE_OPERATION_STATUS } from '@models/worksites/utils/enums';
import { linkedWorksitesOperationsStatus } from '@models/controlContact/utils/utlis';
import { CofracTagInfos } from '@components/atomic/CofracTagInfos';
import { ICofracLotsOperations } from '@models/cofrac/utils/cofracTypes';

type IWorksiteDetailsOperationCardHeaderProps = {
  infos: IWorksiteDetailsHeader & {
    custom_price_precaire?: number | null;
    custom_price_classique?: number | null;
  };
  detailsIsActive: boolean;
  lastUpdateDate: string;
  stepActive?: number;
  setHeaderHeight: Dispatch<SetStateAction<number>>;
  setDetailsIsActive: Dispatch<SetStateAction<boolean>>;
  operationInvalidFixed?: SetOperationAccessibleType | null;
  handleChangeProjectedEnd?: (id: number, date: string) => void;
  cofracLotOperations?: ICofracLotsOperations[];
};

function WorksiteDetailsOperationCardHeader({
  infos,
  stepActive,
  detailsIsActive,
  lastUpdateDate,
  setHeaderHeight,
  setDetailsIsActive,
  operationInvalidFixed,
  handleChangeProjectedEnd,
  cofracLotOperations,
}: IWorksiteDetailsOperationCardHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const cardHeader = useRef<HTMLDivElement>(null);
  const { globalEnum } = useContext(GlobalContext);

  const resize = () => {
    if (cardHeader && cardHeader.current) {
      setHeaderHeight(cardHeader.current.clientHeight);
    }
  };

  const statusTag = () => {
    if (infos.contact_control_status) {
      const tagText =
        globalEnum.worksite_operation_contact_control_status[
          infos.contact_control_status
        ];
      switch (infos.contact_control_status) {
        case linkedWorksitesOperationsStatus.CONTROLABLE:
          return null;
        case linkedWorksitesOperationsStatus.NON_CONTROLEE:
          return <Tag color={textGrey} label={tagText} />;
        case linkedWorksitesOperationsStatus.SATISFAISANT:
          return <Tag color={green} label={tagText} />;
        case linkedWorksitesOperationsStatus.A_RAPPELER:
          return <Tag color={orange} label={tagText} />;
        case linkedWorksitesOperationsStatus.INSUFFISANT:
          return <Tag color={red} label={tagText} />;
        default:
          return null;
      }
    }
    return null;
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();
  }, [detailsIsActive]);

  const operationStatus = useMemo(() => {
    let key = 'contract_lot_status';

    if (operationInvalidFixed) {
      if (operationInvalidFixed['cofrac_lots_operations.id'])
        key = 'cofrac_lots_operations_status';
      if (operationInvalidFixed['deposits_operations.id'])
        key = 'deposits_operations_status';
    }

    return globalEnum[key][operationInvalidFixed?.status || 0];
  }, [operationInvalidFixed]);

  const operationTag = useMemo(
    () => getTagValue(operationStatus, t, true, true),
    [operationStatus]
  );

  const cofracTag = useMemo(() => {
    let status = 0;
    if (
      operationInvalidFixed &&
      operationInvalidFixed['cofrac_lots_operations.id']
    ) {
      status = Number(operationInvalidFixed?.cofrac_lot?.status || 0);
    }
    return getCofracTag(status, true);
  }, [operationInvalidFixed]);

  const shouldShowDatePicker = stepActive
    ? stepActive < WORKSITE_OPERATION_STATUS.PostChecks
    : false;

  const formattedProjectedEndDate = dateToDDMMYYY(infos.projectedEnd);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="w-full py-[1.5rem]"
      ref={cardHeader}
      onClick={() => {
        if (detailsIsActive) {
          setDetailsIsActive(false);
        }
      }}
    >
      <div className="flex items-start justify-between pb-[.875rem]">
        <div className="w-[85%] flex flex-col">
          <div className="flex items-start space-x-[1rem] w-full">
            <p
              className="text-[1.25rem] leading-[2rem] font-[500] text-left"
              data-test-id="op_title"
            >
              {infos.title}
            </p>
            <div className="flex items-center space-x-[1rem] mt-[.375rem]">
              {infos.slug && (
                <div className="flex gap-2">
                  <Tag
                    color={textGrey}
                    label={infos.slug}
                    dataTestId="op_slug"
                  />
                  {cofracLotOperations && cofracLotOperations.length > 0 && (
                    <CofracTagInfos
                      cofracLotOperation={cofracLotOperations[0]}
                    />
                  )}
                </div>
              )}
              {infos.da && infos.da !== 0 && (
                <Tag
                  color={red}
                  label={`${infos.da} ${
                    infos.da && infos.da > 1
                      ? t('worksites.das')
                      : t('worksites.da')
                  }`}
                />
              )}
              {statusTag()}
              {operationInvalidFixed && (
                <>
                  {Number(operationInvalidFixed?.cofrac_lot?.status) ===
                    COFRAC_STATUS.INSATISFAISANT && (
                    <Tag color={cofracTag.color} label={cofracTag.text} />
                  )}
                  {formatWord(operationStatus).includes('insatisfaisant') && (
                    <Tag
                      color={operationTag.tagColor}
                      label={operationTag.tagText}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          {infos.id && (
            <div className="flex w-full items-center space-x-[1rem] mt-4">
              <p className="text-[.875rem] whitespace-nowrap">
                {t('worksites.projected_end_date')} :
              </p>
              {shouldShowDatePicker && handleChangeProjectedEnd ? (
                <DatePicker
                  required
                  onChangeDate={(e) =>
                    handleChangeProjectedEnd(infos.id as number, e)
                  }
                  defaultDate={formattedProjectedEndDate}
                  addClass="w-[20rem]"
                />
              ) : (
                <p
                  className="text-[.875rem] whitespace-nowrap"
                  data-test-id="op_projected_end"
                >
                  {formattedProjectedEndDate}
                </p>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col mt-[.125rem]">
          <div className="flex items-center space-x-[1rem]">
            {infos.haveCdp && (
              <p
                className="text-[.875rem] whitespace-nowrap"
                data-test-id="bonus_type"
              >
                Coup de pouce
              </p>
            )}
            {infos.kwhc && (
              <p
                className="text-[.875rem] whitespace-nowrap"
                data-test-id="op_kwhc"
              >
                {numFormatSpace(Number(infos.kwhc) / 1000)} MWhc
              </p>
            )}
            <p
              className="text-[1rem] font-[500] whitespace-nowrap"
              data-test-id="op_total_bonus"
            >
              {infos.totalAmount} €
            </p>
          </div>

          <div className="mt-8">
            {infos.custom_price_precaire !== null &&
              infos.custom_price_precaire !== undefined && (
                <p className="text-[.875rem] whitespace-nowrap">
                  {t('worksites.custom_price_precaire')} :{' '}
                  {infos.custom_price_precaire.toString().replace('.', ',')}{' '}
                  €/MWhc
                </p>
              )}
            {infos.custom_price_classique !== null &&
              infos.custom_price_classique !== undefined && (
                <p className="text-[.875rem] whitespace-nowrap">
                  {t('worksites.custom_price_classique')} :{' '}
                  {infos.custom_price_classique.toString().replace('.', ',')}{' '}
                  €/MWhc
                </p>
              )}
          </div>
        </div>
      </div>
      {detailsIsActive && (
        <div
          className="text-right text-[.875rem] h-5"
          style={{ color: textGrey }}
          data-test-id="op_last_update_header"
        >
          {lastUpdateDate}
        </div>
      )}
      {formatWord(operationStatus).includes('insatisfaisant') && (
        <div className="text-red text-start">
          {operationInvalidFixed?.cofrac_lot
            ? t('worksites.operation.ko_message_cofrac')
            : t('worksites.operation.ko_message')}
        </div>
      )}
    </div>
  );
}

export { WorksiteDetailsOperationCardHeader };

WorksiteDetailsOperationCardHeader.defaultProps = {
  stepActive: undefined,
  operationInvalidFixed: undefined,
  handleChangeProjectedEnd: undefined,
  cofracLotOperations: undefined,
};
