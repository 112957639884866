import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import { Modal } from '@components/Modal';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ENTITY_TYPES } from '@utils/roles';
import { GlobalContext } from '@context/globalContext';
import { ILabelValue } from '@globalTypes/globalTypes';
import { SETTINGS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { useNavigate } from 'react-router-dom';
import { fetchConventions } from '@models/settings/utils/utils';
import { Loader } from '@components/atomic/Loader';
import { createIframe } from '../../apiRequests/settingsRequests';

interface INewIframeModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

interface IFormValues {
  name: string;
  convention_id: string | null;
}

function NewIframeModal({ setIsOpen }: INewIframeModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userView } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [conventionOptions, setConventionOptions] = useState<ILabelValue[]>([]);
  const [isLoadingConventions, setIsLoadingConventions] =
    useState<boolean>(false);

  // Déterminer si l'utilisateur est un installateur
  const isInstaller = userView?.entity_type === ENTITY_TYPES.INSTALLATEUR;

  const methods = useForm<IFormValues>({
    defaultValues: {
      name: '',
      convention_id: null,
    },
  });

  const { handleSubmit, reset, setValue, watch } = methods;

  // Fonction pour créer une nouvelle iframe
  const onSubmit = async (data: IFormValues) => {
    setIsSubmitting(true);

    const conventionId = data.convention_id ? Number(data.convention_id) : null;
    const result = await createIframe(
      {
        name: data.name,
        convention_id: conventionId,
      },
      setIsSubmitting
    );

    if (result) {
      setIsOpen(false);
      reset();
      navigate(SETTINGS_ROUTES_WITH_ID(result.id).SETTINGS_IFRAME_SETTING);
    }

    setIsSubmitting(false);
  };

  // Réinitialiser le formulaire quand la modal se ferme
  const handleCloseModal = () => {
    setIsOpen(false);
    reset();
  };

  useEffect(() => {
    // Charger les conventions si l'utilisateur est un installateur et si la modal est ouverte
    if (isInstaller) {
      fetchConventions(
        isInstaller,
        userView,
        setConventionOptions,
        setIsLoadingConventions
      );
    }
  }, [isInstaller]);

  return (
    <Modal
      setIsModal={setIsOpen}
      title={t('settings.iframes.new_iframe')}
      onConfirmClick={handleSubmit(onSubmit)}
      onClickCancel={handleCloseModal}
      textBtnConfirm={t('buttons.confirm') || ''}
      btnCancel
      isLoading={isSubmitting}
      backgroundTransparent
      btnConfirmDisabled={
        isSubmitting ||
        !watch('name') ||
        (!watch('convention_id') && isInstaller)
      }
    >
      <FormProvider {...methods}>
        <div className="flex flex-col gap-4 pt-6">
          {isInstaller && isLoadingConventions ? (
            <Loader />
          ) : (
            <>
              <InputText
                name="name"
                id="name"
                placeholder={t('settings.iframes.name_placeholder') || ''}
                label={t('settings.iframes.iframe_name')}
                required
              />
              {isInstaller && (
                <InputSelect
                  label={t('settings.iframes.convention') || ''}
                  dataLabelValue={conventionOptions}
                  placeholder={t('settings.iframes.select_convention') || ''}
                  defaultSelected={
                    conventionOptions.length === 1
                      ? conventionOptions[0].label
                      : undefined
                  }
                  onSelectLabelValue={(value) => {
                    setValue('convention_id', value.value);
                  }}
                  required
                />
              )}
            </>
          )}
        </div>
      </FormProvider>
    </Modal>
  );
}

export default NewIframeModal;
