import { textGrey } from '@assets/color';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Tag } from '@components/atomic/Tag';
import { WORKSITES_ROUTES_WITH_ID } from '@utils/routesUrls';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { DOCUMENT_STATUS_ENUM } from '@utils/utils';
import { saveLinkedFileCheckpointsInProgress } from '../apiRequests/checkpointsRequests';
import { LinkedFileCheckpointContext } from '../utils/linkedFileCheckpointContext';

function HeaderCheckpoints() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { worksiteCheckpoints, linkedFiles, worksite } = useContext(
    LinkedFileCheckpointContext
  );

  const [isLoading, setIsLoading] = useState(false);

  const worksiteRedirection = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('tab_/checkpoints')) {
        localStorage.removeItem(key);
      }
    });

    navigate(WORKSITES_ROUTES_WITH_ID(worksite.id).WORKSITES_VIEW);
  };

  const handleSaveAndQuit = async () => {
    setIsLoading(true);

    const responsesInProgress = worksiteCheckpoints.filter((value) => {
      const linkedFile = linkedFiles.find(
        (file) => file.id === value.document_id
      );
      return linkedFile?.status === DOCUMENT_STATUS_ENUM.PENDING;
    });

    const response = await saveLinkedFileCheckpointsInProgress(
      worksite.id,
      responsesInProgress
    );

    setIsLoading(false);

    if (response.data) {
      worksiteRedirection();
    }
  };

  return (
    <header
      className="flex fixed z-50 bg-white h-[5.5rem] w-screen justify-between items-center px-6 border-b 
        border-b-borderGrey  right-0 top-0 z-[52] transition-all duration-500 ease-in-out "
    >
      <div className="flex items-center gap-4">
        <h2 className="text-[1.688rem] font-medium">
          {t('checkpoints.header_title')}
        </h2>
        <Tag color={textGrey} label={worksite.reference} />
      </div>
      <div className="flex gap-3">
        <ButtonOpx
          label={t('buttons.cancel')}
          type="secondary"
          onClick={worksiteRedirection}
        />
        <ButtonOpx
          label={t('buttons.save_and_quit')}
          onClick={handleSaveAndQuit}
          isLoading={isLoading}
        />
      </div>
    </header>
  );
}

export { HeaderCheckpoints };
