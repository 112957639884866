import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { FormProvider, useForm } from 'react-hook-form';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { VolumeRow } from '@models/contractCreation/components/steps/volumes/VolumeRow';
import { InputText } from '@components/atomic/inputs/InputText';
import { numberRegex } from '@utils/regex';
import { useTranslation } from 'react-i18next';
import { placeholderExample } from '@utils/utils';

function StepVolumesCee() {
  const { t } = useTranslation();
  const placeholders = placeholderExample(t);
  const methods = useForm();
  const { setError, clearErrors, watch } = methods;
  const valuesForm = watch();

  const [volumeMaxError, setVolumeMaxError] = useState<boolean>(false);

  const {
    changeStep,
    contractType,
    updatePenalties,
    penalties,
    deliveryVolume,
    updateDeliveryVolume,
    volumeMin,
    volumeMax,
  } = useContext(ContractCreationContext);

  const setPenaltiesValues = (key: 'classic' | 'precaire', value: string) => {
    updatePenalties((prevState) => ({ ...prevState, [key]: value }));
  };

  const isMandant = useMemo(() => {
    return [ContractTypes.MANDAT, ContractTypes.MANDAT_NON_DEPOSANT].includes(
      contractType
    );
  }, [contractType]);

  const isFieldValid = (value: string, required = false) => {
    if (isMandant || required)
      return value !== '' && numberRegex.test(value.replace(',', '.'));

    return value === '' || numberRegex.test(value.replace(',', '.'));
  };

  const isValid = useMemo(() => {
    // Check if maximum volume fields are set to 0.00
    const allMaxVolumesAreZero = [volumeMax.precaire, volumeMax.classic]
      .map((val) => parseFloat(val.replace(',', '.')))
      .every((val) => val === 0);

    const volumesOk =
      !allMaxVolumesAreZero &&
      isFieldValid(volumeMin.precaire, true) &&
      isFieldValid(volumeMin.classic, true) &&
      isFieldValid(volumeMax.precaire, true) &&
      isFieldValid(volumeMax.classic, true);

    const penaltiesOk =
      contractType === ContractTypes.MANDAT
        ? penalties.classic.trim() !== '' &&
          penalties.precaire.trim() !== '' &&
          numberRegex.test(penalties.classic.replace(',', '.')) &&
          numberRegex.test(penalties.precaire.replace(',', '.'))
        : true;

    const deliveryOk =
      contractType === ContractTypes.MANDAT_NON_DEPOSANT
        ? deliveryVolume.trim() !== '' &&
          Number(deliveryVolume.replace(',', '.')) >= 0
        : true;

    return volumesOk && penaltiesOk && deliveryOk;
  }, [penalties, volumeMax, volumeMin, deliveryVolume, isMandant]);

  const onNextStep = () => {
    const isConvention = [
      ContractTypes.TRIPARTITE,
      ContractTypes.CONVENTION,
    ].includes(contractType);

    if (isConvention) {
      const errorMessage = `${t('contract.volumes_error')}`;
      if (volumeMaxError) {
        setError('volume_max_precaire', { message: errorMessage });
        setError('volume_max_classique', { message: errorMessage });
      }
    }

    if (!volumeMaxError) changeStep('next');
  };

  const precaireEmpty = !isFieldValid(
    valuesForm.volume_max_precaire || '',
    true
  );
  const classiqueEmpty = !isFieldValid(
    valuesForm.volume_max_classique || '',
    true
  );

  useEffect(() => {
    if (precaireEmpty && classiqueEmpty) {
      setVolumeMaxError(true);
    } else {
      setVolumeMaxError(false);
      clearErrors('volume_max_classique');
      clearErrors('volume_max_precaire');
    }
  }, [precaireEmpty, classiqueEmpty]);

  return (
    <Card
      title={t('contract.volumes_cee')}
      actionButtons={
        <div className="flex gap-3">
          <ButtonOpx
            type="secondary"
            label={`${t('global.back')}`}
            onClick={() => changeStep('back')}
          />
          <ButtonOpx
            type="primary"
            label={`${t('buttons.next')}`}
            onClick={onNextStep}
            disabled={!isValid}
            dataTestId="button_next"
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <form className="flex flex-col gap-3">
          <div className="flex flex-wrap">
            <div className="flex flex-col gap-3 w-full mb-[1rem]">
              <div className="mb-[1rem]">
                <p className="font-medium">{t('contract.volumes_cee_min')}</p>
                <p className="text-[0.875rem]">
                  {t('contract.volumes_cee_min_info_required')}
                </p>
              </div>
              <VolumeRow type="min_classique" required />
              <VolumeRow type="min_precaire" required />
            </div>
            <div className="flex flex-col gap-3 w-full mb-[1rem]">
              <div className="my-[1rem]">
                <p className="font-medium">{t('contract.volumes_cee_max')}</p>
                <p className="text-[0.875rem]">
                  {t('contract.volumes_cee_max_info_required')}
                </p>
              </div>
              <VolumeRow type="max_classique" required />
              <VolumeRow type="max_precaire" required />
            </div>
            {contractType === ContractTypes.MANDAT_NON_DEPOSANT && (
              <div className="w-full">
                <div className="my-[1rem]">
                  <p className="font-medium">{t('contract.delivery_volume')}</p>
                  <p className="text-[0.875rem]">
                    {t('contract.delivery_volume_info')}
                  </p>
                </div>
                <div className="w-full">
                  <InputText
                    id="delivery"
                    name="delivery"
                    placeholder="5"
                    label={`${t('contract.volumes_cee_min')} (MWhc)`}
                    onChange={(e) =>
                      updateDeliveryVolume(String(e).replace(',', '.'))
                    }
                    value={deliveryVolume.replace(',', '.')}
                    required
                    valid={Number(deliveryVolume.replace(',', '.')) > 0}
                    error={!numberRegex.test(deliveryVolume.replace(',', '.'))}
                  />
                </div>
              </div>
            )}
            {contractType === ContractTypes.MANDAT && (
              <div className="w-full">
                <div className="my-[1rem]">
                  <p className="font-medium">{t('contract.penalties')}</p>
                  <p className="text-[0.875rem]">
                    {t('contract.penalties_info')}
                  </p>
                </div>
                <div className="flex gap-3 w-full">
                  <InputText
                    id="penaltiesClassic"
                    name="penaltiesClassic"
                    placeholder={placeholders.PRICE}
                    label={`${t(
                      'contract.tab_info_general.amount_penalty_classique'
                    )}`}
                    onChange={(e) => setPenaltiesValues('classic', String(e))}
                    value={penalties.classic}
                    error={
                      !numberRegex.test(penalties.classic.replace(',', '.'))
                    }
                    valid={Number(penalties.classic.replace(',', '.')) >= 0}
                    required
                  />
                  <InputText
                    id="penaltiesPrecaire"
                    name="penaltiesPrecaire"
                    placeholder={placeholders.PRICE}
                    label={`${t(
                      'contract.tab_info_general.amount_penalty_precaire'
                    )}`}
                    onChange={(e) => setPenaltiesValues('precaire', String(e))}
                    value={penalties.precaire}
                    error={
                      !numberRegex.test(penalties.precaire.replace(',', '.'))
                    }
                    valid={Number(penalties.precaire.replace(',', '.')) >= 0}
                    required
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </Card>
  );
}

export { StepVolumesCee };
