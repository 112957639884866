import { useContext } from 'react';
import { TextError } from '@components/TextError';
import { OperationTypeEnum } from '@utils/enums';
import { HouseholdSummary } from '@models/worksiteCreation/components/simulation/summary/HouseholdSummary';
import { cannotDisplayHouseInformationsSummary } from '@models/worksiteCreation/utils/functions';
import { InfoGeneralSummary } from './InfoGeneralSummary';
import { LocationWorksiteSummary } from './LocationWorksiteSummary';
import { OperationSummary } from './OperationSummary';
import { PrimesSummary } from './PrimesSummary';
import { WorksiteCreationContext } from '../../../utils/worksiteCreationContext';
import { BeneficiaryInfoSummary } from './BeneficiaryInfoSummary';
import NoAid from '../stepDetailOperation.tsx/NoAid';

function Summary() {
  const {
    beneficiary,
    worksiteOperationType,
    errorValidateSimulation,
    noAidOperationToDisplay,
    simulatorDataOperations,
  } = useContext(WorksiteCreationContext);

  if (noAidOperationToDisplay) {
    return <NoAid description={noAidOperationToDisplay.description} />;
  }

  return (
    <>
      <div className="flex space-x-[1.5rem] w-full">
        <div className="w-full">
          {!cannotDisplayHouseInformationsSummary(simulatorDataOperations) && (
            <InfoGeneralSummary />
          )}
          <OperationSummary />
          {worksiteOperationType === OperationTypeEnum.B2C &&
            !cannotDisplayHouseInformationsSummary(simulatorDataOperations) && (
              <HouseholdSummary />
            )}
          {beneficiary !== null && <BeneficiaryInfoSummary />}
        </div>
        <div className="w-full">
          <PrimesSummary />
          <div className="w-full flex space-x-[1.5rem]">
            <LocationWorksiteSummary />
          </div>
        </div>
      </div>
      <TextError errorMessage={errorValidateSimulation} />
    </>
  );
}

export { Summary };
