import { SidebarProgressCard } from '@models/worksiteCreation/components/sidebarProgress/SidebarProgressCard';
import {
  StatusStepEnum,
  StepsWorksiteCreationEnum,
  WORKSITE_CREATION_STATUS,
} from '@models/worksiteCreation/utils/enums';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationTypeEnum } from '@utils/enums';

function ContainerSideBarProgressLeftScreen() {
  const { t } = useTranslation();
  const {
    stepActiveWorksiteCreation,
    workflowSidebar,
    lastStep,
    updateStepActiveWorksiteCreation,
    worksiteOperationType,
    worksiteDatas,
  } = useContext(WorksiteCreationContext);

  const [statusSidebarProgress, setStatusSidebarProgress] = useState({
    simulation: StatusStepEnum.IN_PROGRESS,
    worksiteCreation: StatusStepEnum.NOT_STARTED,
    documents: StatusStepEnum.NOT_STARTED,
  });
  const { SIMULATION_RECAPITULATIF, DOCUMENTS } = StepsWorksiteCreationEnum;

  useEffect(() => {
    const simulationCompleted =
      Number(worksiteDatas.creation_status) >
      WORKSITE_CREATION_STATUS.SIMULATION;

    if (worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION) {
      if (stepActiveWorksiteCreation !== DOCUMENTS) {
        setStatusSidebarProgress({
          simulation: StatusStepEnum.COMPLETED,
          worksiteCreation: StatusStepEnum.IN_PROGRESS,
          documents: StatusStepEnum.NOT_STARTED,
        });
      } else {
        setStatusSidebarProgress({
          simulation: StatusStepEnum.COMPLETED,
          worksiteCreation: StatusStepEnum.COMPLETED,
          documents: StatusStepEnum.IN_PROGRESS,
        });
      }
    } else if (stepActiveWorksiteCreation <= SIMULATION_RECAPITULATIF) {
      setStatusSidebarProgress({
        simulation: simulationCompleted
          ? StatusStepEnum.COMPLETED
          : StatusStepEnum.IN_PROGRESS,
        worksiteCreation:
          worksiteDatas.worksites_operations.length > 0
            ? StatusStepEnum.IN_PROGRESS
            : StatusStepEnum.NOT_STARTED,
        documents: StatusStepEnum.NOT_STARTED,
      });
    } else if (
      stepActiveWorksiteCreation < DOCUMENTS ||
      (worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION &&
        stepActiveWorksiteCreation !== DOCUMENTS)
    ) {
      setStatusSidebarProgress({
        simulation: StatusStepEnum.COMPLETED,
        worksiteCreation: StatusStepEnum.IN_PROGRESS,
        documents: StatusStepEnum.NOT_STARTED,
      });
    } else {
      setStatusSidebarProgress({
        simulation: StatusStepEnum.COMPLETED,
        worksiteCreation: StatusStepEnum.COMPLETED,
        documents: StatusStepEnum.IN_PROGRESS,
      });
    }
  }, [stepActiveWorksiteCreation, worksiteOperationType, worksiteDatas]);

  const sideBarDatas = useMemo(() => {
    const sbDatas = [
      {
        title: t('worksite_creation.steps.simulation'),
        status: statusSidebarProgress.simulation,
        steps: workflowSidebar?.simulation || [],
      },
      {
        title: t('worksite_creation.steps.worksite_creation'),
        status: statusSidebarProgress.worksiteCreation,
        steps: workflowSidebar?.creation || [],
      },
      {
        title: t('worksite_creation.steps.document'),
        status: statusSidebarProgress.documents,
        step: workflowSidebar?.documents || [],
      },
    ];

    return worksiteDatas.status > 0 ? sbDatas : [sbDatas[0]];
  }, [worksiteDatas, stepActiveWorksiteCreation, workflowSidebar]);

  const changeStep = (
    step: number,
    steps: { value: number; label: string }[] | undefined,
    status: StatusStepEnum
  ) => {
    if (steps) {
      const currentIndex = steps.findIndex((elt) => elt.value === step);

      const lastIndex = steps.findIndex((elt) => elt.value === lastStep);

      if (currentIndex <= lastIndex || status === StatusStepEnum.COMPLETED) {
        updateStepActiveWorksiteCreation(step);
      }
    }
  };

  return (
    <div className="min-w-[18rem] h-full flex flex-col space-y-[.875rem]">
      {sideBarDatas.map((data, index) => {
        let lastIndex = 0;
        if (data.steps) {
          lastIndex = data.steps.findIndex((elt) => elt.value === lastStep);
        }

        const currentStep =
          data.steps &&
          data.steps.find((elt) => elt.value === stepActiveWorksiteCreation);

        const documentLoaded =
          worksiteDatas.creation_status ===
          WORKSITE_CREATION_STATUS.STATUS_DOCUMENTS;

        if (
          worksiteOperationType !== OperationTypeEnum.GLOBAL_RENOVATION ||
          index > 0
        ) {
          return (
            <SidebarProgressCard
              key={data.title}
              title={data.title}
              status={data.status}
              steps={data.steps}
              nameStepActive={currentStep ? currentStep.label : ''}
              document={data.title === t('worksite_creation.steps.document')}
              onClickStep={(step) => changeStep(step, data.steps, data.status)}
              lastStep={lastIndex}
              worksiteOperationType={worksiteOperationType}
              documentLoaded={documentLoaded}
              onClickTitle={() => {
                if (documentLoaded && !data.steps) {
                  updateStepActiveWorksiteCreation(DOCUMENTS);
                }
              }}
            />
          );
        }
        return false;
      })}
    </div>
  );
}

export { ContainerSideBarProgressLeftScreen };
