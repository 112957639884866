import { Toggle } from '@components/atomic/inputs/controls/Toggle';
import { Card } from '@components/Card';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@context/authContext';
import { toggleEmailNotifications } from '../apiRequests/userRequests';

export function CardNotifications(): JSX.Element {
  const { t } = useTranslation();
  const { user, updateUser } = useContext(AuthContext);

  const toggle = Boolean(user?.receive_email_notifications);

  const handleToggle = async () => {
    if (user) {
      updateUser({
        ...user,
        receive_email_notifications: !toggle,
      });

      const res = await toggleEmailNotifications(!toggle);

      if (!res.data) {
        updateUser({
          ...user,
          receive_email_notifications: toggle,
        });
      }
    }
  };

  return (
    <Card title="" hidePanel addClass="space-y-5 h-[75vh]">
      <div>
        <div className="text-xl font-medium text-[1.25rem] leading-[2.25rem]">
          {t('my_account.tabs.6')}
        </div>
        <div className="font-medium text-[1rem] leading-[2rem] flex border border-borderGrey rounded-default p-5 items-center justify-between mt-6">
          <div>{t('my_account.receive_all_notifications')}</div>
          <Toggle
            label={t('my_account.tabs.6')}
            isToggleOn={toggle}
            onToggle={handleToggle}
          />
        </div>
      </div>
    </Card>
  );
}
