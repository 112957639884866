import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/Modal';
import { ThemeContext } from '@context/ThemeContext';
import { resetTheme } from '@models/settings/apiRequests/settingsRequests';
import { GlobalContext } from '@context/globalContext';

interface IDeleteModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
export function ResetThemeModal({ setShowModal }: IDeleteModalProps) {
  const { t } = useTranslation();

  const { updateThemeData } = useContext(ThemeContext);
  const { userView } = useContext(GlobalContext);

  const [isResetLoading, setIsResetLoading] = useState(false);

  const handleResetTheme = async () => {
    setIsResetLoading(true);
    const response = await resetTheme(userView?.entity_id || 0);
    if (response.success) {
      updateThemeData(undefined);
      setShowModal(false);
      window.location.reload();
    }
    setIsResetLoading(false);
  };

  return (
    <Modal
      title={t('settings.white_label.reset_theme_modal_title')}
      setIsModal={setShowModal}
      backgroundTransparent
      lightHeader
      btnCancel
      textBtnConfirm={t('buttons.confirm') || ''}
      onConfirmClick={handleResetTheme}
      isLoading={isResetLoading}
    >
      <p>{t('settings.white_label.reset_theme_modal_text')}</p>
    </Modal>
  );
}
