import React, { Dispatch, SetStateAction } from 'react';
import { v4 } from 'uuid';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { IAhExtraDataItem } from '@globalTypes/globalTypes';
import { Card } from '@components/Card';
import { EditIcon } from '@assets/images/svgComponents';
import { blueOpx } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';

interface ICardMultipleArrayForm {
  data: IAhExtraDataItem;
  isEditMode: boolean;
  setDisplayMAFModal: Dispatch<SetStateAction<boolean>>;
}

function CardMultipleArrayForm({
  data,
  isEditMode,
  setDisplayMAFModal,
}: ICardMultipleArrayForm) {
  const { value: mainValues, label } = data;

  return (
    <div className="border border-borderGrey rounded-default bg-white w-full">
      <div className="flex justify-between items-center px-7 py-2 border-b border-borderGrey">
        <div className="font-medium text-[.875rem]">{label}</div>
        {isEditMode && (
          <ColorCube
            color={blueOpx}
            numberOrIcon={<EditIcon />}
            onClick={() => setDisplayMAFModal(true)}
            size="2rem"
            opacity
            addClass="mr-[.5rem] rounded"
          />
        )}
      </div>
      <div className="p-6 grid gap-4">
        {Object.entries(mainValues).map(([key, mainValue]: [string, any]) => (
          <Card
            key={`sub-card-${v4()}`}
            title=""
            subtitle={mainValue.title}
            addClass="border border-gray-300"
          >
            <div className="flex flex-col gap-4">
              {mainValue.value.map(
                (element: Record<string, any>, idx: number) => (
                  <React.Fragment key={`${key}-element-${v4()}`}>
                    {mainValue.subtitle && (
                      <div className="text-sm font-semibold text-gray-600">
                        {`${mainValue.subtitle} ${idx + 1}`}
                      </div>
                    )}
                    <div
                      key={`${key}-element-content-${v4()}`}
                      className="border border-gray-200 rounded-md p-2 mb-2"
                    >
                      <div className="grid grid-cols-3 gap-4">
                        {Object.entries(element).map(
                          ([fieldKey, fieldValue]) => (
                            <InfosWithIcon
                              key={`info-${fieldKey}-${v4()}`}
                              infos={[
                                {
                                  title: fieldValue.title,
                                  subtitle: fieldValue.value || '-',
                                },
                              ]}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}

export { CardMultipleArrayForm };
