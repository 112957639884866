/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { HeaderContext } from '@context/headerContext';

import {
  getLotLinkedOperations,
  getLotOperationsToLink,
  getLot,
  lotUnLinkOperation,
  lotLinkOperation,
  lotFinalize,
  lotControl,
} from '@models/lots/apiRequests/lotRequests';

import { ICardOperationType } from '@models/contracts/utils/contractTypes';
import { SubHeader } from '@components/subHeader/SubHeader';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { GlobalContext } from '@context/globalContext';
import { Tag } from '@components/atomic/Tag';
import { blueOpx, green, red, textGrey } from '@assets/color';
import { DownloadIcon, CheckCircleIcon } from '@assets/images/svgComponents';
import { OperationList } from '@models/contracts/components/OperationList';

import { InputText } from '@components/atomic/inputs/InputText';
import { alphanumericRegex } from '@utils/regex';
import { URL_TYPES } from '@models/contracts/utils/contractConstants';
import { LotPageHeader } from '@models/lots/components/LotPageHeader';
import { ConsultList } from '@models/lots/components/ConsultList';
import { ROLES } from '@utils/roles';
import { ModalSendDelivery } from '@models/lots/components/ModalSendDelivery';
import { ModalControl } from '@models/lots/components/ModalControl';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthContext } from '@context/authContext';
import {
  CONTRACTS_ROUTES_WITH_ID,
  CONTRACTS_ROUTES_WITH_PARAMS,
} from '@utils/routesUrls';

function Lot() {
  const { t } = useTranslation();
  const { id: lotId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const lot_number = query.get('lot_number');

  const { updateTitleHeader, updateTagHeader, refreshHeader } =
    useContext(HeaderContext);
  const {
    globalEnum,
    updateRoute,
    roleUser,
    updateDisplayModalUpgradeSubscription,
  } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const methods = useForm();

  const [lotRef, setLotRef] = useState<string>('');
  const [showEnvoiModal, setShowEnvoiModal] = useState<boolean>(false);
  const [showControlModal, setShowControlModal] = useState<boolean>(false);
  const [dataModalEnvoi, setDataModalEnvoi] = useState<any>();
  const [dataModalControl, setDataModalControl] = useState<any>();

  const [data, setData] = useState<any>();
  const [dataLinked, setDataLinked] = useState<ICardOperationType[]>([]);
  const [dataToLink, setDataToLink] = useState<ICardOperationType[]>([]);

  const [searchLinked, setSearchLinked] = useState<string | null>(null);
  const [searchToLink, setSearchToLink] = useState<string | null>(null);

  const [sortAndFilterLinked, setSortAndFilterLinked] =
    useState<ISortAndFilterType>();
  const [sortAndFilterToLink, setSortAndFilterToLink] =
    useState<ISortAndFilterType>();

  const [isLoading, setIsLoading] = useState({
    linked: false,
    toLink: false,
  });

  const getData = async (
    sLinked?: string,
    sToLink?: string,
    refreshLinked?: boolean,
    refreshToLink?: boolean,
    loadLot = true
  ) => {
    setIsLoading({
      linked: refreshLinked || false,
      toLink: refreshToLink || false,
    });

    let inCreation = false;

    if (loadLot) {
      const resLot = await getLot(Number(lotId));
      inCreation = true;
      setData(resLot);
    }

    const fetchBoth = sLinked === undefined && sToLink === undefined;
    const fetchLinked = fetchBoth || sLinked !== undefined;
    const fetchToLink = fetchBoth || sToLink !== undefined;

    if (refreshLinked || sLinked) {
      const resLinked = fetchLinked
        ? await getLotLinkedOperations(
            Number(lotId),
            sLinked !== undefined ? sLinked : searchLinked,
            setIsLoading,
            sortAndFilterLinked
          )
        : await new Promise((myResolve) => {
            myResolve(dataLinked);
          });

      let res1 = [];
      if (!fetchLinked) {
        res1 = resLinked;
      } else if (resLinked && resLinked.data.length >= 1) {
        res1 = resLinked.data.map((item: any) => {
          return {
            id: item.worksite_operation_id,
            code: item.worksite_operation.operation.code,
            description: item.worksite_operation.operation.description,
            precarity: item.worksite_operation.worksite.precarity,
            volume_classique: item.worksite_operation.volume_classique_restant,
            volume_precaire: item.worksite_operation.volume_precaire_restant,
            price: item.worksite_operation.cee_amount,
            price_precaire: item.worksite_operation.cee_precaire,
            price_classique: item.worksite_operation.cee_classique,
            beneficiary: item.worksite_operation.worksite.beneficiary,
            installer: item.worksite_operation.worksite.installer,
            line_id: item.id,
          };
        });
      }
      setDataLinked(res1);
    }

    if (refreshToLink || sToLink) {
      const resToLink =
        fetchToLink && inCreation
          ? await getLotOperationsToLink(
              Number(lotId),
              sToLink !== undefined ? sToLink : searchToLink,
              setIsLoading,
              sortAndFilterToLink
            )
          : await new Promise((myResolve) => {
              myResolve(dataToLink);
            });

      setIsLoading({ toLink: false, linked: false });

      let res2 = [];
      if (!fetchToLink) {
        res2 = resToLink;
      } else if (resToLink.data && resToLink.data.length >= 1) {
        res2 = resToLink.data.map((item: any) => {
          return {
            id: item.id,
            code: item.operation.code,
            description: item.operation.description,
            precarity: item.worksite.precarity,
            volume_classique: item.volume_classique_restant,
            volume_precaire: item.volume_precaire_restant,
            price: item.cee_amount,
            price_precaire: item.cee_precaire,
            price_classique: item.cee_classique,
            beneficiary: item.worksite.beneficiary,
            installer: item.worksite.installer,
          };
        });
      }

      setDataToLink(res2);
    }
  };

  const handleFinalize = async () => {
    const res = await lotFinalize(data.contract_id, data.id, lotRef || '');
    setDataModalEnvoi(res);
    setShowEnvoiModal(true);
  };

  const handleControl = async () => {
    const res = await lotControl(data.id);
    setDataModalControl(res);
    setShowControlModal(true);
  };

  useEffect(() => {
    getData(undefined, undefined, true, true, true);
  }, [lotId]);

  useEffect(() => {
    if (sortAndFilterToLink)
      getData(undefined, undefined, undefined, true, false);
  }, [sortAndFilterToLink]);

  useEffect(() => {
    if (sortAndFilterLinked)
      getData(undefined, undefined, true, undefined, false);
  }, [sortAndFilterLinked]);

  useEffect(() => {
    const postTitle = lot_number || (data ? String(data.reference || '') : '');

    const badgeText = data
      ? (globalEnum.contract_lot_status as any)[
          String(dataModalControl ? dataModalControl.status : data.status)
        ]
      : '';
    const badgeColor = data
      ? [1, 2, 3].includes(data.status)
        ? blueOpx
        : data.status === 4
        ? red
        : green
      : textGrey;

    (async () => {
      await refreshHeader(true);
      updateTagHeader(<Tag label={badgeText} color={badgeColor} />);
    })();
    updateTitleHeader(`${t('contractMandant.delivery')} ${postTitle}`);

    if (data) {
      updateRoute({
        to: `${
          CONTRACTS_ROUTES_WITH_ID(data.contract_id)
            .CONTRACT_REPRESENTATIVE_NON_DEPOSITOR_VIEW
        }#lots`,
        older: [
          `${
            CONTRACTS_ROUTES_WITH_ID(data.contract_id)
              .CONTRACT_REPRESENTATIVE_NON_DEPOSITOR_VIEW
          }#lots`,
        ],
      });
    }
  }, [data, location, dataModalControl]);

  const isEdit = useMemo(() => {
    return data && data.status === 1;
  }, [data]);

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          isEdit ? (
            <div className="w-[25%]">
              <FormProvider {...methods}>
                <form>
                  <InputText
                    onChange={(value) => {
                      setLotRef(value as string);
                    }}
                    onPaste={(e: any) => {
                      const val = e.clipboardData.getData('Text');
                      if (val !== '' && alphanumericRegex.test(val)) {
                        setLotRef(val.trim());
                      }
                    }}
                    id="lotRef"
                    name="lotRef"
                    placeholder={data.reference || t('contract.reference')}
                    required
                    valid={lotRef !== '' && alphanumericRegex.test(lotRef)}
                    error={
                      !lotRef ||
                      lotRef === '' ||
                      !alphanumericRegex.test(lotRef)
                    }
                  />
                </form>
              </FormProvider>
            </div>
          ) : (
            <div />
          )
        }
        rightPart={
          <>
            {!isEdit && roleUser === ROLES.GESTION && (
              <ButtonOpx
                label={t('contractMandant.view_contract')}
                type="secondary"
                onClick={() => {
                  navigate(
                    CONTRACTS_ROUTES_WITH_PARAMS(
                      URL_TYPES.MANDANT_NON_DEPOSANT,
                      data.contract_id
                    ).CONTRACT_VIEW
                  );
                }}
              />
            )}
            <ButtonOpx
              label={t('contractMandant.export_lot')}
              type="secondary"
              icon={<DownloadIcon />}
              onClick={() => {
                if (user?.is_freemium) {
                  updateDisplayModalUpgradeSubscription(true);
                } else {
                  console.log('Export lot');
                }
              }}
            />
            {isEdit && (
              <ButtonOpx
                label={t('contractMandant.finalize_lot')}
                icon={<CheckCircleIcon />}
                onClick={handleFinalize}
                disabled={
                  !data || (data && data.count_operations === 0) || !lotRef
                }
              />
            )}
            {!isEdit &&
              roleUser === ROLES.GESTION &&
              data &&
              data.awaiting_control && (
                <ButtonOpx
                  label={t('contractMandant.control_lot')}
                  onClick={handleControl}
                />
              )}
          </>
        }
      />
      <LotPageHeader data={data} loading={isLoading.linked} />
      {(isEdit === undefined || isEdit === true) && lot_number ? (
        <div className="w-full flex flex-wrap">
          <div className="w-full md:w-[50%] md:pr-6 mb-[1.5rem]">
            <OperationList
              model="lot"
              cardTitle={t('contractMandant.add_to_lot')}
              buttonList={['add']}
              onAdd={async (operationId) => {
                await lotLinkOperation(data.contract_id, data.id, operationId);
              }}
              data={dataToLink}
              setSearch={(val: string) => {
                setSearchToLink(val);
                getData(undefined, val);
              }}
              refresh={() => getData(undefined, undefined, true, true, true)}
              isEdit={isEdit}
              priceLabel={`${t('contractMandant.amount_prime')}`}
              isLoading={isLoading.toLink}
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterToLink)
              }
              onFilter={(filters) =>
                filterList(filters, setSortAndFilterToLink)
              }
            />
          </div>
          <div className="w-full md:w-[50%] mb-[1.5rem]">
            <OperationList
              model="lot"
              cardTitle={t('contractMandant.my_lot')}
              buttonList={['delete']}
              onDelete={async (operationId) => {
                await lotUnLinkOperation(data.id, operationId);
              }}
              data={dataLinked}
              setSearch={(val: string) => {
                setSearchLinked(val);
                getData(val, undefined);
              }}
              refresh={() => getData(undefined, undefined, true, true, true)}
              isEdit={isEdit}
              priceLabel={`${t('contractMandant.amount_prime')}`}
              isLoading={isLoading.linked}
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterLinked)
              }
              onFilter={(filters) =>
                filterList(filters, setSortAndFilterLinked)
              }
            />
          </div>
        </div>
      ) : (
        <ConsultList
          data={data && data.contracts_lots_operations}
          model="lot"
          sortList={(column, direction) =>
            sortList(column, direction, setSortAndFilterLinked)
          }
          onFilter={(filters) => filterList(filters, setSortAndFilterLinked)}
          loading={isLoading.linked}
        />
      )}
      {/* MODAL ENVOI DE LA LIVRAISON */}
      {showEnvoiModal && dataModalEnvoi && (
        <ModalSendDelivery
          data={dataModalEnvoi}
          callbackConfirm={(res) => {
            setShowEnvoiModal(false);
            setDataModalEnvoi(undefined);
            setLotRef('');

            navigate(
              `${
                CONTRACTS_ROUTES_WITH_PARAMS(
                  URL_TYPES.MANDANT_NON_DEPOSANT,
                  res.contract_id
                ).CONTRACT_VIEW
              }#lots`
            );
          }}
          callbackClose={() => setShowEnvoiModal(false)}
        />
      )}
      {/* MODAL CONTROL DU LOT */}
      {showControlModal && dataModalControl && (
        <ModalControl
          data={dataModalControl}
          callbackConfirm={(res) => {
            setShowControlModal(false);
            setDataModalControl(undefined);

            setData(res);
          }}
          callbackClose={() => setShowControlModal(false)}
        />
      )}
    </div>
  );
}

export { Lot };
