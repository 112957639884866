/* eslint-disable no-nested-ternary */
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useIframeMode } from '@hooks/useIframeMode';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function ButtonsHeaderWorksiteCreation() {
  const { t } = useTranslation();
  const {
    beneficiary,
    updateIsModals,
    isModals,
    worksiteDatas,
    stepActiveWorksiteCreation,
  } = useContext(WorksiteCreationContext);
  const isIframeMode = useIframeMode();

  const navigate = useNavigate();

  // Fonction pour recharger la page et faire une nouvelle simulation
  const handleNewSimulation = () => {
    window.location.reload();
  };

  // Si nous sommes en mode iframe et à l'étape récapitulative, afficher uniquement le bouton "Faire une autre simulation"
  if (isIframeMode) {
    return (
      <div className="flex space-x-[1rem] items-center mt-[1rem]">
        <ButtonOpx
          label={t('buttons.make_another_simulation')}
          type="primary"
          addClass="w-full"
          onClick={handleNewSimulation}
          dataTestId="button_make_another_simulation"
        />
      </div>
    );
  }

  return (
    <div className="flex space-x-[1rem] items-center mt-[1rem]">
      {worksiteDatas.status !== 0 && (
        <ButtonOpx
          label={t(
            !beneficiary ? 'buttons.bind_beneficiary' : 'buttons.delete'
          )}
          type="secondary"
          addClass="w-full"
          onClick={() =>
            beneficiary
              ? updateIsModals({
                  ...isModals,
                  bindBeneficiary: false,
                  formBeforeSimulation: false,
                  deleteWorksite: true,
                  saveAndQuit: false,
                })
              : updateIsModals({
                  ...isModals,
                  bindBeneficiary: true,
                  formBeforeSimulation: false,
                })
          }
          dataTestId="button_delete"
        />
      )}
      <ButtonOpx
        label={t(
          !beneficiary
            ? 'buttons.quit_and_delete'
            : (beneficiary && worksiteDatas.id === 0) ||
              (worksiteDatas.status === WORKSITE_STATUS.WORKSITE_CREATION &&
                beneficiary)
            ? 'buttons.save_and_quit'
            : 'buttons.quit'
        )}
        type={
          stepActiveWorksiteCreation ===
          StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF
            ? 'tab'
            : 'primary'
        }
        addClass="w-full"
        onClick={() =>
          worksiteDatas.status !== 0
            ? updateIsModals({
                createBeneficiary: false,
                formBeforeSimulation: false,
                bindBeneficiary: false,
                saveAndQuit: true,
                deleteWorksite: false,
                cancelWorksite: false,
              })
            : navigate('/worksites')
        }
        dataTestId="button_quit"
      />
    </div>
  );
}

export { ButtonsHeaderWorksiteCreation };
