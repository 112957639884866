import { axiosClient } from '@api/apiClient';
import {
  urlControlContactCloseLot,
  urlControlContactCofracs,
  urlControlContactControlPointsByWorksiteOperationId,
  urlControlContactFromCofrac,
  urlControlContactFromOperation,
  urlControlContactInfos,
  urlControlContactInfosWorksitesOperations,
  urlControlContactLots,
  urlControlContactOperations,
  urlControlContactOperationsAndContractsChoices,
  urlControlContactOperationsConsult,
  urlControlContactSettingsFieldDelete,
  urlControlContactSettingsFieldGet,
  urlControlContactSettingsFields,
  urlControlContactSettingsFieldsStoreOrUpdate,
  urlControlContactSettingsFieldsUpdate,
  urlControlContactValidateLot,
  urlExportControlContact,
} from '@api/apiUrls';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { IContactControlPointAnswer } from '@models/worksites/utils/worksitesTypes';
import { convertCssColorToHex } from '@utils/functions';
import { TFunction } from 'i18next';
import { ILabelValue } from '@globalTypes/globalTypes';
import { displayError } from '@utils/format';
import { saveAs } from 'file-saver';
import { FieldValues } from 'react-hook-form';
import {
  colorsResponseFormat,
  responseFormatsLabelValue,
} from '../utils/utlis';
import {
  IControlContactSettingsField,
  IFormInputsNewControlContactField,
} from '../utils/controlContactTypes';

export const getControlContactList = async (
  page: number,
  perPage?: number,
  sortAndFilter?: ISortAndFilterType
) => {
  try {
    const response = await axiosClient.post(urlControlContactLots, {
      pagination: {
        page,
        perPage: perPage || 5,
      },
      sort_and_filter: sortAndFilter,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getCofracListControlled = async () => {
  try {
    const response = await axiosClient.get(urlControlContactCofracs);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getOperationsToControlContact = async () => {
  try {
    const response = await axiosClient.get(urlControlContactOperations);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const storeControlContactFromCofrac = async (data: FieldValues) => {
  try {
    const response = await axiosClient.post(urlControlContactFromCofrac, {
      reference: data.name,
      cofrac_lot_id: data.cofrac_id,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const storeControlContactFromOperation = async (data: FieldValues) => {
  try {
    const response = await axiosClient.post(urlControlContactFromOperation, {
      reference: data.name,
      operation_id: data.operation_id,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getControlContactInfos = async (
  id: number,
  isInProgress: boolean
) => {
  try {
    const url = isInProgress
      ? urlControlContactInfosWorksitesOperations(id)
      : urlControlContactInfos(id);
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getControlContactOperationsConsult = async (
  id: number,
  search: string | null,
  sortAndFilter?: ISortAndFilterType
) => {
  try {
    const response = await axiosClient.post(
      urlControlContactOperationsConsult(id),
      {
        keyword: search,
        sort_and_filter: sortAndFilter,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const postControlContactValidateLot = async (
  id: number,
  worksite_operation_ids: number[]
) => {
  try {
    const response = await axiosClient.post(urlControlContactValidateLot(id), {
      worksite_operation_ids,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const postControlContactValidateControl = async (
  contact_lot_id: number
) => {
  try {
    const response = await axiosClient.post(urlControlContactCloseLot, {
      contact_lot_id,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getControlContactControlPointsByWorksiteOperationId = async (
  id: number
) => {
  try {
    const response = await axiosClient.get(
      urlControlContactControlPointsByWorksiteOperationId(id)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const saveControlContactControlsPoints = async (
  worksiteOperationId: number,
  contact_control_date_reminder: string | null,
  contact_control_date: string,
  contact_control_status: number,
  contact_control_comments: string,
  contact_control_points_answers: IContactControlPointAnswer[]
) => {
  try {
    const response = await axiosClient.post(
      urlControlContactControlPointsByWorksiteOperationId(worksiteOperationId),
      {
        contact_control_date_reminder,
        contact_control_date,
        contact_control_status,
        contact_control_comments,
        contact_control_points_answers,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

/**
 * Télécharge le fichier CSV correspondant au lot de contact de contrôle.
 *
 * @param contactLotId - L'identifiant du lot de contact.
 * @returns Les données de la réponse ou false en cas d'erreur.
 */
export const exportControlContactCsv = async (contactLotId: number) => {
  try {
    const response = await axiosClient.post(urlExportControlContact, {
      contact_lot_id: contactLotId,
    });
    const blob = new Blob([response.data], {
      type: 'text/csv',
    });

    saveAs(blob, `control_contact_lot_${contactLotId}.csv`);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getControlContactSettingsFields = async () => {
  try {
    const response = await axiosClient.get(urlControlContactSettingsFields);
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const updateControlContactSettingsFields = async (
  fields: IControlContactSettingsField[]
) => {
  const formattedFields = fields.map((field) => ({
    id: field.id,
    position: field.position,
  }));
  try {
    const response = await axiosClient.post(
      urlControlContactSettingsFieldsUpdate,
      {
        data: formattedFields,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getOperationsAndContracts = async () => {
  try {
    const response = await axiosClient.get(
      urlControlContactOperationsAndContractsChoices
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const storeOrUpdateControlContactField = async (
  data: IFormInputsNewControlContactField,
  fieldIdToEdit?: number | null
) => {
  const dataToSend = {
    id: fieldIdToEdit,
    label: data.label,
    has_tooltip: data.has_tooltip,
    tooltip_text: data.tooltip_text,
    optional: data.optional,
    question_type: data.response_format.value,
    question_values: {
      color: data.response_format_color_values
        ? convertCssColorToHex(data.response_format_color_values.value)
        : undefined,
      values: data.response_format_values?.map((value) => {
        return {
          label: value,
          value,
        };
      }),
    },
    operation_ids: data.operation_ids?.includes('all')
      ? []
      : data.operation_ids || [],
    contract_ids: data.contract_ids?.includes('all')
      ? []
      : data.contract_ids || [],
  };
  try {
    const response = await axiosClient.post(
      urlControlContactSettingsFieldsStoreOrUpdate,
      dataToSend
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getFieldToEditData = async (id: number, t: TFunction) => {
  try {
    const response = await axiosClient.get(
      urlControlContactSettingsFieldGet(id)
    );
    const data = response.data.data;

    const dataFormReady = {
      label: data.label,
      has_tooltip: data.has_tooltip,
      tooltip_text: data.tooltip_text,
      optional: data.optional,
      response_format: {
        value: data.question_type,
        label:
          responseFormatsLabelValue(t).find(
            (item) => item.value === data.question_type
          )?.label || '',
      },
      response_format_values: data.question_values?.values.map(
        (value: ILabelValue) => value.value
      ),
      operation_ids:
        data.operations.map((operation: any) => String(operation.id)) || [],
      contract_ids:
        data.contracts.map((contract: any) => String(contract.id)) || [],
      response_format_color_values: colorsResponseFormat(t).find(
        (item) =>
          convertCssColorToHex(item.value) === data.question_values?.color
      ),
    };
    return dataFormReady;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const deleteControlContactField = async (id: number) => {
  try {
    const response = await axiosClient.delete(
      urlControlContactSettingsFieldDelete,
      {
        data: {
          contact_control_point_id: id,
        },
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};
