/* eslint-disable @typescript-eslint/no-unused-vars */
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputText } from '@components/atomic/inputs/InputText';
import { IIframe } from '@models/settings/utils/settingsTypes';
import {
  emailValidationRegex,
  phoneNumberRegex,
  postalCodeRegex,
} from '@utils/regex';
import { placeholderExample } from '@utils/utils';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Interface pour les données de l'entité utilisateur
interface IEntityInfo {
  company_name?: string;
  zipcode?: string;
  city?: string;
  address?: string;
  phone_number?: string;
  contact_email?: string;
}

interface ContactInformationsSectionProps {
  iframeData: IIframe | null;
  entityInfo: IEntityInfo | null;
}

function ContactInformationsSection({
  iframeData,
  entityInfo,
}: ContactInformationsSectionProps) {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  const hiddenFileInput = useRef(null);
  const [preview, setPreview] = useState<string>(iframeData?.logo || '');
  const logoFile = watch('logo_file');

  // États pour gérer l'affichage des erreurs
  const [isEmailTouched, setIsEmailTouched] = useState<boolean>(false);
  const [isPhoneTouched, setIsPhoneTouched] = useState<boolean>(false);
  const [isPostalCodeTouched, setIsPostalCodeTouched] =
    useState<boolean>(false);

  // Récupérer les valeurs du formulaire
  const coordinatesEmail = watch('coordinates_email');
  const coordinatesPhone = watch('coordinates_phone');
  const coordinatesAddress = watch('coordinates_address');
  const coordinatesCity = watch('coordinates_city');
  const coordinatesPostalCode = watch('coordinates_postal_code');
  const coordinatesCompany = watch('coordinates_company');

  // Validation des champs
  const isEmailValid = coordinatesEmail
    ? emailValidationRegex.test(coordinatesEmail)
    : true;
  const isPhoneValid = coordinatesPhone
    ? phoneNumberRegex.test(coordinatesPhone)
    : true;
  const isPostalCodeValid = coordinatesPostalCode
    ? postalCodeRegex.test(coordinatesPostalCode)
    : true;

  // Erreurs à afficher
  const emailError =
    isEmailTouched && !isEmailValid ? t('forms.email.error_pattern') : '';
  const phoneError =
    isPhoneTouched && !isPhoneValid ? t('forms.phone.error_pattern') : '';
  const postalCodeError =
    isPostalCodeTouched && !isPostalCodeValid
      ? t('settings.iframes.postal_code_format_error')
      : '';

  const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      // Enregistrer le fichier dans le formulaire
      setValue('logo_file', file);
    }
  };

  // Gestion des changements dans les champs du formulaire
  const handleChange = (field: string, value: any) => {
    if (typeof value === 'string') {
      setValue(field, value, {
        shouldValidate: false,
        shouldDirty: true,
      });
    } else if (value && value.target && value.target.value) {
      setValue(field, value.target.value, {
        shouldValidate: false,
        shouldDirty: true,
      });
    }
  };

  // Gestion des pertes de focus
  const handleBlur = (field: string) => {
    switch (field) {
      case 'coordinates_email':
        setIsEmailTouched(true);
        break;
      case 'coordinates_phone':
        setIsPhoneTouched(true);
        break;
      case 'coordinates_postal_code':
        setIsPostalCodeTouched(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (logoFile && logoFile instanceof File) {
      setPreview(URL.createObjectURL(logoFile));
    }
  }, []);

  return (
    <div className="space-y-4">
      <p className="font-medium">
        {t('settings.iframes.contact_informations')}
      </p>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-4 bg-white border border-borderGrey rounded-default p-6">
          <div className="space-y-2">
            <p className="font-medium">
              {t('settings.iframes.simulator_logo')}
            </p>
            <p className="text-[.875rem]">
              {t('settings.iframes.simulator_logo_description')}
            </p>
          </div>
          <div className="h-[10rem] max-h-[10rem] w-[10rem] flex items-center">
            {preview !== '' ? (
              <img
                alt="entityLogo"
                src={preview}
                className="rounded-default object-contain max-h-[10rem]"
              />
            ) : (
              <div className="bg-borderGrey rounded-default flex items-center justify-center h-full w-full">
                <div>{t('global.logo')}</div>
              </div>
            )}
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={onUploadFile}
            style={{ display: 'none' }}
            accept=".jpg,.jpeg,.png"
            onClick={(e) => {
              e.stopPropagation();
            }}
            data-test-id="iframe-logo-input"
          />
          <ButtonOpx
            label={t('my_account.update_logo')}
            type="tab"
            small
            onClick={() => {
              (hiddenFileInput.current as any).click();
            }}
            addClass="w-min"
          />
        </div>
        <div className="flex flex-col gap-4 bg-white border border-borderGrey rounded-default p-6">
          <div className="space-y-2">
            <p className="font-medium">{t('settings.iframes.coordinates')}</p>
            <p className="text-[.875rem]">
              {t('settings.iframes.coordinates_description')}
            </p>
          </div>
          <div className="space-y-3">
            {/* Raison sociale */}
            <InputText
              id="coordinates_company"
              name="coordinates_company"
              placeholder={placeholderExample(t).COMPANY_NAME}
              label={t('infos.company_name')}
              value={coordinatesCompany || ''}
              onChange={(value) => handleChange('coordinates_company', value)}
              hideIsOptional
            />

            {/* Adresse + Code Postal + Ville */}
            <div className="grid grid-cols-10 gap-3">
              <div className="col-span-4">
                <InputText
                  id="coordinates_address"
                  name="coordinates_address"
                  placeholder={placeholderExample(t).ADDRESS}
                  label={t('infos.address')}
                  value={coordinatesAddress || ''}
                  onChange={(value) =>
                    handleChange('coordinates_address', value)
                  }
                  hideIsOptional
                />
              </div>
              <div className="col-span-2">
                <InputText
                  id="coordinates_postal_code"
                  name="coordinates_postal_code"
                  placeholder={placeholderExample(t).ZIP_CODE}
                  label={t('forms.address.manual.zip')}
                  value={coordinatesPostalCode || ''}
                  onChange={(value) =>
                    handleChange('coordinates_postal_code', value)
                  }
                  onBlur={() => handleBlur('coordinates_postal_code')}
                  error={isPostalCodeTouched && !isPostalCodeValid}
                  textError={postalCodeError}
                  hideIsOptional
                  maxLength={5}
                />
              </div>
              <div className="col-span-4">
                <InputText
                  id="coordinates_city"
                  name="coordinates_city"
                  placeholder={placeholderExample(t).CITY}
                  label={t('forms.address.manual.city')}
                  value={coordinatesCity || ''}
                  onChange={(value) => handleChange('coordinates_city', value)}
                  hideIsOptional
                />
              </div>
            </div>

            {/* Téléphone + Email */}
            <div className="grid grid-cols-2 gap-3">
              <InputText
                id="coordinates_phone"
                name="coordinates_phone"
                placeholder={placeholderExample(t).TELEPHONE}
                label={t('forms.phone.placeholder')}
                value={coordinatesPhone || ''}
                onChange={(value) => handleChange('coordinates_phone', value)}
                onBlur={() => handleBlur('coordinates_phone')}
                error={isPhoneTouched && !isPhoneValid}
                textError={phoneError}
              />
              <InputText
                id="coordinates_email"
                name="coordinates_email"
                placeholder={placeholderExample(t).EMAIL}
                label={t('infos.email')}
                value={coordinatesEmail || ''}
                onChange={(value) => handleChange('coordinates_email', value)}
                onBlur={() => handleBlur('coordinates_email')}
                error={isEmailTouched && !isEmailValid}
                textError={emailError}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInformationsSection;
