import { CellContext } from '@tanstack/react-table';
import { IBuyingPriceType } from '@models/conventions/utils/conventionTypes';
import { Tag } from '@components/atomic/Tag';
import { formatPriceWithoutRounding } from '@utils/format';
import { orange } from '@assets/color';
import { INCENTIVE_TYPE } from '@models/worksiteCreation/utils/enums';

interface OneOperationPriceProps {
  info: CellContext<IBuyingPriceType, number | null>;
  isConvention: boolean;
  incentiveType?: number;
  addClass?: string;
}

function PriceCell({
  info,
  isConvention,
  incentiveType,
  addClass,
}: OneOperationPriceProps) {
  const incentiveNumber = incentiveType === INCENTIVE_TYPE.MIXED ? 2 : 1;

  const conventionOperation = info.row.original as unknown as {
    [x: string]: number | null;
  };

  return (
    <div>
      {[...Array(incentiveNumber)].map((elt, index) => {
        let keyValue = String(info.column.id);

        if (keyValue.includes('_classique')) {
          const uniqueKey = keyValue.replace('_classique', '_unique');

          if (Number(conventionOperation[uniqueKey]) > 0) {
            keyValue = uniqueKey;
          }
        }

        if (index === 1) keyValue = keyValue.replace('indirect_', 'direct_');

        const puCdpName = keyValue.replace('_pu', '_pu_cdp');
        const pfCdpName = keyValue.replace('_pu', '_pf_cdp');

        return (
          <div
            className={`${
              incentiveType === INCENTIVE_TYPE.MIXED && index === 0
                ? 'border-b'
                : ''
            } ${addClass || ''} p-[2rem]`}
          >
            <div className="flex">
              {isConvention && Number(conventionOperation[puCdpName]) > 0 && (
                <Tag color="white" label="CDP" addClass="me-2" />
              )}
              {Number(conventionOperation[keyValue]) > 0
                ? `${formatPriceWithoutRounding(
                    String(Number(conventionOperation[keyValue]) || '')
                  )} €/MWHc`
                : '-'}
            </div>
            {isConvention && conventionOperation[puCdpName] && (
              <div className="flex mt-2">
                <Tag color={orange} label="CDP" addClass="me-2" />
                {formatPriceWithoutRounding(
                  String(Number(conventionOperation[puCdpName]))
                )}{' '}
                €/MWHc
              </div>
            )}
            {isConvention && conventionOperation[pfCdpName] && (
              <div className="flex mt-2">
                <Tag color={orange} label="CDP" addClass="me-2" />
                {formatPriceWithoutRounding(
                  String(Number(conventionOperation[pfCdpName]))
                )}{' '}
                €
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

PriceCell.defaultProps = { incentiveType: undefined, addClass: '' };

export { PriceCell };
