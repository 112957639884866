import { IContractOperationPrice } from '@models/contractCreation/utils/contractCreationTypes';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { getBuyingPrices } from '@models/contracts/apiRequests/contractRequests';
import { getOperationsDdl } from '@models/contracts/apiRequests/newContractRequests';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { IAgreementList } from '@models/settings/utils/settingsTypes';
import { CONTRACTS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { useEffect, useMemo, useState } from 'react';
import { useIframeOperations as useSharedIframeOperations } from '../contexts/IframeOperationsContext';

interface UseIframeOperationsProps {
  contractId: string | null;
  beneficiaryType: string | null;
  primeType: string | null;
  onUpdateOperationPrices?: (prices: IContractOperationPrice[]) => void;
  agreements?: IAgreementList[];
  /**
   * Opérations initiales provenant des données de l'iframe
   */
  initialOperations?: IContractOperationPrice[];
}

/**
 * Hook personnalisé pour gérer les opérations d'un iframe
 * @param props - Propriétés du hook
 * @returns Retourne les données et fonctions nécessaires à la gestion des opérations
 */
export function useIframeOperations({
  contractId,
  beneficiaryType,
  primeType: _primeType,
  onUpdateOperationPrices,
  agreements = [],
  initialOperations = [],
}: UseIframeOperationsProps) {
  // Utiliser le contexte partagé
  const {
    hasUserModifiedOperations,
    setHasUserModifiedOperations,
    currentOperationPrices: operationPrices,
    setCurrentOperationPrices: setOperationPrices,
    originalContractOperations,
    setOriginalContractOperations,
    isContractModified,
    setIsContractModified,
  } = useSharedIframeOperations();

  // États locaux
  const [listOperations, setListOperations] = useState<IOperationType[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fonction pour vérifier si les opérations actuelles diffèrent des opérations d'origine
  const checkIfOperationsModified = (
    currentOps: IContractOperationPrice[],
    originalOps: IContractOperationPrice[]
  ): boolean => {
    // Si le nombre d'opérations est différent, les opérations ont été modifiées
    if (currentOps.length !== originalOps.length) {
      return true;
    }

    // Créer un Map des opérations d'origine pour une comparaison rapide
    const originalOpsMap = new Map(
      originalOps.map((op) => [op.operation_id, op])
    );

    // Vérifier si chaque opération actuelle correspond à son équivalent d'origine
    return currentOps.some((currentOp) => {
      const originalOp = originalOpsMap.get(currentOp.operation_id);

      // Si l'opération n'existe pas dans l'état d'origine, les opérations ont été modifiées
      if (!originalOp) {
        return true;
      }

      // Comparer les objets pour trouver des différences dans les prix
      // Utiliser une approche plus générique en cas de différences structurelles
      const currentOpValues = JSON.stringify(
        Object.values(currentOp).filter((val) => typeof val === 'number')
      );
      const originalOpValues = JSON.stringify(
        Object.values(originalOp).filter((val) => typeof val === 'number')
      );

      return currentOpValues !== originalOpValues;
    });
  };

  // Fonction personnalisée pour mettre à jour les opérations et notifier le parent
  const updateOperationPricesWithNotify = (
    prices: IContractOperationPrice[]
  ) => {
    setOperationPrices(prices);
    // Marquer que l'utilisateur a modifié les opérations
    setHasUserModifiedOperations(true);

    // Vérifier si les opérations ont été modifiées par rapport à leur état d'origine
    if (originalContractOperations.length > 0) {
      const isModified = checkIfOperationsModified(
        prices,
        originalContractOperations
      );
      setIsContractModified(isModified);
    }

    if (onUpdateOperationPrices) {
      onUpdateOperationPrices(prices);
    }
  };

  // Effet pour initialiser les opérations avec les données de l'iframe
  useEffect(() => {
    // Seulement initialiser si nous avons des opérations initiales et que l'utilisateur n'a pas encore modifié les opérations
    if (
      initialOperations.length > 0 &&
      !hasUserModifiedOperations &&
      operationPrices.length === 0
    ) {
      // Vérifier que les opérations initiales sont valides (ont un operation_id)
      const validOperations = initialOperations.filter(
        (op) => op.operation_id && op.operation?.code
      );
      if (validOperations.length > 0) {
        updateOperationPricesWithNotify(validOperations);
      }
    }
  }, [initialOperations]);

  // Fonction pour ouvrir le détail du contrat dans un nouvel onglet
  const openContractDetails = () => {
    if (contractId) {
      const url = CONTRACTS_ROUTES_WITH_ID(contractId).CONTRACTS_EDIT;
      window.open(url, '_blank');
    }
  };

  // Récupérer les prix d'achat lorsqu'un contrat est sélectionné, en adaptant selon le type de contrat
  const fetchBuyingPricesOnSelectContract = async () => {
    if (contractId) {
      // Vérifier si le contrat est de type MANDAT ou MANDAT_NON_DEPOSANT
      const currentContract = agreements.find(
        (contract) => contract.id.toString() === contractId.toString()
      );

      // Déterminer si le contrat est une convention ou un mandat
      const isConventionType = currentContract?.is_convention;

      // Appel à getBuyingPrices avec le paramètre isConvention approprié
      const response = await getBuyingPrices(
        Number(contractId),
        isConventionType ?? false
      );

      if (response) {
        // Convertir la réponse en opérations formatées
        const formattedOperations = response.map((op: any) => ({
          ...op,
          label: `${op.operation.code} - ${op.operation.description}`,
          operation_id: op.operation.id,
        }));

        // À chaque sélection de contrat, nous devons toujours réinitialiser les états importants
        // pour permettre la détection future des modifications

        // Réinitialiser l'état de modification du contrat
        setIsContractModified(false);

        // Enregistrer les nouvelles opérations d'origine pour les comparer plus tard
        setOriginalContractOperations([...formattedOperations]);

        // Mettre à jour les opérations
        setOperationPrices(formattedOperations);

        // Réinitialiser le drapeau hasUserModifiedOperations quand un contrat est sélectionné
        setHasUserModifiedOperations(false);

        // Notifier le parent si nécessaire
        if (onUpdateOperationPrices) {
          onUpdateOperationPrices(formattedOperations);
        }
      }
    }
  };

  // Récupérer les opérations disponibles
  const fetchOperations = async () => {
    setIsLoading(true);
    // Si un contrat est sélectionné, on utilise son ID, sinon on passe null
    const resList = await getOperationsDdl(
      null,
      ContractTypes.MANDAT,
      Number(beneficiaryType),
      '',
      '', // startDate - N/A ici
      '', // endDate - N/A ici
      0, // entityTo.id - N/A ici
      undefined
    );

    if (resList) {
      setListOperations(resList);
    }
    setIsLoading(false);
  };

  // Calculer les opérations disponibles (non sélectionnées)
  const availableOperations = useMemo(() => {
    const usedIds = new Set(operationPrices.map((op) => op.operation_id));
    const uniqueOperations = listOperations?.filter(
      (op) => !usedIds.has(op.id)
    );

    // Removing duplicates from listOperations based on operation_id
    const uniqueListOperations = uniqueOperations?.filter(
      (op, index, self) => index === self.findIndex((item) => item.id === op.id)
    );

    return uniqueListOperations || [];
  }, [operationPrices, listOperations]);

  // Pour simuler le comportement de ContractCreationContext
  const contractContextValue = useMemo(
    () => ({
      operationPrices,
      updateOperationPrices: updateOperationPricesWithNotify,
      listOperations,
      updateListOperations: setListOperations,
      contractType: ContractTypes.CONVENTION,
      beneficiaryType: Number(beneficiaryType),
      incentiveType: 1, // Valeur par défaut
    }),
    [operationPrices, listOperations, beneficiaryType]
  );

  return {
    operationPrices,
    listOperations,
    isLoading,
    availableOperations,
    contractContextValue,
    fetchBuyingPricesOnSelectContract,
    fetchOperations,
    updateOperationPricesWithNotify,
    openContractDetails,
    hasUserModifiedOperations,
    isContractModified,
  };
}
