/* eslint-disable @typescript-eslint/no-unused-vars */
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { GlobalContext } from '@context/globalContext';
import { ILabelValue } from '@globalTypes/globalTypes';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { IIframe } from '@models/settings/utils/settingsTypes';
import { fetchConventions, linkToContract } from '@models/settings/utils/utils';
import { ENTITY_TYPES } from '@utils/roles';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConventionSelectorProps {
  iframeData: IIframe | null;
  contractId: string | null;
  setValue: (name: string, value: any, options?: any) => void;
}

/**
 * Composant pour le sélecteur de convention avec bouton de consultation
 */
export function ConventionSelector({
  iframeData,
  contractId,
  setValue,
}: ConventionSelectorProps) {
  const { t } = useTranslation();
  const { userView } = useContext(GlobalContext);
  const isInstaller = userView?.entity_type === ENTITY_TYPES.INSTALLATEUR;

  const [conventionOptions, setConventionOptions] = useState<ILabelValue[]>([]);
  const [isLoadingConventions, setIsLoadingConventions] = useState(false);

  // Fonction pour mettre à jour la convention sélectionnée
  const handleConventionChange = useCallback(
    (value: ILabelValue) => {
      setValue('convention_id', value.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue]
  );

  // Fonction pour ouvrir le détail de la convention dans un nouvel onglet
  const openContractDetails = useCallback(() => {
    if (contractId) {
      const url = linkToContract(ContractTypes.CONVENTION, contractId);
      window.open(url, '_blank');
    }
  }, [contractId]);

  useEffect(() => {
    fetchConventions(
      isInstaller,
      userView,
      setConventionOptions,
      setIsLoadingConventions
    );
  }, []);

  if (isLoadingConventions) {
    return <LoaderDivSkeleton height="3.5rem" width="100%" />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center gap-4 w-full">
        <div className="flex-grow">
          <InputSelect
            label={t('settings.iframes.convention') || 'Convention'}
            dataLabelValue={conventionOptions}
            placeholder={
              t('settings.iframes.select_convention') ||
              'Sélectionner une convention'
            }
            onSelectLabelValue={handleConventionChange}
            defaultSelected={
              conventionOptions.find(
                (convention) =>
                  Number(convention.value) === iframeData?.convention_id
              )?.label
            }
            required
          />
        </div>
        <div className="flex-shrink-0 self-end pb-1">
          <ButtonOpx
            label={t('settings.iframes.see_contract') || 'Voir la convention'}
            type="tab"
            onClick={openContractDetails}
            disabled={!contractId}
          />
        </div>
      </div>
    </div>
  );
}
