import { ContractTypes } from '@models/contractCreation/utils/enums';
import {
  urlContracts,
  urlConventions,
  urlDuplicateContract,
  urlGetContractDraft,
  urlGetConventionModel,
  urlPostContractDraft,
  urlPostModel,
  urlUpdateContract,
} from '@api/apiUrls';
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';

export const storeContract = async (data: any) => {
  const isConvention = [
    ContractTypes.CONVENTION,
    ContractTypes.TRIPARTITE,
  ].includes(data.contract_type);

  const url = isConvention ? urlConventions : urlContracts;

  try {
    const response = await axiosClient.put(url, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const storeContractDraft = async (
  contractType: number,
  reference: string,
  internal_reference: string,
  payloads: any
) => {
  try {
    const response = await axiosClient.post(urlPostContractDraft, {
      contract_type: contractType,
      reference,
      internal_reference,
      payloads: JSON.stringify(payloads),
    });
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const upsertConventionModel = async (status: number, payloads: any) => {
  try {
    const response = await axiosClient.post(urlPostModel, {
      status,
      payloads: JSON.stringify(payloads),
    });
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getDraftData = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetContractDraft(id));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getModelData = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetConventionModel(id));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const duplicateContract = async (id: number) => {
  try {
    const response = await axiosClient.post(urlDuplicateContract(id));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

// updating here means new line in table contract with same reference and old one is soft deleted
// as by OPX-1817, only mandant will be considered
export const updateContract = async (data: any) => {
  try {
    const response = await axiosClient.post(urlUpdateContract, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};
