/* eslint-disable no-shadow */

export enum CreationSteps {
  TUNNEL,
  SALE_PROFILE,
  PARTNER_CHOICE,
  GENERAL,
  VOLUMES_CEE,
  PARTNER,
  BENEFICIARY,
  AMO,
  REFERENTS,
  OPERATIONS,
  PAYMENT_DELEGATION,
  BUSINESS,
  PAYMENT,
  DOCUMENTS,
  INVITATION,
}

export enum SaleProfiles {
  BUYER = 1,
  SELLER = 2,
}

export enum ContractTypes {
  CONVENTION = -1,
  MANDAT = 1,
  MANDAT_NON_DEPOSANT = 2,
  VENTE = 3,
  DELEGATION = 4,
  TRIPARTITE = 5,
}

export enum BeneficiaryTypes {
  PERSON = 1,
  ENTITY = 2,
  MIXED = 3,
}

export enum OperationPriceTypes {
  CLASSIQUE_PRECAIRE = 1,
  UNIQUE = 2,
  INCOMES = 3,
}

export enum ConventionModelStatus {
  IN_PROGRESS = 1,
  CREATED = 2,
}
export const PRICE_TYPE = {
  CLASSIQUE: 'classique',
  PRECAIRE: 'precaire',
  INTERMEDIAIRE: 'intermediaire',
  MODESTE: 'modeste',
  UNIQUE: 'unique',
} as const;
