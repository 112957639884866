/* eslint-disable react/no-unstable-nested-components */
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Radio } from '@components/atomic/inputs/controls/Radio';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import { Card } from '@components/Card';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import {
  IContactBusinessProvider,
  IContractBusinessProvider,
} from '@models/contractCreation/utils/contractCreationTypes';
import { getPartnersContactsDetails } from '@models/partners/apiRequests/partnersRequest';
import { ModalAddPartnerForm } from '@models/partners/components/ModalAddPartnerForm';
import { ENTITY_TYPES } from '@utils/roles';
import { BUSINESS_PROVIDERS, BusinessProviderValues } from '@utils/utils';
import {
  // useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function StepIntermediaryBusiness() {
  const { t } = useTranslation();
  const [withProvider, setWithProvider] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [commissionPrice, setCommissionPrice] = useState<string>('');
  const [commissionPercent, setCommissionPercent] = useState<string>('');
  const [commissionPrice_2, setCommissionPrice_2] = useState<string>('');
  const [disabledPercent1, setdisabledPercent1] = useState<boolean>(false);
  const [disabledPercent2, setdisabledPercent2] = useState<boolean>(false);
  const [addedNewContact, setAddedNewContact] = useState<string>('');
  const [commissionPercent_2, setCommissionPercent_2] = useState<string>('');
  const [providerContactsList, setProviderContactsList] = useState<
    IContactBusinessProvider[]
  >([]);

  const [triggeringField, setTriggeringField] =
    useState<BusinessProviderValues | null>(null);
  const entityType = ENTITY_TYPES.APPORTEUR_AFFAIRES;

  const methods = useForm();

  const {
    updateBusinessProvider,
    businessProvider,
    operationPrices,
    changeStep,
    // payloadData,
  } = useContext(ContractCreationContext);

  const businessProviderContacts = async () => {
    const res = await getPartnersContactsDetails(
      ENTITY_TYPES.APPORTEUR_AFFAIRES
    );

    if (res?.linkedContactDetails) {
      setProviderContactsList([...res.linkedContactDetails]);
    }
  };

  const handleCommissionChange = (
    providerKey: BusinessProviderValues,
    field: 'price' | 'percent',
    value: string
  ) => {
    const isPriceField = field === 'price';

    // Parse the input value
    const parsedValue = Number(value.replace(',', '.'));

    setdisabledPercent1(false);
    setdisabledPercent2(false);
    // Update the respective field state
    if (providerKey === BUSINESS_PROVIDERS.PROVIDER_1) {
      if (field === 'price') {
        setCommissionPrice(value);
        setCommissionPercent('');
        if (Number(value.replace(',', '.')) > 0) setdisabledPercent1(true);
      } else {
        setCommissionPercent(value);
        setCommissionPrice('');
      }
    } else if (providerKey === BUSINESS_PROVIDERS.PROVIDER_2) {
      if (field === 'price') {
        setCommissionPrice_2(value);
        setCommissionPercent_2('');
        if (Number(value.replace(',', '.')) > 0) setdisabledPercent2(true);
      } else {
        setCommissionPercent_2(value);
        setCommissionPrice_2('');
      }
    }

    // TODO : adapt as per price type chosen in operation step
    // Compute total operation prices
    const totalPrices = operationPrices.reduce(
      (acc, item) =>
        acc + Number(item.pu_classique || 0) + Number(item.pu_precaire || 0),
      0
    );

    // Calculate the minimum bonus allocation based on the price or percent
    const minimumBonusAllocation = isPriceField
      ? parsedValue
      : (parsedValue / 100) * totalPrices;

    // Safely update the business provider state
    if (updateBusinessProvider) {
      updateBusinessProvider((prevState) =>
        prevState
          ? {
              ...prevState,
              [providerKey as keyof IContractBusinessProvider]: {
                ...prevState[providerKey as keyof IContractBusinessProvider],
                minimum_bonus_allocation: minimumBonusAllocation,
              },
            }
          : prevState
      );
    }
  };

  const setBusinessProvider = (key: BusinessProviderValues, value: string) => {
    const contact = providerContactsList.find(
      (elt) => `${elt.company_name}` === value
    );

    if (contact) {
      const { id, entity_id, minimum_bonus_allocation } = contact;

      updateBusinessProvider((prevState) =>
        prevState
          ? {
              ...prevState,
              [key]: { id, entity_id, minimum_bonus_allocation },
            }
          : {
              business_provider_1: {
                id: 0,
                entity_id: 0,
                minimum_bonus_allocation: 0,
              },
              business_provider_2: {
                id: 0,
                entity_id: 0,
                minimum_bonus_allocation: 0,
              },
              [key]: { id, entity_id, minimum_bonus_allocation },
            }
      );
    }
  };

  const getName = (contact: IContactBusinessProvider) =>
    `${contact.company_name}`;

  const filteredBusinessProviderArrayList = useMemo(() => {
    return providerContactsList
      .filter(
        (elt) =>
          elt.entity_id !== businessProvider?.business_provider_1?.entity_id &&
          elt.entity_id !== businessProvider?.business_provider_2?.entity_id
      )
      .map((elt) => getName(elt));
  }, [
    providerContactsList,
    businessProvider?.business_provider_1,
    businessProvider?.business_provider_2,
  ]);

  const getBusinessProviderContact = (key: BusinessProviderValues) => {
    if (!businessProvider) {
      return '';
    }
    const contact = providerContactsList.find(
      (elt) =>
        elt.entity_id ===
        businessProvider[key as keyof IContractBusinessProvider]?.entity_id
    );
    if (!contact) {
      return '';
    }
    return getName(contact);
  };

  const validateProvider = (
    providerId: number | '',
    price: string,
    percent: string
  ): boolean => {
    return (
      !!providerId && // Ensure provider is selected
      (price.trim() !== '' || percent.trim() !== '') && // At least one commission is filled
      (!Number(price.replace(',', '.')) ||
        !Number(percent.replace(',', '.'))) &&
      Number(price.replace(',', '.')) !== 0 // Validate value
    );
  };

  // used to activate/disable the next button
  const isDisabled = useMemo(() => {
    if (!withProvider) {
      return false;
    } // No provider, no need to check further

    const selectedProvider1 =
      businessProvider?.business_provider_1?.entity_id || '';
    const selectedProvider2 =
      businessProvider?.business_provider_2?.entity_id || '';

    const isProvider1Valid = validateProvider(
      selectedProvider1,
      commissionPrice,
      commissionPercent
    );
    const isProvider2Valid = validateProvider(
      selectedProvider2,
      commissionPrice_2,
      commissionPercent_2
    );

    if (isProvider1Valid && !selectedProvider2) {
      return false; // Enable Next if Provider 1 is valid and Provider 2 is empty
    }

    return !(isProvider1Valid && isProvider2Valid); // Disable otherwise
  }, [
    withProvider,
    businessProvider,
    commissionPrice,
    commissionPercent,
    commissionPrice_2,
    commissionPercent_2,
  ]);

  const onClickNext = async () => {
    if (!withProvider) {
      updateBusinessProvider(null);
    }
    changeStep('next');
  };

  const resetValues = () => {
    // Reset commission fields
    setCommissionPrice_2(''); // Clear commissionPrice_2
    setCommissionPercent_2(''); // Clear commissionPercent_2

    updateBusinessProvider((prevState) =>
      prevState
        ? {
            ...prevState,
            business_provider_2: null,
          }
        : null
    );
  };

  const handleModalClose = async (newContact: any) => {
    setModal(false);

    if (newContact) {
      setAddedNewContact(newContact.company_name);
    }
  };

  useEffect(() => {
    if (
      providerContactsList.length > 0 &&
      addedNewContact !== '' &&
      triggeringField
    ) {
      setBusinessProvider(triggeringField, addedNewContact);
    }
  }, [providerContactsList, triggeringField]);

  // Load initial values
  useEffect(() => {
    if (businessProvider?.business_provider_1) {
      setCommissionPrice(
        businessProvider.business_provider_1.minimum_bonus_allocation?.toString() ||
          ''
      );
    }
    if (businessProvider?.business_provider_2) {
      setCommissionPrice_2(
        businessProvider.business_provider_2.minimum_bonus_allocation?.toString() ||
          ''
      );
    }
  }, [businessProvider]);

  useEffect(() => {
    // Check if business_provider_1 or business_provider_2 is set in businessProvider
    if (
      businessProvider?.business_provider_1?.entity_id ||
      businessProvider?.business_provider_2?.entity_id
    ) {
      // If any provider is set, set 'withProvider' to true
      setWithProvider(true);
    } else {
      // Otherwise, set 'withProvider' to false
      setWithProvider(false);
    }
  }, [businessProvider]); // Dependency on businessProvider, so it runs whenever businessProvider changes

  useEffect(() => {
    businessProviderContacts();
  }, []);

  return (
    <Card
      title={t('contract.business.title')}
      actionButtons={
        <div className="flex gap-3">
          <ButtonOpx
            type="secondary"
            label={`${t('global.back')}`}
            onClick={() => changeStep('back')}
          />
          <ButtonOpx
            label={`${
              withProvider === false
                ? t('contract.business.continue_without')
                : t('buttons.next')
            }`}
            onClick={onClickNext}
            disabled={isDisabled}
            dataTestId="button_next"
          />
        </div>
      }
    >
      <div>
        <FormProvider {...methods}>
          <Radio
            name="withProvider"
            value="withProvider"
            onSelect={() => setWithProvider(true)}
            label={`${t('contract.business.with')}`}
            isChecked={!!withProvider}
            colorText="black"
            addClass="border rounded-default focus:outline-none w-full p-2 flex items-center gap-2 blueOpx mb-2"
            dataTestId="with_business"
          />
          <Radio
            name="withoutProvider"
            value="withoutProvider"
            onSelect={() => {
              setWithProvider(false);
            }}
            label={`${t('contract.business.without')}`}
            isChecked={withProvider === false}
            colorText="black"
            addClass="border rounded-default focus:outline-none w-full p-2 flex items-center gap-2 blueOpx"
            dataTestId="without_business"
          />
          {!!withProvider && (
            <form>
              <div className="border border-solid border-transparent border-b-borderGrey py-4">
                <div className="mt-[1rem] mb-[1.5rem]">
                  <p className="w-full font-medium text-[1rem]">{`${t(
                    'contract.business.identity_1'
                  )}`}</p>
                  <p className="text-[0.875rem]">
                    {t('contract.business.select_business_provider')}
                  </p>
                </div>
                <div className="flex  flex-col gap-4 grid md:grid-cols-[65%_33.5%]">
                  <div className="text-[.75rem] leading-[.75rem]">
                    <InputSelect
                      label={String(
                        t('contract.business.selected_field.title')
                      )}
                      placeholder={t(
                        'contract.business.selected_field.placeholder'
                      )}
                      defaultSelected={
                        t('contract.business.selected_field.placeholder') || ''
                      }
                      dataArrayString={filteredBusinessProviderArrayList}
                      onSelect={(e) =>
                        setBusinessProvider(
                          BUSINESS_PROVIDERS.PROVIDER_1,
                          String(e)
                        )
                      }
                      required
                      dataTestIdSelect="select_business_provider_1"
                      dataTestIdOptions="business_provider_1"
                      valueInput={getBusinessProviderContact(
                        BUSINESS_PROVIDERS.PROVIDER_1
                      )}
                    />
                  </div>
                  <div>
                    <ButtonOpx
                      onClick={() => {
                        setTriggeringField(BUSINESS_PROVIDERS.PROVIDER_1);
                        setModal(true);
                      }}
                      label={t('partners.business_provider.modal_add.title')}
                      type="primary"
                      addClass="w-full mt-[1.25rem]"
                      dataTestId="button_add_new_business_provider"
                    />
                  </div>
                </div>

                <div className="mt-[1.5rem]">
                  <div className="mt-[1rem] mb-[1.5rem]">
                    <p className="w-full font-medium text-[1rem]">{`${t(
                      'contract.business.commission'
                    )}`}</p>
                    <p className="text-[0.875rem]">
                      {t('contract.business.commission_choice_title')}
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <InputText
                      id="commissionPrice_1"
                      name="commissionPrice_1"
                      placeholder="€/MWhc"
                      label="€/MWhc"
                      onChange={(e) =>
                        handleCommissionChange(
                          BUSINESS_PROVIDERS.PROVIDER_1,
                          'price',
                          typeof e === 'string' ? e : e.target.value
                        )
                      }
                      value={commissionPrice}
                      disabled={Number(commissionPercent.replace(',', '.')) > 0}
                      required
                      typeNumber
                    />

                    <InputText
                      id="commissionPercent_1"
                      name="commissionPercent_1"
                      placeholder={`${t('contract.business.percent')}`}
                      label={`${t('contract.business.percent')}`}
                      onChange={(e) =>
                        handleCommissionChange(
                          BUSINESS_PROVIDERS.PROVIDER_1,
                          'percent',
                          typeof e === 'string' ? e : e.target.value
                        )
                      }
                      value={commissionPercent}
                      error={Number(commissionPercent.replace(',', '.')) > 100}
                      disabled={disabledPercent1}
                      required
                      typeNumber
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-[1rem] mb-[1.5rem]">
                  <p className="w-full font-medium text-[1rem]">{`${t(
                    'contract.business.identity_2'
                  )}`}</p>
                  <p className="text-[0.875rem]">
                    {t('contract.business.select_business_provider')}
                  </p>
                </div>
                <div className="flex  flex-col gap-4 grid md:grid-cols-[65%_33.5%]">
                  <div className="text-[.75rem] leading-[.75rem]">
                    <InputSelect
                      label={String(
                        t('contract.business.selected_field.title')
                      )}
                      placeholder={t(
                        'contract.business.selected_field.placeholder'
                      )}
                      defaultSelected={
                        t('contract.business.selected_field.placeholder') || ''
                      }
                      dataArrayString={filteredBusinessProviderArrayList}
                      onSelect={(e) =>
                        setBusinessProvider(
                          BUSINESS_PROVIDERS.PROVIDER_2,
                          String(e)
                        )
                      }
                      required
                      dataTestIdSelect="select_business_provider_2"
                      dataTestIdOptions="business_provider_2"
                      valueInput={getBusinessProviderContact(
                        BUSINESS_PROVIDERS.PROVIDER_2
                      )}
                      onClear={() => resetValues()}
                      showClearButton={
                        !!businessProvider?.business_provider_2?.entity_id
                      }
                    />
                  </div>
                  <div>
                    <ButtonOpx
                      onClick={() => {
                        setTriggeringField(BUSINESS_PROVIDERS.PROVIDER_2);
                        setModal(true);
                      }}
                      label={t('partners.business_provider.modal_add.title')}
                      type="primary"
                      addClass="w-full mt-[1.25rem]"
                      dataTestId="button_add_new_business_provider"
                    />
                  </div>
                </div>

                <div className="mt-[1.5rem]">
                  <div className="mt-[1rem] mb-[1.5rem]">
                    <p className="w-full font-medium text-[1rem]">{`${t(
                      'contract.business.commission'
                    )}`}</p>
                    <p className="text-[0.875rem]">
                      {t('contract.business.commission_choice_title')}
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <InputText
                      id="commissionPrice_2"
                      name="commissionPrice_2"
                      placeholder="€/MWhc"
                      label="€/MWhc"
                      onChange={(e) =>
                        handleCommissionChange(
                          BUSINESS_PROVIDERS.PROVIDER_2,
                          'price',
                          typeof e === 'string' ? e : e.target.value
                        )
                      }
                      value={commissionPrice_2}
                      disabled={
                        Number(commissionPercent_2.replace(',', '.')) > 0
                      }
                      required
                      typeNumber
                    />
                    <InputText
                      id="commissionPercent_2"
                      name="commissionPercent_2"
                      placeholder={`${t('contract.business.percent')}`}
                      label={`${t('contract.business.percent')}`}
                      onChange={(e) =>
                        handleCommissionChange(
                          BUSINESS_PROVIDERS.PROVIDER_2,
                          'percent',
                          typeof e === 'string' ? e : e.target.value
                        )
                      }
                      value={commissionPercent_2}
                      error={
                        Number(commissionPercent_2.replace(',', '.')) > 100
                      }
                      disabled={disabledPercent2}
                      required
                      typeNumber
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </FormProvider>
        {modal && (
          <ModalAddPartnerForm
            setModal={setModal}
            refreshList={businessProviderContacts}
            entityType={entityType}
            onClose={handleModalClose}
          />
        )}
      </div>
    </Card>
  );
}

export { StepIntermediaryBusiness };
