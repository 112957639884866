import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { SetStateAction, Dispatch, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { IElementData } from '@models/settings/utils/settingsTypes';
import SquarePicker from './SquarePicker';

interface ColorPickerProps {
  name: string;
  label: string;
  initialColor: string;
  initialTextColor: 'white' | 'black';
  setParentColor: (color: string) => void;
  setParentTextColor: (color: 'white' | 'black') => void;
  onUpdateElement: Dispatch<SetStateAction<IElementData[]>>;
}

function ColorPicker({
  name,
  label,
  initialColor,
  initialTextColor,
  setParentColor,
  setParentTextColor,
  onUpdateElement,
}: ColorPickerProps) {
  const { t } = useTranslation();
  const [color, setColor] = useState(initialColor);
  const [textColor, setTextColor] = useState<'white' | 'black'>(
    initialTextColor
  );

  const handleChange = (newColor: any) => {
    setColor(newColor.hex);
  };

  const standardColors = [
    '#FFB6C1', // Rose clair
    '#87CEFA', // Bleu ciel
    '#98FB98', // Vert pâle
    '#FFD700', // Or
    '#DA70D6', // Orchidée
    '#FFA07A', // Saumon clair
    '#20B2AA', // Vert sarcelle
    '#9370DB', // Violet moyen
    '#FF6347', // Tomate
    '#4682B4', // Bleu acier
  ];

  const handleTextColorChange = (newTextColor: string) => {
    const newTextColorValue = newTextColor as 'white' | 'black';
    setTextColor(newTextColorValue);
  };

  useEffect(() => {
    setParentColor(color);
    setParentTextColor(textColor);
    onUpdateElement((prevElements: IElementData[]) => {
      const otherElements = prevElements.filter(
        (element) => element.element !== name
      );
      return [
        ...otherElements,
        { element: name, background_color: color, text_color: textColor },
      ];
    });
  }, [color, textColor]);

  return (
    <div className="space-y-[1.12rem]">
      <p className="font-medium">{label}</p>
      <div className="flex space-x-8 bg-backgroundBody rounded-default p-6 w-[33.8125rem]">
        <ChromePicker
          color={color}
          onChange={handleChange}
          disableAlpha
          className="!w-1/2"
        />
        <div className="flex flex-grow flex-col space-y-4">
          <div
            className="w-full h-[5rem] rounded-default"
            style={{ backgroundColor: color, color: textColor }}
          />
          <SquarePicker
            selectedColor={color}
            onColorChange={(colorSelected) => setColor(colorSelected)}
            colors={standardColors}
          />
          <div className="space-y-2">
            <p className="text-textGrey text-[0.875rem]">
              {t('settings.white_label.text_color')}
            </p>
            <div className="flex w-full space-x-6">
              <TextWithRadio
                label={t('settings.white_label.black_text')}
                value="black"
                isChecked={textColor === 'black'}
                selectValue={textColor}
                setSelectedValue={handleTextColorChange}
                textColorClass="text-textGrey"
                addClass="!border-none"
                addClassButton="!p-0"
                withoutName
              />
              <TextWithRadio
                label={t('settings.white_label.white_text')}
                value="white"
                isChecked={textColor === 'white'}
                selectValue={textColor}
                setSelectedValue={handleTextColorChange}
                textColorClass="text-textGrey"
                addClass="!border-none"
                addClassButton="!p-0"
                withoutName
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColorPicker;
