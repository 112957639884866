import { blueOpx, red } from '@assets/color';
import { ChangeIcon, NotificationsIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { useContext } from 'react';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';

function ButtonsRequestsAndChanges() {
  const {
    updateRequestOrChangeEnumInModal,
    requestOrChangeEnumInModal,
    listRequestsGroupAll,
  } = useContext(RequestAndActivityIntoModalOrCardContext);

  // Filter the todo list where is_read false ( note not yet read) and where task not yet processed
  const unReadTodoRequests = listRequestsGroupAll.todo?.filter(
    (request) =>
      (request.is_read === undefined && request.relation_type !== 'note') ||
      request.is_read === false
  );

  const totalRequest = unReadTodoRequests?.length || 0;
  return (
    <div className="flex items-center space-x-[1rem]">
      <ColorCube
        numberOrIcon={totalRequest || <NotificationsIcon />}
        color={
          unReadTodoRequests && unReadTodoRequests.length > 0 ? red : blueOpx
        }
        opacity={
          requestOrChangeEnumInModal !== RequestOrMessageOrChangeEnum.REQUEST
        }
        size="2.5rem"
        onClick={() =>
          updateRequestOrChangeEnumInModal(
            requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.REQUEST
              ? null
              : RequestOrMessageOrChangeEnum.REQUEST
          )
        }
      />
      <ColorCube
        numberOrIcon={<ChangeIcon />}
        color={blueOpx}
        opacity={
          requestOrChangeEnumInModal !== RequestOrMessageOrChangeEnum.CHANGE
        }
        size="2.5rem"
        onClick={() =>
          updateRequestOrChangeEnumInModal(
            requestOrChangeEnumInModal === RequestOrMessageOrChangeEnum.CHANGE
              ? null
              : RequestOrMessageOrChangeEnum.CHANGE
          )
        }
      />
    </div>
  );
}

export { ButtonsRequestsAndChanges };
