import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { blueOpx, red, textGrey, white } from '@assets/color';
import { useTranslation } from 'react-i18next';
import {
  ChangeIcon,
  ChatBubbleIcon,
  ChatUnreadIcon,
  ChevronLeftIcon,
  NotificationsIcon,
  SupportContactIcon,
} from '@assets/images/svgComponents';
import { useContext, useEffect, useMemo, useState } from 'react';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { useParams } from 'react-router-dom';
import { ColorCube } from '@components/atomic/ColorCube';
import { AuthContext } from '@context/authContext';
import { ENTITY_TYPES } from '@utils/roles';
import { ThemeContext } from '@context/ThemeContext';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { convertHexToRGBA } from '../../../utils/functions';

interface HeaderCardRequestAndChangesProps {
  onDisplayControleContactPanel: () => void;
}

function HeaderCardRequestAndChanges({
  onDisplayControleContactPanel,
}: HeaderCardRequestAndChangesProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    requestOrChangeEnumInCard,
    updateRequestOrChangeEnumInCard,
    listRequests,
    isLoadingListRequestOrChange,
    listMessagesRequests,
    updateRequestOrChangeTab,
    requestOrChangeTab,
    updateDetailRequest,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { user } = useContext(AuthContext);
  const { themeData } = useContext(ThemeContext);
  const { worksiteDetails } = useContext(WorksitesContext);

  const secondaryElement = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
  );

  const [idWorksiteEnumInitialised, setIdWorksiteEnumInitialised] =
    useState<string>();

  const change =
    requestOrChangeTab === RequestOrMessageOrChangeEnum.CHANGE ||
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.CHANGE;
  const request =
    requestOrChangeTab === null &&
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.REQUEST;
  const message =
    requestOrChangeTab === null &&
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.MESSAGE;

  const requestIconColor = useMemo(() => {
    if (!request)
      return {
        color: listRequests.length > 0 ? red : textGrey,
        backgroundColor: listRequests.length > 0 ? red : textGrey,
      };
    return {
      color:
        listRequests.length > 0 ? red : secondaryElement?.text_color || blueOpx,
      backgroundColor:
        listRequests.length > 0
          ? red
          : secondaryElement?.background_color || blueOpx,
    };
  }, [listRequests, request]);

  const listRequestsIsInitialized =
    listRequests.length === 0 ||
    (listRequests.length > 0 &&
      listRequests[0].id !== 0 &&
      listRequests.some((el) => el.relation_id === Number(id)));

  const listMessagesRequestsIsInitialized =
    listMessagesRequests.length === 0 ||
    (listMessagesRequests.length > 0 &&
      listMessagesRequests[0].id !== 0 &&
      listMessagesRequests.some((el) => el.relation_id === Number(id)));

  useEffect(() => {
    /* On check si les points suivants sont true :
     * - l'id du worksite a changé
     * - la liste des requests est initialisée
     * - la liste des messages est initialisée
     */
    if (
      idWorksiteEnumInitialised !== id &&
      listRequestsIsInitialized &&
      listMessagesRequestsIsInitialized
    ) {
      // Quand on arrive sur la page, on veut afficher les requests si il y en a, sinon les messages s'il y en a, sinon les activités
      if (listRequests.length > 0 && listRequests[0].id !== 0) {
        updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.REQUEST);
        setIdWorksiteEnumInitialised(id);
      } else if (
        listMessagesRequests.length > 0 &&
        listMessagesRequests[0].id !== 0
      ) {
        updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.MESSAGE);
        setIdWorksiteEnumInitialised(id);
      } else {
        updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.CHANGE);
      }
    }
  }, [listRequests, listMessagesRequests]);

  return (
    <div className="grid grid-cols-[1fr_auto] w-full">
      {/* Colonne de gauche (les notifications) */}
      <div className="p-6 border-b border-b-borderGrey">
        <div className="flex items-center justify-between mt-1">
          {!isLoadingListRequestOrChange ? (
            <>
              <ButtonOpx
                icon={<NotificationsIcon />}
                color={requestIconColor.color}
                backgroundColor={
                  request
                    ? convertHexToRGBA(requestIconColor.backgroundColor, 0.1)
                    : white
                }
                label={`${listRequests.length} ${t(
                  'requests_and_changes.request'
                )}${listRequests.length > 1 ? 's' : ''}`}
                addClass="!border-none"
                onClick={() => {
                  updateRequestOrChangeEnumInCard(
                    RequestOrMessageOrChangeEnum.REQUEST
                  );
                  updateRequestOrChangeTab(null);
                  updateDetailRequest(null);
                }}
              />
              <ButtonOpx
                icon={
                  listMessagesRequests.length > 0 ? (
                    <ChatUnreadIcon />
                  ) : (
                    <ChatBubbleIcon />
                  )
                }
                color={
                  message ? secondaryElement?.text_color || blueOpx : textGrey
                }
                backgroundColor={
                  message
                    ? convertHexToRGBA(
                        secondaryElement?.background_color || blueOpx,
                        0.1
                      )
                    : white
                }
                label={`${listMessagesRequests.length} ${t('global.message')}${
                  listMessagesRequests.length > 1 ? 's' : ''
                }`}
                onClick={() => {
                  updateRequestOrChangeEnumInCard(
                    RequestOrMessageOrChangeEnum.MESSAGE
                  );
                  updateRequestOrChangeTab(null);
                  updateDetailRequest(null);
                }}
                addClass="!border-none"
              />
              <ButtonOpx
                icon={<ChangeIcon />}
                color={
                  change ? secondaryElement?.text_color || blueOpx : textGrey
                }
                backgroundColor={
                  change
                    ? convertHexToRGBA(
                        secondaryElement?.background_color || blueOpx,
                        0.1
                      )
                    : white
                }
                label={t('requests_and_changes.changes')}
                onClick={() => {
                  updateRequestOrChangeEnumInCard(
                    RequestOrMessageOrChangeEnum.CHANGE
                  );
                  updateRequestOrChangeTab(null);
                }}
                addClass="!border-none"
              />
            </>
          ) : (
            <>
              <LoaderDivSkeleton height="2.5rem" width="8rem" />
              <LoaderDivSkeleton height="2.5rem" width="8rem" />
              <LoaderDivSkeleton height="2.5rem" width="8rem" />
            </>
          )}
        </div>
      </div>

      {/* Colonne de droite (Contrôle Contact) reservée aux OBLIGE, DELEGATAIRE et MANDATAIRE */}
      {user &&
        user.current_entity_type &&
        user.permissions_names.includes('controle-contact') &&
        worksiteDetails &&
        worksiteDetails.status >= 4 &&
        [
          ENTITY_TYPES.DELEGATAIRE,
          ENTITY_TYPES.MANDATAIRE,
          ENTITY_TYPES.OBLIGE,
        ].includes(user.entity_type) && (
          <div className="p-4 border-l border-b border-l-borderGrey flex flex-col items-center justify-center space-y-2">
            <ColorCube
              color={blueOpx}
              size="2rem"
              opacity
              numberOrIcon={<SupportContactIcon />}
            />
            <ButtonOpx
              label=""
              isOnlyIcon
              type="primary"
              icon={<ChevronLeftIcon />}
              small
              onClick={() => onDisplayControleContactPanel()}
            />
          </div>
        )}
    </div>
  );
}

export { HeaderCardRequestAndChanges };
