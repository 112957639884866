import { axiosClient } from '@api/apiClient';
// import { ISubcontractor } from '@models/partners/utils/types/subcontractorsType';
import { displayError } from '@utils/format';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';
import {
  urlGetSubcontractorInfos,
  urlGetSubcontractorWorksites,
  urlPostIsMySubcontractor,
  urlPostPartnerInfos,
  urlPostSubcontractor,
  //  urlPostSubcontractorInfos,
} from '@api/apiUrls';
import { IPayloadCreateSubcontractor } from '@models/worksiteCreation/utils/types/worksitesType';
import { IUpsertPartner } from '../utils/types/partnersType';

export const getSubcontractorInfos = async (
  installerId: number,
  entityId: number
) => {
  try {
    const response = await axiosClient.get(
      urlGetSubcontractorInfos(installerId, entityId)
    );
    return response.data;
  } catch (error) {
    toast.error(String(error.response.data.errors));
  }
  return false;
};

export const updateSubcontractorInfos = async (
  entityId: number,
  userId: number,
  data: Partial<IUpsertPartner>
) => {
  try {
    await axiosClient.post(urlPostPartnerInfos(entityId, userId), data);
    return true;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getSubcontractorWorksites = async (
  id: number,
  page: number,
  perPage: number
) => {
  try {
    const response = await axiosClient.post(urlGetSubcontractorWorksites(id), {
      pagination: { page, perPage },
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const storeSubcontractor = async (
  data: IPayloadCreateSubcontractor,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setModal: Dispatch<SetStateAction<boolean>>,
  refreshList: CallableFunction,
  worksiteId?: number
): Promise<string> => {
  try {
    await axiosClient.put(urlPostSubcontractor(worksiteId), data);
    setModal(false);
    refreshList();
    return '';
  } catch (error) {
    setLoading(false);
    return error.response.data.errors;
  }
};

export const checkIsMySubcontractor = async (id: number) => {
  try {
    const response = await axiosClient.post(urlPostIsMySubcontractor(id));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};
