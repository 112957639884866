import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Modal } from '@components/Modal';
import { InputText } from '@components/atomic/inputs/InputText';
import { FormProvider, useForm } from 'react-hook-form';
import { numFormatSpace, roundFormat } from '@utils/functions';
import { Tag } from '@components/atomic/Tag';
import { green, red } from '@assets/color';

interface ModalPrimesDistributionProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  totalCeeBonus: number;
  beneficiaryAmount: number;
  setBeneficiaryAmount: Dispatch<SetStateAction<number>>;
  installerAmount: number;
  beneficiaryAmountMinimum: number;
  isLoading?: boolean;
  isAsync?: boolean;
}
function ModalPrimesDistribution({
  setOpen,
  totalCeeBonus,
  beneficiaryAmount,
  setBeneficiaryAmount,
  installerAmount,
  beneficiaryAmountMinimum,
  isLoading,
  isAsync,
}: ModalPrimesDistributionProps) {
  const { t } = useTranslation();

  const methods = useForm();

  const { watch, setValue } = methods;

  const formValues = watch();

  const beneficiaryAmountMinimumPercent =
    (beneficiaryAmountMinimum / totalCeeBonus) * 100;
  const installerAmountMaximumPercent = 100 - beneficiaryAmountMinimumPercent;

  const beneficiaryAmountToSet = useMemo(() => {
    if (watch('beneficiary_amount') === '') {
      return 0;
    }
    if (watch('beneficiary_amount')) {
      return parseFloat(watch('beneficiary_amount'));
    }
    return beneficiaryAmount;
  }, [watch('beneficiary_amount'), beneficiaryAmount]);

  const beneficiaryAmountPercent =
    (beneficiaryAmountToSet / totalCeeBonus) * 100;
  const installerAmountPercent = 100 - beneficiaryAmountPercent;

  const beneficiaryAmountError =
    beneficiaryAmountPercent < beneficiaryAmountMinimumPercent;
  const installerAmountError =
    installerAmountPercent > installerAmountMaximumPercent;

  const handleClose = () => {
    if (setOpen) setOpen(false);
  };

  const handleSubmit = () => {
    setBeneficiaryAmount(
      Number(formValues.beneficiary_amount || beneficiaryAmount)
    );
    if (!isAsync) setOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <Modal
        title={t('worksite_creation.simulation.summary.bonus_repartition')}
        setIsModal={handleClose}
        btnCancel
        textBtnConfirm={t('buttons.save') || ''}
        btnConfirmDisabled={beneficiaryAmountError || installerAmountError}
        backgroundTransparent
        onConfirmClick={handleSubmit}
        isLoading={isLoading}
      >
        <form id="form-primes-distribution">
          <div className="p-6 w-full flex justify-around space-x-6">
            <InputText
              id="total_cee_bonus"
              name="total_cee_bonus"
              label={
                t('worksite_creation.simulation.summary.total_cee_bonus') || ''
              }
              placeholder=""
              readOnly
              valueInitialInput={roundFormat(totalCeeBonus)}
              icon="€"
            />
            <div className="w-full space-y-2">
              <InputText
                id="beneficiary_amount"
                name="beneficiary_amount"
                label={
                  t(
                    'worksite_creation.simulation.summary.beneficiary_amount'
                  ) || ''
                }
                placeholder=""
                typeNumber
                valueInitialInput={roundFormat(beneficiaryAmount)}
                icon="€"
                onChange={(value) => {
                  setValue(
                    'installer_amount',
                    roundFormat(totalCeeBonus - Number(value))
                  );
                }}
                addClassToInput={
                  beneficiaryAmountError ? 'border-red' : 'border-green'
                }
                maxNumber={totalCeeBonus}
              />
              <Tag
                addClass="w-full"
                color={beneficiaryAmountError ? red : green}
                label={t(
                  'worksite_creation.simulation.summary.beneficiary_amount_percentage_information',
                  {
                    beneficiaryAmountPercent: numFormatSpace(
                      roundFormat(beneficiaryAmountPercent)
                    ),
                    beneficiaryAmountMinimumPercent: numFormatSpace(
                      roundFormat(beneficiaryAmountMinimumPercent)
                    ),
                  }
                )}
              />
            </div>
            <div className="w-full space-y-2">
              <InputText
                id="installer_amount"
                name="installer_amount"
                label={
                  t('worksite_creation.simulation.summary.installer_amount') ||
                  ''
                }
                placeholder=""
                valueInitialInput={roundFormat(installerAmount)}
                typeNumber
                icon="€"
                onChange={(value) => {
                  setValue(
                    'beneficiary_amount',
                    roundFormat(totalCeeBonus - Number(value))
                  );
                }}
                addClassToInput={
                  installerAmountError ? 'border-red' : 'border-green'
                }
                maxNumber={totalCeeBonus}
              />
              <Tag
                addClass="w-full"
                color={installerAmountError ? red : green}
                label={t(
                  'worksite_creation.simulation.summary.installer_amount_percentage_information',
                  {
                    installerAmountPercent: numFormatSpace(
                      roundFormat(installerAmountPercent)
                    ),
                    installerAmountMaximumPercent: numFormatSpace(
                      roundFormat(installerAmountMaximumPercent)
                    ),
                  }
                )}
              />
            </div>
          </div>
        </form>
      </Modal>
    </FormProvider>
  );
}

ModalPrimesDistribution.defaultProps = { isLoading: false, isAsync: false };

export default ModalPrimesDistribution;
