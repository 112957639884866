import { Tag } from '@components/atomic/Tag';
import { blueSecondary, textGrey } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { dateToDDMMYYY } from '@utils/format';
import { useNavigate } from 'react-router-dom';
import { PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { IControlContactType } from '../utils/controlContactTypes';
import { controlContactStatus, controlContactStatusEnum } from '../utils/utlis';

function ControlContactCard({
  controlContact,
}: {
  controlContact: IControlContactType;
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tagColor = useMemo(() => {
    if (controlContact.status === 2) return blueSecondary;

    return textGrey;
  }, [controlContact]);

  return (
    <div
      className="w-full bg-white border border-borderGrey rounded-default flex items-center justify-center flex-wrap my-4
        cursor-pointer"
      aria-hidden="true"
      onClick={() => {
        if (controlContact.status === controlContactStatusEnum.LOCKED) {
          navigate(
            PARTNERS_ROUTES_WITH_ID(controlContact.id).CONTROL_CONTACT_LOCKED
          );
        } else {
          navigate(
            PARTNERS_ROUTES_WITH_ID(controlContact.id).CONTROL_CONTACT_VIEW
          );
        }
      }}
    >
      <div className="w-full md:w-[30%] md:border-r md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex flex-wrap items-center justify-start mb-[0.875rem]">
          <span className="text-[1.25rem] font-medium mr-3">
            {controlContact.reference_intern}
          </span>
          <Tag
            label={String(controlContact.id)}
            color={textGrey}
            withBorder={false}
          />
        </div>
      </div>
      <div className="w-full md:w-[70%] border-t md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex justify-between items-center">
          <div className="flex gap-2">
            {controlContact.reference_cofrac && (
              <>
                <Tag label="COFRAC" color={textGrey} withBorder={false} />
                <div>{controlContact.reference_cofrac}</div>
              </>
            )}
          </div>
          <div className="flex gap-2">
            <Tag
              label={controlContact.operation_type.code}
              color={textGrey}
              withBorder={false}
            />
            <div>{controlContact.operation_type.description}</div>
          </div>
        </div>
      </div>
      <div className="w-full p-[1rem] border-t border-borderGrey flex flex-justify-between">
        <div className="w-full">
          <div className="flex items-center">
            <Tag
              label={
                controlContactStatus(t)[controlContact.status as 1 | 2 | 3]
              }
              color={tagColor}
              addClass="mr-3"
            />
            <span className="mr-3 text-[0.875rem]">{`${t(
              'control_contact.card.last_update'
            )}: ${dateToDDMMYYY(String(controlContact.updated_at))}`}</span>
          </div>
        </div>
        <div className="w-full">
          <div className="flex justify-end">
            <div className="mr-3 text-[0.875rem]">
              <span>{`${t('control_contact.card.regulatory_rate')} : `}</span>
              <span className="font-medium">
                {controlContact.regulated_rate}
              </span>
            </div>
            <div className="mr-3 text-[0.875rem]">
              <span>{`${t('control_contact.card.total_operations')} : `}</span>
              <span className="font-medium">{`${controlContact.nb_total_operations}`}</span>
            </div>
            <div className="mr-3 text-[0.875rem]">
              <span>{t('control_contact.card.to_control')} :</span>&nbsp;
              <span className="text-iconBlue">{`${controlContact.nb_required_operations}`}</span>
            </div>
            <div className="mr-3 text-[0.875rem]">
              <span>{t('control_contact.card.control_done')} :</span>&nbsp;
              <span className="text-purple">{`${controlContact.nb_controlled_operations}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ControlContactCard };
