/* eslint-disable @typescript-eslint/no-unused-vars */
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { OneOperationCard } from '@models/worksiteCreation/components/simulation/stepChoiceOperation/OneOperationCard';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

interface ColorPickerSectionProps {
  primaryColor: string;
  primaryTextColor: 'white' | 'black';
  secondaryColor: string;
  secondaryTextColor: 'white' | 'black';
  setPrimaryColor: (color: string) => void;
  setPrimaryTextColor: (color: 'white' | 'black') => void;
  setSecondaryColor: (color: string) => void;
  setSecondaryTextColor: (color: 'white' | 'black') => void;
}

interface ColorPickerProps {
  initialColor: string;
  initialTextColor: 'white' | 'black';
  setParentColor: (color: string) => void;
  setParentTextColor: (color: 'white' | 'black') => void;
}

// Composant ColorPicker interne
function ColorPicker({
  initialColor,
  initialTextColor,
  setParentColor,
  setParentTextColor,
}: ColorPickerProps) {
  const { t } = useTranslation();

  const handleChange = (newColor: any) => {
    setParentColor(newColor.hex);
  };

  const handleTextColorChange = (newTextColor: string) => {
    const newTextColorValue = newTextColor as 'white' | 'black';
    setParentTextColor(newTextColorValue);
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col bg-backgroundBody rounded-default p-4">
        {/* ChromePicker pour sélection de couleur */}
        <ChromePicker
          color={initialColor}
          onChange={handleChange}
          disableAlpha
          styles={{
            default: {
              picker: {
                width: '100% !important',
              },
            },
          }}
          className="!w-full"
        />

        {/* Sélection de la couleur du texte */}
        <div className="space-y-2 mt-4">
          <p className="text-textGrey text-[0.875rem]">
            {t('settings.white_label.text_color')}
          </p>
          <div className="flex space-x-6">
            <TextWithRadio
              label={t('settings.white_label.black_text')}
              value="black"
              isChecked={initialTextColor === 'black'}
              selectValue={initialTextColor}
              setSelectedValue={handleTextColorChange}
              textColorClass="text-textGrey"
              addClass="!border-none"
              addClassButton="!p-0"
              withoutName
            />
            <TextWithRadio
              label={t('settings.white_label.white_text')}
              value="white"
              isChecked={initialTextColor === 'white'}
              selectValue={initialTextColor}
              setSelectedValue={handleTextColorChange}
              textColorClass="text-textGrey"
              addClass="!border-none"
              addClassButton="!p-0"
              withoutName
            />
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Composant affichant deux colorPickers avec prévisualisations,
 * calqué sur ButtonElements de WhiteLabel
 */
export function ColorPickerSection({
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
  setPrimaryColor,
  setPrimaryTextColor,
  setSecondaryColor,
  setSecondaryTextColor,
}: ColorPickerSectionProps) {
  const { t } = useTranslation();

  // Fonction vide pour le handleCheck de OneOperationCard
  const handleCheckNoOp = () => {
    return null;
  };

  return (
    <div>
      <p className="font-medium mb-6">{t('settings.iframes.color_settings')}</p>
      <div className="flex space-x-6">
        {/* Section principale */}
        <div className="border border-borderGrey p-6 w-1/2 space-y-4 rounded-default">
          <p className="font-medium">{t('settings.iframes.primary_color')}</p>
          <div className="grid grid-cols-5 gap-6 items-start">
            <div className="col-span-2">
              <ColorPicker
                initialColor={primaryColor}
                initialTextColor={primaryTextColor}
                setParentColor={setPrimaryColor}
                setParentTextColor={setPrimaryTextColor}
              />
            </div>
            <div className="flex flex-col justify-center col-span-3">
              <p className="font-medium text-[.875rem] mb-2">
                {t('settings.white_label.principal_button_example')}
              </p>
              <ButtonOpx
                label={t('buttons.validate')}
                color={primaryTextColor}
                backgroundColor={primaryColor}
                addClass="w-min"
              />
            </div>
          </div>
        </div>

        {/* Section secondaire */}
        <div className="border border-borderGrey p-6 w-1/2 space-y-4 rounded-default">
          <p className="font-medium">{t('settings.iframes.secondary_color')}</p>
          <div className="grid grid-cols-5 gap-6 items-start">
            <div className="col-span-2">
              <ColorPicker
                initialColor={secondaryColor}
                initialTextColor={secondaryTextColor}
                setParentColor={setSecondaryColor}
                setParentTextColor={setSecondaryTextColor}
              />
            </div>
            <div className="flex flex-col justify-center col-span-3">
              <p className="font-medium text-[.875rem] mb-2">
                {t('settings.iframes.example_element')}
              </p>
              <OneOperationCard
                nameOperation="Isolation des combles"
                code="BAR-TH-106"
                isChecked={false}
                handleCheck={handleCheckNoOp}
                addClass=""
                pictureOperationUrl=""
                disabled={false}
                incompatible={false}
                backgroundColorCheck={secondaryColor}
                textColor={secondaryTextColor}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
