/* eslint-disable no-nested-ternary */
import { useEffect, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';

import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { CardInformations } from '@components/informations/CardInformations';
import { CardLocalisation } from '@components/informations/CardLocalisation';
import { CardWorksites } from '@models/partners/components/informations/cards/CardWorksites';

import {
  getBeneficiaryById,
  updateBeneficiary,
} from '@models/beneficiaries/apiRequests/beneficiariesRequests';
// import { IBeneficiary } from '@models/beneficiaries/utils/beneficiariesType';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { DEFAULT_BENEFICIARY } from '@models/beneficiaries/utils/beneficiariesConstants';

import {
  ContactPageIcon,
  InfoCircleIcon,
  PositionIcon,
  RobotIcon,
} from '@assets/images/svgComponents';
import { GlobalContext } from '@context/globalContext';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { WORKSITE_CREATION_STATUS } from '@models/worksiteCreation/utils/enums';
import { BENEFICIARIES_ROUTES } from '@utils/routesUrls';

// NOTE: Fiche Beneficiaire
function TabBeneficiaryInfos(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { updateTitleHeader, updateDisplayBackButtonHeader } =
    useContext(HeaderContext);
  const { route, updateRoute, globalEnum } = useContext(GlobalContext);
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(DEFAULT_BENEFICIARY);
  const [isActive, setIsActive] = useState<boolean>(false);

  const getById = async () => {
    setLoading(true);
    const resBeneficiary = await getBeneficiaryById(Number(id));

    if (resBeneficiary) {
      const beneficiaryInfos = resBeneficiary.data;
      setData(beneficiaryInfos);

      const activeWorksites = beneficiaryInfos.worksites.filter(
        (worksite: any) =>
          worksite.status !== WORKSITE_STATUS.DELETED &&
          worksite.creation_status !== WORKSITE_CREATION_STATUS.DELETED
      );

      setIsActive(activeWorksites.length > 0);
      if (resBeneficiary.data.company_name) {
        updateTitleHeader(resBeneficiary.data.company_name);
      } else if (resBeneficiary.data.firstname) {
        updateTitleHeader(
          `${resBeneficiary.data.firstname || ''} ${
            resBeneficiary.data.lastname || ''
          }`.trim()
        );
      }
    }
    setLoading(false);
  };

  const onEdit = async (formData: FieldValues) => {
    const resBeneficiary = await updateBeneficiary({
      id: Number(id || 0),
      data: {
        mobile_phone: formData.mobile_phone,
        email: formData.email,
        address: {
          ...formData.address,
          additional_address:
            typeof formData.additional_address === 'string'
              ? formData.additional_address
              : `${formData.additional_address.address || ''} ${
                  formData.additional_address.postal_code || ''
                } ${formData.additional_address.city || ''} ${
                  formData.additional_address.country || ''
                }`.trim(),
        },
      },
    });

    if (resBeneficiary) {
      getById();
    }
  };

  // A SUPPRIMER APRES LA FIN DES TESTS
  const forceDisplayForTests = true;

  const canEditBeneficiary = useMemo(
    () =>
      (['admin-admin', 'installateur-admin'].includes(user?.role_name || '') &&
        user?.permissions_names.includes('gerer-beneficiaire ')) ||
      forceDisplayForTests,
    [user]
  );

  const worksitesWithAddress = useMemo(() => {
    const newWorksites: IWorksiteDetails[] = [];
    const dataWithoutWorksites = { ...data, worksites: undefined };
    delete dataWithoutWorksites.worksites;
    data.worksites.forEach((worksite: IWorksiteDetails) =>
      newWorksites.push({
        ...worksite,
        address: worksite.address || '',
        beneficiary: dataWithoutWorksites as any,
      })
    );
    return newWorksites;
  }, [data]);

  const simulationStatus = useMemo(() => {
    if (globalEnum && globalEnum.worksite_creation_status) {
      const worksiteStatus = Object.entries(
        globalEnum.worksite_creation_status
      );

      const statutObj = worksiteStatus.find((keyValue: string[]) =>
        keyValue[1]?.toLowerCase().includes('simulation')
      );

      return statutObj ? Number(statutObj[0]) : 1;
    }
    return 1;
  }, [globalEnum]);

  useEffect(() => {
    if (location.pathname === route.to) {
      updateRoute({
        to: location.pathname,
        older: [BENEFICIARIES_ROUTES.BENEFICIARIES],
      });
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      updateDisplayBackButtonHeader(true);
      updateTitleHeader('loading');
      getById();
    }
  }, [id]);

  return (
    <>
      <div className="tabs_wrapper mt-6">
        <ButtonOpx
          onClick={() => true}
          label="Informations"
          type="tab"
          addClass="mr-3"
          active
        />
      </div>
      {/* <div className="tabs_content mt-6 grid gap-[1.5%] grid-cols-1 md:grid-cols-[33.5%_65%]"> */}
      <div className="mt-6 flex flex-col md:flex-row pb-6 md:pb-0">
        <div className="flex flex-col space-y-6 mr-6 w-full md:w-[33.5%]">
          {/* GENERAL INFOS */}
          <CardInformations
            title={t('partners.general_infos')}
            data={{
              status: isActive,
              rows: [
                [
                  {
                    title: data.lastname
                      ? t('forms.lastname.placeholder')
                      : t('partners.company_name'),
                    value: data.lastname || data.company_name,
                    icon: <InfoCircleIcon />,
                  },
                  {
                    title: data.firstname
                      ? t('forms.firstname.placeholder')
                      : t('partners.siren'),
                    value: data.firstname || data.siren,
                  },
                ],
                [
                  {
                    name: 'address',
                    title: t('partners.postal_address'),
                    value: data.address,
                    icon: <PositionIcon />,
                    isEditable: canEditBeneficiary,
                    type: 'address',
                  },
                  {
                    name: 'additional_address',
                    title: t('partners.additional_address'),
                    value: data.address?.additional_address,
                    isEditable: canEditBeneficiary,
                  },
                ],
                [
                  {
                    name: 'mobile_phone',
                    title: t('forms.phone.placeholder'),
                    value: data.mobile_phone || data.fixe_phone,
                    icon: <ContactPageIcon />,
                    isEditable: canEditBeneficiary,
                    type: 'phone',
                  },
                  {
                    name: 'email',
                    title: t('partners.email_address'),
                    value: data.email,
                    isEditable: canEditBeneficiary,
                    type: 'email',
                  },
                ],
                [
                  {
                    title: t('partners.internal_reference'),
                    value: data.worksites
                      ? data.worksites.length > 0
                        ? data.worksites[0].created_by?.company_name
                        : null
                      : null,
                    icon: <RobotIcon />,
                  },
                ],
              ],
            }}
            loading={loading}
            onEdit={onEdit}
          />
          {/* LOCALISATION */}
          <CardLocalisation
            address={
              data.address
                ? `${data.address.address} ${data.address.city} ${data.address.postal_code} ${data.address.country}`
                : ''
            }
            loading={loading}
          />
        </div>
        <div className="flex flex-col space-y-6 w-full md:w-[65%] mt-6 md:mt-0">
          {/* CHANTIERS */}
          <CardWorksites
            data={worksitesWithAddress.filter(
              (worksite: IWorksiteDetails) =>
                Number(worksite.creation_status) !== simulationStatus
            )}
            type="worksite"
            loading={loading}
            pagination={{
              paginationData: undefined,
              pagesDatas: [],
              getData: () => true,
              pageAlreadyLoad: () => true,
            }}
          />

          {/* SIMULATION */}
          <CardWorksites
            data={worksitesWithAddress.filter(
              (worksite: IWorksiteDetails) =>
                Number(worksite.creation_status) === simulationStatus
            )}
            loading={loading}
            type="simulation"
            pagination={{
              paginationData: undefined,
              pagesDatas: [],
              getData: () => true,
              pageAlreadyLoad: () => true,
            }}
          />
        </div>
      </div>
    </>
  );
}

export { TabBeneficiaryInfos };
