import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Loader } from '@components/atomic/Loader';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';
import { ILabelValue } from '@globalTypes/globalTypes';
import { IContractOperationPrice } from '@models/contractCreation/utils/contractCreationTypes';
import {
  deleteIframe,
  getContracts,
  getIframeData,
  updateIframe,
} from '@models/settings/apiRequests/settingsRequests';
import { IAgreementList, IIframe } from '@models/settings/utils/settingsTypes';
import { ENTITY_TYPES } from '@utils/roles';
import { SETTINGS_ROUTES, SETTINGS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { DIMENSIONS } from '@utils/utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteIframeModal from './DeleteIframeModal';
import {
  IframeOperationsProvider,
  useIframeOperations,
} from './contexts/IframeOperationsContext';
import IframeOperationPrice from './tabs/IframeOperationPrice';
import IframeSimulator, {
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
} from './tabs/IframeSimulator';

type TabType = 'operation_price' | 'simulator';

interface IFormValues {
  convention_id: string | null;
  beneficiary_type: string | null;
  prime_type: string | null;
  // Nouveaux champs pour le simulateur
  contact_email: string | null;
  primary_color: string;
  primary_text_color: 'white' | 'black';
  secondary_color: string;
  secondary_text_color: 'white' | 'black';
  // Champs pour les coordonnées
  logo_file: File | null;
  coordinates_company: string | null;
  coordinates_phone: string | null;
  coordinates_address: string | null;
  coordinates_city: string | null;
  coordinates_postal_code: string | null;
  coordinates_email: string | null;
  contract_model_id: number | null;
}

// Interface pour les données à soumettre
interface ISubmitData {
  convention_id: string | null;
  beneficiary_type: string | null;
  prime_type: string | null;
  contact_email: string | null;
  primary_color: string;
  primary_text_color: 'white' | 'black';
  secondary_color: string;
  secondary_text_color: 'white' | 'black';
  logo_file: File | null;
  operations: IContractOperationPrice[];
  coordinates: {
    company_name: string | null;
    phone_number: string | null;
    address: string | null;
    city: string | null;
    zipcode: string | null;
    email: string | null;
  };
  contract_model: string | null;
}

function IframeSettingContent() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { updateTitleHeader, updateDisplayBackButtonHeader } =
    useContext(HeaderContext);
  const { userView, route, updateRoute } = useContext(GlobalContext);
  const { currentOperationPrices, setCurrentOperationPrices } =
    useIframeOperations();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<TabType>('simulator');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [iframeData, setIframeData] = useState<IIframe | null>(null);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [agreements, setAgreements] = useState<IAgreementList[]>([]);
  const [contractOptions, setContractOptions] = useState<ILabelValue[]>([]);
  const [isLoadingContracts, setIsLoadingContracts] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Initialisation du formulaire - déplacé ici depuis IframeOperationPrice
  const methods = useForm<IFormValues>({
    defaultValues: {
      convention_id: null,
      beneficiary_type: null,
      prime_type: null,
      contact_email: null,
      primary_color: DEFAULT_PRIMARY_COLOR,
      primary_text_color: 'white',
      secondary_color: DEFAULT_SECONDARY_COLOR,
      secondary_text_color: 'white',
      logo_file: null,
      coordinates_company: null,
      coordinates_phone: null,
      coordinates_address: null,
      coordinates_city: null,
      coordinates_postal_code: null,
      coordinates_email: null,
      contract_model_id: null,
    },
  });

  const { watch } = methods;
  const primeType = watch('prime_type');
  const beneficiaryType = watch('beneficiary_type');
  const colorPrimary = watch('primary_color');
  const colorSecondary = watch('secondary_color');

  // Vérifier si l'utilisateur est un installateur
  const isInstaller = userView?.entity_type === ENTITY_TYPES.INSTALLATEUR;

  // Fonction pour mettre à jour les prix d'opérations (appelée depuis IframeOperationPrice)
  const updateOperationPrices = (prices: IContractOperationPrice[]) => {
    setCurrentOperationPrices(prices);
  };

  const fetchIframeData = async () => {
    updateTitleHeader('loading');
    const response: IIframe = await getIframeData(id as string);
    if (response) {
      setIframeData(response);
      updateTitleHeader(
        `${t('settings.iframes.iframe_settings')} ${response.name}`
      );
      methods.reset({
        convention_id: response.convention_id?.toString() || null,
        beneficiary_type: response.beneficiary_type?.toString() || null,
        prime_type: response.prime_type?.toString() || null,
        contact_email: response.email || null,
        primary_color: response.theme.color_primary || DEFAULT_PRIMARY_COLOR,
        primary_text_color: response.theme.color_primary_text || 'white',
        secondary_color:
          response.theme.color_secondary || DEFAULT_SECONDARY_COLOR,
        secondary_text_color: response.theme.color_secondary_text || 'white',
        coordinates_company: response.coordinates?.company_name || null,
        coordinates_phone: response.coordinates?.phone_number || null,
        coordinates_address: response.coordinates?.address || null,
        coordinates_city: response.coordinates?.city || null,
        coordinates_postal_code: response.coordinates?.zipcode || null,
        coordinates_email: response.coordinates?.email || null,
        contract_model_id: Number(response.contract_model) || null,
      });
      setIsLoading(false);
    }
  };

  const disableSaveButton = () => {
    if (
      colorPrimary === DEFAULT_PRIMARY_COLOR &&
      colorSecondary === DEFAULT_SECONDARY_COLOR
    ) {
      return true;
    }
    if (isInstaller) {
      return false;
    }
    if (
      primeType === null ||
      beneficiaryType === null ||
      currentOperationPrices.length === 0
    ) {
      return true;
    }
    return false;
  };

  const hideCodeButton = useMemo(() => {
    if (isInstaller) {
      if (!iframeData?.theme.color_primary) {
        return true;
      }
      return false;
    }
    if (
      iframeData?.theme.color_primary &&
      iframeData?.theme.color_secondary &&
      iframeData?.prime_type &&
      iframeData?.beneficiary_type &&
      iframeData?.operations.length > 0
    ) {
      return false;
    }
    return true;
  }, [isInstaller, iframeData]);

  // Fonction pour la soumission du formulaire
  const onSubmit = async (data: IFormValues) => {
    setIsSubmitting(true);

    // Extraire les champs de coordonnées
    const {
      coordinates_company,
      coordinates_phone,
      coordinates_address,
      coordinates_city,
      coordinates_postal_code,
      coordinates_email,
      ...otherData
    } = data;

    // Préparer les données complètes à soumettre
    const submitData: ISubmitData = {
      ...otherData,
      operations: currentOperationPrices,
      // Regrouper les coordonnées dans un objet
      coordinates: {
        company_name: coordinates_company,
        phone_number: coordinates_phone,
        address: coordinates_address,
        city: coordinates_city,
        zipcode: coordinates_postal_code,
        email: coordinates_email,
      },
      contract_model: data.contract_model_id
        ? String(data.contract_model_id)
        : null,
      convention_id: data.convention_id ? String(data.convention_id) : null,
    };
    const response = await updateIframe(id as string, submitData);
    if (response) {
      if (hideCodeButton) {
        navigate(SETTINGS_ROUTES_WITH_ID(id as string).IFRAME_CODE);
      } else {
        setIframeData(response);
        toast.success(t('settings.iframes.iframe_updated'));
      }
    }

    setIsSubmitting(false);
  };

  // Fonction de suppression d'iframe
  const handleDeleteIframe = async () => {
    setIsDeleting(true);
    const response = await deleteIframe(id as string);
    if (response) {
      // Redirection vers la liste des iframes après suppression
      navigate(`${SETTINGS_ROUTES.SETTINGS}#iframes`);
      setShowDeleteModal(false);
    }
    setIsDeleting(false);
  };

  // Fonction pour récupérer les contrats et conventions disponibles
  const fetchContractsAndConventions = async () => {
    setIsLoadingContracts(true);
    const agreementsFetched = await getContracts();
    if (agreementsFetched) {
      setContractOptions(
        agreementsFetched.map((agreement: any) => ({
          label: `${agreement.reference} - ${agreement.company_name}`,
          value: agreement.id,
        }))
      );

      // Stockage des deux types de données avec le champ isConvention
      setAgreements(agreementsFetched);
    }
    setIsLoadingContracts(false);
  };

  const tabs = useMemo(() => {
    if (!isInstaller) {
      return [
        t('settings.iframes.operation_price'),
        t('settings.iframes.appearance_and_coordinates'),
      ];
    }
    return [t('settings.iframes.appearance_and_coordinates')];
  }, [isInstaller]);

  useEffect(() => {
    fetchIframeData();
    fetchContractsAndConventions();
    if (route.older.length === 0) {
      updateRoute({
        ...route,
        older: [`${SETTINGS_ROUTES.SETTINGS}#iframes`],
      });
    }
    updateDisplayBackButtonHeader(true);

    // Si l'utilisateur est un installateur, on active par défaut le simulator
    if (!isInstaller) {
      setActiveTab('operation_price');
    }
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case 'operation_price':
        return (
          <IframeOperationPrice
            iframeData={iframeData}
            onUpdateOperationPrices={updateOperationPrices}
            contractOptions={contractOptions}
            isLoadingContracts={isLoadingContracts}
            agreements={agreements}
          />
        );
      case 'simulator':
      default:
        return <IframeSimulator iframeData={iframeData} />;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[50rem]">
        <Loader isBig />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <div
        className="flex-1 flex flex-col px-4 pb-8 overflow-x-auto w-full scroll-invisible"
        style={{
          height: `calc(100vh - ${DIMENSIONS.header_height})`,
        }}
      >
        <div className="sticky top-0 z-50 bg-backgroundBody">
          <SubHeader
            leftPart={
              <TabsLeftPart
                titlesList={tabs.length > 1 ? tabs : []}
                onClick={(title) => {
                  if (title === t('settings.iframes.operation_price')) {
                    setActiveTab('operation_price');
                  } else if (
                    title === t('settings.iframes.appearance_and_coordinates')
                  ) {
                    setActiveTab('simulator');
                  }
                }}
                activeButton={
                  activeTab === 'operation_price'
                    ? (t('settings.iframes.operation_price') as string)
                    : (t(
                        'settings.iframes.appearance_and_coordinates'
                      ) as string)
                }
              />
            }
            rightPart={
              <div className="flex gap-4">
                {!hideCodeButton && (
                  <ButtonOpx
                    label={
                      t('settings.iframes.iframe_code') || "Code de l'iframe"
                    }
                    type="tab"
                    onClick={() => {
                      navigate(
                        SETTINGS_ROUTES_WITH_ID(id as string).IFRAME_CODE
                      );
                    }}
                    small
                  />
                )}
                <ButtonOpx
                  label={t('buttons.delete')}
                  type="secondary"
                  onClick={() => setShowDeleteModal(true)}
                  small
                />
                <ButtonOpx
                  label={t(
                    'settings.iframes.save_and_create_and_generate_code'
                  )}
                  type="primary"
                  onClick={methods.handleSubmit(onSubmit)}
                  isLoading={isSubmitting}
                  disabled={isSubmitting || disableSaveButton()}
                  small
                />
              </div>
            }
          />
        </div>
        <div className="space-y-4">{renderContent()}</div>
      </div>
      {showDeleteModal && (
        <DeleteIframeModal
          setShowModal={setShowDeleteModal}
          iframeName={iframeData?.name || ''}
          deleteIframe={handleDeleteIframe}
          isLoading={isDeleting}
        />
      )}
    </FormProvider>
  );
}

// Composant wrapper qui fournit le contexte d'opérations
function IframeSetting() {
  return (
    <IframeOperationsProvider>
      <IframeSettingContent />
    </IframeOperationsProvider>
  );
}

export default IframeSetting;
