import { Modal } from '@components/Modal';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ProSiretForm } from '@models/mar/components/worksiteCompletion/stepsCompletion/ProSiretForm';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { addPro } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IProEntity } from '@models/mar/utils/marTypes';
import { TextError } from '@components/TextError';

interface IModalAddProProps {
  setDisplayModalAddPro: Dispatch<SetStateAction<boolean>>;
  indexPro: number;
  setResetSignalPro: Dispatch<SetStateAction<number>>;
  prosList: IProEntity[];
}

/**
 * Composant ModalAddPro
 * @param {IModalAddProProps} props - Propriétés du composant
 * @returns {JSX.Element} Modal pour ajouter un professionnel
 */
function ModalAddPro({
  setDisplayModalAddPro,
  indexPro,
  setResetSignalPro,
  prosList,
}: IModalAddProProps) {
  const { t } = useTranslation();
  const { worksiteData, getPros } = useContext(WorksiteCreationMarContext);

  const [loadingAddPro, setLoadingAddPro] = useState(false);

  const { watch, setValue } = useFormContext();

  const entityFormName = `pros[${indexPro}].entity`;
  const entityFormValues = watch(entityFormName);

  const readyForAddPro =
    entityFormValues?.siret &&
    entityFormValues?.siren &&
    entityFormValues?.company_name &&
    entityFormValues?.address &&
    entityFormValues?.zipcode &&
    entityFormValues?.city;

  /**
   * Réinitialise les informations de l'entité au montage du composant
   */
  useEffect(() => {
    setValue(`${entityFormName}.siret`, '');
    setValue(`${entityFormName}.siren`, '');
    setValue(`${entityFormName}.company_name`, '');
    setValue(`${entityFormName}.address`, '');
    setValue(`${entityFormName}.zipcode`, '');
    setValue(`${entityFormName}.city`, '');
    setValue(`${entityFormName}.legal_category`, '');
    setValue(`${entityFormName}.entity_id`, null);
  }, [setValue, entityFormName]);

  const handleValidateAddPro = async () => {
    setLoadingAddPro(true);
    const dataToSend = {
      entity: {
        siret: entityFormValues.siret,
        siren: entityFormValues.siren,
        legal_category: entityFormValues.legal_category,
        company_name: entityFormValues.company_name,
        zipcode: entityFormValues.zipcode,
        city: entityFormValues.city,
        address: entityFormValues.address,
      },
      entity_id: entityFormValues.entity_id,
    };

    const response = await addPro(worksiteData?.id || 0, dataToSend);
    if (response) {
      setValue(`pros[${indexPro}].entity_id`, response.entity_id);
      await getPros();
      setResetSignalPro((prev) => prev + 1);
      setLoadingAddPro(false);
      setDisplayModalAddPro(false);
    } else {
      setLoadingAddPro(false);
    }
  };

  const isProAlreadyAdded =
    entityFormValues &&
    prosList.find((pro) => pro.id === entityFormValues.entity_id) !== undefined;

  return (
    <Modal
      title={`${t(
        'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.add_new_installer'
      )}`}
      backgroundTransparent
      textBtnConfirm={String(t('buttons.validate'))}
      btnCancel
      setIsModal={setDisplayModalAddPro}
      onConfirmClick={handleValidateAddPro}
      isLoading={loadingAddPro}
      btnConfirmDisabled={loadingAddPro || !readyForAddPro || isProAlreadyAdded}
      sidebarVisible={false}
    >
      <div className="pt-6">
        {isProAlreadyAdded && (
          <TextError
            errorMessage={t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.installer_already_added'
            )}
            addClass="!mt-0 mb-4 text-[.875rem]"
          />
        )}
        <ProSiretForm parentName={entityFormName} withMinimumInfos />
      </div>
    </Modal>
  );
}

export default ModalAddPro;
