import { Modal } from '@components/Modal';
import {
  INCENTIVE_TYPE,
  StepsEnumBeforeSimulation,
} from '@models/worksiteCreation/utils/enums';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from '@components/SearchBar';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AddIcon } from '@assets/images/svgComponents';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { v4 } from 'uuid';
import { Pagination } from '@components/atomic/pagination/Pagination';
import { blueOpx } from '@assets/color';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { IBeneficiary } from '@models/beneficiaries/utils/beneficiariesType';
import {
  getBeneficiaryInfo,
  getBeneficiaryName,
} from '@models/worksiteCreation/utils/functions';
import { OperationTypeEnum } from '@utils/enums';
import { DEFAULT_BENEFICIARY } from '@models/beneficiaries/utils/beneficiariesConstants';
import { CardOneBeneficiaryOrPartner } from '../CardOneBeneficiaryOrPartner';
import {
  getListAllBeneficiaries,
  getListRecentBeneficiaries,
  getOneBeneficiary,
} from '../../../apiRequests/worksiteCreationRequests';

interface IStepBindBeneficiary {
  setStepFormBeforeSimulation?: Dispatch<
    SetStateAction<StepsEnumBeforeSimulation>
  >;
}

function StepBindBeneficiary({
  setStepFormBeforeSimulation,
}: IStepBindBeneficiary) {
  const { t } = useTranslation();
  const {
    updateBeneficiary,
    updateIsModals,
    isModals,
    updateIsLoading,
    isLoading,
    worksiteOperationType,
    partner,
    conventionActive,
  } = useContext(WorksiteCreationContext);
  const [listIsLoading, setListIsLoading] = useState(true);
  const [listRecentsIsLoading, setListRecentsIsLoading] = useState(true);
  const [keySearch, setKeySearch] = useState('');

  const [numberPageListBeneficiaries, setNumberPageListBeneficiaries] =
    useState<number>(1);
  // RECUPERE LA LISTE DES BENEFICIAIRES => STEP BINDBENEFICIARY
  const [listAllBeneficiaries, setListAllBeneficiaries] = useState<
    IBeneficiary[]
  >([DEFAULT_BENEFICIARY]);

  // RECUPERE LA LISTE DES BENEFICIAIRES => STEP BINDBENEFICIARY
  const [listRecentBeneficiaries, setListRecentBeneficiaries] = useState<
    IBeneficiary[]
  >([DEFAULT_BENEFICIARY]);

  const getInitialBeneficiaries = () => {
    const requestAllBeneficiaries = getListAllBeneficiaries(
      setListAllBeneficiaries,
      1,
      worksiteOperationType,
      undefined,
      partner?.id_partenaire
    );
    const requestRecentsBeneficiaries = getListRecentBeneficiaries(
      setListRecentBeneficiaries,
      worksiteOperationType,
      partner?.id_partenaire
    );
    Promise.all([requestAllBeneficiaries, requestRecentsBeneficiaries]).then(
      (responses) => {
        responses.forEach((response) => {
          if (response) {
            setListIsLoading(false);
            setListRecentsIsLoading(false);
          }
        });
      }
    );
  };

  const onSearch = async (keyWord: string) => {
    setKeySearch(keyWord);
    setListIsLoading(true);

    let requestCompleted;
    if (keyWord === '') {
      setNumberPageListBeneficiaries(1);
      requestCompleted = await getListAllBeneficiaries(
        setListAllBeneficiaries,
        1,
        worksiteOperationType
      );
    } else {
      requestCompleted = await getListAllBeneficiaries(
        setListAllBeneficiaries,
        numberPageListBeneficiaries,
        worksiteOperationType,
        keyWord
      );
    }
    setListIsLoading(!requestCompleted);
  };

  const onNextStep = async (beneficiary: IBeneficiary) => {
    updateIsLoading(true);
    await getOneBeneficiary(beneficiary.id, updateBeneficiary);
    updateIsLoading(false);
    updateIsModals({
      formBeforeSimulation: false,
      bindBeneficiary: false,
      createBeneficiary: false,
      saveAndQuit: false,
      deleteWorksite: false,
      cancelWorksite: false,
    });
  };

  const onNextPage = async () => {
    setNumberPageListBeneficiaries(numberPageListBeneficiaries + 1);
    setListIsLoading(true);
    const requestCompleted = await getListAllBeneficiaries(
      setListAllBeneficiaries,
      numberPageListBeneficiaries + 1,
      worksiteOperationType,
      keySearch
    );
    setListIsLoading(!requestCompleted);
  };

  const onPreviousPage = async () => {
    setNumberPageListBeneficiaries(
      numberPageListBeneficiaries !== 1
        ? numberPageListBeneficiaries - 1
        : numberPageListBeneficiaries
    );
    setListIsLoading(true);
    const requestCompleted = await getListAllBeneficiaries(
      setListAllBeneficiaries,
      numberPageListBeneficiaries !== 1
        ? numberPageListBeneficiaries - 1
        : numberPageListBeneficiaries,
      worksiteOperationType,
      keySearch
    );
    setListIsLoading(!requestCompleted);
  };

  const goToSimulation = () => {
    updateIsModals({
      formBeforeSimulation: false,
      bindBeneficiary: false,
      createBeneficiary: false,
      saveAndQuit: false,
      deleteWorksite: false,
      cancelWorksite: false,
    });
  };

  useEffect(() => {
    getInitialBeneficiaries();
  }, []);

  // if we step back, if convention incentive type is not mixed
  // we have to skip incentive choice step
  // so we have to move back from two steps instead of one
  const stepIncrement =
    conventionActive.incentive_type === INCENTIVE_TYPE.MIXED ? 1 : 2;

  return (
    <Modal
      withArrowBack
      onBackClick={
        !isModals.bindBeneficiary && !!setStepFormBeforeSimulation
          ? () => {
              setStepFormBeforeSimulation(
                (prevState) => prevState - stepIncrement
              );
            }
          : () => goToSimulation()
      }
      title={t(
        'worksite_creation.form_modal_before_simulation.bind_beneficiary'
      )}
    >
      {!isLoading ? (
        <>
          <div className="w-full flex justify-between space-x-[1rem] my-[1.5rem]">
            <SearchBar
              placeholder={t('search_bar.placeholder_beneficiary')}
              onSearch={(keyWord) => onSearch(keyWord)}
              addClass="!w-full"
            />
            {worksiteOperationType !== OperationTypeEnum.GLOBAL_RENOVATION && (
              <ButtonOpx
                label={t('buttons.associate_later')}
                onClick={goToSimulation}
                type="secondary"
              />
            )}
            <ButtonOpx
              icon={<AddIcon />}
              label={t('buttons.new_beneficiary')}
              onClick={() => {
                if (setStepFormBeforeSimulation) {
                  setStepFormBeforeSimulation(
                    StepsEnumBeforeSimulation.CREATE_BENEFICIARY
                  );
                } else {
                  updateIsModals({ ...isModals, createBeneficiary: true });
                }
              }}
              dataTestId="new_beneficiary_button"
            />
          </div>
          {listRecentBeneficiaries.length > 0 &&
            listRecentBeneficiaries[0].firstname !== '' && (
              <>
                <div className="font-medium mb-[1rem]">
                  {t(
                    'worksite_creation.form_modal_before_simulation.recent_beneficiaries'
                  )}
                </div>
                <div className="flex flex-col space-y-[1rem]">
                  {!listRecentsIsLoading ? (
                    listRecentBeneficiaries.map((beneficiary) => (
                      <CardOneBeneficiaryOrPartner
                        key={v4()}
                        leftPart={getBeneficiaryName(
                          beneficiary,
                          worksiteOperationType
                        )}
                        rightPart={
                          worksiteOperationType === OperationTypeEnum.B2B
                            ? `SIRET: ${beneficiary.siret || ''}`
                            : beneficiary.address
                        }
                        onClick={() => onNextStep(beneficiary)}
                      />
                    ))
                  ) : (
                    <div className="bg-white flex flex-col w-full space-y-[1rem] h-auto rounded-md rounded-default">
                      <LoaderSkeleton height="2.5rem" />
                    </div>
                  )}
                </div>
              </>
            )}
          <div className="font-medium mb-[1rem] mt-[1.5rem]">
            {/* eslint-disable-next-line no-nested-ternary */}
            {listAllBeneficiaries.length > 0
              ? keySearch === ''
                ? t(
                    'worksite_creation.form_modal_before_simulation.all_beneficiaries'
                  )
                : t('search_bar.search_result')
              : t(
                  'worksite_creation.form_modal_before_simulation.no_beneficiary'
                )}
          </div>

          {!listIsLoading ? (
            <div className="flex flex-col space-y-[1rem]">
              {listAllBeneficiaries.map((beneficiary) => (
                <CardOneBeneficiaryOrPartner
                  key={v4()}
                  onClick={() => onNextStep(beneficiary)}
                  leftPart={getBeneficiaryName(
                    beneficiary,
                    worksiteOperationType
                  )}
                  rightPart={getBeneficiaryInfo(
                    t,
                    beneficiary,
                    worksiteOperationType
                  )}
                />
              ))}
            </div>
          ) : (
            <div className="bg-white flex flex-col w-full space-y-[1rem] h-auto rounded-md rounded-default">
              <LoaderSkeleton height="2.31rem" />
              <LoaderSkeleton height="2.31rem" />
              <LoaderSkeleton height="2.31rem" />
              <LoaderSkeleton height="2.31rem" />
              <LoaderSkeleton height="2.31rem" />
            </div>
          )}
          {(numberPageListBeneficiaries > 1 ||
            listAllBeneficiaries.length > 4) && (
            <Pagination
              numberPage={numberPageListBeneficiaries}
              totalNumberPage={0}
              colorPagination={blueOpx}
              addClass="mt-[1rem]"
              onNext={onNextPage}
              onPrevious={onPreviousPage}
            />
          )}
        </>
      ) : (
        <LoaderSkeleton height="30rem" addClass="mt-[2rem]" />
      )}
    </Modal>
  );
}

export { StepBindBeneficiary };

StepBindBeneficiary.defaultProps = {
  setStepFormBeforeSimulation: null,
};
