import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext } from 'react';

/**
 * Hook personnalisé pour accéder au mode iframe
 * @returns {boolean} isIframeMode - Indique si l'application est en mode iframe
 */
export const useIframeMode = () => {
  const { isIframeMode } = useContext(WorksiteCreationContext);
  return isIframeMode;
};
