import { CrossIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { convertHexToRGBA } from '@utils/functions';

interface ITagProps {
  color: string;
  label: string;
  withBorder?: boolean;
  addClass?: string;
  dataTestId?: string;
  onCancel?: () => void;
  backgroundColor?: string;
  borderColor?: string;
}
// Si icon mettre la width et la height à 1rem et le fill identique à color
// color sert pour le background color qui aura une opacité de 0.1, pour le border color et la couleur du texte qui eux auront une opacité de 1
function Tag({
  color,
  label,
  withBorder,
  addClass,
  dataTestId,
  onCancel,
  backgroundColor,
  borderColor,
}: ITagProps): JSX.Element {
  const className = `w-fit rounded-[.125rem] relative flex items-center justify-around ${
    withBorder ? 'border' : ''
  }`;
  const bgColor = backgroundColor || convertHexToRGBA(color, 0.1);

  return (
    <div
      style={{ backgroundColor: bgColor, borderColor: borderColor || color }}
      className={[className, addClass].join(' ')}
    >
      <div
        style={{ color }}
        className="flex items-center px-[.5rem] py-[.25rem] leading-3 text-[.75rem] whitespace-nowrap"
        data-test-id={dataTestId}
      >
        {label}
      </div>
      {onCancel && (
        <ColorCube
          size="1.5rem"
          numberOrIcon={<CrossIcon />}
          color={color}
          opacity
          backgroundColor="transparent"
          onClick={onCancel}
          addClass="cursor-pointer"
        />
      )}
    </div>
  );
}

Tag.defaultProps = {
  withBorder: false,
  addClass: '',
  dataTestId: '',
  onCancel: undefined,
  backgroundColor: undefined,
  borderColor: undefined,
};

export { Tag };
