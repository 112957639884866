import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { convertHexToRGBA } from '@utils/functions';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { colorsResponseFormat } from '@models/controlContact/utils/utlis';

function ColorValues() {
  const { t } = useTranslation();
  const methods = useFormContext();
  const { setValue, watch, resetField } = methods;

  const parentName = 'response_format_color_values';

  const colors = colorsResponseFormat(t);

  useEffect(() => {
    return () => {
      resetField(parentName);
    };
  }, []);

  return (
    <div>
      <InputSelect
        label={
          t('control_contact.settings.new_field_modal.color_values') as string
        }
        colorSelection={watch(parentName)?.value}
        dataLabelValue={colors}
        placeholder={
          t(
            'control_contact.settings.new_field_modal.placeholder_color'
          ) as string
        }
        required
        onSelectLabelValue={(item) => setValue(parentName, item)}
        valueInput={watch(parentName)?.label}
      >
        <div className="flex flex-col items-start rounded-b-default">
          {colors
            .filter((color) => color.value !== watch(parentName)?.value)
            .map((color) => (
              <button
                key={color.value}
                type="button"
                className="w-full text-left px-4 py-3 text-[.875rem] border-b border-b-borderGrey"
                onClick={() => setValue(parentName, color)}
              >
                <p
                  className="py-[.125rem] px-1 rounded-default w-min"
                  style={{
                    color: color.value,
                    backgroundColor: convertHexToRGBA(color.value, 0.2),
                  }}
                >
                  {color.label}
                </p>
              </button>
            ))}
        </div>
      </InputSelect>
    </div>
  );
}

export default ColorValues;
