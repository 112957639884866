import { borderGrey } from '@assets/color';
import { CheckIcon } from '@assets/images/svgComponents';
import { v4 } from 'uuid';
import { convertHexToRGBA } from '@utils/functions';
import { ColorCube } from './ColorCube';

type IProgressBarVerticalProps = {
  headerHeight: number;
  stepHeight: number;
  color: string;
  showList: boolean;
  stepActive: number;
  stepsList: { key: string; value: string }[];
  backgroundColor?: string;
  border?: string;
  width?: string;
  addClass?: string;
  dataTestId?: string;
};

function ProgressBarVertical({
  headerHeight,
  stepHeight,
  color,
  showList,
  stepActive,
  stepsList,
  backgroundColor,
  border,
  width,
  addClass,
  dataTestId,
}: IProgressBarVerticalProps): JSX.Element {
  const stepActiveToUse = stepActive === 51 ? 5.1 : stepActive;

  const getStepNumber = (key: string): number => {
    // Si la key vaut "51", on renvoie 5.1
    // Sinon, on fait un parseFloat "classique".
    if (key === '51') return 5.1;
    return parseFloat(key);
  };

  const getStepLabel = (value: number): string => {
    // Si c'est 5.1, on affiche "5bis", sinon on affiche directement le chiffre.
    if (value === 5.1) return '5bis';
    // Par défaut, on convertit en string
    return String(value);
  };

  const numberOrIconToShow = (stepNumber: number) => {
    if (stepNumber < stepActiveToUse) {
      return <CheckIcon />;
    }
    // Ici, au lieu d'afficher stepNumber directement, on passe par getStepLabel
    if (stepNumber === stepActiveToUse) {
      return getStepLabel(stepNumber);
    }
    return getStepLabel(stepNumber);
  };

  return (
    <div
      style={{
        backgroundColor: !showList ? borderGrey || backgroundColor : '',
        borderRadius: border,
        width: width || '.25rem',
      }}
      className={['h-full flex-col items-center flex', addClass].join('')}
    >
      <div
        style={{
          backgroundColor: color,
          borderRadius: border,
          height: `calc(${headerHeight}px + .875rem)`,
        }}
        className="w-full"
      />
      {!showList ? (
        <ColorCube
          numberOrIcon={
            stepActiveToUse <= stepsList.length ? (
              // On remplace stepActiveToUse direct par la fonction getStepLabel
              getStepLabel(stepActiveToUse)
            ) : (
              <CheckIcon />
            )
          }
          color={color}
          size="2rem"
          borderRadius=".125rem"
        />
      ) : (
        <div
          className="flex flex-col items-center"
          style={{ height: stepHeight }}
        >
          {stepsList.map((step) => {
            const stepNumber = getStepNumber(step.key);
            return (
              <div className="flex flex-col items-center h-full" key={v4()}>
                <div
                  style={{
                    backgroundImage: `linear-gradient(to bottom, ${
                      stepNumber >= stepActiveToUse
                        ? `${convertHexToRGBA(color, 0.1)}`
                        : color
                    }, ${
                      stepNumber >= stepActiveToUse
                        ? `${convertHexToRGBA(color, 0.1)}`
                        : color
                    })`,
                    borderRadius: border,
                    width: width || '.25rem',
                    height: stepNumber === stepsList.length ? '30%' : '100%',
                  }}
                />
                <div className="absolute">
                  <ColorCube
                    numberOrIcon={numberOrIconToShow(stepNumber)}
                    color={color}
                    size="2rem"
                    opacity={stepNumber > stepActiveToUse}
                    borderRadius=".125rem"
                    dataTestId={
                      stepNumber === stepActiveToUse ? dataTestId : ''
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export { ProgressBarVertical };

ProgressBarVertical.defaultProps = {
  border: '',
  backgroundColor: '',
  width: '',
  addClass: '',
  dataTestId: '',
};
