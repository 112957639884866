import { Modal } from '@components/Modal';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface IDeleteModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  iframeName: string;
  deleteIframe: () => void;
  isLoading: boolean;
}

function DeleteIframeModal({
  setShowModal,
  iframeName,
  deleteIframe,
  isLoading,
}: IDeleteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t(
        'settings.iframes.delete_modal_title',
        "Suppression de l'iframe"
      )}
      setIsModal={setShowModal}
      backgroundTransparent
      lightHeader
      isLoading={isLoading}
      textBtnConfirm={t('buttons.confirm') || 'Confirmer'}
      textBtnCancel={t('buttons.cancel') || 'Annuler'}
      btnCancel
      onConfirmClick={deleteIframe}
    >
      <p>
        {t('settings.iframes.delete_modal_text', {
          name: iframeName || '',
          defaultValue:
            "Êtes-vous sûr de vouloir supprimer définitivement l'iframe {{name}} ?",
        })}
      </p>
    </Modal>
  );
}

export default DeleteIframeModal;
