import { createContext, Dispatch, SetStateAction } from 'react';
import {
  IContractBusinessProvider,
  IContractDraftType,
  IContractOperationPrice,
  IContractPartnerInfo,
  IContractPaymentCondition,
  IContractReferents,
  IObligeList,
  IRelatedContract,
  IVolumeType,
} from '@models/contractCreation/utils/contractCreationTypes';
import { IContractType } from '@models/contracts/utils/contractTypes';
import {
  IAssociateType,
  ITunnelType,
} from '@models/contracts/utils/newContractTypes';
import { IUserType } from '@models/auth/utils/types';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import {
  IBeneficiary,
  IBeneficiaryAddress,
} from '@models/beneficiaries/utils/beneficiariesType';

export interface ContractCreation {
  contract: IContractType | undefined;
  updateContract: Dispatch<SetStateAction<IContractType | undefined>>;
  editMode: boolean;
  referenceOpx: string;
  updateReferenceOpx: Dispatch<SetStateAction<string>>;
  referencePerso: string;
  updateReferencePerso: Dispatch<SetStateAction<string>>;
  rai: { value: number; label: string };
  updateRai: Dispatch<SetStateAction<{ value: number; label: string }>>;
  relatedContract: IRelatedContract;
  updateRelatedContract: Dispatch<SetStateAction<IRelatedContract>>;
  beneficiaryType: number;
  updateBeneficiaryType: Dispatch<SetStateAction<number>>;
  validationDelay: number;
  updateValidationDelay: Dispatch<SetStateAction<number>>;
  startDate: string;
  updateStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  updateEndDate: Dispatch<SetStateAction<string>>;
  activeStep: number;
  lastStep: number;
  volumeMin: IVolumeType;
  updateVolumeMin: Dispatch<SetStateAction<IVolumeType>>;
  volumeMax: IVolumeType;
  updateVolumeMax: Dispatch<SetStateAction<IVolumeType>>;
  penalties: IVolumeType;
  updatePenalties: Dispatch<SetStateAction<IVolumeType>>;
  salePrices: IVolumeType;
  updateSalePrices: Dispatch<SetStateAction<IVolumeType>>;
  entityTo: IContractPartnerInfo;
  updateEntityTo: Dispatch<SetStateAction<IContractPartnerInfo>>;
  beneficiary: IBeneficiary;
  updateBeneficiary: Dispatch<SetStateAction<IBeneficiary>>;
  referents: IContractReferents | null;
  updateReferents: Dispatch<SetStateAction<IContractReferents | null>>;
  operationPrices: IContractOperationPrice[];
  updateOperationPrices: Dispatch<SetStateAction<IContractOperationPrice[]>>;
  intermediaryBusiness: IContractPartnerInfo;
  updateIntermediaryBusiness: Dispatch<SetStateAction<IContractPartnerInfo>>;
  amo: IContractPartnerInfo;
  updateAmo: Dispatch<SetStateAction<IContractPartnerInfo>>;
  paymentConditions: IContractPaymentCondition[];
  updatePaymentConditions: Dispatch<
    SetStateAction<IContractPaymentCondition[]>
  >;
  tunnel: ITunnelType | undefined;
  updateTunnel: Dispatch<SetStateAction<ITunnelType | undefined>>;
  saleProfile: number;
  updateSaleProfile: Dispatch<SetStateAction<number>>;
  contractType: number;
  updateContractType: Dispatch<SetStateAction<number>>;
  deliveryVolume: string;
  updateDeliveryVolume: Dispatch<SetStateAction<string>>;
  commission: number;
  updateCommission: Dispatch<SetStateAction<number>>;
  tunnels: ITunnelType[];
  updateTunnels: Dispatch<SetStateAction<ITunnelType[]>>;
  listAssociated: {
    recents: IAssociateType[];
    all: IAssociateType[];
  };
  updateListAssociated: Dispatch<
    SetStateAction<{
      recents: IAssociateType[];
      all: IAssociateType[];
    }>
  >;
  contactList: IUserType[];
  updateContactList: Dispatch<SetStateAction<IUserType[]>>;
  listOperations: IOperationType[] | null;
  updateListOperations: Dispatch<SetStateAction<IOperationType[] | null>>;
  origination: number;
  updateOrigination: Dispatch<SetStateAction<number>>;
  worksiteAddresses: IBeneficiaryAddress[];
  updateWorksiteAddresses: Dispatch<SetStateAction<IBeneficiaryAddress[]>>;
  steps: { label: string; value: number }[];
  changeStep: (direction: 'next' | 'back' | 'end' | number) => void;
  partnerType: number;
  updatePartnerType: Dispatch<SetStateAction<number>>;
  payloadData: IContractDraftType | null;
  updatePayloadData: Dispatch<SetStateAction<IContractDraftType | null>>;
  raiMention: string;
  updateRaiMention: Dispatch<SetStateAction<string>>;
  listObliges: IObligeList[];
  updateListObliges: Dispatch<SetStateAction<IObligeList[]>>;
  incentiveType: number;
  updateIncentiveType: Dispatch<SetStateAction<number>>;
  invitationProcess: Dispatch<SetStateAction<boolean>>;
  conventionModel: boolean;
  isElectronicSignature: boolean;
  updateIsElectronicSignature: Dispatch<SetStateAction<boolean>>;
  canManageWorksiteDocuments: boolean;
  updateCanManageWorksiteDocuments: Dispatch<SetStateAction<boolean>>;
  canUseCustomPrices: boolean;
  updateCanUseCustomPrices: Dispatch<SetStateAction<boolean>>;
  paymentEntityId: number | null;
  updatePaymentEntityId: Dispatch<SetStateAction<null | number>>;
  businessProvider: IContractBusinessProvider | null;
  updateBusinessProvider: Dispatch<
    SetStateAction<IContractBusinessProvider | null>
  >;
  hasCdpCharterSignatory: boolean;
  updateHasCdpCharterSignatory: Dispatch<SetStateAction<boolean>>;
}

export const ContractCreationContext = createContext({} as ContractCreation);
