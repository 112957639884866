import { black, blueOpx, white } from '@assets/color';
import {
  ChevronDownIcon,
  FileDeleteIcon,
  FileIcon,
  OutgoingMailIcon,
  SettingsIcon,
} from '@assets/images/svgComponents';
import { Loader } from '@components/atomic/Loader';
import { ThemeContext } from '@context/ThemeContext';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { convertHexToRGBA } from '@utils/functions';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Composant ActionsSummary
 *
 * Ce composant affiche un bouton de type primary avec une icône de réglages,
 * le texte "Actions" et un chevron indiquant l'état du menu.
 * Au clic, un menu déroulant s'ouvre proposant 3 options comportant chacune une icône, un texte,
 * et déclenchant une action liée.
 *
 * Ce bouton est destiné à regrouper les boutons de création de chantier et de proposition refusée.
 *
 * @returns {JSX.Element} Le composant ActionsSummary
 */
function ActionsSummary({
  onValidateWorksiteCreation,
  isLoading,
}: {
  onValidateWorksiteCreation: (option: { sendToBeneficiary: boolean }) => void;
  isLoading: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const { updateIsModals } = useContext(WorksiteCreationContext);
  const { themeData } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const whiteLabelMode = !!themeData;
  const secondaryThemeColors = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
  );
  const secondaryColor = secondaryThemeColors?.background_color || blueOpx;
  const secondaryTextColor = secondaryThemeColors?.text_color || white;

  const colorIcon = whiteLabelMode ? black : blueOpx;

  // Définition des options du menu déroulant
  const options = [
    {
      label: t('buttons.valid_worksite_creation'),
      icon: <FileIcon width="0.875rem" height="0.875rem" fill={colorIcon} />,
      onClick: () => {
        onValidateWorksiteCreation({ sendToBeneficiary: false });
      },
      dataTestId: 'button_create_worksite',
    },
    {
      label: t('buttons.valid_worksite_creation_and_send_to_beneficiary'),
      icon: (
        <OutgoingMailIcon width="0.875rem" height="0.875rem" fill={colorIcon} />
      ),
      onClick: () => {
        onValidateWorksiteCreation({ sendToBeneficiary: true });
      },
      dataTestId: 'button_validate_worksite_creation_and_send_to_beneficiary',
    },
    {
      label: t('buttons.cancel_worksite'),
      icon: (
        <FileDeleteIcon width="0.875rem" height="0.875rem" fill={colorIcon} />
      ),
      onClick: () => {
        updateIsModals({
          formBeforeSimulation: false,
          bindBeneficiary: false,
          createBeneficiary: false,
          saveAndQuit: false,
          deleteWorksite: false,
          cancelWorksite: true,
        });
      },
      dataTestId: 'button_cancel_worksite',
    },
  ];

  // Gestion du clic en dehors du composant pour fermer le menu
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * Bascule l'ouverture/fermeture du menu déroulant.
   */
  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  /**
   * Gère le clic sur une option du menu.
   *
   * @param onClick Action associée à l'option sélectionnée
   */
  function handleOptionClick(onClick: () => void) {
    onClick();
    setMenuOpen(false);
  }

  return (
    <div className="relative inline-block" ref={containerRef}>
      <button
        type="button"
        onClick={toggleMenu}
        className="rounded-default px-4 py-2 flex items-center focus:outline-none"
        style={{
          backgroundColor: secondaryColor,
          color: secondaryTextColor,
        }}
        data-test-id="button_actions_summary"
        disabled={isLoading}
      >
        {!isLoading ? <SettingsIcon fill={secondaryTextColor} /> : <Loader />}
        <span className="ml-2">{t('buttons.actions')}</span>
        <ChevronDownIcon
          className={`ml-2 ${
            menuOpen ? 'rotate-180' : ''
          } transform duration-200`}
          fill={secondaryTextColor}
        />
      </button>
      {menuOpen && (
        <div className="absolute right-0 mt-2 bg-white border border-borderGrey rounded-default z-10">
          {options.map((option) => (
            <button
              key={option.label}
              type="button"
              onClick={() => handleOptionClick(option.onClick)}
              className="w-full text-left px-4 py-3 flex items-center px-3 hover:bg-opacity-10 transition-colors duration-200"
              style={{
                color: secondaryTextColor,
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = convertHexToRGBA(
                  secondaryColor,
                  0.1
                );
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '';
              }}
            >
              {option.icon}
              <span
                className="ml-2 text-[.875rem] whitespace-nowrap"
                style={{ color: whiteLabelMode ? black : blueOpx }}
              >
                {option.label}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export { ActionsSummary };
