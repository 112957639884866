import { InputText } from '@components/atomic/inputs/InputText';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CivilityEnum } from '@models/worksiteCreation/utils/enums';
import { emailRegex, phoneNumberRegex } from '@utils/regex';
import { FormAddress } from '@components/formAddress/FormAddress';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { getEtablissementData } from '@models/sirene/apiRequests/sireneRequests';
import { convertToFormAddressDatas, placeholderExample } from '@utils/utils';
import { CompanyActivityStatusEnum, OperationTypeEnum } from '@utils/enums';
import { IWorksiteAddress } from '@models/worksiteCreation/utils/types/worksitesType';
import { DEFAULT_BENEFICIARY } from '../../../../../beneficiaries/utils/beneficiariesConstants';

interface IFormContentCreateBeneficiary {
  beneficiaryCivility: { civility: string; value: number };
  setBeneficiaryCivility: Dispatch<
    SetStateAction<{ civility: string; value: number }>
  >;
  setBeneficiaryAddress: Dispatch<
    SetStateAction<IBeneficiaryAddress | undefined>
  >;
  valuesForm: FieldValues;
  setValue: UseFormSetValue<FieldValues>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  worksiteAddress: IWorksiteAddress;
  updateWorksiteAddressFromKeyValue: (
    updates: Partial<IWorksiteAddress>
  ) => void;
}

function FormContentCreateBeneficiary({
  beneficiaryCivility,
  setBeneficiaryCivility,
  setBeneficiaryAddress,
  valuesForm,
  setValue,
  isLoading,
  setIsLoading,
  worksiteAddress,
  updateWorksiteAddressFromKeyValue,
}: IFormContentCreateBeneficiary) {
  const { t } = useTranslation();

  const placeholders = placeholderExample(t);

  const { worksiteOperationType } = useContext(WorksiteCreationContext);

  const [valueCivility, setValueCivility] = useState<string>(
    CivilityEnum.MR.toString()
  );

  const [isManualAddress, setIsManualAddress] = useState(false);
  const [valueAddress, setValueAddress] = useState<IBeneficiaryAddress>(
    DEFAULT_BENEFICIARY.address
  );
  const [validSiretGetted, setValidSiretGetted] = useState<boolean>(false);

  const resetInformations = () => {
    setValidSiretGetted(false);
    setValue('company_name', '');
    setValue('address', '');
    setValue('id', '');
    setValue('siren', '');
    setValue('legal_category', '');
    setValue('zipcode', '');
    setValue('city', '');
    setValue('entity_to_id', '');
  };

  const onSiretChange = async (value: string) => {
    resetInformations();
    if (value.length === 14) {
      setIsLoading(true);
      const response = await getEtablissementData(
        value,
        setIsLoading,
        setValue
      );
      if (response) {
        setValidSiretGetted(true);
      }
    }
  };

  useEffect(() => {
    if (worksiteAddress) {
      setValue('numberStreet', worksiteAddress.numberStreet);

      let addressValue =
        worksiteAddress.numberStreet !== ''
          ? `${worksiteAddress.numberStreet} ${worksiteAddress.streetName}`
          : worksiteAddress.streetName;

      if (worksiteAddress.streetName === '') addressValue = '';

      setValue('address', addressValue);
      setValue('street', worksiteAddress.streetName);
      setValue('zipcode', worksiteAddress.zipCode);
      setValue('city', worksiteAddress.city);
      setValue('country', worksiteAddress.country);
    }
  }, [worksiteAddress]);

  useEffect(() => {
    if (valueCivility === CivilityEnum.MRS.toString()) {
      setBeneficiaryCivility({
        civility: t('forms.civility.mrs'),
        value: 1,
      });
    } else {
      setBeneficiaryCivility({
        civility: t('forms.civility.mr'),
        value: 2,
      });
    }
  }, [valueCivility]);

  useEffect(() => {
    setBeneficiaryAddress(valueAddress);
  }, [valueAddress]);

  return (
    <div className="flex flex-col space-y-[.5rem]  mt-[1rem]">
      {worksiteOperationType !== OperationTypeEnum.B2B && (
        <div className="flex space-x-[.5rem] w-full">
          <TextWithRadio
            label={t('forms.civility.mr')}
            value={CivilityEnum.MR.toString()}
            setSelectedValue={setValueCivility}
            isChecked={beneficiaryCivility.value === CivilityEnum.MR + 1}
            selectValue={beneficiaryCivility.civility}
            addClass="w-full"
          />
          <TextWithRadio
            label={t('forms.civility.mrs')}
            value={CivilityEnum.MRS.toString()}
            setSelectedValue={setValueCivility}
            isChecked={beneficiaryCivility.value === CivilityEnum.MRS + 1}
            selectValue={beneficiaryCivility.civility}
            addClass="w-full"
          />
        </div>
      )}
      <div className="flex space-x-[.5rem] w-full">
        {worksiteOperationType === OperationTypeEnum.B2B ? (
          <>
            <InputText
              id="siret"
              name="siret"
              label={t('partners.siret')}
              placeholder={placeholders.SIRET}
              required
              dataTestId="input_siret"
              typeNumber
              valid={valuesForm.siret && valuesForm.siret.length === 14}
              rules={{
                pattern: {
                  value: /^\d{14}$/,
                  message: t('forms.siret.error'),
                },
              }}
              error={
                valuesForm.status &&
                valuesForm.status !== CompanyActivityStatusEnum.ACTIVE
              }
              textError={
                valuesForm.status !== CompanyActivityStatusEnum.ACTIVE
                  ? t('forms.siret.error_invalid_status', {
                      company_name: valuesForm.company_name,
                    }) || ''
                  : undefined
              }
              maxLength={14}
              onChange={(value) => onSiretChange(String(value))}
              loading={isLoading}
              spinnerLoader
            />
            <InputText
              id="company_name"
              name="company_name"
              placeholder={t('partners.company_name')}
              required
              dataTestId="input_company_name"
              disabled={!validSiretGetted}
              loading={isLoading}
              spinnerLoader
            />
          </>
        ) : (
          <>
            <InputText
              id="firstname"
              name="firstname"
              label={t('worksites.beneficiary.first_name')}
              placeholder={placeholders.FIRSTNAME}
              required
              valid={valuesForm.firstname !== ''}
              dataTestId="input_first_name"
            />
            <InputText
              id="lastname"
              name="lastname"
              label={t('worksites.beneficiary.last_name')}
              placeholder={placeholders.LASTNAME}
              required
              valid={valuesForm.lastname !== ''}
              dataTestId="input_last_name"
            />
          </>
        )}
      </div>
      {worksiteOperationType === OperationTypeEnum.B2B ? (
        <>
          <InputText
            id="address"
            name="address"
            label={t('partners.address')}
            placeholder={placeholders.ADDRESS}
            disabled={!validSiretGetted}
            value={valuesForm.address || ''}
            loading={isLoading}
            spinnerLoader
            dataTestId="input_address_disabled"
            required
          />
          <div className="flex space-x-2 w-full">
            <InputText
              id="zipcode"
              name="zipcode"
              typeNumber
              maxLength={5}
              label={t('forms.address.manual.zip')}
              placeholder={placeholders.ZIP_CODE}
              disabled={!validSiretGetted}
              value={valuesForm.zipcode || ''}
              loading={isLoading}
              spinnerLoader
              dataTestId="input_zipcode_disabled"
              required
            />
            <InputText
              id="city"
              name="city"
              label={t('worksites.beneficiary.city')}
              placeholder={placeholders.CITY}
              disabled={!validSiretGetted}
              value={valuesForm.city || ''}
              loading={isLoading}
              spinnerLoader
              dataTestId="input_address_disabled"
              required
            />
          </div>
        </>
      ) : (
        <FormAddress
          setIsManual={setIsManualAddress}
          addressDatas={valueAddress}
          setAddressDatas={setValueAddress}
          isManual={isManualAddress}
          datas={convertToFormAddressDatas(worksiteAddress)}
          updateDatas={updateWorksiteAddressFromKeyValue}
          showLabel
          required
        />
      )}
      <InputText
        id="phone"
        name="phone"
        label={t('worksites.beneficiary.phone')}
        placeholder={placeholders.TELEPHONE}
        required
        valid={phoneNumberRegex.test(valuesForm.phone)}
        dataTestId="input_phone"
        error={!phoneNumberRegex.test(valuesForm.phone || '0102030405')}
      />
      <InputText
        id="phoneFix"
        name="phoneFix"
        label={t('forms.phoneFix.placeholder')}
        placeholder={placeholders.LANDLINE_TELEPHONE}
        valid={phoneNumberRegex.test(valuesForm.phoneFix)}
        error={!phoneNumberRegex.test(valuesForm.phoneFix || '0102030405')}
      />
      <InputText
        id="email"
        name="email"
        label={t('auth.registration.forms.email')}
        placeholder={placeholders.EMAIL}
        required
        valid={emailRegex.test(valuesForm.email)}
        dataTestId="input_email"
      />
      {worksiteOperationType === OperationTypeEnum.B2B && (
        <>
          <p className="w-full font-medium text-[1rem] mt-[1.0rem]">
            {t('worksites.beneficiary.signatory_infos')}
          </p>
          <div className="flex space-x-[.5rem] w-full">
            <TextWithRadio
              label={t('forms.civility.mr')}
              value={CivilityEnum.MR.toString()}
              setSelectedValue={setValueCivility}
              isChecked={beneficiaryCivility.value === CivilityEnum.MR + 1}
              selectValue={beneficiaryCivility.civility}
              addClass="w-full"
            />
            <TextWithRadio
              label={t('forms.civility.mrs')}
              value={CivilityEnum.MRS.toString()}
              setSelectedValue={setValueCivility}
              isChecked={beneficiaryCivility.value === CivilityEnum.MRS + 1}
              selectValue={beneficiaryCivility.civility}
              addClass="w-full"
            />
          </div>
          <div className="flex space-x-[.5rem] w-full">
            <InputText
              id="firstname"
              name="firstname"
              placeholder={placeholders.FIRSTNAME}
              required
              valid={valuesForm.signatoryFirstname !== ''}
              dataTestId="input_signatory_first_name"
            />
            <InputText
              id="lastname"
              name="lastname"
              placeholder={placeholders.LASTNAME}
              required
              valid={valuesForm.signatoryLastname !== ''}
              dataTestId="input_signatory_last_name"
            />
          </div>
          <InputText
            id="signatory_function"
            name="signatory_function"
            placeholder={placeholders.FUNCTION}
            required
            valid={valuesForm.signatoryFunction !== ''}
            dataTestId="input_signatory_function"
          />
        </>
      )}
    </div>
  );
}

export { FormContentCreateBeneficiary };
