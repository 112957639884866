import { Tag } from '@components/atomic/Tag';
import { blueSecondary, green, red, textGrey, orange } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { useContext, useMemo } from 'react';
import { convertKiloToMega } from '@utils/functions';
import { dateToDDMMYYY, numberWithSeparator } from '@utils/format';
import { GlobalContext } from '@context/globalContext';
import { useNavigate } from 'react-router-dom';
import { PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';

function CofracCard({ cofrac }: { cofrac: ICofracType }): JSX.Element {
  const { globalEnum } = useContext(GlobalContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const operationNumber = useMemo(() => {
    const cofracOps = cofrac.cofrac_lots_operations;
    const opNumber = cofracOps ? cofracOps.length : 0;
    if (opNumber > 1) return `${opNumber} ${t('cofrac.infos.operation')}s`;
    return `${opNumber} ${t('cofrac.infos.operation')}`;
  }, [cofrac]);

  const tagColor = useMemo(() => {
    if (cofrac.status === 2) return blueSecondary;
    if (cofrac.status === 3) return green;
    if (cofrac.status === 4) return red;

    return textGrey;
  }, [cofrac]);

  return (
    <div
      className="w-full bg-white border border-borderGrey rounded-default flex items-center justify-center flex-wrap my-4
        cursor-pointer"
      aria-hidden="true"
      onClick={() => navigate(PARTNERS_ROUTES_WITH_ID(cofrac.id).COFRAC_VIEW)}
    >
      <div className="w-full md:w-[30%] md:border-r md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex flex-wrap items-center justify-start mb-[0.875rem]">
          <span className="text-[1.25rem] font-medium mr-3">{cofrac.name}</span>
          <Tag
            label={cofrac.id.toString()}
            color={orange}
            addClass="border border-borderGrey"
          />
        </div>
      </div>
      <div className="w-full md:w-[70%] border-t md:border-t-0 border-borderGrey p-[1rem]">
        <div className="flex justify-between items-center">
          <div className="flex gap-2">
            <Tag
              label={cofrac.operation_type.code}
              color={textGrey}
              addClass="border border-borderGrey"
            />
            <div>{cofrac.operation_type.description}</div>
          </div>
          <span className="text-[1rem] font-medium">{`${operationNumber}`}</span>
        </div>
      </div>
      <div className="w-full p-[1rem] border-t border-borderGrey flex flex-justify-between">
        <div className="w-full">
          <div className="flex items-center">
            <Tag
              label={globalEnum.cofrac_lots_status[cofrac.status]}
              color={tagColor}
              addClass="mr-3"
            />
            <span className="mr-3 text-[0.875rem]">{`${t(
              'cofrac.infos.creation_date'
            )}: ${dateToDDMMYYY(String(cofrac.updated_at))}`}</span>
          </div>
        </div>
        <div className="w-full">
          <div className="flex justify-start md:justify-end">
            <div className="mr-3 text-[0.875rem]">
              <span>{t('cofrac.infos.volume_precarity')}:</span>&nbsp;
              <span className="text-purple">{`${convertKiloToMega(
                cofrac.volume.precaire
              )} MWhc`}</span>
            </div>
            <div className="mr-3 text-[0.875rem]">
              <span>{t('cofrac.infos.volume_classic')}:</span>&nbsp;
              <span className="text-iconBlue">{`${convertKiloToMega(
                cofrac.volume.classique
              )} MWhc`}</span>
            </div>
            <span className="mr-3 text-[0.875rem]">
              {`${t('cofrac.infos.total_volume')}: ${convertKiloToMega(
                cofrac.volume.total
              )} MWhc`}
            </span>
            <span className="mr-3 text-[0.875rem]">
              {`${t('cofrac.infos.total_prime')}: ${numberWithSeparator(
                String(cofrac.prime_total)
              )} €`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export { CofracCard };
