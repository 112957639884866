import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useMemo,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  getTheme,
  getThemesList,
} from '@models/settings/apiRequests/settingsRequests';
import {
  IThemeResponse,
  IThemesList,
  IThemeInList,
} from '@models/settings/utils/settingsTypes';
import { applyTheme } from '@assets/color';
import { GlobalContext } from '@context/globalContext';
import { ENTITY_TYPES } from '@utils/roles';
import { AuthContext } from './authContext';

/**
 * Interface pour les valeurs du contexte des thèmes.
 */
interface ThemeContextProps {
  themeData: IThemeResponse | undefined;
  updateThemeData: (themeDataToUpdate: IThemeResponse | undefined) => void;
  themesList: IThemesList;
  updateThemesList: Dispatch<SetStateAction<IThemesList>>;
  themeSlug: string | undefined;
  updateThemeSlug: Dispatch<SetStateAction<string | undefined>>;
  themeCompanyName: string | undefined;
  updateThemeCompanyName: Dispatch<SetStateAction<string | undefined>>;
}

/**
 * Création du contexte des thèmes.
 */
export const ThemeContext = createContext({} as ThemeContextProps);

/**
 * Fournisseur de contexte pour gérer les thèmes de l'application.
 * @param {ReactNode} children - Les composants enfants.
 * @returns {JSX.Element} Le fournisseur de contexte.
 */
function ThemeContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { userView, partnersMenu, globalEnum, updateLayoutIsLoading } =
    useContext(GlobalContext);
  const { token } = useContext(AuthContext);
  const [themeData, setThemeData] = useState<IThemeResponse>();
  const [themesList, setThemesList] = useState<IThemesList>([]);
  const [themeSlug, setThemeSlug] = useState<string | undefined>();
  const [themeCompanyName, setThemeCompanyName] = useState<
    string | undefined
  >();
  const [datasLoaded, setDatasLoaded] = useState(false);

  /**
   * Met à jour les données du thème et applique le thème.
   * @param {IThemeResponse | undefined} themeDataToUpdate - Les données du thème à mettre à jour.
   */
  const updateThemeData = (
    themeDataToUpdate: IThemeResponse | undefined
  ): void => {
    setThemeData(themeDataToUpdate);
    applyTheme(themeDataToUpdate, setThemeData);
  };

  const resetThemeData = () => {
    updateThemeData(undefined);
    setThemeSlug(undefined);
    setThemeCompanyName(undefined);
  };

  /**
   * Récupère les données d'un thème spécifique par ID d'entité.
   * @param {number} entityId - L'ID de l'entité.
   */
  const getThemeData = async (entityId: number): Promise<void> => {
    const response = await getTheme(entityId);
    if (response) {
      if (response.status === 200) {
        updateThemeData(response.data.data.theme);
        setThemeSlug(response.data.data.slug);
        setThemeCompanyName(response.data.data.company_name);
      } else {
        resetThemeData();
      }
    } else {
      resetThemeData();
    }
    setDatasLoaded(true);
  };

  /**
   * Récupère et gère la liste des thèmes disponibles.
   *
   * Cette fonction :
   * 1. Récupère la liste des thèmes depuis back-crm
   * 2. Filtre les thèmes valides
   * 3. Met à jour la liste des thèmes dans le state
   * 4. Gère la sélection du thème actif selon la priorité suivante :
   *    - Thème déjà sélectionné (themeSlug)
   *    - Thème stocké dans le localStorage (whitelabel_id)
   *    - Réinitialisation si un seul thème disponible et aucun thème n'est sélectionné
   * 5. Réinitialise les données si aucun thème n'est trouvé
   *
   * @returns {Promise<void>}
   */
  const getThemesListData = async (): Promise<void> => {
    const response = await getThemesList();
    if (response) {
      const list = response.data.data;
      if (list.themes && list.themes.length > 0) {
        const themes = list.themes.filter(
          (themeInList: IThemeInList) => themeInList.theme
        );
        if (themes.length > 0) {
          // On garde la liste des thèmes disponibles dans tous les cas
          setThemesList(themes);

          // On vérifie d'abord si un thème est déjà sélectionné
          const themeId = localStorage.getItem('whitelabel_id');
          if (themeSlug) {
            const theme = themes.find(
              (themeInList: IThemeInList) =>
                themeInList.company_name.toLowerCase() ===
                themeSlug.toLowerCase()
            );
            updateThemeData(theme?.theme);
            setThemeCompanyName(theme?.company_name);
          } else if (themeId) {
            const theme = themes.find(
              (themeInList: IThemeInList) =>
                themeInList.entity_id === parseInt(themeId, 10)
            );
            setThemeSlug(theme?.company_name.toLowerCase());
            updateThemeData(theme?.theme);
            setThemeCompanyName(theme?.company_name);
          } else if (themes.length === 1) {
            // Si aucun thème n'est sélectionné et qu'il n'y a qu'un seul thème
            resetThemeData();
          }
        } else {
          resetThemeData();
        }
      } else {
        resetThemeData();
      }
    } else {
      resetThemeData();
    }
    setDatasLoaded(true);
  };

  useEffect(() => {
    if (
      Object.keys(globalEnum).length > 1 &&
      (!token || (partnersMenu.length > 0 && datasLoaded))
    ) {
      updateLayoutIsLoading(false);
    }
  }, [globalEnum, partnersMenu, datasLoaded]);

  /**
   * Effet secondaire qui réagit aux changements de la vue utilisateur pour gérer les thèmes.
   */
  useEffect(() => {
    if (userView) {
      if (userView.entity_type === ENTITY_TYPES.INSTALLATEUR) {
        setThemesList([]);
        getThemesListData();
      } else if (userView.entity_id) {
        setThemesList([]);
        getThemeData(userView.entity_id);
      }
    }
  }, [userView]);

  /**
   * Mémoïsation des valeurs du contexte pour optimiser les performances.
   */
  const contextValue = useMemo(
    () => ({
      themeData,
      themesList,
      themeSlug,
      themeCompanyName,
      updateThemeData,
      updateThemesList: setThemesList,
      updateThemeSlug: setThemeSlug,
      updateThemeCompanyName: setThemeCompanyName,
    }),
    [themeData, themesList, themeSlug, themeCompanyName]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContextProvider };
