import { axiosClient } from '@api/apiClient';
import { Dispatch, SetStateAction } from 'react';
import {
  urlGetBusinessProviderInfos,
  urlGetFinancialDataDetails,
  urlPostPartnerFinancialData,
  urlPostPartnerInfos,
  urlPostSettingsFinancialData,
  urlPutBusinessProvider,
} from '@api/apiUrls';
import { TFunction } from 'i18next';
import { displayError } from '@utils/format';
import {
  IPayloadCreateBusinessProvider,
  // IPayloadFinancialData,
  IUpsertPartner,
} from '../utils/types/partnersType';

export const getBusinessProviderInfos = async (entityId: number) => {
  try {
    const response = await axiosClient.get(
      urlGetBusinessProviderInfos(entityId)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const storeBusinessProvider = async (
  t: TFunction,
  data: IPayloadCreateBusinessProvider,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setModal: Dispatch<SetStateAction<boolean>>,
  refreshList: CallableFunction
): Promise<string> => {
  try {
    await axiosClient.put(urlPutBusinessProvider, data);
    setModal(false);
    refreshList();
    return '';
  } catch (error) {
    setLoading(false);
    if (!error.response?.data?.errors) {
      return t('global.error') || 'Erreur inconnue';
    }
    return error.response.data.errors;
  }
};

export const updateBusinessProviderInfos = async (
  entityId: number,
  userId: number,
  data: Partial<IUpsertPartner>
) => {
  try {
    await axiosClient.post(urlPostPartnerInfos(entityId, userId), data);
    return true;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getFinancialDataDetails = async (entityId: number) => {
  try {
    const response = await axiosClient.get(
      urlGetFinancialDataDetails(entityId)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const upsertPartnerFinancialData = async (data: FormData) => {
  try {
    const response = await axiosClient.post(urlPostPartnerFinancialData, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const upsertSettingsFinancialData = async (
  entityId: number,
  data: FormData
) => {
  try {
    const response = await axiosClient.post(
      urlPostSettingsFinancialData(entityId),
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};
