import { applyUpdate, checkCaching } from '@utils/functions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import packageJson from '../../package.json';

/**
 * Hook personnalisé pour gérer les mises à jour de l'application
 * Vérifie si une mise à jour est disponible et affiche un toast pour informer l'utilisateur
 */
export function useAppUpdate() {
  const { t } = useTranslation();

  useEffect(() => {
    // Fonction pour afficher le toast de mise à jour
    const showUpdateToast = () => {
      toast.info(
        t('global.update_toast') ||
          'Une nouvelle version est disponible. Cliquez ici pour mettre à jour.',
        {
          position: 'bottom-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false, // L'utilisateur doit cliquer sur le bouton, pas sur le toast
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: true, // Affiche un bouton pour fermer le toast si l'utilisateur préfère mettre à jour plus tard
          onClick: () => {
            // Utilisateur a cliqué pour mettre à jour
            applyUpdate();
          },
          style: {
            width: '22rem',
            cursor: 'pointer',
          },
        }
      );
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'version' && event.newValue !== packageJson.version) {
        const updateAvailable = checkCaching();
        if (updateAvailable) {
          showUpdateToast();
        }
      }
    };

    // Vérification initiale
    const version = localStorage.getItem('version');
    const updateAvailable = checkCaching();

    // Ne montrer le toast que si une version était déjà stockée localement
    // et qu'elle est différente de la version actuelle
    if (version && updateAvailable) {
      showUpdateToast();
    } else if (!version) {
      // Si c'est la première fois (pas de version stockée), initialiser la version
      localStorage.setItem('version', packageJson.version);
    }

    // Écouter les changements dans localStorage (même depuis d'autres onglets)
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
}
