/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Modal } from '@components/Modal';
import { storeMaterial } from '@models/materials/apiRequests/materialsRequests';
import { ILabelValue } from '@globalTypes/globalTypes';
import { IMaterialFormInputs } from '../utils/materialTypes';
import MaterialForm from './MaterialForm';
import { prepareMaterialDataForApi } from '../utils/utils';

interface IModalAddProductProps {
  brand_id: number;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  refetch: CallableFunction;
  operationsOptions: ILabelValue[];
}

function ModalAddProduct({
  brand_id,
  setShowModal,
  refetch,
  operationsOptions,
}: IModalAddProductProps) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<IMaterialFormInputs>({
    // Si vous avez des valeurs par défaut, assurez-vous de les mettre à jour avec le préfixe 'product_'
  });
  const { watch } = methods;
  const valuesForm = watch();

  // Fonction pour vérifier que toutes les valeurs sont remplies
  const checkValues =
    valuesForm.product_name && valuesForm.product_operations?.length > 0;

  const onSubmit = async (formData: FieldValues) => {
    setLoading(true);
    const storedMaterial = prepareMaterialDataForApi(formData, brand_id);
    // Envoi des données (vous devrez ajuster storeMaterial pour gérer le fichier)
    await storeMaterial(storedMaterial, t);
    refetch();
    setShowModal(false);
  };

  return (
    <Modal
      title={t('brand.products.new') || 'Nouveau produit'}
      formId="form-material-creation"
      textBtnConfirm={t('buttons.confirm') || ''}
      setIsModal={setShowModal}
      backgroundTransparent
      btnCancel
      sidebarVisible
      isLoading={loading}
      btnConfirmDisabled={!checkValues}
    >
      <FormProvider {...methods}>
        <MaterialForm
          onSubmit={onSubmit}
          operationsOptions={operationsOptions}
          formId="form-material-creation"
        />
      </FormProvider>
    </Modal>
  );
}

export { ModalAddProduct };
