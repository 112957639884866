import { TFunction } from 'i18next';
import {
  HABITATION,
  HEATING_TYPE,
  WORKSITE_AGE,
  worksiteCategoryEnum,
} from '@models/worksiteCreation/utils/enums';
import {
  blueSecondary,
  darkGreen,
  green,
  orange,
  purple,
  textGrey,
} from '@assets/color';
import {
  fetchAhGeneralData,
  getGraph,
} from '@models/worksites/apiRequests/worksitesRequests';
import { Dispatch, SetStateAction } from 'react';
import { ENTITY_TYPES } from '@utils/roles';
import { fileTypeEnum, OperationTypeEnum } from '@utils/enums';
import {
  IWorksiteDetails,
  IWorksiteOperation,
} from '@models/worksites/utils/worksitesTypes';
import { IUserType } from '@models/auth/utils/types';
import {
  IGraph,
  ISimulatorDataOperation,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { DOCUMENT_STATUS_ENUM } from '@utils/utils';
import {
  IAhExtraDataItem,
  IGlobalEnumType,
  IKeyStringType,
  ILinkedFile,
} from '../../../types/globalTypes';
import { WORKSITE_OPERATION_STATUS, WORKSITE_STATUS } from './enums';

export const totalBonus = (details: any): number => {
  return details.reduce((total: number, detail: any) => {
    const cdpAmount = parseFloat(detail.cdp_amount) || 0;
    const ceeAmount = parseFloat(detail.cee_amount) || 0;
    const mprAmount = parseFloat(detail.mpr_amount) || 0;

    const ceeToAdd = cdpAmount > 0 ? cdpAmount : ceeAmount;
    return total + ceeToAdd + mprAmount;
  }, 0);
};

export const getKeyByValue = (
  obj: IKeyStringType,
  value: string,
  t: TFunction
) => {
  const keys = Object.keys(obj);
  const valueToSend =
    value === t('worksites.toprocess') ? 'Tâches à réaliser' : value;
  const result = keys.find((el) => obj[el] === valueToSend);
  if (result) {
    return parseFloat(result);
  }
  return null;
};

export const getWorksiteOperationStatusColor = (step: number) => {
  switch (Number(step)) {
    case 0:
      return textGrey;
    case 1:
      return blueSecondary;
    case 3:
      return purple;
    case 6:
    case 8:
      return green;
    case 7:
      return darkGreen;
    default:
      return orange;
  }
};

export const getAideApiDataFromValue = (
  value: string,
  globalEnum: IGlobalEnumType,
  enumValue: string
): string => {
  switch (enumValue) {
    case 'age_type':
      if (value === globalEnum[enumValue][1]) return WORKSITE_AGE.BETWEEN;
      if (value === globalEnum[enumValue][3]) return WORKSITE_AGE.OLD;
      return WORKSITE_AGE.YOUNG;
    case 'housing_type':
      if (value === globalEnum[enumValue][1]) return HABITATION.HOUSE;
      if (value === globalEnum[enumValue][2]) return HABITATION.APARTMENT;
      return '';
    case 'heating_type':
      if (value === globalEnum[enumValue][1]) return HEATING_TYPE.COMBUSTIBLE;
      if (value === globalEnum[enumValue][2]) return HEATING_TYPE.ELECTRICITY;
      return '';
    default:
      return ''; //
  }
};

// récupère les données générales AH et les formate pour l'affichage
export const getAhGeneralData = async (
  id: number,
  updateAhGeneralData: Dispatch<SetStateAction<IAhExtraDataItem[] | undefined>>
) => {
  const responseFromApi = await fetchAhGeneralData(id);

  if (responseFromApi) {
    // on filtre la réponse en retirant les données qui ont la clé 'notDisplay'
    const filterRespApi: any = Object.values(responseFromApi).filter(
      (obj: any) => {
        return !obj.notDisplay;
      }
    );
    const transformedData: IAhExtraDataItem[] = Object.values(filterRespApi);
    updateAhGeneralData([...transformedData]);
  }
};

export const canEditWorksite = (status: number, userType: number): boolean => {
  return (
    (status <= WORKSITE_STATUS.WORKSITE_IN_PROGRESS &&
      userType === ENTITY_TYPES.INSTALLATEUR) ||
    (userType !== ENTITY_TYPES.INSTALLATEUR &&
      status <= WORKSITE_STATUS.FINISHED)
  );
};

const groupListByFileType = (files: ILinkedFile[]) => {
  return files.sort((a, b) => {
    // Trie fiscal_declaration en premier
    if (
      a.relation_type === 'fiscal_declaration' &&
      b.relation_type !== 'fiscal_declaration'
    )
      return -1;
    if (
      a.relation_type !== 'fiscal_declaration' &&
      b.relation_type === 'fiscal_declaration'
    )
      return 1;

    // Ensuite groupe par file_type
    if (a.file_type === b.file_type) return 0;
    return a.file_type < b.file_type ? -1 : 1;
  });
};

export const getWorksiteLinkedFiles = (worksite: IWorksiteDetails) => {
  const operationLinkedFiles = worksite.worksites_operations.flatMap(
    (operation) => operation.linkedFiles
  );

  const sortedWorksiteFiles = groupListByFileType(worksite.linkedFiles);

  const sortedWorksiteOperationFiles =
    groupListByFileType(operationLinkedFiles);

  return [...sortedWorksiteFiles, ...sortedWorksiteOperationFiles].filter(
    (file) => file.file_type !== fileTypeEnum.AUTRE && file.file_url
  );
};

export const groupDocuments = (
  linkedFiles: ILinkedFile[],
  forCheckpoints?: boolean // si true => exclut les documents qui ne sont pas à contrôler
) => {
  const files = linkedFiles.filter((file) => {
    if (!forCheckpoints) return true;
    if (file.file_type === fileTypeEnum.AH) {
      return file.status === DOCUMENT_STATUS_ENUM.PENDING;
    }
    return true;
  });

  return files.reduce<
    {
      files: ILinkedFile[];
      fileType: number;
    }[]
  >((acc, lf) => {
    const existingGroup = acc.find((elt) =>
      elt.files.some(
        (f) =>
          f.file_type === lf.file_type &&
          f.relation_type === lf.relation_type &&
          f.file_hash === lf.file_hash
      )
    );

    if (existingGroup) {
      existingGroup.files.push(lf);
      return acc;
    }

    return [
      ...acc,
      {
        files: [lf],
        fileType: lf.file_type,
      },
    ];
  }, []);
};
export const determineOperationCategory = (
  operation: IWorksiteOperation,
  operationType: OperationTypeEnum
) => {
  const operationCode = operation.operation.code.toLowerCase();
  if (operationCode.includes('bar')) {
    return operationType === OperationTypeEnum.B2C
      ? worksiteCategoryEnum.BAR_B2C
      : worksiteCategoryEnum.BAR_B2B;
  }
  if (operationCode.includes('bat')) {
    return worksiteCategoryEnum.BAT;
  }
  if (operationCode.includes('ind')) {
    return worksiteCategoryEnum.IND;
  }
  if (operationCode.includes('res')) {
    return worksiteCategoryEnum.RES;
  }
  if (operationCode.includes('agri')) {
    return worksiteCategoryEnum.AGRI;
  }
  return worksiteCategoryEnum.BAR_B2C;
};

export const determineWorksiteCategories = (
  worksiteDetails: IWorksiteDetails
) => {
  const operationsCodes = worksiteDetails.worksites_operations.map(
    (operation: IWorksiteOperation) => operation.operation.code.toLowerCase()
  );
  const categoriesSet = operationsCodes.reduce<Set<worksiteCategoryEnum>>(
    (acc, code) => {
      if (code.includes('bar')) {
        acc.add(
          worksiteDetails.operation_type === OperationTypeEnum.B2C
            ? worksiteCategoryEnum.BAR_B2C
            : worksiteCategoryEnum.BAR_B2B
        );
      }
      if (code.includes('bat')) {
        acc.add(worksiteCategoryEnum.BAT);
      }
      if (code.includes('ind')) {
        acc.add(worksiteCategoryEnum.IND);
      }
      if (code.includes('res')) {
        acc.add(worksiteCategoryEnum.RES);
      }
      if (code.includes('agri')) {
        acc.add(worksiteCategoryEnum.AGRI);
      }

      return acc;
    },
    new Set<worksiteCategoryEnum>()
  );

  return Array.from(categoriesSet);
};

export const transformWorksitePayloadToSimulatorDataOperation = (
  payload:
    | {
        operations?: Array<{
          [key: string]: any;
        }>;
      }
    | null
    | undefined,
  codeOperation: string
): ISimulatorDataOperation[] | null => {
  if (!payload || !Array.isArray(payload.operations)) return null;

  // Trouver l'opération qui correspond au codeOperation
  const matchingOperation = payload.operations.find(
    (op: { [key: string]: any }) => op['operation.id'] === codeOperation
  );

  if (!matchingOperation) return null;

  // On ne conserve que les clés qui commencent par "operation."
  const transformedOperation = Object.keys(matchingOperation).reduce(
    (acc: Record<string, any>, key: string) => {
      if (
        matchingOperation[key] !== undefined &&
        key.startsWith('operation.')
      ) {
        acc[key] = matchingOperation[key];
      }
      return acc;
    },
    {}
  );

  return [transformedOperation];
};

// Parcours le graph operation et le retourne à partir du noeud "multipleArrayForm" s'il est trouvé sinon retourne null
export const getMultipleArrayFormGraph = (
  code: string,
  graph: { operations: IGraph[] } | null
): IGraph | null => {
  if (!graph || !graph.operations) return null;

  const operation = graph.operations.find((op) => op.value === code);

  if (!operation || !operation.tree) return null;

  const findNodeWithMultipleArrayForm = (nodes: IGraph[]): IGraph | null => {
    let result: IGraph | null = null;

    nodes.some((node) => {
      if (node.input === 'multipleArrayForm') {
        result = node;
        return true;
      }
      if (node.childrens) {
        result = findNodeWithMultipleArrayForm(node.childrens);
        return !!result;
      }
      return false;
    });

    return result;
  };

  return findNodeWithMultipleArrayForm(operation.tree);
};

export const fetchGraph = async (
  codeOperation: string,
  operationType: number,
  conventionId: number
): Promise<any> => {
  try {
    return await getGraph([codeOperation], operationType, conventionId);
  } catch (error) {
    console.error('Error fetching graph:', error);
    return null;
  }
};

export const generalFieldsAreEditable = (
  user: IUserType,
  worksite: IWorksiteDetails
): boolean => {
  const installerOrAMO =
    user?.entity_type === ENTITY_TYPES.INSTALLATEUR ||
    user?.entity_type === ENTITY_TYPES.AMO;

  if (installerOrAMO) {
    return worksite.status <= WORKSITE_STATUS.WORKSITE_IN_PROGRESS;
  }
  return true;
};

// si installateur ou AMO et chantier > IN PROGRESS
export const operationFieldsAreEditable = (
  user: IUserType,
  operation: IWorksiteOperation
): boolean => {
  const installerOrAMO =
    user?.entity_type === ENTITY_TYPES.INSTALLATEUR ||
    user?.entity_type === ENTITY_TYPES.AMO;

  if (installerOrAMO) {
    return operation.status <= WORKSITE_OPERATION_STATUS.InProgress;
  }
  return true;
};
