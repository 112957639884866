import {
  IControlContactOperationTypeType,
  IControlContactType,
} from './controlContactTypes';

export const controlContactFakeData: IControlContactType[] = [
  {
    id: 8,
    reference_intern: 'CC-123456',
    reference_cofrac: 'COFRAC-123456',
    created_at: '2024-11-27',
    updated_at: '2024-11-27',
    regulated_rate: '20%',
    nb_controlled_operations: 18,
    nb_total_operations: 25,
    nb_required_operations: 5,
    status: 1,
    operation_type: {
      id: 28,
      code: 'BAR-EN-101',
      description: 'Isolation de combles ou de toitures',
    },
    linkable_worksites_operations: [],
    linked_worksites_operations: [],
  },
];

export const operationsToControlContactFakeData: IControlContactOperationTypeType[] =
  [
    {
      id: 28,
      code: 'BAR-EN-101',
      description: 'Isolation de combles ou de toitures',
    },
    {
      id: 29,
      code: 'BAR-EN-102',
      description: 'Isolation des murs',
    },
    {
      id: 30,
      code: 'BAR-EN-103',
      description: "Isolation d'un plancher",
    },
    {
      id: 32,
      code: 'BAR-EN-105',
      description: 'Isolation des toitures terrasses',
    },
    {
      id: 56,
      code: 'BAR-TH-113',
      description: 'Chaudière biomasse individuelle',
    },
    {
      id: 77,
      code: 'BAR-TH-159',
      description: 'Pompe à chaleur hybride individuelle',
    },
    {
      id: 87,
      code: 'BAR-TH-171',
      description: 'Pompe à chaleur de type air/eau',
    },
    {
      id: 88,
      code: 'BAR-TH-172',
      description: 'Pompe à chaleur de type eau/eau ou sol/eau',
    },
    {
      id: 96,
      code: 'BAT-EN-101',
      description: 'Isolation de combles ou de toiture',
    },
    {
      id: 97,
      code: 'BAT-EN-102',
      description: 'Isolation des murs',
    },
    {
      id: 98,
      code: 'BAT-EN-103',
      description: "Isolation d'un plancher",
    },
    {
      id: 100,
      code: 'BAT-EN-106',
      description: "Isolation de combles ou de toitures (France d'outre-mer)",
    },
    {
      id: 102,
      code: 'BAT-EN-108',
      description: 'Isolation des murs (France d’outre-mer)',
    },
    {
      id: 129,
      code: 'BAT-TH-113',
      description: 'Pompe à chaleur de type air/eau ou eau/eau',
    },
    {
      id: 139,
      code: 'BAT-TH-139',
      description: 'Récupération de chaleur sur groupe de production de froid',
    },
    {
      id: 150,
      code: 'BAT-TH-157',
      description: 'Chaudière biomasse collective',
    },
    {
      id: 154,
      code: 'IND-BA-112',
      description:
        'Système de récupération de chaleur sur une tour aéroréfrigérante',
    },
    {
      id: 160,
      code: 'IND-EN-102',
      description: "Isolation de combles ou de toitures (France d'outre-mer)",
    },
    {
      id: 161,
      code: 'IND-UT-102',
      description:
        'Système de variation électronique de vitesse sur un moteur asynchrone',
    },
    {
      id: 169,
      code: 'IND-UT-116',
      description:
        "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante",
    },
  ];

export const cofracControlledListFakeData: { id: number; name: string }[] = [
  { id: 1, name: 'COFRAC 1' },
  { id: 2, name: 'COFRAC 2' },
  { id: 3, name: 'COFRAC 3' },
];

export const controlContactLinkedListFakeData = [
  {
    id: 377,
    code: 'BAR-EN-102',
    description: 'Isolation des murs',
    precarity: 2,
    volume_classique: 80000,
    volume_precaire: 0,
    price: 551.2,
    price_precaire: 0,
    price_classique: 551.2,
    worksite_id: 3,
    beneficiary: {
      id: 7,
      civility: 1,
      firstname: null,
      lastname: null,
      address: {
        id: 10,
        worksite_id: null,
        worksite_name: null,
        address_type: 1,
        address: '40 impasse Leclercq',
        postal_code: '75000',
        city: 'Paris',
        country: 'France',
        elevation: null,
        latitude: null,
        longitude: null,
        additional_address: null,
        selected: true,
      },
      fixe_phone: '0175146050',
      mobile_phone: '0607830632',
      email: 'fnac.seed@gmail.com',
      siren: '350127460',
      siret: '35012746000284',
      company_name: 'FNAC SEED',
      addresses: {
        data: [
          {
            id: 10,
            worksite_id: null,
            worksite_name: null,
            address_type: 1,
            address: '40 impasse Leclercq',
            postal_code: '75000',
            city: 'Paris',
            country: 'France',
            elevation: null,
            latitude: null,
            longitude: null,
            additional_address: null,
            selected: true,
          },
        ],
      },
      signatory_function: null,
    },
    installer: {
      id: 8,
      type: null,
      siret: '10384621800073',
      company_name: 'INST-2',
      zipcode: '20339',
      city: 'Rodrigues',
      country: null,
      address: '862, chemin Léon Lemaitre\n34774 Lecoq',
      contact_email: null,
      phone_number: null,
      status: 2,
      logo: null,
      website: null,
      revenue: '1000',
      employees_number: null,
      iban_payment: null,
      firstname: null,
      lastname: null,
    },
    previous_used_status: null,
  },
  {
    id: 378,
    code: 'BAT-EN-104',
    description: 'Isolation des plafonds',
    precarity: 3,
    volume_classique: 50000,
    volume_precaire: 10000,
    price: 600.5,
    price_precaire: 120.1,
    price_classique: 480.4,
    beneficiary: {
      id: 8,
      civility: 2,
      firstname: 'Marie',
      lastname: 'Dupont',
      address: {
        id: 11,
        worksite_id: null,
        worksite_name: null,
        address_type: 2,
        address: '15 rue de la République',
        postal_code: '69000',
        city: 'Lyon',
        country: 'France',
        elevation: null,
        latitude: null,
        longitude: null,
        additional_address: null,
        selected: true,
      },
      fixe_phone: '0147852369',
      mobile_phone: '0612345678',
      email: 'marie.dupont@example.com',
      siren: '123456789',
      siret: '12345678901234',
      company_name: 'Dupont SARL',
      addresses: {
        data: [
          {
            id: 11,
            worksite_id: null,
            worksite_name: null,
            address_type: 2,
            address: '15 rue de la République',
            postal_code: '69000',
            city: 'Lyon',
            country: 'France',
            elevation: null,
            latitude: null,
            longitude: null,
            additional_address: null,
            selected: true,
          },
        ],
      },
      signatory_function: 'Directrice',
    },
    installer: {
      id: 9,
      type: null,
      siret: '20485736900085',
      company_name: 'INST-3',
      zipcode: '75001',
      city: 'Paris',
      country: null,
      address: '123 Boulevard Saint-Germain\n75001 Paris',
      contact_email: 'contact@inst3.com',
      phone_number: '0123456789',
      status: 1,
      logo: null,
      website: 'https://www.inst3.com',
      revenue: '2500',
      employees_number: 15,
      iban_payment: 'FR7630006000011234567890189',
      firstname: 'Jean',
      lastname: 'Martin',
    },
    previous_used_status: 'En attente',
  },
  {
    id: 379,
    code: 'IND-EN-105',
    description: 'Isolation thermique des sols',
    precarity: 1,
    volume_classique: 75000,
    volume_precaire: 5000,
    price: 700.0,
    price_precaire: 35.0,
    price_classique: 665.0,
    beneficiary: {
      id: 9,
      civility: 1,
      firstname: 'Pierre',
      lastname: 'Lefevre',
      address: {
        id: 12,
        worksite_id: null,
        worksite_name: null,
        address_type: 1,
        address: '8 avenue Victor Hugo',
        postal_code: '13001',
        city: 'Marseille',
        country: 'France',
        elevation: null,
        latitude: null,
        longitude: null,
        additional_address: null,
        selected: true,
      },
      fixe_phone: '0498765432',
      mobile_phone: '0698765432',
      email: 'pierre.lefevre@example.com',
      siren: '987654321',
      siret: '98765432109876',
      company_name: 'Lefevre Entreprises',
      addresses: {
        data: [
          {
            id: 12,
            worksite_id: null,
            worksite_name: null,
            address_type: 1,
            address: '8 avenue Victor Hugo',
            postal_code: '13001',
            city: 'Marseille',
            country: 'France',
            elevation: null,
            latitude: null,
            longitude: null,
            additional_address: null,
            selected: true,
          },
        ],
      },
      signatory_function: 'Président',
    },
    installer: {
      id: 10,
      type: null,
      siret: '30597648200029',
      company_name: 'INST-4',
      zipcode: '33000',
      city: 'Bordeaux',
      country: null,
      address: '45 rue Sainte-Catherine\n33000 Bordeaux',
      contact_email: 'support@inst4.com',
      phone_number: '0555666777',
      status: 3,
      logo: null,
      website: 'https://www.inst4.com',
      revenue: '4000',
      employees_number: 25,
      iban_payment: 'FR7630006000019876543210183',
      firstname: 'Sophie',
      lastname: 'Bernard',
    },
    previous_used_status: 'Validé',
  },
  {
    id: 380,
    code: 'IND-TH-118',
    description: 'Installation de panneaux solaires',
    precarity: 2,
    volume_classique: 60000,
    volume_precaire: 8000,
    price: 800.0,
    price_precaire: 160.0,
    price_classique: 640.0,
    beneficiary: {
      id: 10,
      civility: 2,
      firstname: 'Laura',
      lastname: 'Moreau',
      address: {
        id: 13,
        worksite_id: null,
        worksite_name: null,
        address_type: 2,
        address: '22 rue des Lilas',
        postal_code: '31000',
        city: 'Toulouse',
        country: 'France',
        elevation: null,
        latitude: null,
        longitude: null,
        additional_address: null,
        selected: true,
      },
      fixe_phone: '0543216789',
      mobile_phone: '0678901234',
      email: 'laura.moreau@example.com',
      siren: '564738291',
      siret: '56473829101234',
      company_name: 'Moreau Installations',
      addresses: {
        data: [
          {
            id: 13,
            worksite_id: null,
            worksite_name: null,
            address_type: 2,
            address: '22 rue des Lilas',
            postal_code: '31000',
            city: 'Toulouse',
            country: 'France',
            elevation: null,
            latitude: null,
            longitude: null,
            additional_address: null,
            selected: true,
          },
        ],
      },
      signatory_function: 'Gérante',
    },
    installer: {
      id: 11,
      type: null,
      siret: '40618273600010',
      company_name: 'INST-5',
      zipcode: '59000',
      city: 'Lille',
      country: null,
      address: '78 rue de Béthune\n59000 Lille',
      contact_email: 'contact@inst5.com',
      phone_number: '0333444555',
      status: 2,
      logo: null,
      website: 'https://www.inst5.com',
      revenue: '3500',
      employees_number: 20,
      iban_payment: 'FR7630006000011122334455667',
      firstname: 'Antoine',
      lastname: 'Durand',
    },
    previous_used_status: 'En cours',
  },
  {
    id: 381,
    code: 'BAT-EN-107',
    description: 'Isolation des fondations',
    precarity: 1,
    volume_classique: 90000,
    volume_precaire: 2000,
    price: 900.0,
    price_precaire: 18.0,
    price_classique: 882.0,
    beneficiary: {
      id: 11,
      civility: 1,
      firstname: 'Lucas',
      lastname: 'Simon',
      address: {
        id: 14,
        worksite_id: null,
        worksite_name: null,
        address_type: 1,
        address: '5 avenue des Champs',
        postal_code: '75008',
        city: 'Paris',
        country: 'France',
        elevation: null,
        latitude: null,
        longitude: null,
        additional_address: null,
        selected: true,
      },
      fixe_phone: '0167890123',
      mobile_phone: '0690123456',
      email: 'lucas.simon@example.com',
      siren: '192837465',
      siret: '19283746509876',
      company_name: 'Simon Construction',
      addresses: {
        data: [
          {
            id: 14,
            worksite_id: null,
            worksite_name: null,
            address_type: 1,
            address: '5 avenue des Champs',
            postal_code: '75008',
            city: 'Paris',
            country: 'France',
            elevation: null,
            latitude: null,
            longitude: null,
            additional_address: null,
            selected: true,
          },
        ],
      },
      signatory_function: 'Chef de projet',
    },
    installer: {
      id: 12,
      type: null,
      siret: '50729384700056',
      company_name: 'INST-6',
      zipcode: '44000',
      city: 'Nantes',
      country: null,
      address: '34 rue Crébillon\n44000 Nantes',
      contact_email: 'info@inst6.com',
      phone_number: '0244667888',
      status: 1,
      logo: null,
      website: 'https://www.inst6.com',
      revenue: '5000',
      employees_number: 30,
      iban_payment: 'FR7630006000019988776655443',
      firstname: 'Émilie',
      lastname: 'Petit',
    },
    previous_used_status: 'Complété',
  },
  {
    id: 382,
    code: 'IND-UT-117',
    description: 'Maintenance des systèmes de chauffage',
    precarity: 3,
    volume_classique: 65000,
    volume_precaire: 7000,
    price: 750.0,
    price_precaire: 105.0,
    price_classique: 645.0,
    beneficiary: {
      id: 12,
      civility: 2,
      firstname: 'Claire',
      lastname: 'Martin',
      address: {
        id: 15,
        worksite_id: null,
        worksite_name: null,
        address_type: 2,
        address: '12 boulevard de la Liberté',
        postal_code: '67000',
        city: 'Strasbourg',
        country: 'France',
        elevation: null,
        latitude: null,
        longitude: null,
        additional_address: null,
        selected: true,
      },
      fixe_phone: '0387654321',
      mobile_phone: '0676543210',
      email: 'claire.martin@example.com',
      siren: '564738291',
      siret: '56473829109876',
      company_name: 'Martin Services',
      addresses: {
        data: [
          {
            id: 15,
            worksite_id: null,
            worksite_name: null,
            address_type: 2,
            address: '12 boulevard de la Liberté',
            postal_code: '67000',
            city: 'Strasbourg',
            country: 'France',
            elevation: null,
            latitude: null,
            longitude: null,
            additional_address: null,
            selected: true,
          },
        ],
      },
      signatory_function: 'Responsable',
    },
    installer: {
      id: 13,
      type: null,
      siret: '60817263500089',
      company_name: 'INST-7',
      zipcode: '69001',
      city: 'Lyon',
      country: null,
      address: '56 rue de la République\n69001 Lyon',
      contact_email: 'support@inst7.com',
      phone_number: '0477889900',
      status: 2,
      logo: null,
      website: 'https://www.inst7.com',
      revenue: '3200',
      employees_number: 18,
      iban_payment: 'FR7630006000015566778899001',
      firstname: 'Marc',
      lastname: 'Leroy',
    },
    previous_used_status: 'En révision',
  },
];
