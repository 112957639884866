import { blueOpx, green, red } from '@assets/color';
import { useContext, useMemo, useState } from 'react';
import { ColorCube } from '@components/atomic/ColorCube';
import { ChevronDownIcon } from '@assets/images/svgComponents';
import { ProgressBarVertical } from '@components/atomic/ProgressBarVertical';
import { useTranslation } from 'react-i18next';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { calculateTotalCostTTC } from '@models/mar/utils/functions';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { progressStepsWithTranslation } from '@models/mar/utils/enums';
import { WorksiteDetailsOperationCardHeader } from '@models/worksites/components/worksiteDetails/operations/WorksiteDetailsOperationCardHeader';
import { WorksiteDetailsOperationCardSteps } from '@models/worksites/components/worksiteDetails/operations/WorksiteDetailsOperationCardSteps';
import { dateToDDMMYYY } from '@utils/format';
import { IRevisionStatus } from '@models/worksites/utils/worksitesTypes';

function ProgressCardWorksiteMar(): JSX.Element {
  const { t } = useTranslation();
  const { worksiteData } = useContext(WorksiteCreationMarContext);

  const [detailsIsActive, setDetailsIsActive] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [stepsHeight, setStepsHeight] = useState<number>(0);

  const totalAmount = calculateTotalCostTTC(worksiteData as IWorksiteMar);

  const generalInfos = useMemo(() => {
    return {
      title: t('mar.progress.card.major_renovation'),
      totalAmount: `${t('worksites.cost')} ${
        totalAmount ? totalAmount.toLocaleString() : '-'
      }`,
      haveCdp: false,
      lastUpdate: dateToDDMMYYY(worksiteData?.updated_at) || 'XX/XX/XXXX',
    };
  }, [totalAmount]);

  const lastUpdateDate = useMemo(
    () => `${t('worksites.update')} : ${generalInfos.lastUpdate}`,
    [generalInfos, t]
  );

  const stepsProgress = Object.values(progressStepsWithTranslation(t));

  const activeStepProgress =
    stepsProgress.findIndex((step) =>
      step.steps.includes(worksiteData?.step as number)
    ) + 1 || stepsProgress.length + 1;

  const stepsList = stepsProgress.map((step) => step.label);

  const stepUpdates = (worksiteData as IWorksiteMar).steps_updated_at;

  const transformToRevisionStatus = (
    stepsUpdatedAt: Record<string, string | null>
  ): IRevisionStatus[] => {
    return Object.keys(stepsUpdatedAt)
      .filter((key) => !!stepsUpdatedAt[key])
      .map((key) => {
        const stepDate = stepsUpdatedAt[key];

        return {
          created_at: stepDate ?? 'N/A', // Assigner 'N/A' ou une autre valeur si la date est nulle
          old_status: parseInt(key, 10), // Utiliser la clé pour l'ancien statut
          status: stepDate ? 'Completed' : 'Pending', // Statut selon si l'étape est passée ou non
        };
      });
  };

  const revisionStatusArray: IRevisionStatus[] =
    transformToRevisionStatus(stepUpdates);

  const stepsListWithValues = stepsList.map((step, index) => ({
    key: String(index + 1),
    value: step,
  }));

  return (
    <div>
      <div
        className="w-full h-full pr-[1.5rem] rounded-[.25em] border-[1px] flex border-grey bg-white"
        onClick={() => {
          if (!detailsIsActive) {
            setDetailsIsActive(true);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !detailsIsActive) {
            setDetailsIsActive(true);
          }
        }}
        role="button"
        tabIndex={0}
        data-test-id="worksite_mar_card"
      >
        <div className="px-[2rem]">
          <ProgressBarVertical
            color={activeStepProgress > stepsList.length ? green : red}
            headerHeight={headerHeight}
            stepHeight={stepsHeight}
            showList={detailsIsActive}
            stepActive={activeStepProgress}
            stepsList={stepsListWithValues}
            dataTestId="worksite_step_active"
          />
        </div>
        <div className="w-full flex space-x-[1.5rem] items-baseline">
          <div className="w-full">
            <WorksiteDetailsOperationCardHeader
              infos={generalInfos}
              detailsIsActive={detailsIsActive}
              lastUpdateDate={lastUpdateDate}
              setHeaderHeight={setHeaderHeight}
              setDetailsIsActive={setDetailsIsActive}
            />
            <WorksiteDetailsOperationCardSteps
              detailsIsActive={detailsIsActive}
              stepsList={stepsListWithValues}
              stepActive={activeStepProgress}
              setStepHeight={setStepsHeight}
              lastUpdateDate={lastUpdateDate}
              revisionStatus={revisionStatusArray}
            />
          </div>
          <ColorCube
            numberOrIcon={
              <ChevronDownIcon
                style={{ rotate: detailsIsActive ? '180deg' : '' }}
              />
            }
            color={blueOpx}
            size="1.5rem"
            onClick={() => setDetailsIsActive(!detailsIsActive)}
            opacity
            dataTestId="show_steps_list"
          />
        </div>
      </div>
    </div>
  );
}

export { ProgressCardWorksiteMar };
