import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { isBeforeDate } from '@utils/functions';
import { storeCofracDateAndControlOffice } from '@models/cofrac/apiRequests/cofracRequests';
import { ICofracType } from '@models/cofrac/utils/cofracTypes';
import { toAPIDateStr } from '@utils/format';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { getPartnersList } from '@models/partners/apiRequests/partnersRequest';
import { IControlOfficeCofracType } from '@models/auth/utils/types';
import { useForm, FormProvider } from 'react-hook-form';
import { ModalAddPartnerForm } from '@models/partners/components/ModalAddPartnerForm';
import { ENTITY_TYPES } from '@utils/roles';

interface CofracDateProps {
  setModal: Dispatch<SetStateAction<string>>;
  cofrac: ICofracType | undefined;
  setCofrac: Dispatch<SetStateAction<ICofracType | undefined>>;
}

function CofracDate({
  setModal,
  cofrac,
  setCofrac,
}: CofracDateProps): JSX.Element {
  const { t } = useTranslation();

  const methods = useForm();
  const { handleSubmit, setValue, watch } = methods;
  const formDataValues = watch();

  const [error, setError] = useState<boolean>(false);
  const [isFormNotValid, setIsFormNotValid] = useState<boolean>(false);
  const [addControlOfficeModal, setAddControlOfficeModal] =
    useState<boolean>(false);
  const [controlOfficeList, setControlOfficeList] = useState<
    IControlOfficeCofracType[]
  >([]);
  const [controlOfficeActif, setControlOfficeActif] =
    useState<IControlOfficeCofracType>();
  const [isNew, setIsNew] = useState(false);

  const prevListRef = useRef<IControlOfficeCofracType[]>();

  const getControlOfficeList = async () => {
    const resList = await getPartnersList({
      page: 1,
      perPage: 999, // nombre max d'item par page.
      entityType: ENTITY_TYPES.BUREAUX_CONTROLE,
    });

    if (resList && resList.data) {
      const updatedControlOfficeList = resList.data.reduce(
        (acc: IControlOfficeCofracType[], item: any) => {
          if (item.id_partenaire && item.company_name) {
            acc.push({
              id: item.id_partenaire,
              name: item.company_name,
            });
          }
          return acc;
        },
        []
      );
      setControlOfficeList(updatedControlOfficeList);
    }
  };

  const isValidDate = (date: string | undefined): boolean => {
    if (!date) return true; // Si aucune date n'est renseignée, on considère qu'il n'y a pas d'erreur
    const today = new Date().toJSON().slice(0, 10);
    return !isBeforeDate(date, today);
  };

  const onSubmit = () => {
    if (!formDataValues.cofrac_date || !formDataValues.entity_control_id) {
      return;
    }

    if (cofrac && !error) {
      storeCofracDateAndControlOffice(
        cofrac.id,
        formDataValues.entity_control_id,
        toAPIDateStr(formDataValues.cofrac_date),
        'cofrac_date'
      ).then((res) => {
        if (res.data) {
          setCofrac(res.data);
          setModal('');
        }
      });
    }
  };

  useEffect(() => {
    getControlOfficeList();
  }, []);

  useEffect(() => {
    const { cofrac_date, entity_control_id } = formDataValues;

    const isDateValid = isValidDate(cofrac_date);
    setError(!isDateValid);

    const isEntityValid = !!entity_control_id;

    const notValid = !isDateValid || !isEntityValid;
    setIsFormNotValid(notValid);
  }, [formDataValues]);

  // sélectionne automatiquement le bureau de contrôle qui vient d'être créé
  useEffect(() => {
    if (!prevListRef.current) {
      prevListRef.current = controlOfficeList;
      return;
    }

    const currentList = prevListRef.current;

    if (controlOfficeList.length > currentList.length) {
      const newControlOffice = controlOfficeList.filter(
        (co) => !currentList.some((item) => item.id === co.id)
      );
      if (newControlOffice.length > 0 && isNew) {
        setControlOfficeActif(newControlOffice[0]);
        setIsNew(false);
      }
    }
    // reinitialize
    prevListRef.current = controlOfficeList;
  }, [controlOfficeList]);

  useEffect(() => {
    if (controlOfficeActif) {
      setValue('entity_control_id', controlOfficeActif.id);
    }
  }, [controlOfficeActif, setValue]);

  return (
    <div>
      {addControlOfficeModal ? (
        <ModalAddPartnerForm
          refreshList={getControlOfficeList}
          setModal={setAddControlOfficeModal}
          entityType={ENTITY_TYPES.BUREAUX_CONTROLE}
        />
      ) : (
        <FormProvider {...methods}>
          <Modal
            title={`${t('cofrac.btn_validate_lot')} COFRAC`}
            buttonsPosition="bottom"
            backgroundTransparent
            sidebarVisible
            textBtnConfirm={`${t('buttons.validate')}`}
            onConfirmClick={handleSubmit(onSubmit)}
            btnCancel
            onClickCancel={() => setModal('')}
            btnConfirmDisabled={isFormNotValid}
          >
            <div className="grid rows gap-5">
              <div>
                <p>{t('cofrac.form.date_text')}</p>
                <DatePicker
                  addClass="mt-5"
                  label={`${t('cofrac.form.date_placeholder')}`}
                  required
                  onChangeDate={(date) => setValue('cofrac_date', date)}
                  defaultDate={formDataValues.cofrac_date}
                  error={error}
                  textError={`${t('deposits.errors.start_date')}`}
                />
              </div>
              <div>
                <p className="text-textGrey text-[.75rem] leading-3 mb-2">
                  {t('cofrac.inspection_office.title')}
                </p>
                <div className="flex gap-5 items-center w-full">
                  <InputSelect
                    valueInput={
                      controlOfficeActif?.name !== ''
                        ? controlOfficeActif?.name
                        : ''
                    }
                    dataLabelValue={controlOfficeList.map((controlOffice) => ({
                      label: controlOffice.name,
                      value: controlOffice.id?.toString() || '',
                    }))}
                    onSelectLabelValue={(selected) => {
                      const selectedPartner = controlOfficeList.find(
                        (partner) => partner.id === Number(selected.value)
                      );
                      if (selectedPartner) {
                        setControlOfficeActif(selectedPartner);
                      }
                      setValue('entity_control_id', Number(selected.value));
                    }}
                    placeholder={t('global.choose')}
                    addClass="w-full"
                    required
                  />
                  <ButtonOpx
                    onClick={() => {
                      setAddControlOfficeModal(true);
                      setIsNew(true);
                    }}
                    label={t('cofrac.inspection_office.new')}
                    type="primary"
                  />
                </div>
              </div>
            </div>
          </Modal>
        </FormProvider>
      )}
    </div>
  );
}

export { CofracDate };
