import { Modal } from '@components/Modal';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ICofracType,
  IStoreCofracOperations,
} from '@models/cofrac/utils/cofracTypes';
import { useTranslation } from 'react-i18next';
import {
  getCofracInfos,
  getCofracLinkedOperations,
  storeCofracDate,
} from '@models/cofrac/apiRequests/cofracRequests';
import { IWorksiteOperation } from '@models/worksites/utils/worksitesTypes';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { InputText } from '@components/atomic/inputs/InputText';
import { convertKiloToMega } from '@utils/functions';
import { GlobalContext } from '@context/globalContext';
import { SearchBar } from '@components/SearchBar';
import { formatWord } from '@utils/format';
import { DATE_MODAL } from '@models/cofrac/utils/cofracConstants';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { useFormContext } from 'react-hook-form';
import { sendLinkedFile, updateLinkedFile } from '@apiRequests/globalRequests';
import { fileTypeEnum } from '@utils/enums';
import { ILinkedFile } from '@globalTypes/globalTypes';

interface AddCofracOperationsProps {
  data: ICofracType | undefined;
  setData: Dispatch<SetStateAction<ICofracType | undefined>>;
  modal: string;
  setModal: Dispatch<SetStateAction<string>>;
}

function AddCofracOperations({
  data,
  setData,
  modal,
  setModal,
}: AddCofracOperationsProps): JSX.Element {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);

  const { watch, setValue } = useFormContext();
  const formValues = watch();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState({
    linked: false,
    toLink: false,
  });
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>();
  const [operationList, setOperationList] = useState<
    { id: number; worksite_operation: IWorksiteOperation }[]
  >([]);

  const ops: IStoreCofracOperations[] = useMemo(
    () => (Array.isArray(watch('operations')) ? watch('operations') : []),
    [watch()]
  );

  const onUpload = async (file: File, fileType: number) => {
    let existingFile: ILinkedFile | null = null;
    let storedFile: ILinkedFile | null = null;

    if (data && data.linked_files) {
      existingFile =
        fileType === fileTypeEnum.COFRAC_SUMMARY_REPORT_FILE
          ? data.linked_files.summary
          : data.linked_files.report;
    }

    if (existingFile) {
      const response = await updateLinkedFile(Number(existingFile.id), file);
      if (response.data) {
        storedFile = response.data;
      }
    } else {
      const relationType =
        fileType === fileTypeEnum.COFRAC_SUMMARY_REPORT_FILE
          ? 'cofrac_summary_report_file'
          : 'cofrac_report_file';

      const response = await sendLinkedFile(
        file,
        [Number(data?.id)],
        relationType,
        fileType,
        2,
        null,
        null,
        true
      );

      if (response.data) {
        storedFile = response.data;
      }
    }
    return storedFile;
  };

  const onSubmit = async () => {
    setIsSubmitted(true);
    let hadError = false;
    ops.forEach((op) => {
      if (op.status === 2 && (!op.description || op.description === ''))
        hadError = true;
      if (!op.status && op.check) hadError = true;
    });
    if (data && !hadError) {
      const checkedOperations = ops.filter((op) => !!op.check);

      if (formValues.reportFile) {
        await onUpload(formValues.reportFile, fileTypeEnum.COFRAC_REPORT_FILE);
      }
      if (formValues.summaryFile) {
        await onUpload(
          formValues.summaryFile,
          fileTypeEnum.COFRAC_SUMMARY_REPORT_FILE
        );
      }

      storeCofracDate(
        data.id,
        data?.cofrac_date,
        `cofrac_response_${modal}`,
        formValues.reportDate,
        formValues.synthesisDate,
        checkedOperations
      ).then((res) => {
        if (res.data) {
          setModal('');
          getCofracInfos(data.id, setData);
        }
      });
    }
    setIsSubmitted(false);
  };

  const getData = async () => {
    if (data) {
      setLoading((prev) => ({ ...prev, linked: true }));

      const resList = await getCofracLinkedOperations(
        data.id,
        '',
        setLoading,
        sortAndFilterData
      );

      if (resList.data) setOperationList(resList.data);
    }
  };

  useEffect(() => {
    if (data) {
      setOperationList(data.cofrac_lots_operations);
      const values = data.cofrac_lots_operations.map((op) => ({
        id: op.id,
        status: null,
        description: null,
        check: false,
      }));
      setValue('operations', values);
    }
  }, [data]);

  useEffect(() => {
    if (sortAndFilterData) getData();
  }, [sortAndFilterData]);

  const getStatusOpCount = (status: number) => {
    const count = ops.filter((op) => op.status === status && op.check).length;
    let name = globalEnum.cofrac_lots_operations_status[status]
      .toLowerCase()
      .replace(' ', '_');
    const ext = count > 1 ? 'plurial' : 'singular';
    name = t(`cofrac.operation_status.${formatWord(name)}_${ext}`);
    return `${count} ${name}`;
  };

  const operationRender = (
    operation: IWorksiteOperation,
    id: number,
    index: number
  ) => {
    const selectedOperation = ops.find((op) => op.id === id);

    const beneficiary = operation.worksite?.beneficiary;
    const beneficiaryName = `${beneficiary?.firstname || ''} ${
      beneficiary?.lastname || ''
    }`;

    const installer = operation.worksite?.installer.company_name || '';

    if (loading.linked)
      return (
        <div className="my-3 border border-borderGrey rounded-default p-3 flex justify-between">
          <div className="flex flex-col gap-5 w-1/3">
            <LoaderSkeleton height="1rem" addClass="w-10/12" />
            <LoaderSkeleton height="1rem" addClass="w-full" />
          </div>
          <LoaderSkeleton height="2.5rem" addClass="w-1/4" />
        </div>
      );

    return (
      <div className="my-3 border border-borderGrey rounded-default p-3">
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Checkbox
                label={`check_${id}`}
                checked={!!selectedOperation?.check}
                onCheck={() => {
                  const newOpsCheck = ops.map((op) => {
                    if (op.id === id) return { ...op, check: !op.check };
                    return op;
                  });
                  setValue('operations', newOpsCheck);
                }}
              />
              <div className="font-semibold">{`${beneficiaryName} - ${installer}`}</div>
            </div>

            <div>
              <div className="text-textGrey">
                <span>
                  {`${t('cofrac.amount_prime')} : ${
                    operation.cdp_amount > 0
                      ? operation.cdp_amount
                      : operation.cee_amount
                  }`}{' '}
                  €
                </span>
                &nbsp; &nbsp; &nbsp;
                <span>
                  {`${t('cofrac.volume')} : ${convertKiloToMega(
                    Number(operation.kwhc_precaire || 0) +
                      Number(operation.kwhc_classique || 0)
                  )} Mwhc`}
                </span>
              </div>
            </div>
          </div>
          <div>
            <InputSelect
              addClass="w-[15rem]"
              placeholder={t('global.choose')}
              dataArrayString={Object.values(
                globalEnum.cofrac_lots_operations_status
              ).filter((_, i) => i < 3)}
              onSelect={(e) => {
                if (selectedOperation) {
                  const newOpsStatus = ops.map((op) => {
                    const enums = Object.entries(
                      globalEnum.cofrac_lots_operations_status
                    );
                    const selected = enums.find((s) => s[1] === e);
                    const status = selected ? Number(selected[0]) : 0;

                    if (op.id === id) return { ...op, status };
                    return op;
                  });
                  setValue('operations', newOpsStatus);
                }
              }}
              error={
                isSubmitted &&
                selectedOperation?.check &&
                !selectedOperation?.status
              }
            />
          </div>
        </div>
        {selectedOperation &&
          selectedOperation.check &&
          selectedOperation.status === 2 && (
            <InputText
              addClass="mt-3"
              placeholder=""
              id={`operations.${index}.description`}
              name={`operations.${index}.description`}
              required
              error={isSubmitted && !selectedOperation.description}
              textError={String(t('forms.required_error_message'))}
            />
          )}
      </div>
    );
  };

  const onSearch = (value: string) => {
    const searchItems: {
      id: number;
      worksite_operation: IWorksiteOperation;
    }[] = [];

    data?.cofrac_lots_operations.forEach((op) => {
      const beneficiary = op.worksite_operation.worksite?.beneficiary;
      const installer = op.worksite_operation.worksite?.installer;

      if (
        formatWord(beneficiary?.firstname).includes(value) ||
        formatWord(beneficiary?.lastname).includes(value) ||
        formatWord(installer?.company_name).includes(value)
      )
        searchItems.push(op);
    });

    setOperationList(searchItems);
  };

  return (
    <Modal
      title={modal.includes(DATE_MODAL.OK) ? t('cofrac.ok') : t('cofrac.ko')}
      backgroundTransparent
      sidebarVisible
      buttonsPosition="bottom"
      textBtnConfirm={`${t('buttons.next')}`}
      onConfirmClick={() => onSubmit()}
      btnCancel
      onClickCancel={() => setModal('')}
      btnConfirmDisabled={ops.filter((op) => op.check).length < 1}
      isLoading={isSubmitted}
    >
      <>
        <div className="flex justify-between gap-5 items-center my-[1.5rem]">
          <div className="rounded-default w-full mb-[1.5rem] md:mb-0">
            <SearchBar
              addClass="py-3"
              placeholder={`${t('transfer.search_placeholder')}`}
              onSearch={(value: string) => onSearch(formatWord(value))}
              searchOnEveryChange
              width="100%"
            />
          </div>
          <div className="flex md:justify-end">
            <SortAndFilter
              onSort={(column, direction) =>
                sortList(column, direction, setSortAndFilterData)
              }
              onFilter={(filters) => filterList(filters, setSortAndFilterData)}
              page="COFRAC_OPERATIONS"
            />
          </div>
        </div>
        <div className="w-full">
          {data &&
            operationList.map((op, i) =>
              operationRender(op.worksite_operation, op.id, i)
            )}
          <p className="font-semibold">
            <span>{getStatusOpCount(1)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <span>{getStatusOpCount(2)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <span>{getStatusOpCount(3)}</span>
          </p>
        </div>
      </>
    </Modal>
  );
}

export { AddCofracOperations };
