import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderContext } from '@context/headerContext';
import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { TabAccountInfos } from '@models/users/components/TabAccountInfos';
import { TabAccountUsers } from '@models/users/components/TabAccountUsers';
import { ModalAddUser } from '@models/users/components/ModalAddUser';

import {
  MY_ACCOUNT_TABS,
  DEFAULT_ACCOUNT_INFOS,
} from '@models/users/utils/userConstants';

import {
  getUserInfo,
  getUsersAccessList,
} from '@models/users/apiRequests/userRequests';
import { IAccountInfos, IUserAccessItem } from '@models/users/utils/userTypes';
import { AddIcon } from '@assets/images/svgComponents';
import { formatEnumIntoArray } from '@utils/format';
import { IKeyValue, IMyAccountSubmenu } from 'types/globalTypes';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { CardPassword } from '@models/users/components/CardPassword';
import { ROLES } from '@utils/roles';
import { TabCertifications } from '@models/certifications/components/TabCertifications';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { CardNotifications } from '@models/users/components/CardNotifications';

function MyAccount() {
  const { t } = useTranslation();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const {
    globalEnum,
    userView,
    roleUser,
    displayModalUpgradeSubscription,
    updateDisplayModalUpgradeSubscription,
  } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const [tabContent, setTabContent] = useState<string>('informations');
  const [loading, setLoading] = useState<boolean>(!!user);
  const [infos, setInfos] = useState<IAccountInfos>(DEFAULT_ACCOUNT_INFOS);
  const [canAddUser, setCanAddUser] = useState<boolean>(false);
  const [showAddUser, setShowAddUser] = useState<boolean>(false);

  const getInfos = async () => {
    setLoading(true);
    const resInfos = await getUserInfo(user?.id || 0);
    if (resInfos) {
      setInfos(resInfos);

      const resAccess = await getUsersAccessList(
        undefined,
        userView?.entity_id || 0,
        resInfos.informations_utilisateur.id
      );

      if (resAccess) {
        const arrAccessTypes = formatEnumIntoArray(globalEnum.access_type);
        const accessManageUsers = arrAccessTypes.find(
          (access: IKeyValue) =>
            (access.value.toLowerCase().includes('gestion') &&
              access.value.toLowerCase().includes('utilisateurs')) ||
            (access.value.toLowerCase().includes('manage') &&
              access.value.toLowerCase().includes('user'))
        )?.key;
        const canManageUser = resAccess.access_list.find(
          (accessItem: IUserAccessItem) =>
            accessItem.access === Number(accessManageUsers) &&
            accessItem.toggle === true
        );
        setCanAddUser(
          Boolean(canManageUser) &&
            resInfos.informations_utilisateur.profile_type === 2
        );
      }
    }

    setLoading(false);
  };

  const tabs = useMemo(() => {
    let tabsToDisplay = MY_ACCOUNT_TABS.filter(
      (tab: IMyAccountSubmenu) => tab.content !== 'history'
    );
    // hide Certifications tabs if role user is ROLES.PRODUCTIONS
    if (roleUser !== ROLES.PRODUCTION) {
      tabsToDisplay = tabsToDisplay.filter(
        (tab: IMyAccountSubmenu) => tab.content !== 'certifications'
      );
    }

    // hide Users tabs if profile is 'utilisateur'
    if (infos.informations_utilisateur.profile_type === 1) {
      return tabsToDisplay.filter(
        (tab: IMyAccountSubmenu) => tab.content !== 'users'
      );
    }

    return tabsToDisplay;
  }, [infos]);

  const renderTab = useMemo(() => {
    switch (tabContent) {
      case 'password':
        return <CardPassword />;
      case 'users':
        return <TabAccountUsers infos={infos} loadingInfos={loading} />;
      case 'certifications':
        return <TabCertifications />;
      case 'notifications':
        return <CardNotifications />;
      case 'informations':
      default:
        return (
          <TabAccountInfos
            data={infos}
            setData={setInfos}
            loading={loading}
            refetch={getInfos}
          />
        );
    }
  }, [tabContent, infos, loading]);

  useEffect(() => {
    updateTitleHeader(String(t('my_account.title')));
    refreshHeader();
  }, []);

  useEffect(() => {
    if (user && infos.informations_utilisateur.id === 0) {
      getInfos();
    }
  }, [userView, globalEnum, user]);

  useEffect(() => {
    if (user?.is_freemium && tabContent === 'users') {
      if (!displayModalUpgradeSubscription) {
        setTabContent('informations');
      } else {
        updateDisplayModalUpgradeSubscription(true);
      }
    }
  }, [user, displayModalUpgradeSubscription]);

  return (
    <>
      <SubHeader
        leftPart={
          !loading ? (
            <TabsLeftPart
              titlesList={tabs.map((tab) =>
                t(`my_account.tabs.${tab.submenu}`)
              )}
              onClick={(title) => {
                const selectedTab = tabs.find(
                  (tab) => t(`my_account.tabs.${tab.submenu}`) === title
                );
                setTabContent(selectedTab?.content || 'informations');
              }}
              activeButton={tabContent}
            />
          ) : (
            <LoaderDivSkeleton height="2.5rem" width="15rem" />
          )
        }
        rightPart={
          canAddUser && tabContent === 'users' ? (
            <>
              <ButtonOpx
                onClick={() => setShowAddUser(!showAddUser)}
                label={t('my_account.add_user')}
                type="primary"
                small
                addClass="!border-transparent"
                icon={<AddIcon />}
                disabled={user?.is_freemium}
                dataTestId="add_users_button"
              />
              {showAddUser && (
                <ModalAddUser
                  setShowModal={setShowAddUser}
                  refetch={getInfos}
                />
              )}
            </>
          ) : undefined
        }
      />
      <div className="tabs_content">{renderTab}</div>
    </>
  );
}

export { MyAccount };
