import { Dispatch, SetStateAction } from 'react';
import { IGlobalEnumType, ILinkedFile } from '@globalTypes/globalTypes';
import { CHECKPOINT_RESPONSE_TYPE } from '@models/settings/utils/enums';
import { isBefore, isAfter, isEqual } from 'date-fns';
import { toAPIDate, toAPIDateStr } from '@utils/format';
import {
  ICheckpoints,
  ILinkedFileCheckpoints,
  IWorksiteCheckpoints,
} from './checkpointsTypes';

export const getResponseValue = (
  checkpoint: ILinkedFileCheckpoints,
  value: string | null
) => {
  const responseValue = checkpoint.checkpoint?.response_values?.find(
    (res) => res.value === value
  );

  if (responseValue) {
    return {
      value: responseValue.value,
      valid: responseValue.valid,
    };
  }

  return {
    value: checkpoint.value,
    valid: checkpoint.valid,
  };
};

export const isWrongResponseValue = (
  linkedFileCheckpoint: ILinkedFileCheckpoints,
  value: string | null
) => {
  const { checkpoint } = linkedFileCheckpoint;
  const scaleTypes = [
    CHECKPOINT_RESPONSE_TYPE.NUMBER,
    CHECKPOINT_RESPONSE_TYPE.DATE,
  ];

  if (scaleTypes.includes(checkpoint.response_type)) {
    if (!value || value === '') {
      return false;
    }
    return checkpoint.response_values.some((v) => {
      if (v.valid === true) {
        return false;
      }

      const [operator] = v.value.match(/[><]=?/g) || ['='];

      // Si c'est une date
      if (checkpoint.response_type === CHECKPOINT_RESPONSE_TYPE.DATE) {
        const dateValue = v.value.replace(/[><]=?|=/g, '');
        // Formater les deux dates
        const date1 = toAPIDate(toAPIDateStr(value));
        const date2 = toAPIDate(dateValue);

        const dateConditions = {
          '<': isBefore(date1, date2),
          '>': isAfter(date1, date2),
          '<=': isBefore(date1, date2) || isEqual(date1, date2),
          '>=': isAfter(date1, date2) || isEqual(date1, date2),
          '=': isEqual(date1, date2),
        };

        return !dateConditions[operator as keyof typeof dateConditions];
      }
      const [number] = v.value.match(/\d+/g) || ['0'];

      // Si c'est un nombre
      const val = Number(value);
      const compareNumber = Number(number);
      const numberConditions = {
        '<': val < compareNumber,
        '>': val > compareNumber,
        '<=': val <= compareNumber,
        '>=': val >= compareNumber,
        '=': val === compareNumber,
      };

      return !numberConditions[operator as keyof typeof numberConditions];
    });
  }

  const responseValue = getResponseValue(linkedFileCheckpoint, value);
  if (responseValue.valid === false) {
    return true;
  }

  return false;
};

export const updateCheckpoint = (
  document: ILinkedFile,
  checkpoint: ILinkedFileCheckpoints,
  key: keyof ILinkedFileCheckpoints,
  value: string | null,
  updateWorksiteCheckpoints: Dispatch<SetStateAction<IWorksiteCheckpoints[]>>
) => {
  if (checkpoint[key] === value) {
    return;
  }

  const valid =
    key === 'custom_conformity_description'
      ? false
      : !isWrongResponseValue(checkpoint, value);

  updateWorksiteCheckpoints((prev) => {
    return prev.map((item) => {
      if (item.document_id !== document.id) {
        return item;
      }

      const checkpointsValues = item.checkpoints.map((cp) => {
        if (cp.id !== checkpoint.id) {
          return cp;
        }

        let conformityDescription = valid
          ? null
          : checkpoint.checkpoint.conformity_description;

        if (key === 'custom_conformity_description') {
          conformityDescription = value;
        }

        return {
          ...cp,
          [key]: value,
          valid,
          custom_conformity_description: conformityDescription,
        };
      });

      return { ...item, checkpoints: checkpointsValues };
    });
  });
};

export const getErrorMessage = (checkpoint: ILinkedFileCheckpoints) => {
  if (checkpoint.custom_conformity_description !== null) {
    return checkpoint.custom_conformity_description;
  }

  return checkpoint.checkpoint.conformity_description;
};

// Préparer les valeurs par défaut en fonction du mode édition
export const getDefaultValues = (
  checkpoint: ICheckpoints | undefined,
  globalEnum: IGlobalEnumType
) => {
  if (!checkpoint) {
    return {
      question: '',
      response_type: '',
      linked_file_type: [],
      beneficiary_type: undefined,
      operations: [],
      response_values: [],
      comparator: '',
    };
  }

  const { linked_file_type } = globalEnum;

  // En mode édition, pré-remplir avec les données du checkpoint existant
  const linkedFileTypes = checkpoint.file_types
    ? checkpoint.file_types.map((type) => linked_file_type[Number(type)])
    : [];

  const operations = checkpoint.operations
    ? checkpoint.operations.map((op) => op.code)
    : [];

  // Conversion des valeurs de réponse
  let responseValues = checkpoint.response_values || [];

  const scaleResponseTypes = [
    CHECKPOINT_RESPONSE_TYPE.NUMBER,
    CHECKPOINT_RESPONSE_TYPE.DATE,
  ];

  if (scaleResponseTypes.includes(checkpoint.response_type)) {
    // Nettoyer les valeurs des opérateurs de comparaison
    responseValues = responseValues.map((val) => {
      let cleanValue = val.value;

      if (checkpoint.response_type === CHECKPOINT_RESPONSE_TYPE.NUMBER) {
        // Extraire uniquement la partie numérique en supprimant les opérateurs
        cleanValue = val.value.replace(/[^0-9.-]/g, '');
      } else {
        const dateMatch = val.value.match(/(\d{4}-\d{2}-\d{2})/);
        if (dateMatch) {
          cleanValue = dateMatch[0];
        }
      }

      return {
        ...val,
        value: cleanValue,
      };
    });
  }

  return {
    question: checkpoint.question || '',
    response_type: checkpoint.response_type?.toString() || '',
    linked_file_type: linkedFileTypes,
    beneficiary_type: checkpoint.beneficiary_type || 0,
    operations,
    response_values: responseValues,
    conformity_description: checkpoint.conformity_description || '',
    comparator: '',
  };
};
