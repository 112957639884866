import { ILinkedFile } from '@globalTypes/globalTypes';
import { createContext, Dispatch, SetStateAction } from 'react';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import {
  IControlCounts,
  IILinkedFileMessages,
  ILinkedFileCheckpoints,
  IWorksiteCheckpoints,
} from './checkpointsTypes';

interface LinkedFileCheckpoint {
  controlCounts: IControlCounts | null;
  checkpoints: ILinkedFileCheckpoints[];
  isLoading: boolean;
  updateIsLoading: Dispatch<SetStateAction<boolean>>;
  document: ILinkedFile;
  updateDocument: Dispatch<SetStateAction<ILinkedFile>>;
  worksite: IWorksiteDetails;
  worksiteCheckpoints: IWorksiteCheckpoints[];
  updateWorksiteCheckpoints: Dispatch<SetStateAction<IWorksiteCheckpoints[]>>;
  savedMessages: IILinkedFileMessages[];
  updateSavedMessages: Dispatch<SetStateAction<IILinkedFileMessages[]>>;
  linkedFiles: ILinkedFile[];
  updateLinkedFiles: Dispatch<SetStateAction<ILinkedFile[]>>;
  complianceMessage: string;
  updateComplianceMessage: Dispatch<SetStateAction<string>>;
  groupedDocuments: { files: ILinkedFile[]; fileType: number }[];
  updateGroupedDocuments: Dispatch<
    SetStateAction<{ files: ILinkedFile[]; fileType: number }[]>
  >;
  mailIsOpen: boolean;
  updateMailIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const LinkedFileCheckpointContext = createContext(
  {} as LinkedFileCheckpoint
);
