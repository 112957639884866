import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { ILabelValue } from '@globalTypes/globalTypes';
import {
  BeneficiaryTypes,
  ContractTypes,
} from '@models/contractCreation/utils/enums';
import { PRIME_TYPES } from '@models/settings/utils/enums';
import { IAgreementList } from '@models/settings/utils/settingsTypes';
import { linkToContract } from '@models/settings/utils/utils';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface OperationsPriceSelectorProps {
  contractOptions: ILabelValue[];
  isLoadingContracts: boolean;
  contractId: string | null;
  beneficiaryType: string | null;
  primeType: string | null;
  agreements: IAgreementList[];
  resetSignalContractModel: number;
}

/**
 * Composant pour sélectionner les paramètres de prix d'opération:
 * - Type de bénéficiaire
 * - Type de prime
 * - Contrat modèle (facultatif)
 *
 * Ce composant utilise le formulaire principal via useFormContext
 */
export function OperationsPriceSelector({
  contractOptions,
  isLoadingContracts,
  contractId,
  beneficiaryType,
  primeType,
  agreements,
  resetSignalContractModel,
}: OperationsPriceSelectorProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  // Options pour le type de bénéficiaire (statiques)
  const beneficiaryTypeOptions: ILabelValue[] = [
    {
      label: t('settings.iframes.beneficiary_type_physical'),
      value: String(BeneficiaryTypes.PERSON),
    },
    {
      label: t('settings.iframes.beneficiary_type_moral'),
      value: String(BeneficiaryTypes.ENTITY),
    },
    {
      label: t('settings.iframes.both'),
      value: String(BeneficiaryTypes.MIXED),
    },
  ];

  // Options pour le type de prime (statiques)
  const primeTypeOptions: ILabelValue[] = [
    { label: 'CEE', value: String(PRIME_TYPES.CEE) },
    { label: 'MPR', value: String(PRIME_TYPES.MPR) },
    { label: t('settings.iframes.both'), value: String(PRIME_TYPES.BOTH) },
  ];

  const contractSelectedType = useMemo(
    () =>
      agreements.find((contract) => contract.id === Number(contractId))
        ?.contract_type,
    [agreements, contractId]
  );

  // Fonction de mise à jour du type de bénéficiaire
  const handleBeneficiaryChange = useCallback(
    (value: ILabelValue) => {
      setValue('beneficiary_type', value.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue]
  );

  // Fonction de mise à jour du type de prime
  const handlePrimeChange = useCallback(
    (value: ILabelValue) => {
      setValue('prime_type', value.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue]
  );

  // Fonction de mise à jour du contrat sélectionné
  const handleContractChange = useCallback(
    (value: ILabelValue) => {
      setValue('contract_model_id', value.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue]
  );

  // Fonction pour ouvrir le détail du contrat dans un nouvel onglet
  const openContractDetails = useCallback(() => {
    if (contractId) {
      const url = linkToContract(
        contractSelectedType as ContractTypes,
        contractId
      );
      window.open(url, '_blank');
    }
  }, [contractId, contractSelectedType]);

  const beneficiaryTypeMatchContractBeneficiaryType = (
    contractIdToCheck: string
  ) => {
    const contract = agreements.find(
      (agreement) => agreement.id === Number(contractIdToCheck)
    );
    if (
      Number(beneficiaryType) === BeneficiaryTypes.MIXED ||
      Number(contract?.beneficiaries_type) === BeneficiaryTypes.MIXED
    ) {
      return true;
    }
    if (Number(beneficiaryType) === Number(contract?.beneficiaries_type)) {
      return true;
    }
    return false;
  };

  return (
    <>
      {/* Sélecteur de type de bénéficiaire et de prime */}
      <div className="flex flex-col w-full space-y-4">
        {/* Sélecteur de type de bénéficiaire */}
        <div className="w-full">
          <InputSelect
            label={t('settings.iframes.beneficiary_type') || ''}
            dataLabelValue={beneficiaryTypeOptions}
            placeholder={t('settings.iframes.select_beneficiary_type') || ''}
            onSelectLabelValue={handleBeneficiaryChange}
            required
            defaultSelected={
              beneficiaryTypeOptions.find(
                (beneficiary) => beneficiary.value === beneficiaryType
              )?.label
            }
          />
        </div>

        {/* Sélecteur de type de prime */}
        <div className="w-full">
          <InputSelect
            label={t('settings.iframes.prime_type') || ''}
            dataLabelValue={primeTypeOptions}
            placeholder={t('settings.iframes.select_prime_type') || ''}
            onSelectLabelValue={handlePrimeChange}
            required
            defaultSelected={
              primeTypeOptions.find((prime) => prime.value === primeType)?.label
            }
          />
        </div>
      </div>

      {/* Sélecteur de contrat (optionnel) */}
      {beneficiaryType && (
        <div className="flex flex-col space-y-2">
          <div className="flex items-center gap-4 w-full">
            <div className="flex-grow">
              <InputSelect
                label={t('settings.iframes.contract_selector') || ''}
                dataLabelValue={contractOptions.filter((contractOption) =>
                  beneficiaryTypeMatchContractBeneficiaryType(
                    contractOption.value
                  )
                )}
                placeholder={t('settings.iframes.select_contract') || ''}
                onSelectLabelValue={handleContractChange}
                isLoading={isLoadingContracts}
                defaultSelected={
                  contractOptions.find(
                    (contract) => contract.value === contractId
                  )?.label
                }
                resetSignal={resetSignalContractModel}
                onClear={() => {
                  setValue('contract_model_id', null);
                }}
                showClearButton={contractId !== null}
              />
            </div>
            <div className="flex-shrink-0 self-end pb-1">
              <ButtonOpx
                label={t('settings.iframes.see_contract') || ''}
                type="tab"
                onClick={openContractDetails}
                disabled={!contractId}
              />
            </div>
          </div>

          {/* Texte d'aide expliquant le but de la sélection d'un contrat */}
          <div className="text-[.75rem] text-textGrey italic">
            {t('settings.iframes.contract_selection_help') ||
              "La sélection d'un contrat est facultative et sert uniquement de modèle pour le paramétrage."}
          </div>
        </div>
      )}
    </>
  );
}
