import { InputText } from '@components/atomic/inputs/InputText';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { postNewMessage } from '@models/requests/apiRequest/requestRequests';
import { useContext, useState } from 'react';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { closeRequest } from '@apiRequests/changesRequest';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthContext } from '@context/authContext';
import ImageContainer from '@components/atomic/ImageContainer';

function NewMessageDetailsRequestForm() {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      new_message: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        new_message: yup.string().required(t('forms.write_a_message') || ''),
      })
    ),
  });
  const { handleSubmit, reset, watch } = methods;

  const {
    updateMessagesRequest,
    detailRequest,
    refreshRequestsAndChanges,
    updateDetailRequest,
  } = useContext(RequestAndActivityIntoModalOrCardContext);

  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    postNewMessage(
      updateMessagesRequest,
      setIsLoading,
      detailRequest?.id || 0,
      data.new_message,
      reset
    );
  };

  const onClosing = () => {
    closeRequest(
      detailRequest?.id || 0,
      refreshRequestsAndChanges,
      updateDetailRequest
    );
  };

  return (
    <div className="p-[1.5rem] w-full">
      <div className="flex items-end space-x-[.5rem] w-full">
        <ImageContainer
          imageUrl={user?.photo_url}
          width="2.875rem"
          height="2.875rem"
          addClass="min-w-[2.875rem] min-h-[2.875rem]"
        />
        <FormProvider {...methods}>
          <form
            className="w-full"
            id="send_message"
            onSubmit={handleSubmit(onSubmit)}
          >
            <InputText
              id="new_message"
              name="new_message"
              placeholder={t('requests_and_changes.write_new_message')}
              value={watch('new_message')}
              dataTestId="input_text"
            />
          </form>
        </FormProvider>
      </div>
      <div className="flex space-x-[.5rem] items-center float-right pb-[1.5rem] pt-[.5rem]">
        <ButtonOpx
          type="secondary"
          label="Cloturer"
          onClick={() => onClosing()}
          small
        />
        <ButtonOpx
          small
          type="primary"
          label={t('buttons.send')}
          isSubmitBtn
          formId="send_message"
          isLoading={isLoading}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

export { NewMessageDetailsRequestForm };
