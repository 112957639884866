/**
 * Utilitaire pour gérer les URLs des API en mode iframe
 * @param standardUrl URL standard de l'API
 * @param isIframeMode Indique si le mode iframe est activé
 * @returns L'URL appropriée selon le mode
 */
export const getIframeApiUrl = (
  standardUrl: string,
  isIframeMode: boolean
): string => {
  if (!isIframeMode) return standardUrl;

  // Transformation de l'URL pour le mode iframe
  return standardUrl.replace('api/v1', 'api/v1/public/iframe');
};

/**
 * Mapping des URLs standards vers les URLs iframe
 */
export const URL_MAPPINGS = {
  WORKSITES_WORKFLOW: {
    standard: '/worksites/workflow',
    iframe: '/public/iframe/worksites/workflow',
  },
  CONVENTIONS_OPERATIONS: {
    standard: '/conventions',
    iframe: '/public/iframe/conventions',
  },
  IFRAME_OPERATIONS: {
    standard: '/conventions',
    iframe: '/public/iframe',
  },
  AIDES_FIELDS: {
    standard: '/aides/fields',
    iframe: '/public/iframe/aides/fields',
  },
  AIDES_INCOMES: {
    standard: '/aides/incomes',
    iframe: '/public/iframe/aides/incomes',
  },
  AIDES_AMOUNTS: {
    standard: '/aides/amounts',
    iframe: '/public/iframe/aides/amounts',
  },
};
