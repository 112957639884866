import { green, orange, red, textGrey } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { GlobalContext } from '@context/globalContext';
import { DOCUMENT_STATUS_ENUM } from '@utils/utils';
import { useContext, useEffect, useState } from 'react';

interface IDocumentStatusTagProps {
  status: number;
}

interface IDocumentStatusSettings {
  label: string;
  color: string;
}

function DocumentStatusTag({
  status,
}: IDocumentStatusTagProps): React.JSX.Element {
  const { globalEnum } = useContext(GlobalContext);

  const [documentStatusSettings, setDocumentStatusSettings] =
    useState<IDocumentStatusSettings>({ label: '', color: '' });

  const label = globalEnum.linked_file_status[status];
  let color = textGrey;

  function statusManager() {
    switch (status) {
      case DOCUMENT_STATUS_ENUM.TO_IMPORT:
        color = red;
        break;
      case DOCUMENT_STATUS_ENUM.PENDING:
        color = orange;
        break;
      case DOCUMENT_STATUS_ENUM.REFUSE:
        color = red;
        break;
      case DOCUMENT_STATUS_ENUM.VALIDATE:
        color = green;
        break;
      case DOCUMENT_STATUS_ENUM.TO_SIGN:
        color = textGrey;
        break;
      case DOCUMENT_STATUS_ENUM.AWAITING_SIGNATURE:
        color = textGrey;
        break;
      case DOCUMENT_STATUS_ENUM.SIGNED:
        color = green;
        break;
      default:
        break;
    }
    setDocumentStatusSettings({ label, color });
  }

  useEffect(() => {
    statusManager();
  }, [status]);

  return (
    <Tag
      color={documentStatusSettings.color}
      label={documentStatusSettings.label}
    />
  );
}

export default DocumentStatusTag;
