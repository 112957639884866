/* eslint-disable no-param-reassign */
import { fetchEnergeticClass } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import {
  FieldValues,
  UseFieldArrayAppend,
  UseFormSetValue,
} from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { ILabelValue } from '@globalTypes/globalTypes';
import { IProDevis, IWorksiteMar } from '@models/mar/utils/marTypes';
import { createFileWithLinkedFile } from '@utils/functions';
import {
  AUDITOR_TYPES,
  STEPS_WORKSITE_MAR_COMPLETION,
} from '@models/mar/utils/enums';
import { IUserType } from '@models/auth/utils/types';
import { IPartnerView } from '@models/partners/utils/types/partnersType';
import { format } from 'date-fns';

export const getEnergyClass = async (
  datas: {
    cep: string;
    eges: string;
    indexScenario?: number;
  },
  setValue: UseFormSetValue<FieldValues>
) => {
  const { cep, eges, indexScenario } = datas;
  const nameField = `${
    indexScenario !== undefined ? `scenario.${indexScenario}.` : ''
  }energy_class`;
  setValue(nameField, null);
  // Call to an API
  const dataToSend = {
    cep: Number(cep),
    eges: Number(eges),
  };
  const response = await fetchEnergeticClass(dataToSend);

  if (response) {
    setValue(nameField, response);
  }
};

export const appendFormData = (
  data: any,
  formData: FormData,
  parentKey?: string
) => {
  if (data && typeof data === 'object' && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      appendFormData(
        data[key],
        formData,
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else if (typeof data === 'boolean') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append(parentKey!, data ? '1' : '0');
  } else if (data !== undefined && data !== null && data !== '') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append(parentKey!, data);
  }
};

export const addDayToStringDate = (dateString: string): string => {
  if (!dateString) return '';

  try {
    // Parse la date initiale (format YYYY-MM-DD)
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day));

    // Ajoute un jour
    date.setUTCDate(date.getUTCDate() + 1);

    // Formate la date en dd/MM/yyyy
    return format(date, 'dd/MM/yyyy');
  } catch (e) {
    console.error('Error in addDayToStringDate:', e);
    return '';
  }
};

export const downloadWhenGenerated = (url: string, fileName: string) => {
  // Créer un lien invisible
  const link = document.createElement('a');
  link.href = url;

  // Ouvrir le lien dans un nouvel onglet
  link.target = '_blank';

  // Définir l'attribut de téléchargement si vous voulez que le fichier soit téléchargé avec un nom spécifique
  link.download = fileName; // Vous pouvez changer le nom ici

  // Ajouter le lien à la page, déclencher le clic, puis le retirer
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const filterOperations = (
  baseOperations: any[],
  setOperationsFiltered: Dispatch<SetStateAction<any[]>>,
  operationsSelected: any[],
  baseOtherOperations: any[],
  setOtherOperationsFiltered: Dispatch<SetStateAction<any[]>>,
  otherOperationsSelected: any[]
) => {
  const filteredScenarioSelectedOperations = baseOperations.filter(
    (operation) =>
      !operationsSelected.some((op) => op.value === operation.value)
  );
  setOperationsFiltered(filteredScenarioSelectedOperations);

  const filteredOtherOperations = baseOtherOperations.filter(
    (operation) =>
      !otherOperationsSelected.some((op) => op.value === operation.value)
  );
  setOtherOperationsFiltered(filteredOtherOperations);
};

export const calculateTotalHTAndTTC = (
  formGestures: any[],
  formOtherGestures: any[],
  totalHT: number,
  totalTTC: number,
  totalHTFieldName: string,
  totalTTCFieldName: string,
  setValue: UseFormSetValue<FieldValues>,
  setResetSignal: Dispatch<SetStateAction<number>>
) => {
  let totalHTCount = 0;
  let totalTTCCount = 0;
  const allGestures =
    formGestures || formOtherGestures
      ? [...formGestures, ...formOtherGestures]
      : [];
  if (allGestures.length > 0) {
    allGestures.forEach(
      (gesture: {
        cost_ht: string;
        cost_ttc: string;
        operation: ILabelValue;
      }) => {
        const costHT = gesture.cost_ht;
        const costTTC = gesture.cost_ttc;

        totalHTCount += costHT ? parseFloat(costHT) : 0;
        totalTTCCount += costTTC ? parseFloat(costTTC) : 0;
      }
    );
    if (totalHT !== totalHTCount) {
      setValue(totalHTFieldName, totalHTCount);
    }
    if (totalTTC !== totalTTCCount) {
      setValue(totalTTCFieldName, totalTTCCount);
    }
  } else if (totalHT || totalTTC) {
    setResetSignal((prev) => prev + 1);
  }
};

export const setFileFromWorksiteData = async (
  worksiteData: IWorksiteMar,
  fileType: number,
  fieldName: string,
  setValue: (field: string, value: any) => void,
  multiple?: boolean,
  append?: UseFieldArrayAppend<FieldValues, any>
) => {
  const linkedFiles = worksiteData.linked_files?.filter(
    (file: any) => file.file_type === fileType
  );

  if (linkedFiles && linkedFiles.length > 0) {
    if (multiple) {
      const filesToSet = await Promise.all(
        linkedFiles.map((linkedFile: any) =>
          createFileWithLinkedFile(
            linkedFile.file_url as string,
            linkedFile.file_name as string
          )
        )
      );

      filesToSet.forEach((file, index) => {
        if (append) {
          append({ file, linkedFileId: linkedFiles[index].id });
        } else {
          setValue(`${fieldName}[${index}]`, file);
        }
      });
    } else {
      const fileToSet = await createFileWithLinkedFile(
        linkedFiles[0].file_url as string,
        linkedFiles[0].file_name as string
      );
      if (append) {
        append({ file: fileToSet, linkedFileId: linkedFiles[0].id });
      } else {
        setValue(fieldName, fileToSet);
      }
    }
  } else if (append) {
    append({ file: undefined });
  }
};

export const calculationScenarioCostsTTC = (worksiteData: IWorksiteMar) =>
  worksiteData.scenarios?.reduce((acc, scenario) => {
    if (scenario.selected) {
      const scenarioCosts = scenario.mar_gestes?.reduce(
        (scenarioAcc, geste) => {
          scenarioAcc.cost_ttc += geste.cost_ttc;
          return scenarioAcc;
        },
        { cost_ttc: 0 }
      );
      acc += scenarioCosts?.cost_ttc || 0;
    }
    return acc;
  }, 0);

export const calculationDevisCostsTTC = (worksiteData: IWorksiteMar) =>
  worksiteData.pros?.reduce((acc, pro) => {
    const selectedDevis = pro.devis?.filter((devis) => devis.selected);
    selectedDevis?.forEach((devis) => {
      const devisCosts = devis.mar_gestes?.reduce(
        (devisAcc, geste) => {
          devisAcc.cost_ttc += geste.cost_ttc;
          return devisAcc;
        },
        { cost_ttc: 0 }
      );
      acc += devisCosts?.cost_ttc || 0;
    });
    return acc;
  }, 0);

export const calculationInvoicesCostsTTC = (worksiteData: IWorksiteMar) =>
  worksiteData.factures?.reduce((acc, invoice) => {
    const invoiceCosts = invoice.mar_gestes?.reduce(
      (invoiceAcc, geste) => {
        invoiceAcc.cost_ttc += geste.cost_ttc;
        return invoiceAcc;
      },
      { cost_ttc: 0 }
    );

    acc += invoiceCosts?.cost_ttc || 0;
    return acc;
  }, 0);

export const calculateTotalCostTTC = (worksiteData: IWorksiteMar) => {
  const step = worksiteData.step;

  if (step <= STEPS_WORKSITE_MAR_COMPLETION.QUOTES) {
    // Jusqu'à step 8, utiliser les coûts des scénarios
    return calculationScenarioCostsTTC(worksiteData);
  }
  if (step <= STEPS_WORKSITE_MAR_COMPLETION.END_OF_WORK) {
    // Jusqu'à step 16, utiliser les coûts des devis
    return calculationDevisCostsTTC(worksiteData);
  }
  // Après step 16, utiliser les coûts des factures
  return calculationInvoicesCostsTTC(worksiteData);
};

export const calculationInvoicesCostsHT = (worksiteData: IWorksiteMar) =>
  worksiteData.factures?.reduce((acc, invoice) => {
    const invoiceCosts = invoice.mar_gestes?.reduce(
      (invoiceAcc, geste) => {
        invoiceAcc.cost_ht += geste.cost_ht;
        return invoiceAcc;
      },
      { cost_ht: 0 }
    );

    acc += invoiceCosts?.cost_ht || 0;
    return acc;
  }, 0);

export const determineTypeOfAuditor = (
  worksiteData: IWorksiteMar | undefined,
  user: IUserType | null,
  userView: IPartnerView | null
) => {
  if (worksiteData && worksiteData.audit) {
    const entireName = `${worksiteData.audit.firstname} ${worksiteData.audit.lastname}`;
    if (entireName === `${user?.firstname} ${user?.lastname}`) {
      return AUDITOR_TYPES.MYSELF;
    }
    if (worksiteData.audit.entity_id !== userView?.entity_id) {
      return AUDITOR_TYPES.ENGINEERING_OFFICE;
    }

    return AUDITOR_TYPES.COLLABORATOR;
  }
  return undefined;
};

export const calculatePrimeAmountQuotes = (proQuotes: IProDevis[]) => {
  return proQuotes.reduce(
    (acc, quote) => {
      const quoteCosts = quote.mar_gestes?.reduce(
        (quoteAcc, geste) => {
          // eslint-disable-next-line no-param-reassign
          quoteAcc.cost_ht += geste.cost_ht;
          // eslint-disable-next-line no-param-reassign
          quoteAcc.cost_ttc += geste.cost_ttc;
          return quoteAcc;
        },
        { cost_ht: 0, cost_ttc: 0 }
      );

      acc.cost_ht += quoteCosts?.cost_ht || 0;
      acc.cost_ttc += quoteCosts?.cost_ttc || 0;
      acc.mpr_amount += quote.mpr_amount || 0;
      return acc;
    },
    { cost_ht: 0, cost_ttc: 0, mpr_amount: 0 }
  );
};
