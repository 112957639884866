import { AddOperationPrice } from '@models/contractCreation/components/steps/operationPrices/AddOperationPrice';
import { OneOperationPrice } from '@models/contractCreation/components/steps/operationPrices/OneOperationPrice';
import { OperationPricesActions } from '@models/contractCreation/components/steps/operationPrices/OperationPricesActions';
import { IContractOperationPrice } from '@models/contractCreation/utils/contractCreationTypes';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { Dispatch, SetStateAction } from 'react';

interface OperationsPriceEditorProps {
  operationPrices: IContractOperationPrice[];
  listOperations: IOperationType[] | null;
  isLoading: boolean;
  availableOperations: IOperationType[];
  selectedIds: number[];
  setSelectedIds: Dispatch<SetStateAction<number[]>>;
}

/**
 * Composant d'édition des prix d'opérations
 * Affiche les opérations existantes et permet d'en ajouter de nouvelles
 */
export function OperationsPriceEditor({
  operationPrices,
  listOperations,
  isLoading,
  availableOperations,
  selectedIds,
  setSelectedIds,
}: OperationsPriceEditorProps) {
  // Si un des prix d'opérations à une valeur pour les clés pu_classique ou pu_precaire, alors on ce n'est pas une convention
  const isConvention = !operationPrices.some(
    (op) => op.pu_classique || op.pu_precaire
  );

  return (
    <div className="w-full">
      <AddOperationPrice
        operations={availableOperations}
        isLoading={isLoading}
        isConvention={isConvention}
      />
      {operationPrices.length > 0 && (
        <>
          <OperationPricesActions
            isConvention={isConvention}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
          {operationPrices.map((op, i) => (
            <div
              key={`operation-price-item-${op.operation_id || op.id}`}
              className={`py-5 ${
                operationPrices.length > 1 && i + 1 < operationPrices.length
                  ? 'border-b border-b-1'
                  : ''
              }`}
            >
              <OneOperationPrice
                operations={listOperations || []}
                operationPrice={op}
                isLoading={isLoading}
                index={i}
                isConvention={isConvention}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}
