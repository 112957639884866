import { Tag } from '@components/atomic/Tag';
import { cofracLotsOperationsTagColorByStatus } from '@models/cofrac/utils/utils';
import { ICofracLotsOperations } from '@models/cofrac/utils/cofracTypes';

type ICofracTagInfosProps = {
  cofracLotOperation: ICofracLotsOperations;
};

function CofracTagInfos({
  cofracLotOperation,
}: ICofracTagInfosProps): JSX.Element {
  const tag = (
    cofrac: ICofracLotsOperations
  ): {
    text: string;
    color: string;
  } => {
    const getStatusText = (status: number): string => {
      switch (status) {
        case 1:
          return 'Satisfaisant';
        case 2:
          return 'Insatisfaisant';
        case 3:
          return 'Non contrôlé';
        default:
          return 'En cours';
      }
    };
    const color = cofracLotsOperationsTagColorByStatus(cofrac);
    const text = `COFRAC ${getStatusText(cofrac.status)}`;
    return { text, color };
  };

  return (
    <Tag
      label={tag(cofracLotOperation).text}
      color={tag(cofracLotOperation).color}
      dataTestId="cofrac_tag_infos"
    />
  );
}

export { CofracTagInfos };
