import { blueOpx } from '@assets/color';
import { AddFatIcon, DownloadIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Loader } from '@components/atomic/Loader';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import { LoaderWorksitesList } from '@components/loaders/worksites/LoaderWorksitesList';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import {
  FilterRequestData,
  ISortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';
import {
  exportVue,
  getWorksitesList,
} from '@models/worksites/apiRequests/worksitesRequests';
import { WorksitesCard } from '@models/worksites/components/worksitesList/WorksitesCard';
import { WORKSITES_LIST_TABS } from '@models/worksites/utils/tab';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { FilterCriteriaEnum } from '@utils/enums';
import { ROLES } from '@utils/roles';
import { ADMIN_ROUTES, WORKSITES_ROUTES } from '@utils/routesUrls';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { getKeyByValue } from '../utils/utils';
import { IWorksiteDetails } from '../utils/worksitesTypes';

interface WorksitesListProps {
  adminMode?: boolean;
}

function WorksitesList({ adminMode }: WorksitesListProps): JSX.Element {
  const { t } = useTranslation();
  const {
    globalEnum,
    updateDisplayModalUpgradeSubscription,
    userView,
    roleUser,
  } = useContext(GlobalContext);
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const { worksitesListDatas, updateWorksitesListDatas } =
    useContext(WorksitesContext);

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stepStr = queryParams.get('step');
  const step = stepStr ? parseInt(stepStr, 10) : null;

  // const [currentPage, setCurrentPage] = useState<number>(1); // Ajouter un état pour la page actuelle
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const numberLoaderCards = 5;
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<
    ({ data: any; meta: any } | null)[]
  >([]);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [createdBy, setCreatedBy] = useState<number | null | undefined>(
    undefined
  );
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const preFilter = useMemo((): FilterRequestData[] => {
    const filterData: FilterRequestData[] = [];
    const company = query.get('company');
    if (company) {
      filterData.push({
        criteria: FilterCriteriaEnum.ENTITY,
        ids: [parseInt(company as string, 10)],
      });
    }
    return filterData;
  }, [query]);

  const paginationParam = queryParams.get('pagination');
  const pageParam = queryParams.get('page');
  const defaultPagination = { page: 1, perPage: 5 };
  const initialPagination = paginationParam
    ? JSON.parse(decodeURIComponent(paginationParam))
    : defaultPagination;

  const [currentPage, setCurrentPage] = useState<number>(
    // Use pageParam if available, otherwise use the pagination object
    pageParam ? parseInt(pageParam, 10) : initialPagination.page
  );
  const [resultsPerPage, setResultsPerPage] = useState<number>(
    initialPagination.perPage
  );
  const tabStatusFromUrl = queryParams.get('tabStatus');
  const initialTabActive = tabStatusFromUrl || ''; // Default to 'Tous' if not present

  const [tabActive, setTabActive] = useState<string>(initialTabActive);

  // Initialize filters from URL
  const sortAndFilterParam = queryParams.get('sort_and_filter');
  const initialFilters = sortAndFilterParam
    ? JSON.parse(decodeURIComponent(sortAndFilterParam))
    : null;

  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>(initialFilters || { filters: preFilter });

  // handle filter data
  const updateUrlParameters = (
    page: number,
    perPage: number,
    filterData?: any,
    tabStatus?: string
  ) => {
    const params = new URLSearchParams(location.search);

    // Toujours mettre à jour la page
    params.set('page', page.toString());

    // Mettre à jour la pagination si spécifiée
    const paginationValue = JSON.stringify({ page, perPage });
    params.set('pagination', encodeURIComponent(paginationValue));

    // Mettre à jour les filtres si spécifiés, sinon conserver les existants
    if (filterData) {
      params.set(
        'sort_and_filter',
        encodeURIComponent(JSON.stringify(filterData))
      );
    } else {
      // Conserver les filtres existants
      const existingFilter = queryParams.get('sort_and_filter');
      if (existingFilter) {
        params.set('sort_and_filter', existingFilter);
      }
    }

    // Mettre à jour le statut d'onglet si spécifié
    if (tabStatus) {
      params.set('tabStatus', encodeURIComponent(JSON.stringify(tabStatus)));
    }

    // Utiliser navigate avec l'option replace: false pour permettre la navigation arrière
    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
        hash: location.hash,
      },
      { replace: false }
    );
  };

  // When applying filters
  const handleFilterApply = (filterData: any) => {
    updateUrlParameters(currentPage, resultsPerPage, filterData);
  };

  useEffect(() => {
    const createdByParam = query.get('created');
    if (createdByParam) {
      setCreatedBy(Number(createdByParam));
    } else {
      setCreatedBy(null); // Explicitly set to null when the 'created' param is not in the URL
    }
  }, [query]);

  const exportListVue = () => {
    const status = getKeyByValue(globalEnum.worksite_ui_status, tabActive, t);
    exportVue(
      setExportLoading,
      String(userView?.company_name),
      tabActive,
      status,
      sortAndFilterData
    );
  };

  const handleNavigationButtonClick = (label: string) => {
    switch (label) {
      case t('worksites.new_work_site'):
        navigate('/worksites/new');
        break;
      case t('worksites.exportvue'):
        exportListVue();
        break;
      default:
        break;
    }
  };

  const getList = async (
    page: number,
    listData?: IWorksiteDetails[],
    customSortAndFilterData?: ISortAndFilterType
  ) => {
    let tabStatus;

    if (location.hash === '#simulations') {
      tabStatus = 3;
    } else if (tabActive === t('worksites.all')) {
      tabStatus = undefined;
    } else if (tabActive === t('worksites.recall')) {
      tabStatus = 51;
    } else {
      tabStatus = getKeyByValue(globalEnum.worksite_ui_status, tabActive, t);
    }

    const res = await getWorksitesList(
      updateWorksitesListDatas,
      setIsLoadingList,
      page,
      setIsLoading,
      listData,
      tabStatus,
      customSortAndFilterData || sortAndFilterData,
      adminMode,
      createdBy,
      resultsPerPage
    );

    if (res) {
      setPaginationData({
        current_page: res.current_page,
        last_page: res.last_page,
        total: res.total,
      });

      // Mettre à jour pagesDatas de manière à garder l'index cohérent avec le numéro de page
      const newPagesDatas = [...pagesDatas];
      // On s'assure que l'emplacement existe pour l'index (page-1)
      while (newPagesDatas.length < page) {
        newPagesDatas.push(null);
      }
      // On met à jour les données pour cette page spécifique
      newPagesDatas[page - 1] = {
        data: res.data,
        meta: {
          current_page: res.current_page,
          last_page: res.last_page,
          total: res.total,
        },
      };
      setPagesDatas(newPagesDatas);
    }
  };

  const pageAlreadyLoad = (page: number) => {
    // Vérifier si nous avons déjà les données pour cette page spécifique
    if (pagesDatas.length >= page && pagesDatas[page - 1] !== null) {
      const pageData = pagesDatas[page - 1];
      // Type guard pour s'assurer que pageData n'est pas null
      if (pageData) {
        updateWorksitesListDatas(pageData.data);
        setPaginationData(pageData.meta);
      } else {
        // Si jamais pageData est null malgré notre vérification précédente (ne devrait pas arriver)
        getList(page);
      }
    } else {
      getList(page);
    }
  };

  const onClickTabsLeftPart = (title: string) => {
    navigate(
      adminMode ? ADMIN_ROUTES.ADMIN_WORKSITES : WORKSITES_ROUTES.WORKSITES
    );
    setTabActive(title);
    setCurrentPage(1);
    setIsLoading(true);
    setPaginationData(undefined);
    // Réinitialiser le cache des pages pour le nouvel onglet
    setPagesDatas([]);
    updateUrlParameters(1, resultsPerPage, sortAndFilterData, title);
    setIsLoading(false);
  };

  // Référence pour suivre l'initialisation globale
  const isFirstInitialization = useRef(true);

  useEffect(() => {
    // Mise à jour du titre
    updateTitleHeader(`${t('sidebar.worksites')}`);
    refreshHeader();

    // Initialisation des données seulement au premier rendu ou si createdBy change
    if (isFirstInitialization.current || createdBy !== undefined) {
      isFirstInitialization.current = false;

      // Prendre en compte les paramètres d'URL pour le chargement initial
      if (location.search) {
        const urlParams = new URLSearchParams(location.search);
        const urlPageParam = urlParams.get('page');

        // Utiliser la page spécifiée dans l'URL ou par défaut page 1
        const pageToLoad = urlPageParam ? parseInt(urlPageParam, 10) : 1;
        getList(pageToLoad);
      } else {
        getList(1);
      }
    }
  }, [createdBy]);

  // Référence pour suivre le premier chargement des étapes
  const isFirstStepRender = useRef(true);

  useEffect(() => {
    // Si les enums ne sont pas chargées, on n'a rien à faire
    if (Object.keys(globalEnum).length <= 1) {
      return;
    }

    // S'il y a un paramètre step, on l'utilise pour filtrer
    if (step) {
      let customSortAndFilterData;

      if (step >= 1 && step <= 8) {
        customSortAndFilterData = {
          filters: [
            {
              criteria: 2,
              ids: [step],
            },
          ],
        };
      }

      // Au premier rendu, si on a une page dans l'URL, on la respecte
      if (isFirstStepRender.current && pageParam) {
        isFirstStepRender.current = false;
        const pageToLoad = parseInt(pageParam, 10);
        getList(pageToLoad, undefined, customSortAndFilterData);
      } else {
        // Sinon on commence à la page 1
        setCurrentPage(1);
        setPaginationData(undefined);
        // Réinitialiser le cache des pages
        setPagesDatas([]);
        getList(1, undefined, customSortAndFilterData);
      }
      setIsLoading(false);
    }
    // Si le step change à null et que ce n'est pas le premier rendu
    else if (!isFirstStepRender.current) {
      setCurrentPage(1);
      setPaginationData(undefined);
      // Réinitialiser le cache des pages
      setPagesDatas([]);
      getList(1);
      setIsLoading(false);
    } else {
      isFirstStepRender.current = false;
    }
  }, [tabActive, globalEnum, step]);

  // Référence pour suivre l'initialisation des filtres
  const isFirstFilterRender = useRef(true);

  // Effect pour les changements de filtres et résultats par page
  useEffect(() => {
    // Ignorer le premier rendu (initialisation)
    if (isFirstFilterRender.current) {
      isFirstFilterRender.current = false;
      return;
    }

    // Pour les changements ultérieurs, toujours revenir à la page 1
    if (sortAndFilterData || resultsPerPage) {
      // Réinitialiser le cache des pages car les filtres ont changé
      setPagesDatas([]);
      setCurrentPage(1);
      getList(1);
    }
  }, [sortAndFilterData, resultsPerPage]);

  useEffect(() => {
    updateUrlParameters(
      currentPage,
      resultsPerPage,
      sortAndFilterData,
      tabActive
    );
  }, [tabActive]);

  const leftPartSubHeader = useMemo(() => {
    return (
      <TabsLeftPart
        titlesList={Object.values(WORKSITES_LIST_TABS(t, user))}
        onClick={(title: string) => onClickTabsLeftPart(title)}
        activeButton={
          location.hash === '#simulations'
            ? WORKSITES_LIST_TABS(t, user).SIMULATIONS
            : WORKSITES_LIST_TABS(t, user).ALL
        }
      />
    );
  }, [tabActive]);

  const rightPartSubHeader: JSX.Element = (
    <>
      <div className="flex gap-3">
        <ButtonOpx
          label={t('worksites.exportvue')}
          icon={exportLoading ? <Loader /> : <DownloadIcon />}
          type="secondary"
          onClick={() => {
            if (user?.is_freemium) {
              updateDisplayModalUpgradeSubscription(true);
            } else {
              handleNavigationButtonClick(t('worksites.exportvue'));
            }
          }}
        />
        <SortAndFilter
          page={adminMode ? 'ADMIN_CHANTIERS' : 'CHANTIERS'}
          onSort={(column, direction) => {
            const newSortAndFilter = {
              ...sortAndFilterData, // Keep existing filters
              order_by: { column, direction }, // Add/update sort
            };
            sortList(column, direction, setSortAndFilterData);
            handleFilterApply(newSortAndFilter);
          }}
          onFilter={(filters) => {
            const newSortAndFilter = {
              ...sortAndFilterData, // Keep existing sort
              filters, // Add/update filters
            };
            filterList(filters, setSortAndFilterData);
            handleFilterApply(newSortAndFilter);
          }}
          preFilter={preFilter}
          initialValues={sortAndFilterData}
        />
      </div>
      {[ROLES.PRODUCTION, ROLES.GESTION].includes(roleUser) &&
        user?.permissions_names.includes('creer-chantier') && (
          <ButtonOpx
            label={t('worksites.new_work_site')}
            icon={<AddFatIcon />}
            type="primary"
            onClick={() =>
              handleNavigationButtonClick(t('worksites.new_work_site'))
            }
            dataTestId="new_worksite"
          />
        )}
    </>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className="w-full" data-test-id="worksites">
      <SubHeader leftPart={leftPartSubHeader} rightPart={rightPartSubHeader} />
      <div className="flex justify-between">
        <div className="mt-4">
          {paginationData?.total !== undefined && (
            <div>
              {t('pagination.total_results')} : {paginationData.total}
            </div>
          )}
        </div>
        <div className="mb-4">
          <ResultsPerPageButton
            resultsPerPage={resultsPerPage}
            onChange={(value) => {
              setResultsPerPage(value);
              setCurrentPage(1);
              updateUrlParameters(1, value);
              getList(1);
            }}
            options={[5, 10, 20, 50, 100, 250]}
            colorPagination={blueOpx}
          />
        </div>
      </div>
      {isLoadingList ? (
        <div className="w-full overflow-y-scroll flex-col flex space-y-[1.5rem]">
          {[...Array(numberLoaderCards)].map(() => (
            <div key={v4()}>
              <LoaderWorksitesList />
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full" data-test-id="worksites_list">
          {worksitesListDatas?.length === 0 ? (
            <div
              className="list_noElements flex items-center justify-center h-36 text-danger"
              data-test-id="no_result"
            >
              {t('list.no_result')}
            </div>
          ) : (
            <div className="w-full flex-col flex space-y-[1.5rem] ">
              {worksitesListDatas?.map((details: IWorksiteDetails) => (
                <WorksitesCard
                  key={details.id}
                  worksiteDetails={details}
                  adminMode={adminMode}
                />
              ))}
              {isLoading && <LoaderWorksitesList />}
            </div>
          )}
        </div>
      )}
      <ListPagination
        getData={(page) => {
          // Mise à jour de l'état local
          setCurrentPage(page);

          // Mise à jour de l'URL
          updateUrlParameters(
            page,
            resultsPerPage,
            sortAndFilterData,
            tabActive
          );

          // Chargement des données pour la page
          if (page > pagesDatas.length) {
            getList(page);
          } else {
            pageAlreadyLoad(page);
          }
        }}
        paginationData={paginationData}
        resultsPerPage={resultsPerPage}
        currentPage={currentPage}
      />
    </div>
  );
}

export { WorksitesList };

WorksitesList.defaultProps = {
  adminMode: false,
};
