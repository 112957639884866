/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';

import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { FormProvider, useForm } from 'react-hook-form';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { getOperationsDdl } from '@models/contracts/apiRequests/newContractRequests';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { useParams } from 'react-router-dom';
import { OneOperationPrice } from '@models/contractCreation/components/steps/operationPrices/OneOperationPrice';
import { AddOperationPrice } from '@models/contractCreation/components/steps/operationPrices/AddOperationPrice';
import { OperationPricesActions } from '@models/contractCreation/components/steps/operationPrices/OperationPricesActions';

function StepOperationPrices() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    operationPrices,
    relatedContract,
    updateListOperations,
    listOperations,
    changeStep,
    contractType,
    beneficiaryType,
    startDate,
    endDate,
    entityTo,
    amo,
  } = useContext(ContractCreationContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const isConvention = [
    ContractTypes.CONVENTION,
    ContractTypes.TRIPARTITE,
  ].includes(contractType);

  const methods = useForm();

  const getData = async () => {
    setIsLoading(true);
    const resList = await getOperationsDdl(
      relatedContract?.id || null,
      contractType,
      beneficiaryType !== 0 ? beneficiaryType : null,
      '',
      startDate,
      endDate,
      contractType === ContractTypes.TRIPARTITE ? amo.id : entityTo.id,
      id ? Number(id) : undefined
    );

    if (resList) {
      updateListOperations(resList);
    }

    setIsLoading(false);
  };

  const availableOperations = useMemo(() => {
    const usedIds = new Set(operationPrices.map((op) => op.operation_id));
    const uniqueOperations = listOperations?.filter(
      (op) => !usedIds.has(op.id)
    );

    // Removing duplicates(occurs on deleting operations from set price per operation) from listOperations based on operation_id
    const uniqueListOperations = uniqueOperations?.filter(
      (op, index, self) => index === self.findIndex((item) => item.id === op.id)
    );

    return uniqueListOperations;
  }, [operationPrices, listOperations]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card
      title={t('contract.price_purchase_per_operation')}
      actionButtons={
        <div className="flex gap-3">
          <ButtonOpx
            type="secondary"
            label={`${t('global.back')}`}
            onClick={() => changeStep('back')}
          />

          <ButtonOpx
            label={`${t('buttons.next')}`}
            onClick={() => changeStep('next')}
            dataTestId="button_next"
            disabled={!operationPrices.some((op) => op.operation_id > 0)}
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <div className="w-full overflow-y max-h-[38rem]">
          <AddOperationPrice
            operations={availableOperations || []}
            isLoading={isLoading}
            isConvention={isConvention}
          />
          {operationPrices.length > 0 && (
            <>
              <OperationPricesActions
                isConvention={isConvention}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
              {operationPrices.map((op, i) => (
                <div
                  key={`operation-price-item-${op.operation_id}`}
                  className={`py-5 ${
                    operationPrices.length > 1 && i + 1 < operationPrices.length
                      ? 'border-b border-b-1'
                      : ''
                  }`}
                >
                  <OneOperationPrice
                    operations={listOperations || []}
                    operationPrice={op}
                    isLoading={isLoading}
                    index={i}
                    isConvention={isConvention}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </FormProvider>
    </Card>
  );
}

export { StepOperationPrices };
