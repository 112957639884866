import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TextError } from '@components/TextError';
import { calculatePrimes } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { CardForm } from '@models/worksiteCreation/components/CardForm';
import CustomPrice from '@models/worksiteCreation/components/simulation/stepCustomPrices/CustomPrice';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import {
  getContractPrice,
  updateSimulationPrice,
} from '@models/worksiteCreation/utils/functions';
import { ISimulatorDataOperation } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { OperationTypeEnum } from '@utils/enums';
import { useTranslation } from 'react-i18next';

function StepCustomPrices() {
  const {
    updateNoAidOperationToDisplay,
    incomesOptionsArray,
    conventionActive,
    simulatorDataOperations,
    updateSimulatorDataOperation,
    simulatorData,
    updateSimulatorData,
    worksiteDatas,
    updateIsLoading,
    updateStepActiveWorksiteCreation,
    worksiteOperationType,
    updateDisabledNextButton,
    readOnly,
  } = useContext(WorksiteCreationContext);
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { t } = useTranslation();

  const methods = useForm(); //
  const { setValue, unregister, watch } = methods;
  const formValues = watch();

  const onSelect = (
    code: string,
    selected: boolean,
    precarity: null | 'classic' | 'modest'
  ) => {
    const key = precarity ? `${precarity}_${code}` : code;

    if (precarity) unregister(code);
    if (selectedCodes.includes(code) && !selected) {
      unregister(key);
      updateSimulationPrice(
        code,
        null,
        updateSimulatorDataOperation,
        precarity
      );
      setSelectedCodes(selectedCodes.filter((c) => c !== code));
    }

    if (!selectedCodes.includes(code) && selected) {
      setValue(key, '');
      setSelectedCodes([...selectedCodes, code]);
    }
  };

  const onSubmit = async () => {
    let canGoToNextStep: boolean;

    if (!readOnly) {
      const formattedOperations = simulatorDataOperations.map((op) => {
        return Object.entries(op).reduce(
          (acc: ISimulatorDataOperation, [key, value]) => {
            if (key.includes('custom') && typeof value === 'string') {
              acc[key] = value.replace(',', '.');
            } else {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );
      });

      canGoToNextStep = await calculatePrimes(
        simulatorData,
        formattedOperations,
        updateIsLoading,
        updateSimulatorData,
        updateSimulatorDataOperation,
        setErrorMessage,
        worksiteOperationType,
        conventionActive.id,
        incomesOptionsArray,
        t,
        worksiteDatas.incentive_type,
        updateNoAidOperationToDisplay
      );
    } else {
      canGoToNextStep = true;
    }

    if (canGoToNextStep) {
      updateStepActiveWorksiteCreation(
        StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF
      );
    }
  };

  useEffect(() => {
    simulatorDataOperations.forEach((op) => {
      const code = String(op['operation.id']).toUpperCase();

      const isSelected = selectedCodes.includes(code);

      const withCustom = Boolean(
        op.customPrice || op.customPriceClassic || op.customPriceModest
      );

      if (withCustom && !isSelected) {
        setSelectedCodes([...selectedCodes, code]);
      }

      const b2bResidential =
        worksiteOperationType === OperationTypeEnum.B2B &&
        code.startsWith('BAR-');

      if (!b2bResidential) {
        const price = isSelected ? op.customPrice : getContractPrice(op, null);
        setValue(code, price || '');
        unregister(`classic_${code}`);
        unregister(`modest_${code}`);
      } else {
        const priceClassic = isSelected
          ? op.customPriceClassic
          : getContractPrice(op, 'classic');
        const priceModest = isSelected
          ? op.customPriceModest
          : getContractPrice(op, 'modest');

        setValue(`classic_${code}`, priceClassic || '');
        setValue(`modest_${code}`, priceModest || '');
        unregister(code);
      }
    });
  }, [simulatorDataOperations, selectedCodes]);

  useEffect(() => {
    const disabled = Object.values(formValues).some(
      (value) => !value || value === '' || value === '0'
    );
    updateDisabledNextButton(disabled);
  }, [formValues]);

  return (
    <CardForm
      title={`${t('worksite_creation.simulation.custom_prices.title')}`}
      subtitle={`${t('worksite_creation.simulation.custom_prices.subtitle')}`}
      idForm="sendCustomPrices"
      addClass="flexFormGraph w-full pb-[2rem]"
      methods={methods}
      onSubmit={onSubmit}
    >
      <div className="mt-5">
        <FormProvider {...methods}>
          {simulatorDataOperations.map((op) => {
            const code = String(op['operation.id']).toUpperCase();

            const b2bResidential =
              worksiteOperationType === OperationTypeEnum.B2B &&
              code.startsWith('BAR-');

            return (
              <div key={`custom_${code}`}>
                <CustomPrice
                  onSelect={(isSelected) => {
                    if (!b2bResidential) {
                      onSelect(code, isSelected, null);
                    } else {
                      onSelect(code, isSelected, 'modest');
                      onSelect(code, isSelected, 'classic');
                    }
                  }}
                  isSelected={selectedCodes.includes(code)}
                  operation={op}
                  b2bResidential={b2bResidential}
                />
              </div>
            );
          })}
        </FormProvider>
        {errorMessage.length > 0 && <TextError errorMessage={errorMessage} />}
      </div>
    </CardForm>
  );
}

export default StepCustomPrices;
