/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from '@components/atomic/Tag';
import { SearchBar } from '@components/SearchBar';
import { green, orange, red, textGrey } from '@assets/color';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { FilterRequestData } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { OperationListSkeleton } from '@models/contracts/components/OperationListSkeleton';
import { IConsultListPage, IPageInList } from '@globalTypes/globalTypes';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { numberWithSeparator } from '@utils/format';
import { CheckBox } from '@components/atomic/inputs/CheckBox';
import { Link } from 'react-router-dom';
import { WORKSITES_ROUTES_WITH_PARAMS } from '@utils/routesUrls';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { GlobalContext } from '@context/globalContext';
import { convertKiloToMega } from '@utils/functions';
import { IControlContactWorksiteOperationType } from '../utils/controlContactTypes';
import { linkedWorksitesOperationsStatus } from '../utils/utlis';

interface ISetOperationListProps {
  type: 'add' | 'delete' | 'consult';
  data: IControlContactWorksiteOperationType[];
  setSearch: (value: string) => void;
  isLoading: boolean;
  onSubmit?: (operationsChecked: number[]) => void;
  onSort?: (column: string, direction: string) => void;
  onFilter?: (filters: FilterRequestData[]) => void;
  fromCofrac: boolean;
}

function SetOperationList({
  type,
  data,
  setSearch,
  isLoading,
  onSubmit,
  onSort,
  onFilter,
  fromCofrac,
}: ISetOperationListProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const page = 'CONTROL_CONTACT';
  const pageForSortAndFilter: IConsultListPage = `${page}_OPERATIONS`;

  const title =
    type === 'add'
      ? t('control_contact.section_to_link')
      : type === 'delete'
      ? t('control_contact.section_linked')
      : t('control_contact.lot_operations');

  const [operationsChecked, setOperationsChecked] = useState<number[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(operationsChecked);
    }
  };

  const renderWorksiteOperation = (
    item: IControlContactWorksiteOperationType
  ) => {
    const beneficiaryFullName = item.worksite_site_name
      ? item.worksite_site_name
      : item.beneficiary_full_name ?? '';
    const installer = item.installer_company_name ?? '';
    const isChecked = operationsChecked.includes(item.worksite_operation_id);

    const statusTag = () => {
      const tagText =
        globalEnum.worksite_operation_contact_control_status[
          item.contact_control_status
        ];
      switch (item.contact_control_status.toString()) {
        case linkedWorksitesOperationsStatus.CONTROLABLE.toString():
        case linkedWorksitesOperationsStatus.NON_CONTROLEE.toString():
          return <Tag color={textGrey} label={tagText} />;
        case linkedWorksitesOperationsStatus.SATISFAISANT.toString():
          return <Tag color={green} label={tagText} />;
        case linkedWorksitesOperationsStatus.A_RAPPELER.toString():
          return <Tag color={orange} label={tagText} />;
        case linkedWorksitesOperationsStatus.INSUFFISANT.toString():
          return <Tag color={red} label={tagText} />;
        default:
          return null;
      }
    };

    const content = (
      <div
        className={`rounded-default p-[1rem] mb-[0.5rem] border border-borderGrey space-x-4 flex w-full ${
          isChecked ? 'bg-backgroundBody' : ''
        } ${type === 'consult' ? 'cursor-pointer' : ''}`}
        key={`operation-to-${type}-${item.worksite_operation_id}`}
      >
        {type !== 'consult' && (
          <CheckBox
            isCheck={isChecked}
            setIsCheck={() => {
              if (isChecked) {
                setOperationsChecked(
                  operationsChecked.filter(
                    (id) => id !== item.worksite_operation_id
                  )
                );
              } else {
                setOperationsChecked([
                  ...operationsChecked,
                  item.worksite_operation_id,
                ]);
              }
            }}
            addClass="p-0 border-none w-min h-min"
          />
        )}
        <div className="flex w-full text-[0.875rem]">
          <div className="w-full flex flex-wrap justify-between">
            <div className="space-y-[.656rem]">
              {type === 'consult' && (
                <div className="flex space-x-2">
                  {fromCofrac && (
                    <Tag
                      color={textGrey}
                      label={`COFRAC : ${t('control_contact.non_controlled')}`}
                    />
                  )}
                  {statusTag()}
                </div>
              )}
              <div className="flex space-x-2">
                <Link
                  to={
                    item.worksite_id
                      ? WORKSITES_ROUTES_WITH_PARAMS(
                          item.worksite_id,
                          item.worksite_operation_id
                        )
                          .WORKSITES_VIEW_CONTROL_CONTACT_PANEL_WITH_WORKSITE_OPERATION_ID
                      : ''
                  }
                  className="font-medium"
                >
                  {`${beneficiaryFullName}`}
                </Link>
                {type !== 'consult' && (
                  <Tag
                    color={textGrey}
                    label={t('control_contact.non_controlled')}
                  />
                )}
              </div>
              <p className="text-textGrey">{`${installer}`}</p>
            </div>
            <div>
              <div className="flex flex-col items-end justify-center space-y-[.656rem]">
                <div className="font-medium min-w-max">
                  {String(`${convertKiloToMega(item.kwhc_precaire)} MWhc`)}
                </div>
                <p className="text-textGrey">
                  {`${t('cofrac.amount_prime')} : ${numberWithSeparator(
                    String(item.cee_amount)
                  )} €`}
                </p>
              </div>
            </div>
          </div>
        </div>
        {type === 'consult' && (
          <div className="flex items-center justify-center">
            <ChevronLeftIcon className="w-4 h-4 rotate-180" fill={textGrey} />
          </div>
        )}
      </div>
    );

    if (type === 'consult' && item.worksite_id) {
      return (
        <Link
          to={
            WORKSITES_ROUTES_WITH_PARAMS(
              item.worksite_id,
              item.worksite_operation_id
            ).WORKSITES_VIEW_CONTROL_CONTACT_PANEL_WITH_WORKSITE_OPERATION_ID
          }
          className="w-full"
        >
          {content}
        </Link>
      );
    }

    return content;
  };

  useEffect(() => {
    if (operationsChecked.length === data.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [operationsChecked]);

  useEffect(() => {
    setOperationsChecked([]);
    setAllChecked(false);
  }, [data]);

  return (
    <div className="border border-borderGrey rounded-[.25rem] bg-white p-6">
      <div className="space-y-4">
        <div className="flex flex-wrap justify-between">
          <p className="font-medium text-[1rem]">{title}</p>
          <p className="text-textGrey text-[0.875rem]">{`${
            data && data.length
          } ${t('contract.tab_volume_detail.operation')}${
            data && data.length > 1 ? 's' : ''
          }`}</p>
        </div>
        <div className="flex flex-wrap justify-between items-center space-x-4">
          <div className="flex flex-1">
            <SearchBar
              placeholder={`${t('transfer.search_placeholder')}`}
              onSearch={(value: string) => setSearch(value)}
              searchOnEveryChange
              width="100%"
            />
          </div>
          <SortAndFilter
            onSort={onSort}
            onFilter={onFilter}
            page={
              type === 'delete'
                ? (pageForSortAndFilter as IPageInList)
                : (`CREATION_${page}` as IPageInList)
            }
          />
          {type !== 'consult' && (
            <ButtonOpx
              label={type === 'delete' ? t('buttons.delete') : t('buttons.add')}
              onClick={handleSubmit}
              small
              disabled={operationsChecked.length === 0}
            />
          )}
        </div>
        {data.length > 0 && type !== 'consult' && (
          <CheckBox
            label={allChecked ? 'Tout désélectionner' : 'Tout sélectionner'}
            isCheck={allChecked}
            setIsCheck={() => {
              if (allChecked) {
                setOperationsChecked([]);
              } else {
                setOperationsChecked(
                  data.map((item) => item.worksite_operation_id)
                );
              }
            }}
            addClass="p-0 border-none ml-4"
          />
        )}
        <div className="md:h-[25rem] md:overflow-y-scroll">
          {isLoading ? (
            <OperationListSkeleton />
          ) : data && data.length > 0 ? (
            data?.map(
              (worksiteOperation: IControlContactWorksiteOperationType) => (
                <div key={worksiteOperation.worksite_operation_id}>
                  {renderWorksiteOperation(worksiteOperation)}
                </div>
              )
            )
          ) : (
            <p className="text-textGrey text-[0.875rem]">
              {t('transfer.no_operations')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export { SetOperationList };

SetOperationList.defaultProps = {
  onSort: undefined,
  onFilter: undefined,
  onSubmit: undefined,
};
