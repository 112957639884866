import { useContext } from 'react';
import { DOCUMENT_STATUS_ENUM } from '@utils/utils';
import { CHECKPOINT_RESPONSE_TYPE } from '@models/settings/utils/enums';
import { Radio } from '@components/atomic/inputs/controls/Radio';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { InputText } from '@components/atomic/inputs/InputText';
import { ILinkedFileCheckpoints } from '../utils/checkpointsTypes';
import { LinkedFileCheckpointContext } from '../utils/linkedFileCheckpointContext';
import { isWrongResponseValue, updateCheckpoint } from '../utils/utils';

interface ICheckpointResponseProps {
  checkpoint: ILinkedFileCheckpoints;
  initMailIsOpen: boolean;
  setInitMailIsOpen: (value: boolean) => void;
}

function CheckpointResponse({
  checkpoint,
  initMailIsOpen,
  setInitMailIsOpen,
}: ICheckpointResponseProps) {
  const { document, updateWorksiteCheckpoints, updateMailIsOpen } = useContext(
    LinkedFileCheckpointContext
  );
  const readOnly = document.status !== DOCUMENT_STATUS_ENUM.PENDING;

  const updateValue = (value: string | null) => {
    updateCheckpoint(
      document,
      checkpoint,
      'value',
      value,
      updateWorksiteCheckpoints
    );

    const valid = value && !isWrongResponseValue(checkpoint, value);
    if (!initMailIsOpen && valid === false) {
      setInitMailIsOpen(true);
      updateMailIsOpen(true);
    }
  };

  switch (checkpoint.checkpoint.response_type) {
    case CHECKPOINT_RESPONSE_TYPE.BOOLEAN:
      return (
        <div className="flex gap-5">
          <div className="flex justify-between items-center gap-4">
            {checkpoint.checkpoint.response_values?.map((option) => (
              <div className="flex flex-col items-center gap-2">
                <div className="whitespace-nowrap">{option.value}</div>
                <Radio
                  isChecked={checkpoint.value === option.value}
                  value="check"
                  onSelect={() => {
                    updateValue(option.value);
                  }}
                  disabled={readOnly}
                />
              </div>
            ))}
          </div>
        </div>
      );
    case CHECKPOINT_RESPONSE_TYPE.LIST:
      return (
        <InputSelect
          placeholder=""
          dataArrayString={checkpoint.checkpoint.response_values.map(
            (option) => option.value
          )}
          onSelect={updateValue}
          defaultSelected={checkpoint.value || ''}
          addClass="w-[30rem]"
          disabled={readOnly}
        />
      );
    case CHECKPOINT_RESPONSE_TYPE.DATE:
      return (
        <DatePicker
          placeholder=""
          onChangeDate={updateValue}
          addClass="w-[25rem]"
          required
          defaultDate={checkpoint.value || ''}
          disabled={readOnly}
        />
      );
    default:
      return (
        <div className="w-[25rem]">
          <InputText
            placeholder=""
            name={`number${String(checkpoint.id)}`}
            id={`number${String(checkpoint.id)}`}
            value={checkpoint.value || ''}
            required
            addClass="w-full"
            onChange={(e) => updateValue(String(e))}
            typeNumber={
              checkpoint.checkpoint.response_type ===
              CHECKPOINT_RESPONSE_TYPE.NUMBER
            }
            disabled={readOnly}
          />
        </div>
      );
  }
}

export { CheckpointResponse };
