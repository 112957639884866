import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/globalContext';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { looksLikeADateStr } from '@components/sortAndFilter/utils/functions';
import { InputDatePicker } from './InputDatepicker';
import { Calendar } from './Calendar';

interface IDatePickerProps {
  required: boolean;
  onChangeDate: (date: string) => void;
  label?: string;
  addClass?: string;
  error?: boolean;
  textError?: string;
  noDefaultDate?: boolean;
  defaultDate?: string;
  valid?: boolean;
  minDate?: string | Date;
  maxDate?: string | Date;
  dataTestId?: string;
  addClassToCalendar?: string;
  hideCalendar?: boolean | undefined;
  onSelect?: () => void;
  placeholder?: string;
  disabled?: boolean;
  hideIsOptional?: boolean;
}

function DatePicker({
  label,
  required,
  onChangeDate,
  addClass,
  error,
  textError,
  noDefaultDate,
  defaultDate,
  valid,
  minDate,
  maxDate,
  dataTestId,
  addClassToCalendar,
  hideCalendar,
  onSelect,
  placeholder,
  disabled,
  hideIsOptional,
}: IDatePickerProps) {
  const { i18n, t } = useTranslation();
  const { inputSelectMenuOpenId, updateInputSelectMenuOpenId } =
    useContext(GlobalContext);

  // Générer un ID unique pour chaque instance de DatePicker
  const datePickerId = useMemo(
    () => `datepicker-${Math.random().toString(36).substr(2, 9)}`,
    []
  );

  // Déterminer si le calendrier doit être affiché
  const showCalendar = inputSelectMenuOpenId === datePickerId;

  const [date, setDate] = useState(
    noDefaultDate || defaultDate === ''
      ? ''
      : new Date().toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
  );

  const ref = useRef<HTMLDivElement>(null);

  useMemo(() => {
    if (defaultDate) setDate(defaultDate);
  }, [defaultDate]);

  const handleSelectDate = (d: string) => {
    setDate(d);
    updateInputSelectMenuOpenId(undefined);
  };

  const closeCalendar = () => {
    updateInputSelectMenuOpenId(undefined);
  };

  const changeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    const onlyNumber = /^[0-9]*$/;

    const valueWithoutSlash = currentValue.replace(/\//g, '');
    if (currentValue.length < 11 && valueWithoutSlash.match(onlyNumber)) {
      if (currentValue.length === 2 || currentValue.length === 5) {
        setDate(`${currentValue}/`);
      } else {
        setDate(currentValue);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value;

    if (e.key === 'Backspace') {
      // Vérifie si l'avant-dernier caractère est un '/'
      if (currentValue.slice(-2, -1) === '/') {
        setDate(currentValue.slice(0, -2)); // Supprime deux caractères
        e.preventDefault();
      } else {
        setDate(currentValue.slice(0, -1)); // Supprime un caractère
        e.preventDefault();
      }
    }
  };

  const convertToDate = (data: string | null | undefined): Date => {
    try {
      const d = String(data).split('/');
      return new Date(`${d[2]}/${d[1]}/${d[0]}`);
    } catch (e) {
      return new Date();
    }
  };

  const stringDateToDate = (dateToCheck: string | Date): Date => {
    if (dateToCheck instanceof Date) {
      return dateToCheck;
    }
    return new Date(date);
  };

  useOnClickOutside(ref, () => {
    if (showCalendar) {
      updateInputSelectMenuOpenId(undefined);
    }
  });

  useEffect(() => {
    onChangeDate(date);
  }, [date]);

  useEffect(() => {
    if (hideCalendar !== undefined) {
      if (hideCalendar) {
        updateInputSelectMenuOpenId(undefined);
      } else {
        updateInputSelectMenuOpenId(datePickerId);
      }
    }
  }, [hideCalendar]);

  useEffect(() => {
    if (
      (minDate && stringDateToDate(minDate) > new Date(date)) ||
      (maxDate && stringDateToDate(maxDate) < new Date(date))
    ) {
      setDate('');
    }
  }, [minDate, maxDate, date]);

  return (
    <div ref={ref} className={['relative', addClass].join(' ')}>
      {label && (
        <p className="text-[.75rem] leading-3 text-textGrey mb-2">
          {label}{' '}
          <span>
            {`${required || hideIsOptional ? '' : `(${t('global.optional')})`}`}
          </span>
        </p>
      )}
      <InputDatePicker
        required={required}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeDate(e)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          handleKeyDown(e)
        }
        value={date.replace(/-/g, '/')}
        placeholder={placeholder}
        onClickCalendar={() => {
          if (!disabled) {
            if (inputSelectMenuOpenId !== datePickerId) {
              updateInputSelectMenuOpenId(datePickerId);
            } else {
              updateInputSelectMenuOpenId(undefined);
            }
            if (onSelect) onSelect();
          }
        }}
        showCalendar={showCalendar}
        error={error}
        textError={textError}
        valid={valid}
        dataTestId={dataTestId}
        isSelected={!!(showCalendar && !hideCalendar)}
        disabled={disabled}
      />

      {showCalendar && !disabled && (
        <Calendar
          date={format(
            looksLikeADateStr(date) ? convertToDate(date) : new Date(),
            'yyyy-MM-dd'
          )}
          handleSelectDate={handleSelectDate}
          closeCalendar={closeCalendar}
          minDate={minDate}
          maxDate={maxDate}
          addClass={addClassToCalendar}
        />
      )}
    </div>
  );
}

export { DatePicker };

DatePicker.defaultProps = {
  addClass: '',
  label: '',
  error: false,
  textError: '',
  noDefaultDate: false,
  defaultDate: '',
  valid: false,
  minDate: undefined,
  maxDate: undefined,
  dataTestId: '',
  addClassToCalendar: '',
  hideCalendar: undefined,
  onSelect: undefined,
  placeholder: '',
  disabled: false,
  hideIsOptional: false,
};
