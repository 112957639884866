import { IContractOperationPrice } from '@models/contractCreation/utils/contractCreationTypes';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface IframeOperationsContextType {
  // État pour suivre si les opérations ont été modifiées par l'utilisateur
  hasUserModifiedOperations: boolean;
  setHasUserModifiedOperations: (value: boolean) => void;

  // État pour stocker les opérations actuelles
  currentOperationPrices: IContractOperationPrice[];
  setCurrentOperationPrices: (prices: IContractOperationPrice[]) => void;

  // État pour stocker les opérations d'origine du contrat sélectionné
  originalContractOperations: IContractOperationPrice[];
  setOriginalContractOperations: (prices: IContractOperationPrice[]) => void;

  // État pour suivre si le contrat a été modifié par rapport à son état d'origine
  isContractModified: boolean;
  setIsContractModified: (value: boolean) => void;
}

const IframeOperationsContext = createContext<
  IframeOperationsContextType | undefined
>(undefined);

interface IframeOperationsProviderProps {
  children: ReactNode;
}

function IframeOperationsProvider({
  children,
}: IframeOperationsProviderProps): JSX.Element {
  const [hasUserModifiedOperations, setHasUserModifiedOperations] =
    useState<boolean>(false);
  const [currentOperationPrices, setCurrentOperationPrices] = useState<
    IContractOperationPrice[]
  >([]);
  const [originalContractOperations, setOriginalContractOperations] = useState<
    IContractOperationPrice[]
  >([]);
  const [isContractModified, setIsContractModified] = useState<boolean>(false);

  // Utiliser useMemo pour éviter la recréation de l'objet à chaque rendu
  const contextValue = useMemo(
    () => ({
      hasUserModifiedOperations,
      setHasUserModifiedOperations,
      currentOperationPrices,
      setCurrentOperationPrices,
      originalContractOperations,
      setOriginalContractOperations,
      isContractModified,
      setIsContractModified,
    }),
    [
      hasUserModifiedOperations,
      currentOperationPrices,
      originalContractOperations,
      isContractModified,
    ]
  );

  return (
    <IframeOperationsContext.Provider value={contextValue}>
      {children}
    </IframeOperationsContext.Provider>
  );
}

function useIframeOperations(): IframeOperationsContextType {
  const context = useContext(IframeOperationsContext);
  if (context === undefined) {
    throw new Error(
      'useIframeOperations must be used within an IframeOperationsProvider'
    );
  }
  return context;
}

export { IframeOperationsProvider, useIframeOperations };
