import { Dispatch, SetStateAction } from 'react';
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import { INavLinkSidebar } from 'types/globalTypes';
import {
  IPartnerListRequest,
  IPartnerView,
  IPayloadCreatePartner,
  IPayloadPartnersLinkedFilesData,
  ISubcontractorListRequest,
  IUpsertPartner,
  // IPartnersInvitedEmail,
} from '@models/partners/utils/types/partnersType';
import {
  urlGetControlOfficeCofracList,
  urlGetPartnersByTypeInfos,
  urlGetPartnersContacts,
  urlGetPartnersDocumentsLinkedFiles,
  urlGetPartnerTabs,
  urlPartners,
  urlPostPartnerInfos,
  urlPostSubcontractorsList,
  urlPostValidateInvitationFile,
  urlPostValidateModelInvitationFile,
  urlPutPartners,
} from '@api/apiUrls';
import { toast } from 'react-toastify';
import { ENTITY_TYPES } from '@utils/roles';
import { TFunction } from 'i18next';
import { IBasePartnerType } from '@models/auth/utils/types';
import { PARTNERS_MENU_MAPPING } from '../utils/partnersConstants';

export const getPartnersMenu = async (
  updatePartnersMenu: Dispatch<SetStateAction<INavLinkSidebar[]>>,
  updateListUserView: Dispatch<SetStateAction<IPartnerView[]>>,
  updateUserView: Dispatch<SetStateAction<IPartnerView | null>>
) => {
  try {
    const response = await axiosClient.get(urlGetPartnerTabs);
    const current_view = response.data.current_view;

    let menus = response.data.menu || response.data;

    // NOTE: hide sous-traitants menu when mandataire, oblige, delegataire
    if ([2, 3, 6].includes(current_view.entity_type)) {
      menus = menus.filter(
        (menu: Record<string, string | number>) =>
          menu.entityType !== ENTITY_TYPES.SOUS_TRAITANT
      );
    }

    // NOTE: hide mandataire menu when mandataire
    if (current_view.entity_type === ENTITY_TYPES.MANDATAIRE) {
      menus = menus.filter(
        (menu: Record<string, string | number>) =>
          menu.entityType !== ENTITY_TYPES.MANDATAIRE
      );
    }

    updatePartnersMenu(
      menus.map((menu: Record<string, string | number>) => {
        return {
          ...menu,
          name: PARTNERS_MENU_MAPPING[menu.entityType]?.title || '',
          link: PARTNERS_MENU_MAPPING[menu.entityType]?.link || '',
        };
      })
    );

    // SETTING user View
    updateListUserView(response.data.vues);
    updateUserView(response.data.vues[0]);
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnersList = async ({
  page,
  perPage,
  entityType,
  sortAndFilterData,
  withInstallers = false,
}: IPartnerListRequest) => {
  try {
    const response = await axiosClient.post(urlPartners, {
      pagination: { page, perPage },
      entity_type: entityType,
      sort_and_filter: sortAndFilterData,
      withInstallers,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getSubcontractorsList = async ({
  page,
  perPage,
  worksiteId,
  sortAndFilterData,
}: ISubcontractorListRequest) => {
  try {
    const response = await axiosClient.post(
      urlPostSubcontractorsList(worksiteId),
      {
        pagination: { page, perPage },
        sort_and_filter: sortAndFilterData,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const sendValidatedFile = async (uploadFile: File) => {
  const formData = new FormData();
  formData.append('file', uploadFile);
  try {
    const response = await axiosClient.post(
      urlPostValidateInvitationFile,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
  return false;
};

export const setValidatedFile = async (modelId: number, uploadFile: File) => {
  const formData = new FormData();
  formData.append('file', uploadFile);
  try {
    const response = await axiosClient.post(
      urlPostValidateModelInvitationFile(modelId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
  return false;
};

export const getPartnersContactsDetails = async (entityType: number) => {
  try {
    const response = await axiosClient.get(urlGetPartnersContacts(entityType));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getPartnersDocumentsLinkedFiles = async (
  data: IPayloadPartnersLinkedFilesData
) => {
  try {
    const response = await axiosClient.post(
      urlGetPartnersDocumentsLinkedFiles,
      data
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const storePartner = async (
  t: TFunction,
  formData: IPayloadCreatePartner,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setModal: Dispatch<SetStateAction<boolean>>,
  refreshList: CallableFunction,
  setErrorMessage: Dispatch<SetStateAction<string>>
): Promise<any> => {
  try {
    const res = await axiosClient.put(urlPutPartners, formData);
    setModal(false);
    refreshList();
    return res.data;
  } catch (error) {
    setLoading(false);
    if (!error.response?.data?.errors) {
      setErrorMessage(t('global.error') || 'Erreur inconnue');
    }
    setErrorMessage(error.response.data.errors);
    return false;
  }
};

export const getPartnerByTypeInfos = async (data: IBasePartnerType) => {
  try {
    const response = await axiosClient.post(urlGetPartnersByTypeInfos, {
      entity_id: data.entity_id,
      entity_type: data.entity_type,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const updatePartnerInfos = async (
  entityId: number,
  userId: number,
  data: Partial<IUpsertPartner>
) => {
  try {
    await axiosClient.post(urlPostPartnerInfos(entityId, userId), data);
    return true;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getControlOfficeCofracList = async (entityId: number) => {
  try {
    const response = await axiosClient.post(
      urlGetControlOfficeCofracList(entityId)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const editPartnerInfo = async (
  id: number,
  userId: number,
  payload: Partial<IUpsertPartner>,
  onSuccess: () => void
) => {
  const res = await updatePartnerInfos(Number(id), userId, payload);

  if (res) {
    onSuccess(); // Callback to reload data or perform additional actions
  }
};
