import { axiosClient } from '@api/apiClient';
import {
  urlGetLinkedFileCheckpoints,
  urlPutCancelLinkedFileCheckpointsValidation,
  urlPutValidateLinkedFileCheckpoints,
  urlPostSendComplianceRequest,
  urlPutSaveLinkedFileCheckpointsInProgress,
  urlGetEntityCheckpoints,
  urlCheckpoints,
  urlGetCheckpoint,
} from '@api/apiUrls';
import { displayError } from '@utils/format';
import { FieldValues } from 'react-hook-form';
import {
  ILinkedFileCheckpoints,
  IWorksiteCheckpoints,
} from '../utils/checkpointsTypes';

export const getLinkedFileCheckpoints = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetLinkedFileCheckpoints(id));
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
      return false;
    }
    return false;
  }
};

export const validateLinkedFileCheckpoints = async (
  id: number,
  data: {
    checkpoints: ILinkedFileCheckpoints[];
  }
) => {
  try {
    const response = await axiosClient.put(
      urlPutValidateLinkedFileCheckpoints(id),
      data
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
      return false;
    }
    return false;
  }
};

export const sendComplianceRequest = async (
  id: number,
  data: {
    checkpoints: ILinkedFileCheckpoints[];
    message: string;
  }
) => {
  try {
    const response = await axiosClient.post(
      urlPostSendComplianceRequest(id),
      data
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
    return false;
  }
};

export const cancelLinkedFileCheckpoints = async (id: number) => {
  try {
    const response = await axiosClient.put(
      urlPutCancelLinkedFileCheckpointsValidation(id)
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
    return false;
  }
};

export const saveLinkedFileCheckpointsInProgress = async (
  id: number,
  data: IWorksiteCheckpoints[]
) => {
  try {
    const response = await axiosClient.put(
      urlPutSaveLinkedFileCheckpointsInProgress(id),
      data
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
    return false;
  }
};

export const getEntityCheckpoints = async () => {
  try {
    const response = await axiosClient.get(urlGetEntityCheckpoints);
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
      return false;
    }
    return false;
  }
};

export const storeCheckpoint = async (data: FieldValues) => {
  try {
    const response = await axiosClient.post(urlCheckpoints, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
    return false;
  }
};

export const getCheckpoint = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetCheckpoint(id));
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
    return false;
  }
};

export const updateCheckpoint = async (id: number, data: FieldValues) => {
  try {
    const response = await axiosClient.put(urlGetCheckpoint(id), data);
    return response.data;
  } catch (error) {
    if (error.response) {
      displayError(error.response);
    }
    return false;
  }
};
