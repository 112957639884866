import { convertHexToRGBA } from '@utils/functions';

type IProgressBarHorizontalProps = {
  percent: string;
  color: string;
  height?: string;
  border?: string;
  backgroundColor?: string;
  addClass?: string;
  secondValuePercent?: string;
  secondValueColor?: string;
};

function ProgressBarHorizontal({
  percent,
  color,
  height,
  border,
  backgroundColor,
  addClass,
  secondValuePercent,
  secondValueColor,
}: IProgressBarHorizontalProps): JSX.Element {
  const hasSecondValue = secondValueColor && secondValuePercent;
  const borderRadius = border || '.125rem';

  return (
    <div
      style={{
        height: height || '.25rem',
        backgroundColor: convertHexToRGBA(color, 0.1) || backgroundColor,
        borderRadius: border || '.125rem',
      }}
      className={[`w-full flex items-center`, addClass].join('')}
    >
      <div
        style={{
          backgroundColor: color,
          height: height || '.25rem',
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: hasSecondValue ? '' : borderRadius,
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: hasSecondValue ? '' : borderRadius,
          width: percent,
        }}
      />
      {hasSecondValue && (
        <div
          style={{
            backgroundColor: secondValueColor,
            height: height || '.25rem',
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            width: secondValuePercent,
          }}
        />
      )}
    </div>
  );
}

export { ProgressBarHorizontal };

ProgressBarHorizontal.defaultProps = {
  height: '',
  border: '',
  backgroundColor: '',
  addClass: '',
  secondValuePercent: '',
  secondValueColor: '',
};
