/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import { TFunction } from 'i18next';
import {
  urlCopyContract,
  urlDeleteInvitedInstaller,
  urlDuplicateConvention,
  urlGetAndPutConventionPaymentConditions,
  urlGetConventionBuyingPrices,
  urlGetConventionGeneralInfos,
  urlGetConventionModelGeneralInfo,
  urlGetInvitedInstallerForCount,
  urlGetInvitedInstallerList,
  urlPostContractReferents,
  urlPostConventionContact,
  urlPostConventionReferents,
  urlPostConventionWorksites,
  urlPostInvitationFromModel,
  urlUpdateConvention,
  urlTemporaryContractPayload,
} from '@api/apiUrls';
import {
  IConventionModelType,
  IConventionType,
} from '@models/conventions/utils/conventionTypes';
import { IUserType } from '@models/auth/utils/types';

export const getTabs = async (t: TFunction, convention: IConventionType) => {
  try {
    const tabs = [
      {
        label: t('convention.tabs.information'),
        tab_id: 1,
      },
      {
        label: t('convention.tabs.payment_conditions'),
        tab_id: 2,
      },
      {
        label: t('convention.tabs.operation_prices'),
        tab_id: 3,
      },
      {
        label: t('convention.tabs.worksite'),
        tab_id: 5,
      },
    ];

    if (convention.business_provider?.business_provider_1)
      tabs.push({
        label: t('contract.tabs.business_provider'),
        tab_id: 4,
      });

    return tabs.sort((tabA, tabB) => tabA.tab_id - tabB.tab_id);
  } catch (error) {
    displayError(error.response);
  }
};

export const getInfosGeneral = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetConventionGeneralInfos(id));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateContact = async (
  conventionId: number,
  contactId: number,
  data: any
) => {
  try {
    const response = await axiosClient.post(
      urlPostConventionContact(conventionId, contactId),
      data
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateReferents = async (
  contractType: number,
  contractId: number,
  referent1: IUserType,
  referent2: IUserType | null,
  isConvention: boolean,
  isCreator: boolean
) => {
  const data = {
    contract_type: contractType,
    contact_1: {
      id: referent1.id,
      contact_user_id: referent1.contact_user_id,
      entity_type: referent1.entity_type,
      contact_type: isCreator ? 3 : 1,
    },
    contact_2: !referent2
      ? null
      : {
          id: referent2.id,
          contact_user_id: referent2.contact_user_id,
          entity_type: referent2.entity_type,
          contact_type: isCreator ? 3 : 1,
        },
  };

  const url = isConvention
    ? urlPostConventionReferents(contractId)
    : urlPostContractReferents(contractId);

  try {
    const response = await axiosClient.post(url, data);
    return response.data.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getPaymentConditions = async (id: number) => {
  try {
    const response = await axiosClient.get(
      urlGetAndPutConventionPaymentConditions(id)
    );
    return response.data.data.paymentConditions;
  } catch (error) {
    displayError(error.response);
  }
};

export const getBuyingPrices = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetConventionBuyingPrices(id));
    return response.data.data.conventionsOperations;
  } catch (error) {
    displayError(error.response);
  }
};

export const getConventionWorksites = async (
  conventionId: number,
  numberPage: number,
  resultsPerPage: number
) => {
  const paginationConfig = {
    pagination: {
      page: numberPage,
      perPage: resultsPerPage,
    },
  };
  try {
    const response = await axiosClient.post(
      urlPostConventionWorksites(conventionId),
      paginationConfig
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
    return null;
  }
};

export const duplicateOrUpdateConvention = async (
  conventionId: number,
  update = true
) => {
  try {
    const response = await axiosClient.post(
      update
        ? urlUpdateConvention(conventionId)
        : urlDuplicateConvention(conventionId)
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const processInvitationFromModel = async (
  modelId: number | null,
  validRowDatas: any
) => {
  try {
    const response = await axiosClient.post(
      urlPostInvitationFromModel(Number(modelId)),
      { valid_row_datas: validRowDatas }
    );

    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getConventionModelGeneralInfo = async (id: number) => {
  try {
    const response = await axiosClient.get(
      urlGetConventionModelGeneralInfo(id)
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getConventionModelBuyingPrice = async (id: number) => {
  try {
    const response = await axiosClient.get(
      urlGetConventionModelGeneralInfo(id)
    );
    return response.data.data.convention_operations;
  } catch (error) {
    displayError(error.response);
  }
};

export const getConventionModelTabs = async (
  t: TFunction,
  conventionModel: IConventionModelType
) => {
  try {
    const tabs = [
      {
        label: t('convention.tabs.information'),
        tab_id: 1,
      },
      {
        label: t('convention.tabs.operation_prices'),
        tab_id: 2,
      },
    ];

    if (conventionModel.business_provider)
      tabs.push({
        label: t('contract.tabs.business_provider'),
        tab_id: 3,
      });

    return tabs.sort((tabA, tabB) => tabA.tab_id - tabB.tab_id);
  } catch (error) {
    displayError(error.response);
  }
};

export const getInvitedInstallersList = async (
  id: number,
  status: number | null
) => {
  try {
    const response = await axiosClient.post(
      urlGetInvitedInstallerList(Number(id)),
      { status }
    );

    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getInvitedInstallersForCount = async (id: number) => {
  try {
    const response = await axiosClient.post(
      urlGetInvitedInstallerForCount(Number(id))
    );

    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const unlinkInvitedInstaller = async (
  modelId: number,
  entityId: number | undefined,
  invitationId: number | undefined
) => {
  try {
    const response = await axiosClient.post(
      urlDeleteInvitedInstaller(Number(modelId)),
      { entity_id: entityId, invitation_id: invitationId }
    );

    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const draftMandateContract = async (contractId: number) => {
  try {
    const response = await axiosClient.post(urlCopyContract(contractId));
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getTemporaryContractPayload = async (contractId: number) => {
  try {
    const response = await axiosClient.get(
      urlTemporaryContractPayload(contractId)
    );
    return response.data.data;
  } catch (error) {
    displayError(error.response);
  }
};
