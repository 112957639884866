import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ControlContactList } from '@models/controlContact/components/ControlContactList';
import { ControlContactDetails } from '@models/controlContact/components/ControlContactDetails';

function ControlContact(): JSX.Element {
  const { id } = useParams();
  const isCofracList = useMemo(() => !id, [id]);

  return isCofracList ? (
    <ControlContactList />
  ) : (
    <ControlContactDetails isInProgress={false} />
  );
}

export { ControlContact };
