import { useTranslation } from 'react-i18next';
import { placeholderExample } from '@utils/utils';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { v4 } from 'uuid';
import { InputText } from '@components/atomic/inputs/InputText';
import { emailValidationRegex, phoneNumberRegex } from '@utils/regex';
import { FormAddress } from '@components/formAddress/FormAddress';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  IBeneficiary,
  IBeneficiaryAddress,
} from '@models/beneficiaries/utils/beneficiariesType';
import { initialWorksite } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { GlobalContext } from '@context/globalContext';

interface OneBeneficiaryProps {
  contact: IBeneficiary | undefined;
  isBeneficiary: boolean;
  setIsSameContact: Dispatch<SetStateAction<boolean>>;
  isSameContact: boolean;
}
function OneBeneficiary({
  contact,
  isBeneficiary,
  setIsSameContact,
  isSameContact,
}: OneBeneficiaryProps) {
  const title = isBeneficiary
    ? 'partners.general_infos'
    : 'contract.contact_signataire';

  const { updateBeneficiary, payloadData } = useContext(
    ContractCreationContext
  );
  const { globalEnum } = useContext(GlobalContext);

  const [manualAddress, setManualAddress] = useState<boolean>(false);
  const [autoAddress, setAutoAddress] = useState<IBeneficiaryAddress>(
    initialWorksite.beneficiary.address
  );

  const { t } = useTranslation();
  const placeholders = placeholderExample(t);

  const contactType = isBeneficiary ? 'beneficiary' : 'contact';

  const setBeneficiary = (
    key:
      | 'firstname'
      | 'lastname'
      | 'email'
      | 'civility'
      | 'phone_number'
      | 'function',
    value: string | number
  ) => {
    if (isBeneficiary) {
      updateBeneficiary((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    } else {
      updateBeneficiary((prevState) => {
        const { contact: prevContact } = prevState;

        const newContact = prevContact || initialWorksite.beneficiary;

        return {
          ...prevState,
          contact: { ...newContact, [key]: value },
        };
      });
    }
  };

  const setAddress = (
    key: 'city' | 'postal_code' | 'address',
    value: string
  ) => {
    if (isBeneficiary) {
      updateBeneficiary((prevState) => ({
        ...prevState,
        address: { ...prevState.address, [key]: value },
      }));
    } else {
      updateBeneficiary((prevState) => {
        const { contact: prevContact } = prevState;

        const newContact = prevContact || initialWorksite.beneficiary;

        return {
          ...prevState,
          contact: {
            ...newContact,
            address: { ...newContact.address, [key]: value },
          },
        };
      });
    }
  };

  useEffect(() => {
    setAddress('city', autoAddress.city);
    setAddress('address', autoAddress.address);
    setAddress('postal_code', autoAddress.postal_code);
  }, [autoAddress]);

  useEffect(() => {
    const beneficiaryData = payloadData?.beneficiary_with_contacts?.beneficiary;

    setAutoAddress((prev) => ({
      ...prev,
      address: beneficiaryData?.address.address || '',
      city: beneficiaryData?.address.city || '',
      postal_code: beneficiaryData?.address.postal_code || '',
    }));
  }, [payloadData]);

  const resetValue = useMemo(() => {
    if (contact?.address)
      return `${contact?.address.address || ''}${
        contact?.address.postal_code ? `, ${contact.address.postal_code}` : ''
      } ${contact?.address.city || ''}`;

    return '';
  }, [contact]);

  return (
    <div>
      <p className="w-full font-medium text-[1rem] mb-[1.5rem]">{`${t(
        title
      )}`}</p>
      <div className="flex flex-col gap-3 w-full">
        {!isBeneficiary && (
          <div className="w-full flex gap-3">
            <TextWithRadio
              label={`${t('contract.same_person')}`}
              value=""
              selectValue=""
              setSelectedValue={() => {
                if (!isSameContact) {
                  setIsSameContact(true);
                  updateBeneficiary((prevState) => ({
                    ...prevState,
                    contact: undefined,
                  }));
                }
              }}
              isChecked={isSameContact}
              addClass="w-full"
            />
            <TextWithRadio
              label={`${t('contract.new_person')}`}
              value=""
              selectValue=""
              setSelectedValue={() => {
                if (isSameContact) {
                  setIsSameContact(false);
                  updateBeneficiary((prevState) => ({
                    ...prevState,
                    contact: initialWorksite.beneficiary,
                  }));
                }
              }}
              isChecked={!isSameContact}
              addClass="w-full"
            />
          </div>
        )}
        {(!isSameContact || isBeneficiary) && (
          <div className="flex flex-col gap-3 w-full">
            <div className="flex gap-3 w-full">
              {Object.entries(globalEnum.civility).map((elt) => (
                <TextWithRadio
                  label={elt[1]}
                  value={elt[1] + contactType}
                  selectValue={elt[1] + contactType}
                  setSelectedValue={() =>
                    setBeneficiary('civility', Number(elt[0]))
                  }
                  addClass="w-full"
                  key={v4()}
                  isChecked={contact?.civility === Number(elt[0])}
                />
              ))}
            </div>

            <div className="flex gap-3 w-full">
              <InputText
                id={`beneficiaryFirstname${contactType}`}
                name={`beneficiaryFirstname${contactType}`}
                placeholder={placeholders.FIRSTNAME}
                label={`${t('forms.firstname.placeholder')}`}
                onChange={(e) => setBeneficiary('firstname', String(e))}
                valid={contact && contact?.firstname !== ''}
                required
                value={contact?.firstname || ''}
              />
              <InputText
                id={`beneficiaryLastname${contactType}`}
                name={`beneficiaryLastname${contactType}`}
                placeholder={placeholders.LASTNAME}
                label={`${t('forms.lastname.placeholder')}`}
                onChange={(e) => setBeneficiary('lastname', String(e))}
                valid={contact && contact?.lastname !== ''}
                required
                value={contact?.lastname || ''}
              />
            </div>

            <div className="flex gap-3 w-full">
              <InputText
                id={`beneficiaryPhone${contactType}`}
                name={`beneficiaryPhone${contactType}`}
                placeholder={placeholders.TELEPHONE}
                label={`${t('forms.phone.placeholder')}`}
                onChange={(e) => setBeneficiary('phone_number', String(e))}
                valid={!!(contact && contact?.phone_number !== '')}
                error={
                  !phoneNumberRegex.test(contact?.phone_number || '0102030405')
                }
                required
                value={contact?.phone_number || ''}
              />
              {isBeneficiary ? (
                <InputText
                  id={`beneficiaryEmail${contactType}`}
                  name={`beneficiaryEmail${contactType}`}
                  placeholder={placeholders.EMAIL}
                  label={`${t('forms.email.placeholder')}`}
                  onChange={(e) => setBeneficiary('email', String(e))}
                  valid={!!(contact?.email && contact?.email !== '')}
                  error={
                    !emailValidationRegex.test(
                      contact?.email || 'email@drapo.com'
                    )
                  }
                  required
                  value={contact?.email || ''}
                />
              ) : (
                <InputText
                  id={`beneficiaryFunction${contactType}`}
                  name={`beneficiaryFunction${contactType}`}
                  placeholder={t('convention.tab_info_general.function')}
                  label={`${t('convention.tab_info_general.function')}`}
                  onChange={(e) => setBeneficiary('function', String(e))}
                  valid={contact && contact.function !== ''}
                  required
                  value={contact?.function || ''}
                />
              )}
            </div>
            {isBeneficiary ? (
              <div className="flex gap-3 w-full">
                {!manualAddress ? (
                  <div className="w-full">
                    <div className="text-textGrey text-[.75rem] leading-[.75rem] mb-2">
                      {t('forms.address.placeholder')}
                    </div>
                    <FormAddress
                      setIsManual={setManualAddress}
                      isManual={manualAddress}
                      addressDatas={autoAddress}
                      setAddressDatas={setAutoAddress}
                      resetValue={resetValue}
                    />
                  </div>
                ) : (
                  <div>
                    <InputText
                      id={`address${contactType}`}
                      name={`address${contactType}`}
                      placeholder={t('forms.address.placeholder')}
                      label={`${t('forms.address.placeholder')}`}
                      onChange={(e) => setAddress('address', String(e))}
                      required
                    />
                    <InputText
                      id={`zip${contactType}`}
                      name={`zip${contactType}`}
                      placeholder={t('forms.address.manual.zip')}
                      label={`${t('forms.address.manual.zip')}`}
                      required
                      onChange={(e) => setAddress('postal_code', String(e))}
                    />
                    <InputText
                      id={`city${contactType}`}
                      name={`city${contactType}`}
                      placeholder={t('forms.address.manual.city')}
                      label={`${t('forms.address.manual.city')}`}
                      onChange={(e) => setAddress('city', String(e))}
                      required
                    />
                  </div>
                )}
              </div>
            ) : (
              <InputText
                id={`beneficiaryEmail${contactType}`}
                name={`beneficiaryEmail${contactType}`}
                placeholder={t('forms.email.placeholder')}
                label={`${t('forms.email.placeholder')}`}
                onChange={(e) => setBeneficiary('email', String(e))}
                valid={contact && contact?.email !== ''}
                error={
                  !emailValidationRegex.test(
                    contact?.email || 'email@drapo.com'
                  )
                }
                required
                value={contact?.email || ''}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export { OneBeneficiary };
