import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { transformIsoDate } from '@utils/functions';
import {
  IRevisionStatus,
  IWorksiteOperation,
} from '@models/worksites/utils/worksitesTypes';
import { v4 } from 'uuid';
import { textGrey } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { formatWord } from '@utils/format';

type IWorksiteDetailsOperationCardStepsProps = {
  detailsIsActive: boolean;
  stepsList: { key: string; value: string }[];
  stepActive: number;
  setStepHeight: Dispatch<SetStateAction<number>>;
  revisionStatus?: IRevisionStatus[];
  lastUpdateDate: string;
  operation?: IWorksiteOperation;
};

function WorksiteDetailsOperationCardSteps({
  detailsIsActive,
  stepsList,
  stepActive,
  setStepHeight,
  revisionStatus,
  lastUpdateDate,
  operation,
}: IWorksiteDetailsOperationCardStepsProps): JSX.Element {
  const { t } = useTranslation();
  const cardStep = useRef<HTMLDivElement>(null);

  const stepActiveToUse = stepActive === 51 ? 5.1 : stepActive;

  const [stepName, setStepName] = useState<string>('');

  const revisionStatusToDisplay = (index: number) => {
    if (!revisionStatus) return undefined;

    const element = revisionStatus.find((el) => el.status === index.toString());
    if (element) {
      const createdDate = new Date(element.created_at);
      const now = new Date();

      let daysText;

      // Si les dates correspondent exactement, on affiche "aujourd'hui"
      if (now.toDateString() === createdDate.toDateString()) {
        daysText = t('requests_and_changes.today');
      } else {
        // Sinon, on calcule le nombre de jours
        const differenceInDays =
          Math.floor(
            Math.abs(now.getTime() - createdDate.getTime()) /
              (1000 * 60 * 60 * 24)
          ) + 1;

        // Sélectionner la bonne clé de traduction en fonction du nombre de jours
        const dayKey =
          differenceInDays === 1
            ? 'requests_and_changes.day'
            : 'requests_and_changes.days';
        daysText = `${differenceInDays} ${t(dayKey)}`;
      }

      return {
        date: transformIsoDate(element.created_at),
        days: daysText,
      };
    }

    return undefined;
  };

  const resize = () => {
    if (cardStep && cardStep.current) {
      setStepHeight(cardStep.current.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    resize();
  }, [detailsIsActive]);

  useEffect(() => {
    const stepNameToDisplay = stepsList.find((step) =>
      stepActive === 51
        ? step.key === '5.1'
        : step.key === stepActive.toString()
    );
    if (stepNameToDisplay) {
      if (stepNameToDisplay.value.includes(':')) {
        setStepName(stepNameToDisplay.value.split(':')[1]);
      } else {
        setStepName(stepNameToDisplay.value);
      }
    }
  }, [stepsList, stepActive]);

  return (
    <div>
      {!detailsIsActive ? (
        <div className="flex items-center justify-between py-[.875rem] text-[.875rem] border-t-[1px] border-grey">
          <div>{stepName}</div>
          <div
            className="text-[.875rem]"
            style={{ color: textGrey }}
            data-test-id="op_last_update_step"
          >
            {lastUpdateDate}
          </div>
        </div>
      ) : (
        <div ref={cardStep} className="cursor-default">
          {stepsList.map((step) => {
            const name = step.value.includes(':')
              ? step.value.split(':')
              : step.value;
            const stepIndex = parseFloat(step.key);
            const revision = revisionStatusToDisplay(stepIndex);

            const hideCofrac =
              operation &&
              formatWord(Array.isArray(name) ? name[1] : name).includes(
                'cofrac'
              ) &&
              !operation.operation.have_cofrac;

            let textColor = '';

            if (stepIndex < stepActiveToUse) textColor = 'text-textGrey';

            if (hideCofrac) textColor = 'text-gray-200';

            return (
              <div
                className={`flex items-center justify-between py-[.875rem] text-[.875rem] border-t-[1px] ${
                  stepIndex > stepActiveToUse
                    ? 'border-transparent'
                    : 'border-grey'
                } ${textColor}`}
                key={v4()}
                data-test-id="worksite_op_step"
              >
                {/* quand name est un array afficher le deuxième element sinon afficher tout name */}
                {Array.isArray(name) ? name[1] : name}

                {!hideCofrac && stepIndex <= stepActiveToUse && revision && (
                  <div data-test-id="worksite_op_last_update">
                    {revision.days
                      ? `${revision.date} (${revision.days})`
                      : revision.date}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export { WorksiteDetailsOperationCardSteps };

WorksiteDetailsOperationCardSteps.defaultProps = {
  revisionStatus: undefined,
  operation: undefined,
};
