import { blueOpx, backgroundGrey } from '@assets/color';
import { ChevronDownIcon } from '@assets/images/svgComponents';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { getListChanges } from '@apiRequests/changesRequest';
import { GlobalContext } from '@context/globalContext';
import { ColorCube } from '@components/atomic/ColorCube';
import { convertHexToRGBA } from '@utils/functions';
import { useTranslation } from 'react-i18next';

import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { OneChangeCard } from './OneChangeCard';

interface IListChangesCard {
  loadNote?: boolean;
}

function ListChangesCard({ loadNote }: IListChangesCard) {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const {
    listChanges,
    isLoadingListRequestOrChange,
    numberPageChangesList,
    updateNumberPageChangesList,
    totalChangesList,
    updateListChanges,
    updateTotalChangesList,
    demandIdForNote,
    refreshRequestsAndChanges,
    updateRequestOrChangeTab,
    updateRequestOrChangeEnumInCard,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { route } = useContext(GlobalContext);
  const { t } = useTranslation();

  // Create a new filtered list if demand id sent or return all
  const toLoadListChanges = loadNote
    ? listChanges.filter((demand) => demand.id === demandIdForNote)
    : listChanges;

  const infiniteScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight < scrollHeight + 1 &&
        scrollTop + clientHeight > scrollHeight - 1 &&
        !isLoadingList &&
        totalChangesList > listChanges.length
      ) {
        setIsLoadingList(true);
        const dataPost = {
          pagination: {
            page: numberPageChangesList + 1,
            perPage: 15,
          },
          relation_id: parseInt(route.to.split('/')[2], 10),
          relation_type: 'worksite',
        };
        await getListChanges(
          updateListChanges,
          updateTotalChangesList,
          dataPost,
          setIsLoadingList
        );

        listInnerRef.current.scrollTop = scrollTop - 2.5;
        updateNumberPageChangesList(numberPageChangesList + 1);
      }
    }
  };

  useEffect(() => {
    refreshRequestsAndChanges();
  }, [demandIdForNote]);

  return isLoadingListRequestOrChange ? (
    <div className="flex flex-col space-y-[.5rem]">
      <LoaderSkeleton height="6rem" backgroundColor={backgroundGrey} />
      <LoaderSkeleton height="6rem" />
      <LoaderSkeleton height="6rem" />
      <LoaderSkeleton height="6rem" />
    </div>
  ) : (
    <div
      onScroll={() => infiniteScroll()}
      ref={listInnerRef}
      className="py-[1.5rem] pl-[1rem] flex flex-col space-y-[1rem] overflow-y-auto max-h-[65vh]"
    >
      {toLoadListChanges.map((change) => {
        return (
          <OneChangeCard
            key={v4()}
            lastChange={
              toLoadListChanges.length === toLoadListChanges.indexOf(change) + 1
            }
            change={change}
          />
        );
      })}
      {loadNote && (
        <button
          type="button"
          onClick={() => {
            updateRequestOrChangeTab(null);
            updateRequestOrChangeEnumInCard(
              RequestOrMessageOrChangeEnum.CHANGE
            );
          }}
          className="bg-white rounded-default w-full p-[1rem] pt-0"
        >
          <p className="py-[1px] pl-[1rem] pr-[.25rem] bg-backgroundBody rounded-[4px] flex justify-between m-auto items-center text-[.875rem] mt-[.5rem] font-medium ">
            {t('requests_and_changes.access_to_list_activities')}
            <ColorCube
              size="1rem"
              color={blueOpx}
              opacity
              backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
              numberOrIcon={<ChevronDownIcon className="rotate-[-90deg]" />}
            />
          </p>
        </button>
      )}
      {isLoadingList && (
        <LoaderSkeleton backgroundColor={blueOpx} height=".2rem" />
      )}
    </div>
  );
}

ListChangesCard.defaultProps = {
  loadNote: undefined,
};

export { ListChangesCard };
