/* eslint-disable react/jsx-props-no-spreading */
import {
  blueOpx,
  white,
  greyOpacity20,
  textGrey,
  lightGrey,
  grey,
  blueOpacity,
  red,
  lightRed,
} from '@assets/color';
import { convertHexToRGBA } from '@utils/functions';
import React, {
  cloneElement,
  isValidElement,
  useCallback,
  useContext,
} from 'react';
import _ from 'lodash';
import { THEME_ELEMENTS_TYPES } from '@models/settings/utils/enums';
import { ThemeContext } from '@context/ThemeContext';
import { Loader } from './Loader';

//
// Exemple d'utilisation :
//  <ButtonOpx
//    onClick={() => functionOnclick()}
//    label="Support"
//    type="primary"
//    icon={<SupportSidebarIcon />}
//    active={false}
//    small
//  />
//
export interface IButtonOpxProps {
  label: string;
  onClick?: (e: React.MouseEvent) => void;
  type?: 'primary' | 'secondary' | 'tierciary' | 'tab' | 'nav' | 'danger';
  active?: boolean;
  disabled?: boolean;
  small?: boolean;
  icon?: JSX.Element;
  color?: string;
  backgroundColor?: string;
  backgroundOpacity?: number;
  addClass?: string;
  isSubmitBtn?: boolean;
  formId?: string;
  isLoading?: boolean;
  textJustify?: string;
  dataTestId?: string;
  iconSize?: string;
  isOnlyIcon?: boolean;
}

function ButtonOpx({
  label,
  onClick,
  type,
  active,
  disabled,
  small,
  icon,
  color,
  backgroundColor,
  backgroundOpacity,
  addClass,
  isSubmitBtn,
  formId,
  isLoading,
  textJustify,
  dataTestId,
  iconSize,
  isOnlyIcon,
}: IButtonOpxProps): JSX.Element {
  const { themeData } = useContext(ThemeContext);

  const primaryThemeColors = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.PRIMARY
  );

  const secondaryThemeColors = themeData?.elements?.find(
    (element) => element.element === THEME_ELEMENTS_TYPES.SECONDARY
  );

  let className = `rounded-default ${
    isOnlyIcon ? 'px-[0.25rem] ' : 'px-[1rem] '
  }`;
  className += `${small ? 'py-[0.25rem] ' : 'py-[0.5rem] '}`;
  className += `${disabled ? 'cursor-not-allowed ' : ''}`;

  const primaryColor = primaryThemeColors?.background_color || blueOpx;
  const primaryTextColor = primaryThemeColors?.text_color || white;
  const secondaryColor = secondaryThemeColors?.background_color
    ? convertHexToRGBA(secondaryThemeColors?.background_color, 0.4)
    : blueOpacity;
  const secondaryTextColor = secondaryThemeColors?.text_color;

  let bg;
  let fg;
  let border;
  let fw;
  const inactiveState = (!active || disabled) && !isLoading;

  switch (type) {
    case 'primary':
      fg = inactiveState ? textGrey : primaryTextColor;
      bg = inactiveState ? greyOpacity20 : primaryColor;
      border = inactiveState ? 'transparent' : bg;
      break;
    case 'secondary':
      fg = inactiveState ? lightGrey : textGrey;
      border = grey;
      break;
    case 'tierciary': {
      // Partie "normale"
      const tierciaryPrimaryColor =
        primaryThemeColors?.background_color || blueOpx;
      const tierciaryInactiveState = (!active || disabled) && !isLoading;
      fg = tierciaryInactiveState
        ? lightGrey
        : primaryThemeColors?.text_color || blueOpx;
      bg = white;
      border = tierciaryInactiveState ? 'transparent' : grey;
      fw = 600;
      // ----- PATCH -----
      // Si le texte calculé est littéralement "white",
      // on le remplace par la couleur "primary".
      if ((fg || '').toLowerCase() === 'white') {
        fg = tierciaryPrimaryColor;
      }
      break;
    }
    case 'tab':
    case 'nav':
      fg = inactiveState ? textGrey : secondaryTextColor || primaryColor;
      bg = inactiveState ? 'transparent' : secondaryColor;
      break;
    case 'danger':
      fg = inactiveState ? textGrey : red;
      bg = inactiveState ? 'transparent' : lightRed;
      break;
    default:
      break;
  }
  className += `${bg}`;

  let bgColorToUse = backgroundColor || bg;
  if (backgroundOpacity && bgColorToUse) {
    bgColorToUse = convertHexToRGBA(bgColorToUse, backgroundOpacity / 100);
  }

  const btnStyle = {
    color: color || fg,
    backgroundColor: bgColorToUse,
    border: border ? `1px solid ${bgColorToUse || border}` : 'none',
  };

  const iconWithProps = isValidElement(icon)
    ? cloneElement(icon as JSX.Element, {
        fill: color || fg,
        width: iconSize || '1rem',
        height: iconSize || '1rem',
      })
    : icon;

  const delayedClick = useCallback(
    _.debounce(
      (event: React.MouseEvent<HTMLButtonElement>) =>
        onClick ? onClick(event) : {},
      300,
      { leading: true, trailing: false }
    ),
    [onClick]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick && !isLoading) {
      delayedClick(event);
    }
  };

  return (
    <button
      type={isSubmitBtn ? 'submit' : 'button'}
      className={[className, addClass].join(' ')}
      onClick={(event) => {
        handleClick(event);
      }}
      style={btnStyle}
      disabled={disabled}
      form={formId}
      data-test-id={dataTestId}
    >
      <div
        className={`flex items-center ${textJustify} whitespace-nowrap font-[400]`}
        style={{ color: color || fg, fontWeight: fw }}
      >
        {icon && (
          <span className={isOnlyIcon ? '' : 'mr-[0.625rem] min-w-[1rem]'}>
            {iconWithProps}
          </span>
        )}
        {label}
        {isLoading && (
          <div className="ml-[1rem]">
            <Loader />
          </div>
        )}
      </div>
    </button>
  );
}

export { ButtonOpx };

ButtonOpx.defaultProps = {
  type: 'primary',
  active: true,
  disabled: false,
  small: false,
  icon: null,
  color: '',
  backgroundColor: '',
  backgroundOpacity: undefined,
  addClass: '',
  isSubmitBtn: false,
  formId: '',
  isLoading: false,
  onClick: undefined,
  textJustify: 'justify-center',
  dataTestId: '',
  iconSize: undefined,
  isOnlyIcon: false,
};
