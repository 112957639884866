import { useTranslation } from 'react-i18next';
import { InputText } from '@components/atomic/inputs/InputText';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { FileIcon, AddIcon, TrashIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx, red } from '@assets/color';
import { ILabelValue } from '@globalTypes/globalTypes';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
import SelectedOperations from './SelectedOperations';

function MaterialForm({
  operationsOptions,
  onSubmit,
  formId,
  forceResetSignal,
  operationForced,
  defaultName,
}: {
  operationsOptions: ILabelValue[];
  onSubmit: (formData: FieldValues) => Promise<void>;
  formId: string;
  forceResetSignal?: number;
  operationForced?: ILabelValue;
  defaultName?: string;
}) {
  const { t } = useTranslation();

  const [resetSignalInputSelect, setResetSignalInputSelect] =
    useState<number>(0);
  const [filteredOptions, setFilteredOptions] =
    useState<ILabelValue[]>(operationsOptions);

  const { handleSubmit, setValue, watch, reset } = useFormContext();
  const valuesForm = watch();

  const selectedFiles: File[] = Array.isArray(valuesForm.product_files)
    ? valuesForm.product_files
    : [];
  const selectedOperations = valuesForm.product_operations;

  // --- Gestion de l'upload de la fiche technique ---
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCardClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Récupère l'ensemble des fichiers sélectionnés et les fusionne (limités à 4)
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);
    const existingFiles: File[] = Array.isArray(valuesForm.product_files)
      ? valuesForm.product_files
      : [];
    // Fusion et limitation à 4 fichiers maximum
    const mergedFiles = [...existingFiles, ...newFiles].slice(0, 4);
    setValue('product_files', mergedFiles);
  };

  // Supprime un fichier par son indice
  const handleDeleteFile = (index: number) => {
    const existingFiles: File[] = Array.isArray(valuesForm.product_files)
      ? valuesForm.product_files
      : [];
    const newFiles = existingFiles.filter((_, i) => i !== index);
    setValue('product_files', newFiles);
  };
  // --- Fin de la gestion fiche technique ---

  const handleCheckOperation = (operation: ILabelValue) => {
    if (!selectedOperations) {
      setValue('product_operations', [operation]);
    } else if (
      JSON.stringify(selectedOperations).includes(JSON.stringify(operation))
    ) {
      setValue(
        'product_operations',
        selectedOperations.filter(
          (op: ILabelValue) => op.value !== operation.value
        )
      );
    } else {
      setValue('product_operations', [...selectedOperations, operation]);
    }
    setResetSignalInputSelect((prev) => prev + 1);
    setFilteredOptions(operationsOptions);
  };

  // Fonction de recherche parmi les opérations
  const handleSearch = (keyword: string) => {
    if (keyword === '') {
      setFilteredOptions(operationsOptions);
      return;
    }

    const filtered = operationsOptions.filter((op) => {
      const [code, description] = op.label.split(' - ');
      return (
        code.toLowerCase().includes(keyword.toLowerCase()) ||
        description.toLowerCase().includes(keyword.toLowerCase())
      );
    });
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    if (forceResetSignal) {
      setResetSignalInputSelect(resetSignalInputSelect + 1);
    }
  }, [forceResetSignal]);

  useEffect(() => {
    if (operationForced) {
      setValue('product_operations', [operationForced]);
    } else if (!valuesForm.product_operations) {
      setValue('product_operations', []);
    }
  }, [operationForced]);

  useEffect(() => {
    if (defaultName) {
      setValue('product_name', defaultName);
    }

    return () => {
      reset();
    };
  }, []);

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4 my-8">
        <InputText
          label={t('brand.products.name') || 'Nom du produit'}
          id="product_name"
          name="product_name"
          placeholder={t('brand.products.name') || 'Nom du produit'}
          addClass="w-full"
          required
        />
        <div>
          <div className="flex items-center justify-between mb-4">
            <p className="text-sm font-semibold text-gray-700">
              {t('brand.products.technical_sheet_label')}
            </p>
            <ColorCube
              size="1.5rem"
              numberOrIcon={<AddIcon fill="#ffffff" />}
              color={blueOpx}
              onClick={handleCardClick}
              data-test-id="upload_button"
            />
          </div>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileChange}
            multiple
          />
          {selectedFiles.length > 0 && (
            <div>
              {selectedFiles.map((file, index) => (
                <div
                  key={`${file.name}-${file.lastModified}`}
                  className="mt-2 flex items-center justify-between p-2 border border-borderGrey rounded-default bg-white"
                >
                  <a
                    href={URL.createObjectURL(file)}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center space-x-2"
                  >
                    <FileIcon fill={blueOpx} />
                    <span className="text-sm text-blueOpx">{file.name}</span>
                  </a>
                  <ColorCube
                    size="1.5rem"
                    numberOrIcon={<TrashIcon />}
                    color={red}
                    onClick={() => handleDeleteFile(index)}
                    opacity
                    data-test-id={`delete_button_${file.name}`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <InputText
          label={t('brand.products.comment') || 'Commentaire'}
          id="product_comment"
          name="product_comment"
          placeholder={t('brand.products.comment') || 'Ajoutez un commentaire'}
          addClass="w-full"
        />
        {!operationForced && (
          <>
            <InputSelect
              label={
                t('brand.products.select_operations') ||
                'Sélection des opérations concernées'
              }
              placeholder={
                t('brand.products.select_operations') ||
                'Sélection des opérations'
              }
              dataLabelValue={filteredOptions}
              onSelectLabelValue={handleCheckOperation}
              isMultipleSelect
              required
              isAutoComplete
              defaultChecked={
                selectedOperations
                  ? selectedOperations.map((op: ILabelValue) => op.label)
                  : []
              }
              resetSignal={resetSignalInputSelect}
              callbackOnSearch={handleSearch}
            />
            {selectedOperations && selectedOperations.length > 0 && (
              <SelectedOperations
                selectedOperations={selectedOperations}
                handleCheckOperation={handleCheckOperation}
              />
            )}
          </>
        )}
      </div>
    </form>
  );
}

export default MaterialForm;

MaterialForm.defaultProps = {
  forceResetSignal: undefined,
  operationForced: undefined,
  defaultName: undefined,
};
