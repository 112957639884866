/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { HeaderContext } from '@context/headerContext';

import {
  getLinkedOperations,
  getTransfer,
  getOperationsToLink,
  upsertTransfer,
  updateVolume,
  transferLinkOperation,
  transferUnLinkOperation,
} from '@models/contracts/apiRequests/contractRequests';
import { TransferReference } from '@models/contracts/components/TransferReference';
import {
  ICardOperationType,
  ITransferType,
} from '@models/contracts/utils/contractTypes';
import { OperationList } from '@models/contracts/components/OperationList';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { alphanumericRegex } from '@utils/regex';
import { GlobalContext } from '@context/globalContext';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { CONTRACTS_ROUTES_WITH_ID } from '@utils/routesUrls';

function Transfer() {
  const { t } = useTranslation();
  const { id: transferId } = useParams();
  const navigate = useNavigate();

  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const { updateRoute } = useContext(GlobalContext);

  const [odtNumber, setOdtNumber] = useState<string | null>(null);

  const [transfer, setTransfer] = useState<ITransferType>();
  const [dataLinked, setDataLinked] = useState<ICardOperationType[]>([]);
  const [dataToLink, setDataToLink] = useState<ICardOperationType[]>([]);
  const [sortAndFilterLinked, setSortAndFilterLinked] =
    useState<ISortAndFilterType>();
  const [sortAndFilterToLink, setSortAndFilterToLink] =
    useState<ISortAndFilterType>();

  const [searchLinked, setSearchLinked] = useState<string | null>(null);
  const [searchToLink, setSearchToLink] = useState<string | null>(null);

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState({
    linked: false,
    toLink: false,
  });

  const getData = async (
    sLinked?: string,
    sToLink?: string,
    refreshLinked?: boolean,
    refreshToLink?: boolean,
    loadData = true
  ) => {
    if (loadData) {
      const resTransfer = await getTransfer(Number(transferId));
      setTransfer(resTransfer);
      setIsEdit(!resTransfer.odt_number);
    }
    setIsLoading({
      linked: refreshLinked || false,
      toLink: refreshToLink || false,
    });

    const fetchBoth = sLinked === undefined && sToLink === undefined;
    const fetchLinked = fetchBoth || sLinked !== undefined;
    const fetchToLink = fetchBoth || sToLink !== undefined;

    if (refreshLinked || sLinked) {
      const resLinked = fetchLinked
        ? await getLinkedOperations(
            Number(transferId),
            sLinked !== undefined ? sLinked : searchLinked,
            setIsLoading,
            sortAndFilterLinked
          )
        : await new Promise((myResolve) => {
            myResolve(dataLinked);
          });

      // formattind data Livraison
      const res1 = !fetchLinked
        ? resLinked
        : resLinked
        ? resLinked.data.map((item: any) => {
            return {
              id: item.worksite_operation_id,
              code: item.worksiteOperation.operation.code,
              description: item.worksiteOperation.operation.description,
              precarity: item.worksiteOperation.worksite.precarity,
              volume_classique: item.kwhc_classique,
              volume_precaire: item.kwhc_precaire,
              price: item.prixAchat,
              beneficiary: item.worksiteOperation.worksite.beneficiary,
              installer: item.worksiteOperation.worksite.installer,
              line_id: item.id,
            };
          })
        : [];
      setDataLinked(res1);
    }

    if (refreshToLink || sToLink) {
      const resToLink = fetchToLink
        ? await getOperationsToLink(
            Number(transferId),
            sToLink !== undefined ? sToLink : searchToLink,
            setIsLoading,
            sortAndFilterToLink
          )
        : await new Promise((myResolve) => {
            myResolve(dataToLink);
          });

      // formattind data Ajouter a la livraison
      const res2 = !fetchToLink
        ? resToLink
        : resToLink
        ? resToLink.data.map((item: any) => {
            return {
              id: item.id,
              code: item.operation.code,
              description: item.operation.description,
              precarity: item.worksite.precarity,
              volume_classique: item.volume_classique_restant,
              volume_precaire: item.volume_precaire_restant,
              price: item.prixAchat,
              beneficiary: item.worksite.beneficiary,
              installer: item.worksite.installer,
            };
          })
        : [];
      setDataToLink(res2);
    }
  };

  const handleValidateTransfer = async () => {
    if (odtNumber && odtNumber !== '') {
      await upsertTransfer({
        contract_id: Number(transfer?.contract_id),
        contract_transfer_id: Number(transferId),
        odt_number: odtNumber,
      });
      getData();
    }
  };

  useEffect(() => {
    getData(undefined, undefined, true, true);
  }, [transferId]);

  useEffect(() => {
    if (sortAndFilterLinked)
      getData(undefined, undefined, true, undefined, false);
  }, [sortAndFilterLinked]);

  useEffect(() => {
    if (sortAndFilterToLink)
      getData(undefined, undefined, undefined, true, false);
  }, [sortAndFilterToLink]);

  useEffect(() => {
    updateTitleHeader(`${t('contract.title.sale')}`);
    refreshHeader(true);

    if (transfer) {
      updateRoute({
        to: `${
          CONTRACTS_ROUTES_WITH_ID(transfer.contract_id).CONTRACT_SALE_VIEW
        }#detail`,
        older: [
          `${
            CONTRACTS_ROUTES_WITH_ID(transfer.contract_id).CONTRACT_SALE_VIEW
          }#detail`,
        ],
      });
    }
  }, [transfer]);

  if (!transfer || !transfer.id) return <div />;

  return (
    <div className="w-full items-center justify-center">
      <SubHeader
        leftPart={
          <TabsLeftPart
            titlesList={[
              t('convention.partner_information'),
              t('contract.volume_details'),
            ]}
            onClick={(title) => {
              const tabSelected =
                title === t('contract.volume_details') ? '#detail' : '#infos';
              navigate(
                `${
                  CONTRACTS_ROUTES_WITH_ID(transfer?.contract_id)
                    .CONTRACT_SALE_VIEW
                }${tabSelected}`
              );
            }}
            activeButton={String(t('contract.volume_details'))}
          />
        }
        rightPart={
          <ButtonOpx
            label={`${t('contract.tab_volume_detail.validate_transfer')}`}
            onClick={handleValidateTransfer}
            disabled={
              !isEdit ||
              (isEdit &&
                (odtNumber === null ||
                  odtNumber === '' ||
                  !alphanumericRegex.test(odtNumber)))
            }
          />
        }
      />
      <TransferReference
        data={transfer}
        setOdtNumber={(value) => setOdtNumber(value)}
        isEdit={isEdit}
      />
      <div className="w-full flex flex-wrap">
        <div className="w-full md:w-[50%] md:pr-6 mb-[1.5rem]">
          <OperationList
            model="contract"
            cardTitle={t('transfer.add_to_delivery')}
            buttonList={['edit', 'add']}
            onAdd={async (operationId, volumeClassique, volumePrecaire) => {
              await transferLinkOperation(
                transfer.contract_id,
                transfer.id,
                operationId,
                volumeClassique,
                volumePrecaire
              );
            }}
            data={dataToLink}
            setSearch={(val: string) => {
              setSearchToLink(val);
              getData(undefined, val);
            }}
            refresh={() => getData(undefined, undefined, true, true, true)}
            isEdit={isEdit}
            isLoading={isLoading.toLink}
            onSort={(column, direction) =>
              sortList(column, direction, setSortAndFilterToLink)
            }
            onFilter={(filters) => filterList(filters, setSortAndFilterToLink)}
          />
        </div>
        <div className="w-full md:w-[50%] mb-[1.5rem]">
          <OperationList
            model="contract"
            cardTitle={t('transfer.delivery')}
            buttonList={['edit', 'validate', 'delete']}
            onValidate={async (
              operationId,
              volumeClassique,
              volumePrecaire
            ) => {
              await updateVolume(operationId, volumeClassique, volumePrecaire);
            }}
            onDelete={async (operationId) => {
              await transferUnLinkOperation(transfer.id, operationId);
            }}
            data={dataLinked}
            setSearch={(val: string) => {
              setSearchLinked(val);
              getData(val, undefined);
            }}
            refresh={() => getData(undefined, undefined, true, true, true)}
            isEdit={isEdit}
            isLoading={isLoading.linked}
            onSort={(column, direction) =>
              sortList(column, direction, setSortAndFilterLinked)
            }
            onFilter={(filters) => filterList(filters, setSortAndFilterLinked)}
          />
        </div>
      </div>
    </div>
  );
}

export { Transfer };
