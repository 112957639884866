import { red } from '@assets/color';
import { AddFatIcon, TrashIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ColorCube } from '@components/atomic/ColorCube';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { IFormValuesStoreCheckpoint } from '@models/checkpoints/utils/checkpointsTypes';
import { CHECKPOINT_RESPONSE_TYPE } from '@models/settings/utils/enums';
import { InputTypes } from '@utils/utils';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function ResponseArrayInputs() {
  const { t } = useTranslation();

  const methods = useFormContext<IFormValuesStoreCheckpoint>();
  const { control, getValues, setValue } = methods;
  const formValues = getValues();

  const valuesName = 'response_values';

  const responseValues = formValues.response_values;

  const { fields, append, remove } = useFieldArray({
    control,
    name: valuesName,
  });

  const translationBase = 'settings.checkpoints.modal_add_checkpoint';

  const isList = formValues.response_type === CHECKPOINT_RESPONSE_TYPE.LIST;

  return (
    <div className="flex flex-col gap-[1.5rem]">
      {fields.map((val, index) => {
        let placeholder = `${t(`${translationBase}.choice`, {
          index: index + 1,
        })}`;

        if (!isList) {
          placeholder =
            index === 0 ? `${t('global.yes')}` : `${t('global.no')}`;
        }

        return (
          <div className="flex items-center gap-[1.5rem]" key={val.id}>
            <div className="w-[60%] flex items-center gap-[1rem]">
              <DynamicInputEdit
                isEditMode
                inputType={InputTypes.TEXT}
                name={`${valuesName}.${index}.value`}
                label={`${t(`${translationBase}.value`, {
                  index: index + 1,
                })}`}
                placeholder={placeholder}
                required
                addClass="w-full"
              />
              {isList && fields.length > 2 && (
                <ColorCube
                  size="2.5rem"
                  numberOrIcon={<TrashIcon />}
                  color={red}
                  onClick={() => remove(index)}
                  opacity
                  addClass="mt-[1rem]"
                />
              )}
            </div>

            <div className="w-[40%] flex justify-center">
              <div className="flex flex-col items-center">
                <p
                  className="text-textGrey text-[.75rem] leading-3 mb-2"
                  style={{
                    opacity: index === 0 ? 1 : 0,
                  }}
                >
                  {t(`${translationBase}.non_conformity_indicator`)}
                </p>
                <div className="p-[.75rem] border border-borderGrey rounded-default">
                  <Checkbox
                    label={`${t(`${translationBase}.value`, {
                      index: index + 1,
                    })}`}
                    checked={responseValues[index]?.valid === false}
                    onCheck={() => {
                      const checked = responseValues[index]?.valid === false;
                      if (!isList) {
                        const otherIndex = index === 0 ? 1 : 0;
                        setValue(`${valuesName}.${index}.valid`, checked);
                        setValue(`${valuesName}.${otherIndex}.valid`, true);
                      } else {
                        setValue(`${valuesName}.${index}.valid`, checked);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {isList && (
        <div className="w-[60%] p-[.7rem]">
          <ButtonOpx
            label={t(`${translationBase}.add_value`)}
            onClick={() => append({ value: '', valid: true })}
            type="tab"
            icon={<AddFatIcon />}
            addClass="w-full"
            disabled={
              responseValues && responseValues.some((val) => !val.value)
            }
          />
        </div>
      )}
    </div>
  );
}

ResponseArrayInputs.defaultProps = {
  checkpoint: undefined,
};

export default ResponseArrayInputs;
