import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonOpx } from '../atomic/ButtonOpx';

// Fonction pour générer une clé hash basée sur les titres
const generateStorageKey = (titlesList: string[], currentUrl: string) => {
  return `tab_${currentUrl}_${titlesList
    .join('_')
    .replace(/\s+/g, '')
    .toLowerCase()}`;
};

const clearTabStorage = () => {
  // Cette fonction parcourt localStorage et supprime tout ce qui correspond à des clés d'onglet
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('tab_')) {
      localStorage.removeItem(key);
    }
  });
};

interface ITabsLeftPartProps {
  onClick: (title: string, index: number) => void;
  backgroundColorButton?: string;
  textColorButton?: string;
  icon?: JSX.Element;
  activeButton?: string;
  smallButton?: boolean;
  addClass?: string;
  labelValueList?: { label: string; value: string; icon?: JSX.Element }[];
  titlesList: string[];
  multipleIdenticalTitles?: boolean;
}

function TabsLeftPart({
  titlesList,
  onClick,
  addClass,
  backgroundColorButton,
  textColorButton,
  icon,
  activeButton,
  smallButton,
  labelValueList,
  multipleIdenticalTitles,
}: ITabsLeftPartProps): JSX.Element {
  const location = useLocation();
  const storageKey = generateStorageKey(titlesList, location.pathname);
  const initialActiveButton =
    localStorage.getItem(storageKey) || activeButton || titlesList[0];
  const [buttonActive, setButtonActive] = useState<string>(initialActiveButton);

  const handleClick = (title: string, index: number) => {
    const key = multipleIdenticalTitles ? `${title}_${index}` : title;
    localStorage.setItem(storageKey, key);
    setButtonActive(key);
    onClick(title, index);
  };

  useEffect(() => {
    const storedActiveButton =
      localStorage.getItem(storageKey) || activeButton || titlesList[0];
    setButtonActive(storedActiveButton);
  }, [titlesList, activeButton, storageKey]);

  useEffect(() => {
    if (localStorage.getItem(storageKey)) {
      if (
        (activeButton && activeButton !== localStorage.getItem(storageKey)) ||
        (!activeButton && titlesList[0] !== localStorage.getItem(storageKey))
      ) {
        onClick(
          localStorage.getItem(storageKey) || '',
          titlesList.indexOf(localStorage.getItem(storageKey) || '')
        );
      }
    }
  }, [titlesList]);

  const tab = (label: string, value: string, iconToDisplay?: JSX.Element) => {
    const key = multipleIdenticalTitles ? `${label}_${value}` : label;

    return (
      <div key={key} className="p-[0.5rem]">
        <ButtonOpx
          label={label}
          onClick={() => handleClick(label, Number(value))}
          type="nav"
          active={buttonActive === key}
          backgroundColor={backgroundColorButton}
          color={textColorButton}
          icon={iconToDisplay}
          small={smallButton}
          dataTestId="button_sub_header"
        />
      </div>
    );
  };

  return (
    <div
      className={[
        'flex items-center flex-wrap -mx-[0.5rem] -my-[0.5rem]',
        addClass,
      ].join(' ')}
    >
      {!labelValueList
        ? titlesList.map((title: string, index) => {
            return tab(title, String(index), icon);
          })
        : labelValueList.map((item) => {
            return tab(item.label, item.value, item.icon);
          })}
    </div>
  );
}

export { TabsLeftPart, clearTabStorage };

TabsLeftPart.defaultProps = {
  backgroundColorButton: '',
  textColorButton: '',
  icon: null,
  activeButton: '',
  smallButton: false,
  addClass: '',
  labelValueList: undefined,
  multipleIdenticalTitles: false,
};
