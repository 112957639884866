/* eslint-disable no-nested-ternary */

import { useTranslation } from 'react-i18next';
import { TextWithRadio } from './TextWithRadio';

interface IBoolRadioProps {
  label: string;
  /**
   * Valeur actuelle sélectionnée.
   */
  value?: boolean;
  /**
   * Fonction appelée lors de la sélection d'une nouvelle valeur.
   *
   * @param value La nouvelle valeur (true ou false).
   */
  onChange: (value: boolean) => void;
  /**
   * Texte personnalisé pour le bouton "Oui".
   */
  yesLabel?: string;
  /**
   * Texte personnalisé pour le bouton "Non".
   */
  noLabel?: string;
  /**
   * Indique si le composant doit être désactivé.
   */
  disabled?: boolean;
  /**
   * Indique si le composant est obligatoire.
   */
  required?: boolean;
}

/**
 * Composant BoolRadio
 *
 * Ce composant affiche une question avec deux options (Oui/Non)
 * sous forme de boutons radios. Il est totalement contrôlé via
 * la prop "value" et notifie le changement grâce à "onChange".
 *
 * @param {IBoolRadioProps} props Propriétés du composant.
 * @returns {JSX.Element} L'élément JSX du composant.
 */
function BoolRadio({
  label,
  value,
  onChange,
  yesLabel,
  noLabel,
  required,
  disabled,
}: IBoolRadioProps): JSX.Element {
  const { t } = useTranslation();

  /**
   * Gestion de la sélection d'une valeur.
   *
   * @param {string} val Valeur sélectionnée ('true' ou 'false').
   */
  function handleSelection(val: string): void {
    if (disabled) return;
    if (val === 'true') {
      onChange(true);
    } else if (val === 'false') {
      onChange(false);
    }
  }

  // Conversion de la valeur boolean en string pour la gestion de l'état
  const selectValue: string =
    value !== undefined && value !== null ? (value ? 'true' : 'false') : '';

  return (
    <div className="flex flex-col space-y-2">
      <label
        className={[`flex flex-col text-textGrey text-[.625rem] relative`].join(
          ' '
        )}
      >
        {label && (
          <div className="text-textGrey text-[.75rem] leading-[.75rem]">
            {label}
            {!required && ` (${t('global.optional')})`}
          </div>
        )}
      </label>
      <div className="grid grid-cols-2 gap-4">
        <TextWithRadio
          label={yesLabel || t('global.yes')}
          value="true"
          addClass="flex-grow"
          isChecked={selectValue === 'true'}
          setSelectedValue={(val) => handleSelection(val)}
          withoutName
          disabled={disabled}
          selectValue={selectValue}
        />
        <TextWithRadio
          label={noLabel || t('global.no')}
          value="false"
          addClass="flex-grow"
          isChecked={selectValue === 'false'}
          setSelectedValue={(val) => handleSelection(val)}
          withoutName
          disabled={disabled}
          selectValue={selectValue}
        />
      </div>
    </div>
  );
}

export default BoolRadio;

BoolRadio.defaultProps = {
  yesLabel: undefined,
  noLabel: undefined,
  disabled: false,
  required: true,
  value: undefined,
};
