import { HeaderContext } from '@context/headerContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalContext } from '@context/globalContext';
import { CardFinancialData } from '@models/partners/components/informations/cards/CardFinancialData';
import CustomFieldsTab from '@models/settings/components/CustomFieldsTab';
import IframesTab from '@models/settings/components/iframe/IframesTab';
import SettingsTabsNav from '@models/settings/components/SettingsTabsNav';
import ContactControlSettings from '@models/controlContact/components/settings/ContactControlSettings';
import CustomCheckpoints from '@models/settings/components/CustomCheckpoints';
import SubscriptionAndPaymentTab from '@models/settings/components/SubscriptionAndPaymentTab';
import { WhiteLabel } from '@models/settings/components/whiteLabel/WhiteLabel';
import { SETTINGS_TABS } from '@models/settings/utils/utils';
import { Brands } from '@pages/Brands';
import { useLocation } from 'react-router-dom';

function Settings() {
  const { t } = useTranslation();
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const { userView } = useContext(GlobalContext);
  const location = useLocation();
  const hash = location.hash.substring(1);
  const defaultTab = () => {
    if (Object.values(SETTINGS_TABS).includes(hash)) {
      return hash;
    }
    return SETTINGS_TABS.CUSTOM_FIELDS;
  };

  const [tabActive, setTabActive] = useState<string>(defaultTab());

  useEffect(() => {
    updateTitleHeader(`${t('sidebar.settings')}`);
    refreshHeader();
  }, []);

  const renderTab = () => {
    switch (tabActive) {
      case SETTINGS_TABS.SUBSCRIPTION_AND_PURCHASE:
        return <SubscriptionAndPaymentTab />;
      case SETTINGS_TABS.FINANCIAL_DATA:
        return (
          <div className="w-full">
            <CardFinancialData entityId={Number(userView?.entity_id)} />
          </div>
        );
      case SETTINGS_TABS.CUSTOM_FIELDS:
        return <CustomFieldsTab />;
      case SETTINGS_TABS.BRANDS:
        return <Brands />;
      case SETTINGS_TABS.WHITE_LABEL:
        return <WhiteLabel />;
      case SETTINGS_TABS.IFRAMES:
        return <IframesTab />;
      case SETTINGS_TABS.CONTACT_CONTROL:
        return <ContactControlSettings />;
      case SETTINGS_TABS.CHECKPOINTS:
        return <CustomCheckpoints />;
      default:
        return <SubscriptionAndPaymentTab />;
    }
  };

  return (
    <div className="w-full flex space-x-6 pt-6">
      <SettingsTabsNav tabActive={tabActive} setTabActive={setTabActive} />
      {renderTab()}
    </div>
  );
}

export { Settings };
