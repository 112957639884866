import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { urlDownloadModelInvitationInstallerTemplate } from '@api/apiUrls';
import { UploadCard } from '@components/atomic/UploadCard';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { setValidatedFile } from '@models/partners/apiRequests/partnersRequest';
import { GlobalContext } from '@context/globalContext';
import { useParams } from 'react-router-dom';
import { processInvitationFromModel } from '../apiRequests/conventionRequests';

interface ModalConventionModelInstallerInvitationProps {
  setModal: Dispatch<SetStateAction<boolean>>;
  onUpdateList: () => void; // Function to update the list in <InstallerFollowUpCard />
}

function ModalConventionModelInstallerInvitation({
  setModal,
  onUpdateList,
}: ModalConventionModelInstallerInvitationProps): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();

  const [uploadFile, setUploadFile] = useState<File>();
  const [fileValid, setFileValid] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [countInvited, setCountInvited] = useState<number>(0);
  const [countRejected, setCountRejected] = useState<number>(0);
  const [countProcessedLines, setCountProcessedLines] = useState<number>(0);
  const { userView } = useContext(GlobalContext);
  const [rejectedEmailDescription, setRejectedEmailDescription] = useState<
    string[]
  >([]);
  const [rejectedSiretDescription, setRejectedSiretDescription] = useState<
    string[]
  >([]);
  const [disbarredSiretDescription, setDisbarredSiretDescription] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onUpload = (file: File) => {
    setFileValid(false);
    setUploadFile(file);
  };

  const loadValidButton = () => {
    setIsProcess(false);
    setFileValid(false);
  };

  const processInvitation = async () => {
    if (userView) {
      if (uploadFile) {
        setIsProcess(false);
        setLoading(true);

        const resValidatedFile = await setValidatedFile(Number(id), uploadFile);

        if (resValidatedFile && resValidatedFile.proceed) {
          // Call processInvitationFromModel with updated valid_row_datas
          const modelId = Number(id);
          const res = await processInvitationFromModel(
            modelId,
            resValidatedFile.valid_row_datas || []
          );

          if (res) {
            setCountInvited(Number(resValidatedFile.count_invited));
            setCountRejected(Number(resValidatedFile.count_rejected));
            setRejectedEmailDescription(
              resValidatedFile.invalid_email_descr || []
            );
            setRejectedSiretDescription(
              resValidatedFile.invalid_siret_descr || []
            );
            setDisbarredSiretDescription(
              resValidatedFile.disbarred_siret_descr || []
            );
            setCountProcessedLines(
              Number(resValidatedFile.total_lines_process)
            );
            setIsProcess(true);
            onUpdateList();
          }
        }
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('partners.installer_invitation.modal.header_title_upload')}
      backgroundTransparent
      sidebarVisible
      setIsModal={setModal}
    >
      <div className="mt-2 flex flex-col gap-3">
        <p className="mt-0 mb-3">
          {t('partners.installer_invitation.modal.header_text_upload')}
        </p>
        <a
          href={`${process.env.PUBLIC_URL}${urlDownloadModelInvitationInstallerTemplate}`}
          target="_blank"
          rel="noreferrer"
          download
          className="mb-3 font-[600] cursor-pointer"
          style={{ color: 'blue' }}
        >
          {t('partners.installer_invitation.modal.link_download_template')}
        </a>
        <span className="font-300">
          {t('partners.installer_invitation.modal.input_file_title')}
        </span>

        {/* Input d'upload du document */}
        <UploadCard
          fileType=".xlsx"
          onUpload={onUpload}
          isUploadFile={!uploadFile}
          fileName={
            uploadFile
              ? uploadFile.name
              : t('partners.installer_invitation.modal.input_file_placeholder')
          }
          loadValidButton={loadValidButton}
        />
        {isProcess ? (
          <div className="pt-[0.75rem] flex items-center justify-end space-x-[1rem]">
            <div>
              <ButtonOpx
                label={`${t('global.btn_close')}`}
                type="primary"
                onClick={() => setModal(false)}
                addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
              />
            </div>
          </div>
        ) : (
          <div className="pt-[0.75rem] flex items-center justify-end space-x-[1rem]">
            <div>
              <ButtonOpx
                label={`${t('buttons.cancel')}`}
                type="secondary"
                onClick={() => setModal(false)}
                addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
              />
            </div>
            <div>
              <ButtonOpx
                label={`${t('buttons.send')}`}
                type="primary"
                onClick={processInvitation}
                addClass="px-[1rem] py-[0.625rem] !px-[3.5rem]"
                isLoading={loading}
                disabled={!uploadFile || fileValid || isProcess}
              />
            </div>
          </div>
        )}
        {isProcess && (
          <>
            {countProcessedLines === 0 && (
              <div className="mt-2 text-[1rem] p-4 border border-borderGrey rounded-default flex text-red items-center">
                {t('convention.step_invitation.info.none')}
              </div>
            )}
            {countInvited > 0 && (
              <div className="mt-2 text-[1rem] p-4 border border-borderGrey rounded-default flex text-primaryText items-center">
                {countInvited} {t('global.out_of')} {countProcessedLines}{' '}
                {t('convention.step_invitation.info.invited')}
              </div>
            )}
            {countRejected > 0 && (
              <div className="mt-1 text-[1rem] grid grid-cols-1 gap-y-6 p-4 border border-borderGrey rounded-default items-center">
                <div className="flex w-full text-red">
                  {countRejected} {t('global.out_of')} {countProcessedLines}{' '}
                  {t('convention.step_invitation.info.rejected')}
                </div>

                <div className="w-full flex-wrap grid grid-cols-1 px-[.5rem] md:grid-cols-[14%_2%_84%]">
                  <div className="text-red w-[8rem]">
                    {t('convention.model.installer_invitation.incorrect_siret')}
                  </div>
                  <div>{' : '}</div>
                  <div className="float-left">
                    {rejectedSiretDescription.length > 0
                      ? rejectedSiretDescription.map((info) => (
                          <p className="text-black" key={info}>
                            {info}
                          </p>
                        ))
                      : t('global.none')}
                  </div>
                </div>
                <div className="w-full flex-wrap grid grid-cols-1 px-[.5rem] md:grid-cols-[14%_2%_84%]">
                  <div className="text-red">
                    {t('convention.model.installer_invitation.disbarred_siret')}
                  </div>
                  <div>{' : '}</div>
                  <div className="float-left">
                    {disbarredSiretDescription.length > 0
                      ? disbarredSiretDescription.map((info) => (
                          <p className="text-black" key={info}>
                            {info}
                          </p>
                        ))
                      : t('global.none')}
                  </div>
                </div>
                <div className="w-full flex-wrap grid grid-cols-1 px-[.5rem] md:grid-cols-[14%_2%_84%]">
                  <div className="text-red">
                    {t('convention.model.installer_invitation.incorrect_email')}
                  </div>
                  <div>{' : '}</div>
                  <div className="float-left">
                    {rejectedEmailDescription.length > 0
                      ? rejectedEmailDescription.map((info) => (
                          <p className="text-black" key={info}>
                            {info}
                          </p>
                        ))
                      : t('global.none')}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export { ModalConventionModelInstallerInvitation };
