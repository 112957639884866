import { Modal } from '@components/Modal';
import { IElementData, IImageData } from '@models/settings/utils/settingsTypes';
import authImg from '@assets/images/auth_bg_logo.svg';
import { placeholderExample } from '@utils/utils';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoOpxIconBlueFill } from '@assets/images/svgComponents';
import { InputText } from '@components/atomic/inputs/InputText';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import {
  THEME_ELEMENTS_TYPES,
  THEME_IMAGE_TYPES,
} from '@models/settings/utils/enums';

interface LoginPageElementsPreviewProps {
  images: IImageData[];
  elements: IElementData[];
  loginButtonText: string;
  setShowLoginPageElementsPreview: Dispatch<SetStateAction<boolean>>;
  themeCompanyName: string | undefined;
}

function LoginPageElementsPreview({
  images,
  elements,
  loginButtonText,
  setShowLoginPageElementsPreview,
  themeCompanyName,
}: LoginPageElementsPreviewProps) {
  const { t } = useTranslation();
  const placeholders = placeholderExample(t);

  const logoAuthCustom = useMemo(() => {
    return images.find((img) => img.image === THEME_IMAGE_TYPES.LOGO_AUTH)
      ?.custom;
  }, [images]);

  const backgroundImageCustom = useMemo(() => {
    return images.find(
      (img) => img.image === THEME_IMAGE_TYPES.BACKGROUND_IMAGE_AUTH
    )?.custom;
  }, [images]);

  const loginButtonCustom = useMemo(() => {
    return elements.find(
      (elem) => elem.element === THEME_ELEMENTS_TYPES.PRIMARY
    );
  }, []);

  return (
    <Modal
      title=""
      btnCancelIsIcon
      lightHeader
      setIsModal={setShowLoginPageElementsPreview}
      isFullscreen
    >
      <div className="h-screen bg-backgroundBody flex items-center justify-center w-screen">
        <div className="form_container min-w-[90%] lg:min-w-[70rem] mx-auto">
          <div className="rounded-[16px] overflow-hidden bg-white mb-8 border border-borderGrey relative flex">
            <div className="absolute top-[1.5rem] left-[1.5rem] z-[1000]">
              {images && logoAuthCustom ? (
                <img
                  src={URL.createObjectURL(logoAuthCustom)}
                  alt="logo"
                  className="w-[3rem] h-[3rem]"
                />
              ) : (
                <LogoOpxIconBlueFill width="3rem" height="3rem" />
              )}
            </div>
            <div className="lg:px-[6rem] px-10 py-[6rem] flex items-center flex-grow ">
              <div className="w-full lg:max-w-[35rem]">
                <p className="mb-6 text-[1.6875rem] font-medium leading-[2.5rem]">
                  {themeCompanyName
                    ? t('auth.form_title_white_label', {
                        companyName: themeCompanyName,
                      })
                    : t('auth.form_title_opx')}
                </p>
                <form id="form-login">
                  <div className="space-y-6">
                    <div className="space-y-4">
                      <p className="text-base font-medium leading-5">
                        {t('auth.registration.forms.email')}
                      </p>
                      <InputText
                        id="email"
                        name="email"
                        placeholder={placeholders.EMAIL}
                        required
                        hideLabel
                        data-test-id="email"
                      />
                    </div>
                    <div className="space-y-4">
                      <p className="text-[1rem] font-medium leading-5">
                        {t('forms.password.placeholder')}
                      </p>
                      <InputText
                        id="password"
                        name="password"
                        placeholder={placeholders.PASSWORD}
                        required
                        type="password"
                        hideLabel
                        data-test-id="password"
                      />
                    </div>
                    <div className="font-medium block w-full text-sm text-right">
                      {t('auth.forget_pwd.tooltip')}
                    </div>
                    <ButtonOpx
                      label={loginButtonText}
                      formId="form-login"
                      dataTestId="login_button"
                      addClass="w-full"
                      backgroundColor={loginButtonCustom?.background_color}
                      color={loginButtonCustom?.text_color}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="ml-auto pr-6 py-6 hidden lg:block min-h-[40rem]">
              <div className="bg-blueOpx h-full rounded-[1rem] min-w-[22rem] w-[22rem] flex items-end overflow-hidden">
                <img
                  src={
                    backgroundImageCustom
                      ? URL.createObjectURL(backgroundImageCustom)
                      : authImg
                  }
                  className="w-full"
                  alt="auth background"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LoginPageElementsPreview;
