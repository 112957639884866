/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { textGrey } from '@assets/color';
import { AddFatIcon, FileIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Tag } from '@components/atomic/Tag';
import { Card } from '@components/Card';
import { IColumn, IRow, ListTable } from '@components/ListTable';
import { GlobalContext } from '@context/globalContext';
import { createColumnHelper } from '@tanstack/react-table';
import { ENTITY_TYPES } from '@utils/roles';
import { SETTINGS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getIframes } from '../../apiRequests/settingsRequests';
import { IIframe } from '../../utils/settingsTypes';
import NewIframeModal from './NewIframeModal';

/**
 * Composant IframesTab
 * Ce composant affiche la liste des iframes créées par l'utilisateur.
 */
function IframesTab() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userView } = useContext(GlobalContext);
  const [showNewIframeModal, setShowNewIframeModal] = useState(false);
  const [iframes, setIframes] = useState<IRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Vérifier si l'utilisateur est un installateur
  const isInstaller = userView?.entity_type === ENTITY_TYPES.INSTALLATEUR;

  const columnHelper = createColumnHelper<IIframe>();

  // Définition des colonnes du tableau
  const columns = useMemo(
    () => {
      const baseColumns = [
        columnHelper.accessor('name', {
          header: () => t('settings.iframes.iframe_name'),
        }),
      ];

      // Ajouter la colonne convention_reference uniquement pour les installateurs
      if (isInstaller) {
        baseColumns.push(
          columnHelper.accessor('convention_reference', {
            header: () => t('settings.iframes.reference'),
            cell: (info) => {
              return <Tag color={textGrey} label={info.getValue() || ''} />;
            },
          })
        );
      }

      // Ajouter la colonne d'actions
      baseColumns.push(
        columnHelper.accessor('id', {
          header: () => t('global.actions'),
          cell: (info) => (
            <div className="flex gap-2">
              <ButtonOpx
                label={t('settings.iframes.see_code') || 'Voir le code'}
                icon={<FileIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    SETTINGS_ROUTES_WITH_ID(info.getValue()).IFRAME_CODE
                  );
                }}
                small
              />
            </div>
          ),
        }) as IColumn
      );

      return baseColumns;
    },
    [t, navigate, isInstaller] // Ajout de isInstaller comme dépendance
  );

  // Fonction pour récupérer les données des iframes
  const fetchIframes = async () => {
    await getIframes(setIframes, setIsLoading);
  };

  useEffect(() => {
    // Charger la liste des iframes au chargement du composant
    fetchIframes();
  }, []);

  return (
    <>
      <Card
        title={t('settings.iframes.iframe_list')}
        actionButtons={
          <ButtonOpx
            label={t('settings.iframes.new_iframe')}
            icon={<AddFatIcon />}
            onClick={() => setShowNewIframeModal(true)}
            small
          />
        }
        addClass="w-full"
      >
        <ListTable
          loading={isLoading}
          columns={columns as IColumn[]}
          callBackOnRowClick={(e: IRow) => {
            const rowData = e.original as IRow;
            navigate(
              SETTINGS_ROUTES_WITH_ID(rowData.id as string)
                .SETTINGS_IFRAME_SETTING
            );
          }}
          data={iframes}
          addClass="!border-transparent"
        />
      </Card>

      {showNewIframeModal && (
        <div>
          <NewIframeModal setIsOpen={setShowNewIframeModal} />
        </div>
      )}
    </>
  );
}

export default IframesTab;
