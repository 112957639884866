import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertHexToRGBA } from '@utils/functions';
import { red } from '@assets/color';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAddControlContactSchema } from '@utils/validationSchemas';
import { PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { controlContactLotSource } from '../utils/utlis';
import {
  getCofracListControlled,
  getOperationsToControlContact,
  storeControlContactFromCofrac,
  storeControlContactFromOperation,
} from '../apiRequests/controlContactRequests';
import {
  cofracControlledListFakeData,
  operationsToControlContactFakeData,
} from '../utils/fakeData';
import { IControlContactOperationTypeType } from '../utils/controlContactTypes';

interface AddControlContactProps {
  setModal: Dispatch<SetStateAction<boolean>>;
}

function AddControlContact({ setModal }: AddControlContactProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(getAddControlContactSchema(t)),
  });
  const { setValue, handleSubmit, formState, clearErrors, watch, unregister } =
    methods;
  const { errors, isSubmitted } = formState;
  const valuesForm = watch();
  const choiceLotSource = valuesForm.lot_source;
  const isChecked = (value: any) => valuesForm.lot_source === value;

  const [eligibleOperations, setEligibleOperations] = useState<
    IControlContactOperationTypeType[]
  >([]);
  const [cofracControlledList, setCofracControlledList] = useState<
    { id: number; name: string }[]
  >([]);

  const fetchEligibleOperations = async () => {
    const response = await getOperationsToControlContact();
    if (response) {
      setEligibleOperations(response);
    } else {
      setEligibleOperations(operationsToControlContactFakeData);
    }
  };

  const fetchCofracControlledList = async () => {
    const response = await getCofracListControlled();
    if (response) {
      setCofracControlledList(response);
    } else {
      setCofracControlledList(cofracControlledListFakeData);
    }
  };

  const onSubmit = (fieldValues: FieldValues) => {
    if (valuesForm.cofrac_id) {
      storeControlContactFromCofrac(fieldValues).then((res: any) => {
        if (res)
          navigate(
            PARTNERS_ROUTES_WITH_ID(res.contact_lot_id).CONTROL_CONTACT_VIEW
          );
      });
    } else if (valuesForm.operation_id) {
      storeControlContactFromOperation(fieldValues).then((res: any) => {
        if (res)
          navigate(
            PARTNERS_ROUTES_WITH_ID(res.contact_lot_id).CONTROL_CONTACT_LOCKED
          );
      });
    }
  };

  const disableBtnConfirm = () => {
    // Le nom doit être saisie
    // Un lot cofrace ou une opération doit être sélectionné
    return !valuesForm.operation_id && !valuesForm.cofrac_id;
  };

  console.log(methods.formState.errors);

  useEffect(() => {
    fetchEligibleOperations();
    fetchCofracControlledList();
  }, []);

  return (
    <div>
      <FormProvider {...methods}>
        <Modal
          title={t('control_contact.form.title')}
          backgroundTransparent
          sidebarVisible
          textBtnConfirm={String(t('buttons.validate'))}
          btnCancel
          buttonsPosition="bottom"
          setIsModal={setModal}
          btnConfirmDisabled={disableBtnConfirm()}
          formId="form-control-contact-creation"
          onConfirmClick={() => console.log(watch())}
        >
          <form
            className="mt-5 flex flex-col gap-3"
            onSubmit={handleSubmit(onSubmit)}
            id="form-control-contact-creation"
          >
            <InputText
              id="name"
              name="name"
              placeholder={`${t('control_contact.form.internal_reference')}`}
              required
            />
            <div>
              <p className="text-textGrey text-[.75rem] leading-[.75rem] mb-2">
                {t('control_contact.form.lot_source')}
              </p>
              <div className="grid grid-cols-2 gap-4">
                <TextWithRadio
                  label={t('control_contact.form.lot_source_cofrac')}
                  value={controlContactLotSource.COFRAC}
                  addClass="flex-grow"
                  selectValue={choiceLotSource}
                  isChecked={isChecked(controlContactLotSource.COFRAC)}
                  setSelectedValue={(e) => {
                    setValue('lot_source', e);
                    unregister('operation_id');
                  }}
                  withoutName
                />
                <TextWithRadio
                  label={t(
                    'control_contact.form.lot_source_cofrac_operation_no_cofrac'
                  )}
                  value={controlContactLotSource.OPERATION}
                  addClass="flex-grow"
                  selectValue={choiceLotSource}
                  isChecked={isChecked(controlContactLotSource.OPERATION)}
                  setSelectedValue={(e) => {
                    setValue('lot_source', e);
                    unregister('cofrac_id');
                  }}
                  withoutName
                />
              </div>
            </div>
            {choiceLotSource === controlContactLotSource.OPERATION && (
              <InputSelect
                placeholder={`${t(
                  'control_contact.form.placeholder_operation'
                )}`}
                dataArrayString={eligibleOperations.map(
                  (operation) => `${operation.code} - ${operation.description}`
                )}
                onSelect={(e) => {
                  clearErrors('operation_id');
                  const selectedOp = eligibleOperations.find(
                    (elt) => elt.code === e.split(' - ')[0]
                  );
                  setValue('operation_id', selectedOp?.id || 0);
                }}
                error={Boolean(isSubmitted && errors.operation_id)}
              />
            )}
            {choiceLotSource === controlContactLotSource.COFRAC && (
              <InputSelect
                placeholder={`${t(
                  'control_contact.form.placeholder_cofrac_controlled'
                )}`}
                dataArrayString={cofracControlledList.map((elt) => elt.name)}
                onSelect={(e) => {
                  clearErrors('cofrac_id');
                  const selectedCofrac = cofracControlledList.find(
                    (elt) => elt.name === e
                  );
                  setValue('cofrac_id', selectedCofrac?.id || 0);
                }}
                error={Boolean(isSubmitted && errors.cofrac_id)}
              />
            )}

            {isSubmitted && errors.operation_id && (
              <div
                className="rounded-default p-[.5rem] text-red"
                style={{
                  backgroundColor: convertHexToRGBA(red, 0.1),
                }}
              >
                {errors.operation_id.message}
              </div>
            )}
          </form>
        </Modal>
      </FormProvider>
    </div>
  );
}

export { AddControlContact };
