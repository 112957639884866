import { Modal } from '@components/Modal';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import {
  IGraph,
  ISimulatorDataOperation,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { initialSimulatorDataOperation } from '@models/worksiteCreation/utils/initialsValues/simulationInitialsValues';
import MultipleArrayForm from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/MultipleArrayForm';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import {
  fetchGraph,
  getMultipleArrayFormGraph,
  transformWorksitePayloadToSimulatorDataOperation,
} from '@models/worksites/utils/utils';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { isEmptyValue } from '@models/worksiteCreation/utils/functions';

interface IUpdateMultipleArrayFormModalProps {
  worksiteDetails: IWorksiteDetails;
  codeOperation: string;
  worksiteOperationId: number;
  title: string;
  textBtnConfirm: string;
  onConfirmClick: (data: FieldValues) => void;
  onClickCancel: Dispatch<SetStateAction<boolean>>;
}

function UpdateMultipleArrayFormModal({
  worksiteDetails,
  codeOperation,
  worksiteOperationId,
  title,
  textBtnConfirm,
  onConfirmClick,
  onClickCancel,
}: IUpdateMultipleArrayFormModalProps) {
  const methods = useForm();
  const { watch } = methods;

  const [simulatorDataOperations, setSimulatorDataOperation] = useState<
    ISimulatorDataOperation[]
  >([initialSimulatorDataOperation]);
  const [worksiteOperationGraph, setWorksiteOperationGraph] = useState<{
    operations: IGraph[];
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFormNotValid, setIsFormNotValid] = useState<boolean>(false);

  // reformate les donnees pour les transmettre au format attendu pour le recalcul de primes
  const reformatModalData = (
    modalData: FieldValues,
    id: number
  ): Record<string, any> => {
    let reformattedData: Record<string, any> = {};

    if (modalData.operation) {
      const operationContent = modalData.operation;
      Object.entries(operationContent).forEach(([key, value]) => {
        const newKey = `${id}_${key}`;
        reformattedData[newKey] = value;
      });
    } else {
      Object.values(modalData).forEach((value) => {
        if (typeof value === 'object' && value !== null) {
          const nestedReformattedData = reformatModalData(value, id);
          reformattedData = {
            ...reformattedData,
            ...nestedReformattedData,
          };
        }
      });
    }

    return reformattedData;
  };

  const handleSubmitForm = methods.handleSubmit((formData) => {
    const reformattedData = reformatModalData(formData, worksiteOperationId);
    onConfirmClick(reformattedData);
    onClickCancel(false);
  });

  // Contient uniquement la partie du graph nécessaire pour le composant MultipleArrayForm (ou null)
  const graph = useMemo(() => {
    return getMultipleArrayFormGraph(codeOperation, worksiteOperationGraph);
  }, [codeOperation, worksiteOperationGraph]);

  // Construit le simulatorDataOperation à partir du payload du worksite
  useEffect(() => {
    if (worksiteDetails?.payload) {
      try {
        const worksitePayload = JSON.parse(worksiteDetails.payload);
        const data = transformWorksitePayloadToSimulatorDataOperation(
          worksitePayload,
          codeOperation
        );
        if (data) setSimulatorDataOperation(data);
      } catch (error) {
        console.error('Error parsing worksite payload:', error);
      }
    }
  }, [worksiteDetails?.payload]);

  // Récupère le graphe complet et met à jour worksiteOperationGraph
  useEffect(() => {
    if (
      worksiteDetails &&
      worksiteDetails.operation_type > 0 &&
      worksiteDetails.convention &&
      worksiteDetails.convention.id > 0
    ) {
      const fetchAndSetGraph = async () => {
        setIsLoading(true);
        const graphData = await fetchGraph(
          codeOperation,
          worksiteDetails.operation_type,
          worksiteDetails.convention.id
        );
        if (graphData) {
          setWorksiteOperationGraph(graphData);
        }
        setIsLoading(false);
      };
      fetchAndSetGraph();
    }
  }, [worksiteDetails, codeOperation]);

  useEffect(() => {
    setIsFormNotValid(isEmptyValue(watch()));
  }, [watch()]);

  return (
    <Modal
      title={title}
      backgroundTransparent
      sidebarVisible
      btnCancel
      textBtnConfirm={textBtnConfirm}
      onConfirmClick={handleSubmitForm}
      btnConfirmDisabled={isFormNotValid}
      onClickCancel={() => onClickCancel(false)}
      width="50rem"
    >
      <div className="mt-[1rem] max-h-[80vh] overflow-y-auto">
        {isLoading ? (
          <LoaderSkeleton height="5rem" />
        ) : (
          <FormProvider {...methods}>
            {graph && (
              <MultipleArrayForm
                graph={graph}
                operationIndex={0}
                simulatorDataOperations={simulatorDataOperations}
                updateSimulatorDataOperation={setSimulatorDataOperation}
                operationCode={codeOperation}
              />
            )}
          </FormProvider>
        )}
      </div>
    </Modal>
  );
}

export { UpdateMultipleArrayFormModal };
