import { format, parse } from 'date-fns';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { Tag } from '@components/atomic/Tag';

import { textGrey } from '@assets/color';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { FilterRequestData } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { getVolumesRender } from '@models/contracts/utils/contractHelper';
import { getTagValue } from '@models/deposits/utils/depositHelper';
import { formatWord, numberWithSeparator } from '@utils/format';
import { WORKSITES_ROUTES_WITH_PARAMS } from '@utils/routesUrls';
import { IConsultListPage } from '../../../types/globalTypes';

interface IConsultListProps {
  data: any;
  model: '' | 'deposit' | 'lot' | 'contract' | 'cofrac';
  sortList: (column: string, direction: string) => void;
  loading: boolean;
  onFilter?: (filters: FilterRequestData[]) => void;
}

function ConsultList(props: IConsultListProps) {
  const { data, model, sortList, onFilter, loading } = props;
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);

  const page = useMemo(() => {
    switch (model) {
      case 'cofrac':
        return 'COFRAC';
      case 'deposit':
        return 'DEPOTS';
      default:
        return 'CONTRATS';
    }
  }, [model]);

  const renderItem = (item: any) => {
    const bene = item.worksite_operation.worksite.beneficiary;
    const beneCompName = bene && bene.company_name ? bene.company_name : '';
    const beneFullName = bene ? `${bene.firstname} ${bene.lastname}` : '-';

    const beneficiary = beneCompName !== '' ? beneCompName : beneFullName;

    const inst = item.worksite_operation.worksite.installer;
    const installer = inst ? inst.company_name : '-';

    const operation = item.worksite_operation.operation;
    const opCode = operation ? operation.code : '-';
    const opDesc = operation ? operation.description : '-';

    const ceeAmount =
      (item.worksite_operation.cdp_amount > 0
        ? item.worksite_operation.cdp_amount
        : item.worksite_operation.cee_amount) || '-';

    const dateUpdated = item.worksite_operation.worksite.updated_at;
    const dateStr = dateUpdated
      ? format(
          parse(dateUpdated, 'yyyy-MM-dd HH:mm:ss', new Date()),
          'dd/MM/yyyy'
        )
      : '-';

    const list = model === 'cofrac' ? 'cofrac_lots' : 'deposits';

    const statusLabel = formatWord(
      globalEnum[`${list}_operations_status`][item.status]
    );

    const { tagText, tagColor } = getTagValue(
      statusLabel,
      t,
      model === 'cofrac'
    );

    const worksiteId = item.worksite_operation.worksite.id;
    const worksiteOperationId = item.worksite_operation.id;
    const hasWorksite = !!worksiteId;

    if (loading)
      return (
        <div className=" flex justify-between">
          <div className="flex flex-col gap-3 w-1/4">
            <LoaderSkeleton height="1rem" addClass="w-10/12" />
            <LoaderSkeleton height="1rem" addClass="w-full" />
          </div>
          <div className="w-1/4 flex flex-col gap-3 items-end">
            <LoaderSkeleton height="1rem" addClass="w-2/3" />
            <LoaderSkeleton height="1rem" addClass="w-full" />
          </div>
        </div>
      );

    const content = (
      <>
        <div className="flex justify-between flex-wrap items-center">
          <div className="flex">
            {tagText !== '' && (
              <Tag
                color={tagColor}
                label={tagText}
                addClass="h-fit mr-[1rem]"
              />
            )}
            <p className="text-[1rem] font-medium">
              {`${beneficiary} / ${installer}`}
            </p>
          </div>

          {getVolumesRender(item.worksite_operation, globalEnum)}
        </div>

        <div className="flex justify-between flex-wrap items-center">
          <div className="flex flex-wrap items-center">
            {model === 'cofrac' ? (
              <div>{`${t('cofrac.amount_prime')} : ${numberWithSeparator(
                String(ceeAmount)
              )} €`}</div>
            ) : (
              <>
                <Tag
                  color={textGrey}
                  label={opCode}
                  addClass="h-fit mr-[1rem] min-w-[6rem] text-center"
                />
                <p className="text-[0.875rem] my-[0.656rem]">{opDesc}</p>
              </>
            )}
          </div>

          <p className="text-textGrey text-[0.875rem]">{`${t(
            'contract.tab_volume_detail.last_update'
          )}: ${dateStr}`}</p>
        </div>

        {item.status === 2 && (item.commentary || item.description) && (
          <div
            style={{ backgroundColor: '#E43E3E10' }}
            className="p-[0.5rem] rounded-default mt-2"
          >
            <p className="font-normal text-[0.75rem]">
              {item.commentary || item.description}
            </p>
          </div>
        )}
      </>
    );

    const cardClasses =
      'border border-borderGrey rounded-default2 bg-white p-6 my-4';

    if (hasWorksite) {
      const linkTo = WORKSITES_ROUTES_WITH_PARAMS(
        worksiteId,
        worksiteOperationId
      ).WORKSITES_VIEW_WITH_WORKSITE_OPERATION_ID;

      return (
        <Link to={linkTo} key={v4()} className="w-full">
          <div className={`${cardClasses} cursor-pointer flex justify-between`}>
            <div className="w-full">{content}</div>
            <div className="flex items-center justify-center">
              <ChevronLeftIcon
                className="w-4 h-4 rotate-180 ml-4"
                fill={textGrey}
              />
            </div>
          </div>
        </Link>
      );
    }

    return (
      <div key={v4()} className={cardClasses}>
        {content}
      </div>
    );
  };

  const list = useMemo(() => {
    if (data && data.length > 0) {
      return data;
    }

    return [];
  }, [data]);

  if (!data) return <div />;

  const pageForSortAndFilter: IConsultListPage =
    page !== 'CONTRATS' ? `${page}_OPERATIONS` : page;

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full mb-[1.5rem]">
        <div className="flex flex-wrap justify-between items-center mb-4">
          <div className="flex flex-wrap">
            <p className="text-[1.25rem] mr-3 font-medium">
              {t('partners.deposits.operation_maj')}
              {`${list.length > 1 ? 's' : ''}`} ({`${list.length}`})
            </p>
          </div>
          <div className="flex md:justify-end">
            {sortList && (
              <SortAndFilter
                onSort={sortList}
                onFilter={onFilter}
                page={pageForSortAndFilter}
              />
            )}
          </div>
        </div>

        {list.length > 0 ? (
          list.map((item: any) => renderItem(item))
        ) : (
          <p className="text-textGrey">{t('contract.association.no_items')}</p>
        )}
      </div>
    </div>
  );
}

ConsultList.defaultProps = {
  onFilter: undefined,
};

export { ConsultList };
