/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { OneContact } from '@models/partners/components/informations/cards/OneContact';

import { IUserType } from '@models/auth/utils/types';
import {
  IWorksiteReferentType,
  IWorksiteReferentUpdate,
} from '@models/worksites/utils/worksitesTypes';
import { updateWorksiteReferent } from '@models/worksites/apiRequests/worksitesRequests';

interface ICardContactsProps {
  title: string;
  contactList: IUserType[];
  loading: boolean;
  referent: IWorksiteReferentType | null;
  setReferent: Dispatch<SetStateAction<IWorksiteReferentType | null>>;
  canEdit: boolean;
  dataTestId?: string;
}

function CardContacts({
  title,
  contactList,
  loading,
  referent,
  setReferent,
  canEdit,
  dataTestId,
}: ICardContactsProps) {
  const { t } = useTranslation();
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<string>('');
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const handleUpdateContact = async () => {
    const selectedUser = contactList.find((item) => {
      return `${item.firstname} ${item.lastname}` === selectedContact;
    });

    if (selectedUser) {
      const data: IWorksiteReferentUpdate = {
        id: Number(referent?.id),
        entity_type_id: Number(selectedUser.entity_type_id),
      };

      setValidationLoading(true);
      await updateWorksiteReferent(data, setShowContactForm, setReferent);
      setValidationLoading(false);
    }
  };

  useEffect(() => {
    const fullname =
      referent && referent.contact
        ? `${referent.contact.firstname} ${referent.contact.lastname}`
        : '';
    setSelectedContact(fullname);
  }, [referent, contactList]);

  const actionButtons = useMemo(() => {
    if (canEdit) {
      return (
        <ButtonOpx
          key={v4()}
          onClick={() => setShowContactForm(true)}
          label={t('buttons.update')}
          type="tierciary"
          small
          addClass="!border-transparent"
        />
      );
    }
    return undefined;
  }, [referent]);

  const selectList = useMemo(() => {
    return contactList
      .filter(
        (item) => `${item.firstname} ${item.lastname}` !== selectedContact
      )
      .map((item) => `${item.firstname} ${item.lastname}`);
  }, [contactList, selectedContact]);

  return (
    <Card
      title={title}
      actionButtons={showContactForm ? undefined : actionButtons}
      addClass="h-[max-content] w-full"
      dataTestId={dataTestId}
    >
      <>
        {showContactForm ? (
          <>
            <InputSelect
              dataArrayString={selectList}
              onSelect={(value) => {
                setSelectedContact(String(value));
                return value;
              }}
              placeholder=""
              valueInput={selectedContact}
            />
            <div className="flex space-x-4 justify-end mt-4">
              <ButtonOpx
                key={v4()}
                onClick={() => setShowContactForm(false)}
                label={t('buttons.cancel')}
                type="secondary"
                small
                disabled={selectedContact === ''}
              />
              <ButtonOpx
                key={v4()}
                onClick={() => handleUpdateContact()}
                label={t('buttons.validate')}
                type="primary"
                small
                disabled={selectedContact === ''}
                isLoading={validationLoading}
              />
            </div>
          </>
        ) : (
          <OneContact
            key={v4()}
            contact={referent?.contact || undefined}
            loading={loading}
          />
        )}
      </>
    </Card>
  );
}

export { CardContacts };

CardContacts.defaultProps = {
  dataTestId: '',
};
