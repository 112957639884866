import {
  IArrayInput,
  IOptionGraph,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

interface IElementCardInArrayProps {
  element: IArrayInput;
  elementKey: string;
  onChangeForm: () => void;
}

function ElementCardInArray({
  element,
  elementKey,
  onChangeForm,
}: IElementCardInArrayProps) {
  const options = element.options as IOptionGraph[];

  const { watch, setValue } = useFormContext();

  const fieldValue = watch(elementKey);
  const selectValue = fieldValue ? fieldValue.toString() : '';
  const isChecked = (value: string) => fieldValue === value;
  const onSelect = (e: string) => {
    setValue(elementKey, e);
    onChangeForm();
  };

  const gridColsClass = `grid-cols-${options.length}`;

  useEffect(() => {
    if (!fieldValue && fieldValue !== '') {
      setValue(elementKey, '');
    }
  }, []);

  return (
    <div className="flex flex-col w-full space-y-[.5rem]">
      <div className="text-textGrey text-[.75rem] leading-[.75rem]">
        {element.label}
      </div>
      <div className={`grid ${gridColsClass} gap-4`}>
        {options.map((option) => (
          <TextWithRadio
            key={option.value}
            label={option.label}
            value={option.value}
            addClass="flex-grow"
            selectValue={selectValue}
            isChecked={isChecked(option.value)}
            setSelectedValue={(e) => onSelect(e)}
            withoutName
          />
        ))}
      </div>
    </div>
  );
}

export default ElementCardInArray;
