import { useTranslation } from 'react-i18next';

import { useFormContext, useFieldArray } from 'react-hook-form';
import { useEffect } from 'react';
import { InputText } from '@components/atomic/inputs/InputText';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AddIcon, TrashIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { red } from '@assets/color';

/**
 * Composant ResponseFormatValues
 *
 * Ce composant affiche une liste dynamique d'inputs gérés par react-hook-form.
 * Par défaut, il active une valeur initiale (avec clé "1" et valeur vide).
 * Pour chaque valeur dans response_format_values, on affiche un InputText avec :
 *  - name = "response_format_values.X" (X commence à 1)
 *  - label = "Valeur X"
 *
 * Un bouton permet d'ajouter une nouvelle valeur.
 */
function ResponseFormatValues() {
  const { t } = useTranslation();
  const methods = useFormContext();
  const { control, watch, resetField } = methods;
  const parentName = 'response_format_values';
  const responseFormatValues = watch(parentName);

  // Utilisation de useFieldArray pour gérer le tableau dynamique.
  // Bien que le tableau soit géré en interne, nous voulons en sortie
  // obtenir un objet avec comme clés "1", "2", etc.
  const { fields, append, remove } = useFieldArray({
    control,
    name: parentName, // ce champ sera mis à jour via append
  });

  /**
   * Ajoute un nouvel input en appelant append.
   */
  const addValue = () => {
    append('');
  };

  useEffect(() => {
    if (fields.length === 0) {
      addValue();
    }
  }, [fields]);

  useEffect(() => {
    return () => {
      resetField(parentName);
    };
  }, []);

  return (
    <div className="space-y-4">
      {fields.map((field, index) => {
        return (
          <div
            key={field.id}
            className={`${index > 0 ? 'flex gap-2 items-end' : ''}`}
          >
            <InputText
              id={`${parentName}.${index}`}
              // On construit le name pour que ce soit, par exemple, "response_format_values.1"
              name={`${parentName}.${index}`}
              label={`${t('control_contact.settings.new_field_modal.value')} ${
                index + 1
              }`}
              placeholder={t('settings.type.text') as string}
              required
            />
            {index > 0 && (
              <ColorCube
                color={red}
                size="2rem"
                onClick={() => remove(index)}
                opacity
                numberOrIcon={<TrashIcon />}
                addClass="mb-2"
              />
            )}
          </div>
        );
      })}
      <ButtonOpx
        icon={<AddIcon />}
        label={
          t('control_contact.settings.new_field_modal.add_a_value') as string
        }
        small
        onClick={addValue}
        disabled={responseFormatValues?.some((field: string) => field === '')}
        addClass="w-full"
        type="tab"
      />
    </div>
  );
}

export default ResponseFormatValues;
