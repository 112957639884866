import { IStatusCardDatas } from '@models/dashboard/utils/dashboardTypes';
import { IRequestTypes } from '@models/requests/types/requestTypes';

export const fakeStatusDatas: IStatusCardDatas[] = [
  {
    step: 1,
    total_operations: 1250,
    total_prime_amount: 9750000,
    total_mwhc_gisement: 85297.754,
  },
  {
    step: 2,
    total_operations: 980,
    total_prime_amount: 8200000,
    total_mwhc_gisement: 78563.982,
  },
  {
    step: 3,
    total_operations: 820,
    total_prime_amount: 7450000,
    total_mwhc_gisement: 68274.435,
  },
  {
    step: 4,
    total_operations: 675,
    total_prime_amount: 6300000,
    total_mwhc_gisement: 54592.827,
  },
  {
    step: 5,
    total_operations: 520,
    total_prime_amount: 5150000,
    total_mwhc_gisement: 43289.156,
  },
  {
    step: 6,
    total_operations: 430,
    total_prime_amount: 4250000,
    total_mwhc_gisement: 37862.946,
  },
  {
    step: 7,
    total_operations: 320,
    total_prime_amount: 3150000,
    total_mwhc_gisement: 28573.298,
  },
  {
    step: 8,
    total_operations: 185,
    total_prime_amount: 1850000,
    total_mwhc_gisement: 16385.721,
  },
];

export const initialEmptyChartDatas = [0, 0, 0, 0, 0, 0];

export const fakeChartDatas = [
  [3, 8, 6, 8, 9, 9],
  [3, 6, 6, 4, 9, 2],
  [2, 2, 2, 3, 3, 3],
  [0, 1, 2, 4, 5, 6],
];

const fakeRequest: IRequestTypes = {
  id: 1,
  relation_type: 'worksite',
  relation_id: 1,
  type: 1,
  subject: 'Facture',
  message: null,
  nb: 1,
  tags: 3,
  status: 1,
  created_at: '2024-02-06 18:04:15',
  address: '30 rue de la Paix, Paris 75000 France',
  installer: {
    active: true,
    id: 5,
    type: null,
    siret: '12312312312312',
    company_name: 'Installateur Fictif',
    zipcode: '96000',
    city: 'La Ville',
    country: null,
    address: '1, rue du chemin',
    contact_email: null,
    phone_number: null,
    logo: '',
    website: null,
    revenue: null,
    employees_number: null,
  },
  all_external_messages: [],
  linked_file_id: null,
};

export const fakeRequestDatas = [...Array(5)].map(() => fakeRequest);
