import { TextareaHTMLAttributes, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  name: string;
  addClass?: string;
  placeholder?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

function TextArea({
  id,
  name,
  placeholder,
  addClass,
  defaultValue,
  readOnly,
  ...otherProps
}: ITextAreaProps): JSX.Element {
  const formContext = useFormContext();
  const { register, getValues, setValue } = formContext;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const textValue = getValues(name) || '';

  // Calculer le nombre de lignes en fonction du nombre de caractères et de la largeur
  const calculateRows = (text: string) => {
    // Nombre moyen de caractères par ligne (peut être ajusté selon la police et la taille)
    const charsPerLine = 50;

    // Compter les retours à la ligne explicites
    const explicitLines = (text.match(/\n/g) || []).length;

    // Calculer les lignes basées sur la longueur du texte
    const textLines = Math.ceil(text.length / charsPerLine);

    // Prendre le maximum entre les retours à la ligne explicites et les lignes calculées
    return Math.max(explicitLines + 1, textLines);
  };

  return (
    <textarea
      id={id}
      {...(register(name),
      {
        ref: textareaRef,
        onInput: handleInput,
        placeholder,
        rows: calculateRows(textValue),
        className: `${addClass || ''}`,
        value: textValue,
        onChange: (e) => setValue(name, e.target.value),
        defaultValue,
        readOnly,
      })}
      {...otherProps}
    />
  );
}

export { TextArea };

TextArea.defaultProps = {
  addClass: '',
  placeholder: '',
  defaultValue: '',
  readOnly: false,
};
