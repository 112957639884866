import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@components/Card';
import { CardOfficeControl } from '@models/worksites/components/worksiteDetails/worksiteInformations/CardOfficeControl';

interface ICardControlProps {
  worksiteId: number;
}
function CardControl({ worksiteId }: ICardControlProps) {
  const { t } = useTranslation();

  return (
    <Card
      title={t('worksites.control.title')}
      addClass="h-[max-content] w-full"
      dataTestId="card_control"
    >
      <CardOfficeControl worksiteId={worksiteId} />
    </Card>
  );
}

export { CardControl };
