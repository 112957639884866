export const THEME_ELEMENTS_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MENU: 'menu',
  MENU_BUTTON: 'menu_button',
};

export const THEME_IMAGE_TYPES = {
  LOGO_AUTH: 'logo_auth',
  LOGO_MENU: 'logo_menu',
  BACKGROUND_IMAGE_AUTH: 'background_image_auth',
};

export const CHECKPOINT_RESPONSE_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  LIST: 'list',
  DATE: 'date',
};

export const PRIME_TYPES = {
  CEE: 1,
  MPR: 2,
  BOTH: 3,
};
