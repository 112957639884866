/* eslint-disable @typescript-eslint/no-unused-vars */
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  FilterRequestData,
  SortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { toAPIDateStr } from '@utils/format';
import { isDate, parseISO } from 'date-fns';
import { transformIsoDate } from '@utils/functions';
import { looksLikeADateStr } from '@components/sortAndFilter/utils/functions';

interface DateFilterProps {
  filter: SortAndFilterType;
  selectedFilters: FilterRequestData[];
  setSelectedFilters: Dispatch<SetStateAction<FilterRequestData[]>>;
  id: number;
}

function DateFilter({
  filter,
  selectedFilters,
  setSelectedFilters,
  id,
}: DateFilterProps): JSX.Element {
  const dates = selectedFilters.find((f) => f.criteria === filter.value)
    ?.dates?.[id] || { startDate: '', endDate: '' };

  const [defaultDates, setDefaultDates] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: '', endDate: '' });

  const onChangeDate = (date: 'startDate' | 'endDate', value: string) => {
    if (value !== '' && isDate(parseISO(value))) {
      const apiDate = toAPIDateStr(value);

      const newState = selectedFilters.map((f) => {
        const currentFilter = f.criteria === filter.value;
        if (!currentFilter) return f;

        const otherDate = date === 'startDate' ? 'endDate' : 'startDate';

        const filterDates = f.dates
          ? { ...f.dates, [id]: { ...f.dates[id], [date]: apiDate } }
          : { [id]: { [date]: apiDate, [otherDate]: '' } };

        const d = filterDates as {
          [id: number]: { startDate: string; endDate: string };
        };

        return { ...f, dates: d };
      });

      setSelectedFilters(newState);
    }
  };

  useEffect(() => {
    setDefaultDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
  }, []);

  return (
    <div className="absolute top-0 -left-4 -translate-x-[100%] w-[20rem] bg-white border-2 border-borderGrey rounded-default">
      <DatePicker
        required
        onChangeDate={(e) =>
          transformIsoDate(dates.startDate) !== e &&
          onChangeDate('startDate', e)
        }
        addClassToCalendar="translate-y-[4rem] border-2 border-borderGrey rounded-default"
        addClass="p-2"
        defaultDate={
          looksLikeADateStr(transformIsoDate(defaultDates.startDate))
            ? transformIsoDate(defaultDates.startDate)
            : undefined
        }
        maxDate={
          dates.endDate === '' ? undefined : transformIsoDate(dates.endDate)
        }
      />
      <DatePicker
        required
        onChangeDate={(e) =>
          transformIsoDate(dates.endDate) !== e && onChangeDate('endDate', e)
        }
        addClass="px-2 pb-2 pt-1 "
        addClassToCalendar="translate-y-[.5rem] border-2 border-borderGrey rounded-default"
        defaultDate={
          looksLikeADateStr(transformIsoDate(defaultDates.endDate))
            ? transformIsoDate(defaultDates.endDate)
            : undefined
        }
        minDate={
          dates.startDate === '' ? undefined : transformIsoDate(dates.startDate)
        }
      />
    </div>
  );
}

export { DateFilter };
