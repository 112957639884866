import { IContactControlPointAnswer } from '@models/worksites/utils/worksitesTypes';

/**
 * Transforme la structure 'contactControlPoints' du formulaire
 * en un tableau (gère des valeurs multiples et uniques).
 */
export const cleanContactControlPointsToSave = (
  contactControlPointsObj: Record<string, string | string[]>
): IContactControlPointAnswer[] => {
  const groupedById: Record<string, string[]> = {};

  Object.entries(contactControlPointsObj).forEach(
    ([contactControlPointId, value]) => {
      if (!value) return;

      // Valeur multiple (ex. checkbox)
      if (Array.isArray(value)) {
        if (!groupedById[contactControlPointId]) {
          groupedById[contactControlPointId] = [];
        }
        groupedById[contactControlPointId].push(...value);
      }
      // Valeur unique (string)
      else {
        const [idString, rest] = value.split('.');
        if (rest) {
          if (!groupedById[idString]) {
            groupedById[idString] = [];
          }
          groupedById[idString].push(rest);
        } else {
          if (!groupedById[contactControlPointId]) {
            groupedById[contactControlPointId] = [];
          }
          groupedById[contactControlPointId].push(value);
        }
      }
    }
  );

  return Object.entries(groupedById).map(([idStr, answers]) => ({
    contact_control_point_id: parseInt(idStr, 10),
    answer_values: answers,
  }));
};
