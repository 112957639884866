import { useRef } from 'react';
// import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import { AddIcon, CheckIcon } from '@assets/images/svgComponents';
import { iconBlue } from '@assets/color';
import { handleUploadClick } from '@utils/functions';

interface IUploadCardProps {
  fileType?: string;
  onUpload?: (file: File) => void;
  fileName?: string | null;
  isUploadFile?: boolean;
  loadValidButton?: () => void;
}

function UploadCard({
  fileType,
  onUpload,
  fileName,
  isUploadFile,
  loadValidButton,
}: IUploadCardProps) {
  // const { roleUser, globalEnum } = useContext(GlobalContext);
  const hiddenFileInputCard = useRef<HTMLInputElement>(null);
  // const { t } = useTranslation();

  const acceptFileType = () => {
    return fileType;
  };

  const onClickCard = () => {
    handleUploadClick(hiddenFileInputCard);
  };

  return (
    <div>
      <input
        type="file"
        ref={hiddenFileInputCard}
        onChange={(e) => {
          const files = e.target.files;
          if (onUpload && files && files.length > 0) {
            onUpload(files[0]);
          }
        }}
        onClick={loadValidButton}
        style={{ display: 'none' }}
        accept={acceptFileType()}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={onClickCard}
        className="cursor-pointer p-[.7rem] w-full rounded-default border-[1px] bg-white border-borderGrey"
        data-test-id="button_document_card"
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-[.5rem]">
            <div className="text-left">
              <p className="text-[1rem]">{fileName}</p>
            </div>
          </div>
          <div className="flex items-center space-x-[.5rem]">
            <ColorCube
              size="1.5rem"
              numberOrIcon={isUploadFile ? <AddIcon /> : <CheckIcon />}
              color={iconBlue}
              opacity
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { UploadCard };

UploadCard.defaultProps = {
  fileType: '.xlsx',
  onUpload: undefined,
  fileName: null,
  isUploadFile: false,
  loadValidButton: undefined,
};
