import { textGrey, grey, green, orange, red } from '@assets/color';
import { v4 } from 'uuid';
import { Tag } from '@components/atomic/Tag';
import { ProgressBarHorizontal } from '@components/atomic/ProgressBarHorizontal';
import { numFormatSpace } from '@utils/functions';
import { useContext, useMemo } from 'react';
import { GlobalContext } from '@context/globalContext';
import { getWorksiteOperationStatusColor } from '@models/worksites/utils/utils';
import { useTranslation } from 'react-i18next';
import {
  WORKSITE_STATUS,
  WORKSITE_OPERATION_STATUS,
} from '@models/worksites/utils/enums';
import { CofracTagInfos } from '@components/atomic/CofracTagInfos';
import { linkedWorksitesOperationsStatus } from '@models/controlContact/utils/utlis';
import {
  IWorksiteDetails,
  IWorksiteOperation,
} from '../../utils/worksitesTypes';

type IWorksitesCardOperationsProps = {
  details: IWorksiteDetails;
};

function WorksitesCardOperations({
  details,
}: IWorksitesCardOperationsProps): JSX.Element {
  const { globalEnum } = useContext(GlobalContext);

  const worksiteOperationStatus: { [p: number]: string } = useMemo(
    () => globalEnum.worksite_operation_status,
    [globalEnum]
  );

  const { t } = useTranslation();

  const isSimulation = details.worksites_operations?.length === 0;

  const operations = !isSimulation
    ? details.worksites_operations
    : details.simulationOperations || [];

  const isCanceled = Number(details.status) === WORKSITE_STATUS.CANCELED;

  return (
    <div
      className="w-full sm:w-2/3 flex-col text-[.875rem] border-l border-borderGrey h-full"
      data-test-id="list_operations"
    >
      {operations?.length === 0 || isCanceled ? (
        <div className="text-[.75rem] px-[1rem] py-[1.5rem] w-full">
          <div className="flex items-center space-x-[1rem] w-full mt-4">
            <Tag
              color={isCanceled ? red : getWorksiteOperationStatusColor(0)}
              label={`0 - ${
                isCanceled
                  ? t('global.canceled')
                  : t('worksite_creation.steps.simulation')
              }`}
              addClass="whitespace-nowrap"
            />
          </div>
        </div>
      ) : (
        operations?.map((operation: IWorksiteOperation, index: number) => {
          if (operation.operation === null) return null;
          const ceeAmount =
            operation.cdp_amount > 0
              ? operation.cdp_amount
              : operation.cee_amount;
          const mprAmount = operation.mpr_amount;

          let stepName = '';
          const stepActive = isSimulation ? 0 : operation.status;

          if (
            worksiteOperationStatus &&
            Object.keys(worksiteOperationStatus).length > 0
          ) {
            const tab = worksiteOperationStatus[stepActive]
              ? worksiteOperationStatus[stepActive].split(': ')
              : [];
            stepName = tab[1];
          }

          if (isSimulation) {
            stepName = `${t('worksite_creation.steps.simulation')}`;
          }

          const isControlContact =
            String(stepActive) ===
            String(WORKSITE_OPERATION_STATUS.CONTROL_CONTACT);

          const statusTag = () => {
            if (operation.contact_control_status) {
              const tagText =
                globalEnum.worksite_operation_contact_control_status[
                  operation.contact_control_status
                ];
              switch (operation.contact_control_status.toString()) {
                case linkedWorksitesOperationsStatus.CONTROLABLE.toString():
                  return null;
                case linkedWorksitesOperationsStatus.NON_CONTROLEE.toString():
                  return <Tag color={textGrey} label={tagText} />;
                case linkedWorksitesOperationsStatus.SATISFAISANT.toString():
                  return <Tag color={green} label={tagText} />;
                case linkedWorksitesOperationsStatus.A_RAPPELER.toString():
                  return <Tag color={orange} label={tagText} />;
                case linkedWorksitesOperationsStatus.INSUFFISANT.toString():
                  return <Tag color={red} label={tagText} />;
                default:
                  return null;
              }
            }
            return null;
          };

          return (
            <div
              className={`text-[.75rem] px-[1rem] py-[1.5rem] w-full ${
                index === operations.length - 1 ? '' : 'border-b'
              }`}
              style={{
                borderColor: index === operations.length - 1 ? '' : grey,
              }}
              key={v4()}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-[1rem] w-[85%]">
                  <div
                    className="font-[500] text-[1rem] text-justify"
                    data-test-id="op_name"
                  >
                    {operation.operation ? operation.operation.description : ''}
                  </div>
                  <div className="w-1/3 flex gap-2">
                    <Tag
                      label={operation.operation.code}
                      color={textGrey}
                      dataTestId="op_slug"
                    />
                    {Number(stepActive) >= WORKSITE_OPERATION_STATUS.COFRAC &&
                      operation?.cofrac_lot_operations &&
                      operation?.cofrac_lot_operations.length > 0 && (
                        <CofracTagInfos
                          cofracLotOperation={
                            operation?.cofrac_lot_operations[0]
                          }
                        />
                      )}
                  </div>
                </div>

                <div className="flex items-center space-x-4 whitespace-nowrap">
                  {mprAmount > 0 && (
                    <div className="flex items-center space-x-4">
                      <span className="text-[.875rem]">MPR :</span>
                      <span className="font-[500] text-[1rem]">
                        {mprAmount} €
                      </span>
                    </div>
                  )}
                  {ceeAmount > 0 && (
                    <div className="flex items-center space-x-4">
                      <div data-test-id="op_total_bonus" />
                      <span className="text-[.875rem]">CEE :</span>
                      <span className="font-[500] text-[1rem]">
                        {`${numFormatSpace(ceeAmount)} €`}
                      </span>

                      <p data-test-id="op_kwhc">{`${numFormatSpace(
                        (Number(operation.kwhc_precaire || 0) +
                          Number(operation.kwhc_classique || 0)) /
                          1000
                      )} MWhc`}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-[1rem] w-full mt-4">
                <Tag
                  color={getWorksiteOperationStatusColor(stepActive)}
                  label={`${
                    !isControlContact ? stepActive : '5 bis'
                  } - ${stepName}`}
                  addClass="whitespace-nowrap"
                />
                <div className="w-full" data-test-id="op_progress">
                  <ProgressBarHorizontal
                    percent={`${String(
                      ((isControlContact ? 5.5 : stepActive) /
                        Object.keys(worksiteOperationStatus).length) *
                        100
                    )}%`}
                    color={getWorksiteOperationStatusColor(stepActive)}
                  />
                </div>
              </div>
              {statusTag() && <div className="mt-4">{statusTag()}</div>}
            </div>
          );
        })
      )}
    </div>
  );
}

export { WorksitesCardOperations };
