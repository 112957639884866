/* eslint-disable no-shadow */
export enum CheckpointButtonEnum {
  WITH_CONTROL = 1,
  WITHOUT_CONTROL = 2,
  COMPLIANCE_REQUEST = 3,
  CANCEL_VALIDATION = 4,
}

export enum CheckpointResponseTypeEnum {
  BOOLEAN = 'boolean',
  RADIO = 'radio',
  TEXT = 'text',
  NUMBER = 'number',
}
