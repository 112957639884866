/* eslint-disable no-nested-ternary */
import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import {
  CustomFieldTypes,
  getCustomFieldTypeValue,
  renderCustomFieldTypeLabel,
} from '@models/settings/utils/utils';
import { IContractType } from '@models/contracts/utils/contractTypes';
import { getContractList } from '@models/contracts/apiRequests/contractRequests';
import {
  addCustomField,
  updateCustomField,
} from '@models/settings/apiRequests/settingsRequests';
import { toLower } from 'lodash';
import { ICustomField } from '@models/settings/utils/settingsTypes';
import { placeholderExample } from '@utils/utils';

interface IModalAddOrEditCustomFieldProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  refreshlist?: () => void;
  setCustomFieldDetail?: Dispatch<SetStateAction<ICustomField | undefined>>;
  customFieldDetails?: ICustomField;
}

function ModalAddOrEditCustomField({
  setIsOpen,
  refreshlist,
  setCustomFieldDetail,
  customFieldDetails,
}: IModalAddOrEditCustomFieldProps) {
  const editMode =
    setCustomFieldDetail !== undefined && customFieldDetails !== undefined;
  const { t } = useTranslation();
  const placeholders = placeholderExample(t);
  const [loading, setLoading] = useState<boolean>(false);
  const [contractsList, setContractsList] = useState<IContractType[]>([]);

  const methods = useForm();

  const { setValue, watch } = methods;
  const formValues = watch(); // Obtenir toutes les valeurs du formulaire

  const getContractsList = async () => {
    const resList = await getContractList(1, 100, 1);
    if (resList) {
      setContractsList(resList.data);
    }
  };

  const onSubmitCustomField = async () => {
    const data = { ...formValues };
    const customFieldSend = !editMode
      ? await addCustomField(data, setLoading)
      : await updateCustomField(customFieldDetails.id, data, setLoading);
    if (customFieldSend) {
      if (refreshlist) {
        refreshlist();
      }
      if (setCustomFieldDetail) {
        setCustomFieldDetail(customFieldSend);
      }
      setIsOpen(false);
    }
  };

  const handleSelectVisibility = (value: string) => {
    setValue('is_public', value === t('settings.all'));
  };

  const handleSelectType = (value: string) => {
    setValue('type', getCustomFieldTypeValue(value, t));
  };

  const handleSelectContract = (value: string[]) => {
    // Initialisation de tmp avec des structures vides pour éviter de garder des références non désirées
    const tmp: {
      conventions: string[];
      contracts: string[];
    } = {
      conventions: [],
      contracts: [],
    };

    // Parcourir tous les contrats pour mettre à jour tmp en fonction de la sélection
    contractsList.forEach((contract) => {
      const idString = String(contract.id);
      // Si le contrat est sélectionné
      if (value.includes(contract.reference)) {
        // Ajout selon le type de contrat
        if (contract.contract_type === -1) {
          if (!tmp.conventions.includes(idString)) {
            tmp.conventions.push(idString);
          }
        } else if (!tmp.contracts.includes(idString)) {
          tmp.contracts.push(idString);
        }
      }
    });

    // Mise à jour de la valeur du formulaire
    setValue('relations', tmp);
  };

  const handleSelectIsRequired = (value: string) => {
    setValue('is_required', value === t('settings.yes'));
  };

  const handleSelectStatus = (value: string) => {
    setValue('is_active', value === t('settings.active'));
  };

  const populateFieldsIfEditing = () => {
    if (editMode) {
      setValue('internal_name', customFieldDetails?.internal_name);
      setValue('is_public', customFieldDetails?.is_public);
      setValue('type', customFieldDetails?.type);
      setValue('relations', {
        conventions: customFieldDetails?.conventions.map((contract) =>
          String(contract.id)
        ),
        contracts: customFieldDetails?.contracts.map((contract) =>
          String(contract.id)
        ),
      });
      setValue('is_required', customFieldDetails?.is_required);
      setValue('is_active', customFieldDetails?.is_active);
      if (customFieldDetails?.default_value) {
        setValue('default_value', customFieldDetails?.default_value);
      }
      if (customFieldDetails?.placeholder) {
        setValue('placeholder', customFieldDetails?.placeholder);
      }
      if (customFieldDetails?.public_name) {
        setValue('public_name', customFieldDetails?.public_name);
      }
    }
  };

  useEffect(() => {
    getContractsList();
    populateFieldsIfEditing();
  }, []);

  return (
    <Modal
      title={t('settings.add_custom_field')}
      setIsModal={setIsOpen}
      backgroundTransparent
      sidebarVisible
      btnCancel
      isLoading={loading}
      textBtnConfirm={t('buttons.confirm') || ''}
      onConfirmClick={() => onSubmitCustomField()}
      btnConfirmDisabled={
        !(
          formValues.internal_name &&
          formValues.public_name &&
          formValues.type &&
          formValues.placeholder &&
          formValues.relations &&
          (formValues.relations.conventions.length > 0 ||
            formValues.relations.contracts.length > 0) &&
          formValues.is_public !== undefined &&
          formValues.is_public !== null
        )
      }
    >
      <FormProvider {...methods}>
        <form id="form-personal-infos" className="grid grid-cols-2 gap-3 pt-6">
          <InputText
            label={`${t('settings.field_name')} (${toLower(
              t('settings.public') || ''
            )})`}
            id="public_name"
            name="public_name"
            required
            placeholder={placeholders.INSULATING_MATERIAL}
            data-test-id="public_name"
          />
          <InputText
            label={`${t('settings.field_name')} (${toLower(
              t('settings.internal') || ''
            )})`}
            id="internal_name"
            name="internal_name"
            placeholder={placeholders.INSULATING}
            required
            data-test-id="internal_name"
          />
          <InputSelect
            label={t('settings.visibility') || ''}
            defaultSelected={
              editMode
                ? customFieldDetails?.is_public
                  ? (t('settings.all') as string)
                  : (t('settings.internal') as string)
                : undefined
            }
            dataArrayString={[t('settings.all'), t('settings.internal')]}
            onSelect={(value) => handleSelectVisibility(value)}
            placeholder=""
            required
          />
          <InputSelect
            label={t('settings.field_type') || ''}
            defaultSelected={
              editMode
                ? renderCustomFieldTypeLabel(customFieldDetails?.type, t) || ''
                : undefined
            }
            dataArrayString={Object.values(CustomFieldTypes).map((type) =>
              renderCustomFieldTypeLabel(type, t)
            )}
            onSelect={(value) => handleSelectType(value)}
            placeholder=""
            required
          />
          <InputSelect
            label={t('settings.associated_contracts') || ''}
            defaultChecked={
              editMode
                ? [
                    ...(customFieldDetails?.contracts || []).map(
                      (contract) => contract.reference
                    ),
                    ...(customFieldDetails?.conventions || []).map(
                      (contract) => contract.reference
                    ),
                  ]
                : undefined
            }
            dataArrayString={contractsList.map(
              (contract) => contract.reference
            )}
            onChangeValue={(value) => handleSelectContract(value as string[])}
            placeholder={placeholders.CONTRACT}
            isMultipleSelect
            required
          />
          <InputText
            label={t('settings.placeholder') || ''}
            id="placeholder"
            name="placeholder"
            required
            placeholder={placeholders.INDICATIVE_TEXT}
            data-test-id="placeholder"
          />
          <InputSelect
            label={t('settings.is_required') || ''}
            defaultSelected={
              editMode
                ? customFieldDetails?.is_required
                  ? (t('settings.yes') as string)
                  : (t('settings.no') as string)
                : (t('settings.no') as string)
            }
            dataArrayString={[t('settings.yes'), t('settings.no')]}
            onSelect={(value) => handleSelectIsRequired(value)}
            placeholder=""
          />
          <InputText
            label={t('settings.default_value') || ''}
            id="default_value"
            name="default_value"
            placeholder={placeholders.DEFAULT_VALUE_CUSTOM_FIELD}
            data-test-id="default_value"
          />
          <InputSelect
            label={t('settings.status') || ''}
            defaultSelected={
              editMode
                ? customFieldDetails?.is_active
                  ? (t('settings.active') as string)
                  : (t('settings.inactive') as string)
                : (t('settings.active') as string)
            }
            dataArrayString={[t('settings.active'), t('settings.inactive')]}
            onSelect={(value) => handleSelectStatus(value)}
            placeholder=""
          />
        </form>
      </FormProvider>
    </Modal>
  );
}

export default ModalAddOrEditCustomField;

ModalAddOrEditCustomField.defaultProps = {
  refreshlist: undefined,
  setCustomFieldDetail: undefined,
  customFieldDetails: undefined,
};
