// initialState WORKSITES
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { IChange } from 'types/changesTypes';
import {
  IContractPartnerInfo,
  IContractPaymentCondition,
} from '@models/contractCreation/utils/contractCreationTypes';
import { DEFAULT_BENEFICIARY } from '@models/beneficiaries/utils/beneficiariesConstants';
import {
  IActiveDocument,
  IGlobalEnumType,
  ILinkedFile,
} from '../types/globalTypes';

export const initialStateWorksiteDetails: IWorksiteDetails = {
  id: 0,
  reference: '',
  precarity_type: 0,
  operation_type: 0,
  status: -1,
  creation_status: 0,
  entity_to_type: 0,
  incentive_type: 0,
  fiscalDeclarations: [
    {
      id: 1,
      firstname: '',
      lastname: '',
      fiscal_number: '',
      fiscal_reference: '',
    },
  ],
  referent: {
    id: 0,
    contact: {
      id: 0,
      created_at: '',
      updated_at: null,
      deleted_at: null,
      email: '',
      entity_id: 0,
      entity_name: '',
      firstname: '',
      lastname: '',
      permissions_names: [],
      role_name: '',
      current_entity_type: 0,
      phone_number: '',
      function: '',
      photo_url: '',
      entity_type: 1,
    },
  },
  worksite_referent: {
    id: 0,
    contact: {
      id: 0,
      created_at: '',
      updated_at: null,
      deleted_at: null,
      email: '',
      entity_id: 0,
      entity_name: '',
      firstname: '',
      lastname: '',
      permissions_names: [],
      role_name: '',
      current_entity_type: 0,
      phone_number: '',
      function: '',
      photo_url: '',
      entity_type: 1,
    },
  },
  worksite_partner: {
    id: 0,
    contact: {
      id: 0,
      created_at: '',
      updated_at: null,
      deleted_at: null,
      email: '',
      entity_id: 0,
      entity_name: '',
      firstname: '',
      lastname: '',
      permissions_names: [],
      role_name: '',
      current_entity_type: 0,
      phone_number: '',
      function: '',
      photo_url: '',
      entity_type: 1,
    },
  },
  worksite_rai_referent: {
    id: 0,
    contact: {
      id: 0,
      created_at: '',
      updated_at: null,
      deleted_at: null,
      email: '',
      entity_id: 0,
      entity_name: '',
      firstname: '',
      lastname: '',
      permissions_names: [],
      role_name: '',
      current_entity_type: 0,
      phone_number: '',
      function: '',
      photo_url: '',
      entity_type: 1,
    },
  },
  housing: {
    age_type: 0,
    cadastral_parcel: '',
    heating_type: 0,
    housing_type: 0,
    occupation_type: 0,
    surface: 0,
    persons: 0,
  },
  beneficiary: {
    id: 0,
    civility: 0,
    firstname: '',
    lastname: '',
    address: {
      id: 0,
      worksite_id: 0,
      address_type: 0,
      address: '',
      postal_code: '',
      city: '',
      country: '',
      elevation: '',
      latitude: 0,
      longitude: 0,
      additional_address: '',
      selected: false,
    },
    addresses: { data: [] },
    fixe_phone: '',
    mobile_phone: '',
    email: '',
  },
  address: {
    id: 0,
    worksite_id: 0,
    address_type: 0,
    address: '',
    postal_code: '',
    city: '',
    country: '',
    elevation: '',
    latitude: 0,
    longitude: 0,
    additional_address: '',
    selected: false,
  },
  worksites_operations: [
    {
      surface: 0,
      replaced_energy: '',
      id: 0,
      reference: '',
      kwhc_precaire: 0,
      kwhc_classique: 0,
      cee_amount: 0,
      cee_classique: 0,
      cee_precaire: 0,
      cdp_amount: 0,
      mpr_amount: 0,
      system_type: 0,
      status: 0,
      operation_invalid_fixed: null,
      operation: {
        have_cofrac: false,
        have_contact_control: false,
        id: 0,
        code: '',
        description: '',
        can_b2c: false,
        can_b2b: false,
      },
      total_cost: 0,
      revisionStatus: [{ created_at: '', days: 0, old_status: 0, status: '' }],
      linkedFiles: [
        {
          commentary: '',
          created_at: '',
          uploaded_at: '',
          file: '',
          file_type: 0,
          file_url: '',
          file_name: '',
          id: 0,
          is_deletable: false,
          linked_entity_id: 0,
          mandatory: false,
          relation_ids: [0],
          relation_type: '',
          status: 0,
          isolate_file: false,
          file_hash: null,
          custom_field_id: null,
        },
      ],
    },
  ],
  total_cost: 0,
  updated_at: '',
  convention: {
    contactInstaller: [
      {
        id: 0,
        convention_id: 0,
        contract_id: 0,
        firstname: '',
        lastname: '',
        function: '',
        phone_number: '',
        email: '',
        entity_type: 0,
        contact_type: 0,
        signature_status: '',
      },
    ],
    convention_status: 0,
    created_at: '',
    end_date: '',
    from_entity_type: 0,
    id: 0,
    reference: '',
    internal_reference: '',
    referent: {
      id: 0,
      convention_id: 0,
      contract_id: 0,
      firstname: '',
      lastname: '',
      function: '',
      phone_number: '',
      email: '',
      entity_type: 0,
      contact_type: 0,
      contactUser: '',
      signature_status: '',
    },
    start_date: '',
    volume_classique: 0,
    volume_precaire: 0,
    rai: {
      active: false,
      address: '',
      city: '',
      company_name: '',
      contact_email: null,
      country: null,
      id: 0,
      phone_number: null,
      type: null,
      zipcode: '',
      siret: '',
    },
  },
  entity_to: {
    active: false,
    address: '',
    city: '',
    company_name: '',
    contact_email: null,
    country: null,
    id: 0,
    phone_number: null,
    type: null,
    zipcode: '',
    siret: '',
  },
  installer: {
    active: true,
    address: '',
    city: '',
    company_name: '',
    contact_email: '',
    country: '',
    employees_number: 0,
    id: 0,
    logo: '',
    phone_number: '',
    revenue: 0,
    type: 0,
    website: '',
    zipcode: '',
    siret: '',
    certifications: [],
  },
  amo: null,
  revisionStatus: [{ created_at: '', days: 0, old_status: 0, status: '' }],
  linkedFiles: [
    {
      commentary: '',
      created_at: '',
      uploaded_at: '',
      file: '',
      file_type: 0,
      file_url: '',
      file_name: '',
      id: 0,
      is_deletable: false,
      linked_entity_id: 0,
      mandatory: false,
      relation_ids: [0],
      relation_type: '',
      status: 0,
      isolate_file: false,
      file_hash: null,
      custom_field_id: null,
    },
  ],
};

export const initialGlobalEnum: IGlobalEnumType = {
  empty: {
    0: 'Empty',
  },
};

export const initDocumentActive: IActiveDocument = {
  list: '',
  document: {
    id: null,
    commentary: null,
    created_at: null,
    uploaded_at: null,
    file: null,
    file_type: 0,
    file_url: null,
    file_name: null,
    linked_entity_id: 0,
    mandatory: true,
    relation_ids: [1],
    relation_type: 'worksite-operation',
    status: 0,
    isolate_file: false,
    is_deletable: false,
    file_hash: null,
    custom_field_id: null,
  },
};

export const initialChange: IChange = {
  id: 0,
  demand_id: 0,
  relation_type: '',
  relation_id: 0,
  title: '',
  type: '',
  is_read: false,
  created_at: '',
  linked_file: null,
  internal_messages: [
    {
      id: 0,
      from_user: {
        id: 0,
        current_entity_type: 0,
        firstname: '',
        lastname: '',
        email: '',
        phone_number: '',
        function: '',
        photo_url: '',
        role_name: '',
      },
      message: '',
      description: null,
      action: 0,
      is_read: false,
      created_at: '',
    },
  ],
};

export const initialDocument: ILinkedFile = {
  id: null,
  commentary: '',
  created_at: '',
  uploaded_at: '',
  file: null,
  file_type: 0,
  file_url: '',
  file_name: '',
  linked_entity_id: 0,
  relation_ids: [0],
  relation_type: '',
  status: 1,
  isolate_file: false,
  is_deletable: true,
  file_hash: null,
  custom_field_id: null,
};

export const initialOtherDocument = (worksiteId: number): ILinkedFile => {
  return {
    id: null,
    relation_ids: [worksiteId],
    relation_type: 'worksite',
    file_type: 16,
    status: 1,
    isolate_file: false,
    file_url: null,
    file_name: null,
    linked_entity_id: null,
    commentary: null,
    file: null,
    created_at: null,
    uploaded_at: null,
    is_deletable: null,
    mandatory: false,
    file_hash: null,
    custom_field_id: null,
  };
};

export const initialGhostDocument = (
  fileType: number,
  userId: number | null,
  entityId: number | null,
  entityTypeId?: number | null,
  mandatory?: boolean,
  order?: number
): ILinkedFile => {
  return {
    id: null,
    relation_ids: userId ? [userId] : null,
    relation_type: 'user',
    file_type: fileType,
    status: 1,
    isolate_file: false,
    file_url: null,
    file_name: null,
    linked_entity_id: entityId,
    commentary: null,
    file: null,
    created_at: null,
    uploaded_at: null,
    created_by: entityTypeId,
    is_deletable: null,
    mandatory: mandatory ?? true,
    order,
    file_hash: null,
    custom_field_id: null,
  };
};

export const initialContractContact: IContractPartnerInfo = {
  id: null,
  company_name: '',
  siret: '',
  address: DEFAULT_BENEFICIARY.address,
  signatory_id: null,
};

export const initialContractPaymentConditions: IContractPaymentCondition = {
  trigger_type: null,
  trigger_percent: null,
  delay_days: null,
};
