/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-spread */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { blueOpx } from '@assets/color';
import { LoaderHeaderDocument } from '@components/loaders/document/LoaderHeaderDocument';
import { GlobalContext } from '@context/globalContext';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { dateToDDMMYYY, formatWord } from '@utils/format';
import {
  getLinkedFileArchives,
  updateContractLinkedFile,
} from '@apiRequests/globalRequests';
import {
  customStatusSignature,
  DOCUMENT_STATE_STRING,
  SIGNATURE_STATE,
  DOCUMENT_TYPES,
  DOCUMENT_STATUS_ENUM,
  openInNewWindow,
} from '@utils/utils';
import { ShoppingCartIcon } from '@assets/images/svgComponents';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { getNbCredits } from '@models/entities/apiRequests/entitiesRequests';
import { ColorCube } from '@components/atomic/ColorCube';
import { t } from 'i18next';
import { Loader } from '@components/atomic/Loader';
import { getSignatureProcedure } from '@models/linkedFile/apiRequests/linkedFileRequests';
import { convertHexToRGBA } from '@utils/functions';
import { pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { DOCUMENT_CHECKPOINT_ROUTE_WITH_WORKSITE_ID } from '@utils/routesUrls';
import { useNavigate } from 'react-router-dom';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { ILinkedFile } from '../../types/globalTypes';
import { DocumentModalCreditsPayment } from './DocumentModalCreditsPayment';
import { DocumentPreview } from './DocumentPreview';
import DocumentStatusTag from './DocumentStatusTag';
import { FormDisplaySignature } from './DocumentViewerSignatureForms/FormDisplaySignature';
import { FormInputSignature } from './DocumentViewerSignatureForms/FormInputSignature';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;

interface IDocumentViewerProps {
  isLoading: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  addClassHeight?: string;
  isSizingNote?: string;
  reference?: string;
  canEdit?: boolean;
  withoutHeader?: boolean;
  showArchives?: boolean;
  isBeneficiaryView?: boolean;
  openInNewWindowButton?: boolean;
  isCheckpoint?: boolean;
  hideBorder?: boolean;
}

interface ISignatureProcedure {
  beneficiary_email: string;
  beneficiary_phone_number: string;
  created_at: string;
  id: number;
  linked_file_id: number;
  signatory: string;
  signed_at: string;
  status: number;
}

function DocumentViewer({
  isLoading,
  setIsLoading,
  addClassHeight,
  isSizingNote,
  reference,
  canEdit,
  withoutHeader,
  showArchives,
  isBeneficiaryView,
  openInNewWindowButton,
  isCheckpoint,
  hideBorder,
}: IDocumentViewerProps) {
  const { globalEnum, documentActive, updateDocumentActive, userView } =
    useContext(GlobalContext);
  const { sizingNote } = useContext(WorksiteCreationContext);
  const { worksiteDetails } = useContext(WorksitesContext);

  const [selectedStatus, setSelectedStatus] = useState<string>(
    documentActive
      ? globalEnum.linked_file_status[documentActive.document.status]
      : ''
  );
  const [urlDocumentActive, setUrlDocumentActive] = useState<string | null>(
    null
  );
  const [dateDocumentActive, setDateDocumentActive] = useState<string | null>(
    null
  );
  const [archiveData, setArchiveData] = useState<ILinkedFile[]>([]);

  const [isModalBuyCredits, setIsModalBuyCredits] = useState(false);
  const [isAskingSignature, setIsAskingSignature] = useState(false);
  const [nbCreditsEntity, setNbCreditsEntity] = useState(0);
  const [signatureProcedure, setSignatureProcedure] =
    useState<ISignatureProcedure>();
  const [isFormulaireEnvoiSignature, setIsFormulaireEnvoiSignature] =
    useState(false);
  const [
    isFormulaireAnnulerRelancerSignature,
    setIsFormulaireAnnulerRelancerSignature,
  ] = useState(false);
  const [isFormulaireSigne, setIsFormulaireSigne] = useState(false);
  const [statusDoc, setStatusDoc] = useState<string>();

  const navigate = useNavigate();

  /**
   * Cette fonction gère le comportement spécifique pour les documents de type "Attestation sur l'Honneur" (AH).
   */
  const manageAHBehavior = async () => {
    if (documentActive.document.file_type === DOCUMENT_TYPES.AH) {
      documentActive.document.custom_title = t(
        'global.document_ah_generated'
      ).toString();

      if (
        worksiteDetails &&
        worksiteDetails.convention &&
        worksiteDetails.convention.is_electronic_signature
      ) {
        if (documentActive.document.id) {
          const resultGetSignatureProcedure = await getSignatureProcedure(
            documentActive.document.id
          );
          if (resultGetSignatureProcedure.status === 404) {
            setSignatureProcedure(undefined);
          }
          if (resultGetSignatureProcedure.data.data) {
            setSignatureProcedure(resultGetSignatureProcedure.data.data);
          }
        }
      }

      if (userView && userView.entity_id) {
        getNbCredits(userView.entity_id).then((res) => {
          if (res) {
            setNbCreditsEntity(res);
          }
        });
      }
    }
  };

  const getArchives = async (id: number) => {
    const response = await getLinkedFileArchives(id);
    if (response && response.data) {
      setArchiveData(response.data);
    }
  };

  const changeStatus = (label: string) => {
    setSelectedStatus(label);
    const element = Object.values(globalEnum.linked_file_status).indexOf(label);
    const actualStatus =
      globalEnum.linked_file_status[documentActive.document.status];
    const status = Object.entries(globalEnum.linked_file_status)[element][0];
    if (
      status &&
      documentActive &&
      documentActive.document &&
      documentActive.document.id &&
      actualStatus !== label
    ) {
      updateContractLinkedFile(documentActive.document.id, Number(status)).then(
        (res) => {
          if (res.data) updateDocumentActive({ list: '', document: res.data });
        }
      );
    }
  };

  /**
   * setIsFormulaireEnvoiSignature(true) si :
   * 1. Le document est de type AH
   * 2. La convention du chantier autorise la signature électronique
   * 4. Il n'y a pas de SignatureProcedure liée au document, ou s'il existe, sont statut est égale à CANCELED
   */
  const manageIsFormulaireEnvoiSignature = () => {
    setIsFormulaireEnvoiSignature(true);
    if (documentActive.document.file_type !== DOCUMENT_TYPES.AH)
      setIsFormulaireEnvoiSignature(false);
    if (worksiteDetails && !worksiteDetails.convention.is_electronic_signature)
      setIsFormulaireEnvoiSignature(false);
    if (
      signatureProcedure &&
      (customStatusSignature[signatureProcedure.status] ===
        SIGNATURE_STATE.AWAITING_SIGNATURE ||
        customStatusSignature[signatureProcedure.status] ===
          SIGNATURE_STATE.SIGNED)
    ) {
      setIsFormulaireEnvoiSignature(false);
    }
  };

  /**
   * setIsFormulaireAnnulerRelancerSignature(true) si :
   * 1. Le document est de type AH
   * 3. La convention du chantier autorise la signature électronique
   * 4. Il y a une SignatureProcedure liée au document et son statut est égale à AWAITING_SIGNATURE
   */
  const manageIsFormulaireAnnulerRelancerSignature = () => {
    setIsFormulaireAnnulerRelancerSignature(true);
    if (documentActive.document.file_type !== DOCUMENT_TYPES.AH)
      setIsFormulaireAnnulerRelancerSignature(false);
    if (worksiteDetails && !worksiteDetails.convention.is_electronic_signature)
      setIsFormulaireAnnulerRelancerSignature(false);
    if (
      !signatureProcedure ||
      (customStatusSignature[signatureProcedure.status] !==
        SIGNATURE_STATE.AWAITING_SIGNATURE &&
        customStatusSignature[signatureProcedure.status] !==
          SIGNATURE_STATE.SIGNED)
    )
      setIsFormulaireAnnulerRelancerSignature(false);
  };

  /**
   * setIsFormulaireSigne(true) si :
   * 1. Le document est de type AH
   * 3. La convention du chantier autorise la signature électronique
   * 4. Il y a une SignatureProcedure liée au document et son statut est égale à SIGNED
   */
  const manageIsFormulaireSigne = () => {
    setIsFormulaireSigne(true);
    if (documentActive.document.file_type !== DOCUMENT_TYPES.AH)
      setIsFormulaireSigne(false);
    if (worksiteDetails && !worksiteDetails.convention.is_electronic_signature)
      setIsFormulaireSigne(false);
    if (
      !signatureProcedure ||
      customStatusSignature[signatureProcedure.status] !==
        SIGNATURE_STATE.SIGNED
    )
      setIsFormulaireSigne(false);
  };

  const handleCheckDocument = () => {
    if (documentActive.document.id) {
      navigate(
        DOCUMENT_CHECKPOINT_ROUTE_WITH_WORKSITE_ID(documentActive.document.id),
        {
          state: {
            worksiteDetails,
            documentDetails: documentActive.document,
          },
        }
      );
    }
  };
  const isPending =
    documentActive.document.status === DOCUMENT_STATUS_ENUM.PENDING;

  const docStatus = useMemo(() => {
    const label = globalEnum.linked_file_status[documentActive.document.status];
    setSelectedStatus(label);
    return label;
  }, [documentActive]);

  const typeIsImage = useMemo(() => {
    const isBlob = urlDocumentActive?.includes('blob:');
    const parts = urlDocumentActive?.split('?')[0].split('.');
    if (!parts) return false;
    const extension = parts[parts.length - 1];

    return (
      !isBlob &&
      ((extension && extension.toLowerCase() !== 'pdf') ||
        [6, 12].includes(documentActive.document.file_type))
    );
  }, [documentActive]);

  const inputColor = useMemo(() => {
    if (formatWord(selectedStatus).includes('signe')) return 'success';
    return undefined;
  }, [selectedStatus]);

  const archives = useMemo(() => {
    if (!archiveData) return [];

    const dataArchive = archiveData
      .filter((archive) => archive.file_url !== null)
      .map((archive) => ({
        value: String(archive.file_url || ''),
        label: dateToDDMMYYY(archive.uploaded_at || '', true),
      }));

    return [
      {
        value: String(documentActive.document.file_url || ''),
        label:
          documentActive.document.uploaded_at &&
          !documentActive.document.uploaded_at.includes('/')
            ? dateToDDMMYYY(documentActive.document.uploaded_at || '', true)
            : documentActive.document.uploaded_at || '',
      },
      ...dataArchive,
    ];
  }, [documentActive, archiveData]);

  const showCheckpointButton = useMemo(() => {
    if (!worksiteDetails) return false;

    if (
      worksiteDetails.entity_to.id !== userView?.entity_id ||
      worksiteDetails.status <= WORKSITE_STATUS.WORKSITE_CREATION ||
      isBeneficiaryView ||
      documentActive.document.file_type === DOCUMENT_TYPES.AH
    ) {
      return false;
    }

    return documentActive.document.status <= DOCUMENT_STATUS_ENUM.VALIDATE;
  }, [documentActive, isBeneficiaryView, userView, worksiteDetails]);

  useEffect(() => {
    setUrlDocumentActive(documentActive.document.file_url);

    let dateDoc = documentActive.document.uploaded_at || '';

    if (dateDoc !== '' && !dateDoc.includes('/')) {
      dateDoc = dateToDDMMYYY(dateDoc);
    }
    setDateDocumentActive(dateDoc);

    if (
      documentActive.document.id &&
      showArchives &&
      documentActive.document.file_type !== DOCUMENT_TYPES.AH
    ) {
      getArchives(documentActive.document.id);
    }

    manageAHBehavior();

    if (
      documentActive.document.file_type === DOCUMENT_TYPES.SignedAH &&
      documentActive.document.id
    ) {
      getSignatureProcedure(documentActive.document.id)
        .then((res) => {
          setSignatureProcedure(res?.data.data);
        })
        .catch((err) => {
          toast.error(err);
          console.error(err);
          setSignatureProcedure(undefined);
        });
      setIsFormulaireAnnulerRelancerSignature(true);
      setIsFormulaireSigne(true);
    }
  }, [documentActive]);

  useEffect(() => {
    if (urlDocumentActive === documentActive.document.file_url) {
      setDateDocumentActive(
        documentActive.document.uploaded_at
          ? dateToDDMMYYY(documentActive.document.uploaded_at, true)
          : ''
      );
    } else {
      const archive = archiveData.find(
        (item) => item.file_url === urlDocumentActive
      );
      setDateDocumentActive(
        archive && archive.uploaded_at
          ? dateToDDMMYYY(archive.uploaded_at, true)
          : ''
      );
    }
  }, [urlDocumentActive, documentActive, archiveData]);

  useEffect(() => {
    if (signatureProcedure) {
      switch (customStatusSignature[signatureProcedure.status]) {
        case SIGNATURE_STATE.AWAITING_SIGNATURE:
          setStatusDoc(DOCUMENT_STATE_STRING.AWAITING_SIGNATURE);
          break;
        case SIGNATURE_STATE.SIGNED:
          setStatusDoc(DOCUMENT_STATE_STRING.SIGNED);
          break;
        default:
          setStatusDoc(DOCUMENT_STATE_STRING.TO_SIGN);
          break;
      }
    }
  }, [signatureProcedure]);

  useEffect(() => {
    manageIsFormulaireEnvoiSignature();
    manageIsFormulaireAnnulerRelancerSignature();
    manageIsFormulaireSigne();
  }, [signatureProcedure, documentActive]);

  // ---------- RETURN ----------
  return (
    <div
      className={`w-full bg-white overflow-hidden ${
        !isSizingNote ? 'py-[1.5rem] px-[2.75rem]' : ''
      }  ${
        !hideBorder ? 'border border-borderGrey rounded-default ' : 'border-0'
      } flex flex-col space-y-[1.5rem]`}
      data-test-id="document_viewer"
    >
      {!urlDocumentActive && !isLoading && !isSizingNote ? (
        <>
          <div className="flex flex-col space-y-[.25rem]">
            <p className="text-[1rem] leading-[1.25rem]">
              {documentActive.list}
            </p>
          </div>
          <div
            className={`w-full ${
              addClassHeight ? 'h-[60vh]' : 'h-[80vh]'
            } rounded-default bg-backgroundBody`}
          />
        </>
      ) : (
        <>
          {isLoading && !isSizingNote ? (
            <LoaderHeaderDocument />
          ) : !isSizingNote && !isLoading && !withoutHeader ? (
            <>
              <div className="flex justify-between relative">
                <div className="flex flex-col gap-y-[1rem] space-y-[.25rem] w-full">
                  <div className="flex w-full justify-between">
                    <div className="w-full">
                      <div className="flex gap-x-[.5rem] w-full">
                        {!isAskingSignature && (
                          <div className="w-full flex justify-between">
                            <div>
                              <p
                                className="text-[1rem]"
                                data-test-id="document_title"
                              >
                                {documentActive.document.custom_name
                                  ? documentActive.document.custom_name
                                  : reference || documentActive.listAsTitle
                                  ? documentActive.list
                                  : globalEnum.linked_file_type[
                                      documentActive.document.file_type
                                    ]}
                              </p>
                              <p
                                className="text-[.75rem] text-textGrey"
                                data-test-id="document_date"
                              >
                                {urlDocumentActive !==
                                  documentActive.document.file_url &&
                                !isFormulaireSigne
                                  ? `archive (${dateDocumentActive})`
                                  : documentActive.document.file_type !==
                                      DOCUMENT_TYPES.AH && dateDocumentActive}
                                {documentActive.document.file_type ===
                                  DOCUMENT_TYPES.AH &&
                                  !isFormulaireSigne &&
                                  `Générée le ${dateDocumentActive}`}
                              </p>
                              {/* sizingNote fait parti du worksiteCreation
                                ici on l'utilise pour cacher le statut lors de la création du chantier */}
                              {!isBeneficiaryView &&
                                sizingNote === undefined &&
                                documentActive.document.file_type ===
                                  DOCUMENT_TYPES.AH &&
                                worksiteDetails &&
                                worksiteDetails.convention &&
                                worksiteDetails.convention
                                  .is_electronic_signature &&
                                statusDoc !== '' && (
                                  <DocumentStatusTag
                                    status={documentActive.document.status}
                                  />
                                )}
                            </div>
                            <div className="flex flex-col gap-y-3">
                              {showCheckpointButton && (
                                <div
                                  className={`${
                                    !isPending ? 'border border-borderGrey' : ''
                                  } rounded-default`}
                                >
                                  <ButtonOpx
                                    small
                                    label={
                                      isPending
                                        ? t('global.document_check_processing')
                                        : t('global.document_control_completed')
                                    }
                                    onClick={handleCheckDocument}
                                    type={isPending ? 'primary' : 'secondary'}
                                  />
                                </div>
                              )}
                              {archiveData &&
                                archiveData.length > 0 &&
                                documentActive.document.file_type !==
                                  DOCUMENT_TYPES.Other && (
                                  <InputSelect
                                    placeholder=""
                                    dataLabelValue={archives}
                                    valueInput="Versions"
                                    onSelectLabelValue={(item) => {
                                      if (urlDocumentActive !== item.value) {
                                        setUrlDocumentActive(item.value);
                                        setDateDocumentActive(
                                          dateToDDMMYYY(item.label)
                                        );
                                      }
                                    }}
                                    hideScrollBar
                                  />
                                )}
                            </div>
                          </div>
                        )}
                        {!isBeneficiaryView &&
                          isAskingSignature &&
                          documentActive.document.file_type ===
                            DOCUMENT_TYPES.AH && (
                            <div className="flex gap-x-[.5rem]">
                              <p className="text-[1rem]">
                                {t('global.sending')}
                              </p>
                              <Loader />
                            </div>
                          )}
                      </div>

                      {isFormulaireSigne &&
                        signatureProcedure &&
                        signatureProcedure.signed_at && (
                          <p className="text-[0.75rem] text-textGrey">
                            {t('global.signed_at')} :{' '}
                            {dateToDDMMYYY(signatureProcedure?.signed_at)}
                          </p>
                        )}
                    </div>
                    {documentActive.document.file_type === DOCUMENT_TYPES.AH &&
                      worksiteDetails &&
                      worksiteDetails.convention &&
                      worksiteDetails.convention.is_electronic_signature && (
                        <div className="flex justify-end gap-x-[.5rem]">
                          <span className="text-[.75rem] text-textGrey mt-2">
                            {t('global.document_nb_credits')} :{' '}
                            {nbCreditsEntity}
                          </span>
                          <ColorCube
                            numberOrIcon={<ShoppingCartIcon />}
                            size="2rem"
                            backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
                            color={blueOpx}
                            addClass="cursor-pointer p-2"
                            onClick={() => setIsModalBuyCredits(true)}
                          />
                          {isModalBuyCredits && (
                            <DocumentModalCreditsPayment
                              setNbCreditsEntity={setNbCreditsEntity}
                              setIsModalBuyCredits={setIsModalBuyCredits}
                            />
                          )}
                        </div>
                      )}
                    {openInNewWindowButton &&
                      documentActive.document.file_url && (
                        <ButtonOpx
                          label={t('buttons.open_in_new_window')}
                          small
                          onClick={() =>
                            openInNewWindow(
                              String(documentActive.document.file_url)
                            )
                          }
                        />
                      )}
                  </div>
                </div>
                {canEdit && (
                  <InputSelect
                    placeholder=""
                    defaultSelected={docStatus}
                    dataArrayString={Object.values(
                      globalEnum.linked_file_status
                    ).filter(
                      (elt) =>
                        elt.includes(DOCUMENT_STATE_STRING.SIGNED) ||
                        elt.includes(DOCUMENT_STATE_STRING.AWAITING_SIGNATURE)
                    )}
                    addClass="w-[13.5rem] absolute right-0 top-[-1rem] z-20"
                    onSelect={(value) => changeStatus(String(value))}
                    color={inputColor}
                    borderRadius={6}
                  />
                )}
              </div>
              {!isBeneficiaryView &&
                isFormulaireEnvoiSignature &&
                !isCheckpoint && (
                  <FormInputSignature
                    setIsFormulaireEnvoiSignature={
                      setIsFormulaireEnvoiSignature
                    }
                    setIsFormulaireAnnulerRelancerSignature={
                      setIsFormulaireAnnulerRelancerSignature
                    }
                    nbCreditsEntity={nbCreditsEntity}
                    setNbCreditsEntity={setNbCreditsEntity}
                    setSignatureProcedure={setSignatureProcedure}
                    isAskingSignature={isAskingSignature}
                    setIsAskingSignature={setIsAskingSignature}
                    setStatusDoc={setStatusDoc}
                  />
                )}
              {!isBeneficiaryView && isFormulaireAnnulerRelancerSignature && (
                <FormDisplaySignature
                  signatureProcedure={signatureProcedure}
                  setIsFormulaireAnnulerRelancerSignature={
                    setIsFormulaireAnnulerRelancerSignature
                  }
                  setIsFormulaireEnvoiSignature={setIsFormulaireEnvoiSignature}
                  nbCreditsEntity={nbCreditsEntity}
                  setNbCreditsEntity={setNbCreditsEntity}
                  isFormulaireSigne={isFormulaireSigne}
                  setStatusDoc={setStatusDoc}
                />
              )}
            </>
          ) : null}
          {typeIsImage ? (
            <div
              className="overflow-auto flex justify-center items-start"
              style={{ width: '100%', height: '65vh' }}
            >
              <img
                src={urlDocumentActive || ''}
                alt="document active"
                className="rounded-default object-contain max-h-full"
                style={{ maxWidth: '100%' }}
              />
            </div>
          ) : (
            <DocumentPreview
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              addClassHeight={addClassHeight}
              sizingNote={isSizingNote}
              urlDocumentActive={urlDocumentActive}
            />
          )}
        </>
      )}
    </div>
  );
}

export { DocumentViewer };

DocumentViewer.defaultProps = {
  addClassHeight: undefined,
  isSizingNote: undefined,
  reference: '',
  canEdit: false,
  withoutHeader: false,
  showArchives: false,
  setIsLoading: undefined,
  isBeneficiaryView: false,
  openInNewWindowButton: false,
  isCheckpoint: false,
  hideBorder: false,
};
