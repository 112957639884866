/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { urlGetSignatories, urlGetSignatureProcedure } from '@api/apiUrls';
import { displayError } from '@utils/format';

export const getSignatories = async (id: number, email?: string | null) => {
  try {
    const response = await axiosClient.post(urlGetSignatories(id), { email });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getSignatureProcedure = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetSignatureProcedure(id));
    return response;
  } catch (error) {
    if (error.response && error.response.status !== 404) {
      displayError(error.response);
    }
    return error.response;
  }
};
