import {
  blueSecondary,
  borderGrey,
  red,
  textGrey,
  purple,
  orange,
  blueOpx,
} from '@assets/color';
import { ChevronDownIcon, ListDotIcon } from '@assets/images/svgComponents';
import { Tag } from '@components/atomic/Tag';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
import {
  getListRequests,
  markNoteAsRead,
} from '@models/requests/apiRequest/requestRequests';
import { getListChanges } from '@apiRequests/changesRequest';
import { useContext } from 'react';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { WORKSITES_ROUTES_WITH_ID } from '@utils/routesUrls';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { ColorCube } from '@components/atomic/ColorCube';
import { convertHexToRGBA } from '@utils/functions';
import { AuthContext } from '@context/authContext';
import { IPayloadDataPostListChanges } from '../../../types/changesTypes';
import {
  IOneRequestInList,
  IPayloadDataPostListRequests,
} from '../types/requestTypes';

interface IOneRequest {
  request: IOneRequestInList;
  borderBottom: boolean;
  isKanban: boolean;
}

function OneRequest({ request, borderBottom, isKanban }: IOneRequest) {
  const {
    updateListRequests,
    updateIsLoadingListRequestOrChange,
    updateTotalRequestsList,
    listRequests,
    updateListChanges,
    updateTotalChangesList,
    updateRequestOrChangeEnumInModal,
    updateRequestOrChangeTab,
    updateDemandIdForNote,
    updateRequestOrChangeEnumInCard,
  } = useContext(RequestAndActivityIntoModalOrCardContext);

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const date = moment(request.created_at, 'YYYY-MM-DD HH:mm:ss');
  const now = moment();
  const diff = now.diff(date, 'days');
  const action = request.tags?.filter((tag) => tag !== null);
  const requestAuto = request.types?.filter((type) =>
    type.includes('Automatique')
  );
  const requestPerso = request.types?.filter((type) =>
    type.includes('Personnalisée')
  );

  // Function to truncate the description, respecting word boundaries
  const truncateDescription = (description: string) => {
    if (description.length > 40) {
      const truncatedText = description.substring(0, 40);

      return `${truncatedText}...`;
    }
    return description;
  };

  const setTaskActivityTab = async () => {
    updateRequestOrChangeTab(null);
    updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.REQUEST);
    // when a note is clicked, load 'Activités' tab else 'Taches' tab
    if (request.relation_type === 'note') {
      updateRequestOrChangeTab(RequestOrMessageOrChangeEnum.CHANGE);
      updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.CHANGE);
      updateDemandIdForNote(request.demands[0].id);

      // set note as read if targetted user is user connected
      if (user && user.id === request.to_user_id) {
        await markNoteAsRead(request.demands[0].id);
      }
    }
  };

  const getRelatedInfos = async () => {
    const dataPostRequest: IPayloadDataPostListRequests = {
      pagination: { page: 1, perPage: 10 },
      status: 1,
      relation_id: request.demands[0].relation_id,
      relation_type: 'worksite',
    };
    const dataPostChanges: IPayloadDataPostListChanges = {
      pagination: { page: 1, perPage: 15 },
      relation_id: request.demands[0].relation_id,
      relation_type: 'worksite',
    };

    await getListRequests(
      updateListRequests,
      updateTotalRequestsList,
      listRequests,
      dataPostRequest,
      updateIsLoadingListRequestOrChange
    );

    await getListChanges(
      updateListChanges,
      updateTotalChangesList,
      dataPostChanges,
      updateIsLoadingListRequestOrChange
    );
    setTaskActivityTab();
    updateRequestOrChangeEnumInModal(null);

    navigate(
      WORKSITES_ROUTES_WITH_ID(request.demands[0].relation_id).WORKSITES_VIEW
    );
  };

  const tagHeaderWorksitePartner =
    request.relation_type === 'worksite'
      ? t('requests_and_changes.worksite')
      : t('requests_and_changes.partner');
  const getLabel =
    request.relation_type === 'note'
      ? request.reference
      : tagHeaderWorksitePartner;

  return (
    <button
      type="button"
      onClick={getRelatedInfos}
      style={{
        borderBottom: borderBottom ? `1px solid ${borderGrey}` : '',
      }}
      className="bg-white rounded-default w-full p-[1rem] pt-0"
    >
      <div className="flex items-center justify-between w-full">
        <Tag addClass="mt-[1rem]" color={textGrey} label={getLabel || ''} />
        {(request.relation_type === 'worksite' ||
          request.relation_type === 'note') &&
          request.status !== '0' && (
            <Tag
              addClass="mt-[1rem]"
              color={orange}
              label={`${t('requests_and_changes.step')} ${request.status}`}
            />
          )}
      </div>

      <div className="flex items-center justify-between mt-[1rem] w-full">
        <div className="flex items-center space-x-[.5rem] ">
          {!request.is_read && (
            <ListDotIcon fill={red} width=".5rem" height=".5rem" />
          )}
          <p className="font-medium text-[1rem]">{request.title}</p>
        </div>
        <Tag
          color={textGrey}
          label={
            diff === 0
              ? t('requests_and_changes.today')
              : `${t('requests_and_changes.there_are')} ${diff} ${t(
                  'requests_and_changes.day'
                )}${diff === 1 ? '' : 's'}`
          }
        />
      </div>
      <p className="text-textGrey text-[.875rem] text-left mt-[.5rem]">
        {request.relation_type === 'note'
          ? truncateDescription(request.description)
          : request.description}
      </p>
      {request.relation_type === 'note' && (
        <p className="py-[1px] pl-[1rem] pr-[.25rem] bg-backgroundBody rounded-[4px] flex justify-between m-auto items-center text-[.875rem] mt-[.5rem] font-medium ">
          {t('requests_and_changes.access_to_note')}
          <ColorCube
            size="1rem"
            color={blueOpx}
            opacity
            backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
            numberOrIcon={<ChevronDownIcon className="rotate-[-90deg]" />}
          />
        </p>
      )}
      <div className="flex items-center justify-between w-full mt-[1rem]">
        {action?.length > 0 ? (
          <Tag
            color={red}
            label={`${action.length} ${t(
              'requests_and_changes.task'
            ).toLowerCase()}${action.length > 1 ? 's' : ''}`}
          />
        ) : (
          <div />
        )}
        {request.relation_type !== 'note' && (
          <div className="flex items-center space-x-[.5rem]">
            {requestPerso.length > 0 && (
              <Tag
                color={purple}
                label={
                  !isKanban
                    ? requestPerso.length.toString()
                    : `${requestPerso.length.toString()} ${t(
                        `requests_and_changes.${
                          requestPerso.length > 1 ? 'requests' : 'request'
                        }_personalized`
                      ).toLowerCase()}`
                }
              />
            )}
            {requestAuto.length > 0 && (
              <Tag
                color={blueSecondary}
                label={
                  !isKanban
                    ? requestAuto.length.toString()
                    : `${requestAuto.length.toString()} ${t(
                        `requests_and_changes.${
                          requestAuto.length > 1 ? 'requests' : 'request'
                        }_automatic`
                      ).toLowerCase()}`
                }
              />
            )}
          </div>
        )}
      </div>
    </button>
  );
}

export { OneRequest };
