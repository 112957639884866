/* eslint-disable @typescript-eslint/no-unused-vars */
import { red } from '@assets/color';
import { AddFatIcon, TrashIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ColorCube } from '@components/atomic/ColorCube';
import { IColumn, IRow, ListTable } from '@components/ListTable';
import { GlobalContext } from '@context/globalContext';
import { HeaderContext } from '@context/headerContext';
import { ILabelValue } from '@globalTypes/globalTypes';
import { DeleteBrandModal } from '@models/brands/DeleteBrandModal';
import { DeleteMaterialModal } from '@models/brands/DeleteMaterialModal';
import {
  deleteBrand,
  deleteMaterial,
  getAllMaterialsByBrandId,
  getOperationsWithMaterial,
  updateMaterial,
} from '@models/materials/apiRequests/materialsRequests';
import MaterialForm from '@models/materials/components/MaterialForm';
import { ModalAddProduct } from '@models/materials/components/ModalAddProduct';
import {
  IMaterial,
  IMaterialFormInputs,
} from '@models/materials/utils/materialTypes';
import { SETTINGS_TABS } from '@models/settings/utils/utils';
import { createColumnHelper } from '@tanstack/react-table';
import { createFileWithLinkedFile } from '@utils/functions';
import { SETTINGS_ROUTES } from '@utils/routesUrls';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

function BrandsMaterials() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const { route, updateRoute } = useContext(GlobalContext);

  const [materials, setMaterials] = useState<IMaterial[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<IMaterial | null>(
    null
  );
  const [showDeleteBrandModal, setShowDeleteBrandModal] =
    useState<boolean>(false);
  const [showDeleteMaterialModal, setShowDeleteMaterialModal] =
    useState<boolean>(false);
  const [brandData, setBrandData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showNewProductModal, setShowNewProductModal] =
    useState<boolean>(false);
  const [initialFile, setInitialFile] = useState<File | null>(null);
  const [resetSignalOperationsSelect, setResetSignalOperationsSelect] =
    useState<number>(0);

  // Création d'un état pour les options réelles
  const [operationsOptions, setOperationsOptions] = useState<ILabelValue[]>([]);
  const [optionsLoading, setOptionsLoading] = useState<boolean>(false);

  const methods = useForm<IMaterialFormInputs>({
    defaultValues: {
      product_name: '',
      product_comment: '',
      product_operations: [] as ILabelValue[],
      product_files: [],
    },
  });
  const { reset } = methods;

  const refetchProducts = async () => {
    if (id) {
      const r = await getAllMaterialsByBrandId(parseInt(id, 10), t);
      setBrandData(r);
      setMaterials(r.materials);
      updateTitleHeader(r.name);
      refreshHeader(true);
    }
  };

  const fetchOperationsOptions = useCallback(async () => {
    setOptionsLoading(true);
    const operations = await getOperationsWithMaterial();
    if (operations && Array.isArray(operations)) {
      const mappedOptions = operations.map((operation: any) => ({
        label: `${operation.code} - ${operation.description}`,
        value: operation.id.toString(),
      }));
      setOperationsOptions(mappedOptions);
    } else {
      setOperationsOptions([]);
    }
    setOptionsLoading(false);
  }, [t]);

  const columnHelper = createColumnHelper<IMaterial>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => 'id',
      }),
      columnHelper.accessor('name', {
        header: () => t('brand.products.own'),
      }),
    ],
    [t]
  );

  const handleUpdateMaterial = async (formData: FieldValues) => {
    if (selectedMaterial) {
      // Séparer les fichiers uploadés et les fichiers préremplis restants
      const allFiles: File[] = Array.isArray(formData.product_files)
        ? formData.product_files
        : [];
      const newFiles = allFiles.filter((file: any) => !file.isPrefilled);
      const existingFiles = allFiles
        .filter((file: any) => file.isPrefilled)
        .map((file: any) => file.prefilledURL);

      const updatedMaterial: IMaterial = {
        ...selectedMaterial,
        name: formData.product_name,
        comment: formData.product_comment,
        operations: formData.product_operations.map(
          (option: ILabelValue) => option.value
        ),
        files: newFiles, // nouveaux fichiers à uploader
        existing_files: existingFiles, // URLs des fichiers existants restant
      };

      const res = await updateMaterial(updatedMaterial, t, id);
      if (res) {
        await refetchProducts();
        const updatedFromList = materials.find(
          (mat) => mat.id === updatedMaterial.id
        );
        if (updatedFromList) {
          setSelectedMaterial(updatedFromList);
        } else {
          setSelectedMaterial(updatedMaterial);
        }
      }
    }
  };

  const handleDeleteMaterial = async () => {
    if (selectedMaterial) {
      const res = await deleteMaterial(selectedMaterial, t);

      if (res) {
        const newMaterials = materials.filter(
          (material) => material.id !== selectedMaterial.id
        );
        setMaterials(newMaterials);
        setSelectedMaterial(null);
        reset();
      }
    }
  };

  const handleDeleteBrand = async () => {
    if (id != null) {
      setIsLoading(true);
      await deleteBrand(parseInt(id, 10), t);
      setIsLoading(false);
      navigate(SETTINGS_ROUTES.SETTINGS);
    }
  };

  // ...
  const prefillForm = async () => {
    if (selectedMaterial) {
      const prefilledFiles = await Promise.all(
        (selectedMaterial.files || []).map(async (linkedFile: any) => {
          if ((linkedFile as any).isPrefilled) return linkedFile;

          const file = await createFileWithLinkedFile(
            linkedFile.file_url,
            linkedFile.file_name
          );
          (file as any).isPrefilled = true;
          (file as any).prefilledURL = linkedFile.file_url;
          return file;
        })
      );

      reset({
        product_name: selectedMaterial.name,
        product_comment: selectedMaterial.comment || '',
        product_operations: selectedMaterial.operations
          ? selectedMaterial.operations.map((option: any) => ({
              label: `${option.code} - ${option.description}`,
              value: String(option.id),
            }))
          : [],
        product_files: prefilledFiles,
      });
      setResetSignalOperationsSelect(resetSignalOperationsSelect + 1);
    }
  };

  useEffect(() => {
    prefillForm();
  }, [selectedMaterial]);

  useEffect(() => {
    if (route.older.length === 0) {
      updateRoute({
        to: route.to,
        older: [`${SETTINGS_ROUTES.SETTINGS}#${SETTINGS_TABS.BRANDS}`],
      });
    }
    refetchProducts();
    fetchOperationsOptions(); // Appel de la fonction pour récupérer les options
  }, []);

  useEffect(() => {
    if (selectedMaterial && materials.length > 0) {
      const updatedMaterial = materials.find(
        (material) => material.id === selectedMaterial.id
      );
      if (updatedMaterial) {
        setSelectedMaterial(updatedMaterial);
      }
    }
  }, [materials, selectedMaterial]);

  if (materials?.length === 0) {
    return (
      <div className="w-full flex flex-col pt-6">
        <div className="flex flex-col lg:flex-row-reverse gap-2">
          <ColorCube
            size="2.5rem"
            numberOrIcon={<TrashIcon />}
            color={red}
            opacity
            onClick={() => setShowDeleteBrandModal(true)}
          />
          <ButtonOpx
            label={t('brand.products.new')}
            icon={<AddFatIcon />}
            type="primary"
            onClick={() => setShowNewProductModal(true)}
          />
        </div>
        <div className="list_noElements flex items-center justify-center h-36 text-danger">
          {t('list.no_result')}
        </div>
        {showNewProductModal && id && (
          <ModalAddProduct
            brand_id={parseInt(id, 10)}
            setShowModal={setShowNewProductModal}
            refetch={refetchProducts}
            operationsOptions={operationsOptions}
          />
        )}
        {showDeleteBrandModal && (
          <DeleteBrandModal
            setShowModal={setShowDeleteBrandModal}
            brandData={brandData}
            deleteBrand={handleDeleteBrand}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col pt-6 h-full">
      <div className="flex flex-row-reverse items-center py-[1.5rem] gap-5">
        <ColorCube
          size="2.5rem"
          numberOrIcon={<TrashIcon />}
          color={red}
          opacity
          onClick={() => setShowDeleteBrandModal(true)}
        />
        <ButtonOpx
          label={t('brand.products.new')}
          icon={<AddFatIcon />}
          type="primary"
          onClick={() => setShowNewProductModal(true)}
        />
      </div>
      <div className="w-full flex flex-col lg:flex-row lg:gap-6 pt-6 h-full">
        <div className={`px-6 w-full ${selectedMaterial ? 'lg:w-1/3' : ' '}`}>
          <div className="w-full flex flex-col text-neutral-400 h-full">
            {materials?.length > 0 && (
              <ListTable
                loading={false}
                columns={columns as IColumn[]}
                data={materials}
                callBackOnRowClick={(e: IRow) => {
                  setSelectedMaterial(e.original as IMaterial);
                }}
                addClass="w-full"
                hiddenColumns={['id']}
              />
            )}
          </div>
        </div>
        {selectedMaterial && (
          <div className="border border-borderGrey rounded-default flex flex-col w-full lg:w-2/3 bg-white p-[1.5rem] h-full">
            <div className="flex flex-row justify-between items-center pb-[1.5rem] border-b-borderGrey">
              <p className="text-[1.5rem] font-medium">
                {`${t('brand.products.edit')} ${selectedMaterial.name}`}
              </p>
              <div className="flex gap-5">
                <ButtonOpx
                  label={t('brand.products.update')}
                  type="primary"
                  onClick={methods.handleSubmit(handleUpdateMaterial)}
                />
                <ColorCube
                  size="2.5rem"
                  numberOrIcon={<TrashIcon />}
                  color={red}
                  opacity
                  onClick={() => setShowDeleteMaterialModal(true)}
                />
              </div>
            </div>
            <FormProvider {...methods}>
              <MaterialForm
                forceResetSignal={resetSignalOperationsSelect}
                operationsOptions={operationsOptions}
                onSubmit={handleUpdateMaterial}
                formId="form-material-update"
              />
            </FormProvider>
          </div>
        )}
      </div>
      {showNewProductModal && id && (
        <ModalAddProduct
          brand_id={parseInt(id, 10)}
          setShowModal={setShowNewProductModal}
          refetch={refetchProducts}
          operationsOptions={operationsOptions}
        />
      )}
      {showDeleteBrandModal && (
        <DeleteBrandModal
          setShowModal={setShowDeleteBrandModal}
          brandData={brandData}
          deleteBrand={handleDeleteBrand}
          isLoading={isLoading}
        />
      )}

      {showDeleteMaterialModal && (
        <DeleteMaterialModal
          setShowModal={setShowDeleteMaterialModal}
          materialData={selectedMaterial}
          deleteMaterial={handleDeleteMaterial}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export { BrandsMaterials };
