import { CheckActif, CheckInactif } from '@assets/images/svgComponents';
import { Dispatch, SetStateAction } from 'react';

type ICheckBoxProps = {
  label?: string;
  isCheck: boolean;
  setIsCheck: Dispatch<SetStateAction<boolean>>;
  addClass?: string;
};

function CheckBox({
  label,
  isCheck,
  setIsCheck,
  addClass,
}: ICheckBoxProps): JSX.Element {
  return (
    <button
      type="button"
      className={[
        'w-full rounded-default border-[1px] border-borderGrey p-[.5rem] flex items-center space-x-[.5rem]',
        addClass,
      ].join(' ')}
      onClick={() => setIsCheck(!isCheck)}
    >
      {isCheck ? (
        <CheckActif width="1.25rem" height="1.25rem" />
      ) : (
        <CheckInactif width="1.25rem" height="1.25rem" />
      )}
      {label && <div className="text-[.875rem] leading-[1.25rem]">{label}</div>}
    </button>
  );
}

export { CheckBox };

CheckBox.defaultProps = {
  label: undefined,
  addClass: '',
};
