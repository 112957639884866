import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { CalendarIcon } from '@assets/images/svgComponents';
import { fetchWorksiteControlOfficeData } from '@models/worksites/apiRequests/worksitesRequests';
import { v4 } from 'uuid';
import { IWorksiteControlOfficesData } from '@models/worksites/utils/worksitesTypes';
import { getControlOfficesInfos } from '@models/worksites/utils/tab';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

interface ICardControlProps {
  worksiteId: number;
}

function CardOfficeControl({ worksiteId }: ICardControlProps) {
  const { t } = useTranslation();

  const [controlOffices, setControlOffices] = useState<
    IWorksiteControlOfficesData[] | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getControlOfficesData = async (id: number) => {
    try {
      setIsLoading(true);
      const res = await fetchWorksiteControlOfficeData(id);
      if (res) {
        setControlOffices(res);
      }
    } catch (error) {
      console.error('Error fetching worksite control office data:', error);
    }
    setIsLoading(false);
  };

  const controlOfficesInfos = controlOffices
    ? getControlOfficesInfos(t, controlOffices)
    : [];

  useEffect(() => {
    getControlOfficesData(worksiteId);
  }, [worksiteId]);

  return isLoading ? (
    <LoaderSkeleton height="1rem" addClass="w-full" />
  ) : (
    <div className="grid gap-2 grid-cols-1 p-6">
      {controlOfficesInfos
        ? controlOfficesInfos.map((infos) => (
            <div
              key={v4()}
              className="flex flex-col space-y-[.375rem] w-full mb-2.5"
            >
              <InfosWithIcon infos={infos} icon={<CalendarIcon />} />
            </div>
          ))
        : null}
    </div>
  );
}

export { CardOfficeControl };
