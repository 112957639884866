import {
  ContractsIcon,
  DashboardIcon,
  DepositsIcon,
  BeneficiariesIcon,
  PartnersIcon,
  WorksiteIcon,
  SupportContactIcon,
  SettingsIcon,
  CheckCircleIcon,
  PhoneIcon,
} from '@assets/images/svgComponents';
import { TFunction } from 'i18next';
import { ENTITY_TYPES, ROLES } from '@utils/roles';
import {
  ADMIN_ROUTES,
  BENEFICIARIES_ROUTES,
  CONTRACTS_ROUTES,
  CUSTOMERS_ROUTES,
  DASHBOARD_ROUTES,
  DEPOSITS_ROUTES,
  MAR_ROUTES,
  PARTNERS_ROUTES,
  SETTINGS_ROUTES,
  SUPPORT_URL,
  WORKSITES_ROUTES,
} from '@utils/routesUrls';
import { INavLinkSidebar } from '../../types/globalTypes';

const navLinksSidebar = (
  t: TFunction<string[], undefined, string[]>
): INavLinkSidebar[] => [
  {
    icon: <DashboardIcon />,
    name: t('sidebar.dashboard'),
    link: DASHBOARD_ROUTES.DASHBOARD,
    roles: [ROLES.PRODUCTION, ROLES.GESTION, ROLES.MAR],
    dataTestId: 'navlink_dashboard',
  },
  {
    icon: <WorksiteIcon />,
    name: t('sidebar.worksites'),
    link: WORKSITES_ROUTES.WORKSITES,
    roles: [ROLES.PRODUCTION, ROLES.GESTION],
    dataTestId: 'navlink_worksites',
  },
  {
    icon: <WorksiteIcon />,
    name: t('sidebar.projects'),
    link: MAR_ROUTES.WORKSITES_LIST,
    roles: [ROLES.MAR],
    dataTestId: 'navlink_worksites_mar',
  },
  {
    icon: <BeneficiariesIcon />,
    name: t('sidebar.installers'),
    link: PARTNERS_ROUTES.INSTALLERS,
    roles: [ROLES.GESTION],
    dataTestId: 'navlink_installers',
  },
  {
    icon: <CheckCircleIcon />,
    name: t('sidebar.cofrac'),
    link: PARTNERS_ROUTES.COFRAC,
    roles: [ROLES.GESTION],
    dataTestId: 'navlink_cofrac',
  },
  {
    icon: <PhoneIcon />,
    name: t('sidebar.control_contact'),
    link: PARTNERS_ROUTES.CONTROL_CONTACT,
    roles: [ROLES.GESTION],
    dataTestId: 'navlink_control_contact',
  },
  {
    icon: <DepositsIcon />,
    name: t('sidebar.deposits'),
    link: DEPOSITS_ROUTES.DEPOSITS,
    roles: [ROLES.GESTION],
    dataTestId: 'navlink_deposits',
  },
  {
    icon: <BeneficiariesIcon />,
    name: t('sidebar.beneficiaries'),
    link: BENEFICIARIES_ROUTES.BENEFICIARIES,
    roles: [ROLES.PRODUCTION],
    allowed_entities: [
      ENTITY_TYPES.ADMINISTRATOR,
      ENTITY_TYPES.INSTALLATEUR,
      ENTITY_TYPES.RESEAUX_INSTALLATEUR,
      ENTITY_TYPES.SOUS_TRAITANT,
    ],
    dataTestId: 'navlink_beneficiaries',
  },
  {
    icon: <BeneficiariesIcon />,
    name: t('sidebar.customers'),
    link: CUSTOMERS_ROUTES.CUSTOMERS,
    roles: [ROLES.MAR],
    dataTestId: 'navlink_customers',
  },
  {
    icon: <PartnersIcon />,
    name: t('sidebar.partners'),
    link: PARTNERS_ROUTES.PARTNERS,
    roles: [ROLES.PRODUCTION, ROLES.GESTION],
    dataTestId: 'navlink_partners',
    not_allowed_entities: [ENTITY_TYPES.BUREAUX_CONTROLE],
  },
  {
    icon: <ContractsIcon />,
    name: t('sidebar.contracts'),
    link: CONTRACTS_ROUTES.CONTRACTS,
    roles: [ROLES.PRODUCTION, ROLES.GESTION],
    dataTestId: 'navlink_contracts',
    not_allowed_entities: [ENTITY_TYPES.BUREAUX_CONTROLE],
  },
  {
    icon: <PartnersIcon />,
    name: t('sidebar.users'),
    link: ADMIN_ROUTES.ADMIN_USERS,
    roles: [ROLES.ADMIN],
    dataTestId: 'navlink_admin_users',
  },
  {
    icon: <WorksiteIcon />,
    name: t('sidebar.worksites'),
    link: ADMIN_ROUTES.ADMIN_WORKSITES,
    roles: [ROLES.ADMIN],
    dataTestId: 'navlink_admin_worksites',
  },
  {
    icon: <ContractsIcon />,
    name: t('sidebar.contracts'),
    link: ADMIN_ROUTES.ADMIN_CONTRACTS,
    roles: [ROLES.ADMIN],
    dataTestId: 'navlink_admin_contracts',
  },
  /* {
    icon: <EditIcon />,
    name: 'Design',
    link: '/design',
    roles: [ROLES.INSTALLER, ROLES.PILOT, ROLES.PRODUCTION],
    dataTestId: 'navlink_design',
  }, */
];

const navLinksSidebarBottom = (
  t: TFunction<string[], undefined, string[]>
): INavLinkSidebar[] => [
  {
    icon: <SupportContactIcon fill="white" />,
    name: t('sidebar.opx_support'),
    link: SUPPORT_URL,
    dataTestId: 'navlink_support',
  },
  {
    icon: <SettingsIcon fill="white" />,
    name: t('sidebar.settings'),
    link: SETTINGS_ROUTES.SETTINGS,
    dataTestId: 'navlink_setting',
  },
];

export { navLinksSidebar, navLinksSidebarBottom };
