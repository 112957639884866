import { blueOpx, green, red } from '@assets/color';
import { DownloadIcon, EditIcon, EuroIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { AuthContext } from '@context/authContext';
import { PRIME_TYPES } from '@models/settings/utils/enums';
import { IIframe } from '@models/settings/utils/settingsTypes';
import { generatePdfIframeUrl } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import ModalPrimesDistribution from '@models/worksiteCreation/components/simulation/summary/ModalPrimesDistribution';
import { calculateDateOfExpiration } from '@models/worksiteCreation/utils/functions';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { numFormatSpace, transformIsoDate } from '@utils/functions';
import { isInstaller } from '@utils/roles';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function PrimesSummary() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const {
    simulatorData,
    conventionActive,
    updateSimulatorData,
    worksiteDatas,
    isIframeMode,
    iframeData,
    worksiteOperationType,
    datasForPdfIframe,
    updateDatasForPdfIframe,
  } = useContext(WorksiteCreationContext);

  const totalCee = Number(simulatorData.totalCeeEuros);
  const totalMpr = Number(simulatorData.totalMprEuros);
  const totalGisement = Number(simulatorData.totalGisement) / 1000;
  const beneficiaryMinimumAmount = Number(
    simulatorData.beneficiaryMinimumAmount
  );

  const isInst = isInstaller(user);

  const [beneficiaryAmount, setBeneficiaryAmount] = useState<number>(
    beneficiaryMinimumAmount
  );
  const [modalPrimesDistributionOpen, setModalPrimesDistributionOpen] =
    useState<boolean>(false);
  const [loadingPdfGeneration, setLoadingPdfGeneration] =
    useState<boolean>(false);

  const installerAmount = totalCee - beneficiaryAmount;
  const beneficiaryAmountPercent = (beneficiaryAmount / totalCee) * 100;
  const installerAmountPercent = 100 - beneficiaryAmountPercent;

  const dateOfExpiration = calculateDateOfExpiration(
    worksiteDatas,
    conventionActive
  );

  const canValidateSimulation = dateOfExpiration >= new Date();

  const handleDownloadPdfIframe = async () => {
    try {
      if (datasForPdfIframe) {
        // Utiliser fetch pour télécharger le fichier en tant que blob
        const response = await fetch(datasForPdfIframe.url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'simulation-primes.pdf');
        document.body.appendChild(link);
        link.click();
        // Nettoyer
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
        return;
      }

      setLoadingPdfGeneration(true);
      const response = await generatePdfIframeUrl(
        iframeData as IIframe,
        simulatorData,
        worksiteOperationType,
        t
      );

      if (response) {
        updateDatasForPdfIframe(response);
        // Utiliser fetch pour télécharger le fichier en tant que blob
        const pdfResponse = await fetch(response.url);
        const blob = await pdfResponse.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'simulation-primes.pdf');
        document.body.appendChild(link);
        link.click();
        // Nettoyer
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
      }
      setLoadingPdfGeneration(false);
    } catch (error) {
      console.error('Erreur lors du téléchargement du PDF:', error);
      setLoadingPdfGeneration(false);
      // Méthode de secours en cas d'échec
      if (datasForPdfIframe) {
        window.open(datasForPdfIframe.url, '_blank');
      }
    }
  };

  useEffect(() => {
    if (beneficiaryAmount !== 0) {
      updateSimulatorData({
        ...simulatorData,
        beneficiaryAmount,
      });
    }
  }, [beneficiaryAmount]);

  useEffect(() => {
    updateDatasForPdfIframe(undefined);
  }, []);

  return (
    <div className="mb-[1rem] border border-borderGrey rounded-default">
      <div className="border-b border-b-borderGrey w-full flex justify-between items-center p-[1.5rem]">
        <p className="text-[1.25rem] font-medium">
          {t('worksite_creation.simulation.summary.offer_recap')}
        </p>
        {isIframeMode && (
          <ButtonOpx
            label={t('worksite_creation.simulation.summary.download_pdf')}
            type="secondary"
            icon={<DownloadIcon />}
            iconSize="1.5rem"
            onClick={() => handleDownloadPdfIframe()}
            addClass="!border !border-borderGrey"
            isLoading={loadingPdfGeneration}
          />
        )}
        {beneficiaryMinimumAmount !== 0 && (
          <EditIcon
            fill={blueOpx}
            className="cursor-pointer"
            onClick={() => setModalPrimesDistributionOpen(true)}
            width="1.5rem"
            height="1.5rem"
          />
        )}
      </div>
      {modalPrimesDistributionOpen && (
        <ModalPrimesDistribution
          setOpen={setModalPrimesDistributionOpen}
          setBeneficiaryAmount={setBeneficiaryAmount}
          totalCeeBonus={totalCee}
          beneficiaryAmount={beneficiaryAmount}
          installerAmount={installerAmount}
          beneficiaryAmountMinimum={beneficiaryMinimumAmount}
        />
      )}
      <div className="p-[1.5rem] grid grid-cols-2 gap-4">
        {(!isIframeMode ||
          (isIframeMode && iframeData?.prime_type !== PRIME_TYPES.MPR)) && (
          <InfosWithIcon
            icon={<EuroIcon />}
            spaceLeft
            infos={[
              {
                title: t(
                  'worksite_creation.simulation.summary.total_cee_bonus'
                ),
                subtitle: totalCee > 0 ? `${numFormatSpace(totalCee)} €` : '-',
              },
            ]}
          />
        )}
        {beneficiaryMinimumAmount !== 0 && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t(
                  'worksite_creation.simulation.summary.beneficiary_amount'
                ),
                subtitle: `${numFormatSpace(beneficiaryAmount)} €`,
                subtitleTag: {
                  color: green,
                  label: `${numFormatSpace(beneficiaryAmountPercent)} %`,
                },
              },
            ]}
          />
        )}
        <InfosWithIcon
          spaceLeft
          infos={[
            {
              title: t('worksite_creation.simulation.summary.total_cee_volume'),
              subtitle:
                totalGisement > 0
                  ? `${numFormatSpace(totalGisement)} MWhc`
                  : '-',
            },
          ]}
        />
        {beneficiaryMinimumAmount !== 0 && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t(
                  'worksite_creation.simulation.summary.installer_amount'
                ),
                subtitle: `${numFormatSpace(installerAmount)} €`,
                subtitleTag: {
                  color: green,
                  label: `${numFormatSpace(installerAmountPercent)} %`,
                },
              },
            ]}
          />
        )}
        {(totalMpr > 0 && !isIframeMode) ||
        (totalMpr > 0 &&
          isIframeMode &&
          iframeData?.prime_type !== PRIME_TYPES.CEE) ? (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t(
                  'worksite_creation.simulation.summary.total_mpr_bonus'
                ),
                subtitle: totalMpr > 0 ? `${numFormatSpace(totalMpr)} €` : '-',
              },
            ]}
          />
        ) : (
          <div />
        )}
        {(!isIframeMode || isInst) && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t(
                  'worksite_creation.simulation.summary.offer_validity_date'
                ),
                subtitle: dateOfExpiration
                  ? transformIsoDate(String(dateOfExpiration))
                  : '-',
              },
            ]}
            subtitleColor={!canValidateSimulation ? red : undefined}
          />
        )}
      </div>
    </div>
  );
}

export { PrimesSummary };
