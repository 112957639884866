import { toast } from 'react-toastify';
import { axiosClient } from '@api/apiClient';
import {
  urlBrandCreation,
  urlSettingsBrands,
  urlMaterialBrands,
  urlProductCreation,
  urlProductUpdate,
  urlProductDelete,
  urlBrandDelete,
  urlProductCreateByName,
  urlOperationsWithMaterial,
} from '@api/apiUrls';
import {
  IMaterial,
  IMaterialStore,
  IProductByName,
} from '@models/materials/utils/materialTypes';
import { TFunction } from 'i18next';
import { displayError } from '@utils/format';

export const getAllUserBrand = async () => {
  try {
    const response = await axiosClient.post(urlSettingsBrands);
    return response.data;
  } catch (error) {
    displayError(error);
    return false;
  }
};

export const getAllMaterialsByBrandId = async (id: number, t: TFunction) => {
  try {
    const url = `${urlMaterialBrands}/${id}`;
    const response = await axiosClient.post(url, {});
    return response.data;
  } catch (error: any) {
    toast.error(t('brand.get_error'));
    return false;
  }
};

export const storeBrand = async (name: string, t: TFunction) => {
  const nameUpperCase = name.toUpperCase();
  try {
    const response = await axiosClient.post(urlBrandCreation, {
      name: nameUpperCase,
    });
    toast.success(t('brand.added'));
    return response.data;
  } catch (error: any) {
    toast.error(t('brand.add_error'));
    return false;
  }
};

export const storeMaterial = async (
  materialData: IMaterialStore,
  t: TFunction
) => {
  const dataToSend = new FormData();
  dataToSend.append('name', materialData.name.toUpperCase());
  dataToSend.append('brand_id', materialData.brand_id.toString());
  dataToSend.append('comment', materialData.comment);

  materialData.operations?.forEach((operation) => {
    dataToSend.append('operations[]', String(operation));
  });

  if (materialData.files && materialData.files.length > 0) {
    materialData.files.forEach((file) => {
      dataToSend.append('files[]', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axiosClient.post(
      urlProductCreation,
      dataToSend,
      config
    );
    toast.success(t('brand.products.success_message'));
    return response.data;
  } catch (error: any) {
    toast.error(t('brand.products.error_message'));
    return false;
  }
};

export const deleteBrand = async (id: number, t: TFunction) => {
  try {
    const response = await axiosClient.post(urlBrandDelete(id));
    toast.success(t('brand.deleted'));
    return response.data;
  } catch (error: any) {
    toast.error(t('brand.delete_error'));
    return false;
  }
};

export const updateMaterial = async (
  material: IMaterial,
  t: TFunction,
  brandId?: string
) => {
  const dataToSend = new FormData();
  dataToSend.append('name', material.name.toUpperCase());
  dataToSend.append('caracteristics', material.caracteristics);
  dataToSend.append('entity_id', material.entity_id.toString());
  dataToSend.append('id', material.id.toString());
  dataToSend.append('comment', material.comment);
  if (brandId) {
    dataToSend.append('brand_id', brandId);
  }

  material.operations?.forEach((operation) => {
    dataToSend.append('operations[]', String(operation));
  });

  // Envoi les nouveaux fichiers de type File (les nouveaux fichiers uploadés par l'utilisateur)
  if (material.files && material.files.length > 0) {
    material.files.forEach((file: any) => {
      if (file instanceof File) {
        dataToSend.append('files[]', file);
      }
    });
  }

  // On envoie les fichiers existants restants (URLs) s'ils sont gérés en front
  if (material.existing_files && material.existing_files.length > 0) {
    material.existing_files.forEach((url: string) => {
      dataToSend.append('existing_files[]', url);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axiosClient.post(
      urlProductUpdate(material.id),
      dataToSend,
      config
    );
    toast.success(t('brand.products.updated'));
    return response.data;
  } catch (error: any) {
    toast.error(t('brand.products.update_error'));
    return false;
  }
};

export const deleteMaterial = async (material: IMaterial, t: TFunction) => {
  try {
    const response = await axiosClient.post(
      urlProductDelete(material.id),
      material
    );
    toast.success(t('brand.products.deleted'));
    return response.data;
  } catch (error: any) {
    toast.error(t('brand.products.delete_error'));
    return false;
  }
};

export const getOperationsWithMaterial = async () => {
  try {
    const response = await axiosClient.get(urlOperationsWithMaterial);
    return response.data;
  } catch (error: any) {
    displayError(error);
    return false;
  }
};

export const storeProduct = async (payload: IProductByName, t: TFunction) => {
  try {
    const dataToSend = new FormData();

    dataToSend.append('brand_name', payload.brand_name);
    dataToSend.append('name', payload.name.toUpperCase());

    if (payload.comment) {
      dataToSend.append('comment', payload.comment);
    }

    if (payload.operations && payload.operations.length > 0) {
      payload.operations.forEach((operation) => {
        dataToSend.append('operations[]', operation);
      });
    }

    if (payload.files && payload.files.length > 0) {
      payload.files.forEach((file) => {
        dataToSend.append('files[]', file);
      });
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await axiosClient.post(
      urlProductCreateByName,
      dataToSend,
      config
    );

    toast.success(t('brand.products.success_message'));
    return response.data;
  } catch (error: any) {
    toast.error(t('brand.products.error_message'));
    return false;
  }
};
