import { TrashIcon } from '@assets/images/svgComponents';
import { red, textGrey } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { ILabelValue } from '@globalTypes/globalTypes';
import { Tag } from '@components/atomic/Tag';

function SelectedOperations({
  selectedOperations,
  handleCheckOperation,
}: {
  selectedOperations: ILabelValue[];
  handleCheckOperation: (operation: ILabelValue) => void;
}) {
  return (
    <div
      className="flex flex-wrap gap-2"
      style={{
        maxHeight: '8.75rem',
        overflowY:
          selectedOperations && selectedOperations.length > 2
            ? 'auto'
            : 'visible',
      }}
    >
      {selectedOperations?.map((operation: ILabelValue) => {
        const [code, description] = operation.label.split(' - ');
        return (
          <div
            key={operation.value}
            className="flex items-center bg-white border border-borderGrey p-2 rounded-default w-full"
          >
            <div className="flex items-center flex-grow gap-2">
              <span className="text-sm">{description}</span>
              <Tag color={textGrey} label={code} />
            </div>

            <ColorCube
              color={red}
              size="1.5rem"
              numberOrIcon={<TrashIcon />}
              opacity
              onClick={() => handleCheckOperation(operation)}
            />
          </div>
        );
      })}
    </div>
  );
}

export default SelectedOperations;
