import { HistoryRequests } from '@models/requests/components/history/HistoryRequests';
import { KanbanRequests } from '@models/requests/components/kanban/KanbanRequests';
import { RequestsContext } from '@models/requests/utils/requestsContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '../context/headerContext';
import { RequestAndActivityIntoModalOrCardContext } from '../context/requestAndChangeContext';

function Requests() {
  const { t } = useTranslation();
  const {
    isHistoryRequest,
    updateIsHistoryRequest,
    updateFilterDataRequests,
    filterDataRequests,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);
  const [
    elementActiveAllRequestWorksiteOrPartner,
    setElementActiveAllRequestWorksiteOrPartner,
  ] = useState<string>(t('requests_and_changes.all_requests') || '');
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);

  useEffect(() => {
    if (isHistoryRequest) {
      updateTitleHeader(t('requests_and_changes.history_requests') || '');
    } else {
      updateTitleHeader(t('requests_and_changes.requests') || '');
    }
  }, [isHistoryRequest]);

  const RequestsContextValue = useMemo(
    () => ({
      elementActiveAllRequestWorksiteOrPartner,
      updateElementActiveAllRequestWorksiteOrPartner:
        setElementActiveAllRequestWorksiteOrPartner,
      isLoadingList,
      updateIsLoadingList: setIsLoadingList,
    }),
    [elementActiveAllRequestWorksiteOrPartner, isLoadingList]
  );

  useEffect(() => {
    const storedIsHistoryRequest = localStorage.getItem('isHistoryRequest');
    if (storedIsHistoryRequest !== null) {
      updateIsHistoryRequest(true);
    }
    refreshHeader(true);
    if (filterDataRequests && Object.keys(filterDataRequests).length > 0) {
      updateFilterDataRequests(undefined);
    }
  }, []);

  return (
    <RequestsContext.Provider value={RequestsContextValue}>
      <div>
        {/* <HeaderRequests /> */}
        {isHistoryRequest ? <HistoryRequests /> : <KanbanRequests />}
      </div>
    </RequestsContext.Provider>
  );
}

export default Requests;
