/**
 * Validation stricte d'emails selon RFC 3696
 * Exemple valide : "jean.dupont@exemple.fr"
 */
export const emailRegex =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-ZÀ-ÿ\-0-9]+\.)+[a-zA-ZÀ-ÿ]{2,}))$/;

/**
 * Numéros de téléphone français et internationaux
 * Formats acceptés : 0123456789 ou +331234567890 (9 à 11 chiffres après l'indicatif)
 */
export const phoneNumberRegex = /^(0\d{9}|\+\d{2}\d{9,10})$/;

/**
 * Chiffres, virgules et points uniquement
 * Utilisé pour les entrées numériques brutes
 */
export const numberRegex = /^[0-9,.]*$/;

/**
 * Caractères alphanumériques étendus avec symboles courants
 * Accepte les accents, espaces et caractères spéciaux : /-'"«»!? etc.
 */
export const alphanumericRegex =
  /^[A-Za-z0-9\s-/\\'"’°«»áàâäãæçéèêëíìîïñóòôöõœúùûüÁÀÂÄÃÆÇÉÈÊËÍÌÎÏÑÓÒÔÖÕŒÚÙÛÜ.,!?`~@#$%^&*()_\-+={}[\]:/<>]*$/;

/**
 * Lettres (majuscules/minuscules) avec accents et espaces
 * Rejette les chiffres et symboles
 */
export const alphabeticRegex = /^[a-zA-ZÀ-ÿ\s]+$/;

/**
 * Noms complets avec caractères spéciaux
 * Accepte : apostrophes, tirets, espaces (ex: "Jean-François O'Neil")
 */
export const nameRegex = /^[a-zA-ZÀ-ÿ\s'-]+$/;

/**
 * Dates au format JJ/MM/AAAA (à partir de 2011)
 * Exemple valide : "31/12/2023"
 */
export const dateRegex =
  /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(201[1-9]|20[2-9][0-9])$/;

/**
 * Validation basique d'emails (moins stricte que emailRegex)
 * Accepte certains formats non conformes à la RFC
 */
export const emailValidationRegex =
  /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

/**
 * Numéro SIRET (14 chiffres exactement)
 */
export const siretRegex = /^[0-9]{14}$/;

/**
 * Numéro fiscal français (13 chiffres)
 */
export const taxNumberRegex = /^\d{13}$/;

/**
 * Référence d'avis fiscal (13-14 caractères alphanumériques)
 * Format complexe avec possible séparateur non-numérique
 */
export const taxNoticeReferenceRegex =
  /^(?=\d*\D?\d*$)(?=[a-zA-Z\d]{13,14}$)\d*\D?\d*$/;

/**
 * Numéro IBAN international (5 à 34 caractères alphanumériques)
 */
export const ibanRegex = /^[A-Z0-9]{5,34}$/;

/**
 * Code BIC/SWIFT (8 ou 11 caractères)
 * Format : AAAA BB CC DDD (optionnel)
 */
export const bicRegex =
  /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;

/**
 * Entiers positifs uniquement (pas de séparateurs décimaux)
 */
export const numberRegexWithoutNegative = /^\d*$/;

/**
 * Entiers positifs ou négatifs (pas de séparateurs décimaux)
 */
export const numberRegexWithNegative = /^-?\d*$/;

/**
 * Nombres décimaux avec gestion des signes négatifs
 * Accepte : 15, 15.6, -15, -15.67, etc.
 */
export const decimalRegex = /^-?\d+[.,]?\d*$/;

/**
 * Nombres décimaux positifs uniquement
 * Accepte : 15, 15,6, 15.7, etc.
 */
export const decimalRegexWithoutNegative = /^\d+[.,]?\d*$/;

/**
 * URLs de sites web (http/https optionnel)
 * Exemple valide : "www.exemple.com/path"
 */
export const websiteRegex =
  /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,6})([/\w .-]*)*\/?$/;

/**
 * Numéro de parcelle postale française
 * Format : 999 AA 9999 (3 chiffres, 2 alphanum, 4 chiffres)
 */
export const parcelRegex = /^\d{3}\s[A-Za-z0-9]{2}\s\d{4}$/;

/**
 * Code postal français (5 chiffres)
 */
export const postalCodeRegex = /^\d{5}$/;
