import { blueOpx } from '@assets/color';
import { ChevronLeftIcon, InfoCircleIcon } from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';

function LeftPartCheckpoints() {
  // ne fait rien pour l'instant // IA à implémenter
  return (
    <div className="h-full bg-white rounded-[.5rem] flex flex-col gap-[1rem]">
      <div className="flex flex-col items-center gap-[1rem] border-b border-b-borderGrey p-[1rem]">
        <ColorCube
          color={blueOpx}
          opacity
          numberOrIcon={<InfoCircleIcon />}
          size="1.5rem"
        />
        <ColorCube
          color={blueOpx}
          opacity
          numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
          size="1.5rem"
        />
      </div>
    </div>
  );
}

export default LeftPartCheckpoints;
