/* eslint-disable @typescript-eslint/no-unused-vars */
import { borderGrey, textGrey } from '@assets/color';
import { UploadIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { GlobalContext } from '@context/globalContext';
import { ILinkedFile } from '@globalTypes/globalTypes';
import { DOCUMENT_STATUS_ENUM, DOCUMENT_TYPES } from '@utils/utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadButton } from '@components/documents/UploadButton';
import { sendLinkedFile } from '@apiRequests/globalRequests';
import { Tag } from '@components/atomic/Tag';
import { groupDocuments } from '@models/worksites/utils/utils';
import { Modal } from '@components/Modal';
import {
  cancelLinkedFileCheckpoints,
  sendComplianceRequest,
  validateLinkedFileCheckpoints,
} from '../apiRequests/checkpointsRequests';
import { CheckpointButtonEnum } from '../utils/enums';
import { LinkedFileCheckpointContext } from '../utils/linkedFileCheckpointContext';
import { tabList } from '../utils/tabs';

interface TabsCheckPointsProps {
  getData: (id: number) => Promise<void>;
}

function TabsCheckPoints({ getData }: TabsCheckPointsProps) {
  const { t } = useTranslation();

  const {
    globalEnum: { linked_file_type },
  } = useContext(GlobalContext);

  const {
    document,
    updateDocument,
    linkedFiles,
    updateLinkedFiles,
    worksite,
    controlCounts,
    checkpoints,
    updateWorksiteCheckpoints,
    complianceMessage,
    groupedDocuments,
    updateGroupedDocuments,
  } = useContext(LinkedFileCheckpointContext);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonStatus, setbuttonStatus] = useState<CheckpointButtonEnum | null>(
    null
  );
  const [validateWithoutControl, setValidateWithoutControl] = useState(false);

  const docGroup = groupedDocuments.find((elt) =>
    elt.files.some((f) => f.id === document.id)
  );

  const relationIds: number[] = docGroup?.files
    .flatMap((f) => f.relation_ids)
    .filter((relationId) => relationId !== null)
    .map((relationId) => Number(relationId)) || [0];

  const handleClickOnTab = (title: string, id: number) => {
    const doc = linkedFiles.find(
      (file) => linked_file_type[file.file_type] === title && file.id === id
    );
    if (doc) {
      updateDocument(doc);
    }
  };

  const setLinkedFiles = (file: ILinkedFile, isReplaced = false) => {
    const newFile = { ...file, relation_ids: relationIds };

    const files = linkedFiles.map((f) => {
      if (f.id === document.id) {
        return newFile;
      }

      if (docGroup?.files.some((elt) => elt.id === f.id)) {
        return { ...newFile, id: f.id };
      }
      return f;
    });

    updateLinkedFiles(files);

    updateGroupedDocuments(groupDocuments(files, true));

    updateDocument(file);

    if (file.status === DOCUMENT_STATUS_ENUM.PENDING && isReplaced) {
      updateWorksiteCheckpoints((prev) => {
        return prev.map((c) => {
          if (c.document_id === document.id) {
            return {
              ...c,
              checkpoints: c.checkpoints.map((item) => ({
                ...item,
                value: null,
                valid: null,
                linked_file_id: Number(file.id),
              })),
            };
          }

          return c;
        });
      });

      const storageKey = `tab_/checkpoints/${worksite.id}_`;
      const storageValue = `${linked_file_type[document.file_type]}_${file.id}`;

      const tabItem = localStorage.getItem(storageKey);

      if (tabItem) {
        localStorage.setItem(storageKey, storageValue);
      }
    }

    updateGroupedDocuments(groupDocuments(files, true));
  };

  const handleUpload = async (file: File) => {
    if (!document) {
      return;
    }

    const relation_ids = relationIds;
    const relation_type = document.relation_type || '';
    const file_type = document.file_type;
    const status = document.status;
    const commentary = document.commentary || '';
    const linked_entity_id = document.linked_entity_id;
    const mandatory = document.mandatory || false;
    const documentId = document.id || undefined;
    const canDuplicate = document.can_duplicate || false;

    setIsLoading(true);

    const res = await sendLinkedFile(
      file,
      relation_ids,
      relation_type,
      file_type,
      status,
      commentary,
      linked_entity_id,
      mandatory,
      canDuplicate, //
      documentId
    );

    if (res?.data) {
      setLinkedFiles(res.data, true);
    }

    setIsLoading(false);
  };

  const buttonLabels = {
    [CheckpointButtonEnum.WITH_CONTROL]: t('checkpoints.validate_with_control'),
    [CheckpointButtonEnum.WITHOUT_CONTROL]: t(
      'checkpoints.validate_without_control'
    ),
    [CheckpointButtonEnum.COMPLIANCE_REQUEST]: t(
      'checkpoints.send_conformity_request'
    ),
    [CheckpointButtonEnum.CANCEL_VALIDATION]: t(
      'checkpoints.cancel_validation'
    ),
  };

  const cancelValidation = async () => {
    setIsLoading(true);
    const response = await cancelLinkedFileCheckpoints(Number(document.id));
    if (response.data) {
      setLinkedFiles(response.data);
    }
    await getData(Number(document.id));
    setIsLoading(false);
  };

  const validateLinkedFile = async () => {
    setIsLoading(true);

    const response = await validateLinkedFileCheckpoints(Number(document.id), {
      checkpoints,
    });
    if (response.data) {
      setLinkedFiles(response.data);
    }
    setValidateWithoutControl(false);
    setIsLoading(false);
  };

  const sendCheckpointCompliance = async () => {
    setIsLoading(true);

    const response = await sendComplianceRequest(Number(document.id), {
      checkpoints,
      message: complianceMessage,
    });

    if (response.data) {
      setLinkedFiles(response.data);
    }
    setIsLoading(false);
  };

  const onClickCheckpointButton = () => {
    switch (buttonStatus) {
      case CheckpointButtonEnum.WITH_CONTROL:
        validateLinkedFile();
        break;
      case CheckpointButtonEnum.WITHOUT_CONTROL:
        setValidateWithoutControl(true);
        break;
      case CheckpointButtonEnum.COMPLIANCE_REQUEST:
        sendCheckpointCompliance();
        break;
      case CheckpointButtonEnum.CANCEL_VALIDATION:
        cancelValidation();
        break;
      default:
        break;
    }
  };

  const documentOperations = useMemo(() => {
    const groupedDocument = groupedDocuments.find((elt) =>
      elt.files.some(
        (e) => e.id === document.id && e.relation_type === 'worksite_operation'
      )
    );

    if (!groupedDocument || !worksite) return null;
    return worksite.worksites_operations
      .filter((wo) => relationIds.includes(wo.id))
      .map((wo) => wo.operation);
  }, [document, worksite, relationIds]);

  const btnDisabled = useMemo(() => {
    if (!buttonStatus) {
      return true;
    }

    if (document.status === DOCUMENT_STATUS_ENUM.REFUSE) {
      return true;
    }

    if (
      buttonStatus === CheckpointButtonEnum.COMPLIANCE_REQUEST &&
      Number(controlCounts?.to_control) > 0
    ) {
      return true;
    }

    return false;
  }, [buttonStatus, document, controlCounts]);

  useEffect(() => {
    if (
      !controlCounts ||
      !document ||
      document.status === DOCUMENT_STATUS_ENUM.REFUSE
    ) {
      setbuttonStatus(null);
    } else if (document.status === DOCUMENT_STATUS_ENUM.VALIDATE) {
      setbuttonStatus(CheckpointButtonEnum.CANCEL_VALIDATION);
    } else if (controlCounts.non_compliant > 0) {
      setbuttonStatus(CheckpointButtonEnum.COMPLIANCE_REQUEST);
    } else if (controlCounts.to_control > 0) {
      setbuttonStatus(CheckpointButtonEnum.WITHOUT_CONTROL);
    } else if (controlCounts.compliant > 0) {
      setbuttonStatus(CheckpointButtonEnum.WITH_CONTROL);
    } else {
      setbuttonStatus(CheckpointButtonEnum.WITHOUT_CONTROL);
    }
  }, [checkpoints, controlCounts, document]);

  return (
    <div>
      <SubHeader
        leftPart={
          <TabsLeftPart
            titlesList={[]}
            onClick={handleClickOnTab}
            activeButton={document ? String(document.id) : ''}
            labelValueList={tabList(groupedDocuments, linked_file_type)}
            multipleIdenticalTitles
          />
        }
        rightPart={
          <div className="flex gap-3 items-center">
            {document.status < DOCUMENT_STATUS_ENUM.VALIDATE &&
              document.file_type !== DOCUMENT_TYPES.AH && (
                <UploadButton
                  name={t('checkpoints.replace_and_analyze')}
                  onUpload={(file: File) => handleUpload(file)}
                  onDelete={() => console.log('delete')}
                  fileType={Number(document.file_type)}
                  icon={
                    <ButtonOpx
                      label={t('checkpoints.replace_and_analyze')}
                      icon={<UploadIcon />}
                      type="tab"
                    />
                  }
                  color="blue"
                />
              )}
            {buttonStatus && (
              <ButtonOpx
                label={buttonLabels[buttonStatus]}
                disabled={btnDisabled}
                onClick={onClickCheckpointButton}
                isLoading={!validateWithoutControl && isLoading}
              />
            )}
          </div>
        }
      />
      <div className="mb-5 flex gap-3 h-[1rem]">
        {documentOperations &&
          documentOperations.map((operation) => {
            return (
              <Tag
                color={textGrey}
                label={`${operation.code} - ${operation.description}`}
                backgroundColor="white"
                addClass="p-3 rounded-default"
                withBorder
                borderColor={borderGrey}
                key={operation.code}
              />
            );
          })}
      </div>
      {validateWithoutControl && (
        <Modal
          title={t('checkpoints.validate_without_control')}
          onConfirmClick={() => validateLinkedFile()}
          onClickCancel={() => setValidateWithoutControl(false)}
          backgroundTransparent
          btnCancel
          textBtnConfirm={t('buttons.confirm') || ''}
          isLoading={isLoading}
        >
          <div className="pt-[1.5rem]">
            <p>{t('checkpoints.validate_without_control_message')}</p>
          </div>
        </Modal>
      )}
    </div>
  );
}

export { TabsCheckPoints };
