/* eslint-disable no-nested-ternary */
import { black, blueOpx, textGreyInfos } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { ThemeContext } from '@context/ThemeContext';
import { useContext } from 'react';
import { IInfoWithIcon } from '../../types/globalTypes';
import { ColorCube } from './ColorCube';

type IInfosWithIconProps = {
  infos: IInfoWithIcon[];
  colorIcon?: string;
  icon?: number | JSX.Element;
  titleColor?: string;
  subtitleColor?: string;
  addClass?: string;
  loading?: boolean;
  borderRadius?: boolean;
  dataTestId?: string;
  onClick?: () => void;
  isLink?: boolean;
  spaceLeft?: boolean;
  noWrap?: boolean;
  spaceY?: string;
  backgroundColorIcon?: string;
};

function InfosWithIcon({
  infos,
  colorIcon,
  icon,
  titleColor,
  subtitleColor,
  addClass,
  loading,
  borderRadius,
  dataTestId,
  onClick,
  isLink,
  spaceLeft,
  noWrap,
  spaceY,
  backgroundColorIcon,
}: IInfosWithIconProps): JSX.Element {
  const { themeData } = useContext(ThemeContext);

  return (
    <div className={['flex items-start space-x-[1rem]', addClass].join(' ')}>
      {icon && (
        <ColorCube
          size="2rem"
          numberOrIcon={icon}
          color={colorIcon || blueOpx}
          opacity={!backgroundColorIcon}
          backgroundColor={backgroundColorIcon}
          borderRadius={!borderRadius ? '4px' : undefined}
        />
      )}
      {spaceLeft && !icon && <div className="min-w-[2rem]" />}
      <div className={`flex-1 min-w-0 flex flex-col items-center ${spaceY}`}>
        {infos.map((info: IInfoWithIcon) => (
          <div
            className="flex-col items-center space-y-[.375rem] w-full"
            key={`${info.title}-${info.subtitle}`}
          >
            <p
              style={{ color: titleColor || textGreyInfos }}
              className={['text-[.75rem] leading-[.75rem]', info.addClass].join(
                ' '
              )}
            >
              {info.title}
            </p>
            <div
              style={{ color: subtitleColor || 'black' }}
              className={`flex space-x-12 items-center font-[500] text-[.875rem] leading-[1rem] ${
                isLink && onClick ? 'cursor-pointer' : ''
              }`}
              data-test-id={dataTestId}
              aria-hidden
            >
              {loading ? (
                <LoaderSkeleton height="1rem" addClass="max-w-[15rem]" />
              ) : (
                <>
                  {info.list ? (
                    <div className="flex flex-col space-y-1">
                      {info.list.map((text) => (
                        <p
                          key={`element-in-list-${text}`}
                          aria-hidden
                          style={{
                            color: isLink ? (themeData ? black : blueOpx) : '',
                            fontWeight:
                              themeData && isLink ? 'bold' : undefined,
                          }}
                          onClick={() =>
                            info.onClick
                              ? info.onClick()
                              : isLink && onClick && onClick()
                          }
                          className={
                            noWrap ? 'whitespace-nowrap' : 'break-words'
                          }
                        >
                          {text}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <span
                      aria-hidden
                      style={{
                        color: isLink ? (themeData ? black : blueOpx) : '',
                        fontWeight: themeData && isLink ? 'bold' : undefined,
                      }}
                      onClick={() =>
                        info.onClick
                          ? info.onClick()
                          : isLink && onClick && onClick()
                      }
                      className={noWrap ? 'truncate' : 'break-words'}
                    >
                      {info.subtitle}
                    </span>
                  )}
                  {info.subtitleTag && (
                    <div className="relative h-0">
                      <Tag
                        color={info.subtitleTag.color}
                        label={info.subtitleTag.label}
                        withBorder
                        addClass="absolute transform -translate-y-1/2 top-0 right-0"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export { InfosWithIcon };

InfosWithIcon.defaultProps = {
  titleColor: '',
  colorIcon: '',
  icon: undefined,
  subtitleColor: '',
  addClass: '',
  loading: undefined,
  borderRadius: false,
  dataTestId: '',
  onClick: undefined,
  isLink: false,
  spaceLeft: false,
  noWrap: false,
  spaceY: 'space-y-[.5rem]',
  backgroundColorIcon: undefined,
};
