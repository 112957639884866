/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { blueOpx, green, grey, orange, red, textGrey } from '@assets/color';
import {
  AddIcon,
  CheckIcon,
  ClockAlertOutline,
  CrossIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  FileIcon,
  InfoCircleIcon,
  MoreDotsIcon,
  SignataryIcon,
  TrashIcon,
} from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import {
  DOCUMENT_GENERATION_STATE,
  DOCUMENT_STATE_STRING,
  DOCUMENT_STATUS_ENUM,
  DOCUMENT_TYPES,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_MB,
} from '@utils/utils';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  getLinkedFileDocumentAnalysis,
  updateLinkedFileCustomName,
} from '@apiRequests/globalRequests';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { TextWithCheckboxOrToggle } from '@components/atomic/inputs/controls/TextWithCheckboxOrToggle';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import { Loader } from '@components/atomic/Loader';
import { Tag } from '@components/atomic/Tag';
import Tooltip from '@components/atomic/Tooltip';
import { ModalDocumentAnalysis } from '@components/documents/ModalDocumentAnalysis';
import { Modal } from '@components/Modal';
import { GlobalContext } from '@context/globalContext';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { IRequestTypes } from '@models/requests/types/requestTypes';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import {
  convertPdfToImages,
  createPdfFromImages,
  dataURLToBlob,
  verifyPdfSignature,
} from '@utils/documentUtils';
import { fileTypeEnum, RequestOrMessageOrChangeEnum } from '@utils/enums';
import {
  convertHexToRGBA,
  handleUploadClick,
  shortenStringWithEllipsis,
  transformObjectList,
} from '@utils/functions';
import { initialDocument } from '@utils/initialState';
import { ROLES } from '@utils/roles';
import {
  BENEFICIARIES_ROUTES,
  DOCUMENT_CHECKPOINT_ROUTE_WITH_WORKSITE_ID,
} from '@utils/routesUrls';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  IDocumentAnalysis,
  ILinkedFile,
  ILinkedFilesGrouped,
} from '../../types/globalTypes';
import DocumentStatusTag from './DocumentStatusTag';

type ISelectedActionsType = {
  document: ILinkedFile;
  action: 'validate' | 'refuse';
  rejectionComment?: string;
};

interface INewDocumentCardProps {
  groupedDocuments: ILinkedFilesGrouped;
  onSelect: (document: ILinkedFile) => void;
  updateParentDocuments?: () => Promise<void>;
  closeDropdown: boolean;
  setCloseDropdown: Dispatch<SetStateAction<boolean>>;
  index: number;
  importMode: boolean;
  readOnly: boolean;
  onUpload?: (
    file: File,
    originalLinkedFile: ILinkedFile,
    customfield_id: number | null
  ) => Promise<void>;
  onDelete?: (document: ILinkedFile) => Promise<void>;
  showAnalysis?: boolean;
  onValidateProcessing?: () => void;
}

function DocumentCardInList({
  groupedDocuments,
  onSelect,
  onUpload,
  onDelete,
  closeDropdown,
  setCloseDropdown,
  index,
  importMode,
  readOnly,
  showAnalysis,
}: INewDocumentCardProps) {
  const { t } = useTranslation();
  const { title, required, file_type /* , documents */ } = groupedDocuments;
  const { roleUser, globalEnum, documentActive, updateDocumentActive } =
    useContext(GlobalContext);
  const { worksiteDetails } = useContext(WorksitesContext);
  const {
    listRequests,
    updateDetailRequest,
    updateRequestOrChangeEnumInCard,
    listRequestsGroupAll,
  } = useContext(RequestAndActivityIntoModalOrCardContext);
  const { updateSignedQuotesToSend, signedQuotesToSend } = useContext(
    WorksiteCreationContext
  );
  const { id: relatedId } = useParams();
  const { pathname } = useLocation();
  const methods = useForm({
    defaultValues: {
      customName: groupedDocuments.documents[0].custom_name || '',
    },
  });
  const { handleSubmit, watch } = methods;

  const [selectedActions, setSelectedActions] = useState<
    ISelectedActionsType[]
  >([]);
  const [selectedOperations, setSelectedOperations] = useState<
    { relation_id: number; name: string }[]
  >([]);

  const [fileToChange, setFileToChange] = useState<ILinkedFile | null>(null);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isDocumentReportModal, setDocumentReportModal] = useState(false);
  const [documentReportModalContent, setDocumentReportModalContent] =
    useState<IDocumentAnalysis | null>(null);
  const [documentReportActiveFile, setDocumentReportActiveFile] =
    useState<ILinkedFile | null>(null);
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [docToDelete, setDocToDelete] = useState<ILinkedFile | null>(null);

  const navigate = useNavigate();

  const hiddenFileInputCard = useRef<HTMLInputElement>(null);

  const isBeneficiaryView = useMemo(() => {
    return pathname.includes(BENEFICIARIES_ROUTES.BENEFICIARY_WORKSITE);
  }, [pathname]);

  const uploadFileEnabled =
    (groupedDocuments.is_operation_document &&
      groupedDocuments.operations?.length === 1) ||
    (groupedDocuments.is_operation_document && selectedOperations.length > 0) ||
    !groupedDocuments.is_operation_document;

  const customFieldFileAlreadyUploaded =
    groupedDocuments.customfield_id &&
    groupedDocuments.documents.some((doc) => doc.file_url);

  const documentsWithUrl = useMemo(() => {
    const uniqueUrls: string[] = [];

    const groupedDocs = groupedDocuments.documents.map((d) => {
      if (!d.file_url) return undefined;
      if (uniqueUrls.includes(d.file_url)) {
        return undefined;
      }
      const relationIds: number[] = [];
      const sameUrls = groupedDocuments.documents.filter(
        (lf) => lf.file_url === d.file_url
      );

      sameUrls.forEach((u) =>
        u.relation_ids?.forEach((i) => relationIds.push(i))
      );

      uniqueUrls.push(d.file_url);
      return { ...d, relation_ids: relationIds };
    });

    return groupedDocs.filter((d) => d !== undefined) as ILinkedFile[];
  }, [groupedDocuments]);

  const relationType = useMemo(() => {
    if (pathname.includes(t('requests_and_changes.worksites').toLowerCase()))
      return 'worksite';
    return '';
  }, [pathname]);

  const isPartners = useMemo(() => {
    return pathname.includes('partners');
  }, [pathname]);

  const relatedRequests = useMemo(() => {
    const reqs: IRequestTypes[] = [];
    if (listRequestsGroupAll.waiting) {
      listRequestsGroupAll.waiting.forEach((r) => {
        r.demands.forEach((d) => reqs.push(d));
      });
    }
    if (listRequestsGroupAll.todo) {
      listRequestsGroupAll.todo.forEach((r) => {
        r.demands.forEach((d) => reqs.push(d));
      });
    }
    return reqs.filter(
      (r) =>
        r.relation_type === relationType && r.relation_id === Number(relatedId)
    );
  }, [listRequestsGroupAll]);

  const [isEditingCustomName, setIsEditingCustomName] =
    useState<boolean>(false);
  const [isUpdatingCustomName, setIsUpdatingCustomName] =
    useState<boolean>(false);

  const acceptFileType = () => {
    if (
      groupedDocuments.file_type === fileTypeEnum.PHOTOSAV ||
      groupedDocuments.file_type === fileTypeEnum.PHOTO_AVANT_CHANTIER ||
      groupedDocuments.file_type === fileTypeEnum.PHOTO_APRES_CHANTIER ||
      groupedDocuments.file_type === fileTypeEnum.PHOTO_CALORIFUGEAGE ||
      groupedDocuments.file_type === fileTypeEnum.PHOTO_VANNE_EQUILIBRAGE
    ) {
      return '.jpg,.jpeg,.png';
    }
    if (
      groupedDocuments.file_type === fileTypeEnum.AUTRE ||
      groupedDocuments.file_type === fileTypeEnum.JUSTIFICATIF_IDENTITE ||
      groupedDocuments.file_type === fileTypeEnum.FACTURE ||
      groupedDocuments.file_type === fileTypeEnum.CUSTOM_FIELD
    ) {
      return '.jpg,.jpeg,.png,.pdf';
    }
    return '.pdf';
  };

  const isInstaller = useMemo(() => roleUser === ROLES.PRODUCTION, [roleUser]);

  const status = useMemo(() => {
    // Utilisation de la méthode reduce pour trouver le statut le plus bas
    const lowestStatus = groupedDocuments.documents.reduce(
      (lowest, document) => {
        if (document.status < lowest) {
          return document.status;
        }
        return lowest;
      },
      Infinity
    );
    return globalEnum.linked_file_status[lowestStatus];
  }, [groupedDocuments, roleUser, globalEnum]);

  const canUploadNewFile = useMemo(() => {
    if (importMode) return true;
    if (readOnly) return false;
    if (customFieldFileAlreadyUploaded) {
      return false;
    }
    if (
      groupedDocuments.documents[0].file_type === DOCUMENT_TYPES.Other &&
      groupedDocuments.documents[0].custom_name
    ) {
      return false;
    }
    if (!required) {
      return (
        status === DOCUMENT_STATE_STRING.TO_IMPORT ||
        status === DOCUMENT_STATE_STRING.PENDING
      );
    }
    return status === DOCUMENT_STATE_STRING.TO_IMPORT;
  }, [status]);

  const missingMandatoryFilesCount = useMemo(() => {
    let count = 0;

    groupedDocuments.documents.forEach((doc) => {
      if (doc.mandatory === true && doc.file_url === null) {
        count += 1;
      }
    });

    return count;
  }, [groupedDocuments]);

  const documentIsGenerated = groupedDocuments.documents.some(
    (file: ILinkedFile) =>
      file.document_generation_status !== null &&
      file.document_generation_status !== undefined
  );

  const documentGenerationStatus = globalEnum.document_generation_status;

  const uploadBlockedByGeneration = useMemo(() => {
    const linkedFiles = groupedDocuments.documents;
    return linkedFiles.some(
      (file: ILinkedFile) =>
        file.document_generation_status === DOCUMENT_GENERATION_STATE.GENERATION
    );
  }, [groupedDocuments]);

  const tagProperties = () => {
    const doc = groupedDocuments.documents[0];

    if (doc.file_type === DOCUMENT_TYPES.AH && !uploadBlockedByGeneration) {
      return {
        label: t('global.auto_completed'),
        color: green,
      };
    }

    const generatedLabel =
      worksiteDetails &&
      Number(worksiteDetails.status) > WORKSITE_STATUS.WORKSITE_CREATION
        ? t('global.ready_to_download')
        : documentGenerationStatus[DOCUMENT_GENERATION_STATE.GENERATED];

    return {
      label: uploadBlockedByGeneration
        ? documentGenerationStatus[DOCUMENT_GENERATION_STATE.GENERATION]
        : generatedLabel,
      color: uploadBlockedByGeneration ? orange : green,
    };
  };

  const documentIcon = useMemo(() => {
    let left = null;
    let right = null;

    const documentStatus = groupedDocuments.documents[0].status;

    if (readOnly || customFieldFileAlreadyUploaded) {
      if (documentsWithUrl.length > 0) {
        right = {
          icon: <DownloadIcon />,
          color: blueOpx,
        };
      }
    } else if (importMode) {
      if (
        DOCUMENT_TYPES.Other === groupedDocuments.file_type &&
        documentStatus === DOCUMENT_STATUS_ENUM.PENDING
      ) {
        right = null;
      } else {
        right = {
          icon: <AddIcon />,
          color: uploadFileEnabled ? blueOpx : textGrey,
        };
      }
    } else {
      const signataryIcon = {
        icon: <SignataryIcon />,
        color: textGrey,
      };

      const validatedIcon = {
        icon: <CheckIcon />,
        color: green,
      };

      switch (documentStatus) {
        case DOCUMENT_STATUS_ENUM.TO_IMPORT:
          if (!isInstaller) {
            left = {
              icon: <MoreDotsIcon />,
              color: blueOpx,
              dataTestId: 'to_import',
            };
          }
          right = {
            icon: <AddIcon />,
            color: uploadFileEnabled ? blueOpx : textGrey,
          };
          break;
        case DOCUMENT_STATUS_ENUM.PENDING:
          if (!isPartners && (!isInstaller || (isInstaller && required))) {
            left = {
              icon: <ClockAlertOutline />,
              color: orange,
              dataTestId: 'pending',
            };
          }
          if (required || groupedDocuments.file_type === DOCUMENT_TYPES.Other) {
            right = { icon: <DownloadIcon />, color: blueOpx };
          } else {
            right = {
              icon: <AddIcon />,
              color: uploadFileEnabled ? blueOpx : textGrey,
            };
          }
          break;
        case DOCUMENT_STATUS_ENUM.REFUSE:
          left = { icon: <CrossIcon />, color: red, dataTestId: 'refused' };
          break;
        case DOCUMENT_STATUS_ENUM.TO_SIGN:
        case DOCUMENT_STATUS_ENUM.AWAITING_SIGNATURE:
          left = { ...signataryIcon, dataTestId: 'to_sign' };
          break;
        case DOCUMENT_STATUS_ENUM.VALIDATE:
          if (groupedDocuments.file_type === DOCUMENT_TYPES.AH) {
            left = { ...signataryIcon, dataTestId: 'validated' };
            break;
          } else {
            left = { ...validatedIcon, dataTestId: 'validated' };
            right = { icon: <DownloadIcon />, color: blueOpx };
            break;
          }
        case DOCUMENT_STATUS_ENUM.SIGNED:
          left = { ...signataryIcon, color: green, dataTestId: 'signed' };
          right = { icon: <DownloadIcon />, color: blueOpx };
          break;
        default:
          break;
      }
    }

    return { left, right };
  }, [status, groupedDocuments, selectedOperations]);

  const showCheckpointButton = useMemo(() => {
    const document = groupedDocuments.documents[0];

    if (
      isPartners ||
      isInstaller ||
      importMode ||
      readOnly ||
      isBeneficiaryView
    ) {
      return false;
    }

    if (document.file_type === DOCUMENT_TYPES.AH) {
      return document.status === DOCUMENT_STATUS_ENUM.PENDING;
    }

    return document.status <= DOCUMENT_STATUS_ENUM.VALIDATE;
  }, [isPartners, isInstaller, importMode, readOnly, groupedDocuments]);

  const getDocumentAnalysis = useCallback(
    async (document: ILinkedFile) => {
      const groupedId = document.ids
        ?.map((id) =>
          groupedDocuments.documents.find((doc) => doc.id === id)
            ?.document_analysis
            ? id
            : false
        )
        .filter((id) => id !== false)
        .pop();
      if (groupedId) {
        const res = await getLinkedFileDocumentAnalysis(groupedId);
        setDocumentReportModalContent(res.data as IDocumentAnalysis);
      } else if (document.id) {
        const res = await getLinkedFileDocumentAnalysis(document.id);
        setDocumentReportModalContent(res.data as IDocumentAnalysis);
      }
    },
    [documentsWithUrl]
  );

  const showConversation = (doc: ILinkedFile) => {
    const relatedList = isInstaller ? listRequests : relatedRequests;

    const request = relatedList.find(
      (req) =>
        req.message === doc.commentary &&
        req.subject === globalEnum.linked_file_type[doc.file_type]
    );

    if (request) {
      updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.REQUEST);
      updateDetailRequest(request);
    }
  };

  const isPhotoHorodate =
    groupedDocuments.file_type === fileTypeEnum.PHOTO_HORODATEE;

  const multipleEnabled = acceptFileType().includes('jpg') || isPhotoHorodate;

  const nbSuggestedFilesToSend = isPhotoHorodate
    ? groupedDocuments.documents.length
    : 0;

  // Fonction pour gérer l'upload d'un fichier individuel
  const uploadOneFile = async (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      toast.error(
        t('global.file_too_large', { maxFileSize: MAX_FILE_SIZE_MB })
      );
      return;
    }

    if (onUpload) {
      setIsLoading(true);
      if (fileToChange) {
        await onUpload(
          file,
          fileToChange,
          groupedDocuments.customfield_id ?? null
        );
      } else {
        const defaultDocument = {
          ...initialDocument,
          file_type: groupedDocuments.documents[0].file_type,
          mandatory: groupedDocuments.documents[0].mandatory,
          relation_ids:
            groupedDocuments.is_operation_document &&
            selectedOperations.length > 0
              ? selectedOperations.map((operation) => operation.relation_id)
              : groupedDocuments.documents[0].relation_ids,
          relation_type: groupedDocuments.documents[0].relation_type,
          created_by: groupedDocuments.documents[0]?.created_by,
          order: groupedDocuments.documents[0].order,
          can_duplicate: isPhotoHorodate
            ? false
            : multipleEnabled || groupedDocuments.documents[0].can_duplicate,
        };
        await onUpload(
          file,
          defaultDocument,
          groupedDocuments.customfield_id ?? null
        );
      }
    }
  };

  /**
   * Fonction pour gérer le changement des fichiers d'entrée.
   * Traite chaque fichier de manière séquentielle.
   * Si le fichier est un PDF et échoue la vérification de signature,
   * il est converti en images avant l'upload.
   *
   * @param files - Tableau de fichiers sélectionnés par l'utilisateur.
   */
  async function onChangeInputFile(files: File[]) {
    if (files.length === 0) return;

    setIsLoading(true); // Commencer le chargement global

    for (const file of files) {
      if (file.type === 'application/pdf') {
        // Vérifier la signature du PDF
        const isPdfValid = await verifyPdfSignature(file);

        if (!isPdfValid) {
          // Si la vérification échoue, convertir le PDF en images
          try {
            const images = await convertPdfToImages(file);
            const imageFiles = images.map((dataUrl, indexImage) => {
              const blob = dataURLToBlob(dataUrl);
              return new File(
                [blob],
                `${file.name}-page-${indexImage + 1}.png`,
                { type: 'image/png' }
              );
            });

            // Créer un PDF à partir des images et l'upload
            const combinedPdf = await createPdfFromImages(
              imageFiles,
              file.name
            );
            if (combinedPdf) {
              await uploadOneFile(combinedPdf);
            }
          } catch (error) {
            toast.error(
              t('worksites.documents.downloaded.fail.incorrect_signature', {
                fileName: file.name,
              })
            );
          }
        } else {
          await uploadOneFile(file);
        }
      } else {
        // Si ce n'est pas un PDF, procéder à l'upload normal
        await uploadOneFile(file);
      }
    }

    // Une fois que tous les fichiers sont traités
    setIsLoading(false);
    setResetSelect(true);

    // Réinitialiser l'input file
    if (hiddenFileInputCard.current) {
      hiddenFileInputCard.current.value = ''; // Réinitialiser la valeur de l'input
    }
  }

  const onClickCard = () => {
    if (importMode && status === DOCUMENT_STATE_STRING.PENDING) return;
    if (canUploadNewFile && uploadFileEnabled && !uploadBlockedByGeneration) {
      handleUploadClick(hiddenFileInputCard);
    }
  };

  const downloadAllFiles = () => {
    groupedDocuments.documents.forEach((document) => {
      const fileUrl = document.file_url?.toString();

      // Vérifier si fileUrl est une chaîne de caractères non vide
      if (typeof fileUrl === 'string' && fileUrl.length > 0) {
        // Vérifier si fileUrl est une URL valide
        try {
          const url = new URL(fileUrl);
          window.open(url.toString());
        } catch (error) {
          console.error(`Invalid URL: ${fileUrl}`);
        }
      }
    });
  };

  const fileIcon = (file: ILinkedFile) => {
    let icon = <FileIcon />;
    let color = documentActive.document.id === file.id ? blueOpx : textGrey;

    const isAH = groupedDocuments.file_type === DOCUMENT_TYPES.AH;

    if (!importMode && !readOnly) {
      switch (file.status) {
        case DOCUMENT_STATUS_ENUM.SIGNED:
          icon = <CheckIcon />;
          color = green;
          break;
        case DOCUMENT_STATUS_ENUM.REFUSE:
          icon = <CrossIcon />;
          color = red;
          break;
        case DOCUMENT_STATUS_ENUM.VALIDATE:
          icon = isAH ? <FileIcon /> : <CheckIcon />;
          color = isAH ? textGrey : green;
          break;
        default:
          break;
      }
    }

    return (
      <ColorCube size="1.5rem" numberOrIcon={icon} color={color} opacity />
    );
  };

  const handleChangeRejectionComment = (
    document: ILinkedFile,
    rejectionComment: string
  ) => {
    setSelectedActions((prevSelectedActions) => {
      if (prevSelectedActions && prevSelectedActions.length > 0) {
        return prevSelectedActions.map((prevAction) => {
          if (prevAction.document === document) {
            return { ...prevAction, rejectionComment }; // Mettre à jour le commentaire existant
          }
          return prevAction;
        });
      }

      const newAction: ISelectedActionsType = {
        document,
        action: 'refuse',
        rejectionComment,
      };

      return [newAction];
    });
  };

  const handleCheckDocument = async (doc: ILinkedFile) => {
    if (doc.id) {
      navigate(DOCUMENT_CHECKPOINT_ROUTE_WITH_WORKSITE_ID(worksiteDetails.id), {
        state: {
          worksiteDetails,
          documentDetails: doc,
        },
      });
    }
  };

  const changeFileButton = (doc: ILinkedFile) => (
    <ButtonOpx
      label={t('global.document_change')}
      addClass="flex-1"
      small
      onClick={() => {
        setFileToChange(doc);
        handleUploadClick(hiddenFileInputCard);
      }}
    />
  );

  const resetInputFile = () => {
    const inputElement = document.getElementById(
      `inputFile_${index}`
    ) as HTMLInputElement;
    if (inputElement) inputElement.value = ''; // reinitialise la valeur de l'input pour que le onchange fonctionne si on choisi le fichier précedemment uploadé puis supprimé
  };

  const getMandatoryAndRecommendedText = () => {
    if (required) {
      if (isPhotoHorodate) {
        return `${nbSuggestedFilesToSend} ${t(
          'global.elements_to_time_stamp'
        )}`;
      }
      if (documentsWithUrl.length === 0) {
        return t('global.mandatory');
      }
      if (missingMandatoryFilesCount > 0 && !isPhotoHorodate) {
        const suffix = missingMandatoryFilesCount > 1 ? 's' : '';
        return (
          t('global.missing_file_for_operation', {
            count: missingMandatoryFilesCount,
          }) + suffix
        );
      }
    }

    return null;
  };

  const mandatoryAndRecommendedText = getMandatoryAndRecommendedText();

  const handleOnDelete = (doc: ILinkedFile) => {
    setShowModalConfirmDelete(true);
    setDocToDelete(doc);
  };

  const handleOnDeleteConfirm = () => {
    if (onDelete && docToDelete) {
      setShowModalConfirmDelete(false);
      onDelete(docToDelete).then(() => {
        resetInputFile(); // reinitialise la valeur de l'input pour que le onchange fonctionne si on choisi le fichier précedemment uploadé puis supprimé
        setIsLoading(false);
        if (updateSignedQuotesToSend) {
          updateSignedQuotesToSend((prevState) =>
            prevState.filter((d) => d.id !== docToDelete.id)
          );
        }
      });
    }
  };

  const onClickEditCustomName = () => {
    setIsEditingCustomName(true);
  };

  const onUpdateCustomName = async () => {
    setIsUpdatingCustomName(true);
    setIsEditingCustomName(false);
    const documentId = groupedDocuments.documents[0].id;
    if (documentId !== null) {
      const result = await updateLinkedFileCustomName(
        documentId,
        watch('customName')
      );
      if (result) {
        // eslint-disable-next-line no-param-reassign
        groupedDocuments.documents[0].custom_name = result.data.custom_name;
      }
      const updatedDocument = {
        ...documentActive.document,
        custom_name: watch('customName'),
      };

      updateDocumentActive({ list: '', document: updatedDocument });
    }
    setIsUpdatingCustomName(false);
  };

  const onSubmitEditCustomName = async () => {
    const custom_name = watch('customName');
    console.log('custom_name', custom_name);
  };

  useEffect(() => {
    // si supérieur à 2 c'est géré par le input select avec OnChangeValue
    if (groupedDocuments.operations?.length === 1) {
      setSelectedOperations(groupedDocuments.operations);
    }
  }, []);

  useEffect(() => {
    if (resetSelect) {
      setSelectedOperations([]);
      setResetSelect(false);
    }
  }, [resetSelect]);

  return (
    <div>
      {isDocumentReportModal && (
        <ModalDocumentAnalysis
          document={documentReportActiveFile as ILinkedFile}
          content={documentReportModalContent}
          setModal={setDocumentReportModal}
        />
      )}
      <input
        data-test-id={`inputFile_${file_type}${required ? '_required' : ''}`}
        id={`inputFile_${index}`}
        type="file"
        ref={hiddenFileInputCard}
        multiple={multipleEnabled}
        onChange={async (e) => {
          const files = Array.from(e.target.files || []); // Convertir FileList en tableau
          await onChangeInputFile(files);
        }}
        style={{ display: 'none' }}
        accept={acceptFileType()}
      />
      <div
        className="w-full border border-borderGrey rounded-default bg-white"
        data-test-id="button_document_card"
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          onClick={onClickCard}
          className={`w-full flex justify-between items-center p-4 ${
            canUploadNewFile ? 'cursor-pointer' : 'cursor-default'
          }`}
        >
          <div className="flex space-x-2 items-center">
            {isLoading ? (
              <Loader />
            ) : (
              documentIcon?.left && (
                <ColorCube
                  size="1.5rem"
                  numberOrIcon={documentIcon.left.icon}
                  color={documentIcon.left.color}
                  dataTestId={documentIcon.left.dataTestId}
                  opacity
                />
              )
            )}
            {!isEditingCustomName && (
              <>
                <p className="text-[1rem] text-left">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {groupedDocuments.documents[0].custom_name ? (
                    groupedDocuments.documents[0].custom_name
                  ) : file_type === DOCUMENT_TYPES.AH ? (
                    <span>{t('global.document_ah_generated')}</span>
                  ) : (
                    title
                  )}
                </p>
                {groupedDocuments.tooltip && (
                  <Tooltip content={groupedDocuments.tooltip}>
                    <InfoCircleIcon
                      width="1.5rem"
                      height="1.5rem"
                      fill={blueOpx}
                    />
                  </Tooltip>
                )}
              </>
            )}
            {isEditingCustomName && (
              <FormProvider {...methods}>
                <form
                  id="form_editCustomName"
                  onSubmit={handleSubmit(onSubmitEditCustomName)}
                  className="flex flex-row gap-x-3"
                >
                  <InputText
                    id="customName"
                    name="customName"
                    placeholder=""
                    maxLength={50}
                    form="form_editCustomName"
                    hideLabel
                    size={30}
                  />
                  <div className="flex flex-row items-center gap-x-2">
                    <ColorCube
                      onClick={onUpdateCustomName}
                      addClass="cursor-pointer"
                      size="1.5rem"
                      numberOrIcon={<CheckIcon />}
                      color={green}
                      backgroundColor={convertHexToRGBA(green, 0.1)}
                    />
                    <ColorCube
                      onClick={() => setIsEditingCustomName(false)}
                      addClass="cursor-pointer"
                      size="1.5rem"
                      numberOrIcon={<CrossIcon />}
                      color={red}
                      backgroundColor={convertHexToRGBA(red, 0.1)}
                    />
                  </div>
                </form>
              </FormProvider>
            )}
            {DOCUMENT_TYPES.Other === groupedDocuments.documents[0].file_type &&
              groupedDocuments.documents[0].custom_name &&
              !isEditingCustomName &&
              !isUpdatingCustomName && (
                <button type="button" onClick={onClickEditCustomName}>
                  <ColorCube
                    size="1.5rem"
                    numberOrIcon={<EditIcon />}
                    color={blueOpx}
                    backgroundColor={convertHexToRGBA(blueOpx, 0.1)}
                  />
                </button>
              )}
            {isUpdatingCustomName && <Loader />}
          </div>
          <div className="flex space-x-4 items-center">
            {documentIsGenerated && (
              <Tag
                color={tagProperties().color}
                label={tagProperties().label}
                addClass="text-[.75rem] leading-[.75rem]"
              />
            )}
            {mandatoryAndRecommendedText && !uploadBlockedByGeneration && (
              <p className="text-[.75rem] leading-[.75rem]">
                {mandatoryAndRecommendedText}
              </p>
            )}
            {documentIcon?.right &&
              !uploadBlockedByGeneration &&
              (canUploadNewFile ? (
                <button
                  type="button"
                  className={
                    documentIcon.right.color === textGrey
                      ? 'cursor-default'
                      : 'cursor-pointer'
                  }
                >
                  <ColorCube
                    size="1.5rem"
                    numberOrIcon={documentIcon.right.icon}
                    color={documentIcon.right.color}
                  />
                </button>
              ) : (
                <button type="button" onClick={downloadAllFiles}>
                  <ColorCube
                    size="1.5rem"
                    numberOrIcon={documentIcon.right.icon}
                    color={documentIcon.right.color}
                  />
                </button>
              ))}
          </div>
        </div>
        {canUploadNewFile &&
          !uploadBlockedByGeneration &&
          groupedDocuments.is_operation_document &&
          groupedDocuments.operations?.length !== 1 && (
            <div className="flex p-4 pt-0 justify-between items-center self-stretch gap-4">
              <div className="text-[.75rem] flex-1">
                {groupedDocuments.operations &&
                groupedDocuments.operations.length < 2
                  ? t('global.operation_concerned')
                  : t('global.operations_concerned')}
              </div>
              {groupedDocuments.operations &&
              groupedDocuments.operations.length < 2 ? (
                <div className="text-[.75rem]">
                  {groupedDocuments.operations[0].name}
                </div>
              ) : (
                <InputSelect
                  addClass="max-w-[20rem] min-w-[20rem]"
                  placeholder={t('global.choose')}
                  dataLabelValue={
                    groupedDocuments.operations
                      ? transformObjectList(
                          groupedDocuments.operations,
                          'name',
                          'relation_id'
                        )
                      : undefined
                  }
                  disabled={
                    groupedDocuments.operations &&
                    groupedDocuments.operations.length < 2
                  }
                  onChangeValue={(value) => {
                    const arrayValue = value as string[];

                    if (groupedDocuments.operations) {
                      const newState = groupedDocuments.operations.filter(
                        (op) => arrayValue.includes(op.name)
                      );

                      setSelectedOperations(newState);
                    }
                  }}
                  isMultipleSelect
                  closeDropdown={closeDropdown}
                  setCloseDropdown={setCloseDropdown}
                  resetValue={resetSelect}
                  dataTestIdSelect={`input_select_operation_concerned${
                    required ? '_required' : ''
                  }`}
                  dataTestIdOptions="input_select_operation_concerned_option"
                />
              )}
            </div>
          )}
        {documentsWithUrl.length > 0 && (
          <div className="w-full flex flex-col gap-4 border-t border-borderGrey p-4">
            {documentsWithUrl.map((doc, i) => {
              const selectedAction = selectedActions?.find((action) => {
                return action.document === doc;
              });

              const documentKey = `${groupedDocuments.title}_${i}_${selectedAction?.action}`;

              const operationsConcerned = groupedDocuments.operations
                ? doc.relation_ids?.map((relationId) => {
                    if (groupedDocuments.operations) {
                      const operation = groupedDocuments.operations.find(
                        (op) => op.relation_id === relationId
                      );
                      return operation;
                    }
                    return undefined;
                  })
                : undefined;

              const groupedDocumentAnalysisAvailable =
                groupedDocuments.documents.some(
                  (document) => document.document_analysis
                );

              const isPending = doc.status === DOCUMENT_STATUS_ENUM.PENDING;

              return (
                <div key={`document-with-url-${doc.id}`}>
                  {!!updateSignedQuotesToSend &&
                    doc.file_type === DOCUMENT_TYPES.Quote && (
                      <TextWithCheckboxOrToggle
                        label={t('worksites.documents.estimate_message')}
                        onCheck={(isCheck) => {
                          if (isCheck) {
                            updateSignedQuotesToSend((prevState) => [
                              ...prevState,
                              doc,
                            ]);
                          } else {
                            updateSignedQuotesToSend((prevState) =>
                              prevState.filter((d) => d.id !== doc.id)
                            );
                          }
                        }}
                        type="checkbox"
                        checked={signedQuotesToSend.some(
                          (d) => d.id === doc.id
                        )}
                        addClass={`!text-[.75rem] mb-2 ${
                          documentActive.document.id === doc.id
                            ? 'bg-backgroundBody'
                            : ''
                        }`}
                        checkOrTogglePosition="left"
                      />
                    )}
                  <div
                    key={documentKey}
                    className={`flex p-2 flex-col gap-2 self-stretch border border-borderGrey rounded-default ${
                      documentActive &&
                      documentActive.document &&
                      documentActive.document.id &&
                      doc &&
                      doc.id &&
                      documentActive.document.id === doc.id
                        ? 'bg-backgroundBody'
                        : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="flex self-stretch text-[.75rem] justify-between"
                      onClick={() => onSelect(doc)}
                    >
                      <div className="flex items-center gap-2 text-left">
                        {fileIcon(doc)}
                        {shortenStringWithEllipsis(
                          `${
                            doc.file_name ??
                            (doc.file_url && doc.file_url.split('/').pop())
                          }`,
                          isPending ? 43 : 38
                        )}
                        {doc.file_type === DOCUMENT_TYPES.AH &&
                          doc.status > DOCUMENT_STATUS_ENUM.VALIDATE &&
                          worksiteDetails &&
                          worksiteDetails.convention &&
                          worksiteDetails.convention
                            .is_electronic_signature && (
                            <DocumentStatusTag status={doc.status} />
                          )}
                      </div>
                      <div className="flex gap-2">
                        {showAnalysis &&
                          (doc.document_analysis ||
                            groupedDocumentAnalysisAvailable) && (
                            <ColorCube
                              size="1.5rem"
                              numberOrIcon={<EyeIcon />}
                              color={blueOpx}
                              onClick={async () => {
                                getDocumentAnalysis(doc).then(() => {
                                  setDocumentReportActiveFile(doc);
                                  setDocumentReportModal(true);
                                });
                              }}
                              opacity
                            />
                          )}
                        {(isPartners ||
                          importMode ||
                          (isInstaller &&
                            ((doc.status < DOCUMENT_STATUS_ENUM.PENDING &&
                              doc.mandatory) ||
                              (doc.status < DOCUMENT_STATUS_ENUM.REFUSE &&
                                !doc.mandatory)))) &&
                          !readOnly &&
                          // !doc.mandatory &&
                          onDelete && (
                            <ColorCube
                              size="1.5rem"
                              numberOrIcon={<TrashIcon />}
                              color={red}
                              onClick={() => handleOnDelete(doc)}
                              opacity
                            />
                          )}
                        {showCheckpointButton && (
                          <div
                            className={`ms-2 ${
                              !isPending
                                ? 'border border-borderGrey rounded-default'
                                : ''
                            }`}
                          >
                            <ButtonOpx
                              label={
                                isPending
                                  ? t('global.document_check_processing')
                                  : t('global.document_control_completed')
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCheckDocument(doc);
                              }}
                              small
                              dataTestId={`button_check_document_processing_${file_type}`}
                              type={isPending ? 'primary' : 'secondary'}
                            />
                          </div>
                        )}
                      </div>
                    </button>
                    {operationsConcerned && operationsConcerned.length > 0 && (
                      <div className="text-[.75rem] text-textGrey pl-8">
                        {operationsConcerned
                          .map((operation) => operation?.name)
                          .join(', ')}
                      </div>
                    )}
                    {selectedAction?.action === 'refuse' && (
                      <input
                        style={{ borderColor: grey, borderWidth: '1px' }}
                        className="rounded-default w-full p-[.5rem] text-sm"
                        placeholder={t('global.document_input_rejection') || ''}
                        onChange={(e) => {
                          handleChangeRejectionComment(doc, e.target.value);
                        }}
                        readOnly={status === DOCUMENT_STATE_STRING.REFUSE}
                        onClick={(e) => e.stopPropagation()}
                        value={selectedAction?.rejectionComment || ''}
                        onBlur={(e) => e.stopPropagation()}
                        data-test-id={`input_refuse_file_${file_type}`}
                      />
                    )}
                    {doc.status === DOCUMENT_STATUS_ENUM.REFUSE &&
                      !importMode &&
                      isInstaller && (
                        <div className="flex gap-2 self-stretch">
                          <ButtonOpx
                            label={t('global.send_a_message')}
                            small
                            type="secondary"
                            addClass="flex-1"
                            onClick={() => showConversation(doc)}
                          />
                          {isInstaller && changeFileButton(doc)}
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
            {showModalConfirmDelete && (
              <Modal
                title={t('worksites.documents.delete')}
                onConfirmClick={handleOnDeleteConfirm}
                btnCancel
                onClickCancel={() => setShowModalConfirmDelete(false)}
                textBtnConfirm={`${t('global.yes')}`}
                textBtnCancel={`${t('global.no')}`}
                buttonsPosition="bottom"
                backgroundTransparent
              >
                <>
                  <hr />
                  <div className="mt-5">
                    {t('worksites.documents.delete_confirmation', {
                      document: docToDelete?.file_name,
                    })}
                  </div>
                </>
              </Modal>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export { DocumentCardInList };

DocumentCardInList.defaultProps = {
  onUpload: undefined,
  onDelete: undefined,
  updateParentDocuments: undefined,
  showAnalysis: false,
  onValidateProcessing: undefined,
};
