import { axiosClient } from '@api/apiClient';
import {
  urlDeleteRequest,
  urlGetChangesList,
  urlPostAnswerMessageChanges,
  urlPostNewMessageChanges,
} from '@api/apiUrls';
import { IChange, IPayloadDataPostListChanges } from 'types/changesTypes';
import { Dispatch, SetStateAction } from 'react';
import { IRequestTypes } from '@models/requests/types/requestTypes';
import { displayError } from '@utils/format';

// RECUPERE LA LISTE DES ACTIVITES
export const getListChanges = async (
  setListChanges: Dispatch<SetStateAction<IChange[]>>,
  setTotalListChanges: Dispatch<SetStateAction<number>>,
  dataPost: IPayloadDataPostListChanges,
  setIsLoading?: Dispatch<SetStateAction<boolean>>
) => {
  if (setIsLoading) {
    setIsLoading(true);
  }

  try {
    const response = await axiosClient.post(urlGetChangesList, dataPost);
    if (dataPost.pagination.page === 1) {
      setListChanges(response.data.data);
    } else {
      // Par celle-ci qui filtre les doublons par ID :
      setListChanges((prevState) => {
        const existingIds = new Set(prevState.map((item) => item.id));
        const newUniqueItems = response.data.data.filter(
          (item: IChange) => !existingIds.has(item.id)
        );
        // Retourner la liste combinée sans doublons
        return [...prevState, ...newUniqueItems];
      });
    }

    setTotalListChanges(response.data.meta.total);
    if (setIsLoading) {
      setIsLoading(false);
    }
  } catch (error) {
    if (setIsLoading) {
      setIsLoading(false);
    }
  }
};

// ENVOYER UN NOUVEAU MESSAGE INTERNE DECLANCHANT UNE ACTIVITE
export const postNewMessageChange = async (options: {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  relation_type: string;
  relation_id: string;
  message: string;
  setListChanges: Dispatch<SetStateAction<IChange[]>>;
  listChanges: IChange[];
  updateTotalChangesList: Dispatch<SetStateAction<number>>;
  usersMentionedIds?: number[];
  totalChangesList: number;
}) => {
  const {
    setIsLoading,
    relation_type,
    relation_id,
    message,
    setListChanges,
    listChanges,
    updateTotalChangesList,
    usersMentionedIds,
    totalChangesList,
  } = options;
  const formData = new FormData();
  formData.append('message', message);
  formData.append('relation_type', relation_type);
  formData.append('relation_id', relation_id);
  if (usersMentionedIds && usersMentionedIds.length > 0) {
    formData.append('users_mentioned_ids', JSON.stringify(usersMentionedIds));
  }

  try {
    const response = await axiosClient.post(urlPostNewMessageChanges, formData);
    if (listChanges.length === totalChangesList) {
      setListChanges((prev) => [...prev, response.data.data]);
      updateTotalChangesList((prev) => prev + 1);
    }
    setIsLoading(false);
  } catch (error) {
    displayError(error);
    setIsLoading(false);
  }
};

// REPONDRE A UN MESSAGE ETANT DANS LES ACTIVITEES
export const postAnswerMessageChange = async (options: {
  idChange: number;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  relation_type: string;
  relation_id: string;
  message: string;
  usersMentionedIds?: number[];
  updateListChanges: Dispatch<SetStateAction<IChange[]>>;
}) => {
  const {
    idChange,
    setIsLoading,
    relation_type,
    relation_id,
    message,
    usersMentionedIds,
    updateListChanges,
  } = options;

  const formData = new FormData();
  formData.append('relation_type', relation_type);
  formData.append('relation_id', relation_id);
  formData.append('message', message);
  if (usersMentionedIds && usersMentionedIds.length > 0) {
    formData.append('users_mentioned_ids', JSON.stringify(usersMentionedIds));
  }

  try {
    const response = await axiosClient.post(
      urlPostAnswerMessageChanges(idChange.toString()),
      formData
    );

    updateListChanges((prev) => {
      return prev.map((change) => {
        if (change.id === idChange) {
          return response.data.data;
        }
        return change;
      });
    });
    // getListChanges(
    //   setListChanges,
    //   updateTotalChangesList,
    //   listChanges,
    //   dataPost,
    //   setIsLoading
    // );
  } catch (error) {
    displayError(error);
    setIsLoading(false);
  }
};

export const closeRequest = async (
  idRequest: number,
  refreshRequestsAndChanges: () => void,
  updateDetailRequest: Dispatch<SetStateAction<IRequestTypes | null>>
) => {
  try {
    await axiosClient.delete(urlDeleteRequest(idRequest.toString()));
    refreshRequestsAndChanges();
    updateDetailRequest(null);
  } catch (error) {
    displayError(error);
  }
};
