/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { Header } from '@components/Header';
import { Sidebar } from '@components/navigation/Sidebar';

import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';
import { IUserType } from '@models/auth/utils/types';
import { ROLES, VIEW_ROLES } from '@utils/roles';

import { getGlobalEnum } from '@apiRequests/globalRequests';
import { Loader } from '@components/atomic/Loader';
import { RequestsAndChangesCardModal } from '@components/requestsAndChanges/modal/RequestsAndChangesCardModal';
import { NewRequestModal } from '@components/requestsAndChanges/NewRequestModal';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import UpgradeSubscriptionModal from '@components/UpgradeSubscriptionModal';
import { HeaderContext } from '@context/headerContext';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { ThemeContextProvider } from '@context/ThemeContext';
import { getUserByToken } from '@models/auth/apiRequests/authRequests';
import { getPartnersMenu } from '@models/partners/apiRequests/partnersRequest';
import { IPartnerView } from '@models/partners/utils/types/partnersType';
import {
  getListRequests,
  getRequestsListGroup,
} from '@models/requests/apiRequest/requestRequests';
import {
  IListGroupRequests,
  IMessageRequest,
  IPayloadDataPostListRequests,
  IRequestTypes,
} from '@models/requests/types/requestTypes';
import {
  listRequestsGroupInitial,
  requestInitial,
} from '@models/requests/utils/initialsValues/initialValuesRequest';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import {
  initDocumentActive,
  initialChange,
  initialGlobalEnum,
  initialStateWorksiteDetails,
} from '@utils/initialState';
import {
  AUTH_ROUTES,
  BENEFICIARIES_ROUTES,
  CHECKPOINTS_ROUTE,
  DOWNLOAD_ARCHIVE_ROUTE,
  WORKSITES_ROUTES,
} from '@utils/routesUrls';
import { DIMENSIONS } from '@utils/utils';
import Cookies from 'js-cookie';
import Pusher, { Channel } from 'pusher-js';
import { IChange, IPayloadDataPostListChanges } from 'types/changesTypes';
import { getListChanges } from '../apiRequests/changesRequest';
import { useAppUpdate } from '../hooks/useAppUpdate';
import {
  IActiveDocument,
  IGlobalEnumType,
  IHistoryRoute,
  INavLinkSidebar,
} from '../types/globalTypes';

interface ILayoutProps {
  children: JSX.Element;
}

function Layout({ children }: ILayoutProps): JSX.Element {
  const localStorageToken = Cookies.get('token');
  const localStorageImpersonateToken = Cookies.get('impersonate_token');
  const errorToastOnLoad = sessionStorage.getItem('error_toast');
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // Utiliser le hook personnalisé pour gérer les mises à jour de l'application
  useAppUpdate();

  const [loading, setLoading] = useState(true);
  const [route, setRoute] = useState<IHistoryRoute>({
    to: location.pathname,
    older: [],
  });

  // ROLE USER ET TOKEN
  //---------------------------
  const [roleUser, setRoleUser] = useState<string>(ROLES.PRODUCTION);
  const [token, setToken] = useState<string | undefined>(localStorageToken);
  const [user, setUser] = useState<IUserType | null>(null);
  const [impersonateToken, setImpersonateToken] = useState<string | undefined>(
    localStorageImpersonateToken
  );
  const [pusher, setPusher] = useState<Pusher>();
  const [pusherChannelUser, setPusherChannelUser] = useState<Channel>();
  const [pusherChannelUserId, setPusherChannelUserId] = useState<number>();
  const [webSocketConnected, setWebSocketConnected] = useState<boolean>(false);

  const [listUserViews, setListUserViews] = useState<IPartnerView[]>([]);
  const [userView, setUserView] = useState<IPartnerView | null>(null);
  //---------------------------

  // ENUMS DE L'API
  //---------------------------
  const [globalEnum, setGlobalEnum] =
    useState<IGlobalEnumType>(initialGlobalEnum);
  //---------------------------

  // SIDEBAR
  //---------------------------
  const [sidebarMenuIsOpen, setSidebarMenuIsOpen] = useState<boolean>(
    !isMobile
  );
  const [partnersMenu, setPartnersMenu] = useState<INavLinkSidebar[]>([]);

  const pageUrlsWithoutSidebar = [
    WORKSITES_ROUTES.WORKSITES_NEW,
    `${WORKSITES_ROUTES.WORKSITES_NEW}/`,
    AUTH_ROUTES.LOGIN,
    AUTH_ROUTES.FORGOT_PASSWORD,
    AUTH_ROUTES.RESET_PASSWORD,
    AUTH_ROUTES.REGISTER,
    AUTH_ROUTES.INTERNAL_REGISTRATION,
    AUTH_ROUTES.REGISTER_FROM_MODEL,
    AUTH_ROUTES.REGISTRATION_ALMOST_DONE,
    AUTH_ROUTES.SUBCONTRACTOR_OBJECTION,
    DOWNLOAD_ARCHIVE_ROUTE,
  ];

  //---------------------------

  // HEADER
  //---------------------------
  const [titleHeader, setTitleHeader] = useState<string>('');
  const [subtitleHeader, setSubtitleHeader] = useState<string | undefined>(
    undefined
  );
  const [tagHeader, setTagHeader] = useState<JSX.Element | undefined>(
    undefined
  );
  // Sert à afficher en gris à droite du tag des informations comme une référence par exemple : N°DD Classique : CL061222STAS402960397A5
  const [additionnalInfosHeader, setAdditionnalInfosHeader] = useState<
    string[] | undefined
  >(undefined);

  const [displayBackButtonHeader, setDisplayBackButtonHeader] =
    useState<boolean>(false);

  //---------------------------

  // DOCUMENTS GLOBAUX DE L'APPLICATION
  //---------------------------
  const [documentActive, setDocumentActive] =
    useState<IActiveDocument>(initDocumentActive);
  //---------------------------

  // DEMANDES ET ACTIVITES
  //---------------------------
  // Indique quel onglet est actif entre les demandes et les activités dans la modal, quand null c'est qu'aucun n'est actif
  const [requestOrChangeEnumInModal, setRequestOrChangeEnumInModal] =
    useState<RequestOrMessageOrChangeEnum | null>(null);
  // Indique quel onglet est actif entre les demandes et les activités dans la card.
  const [requestOrChangeEnumInCard, setRequestOrChangeEnumInCard] =
    useState<RequestOrMessageOrChangeEnum>(RequestOrMessageOrChangeEnum.CHANGE);
  const [requestOrChangeTab, setRequestOrChangeTab] =
    useState<RequestOrMessageOrChangeEnum | null>(null);
  const [demandIdForNote, setDemandIdForNote] = useState<number>(-1);
  // Indique si la page "demandes" affiche l'historique ou bien le kanban
  const [isHistoryRequest, setIsHistoryRequest] = useState<boolean>(false);
  const [isModalNewRequest, setIsModalNewRequest] = useState<boolean>(false);
  // Liste de demandes spécifiques à un worksite OU un partenaire
  const [listRequests, setListRequests] = useState<IRequestTypes[]>([]);
  // Liste de demandes spécifiques à un worksite OU un partenaire
  const [listMessagesRequests, setListMessagesRequests] = useState<
    IRequestTypes[]
  >([requestInitial]);
  const [numberPageRequestsList, setNumberPageRequestsList] =
    useState<number>(1);
  const [totalRequestsList, setTotalRequestsList] = useState<number>(0);
  const [totalRequestsMessagesList, setTotalRequestsMessagesList] =
    useState<number>(0);
  // Liste de toute les demandes groupés par worksite OU par partenaire
  const [listRequestsGroupAll, setListRequestsGroupAll] =
    useState<IListGroupRequests>(listRequestsGroupInitial);
  const [filterDataRequests, setFilterDataRequests] =
    useState<ISortAndFilterType>();
  const [listChanges, setListChanges] = useState<IChange[]>([initialChange]);
  const [numberPageChangesList, setNumberPageChangesList] = useState<number>(1);
  const [totalChangesList, setTotalChangesList] = useState<number>(0);
  const [messagesRequest, setMessagesRequest] = useState<
    IMessageRequest[] | null
  >(null);
  const [isLoadingListRequestOrChange, setIsLoadingListRequestOrChange] =
    useState<boolean>(false);
  const [isLoadingMessagesRequest, setIsLoadingMessagesrequest] =
    useState<boolean>(false);

  const [detailRequest, setDetailRequest] = useState<IRequestTypes | null>(
    null
  );
  const [worksiteDetailForNewRequest, setWorksiteDetailForNewRequest] =
    useState<IWorksiteDetails>(initialStateWorksiteDetails);

  //---------------------------

  const [addDocumentModalIsActive, setAddDocumentModalIsActive] =
    useState<boolean>(false);

  const [showDropDownSortAndFilter, setShowDropDownSortAndFilter] = useState({
    sort: false,
    filter: false,
  });
  const [isOpaque, setIsOpaque] = useState(false);

  const [inputSelectMenuOpenId, setInputSelectMenuOpenId] = useState<
    string | undefined
  >(undefined);

  const resetDropDown = () => {
    setShowDropDownSortAndFilter({ sort: false, filter: false });
  };

  const handleLayoutClick = () => {
    if (
      isOpaque &&
      (showDropDownSortAndFilter.sort || showDropDownSortAndFilter.filter)
    ) {
      resetDropDown();
    }
  };

  const [displayModalUpgradeSubscription, setDisplayModalUpgradeSubscription] =
    useState<boolean>(false);

  const globalContextValues = useMemo(
    () => ({
      pusher,
      pusherChannelUser,
      roleUser,
      sidebarMenuIsOpen,
      updateSidebarMenuIsOpen: setSidebarMenuIsOpen,
      partnersMenu,
      updatePartnersMenu: setPartnersMenu,
      globalEnum,
      listUserViews,
      updateListUserViews: setListUserViews,
      userView,
      updateUserView: setUserView,
      documentActive,
      updateDocumentActive: setDocumentActive,
      route,
      updateRoute: setRoute,
      addDocumentModalIsActive,
      updateAddDocumentModalIsActive: setAddDocumentModalIsActive,
      layoutIsLoading: loading,
      updateLayoutIsLoading: setLoading,
      isMobile,
      isOpaque,
      updateIsOpaque: setIsOpaque,
      displayModalUpgradeSubscription,
      updateDisplayModalUpgradeSubscription: setDisplayModalUpgradeSubscription,
      showDropDownSortAndFilter,
      updateShowDropDownSortAndFilter: setShowDropDownSortAndFilter,
      inputSelectMenuOpenId,
      updateInputSelectMenuOpenId: setInputSelectMenuOpenId,
    }),
    [
      pusher,
      pusherChannelUser,
      roleUser,
      titleHeader,
      globalEnum,
      sidebarMenuIsOpen,
      partnersMenu,
      documentActive,
      route,
      addDocumentModalIsActive,
      isMobile,
      isOpaque,
      displayModalUpgradeSubscription,
      userView,
      showDropDownSortAndFilter,
      inputSelectMenuOpenId,
    ]
  );

  const getUserDatas = async () => {
    setLoading(true);
    await getUserByToken(setUser);
    await getPartnersMenu(setPartnersMenu, setListUserViews, setUserView);
    setLoading(false);
    navigate('/', { replace: true });
  };

  const authContextValue = useMemo(
    () => ({
      token,
      updateToken: setToken,
      user,
      updateUser: setUser,
      getUserDatas,
      impersonateToken,
      updateImpersonateToken: setImpersonateToken,
    }),
    [token, user, impersonateToken]
  );

  const refreshHeader = async (displayBackButton?: boolean) => {
    setDocumentActive(initDocumentActive);
    setTagHeader(undefined);
    setAdditionnalInfosHeader(undefined);
    setSubtitleHeader(undefined);
    setDisplayBackButtonHeader(displayBackButton || false);
  };

  const headerContextValue = useMemo(
    () => ({
      titleHeader,
      updateTitleHeader: setTitleHeader,
      subtitleHeader,
      updateSubtitleHeader: setSubtitleHeader,
      tagHeader,
      updateTagHeader: setTagHeader,
      displayBackButtonHeader,
      updateDisplayBackButtonHeader: setDisplayBackButtonHeader,
      additionnalInfosHeader,
      updateAdditionnalInfosHeader: setAdditionnalInfosHeader,
      refreshHeader,
    }),
    [titleHeader, tagHeader, additionnalInfosHeader]
  );

  const getRequestsListGroupWithFilter = async () => {
    getRequestsListGroup(
      setListRequestsGroupAll,
      setIsLoadingListRequestOrChange,
      undefined,
      { sort_and_filter: filterDataRequests }
    );
  };

  const getRequestsAndChanges = async () => {
    if (token) {
      setIsLoadingListRequestOrChange(true);
      const dataPostRequest: IPayloadDataPostListRequests = {
        pagination: {
          page: 1,
          perPage: 10,
        },
        status: 1,
      };
      const dataPostChanges: IPayloadDataPostListChanges = {
        pagination: {
          page: 1,
          perPage: 15,
        },
      };

      const lastPathSegment = location.pathname.split('/').pop() as string;

      if (location.pathname.includes('worksites')) {
        if (requestOrChangeEnumInModal === null) {
          if (/\d/.test(lastPathSegment)) {
            dataPostRequest.relation_type = 'worksite';
            dataPostRequest.relation_id = parseInt(lastPathSegment, 10);
            dataPostChanges.relation_type = 'worksite';
            dataPostChanges.relation_id = parseInt(lastPathSegment, 10);
          } else {
            dataPostRequest.relation_type = 'worksite';
            delete dataPostRequest.relation_id;
            delete dataPostChanges.relation_type;
            delete dataPostChanges.relation_id;
          }
        } else {
          delete dataPostRequest.relation_type;
          delete dataPostRequest.relation_id;
          delete dataPostChanges.relation_type;
          delete dataPostChanges.relation_id;
        }
      } else if (userView && userView.entity_id) {
        dataPostChanges.relation_type = 'entity';
        dataPostChanges.relation_id = userView.entity_id;
      }

      getRequestsListGroup(setListRequestsGroupAll);
      try {
        await Promise.all([
          getListRequests(
            setListRequests,
            setTotalRequestsList,
            listRequests,
            dataPostRequest
          ),
          getListRequests(
            setListMessagesRequests,
            setTotalRequestsMessagesList,
            listMessagesRequests,
            { ...dataPostRequest, status: null },
            undefined,
            2
          ),
          getListChanges(setListChanges, setTotalChangesList, dataPostChanges),
        ]);
      } catch (error) {
        console.error(
          'Error in getRequestsAndChanges in LayoutContextProvider',
          error
        );
      } finally {
        setIsLoadingListRequestOrChange(false);
      }
    }
  };

  const requestContextValue = useMemo(
    () => ({
      requestOrChangeEnumInModal,
      updateRequestOrChangeEnumInModal: setRequestOrChangeEnumInModal,
      isHistoryRequest,
      updateIsHistoryRequest: setIsHistoryRequest,
      requestOrChangeEnumInCard,
      updateRequestOrChangeEnumInCard: setRequestOrChangeEnumInCard,
      requestOrChangeTab,
      updateRequestOrChangeTab: setRequestOrChangeTab,
      demandIdForNote,
      updateDemandIdForNote: setDemandIdForNote,
      isModalNewRequest,
      updateIsModalNewRequest: setIsModalNewRequest,
      listRequests,
      updateListRequests: setListRequests,
      listChanges,
      updateListChanges: setListChanges,
      isLoadingListRequestOrChange,
      updateIsLoadingListRequestOrChange: setIsLoadingListRequestOrChange,
      detailRequest,
      updateDetailRequest: setDetailRequest,
      messagesRequest,
      updateMessagesRequest: setMessagesRequest,
      isLoadingMessagesRequest,
      updateIsLoadingMessagesRequest: setIsLoadingMessagesrequest,
      worksiteDetailForNewRequest,
      updateWorksiteDetailForNewRequest: setWorksiteDetailForNewRequest,
      numberPageRequestsList,
      updateNumberPageRequestsList: setNumberPageRequestsList,
      numberPageChangesList,
      updateNumberPageChangesList: setNumberPageChangesList,
      totalRequestsList,
      updateTotalRequestsList: setTotalRequestsList,
      totalRequestsMessagesList,
      updateTotalRequestsMessagesList: setTotalRequestsMessagesList,
      totalChangesList,
      updateTotalChangesList: setTotalChangesList,
      listRequestsGroupAll,
      updateListRequestsGroupAll: setListRequestsGroupAll,
      refreshRequestsAndChanges: getRequestsAndChanges,
      filterDataRequests,
      updateFilterDataRequests: setFilterDataRequests,
      refreshListGroupRequests: getRequestsListGroupWithFilter,
      listMessagesRequests,
      updateListMessagesRequests: setListMessagesRequests,
    }),
    [
      requestOrChangeEnumInModal,
      isHistoryRequest,
      requestOrChangeEnumInCard,
      requestOrChangeTab,
      demandIdForNote,
      isModalNewRequest,
      listRequests,
      listRequestsGroupAll,
      listChanges,
      isLoadingListRequestOrChange,
      detailRequest,
      messagesRequest,
      isLoadingMessagesRequest,
      worksiteDetailForNewRequest,
      numberPageChangesList,
      numberPageRequestsList,
      totalRequestsList,
      totalRequestsMessagesList,
      totalChangesList,
      filterDataRequests,
      getRequestsAndChanges,
      listMessagesRequests,
    ]
  );

  const eventHandler = (data: any) => {
    if (data.type === 'App\\Notifications\\DemandNotification') {
      getRequestsAndChanges();
    }
  };
  const eventName =
    'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';

  const webSocketHandler = () => {
    const channel = pusher?.subscribe(`users.${user?.id}`);

    channel?.bind(eventName, eventHandler);

    // Écoutez les événements de cycle de vie du canal
    channel?.bind('pusher:subscription_succeeded', () => {
      setWebSocketConnected(true);
    });

    setPusherChannelUser(channel);
    setPusherChannelUserId(user?.id);
  };

  const refreshBind = () => {
    const channel = pusher?.channel(`users.${user?.id}`);
    channel?.unbind(eventName, eventHandler);
    channel?.bind(eventName, eventHandler);
    setPusherChannelUser(channel);
  };

  const disconnectPusher = () => {
    const channel = pusher?.channel(`users.${user?.id}`);
    channel?.unbind(eventName, eventHandler);
    channel?.unsubscribe();
    setPusherChannelUser(undefined);
  };

  const refreshPusherWithUser = () => {
    disconnectPusher();
    setTimeout(() => {
      webSocketHandler();
    }, 1000);
  };

  useEffect(() => {
    if (pusher && !webSocketConnected && user?.id) {
      webSocketHandler();
    }

    if (
      pusher &&
      webSocketConnected &&
      user?.id &&
      pusherChannelUserId === user?.id &&
      location.pathname.includes('worksites/')
    ) {
      refreshBind();
    }

    if (
      pusher &&
      webSocketConnected &&
      user?.id &&
      pusherChannelUserId &&
      pusherChannelUserId !== user?.id
    ) {
      refreshPusherWithUser();
    }
  }, [user, pusher, location, pusherChannelUserId]);

  useEffect(() => {
    // Créer une URL complète incluant les paramètres de requête et le hash
    const currentFullPath = `${location.pathname}${location.search}${location.hash}`;
    const isWorksiteCreationRoute = location.pathname.includes(
      WORKSITES_ROUTES.WORKSITES_NEW
    );

    if (isWorksiteCreationRoute) {
      setRoute({
        to: '/',
        older: [],
      });
      return;
    }

    // Vérifier si nous revenons en arrière à une route précédente
    if (currentFullPath !== route.to) {
      // Vérifier si seuls les paramètres de requête ont changé, mais le chemin de base reste le même
      const samePathDifferentParams =
        location.pathname === route.to.split('?')[0] &&
        currentFullPath.includes('?') &&
        !route.to.includes('?');

      if (
        route.older.length > 0 &&
        route.older[route.older.length - 1] === currentFullPath
      ) {
        // Navigation en arrière
        const newOlder = route.older.slice(0, -1);
        setRoute({
          to: currentFullPath,
          older: newOlder,
        });
      } else if (samePathDifferentParams) {
        // Si seuls les paramètres ont changé et que le chemin de base est le même,
        // mettre à jour l'URL actuelle sans ajouter l'ancienne à l'historique
        setRoute({
          to: currentFullPath,
          older: route.older,
        });
      } else {
        // Navigation vers une nouvelle route
        setRoute({
          to: currentFullPath,
          older: [...route.older, route.to],
        });
      }
    }
  }, [location.pathname, location.search, location.hash]);

  useEffect(() => {
    if (Object.keys(globalEnum).length === 1) {
      getGlobalEnum(setGlobalEnum);
    }
    if (token) {
      if (token !== localStorageToken) {
        Cookies.set('token', token, {
          expires: 7,
          secure: true,
        });
        // localStorage.setItem('token', token);
      }
      if (!pusher) {
        const initPusher = new Pusher(
          process.env.REACT_APP_PUSHER_APP_KEY || '',
          {
            wsHost: process.env.REACT_APP_PUSHER_IP,
            cluster: 'eu',
            enabledTransports: ['ws', 'wss'],
          }
        );
        setPusher(initPusher);
      }
      getUserByToken(setUser);
      getPartnersMenu(setPartnersMenu, setListUserViews, setUserView);
      if (
        !location.pathname.includes(`${WORKSITES_ROUTES.WORKSITES}/`) &&
        !location.pathname.includes(`${WORKSITES_ROUTES.CHECKPOINTS}/`)
      ) {
        getRequestsAndChanges();
      }
    }
  }, [token, impersonateToken]);

  useEffect(() => {
    if (!localStorageToken && token) {
      setToken(undefined);
      setUser(null);
      setPartnersMenu([]);
    }
  }, [localStorageToken]);

  useEffect(() => {
    if (errorToastOnLoad) {
      toast.error(errorToastOnLoad);
      sessionStorage.removeItem('error_toast');
    }
  }, [errorToastOnLoad]);

  useEffect(() => {
    if (userView) {
      const viewIndex = Number(userView.vue);
      if (viewIndex in VIEW_ROLES) {
        setRoleUser(VIEW_ROLES[viewIndex as keyof typeof VIEW_ROLES]);
      }
    }
  }, [userView]);

  useEffect(() => {
    if (isOpaque) {
      // Désactiver le défilement lorsque le modal est ouvert
      document.body.style.overflow = 'hidden';
    } else {
      // Réactiver le défilement lorsque le modal est fermé
      document.body.style.overflow = '';
    }

    // Nettoyage : réactiver le défilement lorsque le composant est démonté
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpaque]);

  useEffect(() => {
    // Ajouter un nettoyage pour unbind lors du démontage du composant
    return () => {
      disconnectPusher();
    };
  }, []);

  return (
    <AuthContext.Provider value={authContextValue}>
      <GlobalContext.Provider value={globalContextValues}>
        <ThemeContextProvider>
          <HeaderContext.Provider value={headerContextValue}>
            <RequestAndActivityIntoModalOrCardContext.Provider
              value={requestContextValue}
            >
              {loading ? (
                <div className="min-h-screen flex w-full justify-center items-center">
                  <Loader isBig />
                </div>
              ) : !pageUrlsWithoutSidebar.includes(location.pathname) &&
                !location.pathname.includes(
                  `${WORKSITES_ROUTES.WORKSITES_NEW}/`
                ) &&
                !location.pathname.includes(
                  `${WORKSITES_ROUTES.WORKSITES_CANCELED}/`
                ) &&
                !location.pathname.includes(
                  `${BENEFICIARIES_ROUTES.BENEFICIARY_WORKSITE}/`
                ) &&
                !location.pathname.includes(AUTH_ROUTES.LOGIN) &&
                !location.pathname.includes(DOWNLOAD_ARCHIVE_ROUTE) &&
                !location.pathname.includes(CHECKPOINTS_ROUTE) &&
                !location.pathname.includes(
                  `${WORKSITES_ROUTES.IFRAME_SIMULATION}/`
                ) ? (
                <>
                  <div
                    style={{
                      overflow:
                        requestOrChangeEnumInModal !== null ? 'hidden' : 'auto',
                    }}
                    className="min-h-screen text-black flex flex-col items-end bg-backgroundBody text-[leading-[1.25rem]"
                  >
                    {isModalNewRequest && <NewRequestModal />}
                    <RequestsAndChangesCardModal />
                    <Sidebar />
                    <Header />
                    <div
                      className={`px-6 ${
                        sidebarMenuIsOpen ? 'w-[85%]' : 'w-[95%]'
                      } relative flex-grow transition-all duration-500 ease-in-out`}
                      style={{
                        marginTop: DIMENSIONS.header_height,
                        width: sidebarMenuIsOpen
                          ? `${
                              100 - DIMENSIONS.sidebarmenu_open_percent_width
                            }%`
                          : `${
                              100 - DIMENSIONS.sidebarmenu_close_percent_width
                            }%`,
                      }}
                    >
                      {isOpaque && (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        <div
                          onClick={handleLayoutClick}
                          className="absolute inset-0 top-[-5.5rem] bg-black opacity-20 z-[51]"
                        />
                      )}
                      <div className="relative w-full">{children}</div>
                    </div>
                  </div>
                  {displayModalUpgradeSubscription && (
                    <UpgradeSubscriptionModal />
                  )}
                </>
              ) : (
                <div>{children}</div>
              )}
              <ToastContainer style={{ zIndex: 100000 }} />
            </RequestAndActivityIntoModalOrCardContext.Provider>
          </HeaderContext.Provider>
        </ThemeContextProvider>
      </GlobalContext.Provider>
    </AuthContext.Provider>
  );
}

export { Layout };
