import { green, iconBlue, red, textGrey } from '@assets/color';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { TFunction } from 'i18next';

export const getRelatedModelBadge = (
  firstNumber: number,
  secondNumber: number,
  relatedModel: string
) => {
  let text = '';
  let color = iconBlue;
  const value = firstNumber > 0 ? String(firstNumber) : String(secondNumber);

  if (firstNumber === 1) {
    text = `one_active_${relatedModel}`;
  } else if (firstNumber > 1) {
    text = `multiple_active_${relatedModel}s`;
  } else if (secondNumber === 1) {
    text = `one_inactive_${relatedModel}`;
    color = relatedModel !== 'worksites' ? textGrey : green;
  } else {
    text =
      relatedModel !== 'worksites'
        ? `multiple_inactive_${relatedModel}s`
        : 'multiple_done_worksites';
    color = relatedModel !== 'worksites' ? textGrey : green;
  }

  text = `partners.${text}`;

  if (firstNumber < 1 && secondNumber < 1 && relatedModel === 'worksite') {
    return { text: 'partners.no_worksite', value: '', color: red };
  }

  return { text, value, color };
};

export const getContractLabel = (
  contractType: ContractTypes | null,
  t: TFunction
) => {
  const contractLabels: Record<ContractTypes, string> = {
    [ContractTypes.CONVENTION]: t(
      'partners.tabs.button.cee_installer_partnership_contract'
    ),
    [ContractTypes.MANDAT]: t('partners.tabs.button.mandate_contract'),
    [ContractTypes.MANDAT_NON_DEPOSANT]: t(
      'partners.tabs.button.mandate_contract'
    ),
    [ContractTypes.VENTE]: t('partners.tabs.button.sales_contract'),
    [ContractTypes.DELEGATION]: t('partners.tabs.button.sales_contract'),
    [ContractTypes.TRIPARTITE]: t('partners.tabs.button.tripartite_contract'),
  };

  return contractType
    ? `${t('global.new')} ${contractLabels[contractType]}`
    : t('contract.new_contract');
};
